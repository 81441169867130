// @ts-nocheck
import React, {
	Suspense, useState, useCallback,
} from 'react';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { WithTranslation, withTranslation } from 'react-i18next';
import {
	Form, SFormGroup, SInputLabel,
} from '@components/DynamicForm/ComponentsCore';
import { SButton } from '@components/Buttons';
import { STextField } from '@forms/Components';
import { Creators } from '@actions';
import { IRootState, persistor } from 'src/store';
import SpinnerLDSRipple from '@components/Shared/SpinnerLDSRipple';
import ImageInput from '@components/Inputs/ImageInput';
import { TObject } from 'src/@types/IShared';
import { maxLength } from '@constants/GLOBAL';
import routes from '@routes/modal';
import { handleAxiosErrors } from '@services/auth';
import { getValidationErrors } from '@utils/yup';
import { APIOrganizations } from '@services/apis';
import { debounce } from '@utils/helpers';
import { Grid } from '@mui/material';
import { SBox } from '@components/Background/SBox';

declare type TProps = WithTranslation;

const _Profile = ({ t }: TProps) => {
	const dispatch = useDispatch();

	const [errors, setErrors] = useState<TObject>({});
	const { organization, organizations, user } = useSelector(
		(state: IRootState) => state.userOrganizationProfile,
	) || {};

	const [inProgress, setInProgress] = useState<string | undefined>(undefined);

	const onOrganizationDebounce = useCallback(
		// @ts-ignore
		debounce(async (value, callback) => {
			try {
				if (!organization) return;

				const schema = Yup.object().shape({
					nome: Yup.string().required(t('messages.requiredField')),
				});

				await schema.validate(value, {
					abortEarly: false,
				});

				await APIOrganizations.patchOrganization(organization._id, value);
			} catch (err) {
				if (err instanceof Yup.ValidationError) {
					setErrors(getValidationErrors(err));
					return;
				}
				// @ts-ignore
				if (err?.response?.data?.err === 'messages.nameAlreadyInUse') {
					setErrors({ nome: t('messages.nameAlreadyInUse') });

					return;
				}
				handleAxiosErrors(err);
			} finally {
				if (typeof callback === 'function') {
					callback();
				}
			}
		}, 1000), []);

	const onOrganizationHandler = (e: any) => {
		setInProgress('name');
		setErrors({});
		const name = { nome: e.target.value };
		dispatch(Creators.USER_ORGANIZATION.handleOrganization(name));
		// @ts-ignore
		onOrganizationDebounce(name, () => setInProgress(undefined));
	};

	const onOutHandler = (mode: 'leave' | 'delete') => {
		persistor.pause();
		dispatch(Creators.MODAL.showModal(routes.MODAL_ORGANIZATION_OUT.path, { mode }));
	};

	// @ts-ignore
	const onImageHandler = async ({ formData }) => {
		setInProgress('image-organization-profile');
		dispatch(Creators.USER_ORGANIZATION.handleOrganizationImage({
			formData,
			callback: () => setInProgress(undefined),
		}));
	};

	return (
		<SBox id="organizationProfile" sx={{ width: { xs: '100%', sm: '50%' } }} loading={!organization}>
			<Grid container sx={{ display: 'flex', flexDirection: 'column' }} spacing={3}>
				<Grid item xs>
					<ImageInput
						id="image-organization-profile"
						initial={organization.nome}
						image={organization.image}
						color="var(--primary)"
						onClick={onImageHandler}
						disabled={!user?.isAdministrator}
						isLoading={inProgress === 'image-organization-profile'}
					/>
				</Grid>
				<Grid item xs>
					<Form className="s-form">
						<SFormGroup>
							<SInputLabel htmlFor="org-name" error={typeof errors?.nome === 'string'}>
								{t('global.name')}
							</SInputLabel>
							<STextField
								type="text"
								name="nome"
								maxLength={maxLength.orgName}
								// @ts-ignore
								pattern="^[a-zA-ZÀ-ÖØ-öø-ÿ0-9 ]+$"
								id="org-name"
								placeholder={t('form.placeholder.organization.name')}
								value={organization?.nome}
								disabled={!user?.isAdministrator}
								error={typeof errors?.nome === 'string'}
								helperText={errors?.nome}
								onChange={onOrganizationHandler}
								isLoading={inProgress === 'name'}
								isAsync
							/>
						</SFormGroup>
					</Form>
				</Grid>
				<Grid item xs>
					<h5>{`${t('global.leave')} ${organization?.nome}`}</h5>
					<p>{t('organization.leaveText')}</p>
					<SButton
						type="button"
						variant="outlined"
						color="secondary"
						onClick={() => onOutHandler('leave')}
					>
						{t('organization.leave')}
					</SButton>
				</Grid>
				<Grid item xs>
					{user?.isAdministrator && (
						<>
							<h5>{`${t('global.delete')} ${organization?.nome}`}</h5>
							<p>{t('organization.deletionDisclaimer.p1')}</p>
							<p>{t('organization.deletionDisclaimer.p2')}</p>
							<p>{t('organization.deletionDisclaimer.p3')}</p>
							<SButton
								type="button"
								variant="outlined"
								color="secondary"
								onClick={() => onOutHandler('delete')}
							>
								{t('organization.delete')}
							</SButton>
						</>
					)}
				</Grid>
			</Grid>
		</SBox>
	);
};

const Translation = withTranslation('common')(_Profile);

const Profile = () => (
	<Suspense fallback={<SpinnerLDSRipple />}>
		<Translation />
	</Suspense>
);

export default Profile;
