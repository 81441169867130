// @ts-nocheck
import React, { useMemo, useState } from 'react';
import {
	useStripe,
	useElements,
	CardNumberElement,
	CardCvcElement,
	CardExpiryElement,
	Elements,
} from '@stripe/react-stripe-js';
import {
	loadStripe, PaymentMethodResult, StripeCardNumberElement, StripeCardNumberElementChangeEvent,
} from '@stripe/stripe-js';
import {
	Box, BoxProps, ButtonProps, ButtonPropsVariantOverrides, Grid,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { SButton } from '@components/Buttons';
import { APIBilling } from '@services/apis';
import { Creators } from '@actions';
import { useDispatch } from 'react-redux';
import { OverridableStringUnion } from '@mui/types';
import { SToast } from '@modules/SToast';
import { ISButton } from '@components/Buttons/SButton';
import { handleAxiosErrors } from '@services/auth';

interface StripeFormProps extends BoxProps {
  onSubscribe?: (result: PaymentMethodResult) => void
  ConfirmButtonProps?: ButtonProps & ISButton
  mode?: 'card' | 'subscrible' | 'update'
  components?: { closeButton?: React.ReactNode, label?: string }
	variant?: OverridableStringUnion<
  'text' | 'outlined' | 'contained',
  ButtonPropsVariantOverrides
>
}

const useOptions = () => {
	const fontSize = '1rem';
	const options = useMemo(
		() => ({
			style: {
				base: {
					fontSize,
					width: '100%',
					color: '#424770',
					letterSpacing: '0.025em',
					fontFamily: 'Source Code Pro, monospace',
					'::placeholder': {
						color: '#aab7c4',
					},
				},
				invalid: {
					color: '#9e2146',
				},
			},
		}),
		[fontSize],
	);

	return options;
};

const SplitForm = (props: StripeFormProps) => {
	const stripe = useStripe();
	const { t } = useTranslation();
	const elements = useElements();
	const options = useOptions();
	const dispatch = useDispatch();
	const [inProgress, setInProgress] = useState<'register' | undefined>();

	const {
		components, variant, ConfirmButtonProps, mode, sx, ...rest
	} = props;

	const onRegister = async () => {
		try {
			setInProgress('register');

			if (mode === 'subscrible') {
				dispatch(Creators.BILLING.subscribePlan({
					callback: () => setInProgress(undefined),
				}));
				return;
			}

			if (!stripe || !elements) {
				// Stripe.js has not loaded yet. Make sure to disable
				// form submission until Stripe.js has loaded.
				return;
			}

			const payload = await stripe.createPaymentMethod({
				type: 'card',
				card: elements.getElement(CardNumberElement) as StripeCardNumberElement,
			});

			if (!payload.error) {
				const { paymentMethod } = payload;

				// Register Senno
				await APIBilling.postPaymentMethod(
					{
						lastFourDigits: paymentMethod.card.last4,
						token: paymentMethod.id,
					},
				);

				if (mode === 'update') {
					dispatch(Creators.BILLING.subscribePlan({
						callback: () => setInProgress(undefined),
					}));
				}

				dispatch(Creators.BILLING.successBilling({ defaultPaymentMethod: paymentMethod }));
				SToast.success(t('messages.stripe.success.paymentMethod'));
			}
		} catch (err) {
			setInProgress(undefined);
			if (err.response?.data?.err && err.response?.data?.err.includes('declined')) {
				SToast.error(t('messages.stripe.card.declined'));
				return;
			}

			handleAxiosErrors(err);
		}
	};

	return (
		<Box
			className="stripe-form"
			{...rest }
			sx={{
				'.StripeElement': {
					height: '40px',
					margin: '10px 0 20px 0',
					padding: '10px 14px',
					borderRadius: '4px',
					backgroundColor: '#fff',
					display: 'block',
					boxShadow: 'rgba(50, 50, 93, 0.14902) 0px 1px 3px,rgba(0, 0, 0, 0.0196078) 0px 1px 0px',
				},
				...sx,
			}}

		>
			<form>
				{['card', 'update'].includes(mode) && (
					<>
						<label>
							{t('billing.card.number')}
							<CardNumberElement
								options={{ ...options, showIcon: true }}
								onReady={() => {
									console.log('CardNumberElement [ready]');
								}}
								onChange={(event: StripeCardNumberElementChangeEvent) => {
									console.log('CardNumberElement [change]', event);
									// setErrors((prevState) => ({...prevState, [event.code]: event.message }))
								}}
								onBlur={() => {
									console.log('CardNumberElement [blur]');
								}}
								onFocus={() => {
									console.log('CardNumberElement [focus]');
								}}
							/>
						</label>
						<Box
							className="stripe-fields"
							sx={{
								display: 'flex',
							}}
						>
							<label className="mr-15px">
								{t('billing.card.expiration')}
								<CardExpiryElement
									options={options}
									onReady={() => {
										console.log('CardNumberElement [ready]');
									}}
									onChange={(event) => {
										console.log('CardNumberElement [change]', event);
									}}
									onBlur={() => {
										console.log('CardNumberElement [blur]');
									}}
									onFocus={() => {
										console.log('CardNumberElement [focus]');
									}}
								/>
							</label>
							<label className="ml-15px">
								CVC
								<CardCvcElement
									options={options}
									onReady={() => {
										console.log('CardNumberElement [ready]');
									}}
									onChange={(event) => {
										console.log('CardNumberElement [change]', event);
									}}
									onBlur={() => {
										console.log('CardNumberElement [blur]');
									}}
									onFocus={() => {
										console.log('CardNumberElement [focus]');
									}}
								/>
							</label>
						</Box>
					</>
				)}

				<Grid container className="stripe-buttons">
					{typeof components?.closeButton === 'object' && (
						<Grid item>
							{components?.closeButton}
						</Grid>
					)}
					<Grid item>
						<SButton
							type="button"
							color="secondary"
							variant={variant}
							disabled={!stripe}
							isLoading={inProgress === 'register'}
							onClick={onRegister}
							{...ConfirmButtonProps}
						/>
					</Grid>
				</Grid>

			</form>
		</Box>
	);
};

console.log('----', process.env);

const stripePromise = loadStripe(process.env.STRIPE_API_KEY);

const appearance = {
	theme: 'stripe',
	variables: {
		colorPrimary: '#0570de',
		colorBackground: '#ffffff',
		colorText: '#30313d',
		colorDanger: '#df1b41',
		fontFamily: 'Ideal Sans, system-ui, sans-serif',
		spacingUnit: '2px',
		borderRadius: '4px',
		// See all possible variables below
	},
};

const StripeForm = (props: StripeFormProps) => {
	const options = {
		// passing the client secret obtained from the server
		// clientSecret: process.env.STRIPE_API_KEY,
		appearance,
	};

	return (
		<Elements stripe={stripePromise} options={options}>
			<SplitForm {...props} />
		</Elements>
	);
};

export default StripeForm;
