// @ts-nocheck
import React, {
	MouseEvent, useEffect, useRef, useState,
} from 'react';
import ReactEcharts from 'echarts-for-react';
import { WithTranslation, withTranslation } from 'react-i18next';
import Icon from '@components/Shared/Icon';
import { useReactToPrint } from 'react-to-print';
import _ from 'lodash';
// Import the echarts core module, which provides the necessary interfaces for using echarts.
import * as echarts from 'echarts/core';
// Import charts, all with Chart suffix
import {
	BarChart,
	// 系列类型的定义后缀都为 SeriesOption
	BarSeriesOption,
	LineChart,
	LineSeriesOption,
} from 'echarts/charts';
import { LabelLayout, UniversalTransition } from 'echarts/features';
import {
	TitleComponent,
	// 组件类型的定义后缀都为 ComponentOption
	TitleComponentOption,
	TooltipComponent,
	TooltipComponentOption,
	GridComponent,
	GridComponentOption,
	// 数据集组件
	DatasetComponent,
	DatasetComponentOption,
	// 内置数据转换器组件 (filter, sort)
	TransformComponent,
} from 'echarts/components';
// Import renderer, note that introducing the CanvasRenderer or SVGRenderer is a required step
import {
	CanvasRenderer,
	SVGRenderer,
} from 'echarts/renderers';
import { DefaultLabelFormatterCallbackParams } from 'echarts';
import { overflowText } from '@utils/helpers';
import { Box, BoxProps, IconButton, Typography } from '@mui/material';
import { SMenu } from '@components/SMenu';
import { MatrixGrid } from './styles';

// 通过 ComposeOption 来组合出一个只有必须组件和图表的 Option 类型
export type ECOption = echarts.ComposeOption<
	| BarSeriesOption
	| LineSeriesOption
	| TitleComponentOption
	| TooltipComponentOption
	| GridComponentOption
	| DatasetComponentOption
>;

// Register the required components
// 注册必须的组件
echarts.use([
	TitleComponent,
	TooltipComponent,
	GridComponent,
	DatasetComponent,
	TransformComponent,
	BarChart,
	LabelLayout,
	UniversalTransition,
	SVGRenderer,
]);

export interface MatrixBaseProps extends BoxProps {
	hasOptions?: boolean
	title?: string
	isLoading?: boolean
	series: ECOption
	optionBase: ECOption
	quadrants: { topLeft?: string, topRight?: string, bottomLeft?: string, bottomRight?: string }
	classes?: {
		zoomIn?: string
		zoomOut?: string
	},
	onClickMore?: () => void
}

declare type TProps = MatrixBaseProps & WithTranslation

const _MatrixBase = (props: TProps) => {
	const {
		t, quadrants, title, className, id, classes, optionBase,
		isLoading, series, onClickMore, ...rest
	} = props;

	const [expanded, setExpanded] = useState(classes?.zoomOut);
	const printRef = useRef();
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

	const onPrintHandler = useReactToPrint({
		content: () => {
			printRef.current.classList.add('printing');
			return printRef.current;
		},
		documentTitle: title,
		onAfterPrint: () => {
			printRef.current.classList.remove('printing');
		},
	});

	const options = [
		{
			label: t('global.print'),
			icon: <Icon icon="print" />,
			onClick: onPrintHandler,
		},
	];

	const onTooltipHandler = (event: MouseEvent) => {
		// console.log('Modern handler called main', event);
		if (typeof onClickMore === 'function') {
			onClickMore({ title });
		}
	};

	const points = [];

	series.forEach((element) => {
		points.push([element.data[0][0], element.data[0][1]]);
		element.symbolSize = 40;
		element.precision = 2;
		element.symbolOffset = [0, 0];
		element.itemStyle = {
			color: 'rgb(4, 72, 110)',
		};
		element.label = {
			fontSize: 14,
			show: true,
			textColor: '#fff',
			borderTextColor: '#fff',
			borderColor: '#fff',
			formatter: (params: DefaultLabelFormatterCallbackParams) => {
				let frequency = 0;
				points.forEach((item) => {
					if (_.isEqual(item, [params.data[0], params.data[1]])) {
						frequency += 1;
					}
				});

				if (frequency > 1) {
					return `+${frequency <= 99 ? frequency - 1 : '+99'}`;
				}

				return element.symbol === 'circle' ? `${params.data[2]?.getInitials()}` : '';
			},
			emphasis: {
				show: true,
				formatter: (params: DefaultLabelFormatterCallbackParams) => {
					let frequency = 0;
					points.forEach((item) => {
						if (_.isEqual(item, [params.data[0], params.data[1]])) {
							frequency += 1;
						}
					});

					if (frequency > 1) {
						return t('global.seeMore');
					}
					return overflowText(params.data[2], 10);
				},
				fontSize: 14,
				padding: [0, 0, 0, 10],
				position: 'right',
			},
		};
	});

	optionBase.xAxis.show = false;
	optionBase.yAxis.show = false;

	if (typeof onClickMore === 'function') {
		optionBase.tooltip = {
			trigger: 'axis',
			axisPointer: {
				type: 'cross',
			},
			showContent: true,
			alwaysShowContent: true,
			enterable: true,
			show: true,
			// hideDelay: 10000,
			align: 'right',
			borderRadius: 50,
			// appendToBody: true,
			className: 'tooltip__matrix',
			triggerOn: 'click',
			backgroundColor: 'var(--air-force-color)',
			renderMode: 'html',
			formatter: (params: DefaultLabelFormatterCallbackParams[]) => {
				if (params.length === 1) {
					return null;
				}

				for (let i = 0; i <= params.length; i += 1) {
					let frequency = 0;
					for (let j = 0; j <= points.length; j += 1) {
						if (_.isEqual([params[i]?.data[0], params[i]?.data[1]], points[j])) {
							frequency += 1;
						}
					}
					if (frequency > 1) {
						return `
						<div  id="tooltip-${params[0].seriesIndex}-dash" index="${params[0].seriesIndex}" class="tooltip__matrix--inner" >
							+${params.length - 1}
						</div>
					`;
					}
				}

				return null;
			},
		};
	}

	const callback = function (mutationList, observer) {
		const btn = mutationList[0]?.target?.firstElementChild;

		if (btn && btn.id?.indexOf('tooltip') > -1) {
			btn?.removeEventListener('click', onTooltipHandler);
			btn?.addEventListener('click', onTooltipHandler);
		}
	};

	useEffect(() => {
		// https://echarts.apache.org/en/option.html#tooltip
		// https://echarts.apache.org/v4/en/option.html#tooltip.formatte

		const btn = document.querySelector(`#${id}-matrix .chart__matrix`);

		if (btn && typeof onClickMore === 'function') {
			const observer = new MutationObserver(callback);
			// observer.disconnect();
			observer.observe(btn, { childList: true, attributes: false, subtree: true });
		}
	}, []);

	const option = { ...optionBase };

	option.series = [...optionBase.series, ...series];

	return (
		<MatrixGrid
			id={`${id}-matrix`}
			ref={printRef}
			className={`matrix__base ${expanded} ${className} `}
			{...rest}
		>
			<Box className={`matrix ${isLoading ? 'loading' : ''}`} data-tut={`dashboard-${id}`}>
				<Box className="matrix__header">
					<Typography variant="h5">{title}</Typography>
					<SMenu
						id={`${id}-menu`}
						open={Boolean(anchorEl)}
						anchorEl={anchorEl}
						setAnchorEl={setAnchorEl}
						options={options}
					>
						<IconButton color="primary" size="small">
							<Icon icon="ellipsis" />
						</IconButton>
					</SMenu>
				</Box>
				<Box id={`echart-matrix-base-${id}`} className="matrix__body">
					<ReactEcharts
						// ref={(e) => setRef(e)}
						option={option}
						// notMerge
						// echarts={echarts}
						// showLoading={isLoading}
						lazyUpdate
						opts={{ height: expanded === classes?.zoomIn ? 700 : 600 }}
						style={{ height: expanded === classes?.zoomIn ? 700 : 600 }}
						className="chart__matrix"
					/>
					{Object.keys(quadrants).map((key) => (
						<p key={key} className={key}>
							{t(quadrants[key])}
						</p>
					))}
					<p
						className="matrix__params"
						style={{
							top: '30px',
							left: '10px',
							transformOrigin: 'top left',
							transform: 'rotate(-90deg) translate(-100%)',
						}}
					>
						{t('sort.high')}
					</p>
					<p
						className="matrix__params"
						style={{ bottom: '33px', left: '7px', transform: 'rotate(-90deg)' }}
					>
						{t('sort.low')}
					</p>
					<p
						className="matrix__params"
						style={{ bottom: '10px', left: '30px', transformOrigin: 'top left' }}
					>
						{t('sort.low')}
					</p>
					<p
						className="matrix__params"
						style={{ bottom: '10px', right: '30px', transformOrigin: 'top left' }}
					>
						{t('sort.high')}
					</p>
				</Box>
				<Box className="matrix__footer">
					<IconButton
						type="button"
						size="small"
						sx={{ border: 0 }}
						disabled={isLoading}
						color="primary"
						onClick={() => setExpanded(expanded === classes?.zoomIn
							? classes?.zoomOut : classes?.zoomIn)}
					>
						<Icon
							className="mr-0"
							icon={expanded === classes?.zoomIn
								? 'fa fa-minimize' : 'fa fa-expand'}
						/>
					</IconButton>
				</Box>
			</Box>
		</MatrixGrid>
	);
};
// https://wallacemaxters.com.br/blog/16/como-redimensionar-uma-imagem-com-javascript

const Translation = withTranslation('common')(_MatrixBase);

const MatrixBase = (props: MatrixBaseProps) => (
	<Translation {...props} />
);

MatrixBase.defaultProps = {
	hasOptions: false,
	title: '',
	isLoading: false,
	onClickMore: undefined,
	classes: undefined,
};

export { MatrixBase };
