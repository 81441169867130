import { Creators } from '@actions';
import { DynamicCircle, DynamicCircleProps, TSizeCircle } from '@components/DynamicCircle';
import ModalFeedback, { IFeedbackProps } from '@components/Modals/ModalFeedback';
import RedactorText from '@components/Shared/RedactorText';
import { UserUpdated } from '@components/Shared/UserUpdated';
import { BuildTour } from '@components/STour';
import { SToast } from '@modules/SToast';
import { useTour } from '@reactour/tour';
import { APIFeatures } from '@services/apis';
import { handleAxiosErrors } from '@services/auth';
import { createBackground } from '@utils/helpers';
import React, { Suspense, useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { IFeature, IIdea } from 'src/@types/IIdea';
import { IPagination } from 'src/@types/IShared';
import { IRootState } from 'src/store';
import { Box, List, ListItem, Typography } from '@mui/material';
import { SBox } from './SBox';

export declare type TFeatureActions = 'edit' | 'view' | 'support' | 'insert'
	| 'add' | 'remove' | undefined

interface BFeaturesProps {
	readonly mode: TFeatureActions
	CreateProps?: DynamicCircleProps
	readonly features: IPagination<IFeature>
	hasEmptyState?: boolean
	routes: { features?: string, feature: string, sponsors?: string }
	size?: TSizeCircle
	id: string
	className?: string
	components?: {title: React.ReactNode}
	orientation?: 'vertical' | 'horizontal'
	onInsert?: (featureId: string, callback: Function) => void
	updateIdea?: (idea: IIdea) => void
	idea: IIdea,
}

declare type TProps = BFeaturesProps & WithTranslation

const _BFeatures = (props: TProps) => {
	const {
		t, CreateProps, features, hasEmptyState, routes, mode, size, idea, components,
		id, className, orientation = 'horizontal', onInsert, updateIdea,
	} = props;

	const { userOrganizationProfile } = useSelector((state: IRootState) => state);
	const tour = useTour();
	const dispatch = useDispatch();
	const [feedbackProps, setFeedbackProps] = useState<IFeedbackProps>({ open: false });
	const [inProgress, setInProgress] = useState(['', '']);

	const onDeleteHandler = async (feature: IFeature) => {
		setFeedbackProps({
			...feedbackProps,
			title: t('feedback.delete.feature.title', { feature: `${feature.nome}` }),
			rightButtonText: t('global.delete'),
			open: true,
			color: 'error',
			onRightButton: async () => {
				try {
					setFeedbackProps((params) => ({ ...params, isLoading: true }));
					await APIFeatures.deleteSupport(feature._id);

					SToast.success(t('messages.success.feature.removed'));

					dispatch(
						Creators.IDEAS.getIdea(
							{
								ideaId: idea._id,
								onSuccess: (data: IIdea) => {
									if (typeof updateIdea === 'function') {
										updateIdea(data);
									}
								},
							},
						),
					);

					setFeedbackProps({ open: false });
				} catch (err: any) {
					handleAxiosErrors(err);
				} finally {
					setFeedbackProps((params) => ({ ...params, isLoading: false }));
				}
			},
		});
	};

	const onSupportHandler = async (feature: IFeature) => {
		setFeedbackProps({
			...feedbackProps,
			title: t('idea.fundings.featureToBeSponsored', { feature: feature.nome }),
			rightButtonText: t('global.confirm'),
			open: true,
			color: 'success',
			onRightButton: async () => {
				try {
					setFeedbackProps((params) => ({ ...params, isLoading: true }));
					const response = await APIFeatures.postSupportFeature(feature._id);

					switch (response.status) {
						case 204:
							SToast.warn(t('idea.fundings.messages.alreadySponsor'));
							break;
						case 200:
							SToast.success(t('idea.fundings.messages.successSponsored'));
							break;
						default:
							break;
					}

					dispatch(
						Creators.IDEAS.getIdea(
							{
								ideaId: idea._id,
								onSuccess: (data: IIdea) => {
									if (typeof updateIdea === 'function') {
										updateIdea(data);
									}
								},
							},
						),
					);
					setFeedbackProps({ open: false });
				} catch (err) {
					handleAxiosErrors(err);
				} finally {
					setFeedbackProps((params) => ({ ...params, isLoading: false }));
				}
			},
		});
	};

	const onViewHandler = (feature: IFeature, action: TFeatureActions) => {
		if (!action) return;

		BuildTour.close(tour);

		if (action === 'add' && typeof onInsert === 'function') {
			setInProgress(['add', feature._id]);
			onInsert(feature._id, () => setInProgress(['', '']));
			return;
		}

		if (action === 'support') {
			onSupportHandler(feature);
			return;
		}

		if (action === 'remove') {
			onDeleteHandler(feature);
			return;
		}

		dispatch(Creators.FEATURES.setFeature(feature));
		dispatch(Creators.MODAL.showModal(routes.feature, { action }));
	};

	return (
		<SBox
			id={id}
			className={`background__features ${className || ''}`}
			loading={features.isLoading || !features}
			empty={features.docs?.length === 0 && hasEmptyState ? 'features' : undefined}
		>
			{components?.title}
			<List className={orientation === 'vertical' ? 'list' : 'horizontal-list'}>
				{CreateProps && (
					<ListItem className="list-item" data-tut="feature-create">
						<DynamicCircle
							icon="plus"
							className="default-colors"
							size={size || 'middle'}
							clickable
							onClick={() => {
								BuildTour.close(tour);
								dispatch(Creators.MODAL.showModal(routes.features));
							}}
							{...CreateProps}
						/>
						<Typography variant="caption" className="item-description">
							{t('global.new')}
						</Typography>
					</ListItem>
				)}
				{features.docs?.map((feature, index) => {
					if (!feature || !feature.cor) return null;
					const action = (function () {
						if (feature.supported
							|| feature.supporters?.find((supporter) => supporter.userOrganizationProfile._id === userOrganizationProfile?._id)) {
							return 'remove';
						}
						if (mode === 'view') return 'view';
						if (mode === 'edit') return 'edit';
						if (mode === 'add') return 'add';
						if (mode === 'support') return 'support';

						return undefined;
					}());

					return (
						<ListItem
							key={feature._id}
							data-tut={`template-${index}-${orientation}`}
							className={`list-item ${(['add'].includes(inProgress[0])
								&& [feature._id].includes(inProgress[1])) ? 'loading' : ''}`}
						>
							<DynamicCircle
								color={feature.cor}
								background={createBackground(feature.cor)}
								icon="feature"
								size={size || 'middle'}
								onClick={action ? () => onViewHandler(feature, action) : undefined}
								action={action ? t(`global.${action}`) : undefined}
							/>
							{orientation === 'horizontal' && (
								<Typography variant="caption" className="item-description">{feature.nome}</Typography>
							)}
							{orientation === 'vertical' && (
								<div
									aria-hidden
									className="container-content"
									onClick={mode === 'add'
										? () => onViewHandler(feature, 'insert')
										: undefined}
									style={{ cursor: mode === 'add' ? 'pointer' : 'auto' }}
								>
									<Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
										<span className="title">{feature.nome}</span>
										<span>
											<span>{t('global.priority')}</span>
											:
											{' '}
											<span className="first-letter-capitalize">{feature.prioridade}</span>
										</span>
										<span className="mb-2">
											<span>{t('global.budget')}</span>
											:
											{' '}
											<span>
												{Intl.NumberFormat('pt-BR', {
													style: 'currency',
													currency: 'BRL',
												}).format(feature.budget)}
											</span>
										</span>
									</Box>
									<RedactorText html={feature.resumo} hasToggle className="content-text" />
									{Array.isArray(feature?.supporters) && feature.supporters.length > 0 && (
										<UserUpdated
											prefixBy={t('global.sponsored')}
											// @ts-ignore
											group={feature.supporters.map((item) => item.userOrganizationProfile)}
											updatedAt={feature.lastSupport}
											prefixByUpdatedAt={t('idea.fundings.lastSponsorship')}
											className="justify-content-between"
											onMore={() => dispatch(
												Creators.MODAL.showModal(
													routes.sponsors,
													{ featureId: feature._id },
												),
											)}
											prefixTarget="feature_updated"
											hasPopover
										/>
									)}
									{/* <button className="" onClick={() => dispatch(
										Creators.MODAL.showModal(routes.MODAL_SPONSORS_FOR_FEATURE.path,
											{ featureId: feature._id })
									)}>Sponsors</button> */}
								</div>
							)}
						</ListItem>
					);
				})}
			</List>
			{feedbackProps.open && (
				<ModalFeedback
					id="feature-sponsor"
					onRequestClose={() => setFeedbackProps({ open: false })}
					{...feedbackProps}
				/>
			)}

		</SBox>
	);
};

const Translation = withTranslation('common')(_BFeatures);

const BFeatures = (props: BFeaturesProps) => (
	<Suspense fallback={<div />}>
		<Translation {...props} />
	</Suspense>
);

export { BFeatures };
