// @ts-nocheck

import * as Yup from 'yup';
import i18next from 'i18next';
import URLS from '@constants/URLS';
import { Creators } from '@actions';
import { IRootState } from 'src/store';
import { SButton } from '@components/Buttons';
import { Trans, withTranslation } from 'react-i18next';
import { TLanguages } from 'src/@types/IShared';
import { getValidationErrors } from '@utils/yup';
import { APIUnregistered } from '@services/apis';
import { SLink } from '@components/Buttons/SLink';
import { useNavigate } from 'react-router-dom';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { goToLink, isEmpty } from '@utils/ReactUtils';
import { useDispatch, useSelector } from 'react-redux';
import { ISMenuOption, SMenu } from '@components/SMenu';
import { auth, handleAxiosErrors } from '@services/auth';
import { languages, languagesOptions } from '@utils/index';
import { STextField, SPasswordField } from '@forms/Components';
import { GoogleButton } from '@components/Buttons/GoogleButton';
import {
	useState, Suspense, MouseEvent, useEffect,
} from 'react';
import SpinnerLDSRipple from '@components/Shared/SpinnerLDSRipple';
import { LinkedInButton } from '@components/Buttons/LinkedInButton';
import { Form, SFormGroup, SInputLabel } from '@components/DynamicForm/ComponentsCore';
import {
	Alert,
	Box, Divider, Grid, Typography,
} from '@mui/material';

interface IOwnProps {
	t,
	hasIntegrations: boolean;
	isLandingPage: boolean;
	path: string;
	orgId: string;
	onError: () => {}
}

const LLoginForm = (props: IOwnProps) => {
	const {
		t,
		hasIntegrations = true,
		isLandingPage,
		path,
		orgId,
		onError,
	} = props;

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const [password, setPassword] = useState('');
	const [inProgress, setInProgress] = useState();
	const [errors, setErrors] = useState({});
	const { userOrganizationProfile } = useSelector((state: IRootState) => state);
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [language, setLanguage] = useState(localStorage.getItem('i18nextLng'));

	const [hasPassword, setHasPassword] = useState(false);

	const isLogIn = path === URLS.LOGIN;
	const isSignUp = path === URLS.SIGN_UP;

	const onLoginHandler = async () => {
		const email = userOrganizationProfile?.user?.email;
		try {
			setInProgress('login');
			const schema = Yup.object().shape({
				email: Yup.string().email(t('messages.email.isInvalid')).required(t('messages.requiredField')),
				password: Yup.string().required(t('messages.requiredField')),
			});

			await schema.validate({ email, password }, {
				abortEarly: false,
			});

			const response = await APIUnregistered.postLogin({ email, password, language });
			auth.setToken(response.data.token);
			goToLink(URLS.HOME);
		} catch (err) {
			if (err instanceof Yup.ValidationError) {
				setErrors(getValidationErrors(err));
				return;
			}

			switch (err?.response?.status) {
				case 401:
					if (['messages.error.noOrg.title'].includes(err.response.data.err)) {
						navigate(URLS.CREATE_ORGANIZATION);
					}
					break;
				case 409:
					setErrors({ ...errors, message: err.response.data.err, code: 409 });
					setHasPassword(true);
					// messages.token.verified
					break;
				case 403:
					if (err.response.data.err === 'messages.email.notRegistered') {
						setErrors({ ...errors, email: t(err.response.data.err) });
					}
					if (['messages.invalidUserPasswordCombination', 'messages.accountHasNotBeenVerified'].includes(err.response.data.err)) {
						setErrors({ ...errors, message: err.response.data.err });
					}

					break;
				case 404:
					if (typeof onError === 'function') onError({ code404: true, email });
					break;
				default:
					handleAxiosErrors(err);
					setErrors({ ...errors, message: err?.response?.data?.err });
					break;
			}
		} finally {
			setInProgress(undefined);
		}
	};

	const onRegisterHandler = async () => {
		try {
			setInProgress('register');
			const email = userOrganizationProfile?.user?.email;
			const schema = Yup.object().shape({
				email: Yup.string().email(t('messages.email.isInvalid')).required(t('messages.email.enter')),
			});

			await schema.validate({ email }, {
				abortEarly: false,
			});

			let response;

			if (isLandingPage) {
				response = await APIUnregistered.postEmailLandingPage({ email, org: orgId });
			} else response = await APIUnregistered.postEmail({ email, language });

			dispatch(Creators.USER_ORGANIZATION.handleUser({ email: response.data.email }));
			navigate(`${URLS.SIGN_UP}/token?email=${email}`);
		} catch (err) {
			if (err instanceof Yup.ValidationError) {
				setErrors(getValidationErrors(err));
				return;
			}

			if (err?.response?.status) {
				switch (err.response?.status) {
					case 409:
						setErrors({ ...errors, email: t(err.response.data.err), code: 409 });
						if (err.response.data.err !== 'register.messages.409' && isLandingPage) {
							setHasPassword(true);
						}
						break;
					default:
						setErrors({ ...errors, message: err.response.data.err, code: err.response.status });
						break;
				}
			}
		} finally {
			setInProgress(undefined);
		}
	};

	const onSubmitHandler = (e) => {
		e?.preventDefault();
		if (isSignUp) {
			onRegisterHandler();
		}
		if (isLogIn || hasPassword) {
			onLoginHandler();
		}
	};

	const onErrorHandler = (field) => {
		if (!isEmpty(errors)) delete errors[field];
	};

	const handleValue = (e) => {
		onErrorHandler(e.target.name);
		onErrorHandler('message');
		onErrorHandler('code');
		switch (e.target.name) {
			case 'password':
				setPassword(e.target.value);
				break;
			case 'email':
				dispatch(Creators.USER_ORGANIZATION.handleUser({ email: e.target.value }));
				break;
			default:
				break;
		}
	};

	const handleKeyUp = (e) => {
		if (e?.keyCode === 13) document.querySelector('#password')?.focus();
	};

	const handleLanguage = (option: ISMenuOption) => {
		setLanguage(option.value);
		i18next.changeLanguage(option.value);
		localStorage.setItem('i18nextLng', option.value);
	};

	// When the account is not verified
	const onResendTokenHandler = (event: MouseEvent<HTMLElement>) => {
		event.preventDefault();
		setInProgress('token');

		const email = userOrganizationProfile?.user?.email;

		dispatch(Creators.USER_ORGANIZATION.resendToken({
			email,
			onError: (err: string) => setErrors({
				message: t(err),
				messageCode: err,
			}),
			onSuccess: () => {
				dispatch(Creators.USER_ORGANIZATION.handleUser({ email }));
				setInProgress(undefined);
				navigate(URLS.TOKEN);
			},
		}));
	};

	useEffect(() => {
		setErrors({});
	}, [isLogIn, isSignUp]);

	const openMenu = Boolean(anchorEl);

	return (
		<Form onSubmit={onSubmitHandler} className="s-form " autocomplete="off">
			<SFormGroup>
				<SInputLabel htmlFor="email" error={typeof errors?.email === 'string'}>
					E-mail
				</SInputLabel>
				<STextField
					name="email"
					type="email"
					onKeyUp={handleKeyUp}
					autoFocus
					id="email"
					value={userOrganizationProfile?.user?.email}
					error={typeof errors?.email === 'string'}
					helperText={errors?.email}
					onChange={handleValue}
					tabIndex="0"
					placeholder={t('form.placeholder.yourEmail')}
				/>
			</SFormGroup>
			{(isLogIn || hasPassword) && (
				<SFormGroup>
					<SInputLabel htmlFor="password" error={typeof errors?.password === 'string'}>
						{t('form.password')}
					</SInputLabel>
					<SPasswordField
						name="password"
						id="password"
						value={password}
						error={typeof errors?.password === 'string'}
						helperText={errors?.password}
						onChange={handleValue}
						tabIndex="0"
						placeholder={t('form.placeholder.yourPassword')}
					/>
					{
						(isLogIn || (isLandingPage && errors?.code === 409))
							&& (
								<SLink
									onClick={() => navigate(URLS.RECOVER)}
									underline="hover"
									sx={{
										fontSize: '14px', fontWeight: '700', color: 'rgba(0, 0, 0, 0.75)', cursor: 'pointer',
									}}
								>
									{t('form.login.forgotPassword')}
								</SLink>
							)
					}
				</SFormGroup>
			)}
			{errors?.message && errors.message === 'messages.accountHasNotBeenVerified' && (
				<Alert severity="warning" sx={{ button: { verticalAlign: 'baseline' } }}>
					<Trans
						i18nKey="messages.accountHasNotBeenVerified"
						components={{
							1: (
								<SLink
									component="button"
									underline="always"
									onClick={onResendTokenHandler}
									isLoading={inProgress === 'token'}
								/>
							),
						}}
					/>
				</Alert>
			)}
			<SButton
				type="submit"
				onClick={onSubmitHandler}
				variant="outlined"
				color="primary"
				isLoading={['register', 'login'].includes(inProgress)}
				tabIndex="0"
				disabled={isLogIn || hasPassword
					? (userOrganizationProfile?.user?.email.length < 1 || password.length < 1)
					: userOrganizationProfile?.user?.email.length < 1}
				error={!isEmpty(errors)}
			>
				{isLogIn || hasPassword ? t('register.logIn') : `${t('register.continueWith')} e-mail`}
			</SButton>
			<Box
				sx={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'space-between',
					marginY: 6,
				}}
			>
				<Divider orientation="horizontal" sx={{ height: '100%', width: '45%' }} />
				<Typography className="SeparatorRow-label LoginContent-separatorRowLabel--reskin LoginContent-separatorRowLabel">
					{t('global.or')}
				</Typography>
				<Divider orientation="horizontal" sx={{ height: '100%', width: '45%' }} />
			</Box>
			{hasIntegrations
					&& (
						<>
							<GoogleButton
								org={orgId}
								onError={(response) => onError(response)}
							>
								{`${t('register.continueWith')} Google`}
							</GoogleButton>
							<LinkedInButton
								orgId={orgId}
								onError={(response) => onError(response)}
							>
								{`${t('register.continueWith')} LinkedIn`}
							</LinkedInButton>
						</>
					)}
			<Grid
				container
				item
				xs={12}
				sx={{
					marginY: 3,
					display: 'flex',
					justifyContent: 'center',
				}}
			>
				<SMenu
					id="languages-menu"
					open={openMenu}
					anchorEl={anchorEl}
					setAnchorEl={setAnchorEl}
					options={languagesOptions}
					onChange={handleLanguage}
				>

					<SButton
						className={`arrow ${openMenu ? 'down' : 'up'}`}
						size="large"
						endIcon={<MdKeyboardArrowDown />}
					>
						{languages[i18next.language as TLanguages]?.label}
					</SButton>
				</SMenu>
			</Grid>
		</Form>
	);
};

const Translation = withTranslation('common')(LLoginForm);

const LogInForm = (props) => (
	<Suspense fallback={<SpinnerLDSRipple />}>
		<Translation {...props} />
	</Suspense>
);

export { LogInForm };
