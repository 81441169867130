/* eslint-disable no-underscore-dangle */
// @ts-nocheck
import React, { Suspense, useState } from 'react';
import { WithTranslation, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import URLS from '@constants/URLS';
import { UserAvatar } from '@components/Avatar/UserAvatar';
import { IPaginatedRequest, IPagination } from 'src/@types/IShared';
import { IUserOrganization } from 'src/@types/IUser';
import { SButton } from '@components/Buttons';
import { Box, BoxProps, Grid } from '@mui/material';
import { SBox } from '@components/Background/SBox';
import { ActivityChip } from '../Shared/ActivityChip';
import { Sticker } from '../Stickers/Sticker';
import { IListRequest } from './types';

interface MembersListProps extends BoxProps {
	loadMore?: (params: IListRequest) => void
	members: IPagination<IUserOrganization>
	isChallenge?: boolean
	isBrainstorm?: boolean
	areCoauthors?: boolean
	areTeams?: boolean
	queryParams?: IPaginatedRequest
}

declare type TProps = MembersListProps & WithTranslation

const MMembersList = (props: TProps) => {
	const { t } = useTranslation();
	const {
		id, members,
		isBrainstorm, areTeams,
		areCoauthors, isChallenge, loadMore,
		className = 'dark', queryParams, ...rest
	} = props;

	const [isLoadingMore, setIsLoadingMore] = useState(false);

	const loadedMessage = () => {
		if (areCoauthors) return <span>{t('messages.loadedAll.co-authors')}</span>;
		if (isBrainstorm || isChallenge || areTeams) return <span>{t('messages.loadedAll.participants')}</span>;
		return '';
	};

	const loadMoreHandler = () => {
		setIsLoadingMore(true);
		loadMore({
			...queryParams,
			page: members.nextPage,
			callback: setIsLoadingMore(false),
		});
	};

	return (
		<SBox
			id="membersList"
			loading={!members || members.isLoading || !members.docs}
			empty={members.docs.length === 0 ? 'members' : undefined}
			{...rest}
		>
			<ul className="members__list" id={id || 'members_list'}>
				{members.docs?.map((member) => (
					<li key={member._id}>
						<div className="d-flex">
							<UserAvatar
								profile={member}
								size="middle"
								hasPopover
								target={`member_${member._id}`}
								hasHref
							/>
							<div className="member__info">
								<Link to={`${URLS.PROFILE}/${member._id}`} className="name">{member.user._nome || member.user.nome}</Link>
								{member.createdAt && (
									<span className="font-light">
										{t('global.joinedIn', { date: new Date(member.createdAt) })}
									</span>
								)}
							</div>
						</div>
						<Box className="right has-tablet" sx={{ gap: 1 }}>
							{isBrainstorm && (
								<ActivityChip
									tooltip={t('global.ideas')}
									count={member.ideias_count}
									icon="idea"
									className={className || ''}
									target={`member_ideas_${member._id}`}
								/>
							)}
							{areTeams && (
								<>
									<ActivityChip
										tooltip={t('global.brainstorms')}
										className={className}
										count={member.plataformasQueParticipa_count}
										icon="brainstorm"
										target={`member-brainstorms-count-${member._id}`}
									/>
									<ActivityChip
										tooltip={t('global.challenges')}
										className={className}
										count={member.plataformasQueParticipa_count}
										icon="challenge"
										target={`member-challenges-count-${member._id}`}
									/>
									<ActivityChip
										tooltip={t('global.ideas')}
										count={member.ideias_count}
										icon="idea"
										className={className || ''}
										target={`member-ideas-count-${member._id}`}
									/>
								</>
							)}
							{isChallenge && (
								<>
									{member.isAgente && (
										<>
											<Sticker label={t('global.agent')} className="agente" />
											<ActivityChip
												tooltip={t('global.ideas')}
												count={member.ideias_count}
												icon="idea"
												className={className || ''}
												target={`member_ideas_${member._id}`}
											/>
										</>
									)}
									{member.isModerador && (
										<>
											<Sticker label={t('global.moderator')} className="moderador" />
											<ActivityChip
												tooltip={t('global.evaluations')}
												count={member.ideias_avaliadas_count}
												className={className}
												icon="fas fa-solid fa-clipboard-check"
												target={`member_evalutions_${member._id}`}
											/>
										</>
									)}
									{member.isSponsor && (
										<>
											<Sticker label={t('global.sponsor')} className="sponsor" />
											<ActivityChip
												className={className}
												tooltip={t('global.ideas')}
												count={member.ideias_apoiadas_count}
												icon="fas fa-solid fa-gem"
												target={`member_ideas_sponsor_${member._id}`}
											/>
										</>
									)}
								</>
							)}
						</Box>
					</li>
				))}
			</ul>
			<Grid container className="footer__list" marginY={6}>
				<Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
					{members.hasNextPage && typeof loadMore === 'function' ? (
						<SButton
							type="button"
							isLoading={isLoadingMore}
							variant="outlined"
							color="secondary"
							onClick={loadMoreHandler}
						>
							{t('global.loadMore')}
						</SButton>
					) : loadedMessage()}
				</Grid>
			</Grid>
		</SBox>
	);
};

const MembersList = (props: MembersListProps) => (
	<Suspense fallback={<div />}>
		<MMembersList {...props} />
	</Suspense>
);

export { MembersList };
