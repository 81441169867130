// @ts-nocheck
import { Suspense, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { withTranslation, WithTranslation } from 'react-i18next';
import { IIdea } from 'src/@types/IIdea';
import { isEmpty } from '@utils/ReactUtils';
import { APIPlatforms } from '@services/apis';
import { IPlatform } from 'src/@types/IPlatform';
import { handleAxiosErrors } from '@services/auth';
import { SFormGroup, SInputLabel } from '@components/DynamicForm/ComponentsCore';
import { SPasswordField } from '@components/DynamicForm/Components';
import ModalFeedback, { IFeedbackProps } from '@components/Modals/ModalFeedback';

import { SButton } from './SButton';
import { SToast } from '@modules/SToast';

declare type TReceiveMode = 'invite' | 'leave' | 'join' | 'authenticate'

interface IReceiveButtonProps extends HTMLButtonElement {
	feedbackOptions?: IFeedbackProps
	updatePlatform?: (platform: IPlatform) => void
	updateIdea?: (platform: IIdea) => void
	mode: TReceiveMode
	color: 'inherit' | 'primary'
}

declare type TProps = IReceiveButtonProps & WithTranslation

const _ReceiveButton = (props: TProps) => {
	const {
		t, className = '', feedbackOptions, mode, children, color = 'inherit', ...rest
	} = props;

	const flowT = t(`global.${feedbackOptions?.match?.flow}`);
	const [password, setPassword] = useState<string>('');
	const [feedbackProps, setFeedbackProps] = useState<IFeedbackProps>({
		open: false,
		color: 'success',
		...feedbackOptions,
	});

	const [errors, setErrors] = useState<string>({});

	const navigate = useNavigate();

	const onPlatformReceiveHandler = async () => {
		setFeedbackProps((prevState) => ({ ...prevState, isLoading: true }));

		let key;
		let response = {};

		const { match } = feedbackProps;

		if (match?.permission === 'secreta' && password.length === 0) {
			setErrors({ password: t('messages.requiredField') });
			return;
		}

		try {
			switch (mode) {
				case 'join':
					response = await APIPlatforms.postJoinPlatform(match.platformId, password);
					key = 'joined';
					break;

				case 'authenticate':
					if (match?.permission === 'secreta' && match.passwordRecurrency === 'access') {
						response = await APIPlatforms.postSecretPassword(match.platformId, password);
						response.data.wasAuthenticated = true;
						key = 'authenticated';
					}
					break;
				case 'invite':
					response = await APIPlatforms.postAcceptInvite(match.platformId, password);
					key = 'joined';
					break;
				case 'leave':
					response = await APIPlatforms.postLeavePlatform(match.platformId);
					key = 'leave';

					if (match?.permission === 'secreta') {
						navigate(-1);
					}

					break;
				default:
					break;
			}

			SToast.success(t(`messages.success.platform.${key}`, { type: flowT }));

			if (typeof match.updatePlatform === 'function' && match.platformId) {
				match.updatePlatform(response.data);
			}
			if (typeof match.updateIdea === 'function' && match.ideaId) {
				match.updateIdea({
					_id: match.ideaId,
					plataforma: response.data,
				});
			}
			setFeedbackProps((prevState) => ({ ...prevState, open: false }));
		} catch (err: any) {
			if (['messages.error.platform.join'].includes(err.response?.data?.err)) {
				SToast.error(t(err.response.data.err, { type: flowT }));
				return;
			}
			handleAxiosErrors(err);
		} finally {
			setFeedbackProps((prevState) => ({ ...prevState, isLoading: false }));
		}
	};

	return (
		<>
			<SButton
				type="button"
				color={color}
				variant="outlined"
				size="large"
				className={`join__button ${className}`}
				{...rest}
				onClick={(event) => {
					event.stopPropagation();
					setFeedbackProps((prevState) => ({
						...prevState, open: true,
					}));
				}}
			>
				{children || t(`feedback.receive.${mode}.label`)}
			</SButton>
			{feedbackProps.open && (
				<ModalFeedback
					id="join"
					open={feedbackProps.open}
					onRequestClose={() => setFeedbackProps((prevState) => ({
						...prevState, open: false,
					}))}
					title={t(`feedback.receive.${mode}.title`, { label: flowT })}
					text={['publica', 'privada'].includes(feedbackOptions?.match?.permission)
						&& t(`feedback.receive.${mode}.text`, { label: flowT })}
					onRightButton={onPlatformReceiveHandler}
					rightButtonText={t(`feedback.receive.${mode}.label`)}
					isLoading={feedbackProps.isLoading}
					container={feedbackProps.container}
					rightButtonDisabled={feedbackOptions?.match?.permission === 'secreta' && password.length < 1}
				>
					{(feedbackOptions?.match?.permission === 'secreta'
						|| feedbackOptions?.match.passwordRecurrency === 'access')
						&& (
							<div className="s-form">
								<SFormGroup>
									<SInputLabel htmlFor="secret-platform" error={typeof errors?.password === 'string'}>
										{t('feedback.receive.authenticate.text', { label: flowT })}
									</SInputLabel>
									<SPasswordField
										id="secret-platform"
										autoFocus
										value={password}
										helperText={errors?.password}
										placeholder={t('form.placeholder.flow.password', { type: flowT })}
										onKeyDown={(e) => e.key === 'Enter' && onPlatformReceiveHandler()}
										onChange={(e) => {
											if (!isEmpty(errors)) delete errors.password;
											setPassword(e.target.value);
										}}
										error={typeof errors?.password === 'string'}
									/>
								</SFormGroup>
							</div>
						)}
				</ModalFeedback>
			)}
		</>
	);
};

const Translation = withTranslation('common')(_ReceiveButton);

const ReceiveButton = (props: IReceiveButtonProps) => (
	<Suspense fallback={<div />}>
		<Translation {...props} />
	</Suspense>
);

export default ReceiveButton;
