// @ts-nocheck
import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation, WithTranslation } from 'react-i18next';
import { IPagination, TFlow } from 'src/@types/IShared';
import { IForm } from 'src/@types/IForm';
import { createBackground } from '@utils/helpers';
import { Creators } from '@actions';
import RedactorText from '@components/Shared/RedactorText';
import { DynamicCircle, DynamicCircleProps, TSizeCircle } from '@components/DynamicCircle';
import { useTour } from '@reactour/tour';
import { BuildTour } from '@components/STour';
import {
	BoxProps, List, ListItem, Typography,
} from '@mui/material';
import { SBox } from './SBox';

export declare type TFormActions = 'edit' | 'answer' | 'view' | 'add'

interface BFormsProps extends BoxProps {
	id: string
	readonly mode: TFormActions
	readonly flow: TFlow
	components?: { title?: React.ReactNode }
	readonly forms: IPagination<IForm>
	routes: { forms?: string, form: string, nav?: string }
	CreateProps?: DynamicCircleProps
	size?: TSizeCircle
	hasEmptyState?: boolean
	orientation?: 'vertical' | 'horizontal'
	rootPath?: string
	className?: string
	onInsert?: (formId: string, callback: Function) => void
	isDisabled?: boolean
}

declare type TProps = BFormsProps & WithTranslation;

const BForms = (props: TProps) => {
	const { t } = useTranslation();
	const {
		forms, mode, flow, orientation = 'horizontal', rootPath, components,
		routes, CreateProps, size, hasEmptyState, id, className, isDisabled = false,
		onInsert, ...rest
	} = props;
	const tour = useTour();
	const dispatch = useDispatch();
	const [inProgress, setInProgress] = React.useState(['', '']);

	const onViewHandler = (form: IForm, action: TFormActions, index: number) => {
		BuildTour.close(tour);
		dispatch(Creators.MODAL.handleModal({ flow }));
		if (action === 'add' && typeof onInsert === 'function') {
			setInProgress(['add', form._id]);
			onInsert(form._id, () => setInProgress(['', '']));
			return;
		}

		if (routes.nav) {
			dispatch(Creators.MODAL.showModal(
				routes.nav,
				{
					rootPath,
					action: 'view',
					currentPage: index,
					currentModel: form,
					pagingPaths: forms.docs.map((item) => ({
						pagePath: routes.nav,
						pageModel: item,
					})),
				},
			));
			return;
		}
		dispatch(Creators.FORMS.setForm(form));
		dispatch(Creators.MODAL.showModal(routes.form, { action }));
	};

	return (
		<SBox
			id={id}
			className={`background__forms ${className || ''}`}
			loading={forms.isLoading || !forms || !flow}
			empty={forms.docs?.length === 0 && hasEmptyState ? 'forms' : undefined}
			{...rest}
		>
			{components?.title}
			<List className={orientation === 'vertical' ? 'list' : 'horizontal-list'}>
				{CreateProps && (
					<ListItem className="list-item" key="new-form" data-tut="form-create">
						<DynamicCircle
							icon="plus"
							size={size || 'middle'}
							className="default-colors"
							clickable={!['add'].includes(inProgress[0])}
							disabled={['add'].includes(inProgress[0]) || isDisabled}
							onClick={() => {
								BuildTour.close(tour);
								dispatch(Creators.MODAL.showModal(routes.forms));
							}}
							{...CreateProps}
						/>
						{orientation === 'vertical' && (
							<div className="container-content">
								<div className="title">
									{CreateProps.title}
								</div>
								<div className="content-text">
									{t('form.features.blank.introduction')}
								</div>
							</div>
						)}
						{orientation === 'horizontal' && (
							<Typography variant="caption" className="item-description">
								{CreateProps.title}
							</Typography>
						)}

					</ListItem>
				)}
				{forms?.docs?.map((form, index) => {
					const action: TFormActions = (function () {
						if (mode === 'answer' || (form.isHeritage && mode === 'edit')) {
							return 'answer';
						}
						if (mode === 'add') return 'add';

						if (mode === 'view' || form.template?.isDefault) {
							return 'view';
						}

						return 'edit';
					}());

					return (
						<ListItem
							data-tut={`template-${index}-${orientation}`}
							key={form._id}
							className={`list-item ${(['add'].includes(inProgress[0])
								&& [form._id].includes(inProgress[1])) ? 'loading' : ''}`}
						>
							<DynamicCircle
								icon="form"
								size={size || 'middle'}
								color={form.color}
								background={createBackground(form.color)}
								action={t(`global.${action}`)}
								onClick={() => onViewHandler(form, action, index)} // Ony Edit ??
								clickable={!['add'].includes(inProgress[0])}
								disabled={['add'].includes(inProgress[0])}
							/>
							{orientation === 'vertical' && (
								<div
									aria-hidden
									className="container-content"
									onClick={mode === 'add'
										? () => onViewHandler(form, 'insert')
										: undefined}
									style={{ cursor: mode === 'add' ? 'pointer' : 'auto' }}
								>
									<div className="mb-2 bold">{form?.name}</div>
									<RedactorText html={form?.description} hasToggle className="content-text" />
									{/* {form.moderador
															&& (
																<UserUpdated
																	user={form.moderador.user}
																	updatedAt={form.data_premiacao}
																/>
															)} */}
								</div>
							)}
							{orientation === 'horizontal' && (
								<Typography variant="caption" className="item-description">{form.name}</Typography>
							)}
						</ListItem>
					);
				})}
			</List>
		</SBox>
	);
};

export { BForms };
