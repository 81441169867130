// @ts-nocheck
import React, { useEffect, Suspense, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Creators } from '@actions';
import ModalHeader from '@modules/Modal/ModalHeader';
import { WithTranslation, withTranslation } from 'react-i18next';
import { FLOWS } from '@constants/GLOBAL';
import { APICanvas } from '@services/apis';
import { IModal } from '@modules/Modal/types';
import { IRootState } from 'src/store';
import { DialogBox } from '@modules/Modal/Components';
import { handleAxiosErrors } from '@services/auth';
import { BCanvas } from '@components/Background/BCanvas';
import { DynamicCircle } from '@components/DynamicCircle';
import {
	Box, DialogContent, DialogTitle, List, ListItem, Typography,
} from '@mui/material';
import { BuildTour, STour } from '@components/STour';
import { useTour } from '@reactour/tour';
import { W_GRID } from '@components/Grids/GenericGrid';
import SpinnerLDSRipple from '../../Shared/SpinnerLDSRipple';
import { HelpButton } from '../../Buttons/HelpButton';
import RedactorText from '../../Shared/RedactorText';

type TProps = IModal & WithTranslation

const MModalCanvas = (props: TProps) => {
	const { t, routes } = props;
	const tour = useTour();
	const dispatch = useDispatch();

	const { platforms, ideas, shared } = useSelector((state: IRootState) => state);
	const { flow } = useSelector((state: IRootState) => state.modal);
	const [canvas, setCanvas] = useState({ docs: [], isLoading: true });
	const [templates, setTemplates] = useState({ docs: [], isLoading: true });

	const TOUR_STEPS = [
		{
			id: templates.docs.length > 1 ? 'template-0-vertical' : 'import-canvas',
			selector: templates.docs.length > 1 ? '[data-tut="template-0-vertical"]' : '[data-tut="import-canvas"]',
			title: t(`tour.canvas.${templates.docs.length > 1 ? 'add' : 'import'}.title`),
			description: t(`tour.canvas.${templates.docs.length > 1 ? 'add' : 'import'}.description`),
			action: t(`tour.canvas.${templates.docs.length > 1 ? 'add' : 'import'}.action`),
		},
		{
			id: 'modal-back',
			selector: '[data-tut="modal-back"]',
			title: t('tour.modal.back.title'),
			description: t('tour.modal.back.description'),
			action: t('tour.modal.back.action'),
		},
	];

	const rootPath = {
		idea: ideas?.nome || '...',
		brainstorm: platforms?.nome || '...',
		challenge: platforms?.nome || '...',
	};

	const mode = {
		idea: ideas?.mode,
		brainstorm: platforms.mode,
		challenge: platforms.mode,
	};

	const platformPath = {
		model: routes.MODAL_PLATFORM_CANVAS_MODEL.path,
	};

	const paramsPaths = {
		idea: {
			model: routes.MODAL_IDEA_CANVAS_MODEL.path,
		},
		challenge: platformPath,
		brainstorm: platformPath,
	};

	const onTourHandler = () => {
		if (shared?.tour === FLOWS.IDEA) {
			tour.setCurrentStep(7);
		}
		if (shared.tour === FLOWS.CHALLENGE) {
			tour.setCurrentStep(9);
		}
		if (shared.tour === FLOWS.BRAINSTORM) {
			tour.setCurrentStep(7);
		}
		tour.setIsOpen(false);
	};

	const loadTemplates = async (ids: string[], startTour?: boolean) => {
		try {
			setTemplates({ ...templates, isLoading: true });
			const response = await APICanvas.getTemplates(ids);
			setTemplates({ docs: response.data, isLoading: false });
			if (startTour) {
				console.log('-----------------------');
				BuildTour.setTour(tour, [{
					id: response.data?.length > 1 ? 'template-0-vertical' : 'import-canvas',
					selector: response.data?.length > 1 ? '[data-tut="template-0-vertical"]' : '[data-tut="import-canvas"]',
					title: t(`tour.canvas.${response.data?.length > 1 ? 'add' : 'import'}.title`),
					description: t(`tour.canvas.${response.data?.length > 1 ? 'add' : 'import'}.description`),
					action: t(`tour.canvas.${response.data?.length > 1 ? 'add' : 'import'}.action`),
				}, TOUR_STEPS[1]], { currentStep: 0 });
			}
		} catch (err) {
			handleAxiosErrors(err);
		}
	};

	const loadCanvas = (startTour?: boolean) => {
		setCanvas({ ...canvas, isLoading: true });
		const params = {
			flow,
			ideaId: ideas?._id,
			platformId: platforms?._id,
			onSuccess: (response) => {
				if ([FLOWS.IDEA].includes(flow)) {
					dispatch(Creators.IDEAS.updateIdeas({ canvas: response.docs }));
				}
				if ([FLOWS.CHALLENGE, FLOWS.BRAINSTORM].includes(flow)) {
					dispatch(Creators.PLATFORMS.updatePlatform({ canvas: response.docs }));
				}
				setCanvas({ ...response, isLoading: false });
				loadTemplates(response.docs.map((item) => item.idTemplate), startTour);
			},
		};
		dispatch(Creators.CANVAS.getCanvas(params));
	};

	const onInsertHandler = (templateId, callback) => {
		if (mode[flow] === 'view') return;
		dispatch(Creators.CANVAS.insertCanvas({
			templateId,
			ideaId: ideas._id,
			platformId: platforms._id,
			onSuccess: () => loadCanvas(),
			callback,
		}));
	};

	const onBackHandler = (params) => {
		onTourHandler();
		dispatch(Creators.MODAL.backModal(params));
	};

	const loadMiroBoards = async () => {
		if (mode[flow] === 'view') return;
		const { MIRO_CLIENT_ID } = process.env;
		// @ts-ignore
		miroBoardsPicker.open({
			clientId: MIRO_CLIENT_ID, // TODO: change this
			action: 'access-link',
			success: (result) => {
				console.log('result -->>', result);
				switch (flow) {
					case FLOWS.IDEA:
						dispatch(Creators.IDEAS.handleIdea({
							key: 'miroBoards',
							value: result,
							noDelay: true,
							onSuccess: () => loadCanvas(),
						}));
						break;
					case FLOWS.BRAINSTORM:
					case FLOWS.CHALLENGE:
						dispatch(Creators.PLATFORMS.handlePlatform({
							key: 'miroBoards',
							noDelay: true,
							value: result,
							onSuccess: () => loadCanvas(),
						}));
						break;
					default:
						break;
				}
			},
		});
	};

	useEffect(() => {
		loadCanvas(shared.tour === flow);
	}, []);

	return (
		<DialogContent id="modal-canvas">
			<ModalHeader
				fullScreen
				hideClose
				icon={flow?.toLowerCase()}
				BreadcrumbProps={{ className: W_GRID.W_GRID_2 }}
				onBack={onBackHandler}
				pathParams={{
					idea: rootPath[flow],
					platform: rootPath[flow],
					type: platforms?.type,
					template: ideas?.templateNome,
				}}
				BackModalProps={{ anchor: '#canvas-modal-create' }}
			/>
			<DialogBox className="s-dialog-box inner w-grid-2">
				{!flow ? <SpinnerLDSRipple />
					: (
						<>
							<DialogBox className="s-dialog-title">
								<DialogTitle variant="h1">
									{t(`form.${flow === 'idea' ? 'idea' : 'platform'}.canvas`, { flow: t(`global.${flow}`) })}
								</DialogTitle>
								<HelpButton
									size="large"
									href={t('urls.canvas')}
									onClick={() => BuildTour.setTour(tour, TOUR_STEPS, { currentStep: 0 })}
								>
									Tour
								</HelpButton>
							</DialogBox>
							<DialogBox className="s-dialog-content">
								<BCanvas
									id="canvas"
									canvas={canvas}
									orientation="horizontal"
									routes={paramsPaths[flow]}
									size="x-large"
									flow={flow}
									hasEmptyState
									mode={mode[flow]}
								/>
							</DialogBox>

						</>
					)}
				{templates?.docs?.length > 0 && (
					<Box>
						<DialogBox className="s-dialog-content">
							<Typography variant="h6" fontWeight={700}>
								{t('form.canvas.addInternal')}
							</Typography>
						</DialogBox>
						<DialogBox className="s-dialog-content">
							<BCanvas
								id="canvas-templates"
								canvas={templates}
								routes={paramsPaths[flow]}
								size="x-large"
								flow={flow}
								mode={mode[flow] === 'view' ? 'view' : 'add'}
								onInsert={onInsertHandler}
								orientation="vertical"
							/>
						</DialogBox>
					</Box>
				)}

				{!templates.isLoading && (
					<Box>
						<DialogBox className="s-dialog-content">
							<Typography variant="h6" fontWeight={700}>
								{t('form.canvas.addExternal')}
							</Typography>
						</DialogBox>
						<List className="list" data-tut="import-canvas">
							<ListItem className="align-items-sm-start" key="miro">
								<DynamicCircle
									icon="canvas"
									size="x-large"
									className="miro"
									action={mode[flow] === 'view' ? undefined : t('global.add')}
									onClick={loadMiroBoards}
									clickable={mode[flow] === 'edit'}
								/>
								<div
									className="container-content"
									// onClick={() => onViewHandler(template, 'insert')}
								>
									<div className="title">
										{t('form.canvas.miro.title')}
										{/* <HelpButton size="small" /> */}
									</div>
									<RedactorText html={t('form.canvas.miro.text')} hasToggle className="content-text" />
								</div>
							</ListItem>
						</List>
					</Box>
				)}
			</DialogBox>
		</DialogContent>
	);
};

const Translation = withTranslation('common')(MModalCanvas);

const ModalCanvas = (props: IModal) => (
	<Suspense fallback={<DialogContent />}>
		<Translation {...props} />
	</Suspense>
);
export default ModalCanvas;
