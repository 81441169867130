import React from 'react';
import { Grid, Typography } from '@mui/material';

interface MessageProps {
	readonly htmlContent?: string
	readonly title: string
	readonly content?: string
	readonly button?: React.ReactNode
}

export const MessageBox = ({
	button, title, content, htmlContent,
}: MessageProps) => (
	<Grid
		container
		className="feedback-message"
		spacing={3}
	>
		<Grid container item textAlign="center" spacing={2}>
			<Grid item xs={12}>
				<Typography variant="h1">{title}</Typography>
			</Grid>
			<Grid item xs={12}>
				{
					htmlContent
						? (
							<Typography
								variant="body1"
								dangerouslySetInnerHTML={{
									__html: htmlContent,
								}}
							/>
						)
						: (
							<Typography variant="h6">
								{content}
								{' '}
							</Typography>
						)
				}

			</Grid>
		</Grid>
		<Grid
			item
			xs={12}
			sx={{
				justifyContent: 'center',
				display: 'flex',
				alignItems: 'center',
			}}
		>
			{button}
		</Grid>
	</Grid>
);

