// @ts-nocheck
import React, { useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { getTextFromHTML } from '@utils/helpers';
import Icon from '@components/Shared/Icon';
import { IArticle } from 'src/@types/IShared';
import RedactorText from '@components/Shared/RedactorText';
import { Similarity } from '@components/Signals/Similarity';
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box, Chip, Divider, Grid, IconButton, Tooltip, Typography,
} from '@mui/material';
import { SLink } from '@components/Buttons/SLink';
import { SButton } from '@components/Buttons';
import { MdOutlineClose } from 'react-icons/md';
import { Creators } from '@actions';
import routes from '@routes/modal';
import { useDispatch } from 'react-redux';
import { getArticleDate, getLanguage } from './Helpers';

interface IAccordionTrends {
	article: IArticle
	similarity: number
}

declare type TProps = IAccordionTrends & WithTranslation

const _AccordionArticle = (props: TProps) => {
	const {
		index, className = '', article, t, nlp, similarity,
	} = props;

	const target = `accordion_${article.oid || index}`;
	const dispatch = useDispatch();

	const [expanded, setExpanded] = useState<boolean>(false);

	const [seeMore, setSeeMore] = useState<number>(10);

	const authors = article.authors ? article.authors.map((a) => a.fullName || a.name).join('; ') : '';
	const year = article.date && article.date.year ? article.date.year : '-';

	return (
		<Accordion
			id={article.oid}
			expanded={expanded}
			onChange={(e, _expanded) => setExpanded(_expanded)}
			TransitionProps={{ unmountOnExit: true }}
			className="accordion trends"
			sx={{
				'&.MuiPaper-root': {
					backgroundColor: 'var(--body-background-color)',
					boxShadow: 'none',
					borderBottom: '1px solid var(--fog-gray-color)',
					paddingY: 3,
				},
				'&.MuiPaper-root:before': {
					display: 'none',
				},
				'.redactor-text': {
					padding: '0',
					margin: '0',
				},
			}}
		>
			<AccordionSummary
				sx={{
					padding: 0,
					'&.Mui-expanded': {
						display: 'none',
					},
				}}
				expandIcon={(
					<Tooltip placement="top" className="tooltip-secondary" title={t('global.view')} arrow>
						<IconButton
							className="charcoal-color"
							size="small"
							aria-controls={target}
						>
							<Icon icon="eye" />
						</IconButton>
					</Tooltip>
				)}
			>
				<Grid
					className="header-info"
					container
					sx={{
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'center',
						flexWrap: 'nowrap',
					}}
				>
					<Grid
						item
						container
						className="docs"
						spacing={2}
						xs={6}
						sx={{
							display: 'flex',
							flexDirection: 'column',
						}}
					>
						<Grid item xs>
							{article.contentType && (
								<Typography className={`trends__category ${article.contentType}`}>
									{t(`trends.contentType.${article.contentType}`)}
								</Typography>
							)}
						</Grid>
						<Grid item xs>
							<Typography
								variant="h6"
								className="article__title"
								sx={{
									fontWeight: '400',
								}}
							>
								{article.title}
								<IconButton
									className="iron-color"
									color="primary"
									href={article.href}
									target="_blank"
									size="small"
									rel="noopener noreferrer"
								>
									<Icon icon="link" />
								</IconButton>
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<RedactorText className="article__authors" html={authors} hasToggle limitText={100} />
						</Grid>
					</Grid>
					<Grid item xs={3} marginLeft={2}>
						{year}
					</Grid>
					<Grid item xs={3}>
						<Similarity id={`view-similarity-${index}`} similarity={similarity} />
					</Grid>
				</Grid>
			</AccordionSummary>
			<AccordionDetails
				sx={{
					padding: '0',
				}}
			>
				<Grid
					container
					sx={{
						display: 'flex',
						flexWrap: 'nowrap',
					}}
				>
					<Grid item container xs={7} spacing={2}>
						<Grid item xs={12}>
							{article.contentType && (
								<Typography className={`trends__category ${article.contentType}`}>
									{t(`trends.contentType.${article.contentType}`)}
								</Typography>
							)}
						</Grid>
						<Grid item xs={12}>
							<Typography
								variant="h6"
								className="article__title"
								sx={{
									fontWeight: '400',
								}}
							>
								{article.title}
								<IconButton
									className="iron-color"
									color="primary"
									href={article.href}
									target="_blank"
									size="small"
									rel="noopener noreferrer"
								>
									<Icon icon="link" />
								</IconButton>
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<RedactorText className="article__authors" html={authors} hasToggle limitText={100} />
						</Grid>
						<Grid item xs={12}>
							<Typography className="abstract">
								{t('global.abstract')}
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<RedactorText
								html={getTextFromHTML(article.abstract)}
								className="content-text"
								hasToggle
								limitText={500}
							/>
						</Grid>
						<Grid
							item
							xs={12}
							sx={{
								display: 'flex',
								gap: 1,
								width: '100%',
								alignItems: 'center',
							}}
						>
							<SLink
								href={article.href}
								target="_blank"
								color="primary"
								rel="noopener noreferrer"
								startIcon={<Icon icon="link" />}
								sx={{
									whiteSpace: 'nowrap',
									minWidth: 'auto',
									i: {
										marginRight: '0.25rem',
									},
								}}
							>
								{t(`trends.accessFull.${article.contentType || 'content'}`)}
							</SLink>
							<SLink
								startIcon={<Icon icon="fa fa-sitemap" />}
								onClick={() => {
									dispatch(Creators.MODAL.showModal(routes?.MODAL_RELATED_DOCS.path, {
										article, similarity,
									}));
								}}
								color="primary"
								sx={{
									whiteSpace: 'nowrap',
									minWidth: 'auto',
									cursor: 'pointer',
									i: {
										marginRight: '0.25rem',
									},
								}}
							>
								{t('trends.relatedDocs')}
							</SLink>
						</Grid>
					</Grid>
					<Grid
						item
						xs={1}
						sx={{
							display: 'flex',
							justifyContent: 'center',
						}}
					>
						<Divider
							orientation="vertical"
							sx={{
								height: '100% !important',
								width: '1px',
								color: 'var(--fog-gray-color)',
							}}
						/>
					</Grid>
					<Grid
						item
						container
						xs={4}
						spacing={3}
						sx={{
							position: 'relative',
							display: 'flex',
							flexDirection: 'column',
							height: 'fit-content',
						}}
					>
						<Grid item container>
							<Tooltip title={t('global.close')}>
								<IconButton
									onClick={() => setExpanded(false)}
									size="medium"
									sx={{
										marginLeft: 'auto',
									}}
								>
									<MdOutlineClose />
								</IconButton>
							</Tooltip>
						</Grid>

						<Grid item xs paddingTop="0 !important">
							<Typography variant="caption">
								{t('global.details')}
							</Typography>
						</Grid>

						{article.doi
								&& (
									<Grid item xs>
										<Typography variant="caption" color="rgba(0, 0, 0, 0.75)">{t('trends.identifiers')}</Typography>
										<Box sx={{ gap: 1, display: 'flex' }}>
											<Typography variant="caption" color="rgba(0, 0, 0, 0.75)">
												DOI:
											</Typography>
											<Typography variant="caption" color="rgba(0, 0, 0, 0.55)">
												{article.doi}
											</Typography>

										</Box>

									</Grid>
								)}

						{(
							article.publisher
								|| getArticleDate(article.date).length > 0
								|| getLanguage(article.language))
								&& (
									<Grid item xs>
										<Typography variant="caption" color="rgba(0, 0, 0, 0.75)">
											{t('trends.publishingInformation')}
										</Typography>
										{
											[
												{
													label: t('trends.publisher'),
													text: article.publisher,
												},
												{
													label: t('global.date'),
													text: getArticleDate(article.date),
												},
												{
													label: t('trends.languageFullText'),
													text: getLanguage(article.language),
												},

											].map((item, index) => {
												if (item.text) {
													return (
														<Box className="info" key={index} sx={{ gap: 1, display: 'flex' }}>
															<Typography variant="caption" color="rgba(0, 0, 0, 0.75)">
																{`${item.label}:`}
															</Typography>
															<Typography variant="caption" color="rgba(0, 0, 0, 0.55)">
																{item.text}
															</Typography>

														</Box>
													);
												}
											})
										}
									</Grid>
								)}

						<Grid item sx={{ display: 'flex', gap: 1 }}>
							<Typography sx={{ whiteSpace: 'nowrap' }}>
								{t('global.similarity')}
								:
							</Typography>
							<Similarity id={`info-similarity-${index}`} similarity={similarity} />
						</Grid>

						{nlp.conceptsArray.length > 0 && (
							<Grid item>
								<Typography sx={{ gap: 1, display: 'flex', flexWrap: 'wrap' }}>
									Tags:

									{nlp.conceptsArray.slice(0, seeMore)?.map((tag: string, index: number) => (
										<Chip key={index} label={tag} variant="outlined" color="primary" size="small" />
									))}
									{(nlp.conceptsArray.length > 10) && (
										<SButton
											color="secondary"
											variant="outlined"
											size="small"
											sx={{
												borderRadius: '50px',
												fontWeight: '400',
												fontSize: '0.8125rem',
												height: '24px',
											}}
											onClick={() => (seeMore === 10
												? setSeeMore(nlp.conceptsArray.length)
												: setSeeMore(10))}
										>
											{seeMore === 10 ? `${t('global.seeMore')} ${nlp.conceptsArray.length - 10}`
												: t('global.seeLess')}
										</SButton>
									)}
								</Typography>
							</Grid>
						)}
					</Grid>
				</Grid>
			</AccordionDetails>
		</Accordion>
	);
};

const AccordionArticle = withTranslation('common')(_AccordionArticle);
export { AccordionArticle };
