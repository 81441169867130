import i18next from 'i18next';
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { isAuthenticated, onLogout, TOKEN_KEY } from '@services/auth';
import { SToast } from '@modules/SToast';

const port = process.env.BACKEND_PORT ? `:${process.env.BACKEND_PORT}` : '';
export const BASE_URL = `${process.env.BACKEND_URL}${port}/api/v1`;

const axiosInstance = axios.create({
	baseURL: BASE_URL,
	// timeout: 20000,
});

// Intercept responses before individual handlers
axiosInstance.interceptors.response.use(
	(response: AxiosResponse) => response,
	(error: AxiosError) => {
		console.log('🚀 ~ Interceptor error', error);
		if (error.response?.data?.err) {
			if (['messages.user.notFound', 'messages.token.expired', 'messages.user.profileNotFound'].includes(error.response?.data?.err)) {
				SToast.error(i18next.t(error.response.data.err));
				onLogout();
			}
		}

		return Promise.reject(error);
	},
);

axiosInstance.interceptors.request.use(
	(config: AxiosRequestConfig) => {
		// return authorization header with jwt token
		if (isAuthenticated()) {
			const TOKEN = localStorage.getItem(TOKEN_KEY);
			if (TOKEN) {
				// eslint-disable-next-line no-param-reassign
				config.headers = { Authorization: `Bearer ${TOKEN}` };
			}
		}

		return config;
	},
	(error: AxiosError) => {
		console.log('🚀 ~ Interceptor error', error);
		if (error.response?.data?.err) {
			if (['messages.user.notFound', 'messages.token.expired', 'messages.user.profileNotFound'].includes(error.response?.data?.err)) {
				SToast.error(i18next.t(error.response.data.err));
				onLogout();
			}
		}

		return Promise.reject(error);
	},
);

export { axiosInstance };
