import React, { useState } from 'react';
import Icon from '@components/Shared/Icon';
import {
	InputLabel, Box, IconButton, InputAdornment,
	InputBaseComponentProps, TextFieldProps, TextField,
} from '@mui/material';
import i18n from '@services/i18n';

interface IPasswordValidation {
	message: string,
	level: string
}

export const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[\d])(?=.*[~,!,@,#,$,%,^,&,*,-,_,+,=,?,>,<,/])(?=.{6,})/;

export const passwordValidation = (password: string, min?: number): IPasswordValidation => {
	const numbers = /([0-9])/;
	const alphabet = /([a-zA-Z])/;
	const chSpecials = /([~,!,@,#,$,%,^,&,*,-,_,+,=,?,>,<])/;

	if (!password || password.length < (min || 6)) {
		return { message: i18n.t('messages.password.level.initial', { min: min || 6 }), level: 'weak' };
	}

	if (password.match(numbers) && password.match(alphabet) && password.match(chSpecials)) {
		return { message: i18n.t('messages.password.level.strong'), level: 'strong' };
	}
	if (!password.match(numbers) || !password.match(alphabet) || !password.match(chSpecials)) {
		return { message: i18n.t('messages.password.level.weak', { min: min || 6 }), level: 'weak' };
	}

	return { message: i18n.t('messages.password.level.initial', { min: min || 6 }), level: 'weak' };
};

interface ISPasswordFieldProps extends InputBaseComponentProps {
	isLoading?: boolean
}

export const SPasswordField = (props:ISPasswordFieldProps & TextFieldProps) => {
	const {
		error, className, id, InputProps, inputProps, ...rest
	} = props;
	const [type, setType] = useState<'password' | 'text'>('password');

	return (
		<Box className={`s-input ${className || ''}`}>
			<TextField
				id={id}
				type={type || 'text'}
				variant="outlined"
				fullWidth
				error={error}
				className={`reveal ${error ? 'is-invalid' : ''}`}
				sx={{
					'.MuiFormHelperText-root.MuiFormHelperText-sizeMedium': {
						margin: 0,
					},
				}}
				InputProps={{
					inputProps: {
						...inputProps,
						minLength: 6,
					},
					endAdornment: (
						<InputAdornment position="end">
							<IconButton
								aria-label="toggle password visibility"
								size="small"
								onClick={() => setType((prevState) => (prevState === 'password' ? 'text' : 'password'))}
								// onMouseDown={handleMouseDownPassword}
								edge="end"
							>
								{type === 'password'
									? <Icon icon="fa fa-eye-slash" />
									: <Icon icon="eye" />}
							</IconButton>
						</InputAdornment>
					),
					...InputProps,
				}}
				{...rest}
			/>
		</Box>
	);
};
