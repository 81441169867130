// @ts-nocheck
import React, { Suspense } from 'react';
import { showTab } from '@utils/helpers';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import URLS from '@constants/URLS';
import { UserAvatar } from '@components/Avatar/UserAvatar';
import { IPaginatedRequest, IPagination } from 'src/@types/IShared';
import { IUser } from 'src/@types/IUser';
import { DynamicCircle } from '@components/DynamicCircle';
import { BoxProps, Tooltip } from '@mui/material';
import { SBox } from '@components/Background/SBox';
import { SeeMoreCircle } from '../Shared/SeeMoreCircle';
import { IListRequest } from './types';

interface SponsorsListProps extends BoxProps {
	loadMore?: (params: IListRequest) => void
	sponsors: IPagination<IUser>
	isCollapsed?: boolean
	numbersToShow?: number
	queryParams?: IPaginatedRequest
}

const SSponsorsList = (props: SponsorsListProps) => {
	const { t } = useTranslation();
	const {
		sponsors, isCollapsed, numbersToShow = 3, queryParams, loadMore, ...rest
	} = props;

	// const [isLoadingMore, setIsLoadingMore] = useState(false);

	// const loadMoreHandler = () => {
	// 	setIsLoadingMore(true);
	// 	loadMore({
	// 		...queryParams,
	// 		page: sponsors.nextPage,
	// 		callback: () => setIsLoadingMore(false),
	// 	});
	// };

	return (
		<SBox
			id="sponsorsList"
			loading={sponsors.docs?.length === 0}
			empty={!sponsors || sponsors?.isLoading ? 'features' : undefined}
			{...rest}
		>
			<ul className="members__list">
				{sponsors.docs.map((member) => (
					<li key={member._id}>
						<div className="d-flex">
							<UserAvatar
								profile={member.userOrganizationProfile}
								size="middle"
								hasPopover
								goProfile
								target={`sponsor_${member._id}`}
							/>
							<div className="member__info">
								<Link to={`${URLS.PROFILE}/${member._id}`} className="name">
									{member.userOrganizationProfile.user.nome}
								</Link>
								{member.lastSponsorDate && (
									<div className="mr-1 font-light">
										<span className="mr-1">{t('idea.fundings.lastSponsorship')}</span>
										<span className="mr-1">{t('global.in', { date: new Date(member.lastSponsorDate) })}</span>
									</div>
								)}
							</div>
						</div>

						<ul className="right has-tablet">
							{member.fundings?.map((feature, i) => {
								const target = `feature_${feature._id}`;
								if (isCollapsed) {
									if (member.fundings.length > 1) return null;
								} else if (i > numbersToShow - 1) {
									return null;
								}
								return (
									<li key={`feature_${feature._id}`}>
										<Tooltip classes={{ popper: 'secondary' }} placement="top" title={feature.nome}>
											<DynamicCircle
												id={target}
												color={feature.cor}
												background={feature.background}
												icon="feature"
											/>
										</Tooltip>
									</li>
								);
							})}
							<SeeMoreCircle
								id={member._id}
								numbersToShow={numbersToShow}
								more={member.fundings}
								titleTooltip={t('global.viewMore')}
								onClick={() => showTab({ navId: '#idea-tabs', tabId: 'supports-tab' })}
							/>
						</ul>
					</li>
				))}
			</ul>
		</SBox>
	);
};

const SponsorsList = (props: SponsorsListProps) => (
	<Suspense fallback={<div />}>
		<SSponsorsList {...props} />
	</Suspense>
);

export { SponsorsList };
