import React, { KeyboardEvent, MouseEvent } from 'react';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Primitive } from 'src/@types/IShared';
import { ListItemIcon, ListItemText } from '@mui/material';

export interface ISMenuOption {
    name?: string
    value?: Primitive
    label?: string
    details?: string
    disabled?: boolean
    close?: boolean
	className?: string
    show?: boolean
    icon?: string | React.ReactNode
    onClick?: (event: MouseEvent | KeyboardEvent) => void
}

interface ISMenuProps {
  id: string
  options?: ISMenuOption[]
  children?: React.ReactNode
  disabled?: boolean
  setAnchorEl: React.Dispatch<React.SetStateAction<null | HTMLElement>>
  onChange?: (option: ISMenuOption, event: MouseEvent | KeyboardEvent) => void
}

export const SMenu = (props: ISMenuProps & MenuProps) => {
	const {
		options, children, id, open, setAnchorEl, onChange, disabled, ...rest
	} = props;

	const handleClick = (event: MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const onCloseHandler = (event: MouseEvent | KeyboardEvent, reason: 'backdropClick' | 'escapeKeyDown') => {
		setAnchorEl(null);
	};

	const onOptionClick = (event: MouseEvent | KeyboardEvent, option: ISMenuOption) => {
		if (disabled) return;
		if (typeof option?.onClick === 'function') {
			option?.onClick(event);
		} else if (typeof onChange === 'function') {
			onChange(option, event);
		}

		if (typeof option.close === 'undefined' || option.close) {
			onCloseHandler(event, 'backdropClick');
		}
	};

	return (
		<>
			<div
				aria-hidden
				onClick={handleClick}
				aria-controls={open ? id : undefined}
				aria-haspopup="true"
				aria-expanded={open ? 'true' : undefined}
			>
				{children}
			</div>
			<Menu
				id={id}
				open={disabled ? false : open}
				onClose={onCloseHandler}
				className="s-menu"
				PaperProps={{
					elevation: 0,
					sx: {
						overflow: 'auto',
						filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
						mt: 1.5,
						// '&:before': {
						// 	content: '""',
						// 	display: 'block',
						// 	position: 'absolute',
						// 	top: 0,
						// 	right: 14,
						// 	width: 10,
						// 	height: 10,
						// 	bgcolor: 'background.paper',
						// 	transform: 'translateY(-50%) rotate(45deg)',
						// 	zIndex: 0,
						// },
					},
				}}
				transformOrigin={{ horizontal: 'center', vertical: 'top' }}
				anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
				{...rest}
			>
				{options?.map((option, index) => {
					if (!option.show && typeof option.show === 'boolean') return null;
					const key = `menu-${index}`;
					return (
						<MenuItem
							key={key}
							onClick={(e: MouseEvent | KeyboardEvent) => onOptionClick(e, option)}
							disabled={option.disabled}
							className={option.className || ''}
						>
							{option.icon && (
								<ListItemIcon>{option.icon}</ListItemIcon>
							)}
							<ListItemText primary={option.label} secondary={option.details} />
						</MenuItem>
					);
				})}
			</Menu>
		</>
	);
};
