/* eslint-disable react/no-array-index-key */
/* eslint-disable no-param-reassign */
/* eslint-disable react/prop-types */
// @ts-nocheck
// @ts-ignore
import { Box, FormHelperText } from '@mui/material';

import { SCheckbox } from '../ComponentsCore';

const MultipleChoice = (props) => {
	const {
		options, error, value, id,
		innerClass = 'form', className, onChange, helperText, ...rest
	} = props;

	const handleValues = (option) => {
		if (option.isOther) {
			if (option.hasOther) {
				option.hasOther = false;
			} else option.hasOther = true;
			option.value = '';
		} else {
			const optionIndex = value?.findIndex((item) => item === option.value);

			if (value?.includes(option.value)) {
				value?.splice(optionIndex, 1);
			} else value?.push(option.value);
		}
		if (typeof onChange === 'function') {
			onChange(value);
		}
	};

	const onOtherOption = (option, otherValue) => {
		option.value = otherValue;

		if (typeof onChange === 'function') { onChange(value); }
	};

	return Array.isArray(options)
		? (
			<Box className={className}>
				{options?.map((option, index) => (
					<div key={`${id}-${index}`}>
						<SCheckbox
							id={`${id}-${index}`}
							value={option.value}
							checked={(Array.isArray(value) && value.includes(option.value)) || option.hasOther}
							onChange={() => handleValues(option)}
							label={option.label}
							{...rest}
						/>
						{(option.hasOther)
							&& (
								<textarea
									rows="6"
									className={`form-control ${innerClass}`}
									value={option.value}
									onChange={(e) => onOtherOption(option, e.target.value)}
								/>
							)}
					</div>
				))}
				{helperText && <FormHelperText type="invalid" error={error}>{helperText}</FormHelperText>}
			</Box>
		)
		: null;
};

export { MultipleChoice };
