import React from 'react';
import { UserAvatar } from '@components/Avatar/UserAvatar';
import { IUser } from 'src/@types/IUser';
import { Box, Typography } from '@mui/material';

interface IOwnProps {
	hasHref?: boolean
	user: IUser
	initial?: string
}

const UserCell = ({
	hasHref, user, initial,
}: IOwnProps) => (
	<Box
		sx={{
			display: 'flex',
			alignItems: 'center',
		}}
	>
		<UserAvatar
			size="small"
			className="mr-10px"
			// @ts-ignore
			profile={{ user }}
			hasHref={hasHref}
			initial={initial || user?._nome || user?.nome || user?.email}
		/>
		<div className="info">
			{(user?._nome || user?.nome) && (
				<Typography
					className="info-name"
					sx={{
						minWidth: '1px',
						flex: '1 1 auto',
						overflowX: 'hidden',
						fontSize: '12px',
						textOverflow: 'ellipsis',
						lineHeight: '20px',
						whiteSpace: 'nowrap',
						maxWidth: '130px',
						fontWeight: '700',

					}}
				>
					{user._nome || user.nome}
				</Typography>
			)}
			{user?.email && (
				<Typography
					className="info-email"
					sx={{
						minWidth: '1px',
						flex: '1 1 auto',
						maxWidth: '130px',
						fontSize: '12px',
						color: '#827f9b',
					}}
				>
					{user.email}
				</Typography>
			)}
		</div>
	</Box>
);

export { UserCell };
