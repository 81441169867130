import { SLink } from '@components/Buttons/SLink';
import { Typography } from '@mui/material';
import React, { Suspense } from 'react';
import { Trans, withTranslation, WithTranslation } from 'react-i18next';

interface IOwnProps {
  className?: string
  cover: {
    author: {
      portforlio: string
      name: string
    }
    url?: string
  }
}

declare type TProps = IOwnProps & WithTranslation

const _CreditsAuthor = ({ cover, className = '' }: TProps) => (
	<Typography variant="h6" className={`credits-author ${className}`} sx={{ gap: 1 }}>
		<Trans
			i18nKey="global.photoBy"
			values={{ name: cover.author.name, location: 'Unsplash' }}
			components={{
				1: <SLink
					href={`${cover.author.portforlio || cover.url}?utm_source=SENNO&utm_medium=referral`}
					target="_blank"
					underline="always"
					rel="noreferrer"
				/>,
				2: <SLink href="https://unsplash.com/?utm_source=SENNO&utm_medium=referral" underline="always" target="blank" />,
			}}
		/>
	</Typography>
);

const Translation = withTranslation('common')(_CreditsAuthor);

const CreditsAuthor = (props: IOwnProps) => (
	<Suspense fallback={<div />}>
		<Translation {...props} />
	</Suspense>
);

export { CreditsAuthor };
