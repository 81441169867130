// @ts-nocheck
import { createActions, createReducer } from 'reduxsauce';
import { IUserOrganizationActions, IUserState } from './types';

/** Creating actions types & creators */

export const userActions: IUserOrganizationActions = createActions({
	getProfile: ['params'],
	getUserOrganization: [''],
	getOrganization: ['params'],
	successUser: ['data'],
	userLogout: [''],
	clearUser: [''],
	handleUser: ['user'], // Change only the store
	handleUserImage: ['params'],
	getOrganizations: ['params'],
	changeOrganization: ['organizationId'],
	handleOrganization: ['organization'],
	handleOrganizationImage: ['params'], // formData, onSuccess
	inviteOrganization: ['params'],

	nextOrganization: [''],

	resendToken: ['params'],
	requestOrganizationCreation: ['params'],
});

const { Types } = userActions;

export const INITIAL_STATE: IUserState = {
};

const successUser = (state: IUserState, action) => ({
	...state,
	...action.data,
	isLoading: false,
});

const handleUser = (state: IUserState, { user }) => ({
	...state,
	user: {
		...state.user,
		...user,
	},
	isLoading: false,
});

const handleOrganization = (state: IUserState, action) => ({
	...state,
	organization: {
		...state.organization,
		...action.organization,
	},
	isLoading: false,
});

const clearUser = () => ({});

const defaultUser = (state: IUserState) => ({
	...state,
});

/** Creating our own reducer */

export default createReducer(INITIAL_STATE, {
	[Types.GET_USER_ORGANIZATION]: defaultUser,
	[Types.GET_PROFILE]: defaultUser,
	[Types.GET_ORGANIZATION]: defaultUser,
	[Types.GET_ORGANIZATIONS]: defaultUser,
	[Types.SUCCESS_USER]: successUser,
	[Types.USER_LOGOUT]: defaultUser,
	[Types.CLEAR_USER]: clearUser,
	[Types.HANDLE_USER_IMAGE]: defaultUser,
	[Types.HANDLE_USER]: handleUser,

	[Types.HANDLE_ORGANIZATION]: handleOrganization,
	[Types.HANDLE_ORGANIZATION_IMAGE]: defaultUser,

	[Types.CHANGE_ORGANIZATION]: defaultUser,
	[Types.INVITE_ORGANIZATION]: defaultUser,

	[Types.RESEND_TOKEN]: defaultUser,
	[Types.REQUEST_ORGANIZATION_CREATION]: defaultUser,
});
