// @ts-nocheck
import React, { Suspense } from 'react';
import Icon from '@components/Shared/Icon';
import { Box, Tooltip } from '@mui/material';
import { createBackground, overflowText } from '@utils/helpers';
import { WithTranslation, withTranslation } from 'react-i18next';

interface ITemplate {
  _id: string
  icon?: string
  name: string
  summary: string
  color: string
}

interface IOwnProps {
	template: ITemplate
	clickable?: boolean
}

declare type TProps = IOwnProps & WithTranslation

const TTemplateCard = (props: TProps) => {
	const { template, t, clickable } = props;

	const backgroundColor = createBackground(template?.color || 'rgb(4, 72, 110)');

	return (
		<Box className="box-item card-box" sx={{ cursor: clickable ? 'pointer' : 'auto' }}>
			<figure className="rollover" aria-hidden style={{ backgroundColor }}>
				<div
					className="align-items-center box-photo d-flex justify-content-center w-100"
					style={{
						color: template?.color || 'var(--primary)',
					}}
				>
					<Icon icon={template?.slug || 'template'} style={{ fontSize: '84px' }} />
				</div>
			</figure>
			<div className="box-info">
				<div className="content-body">
					<div className="content-infos">
						<Tooltip
							placement="top"
							className="tooltip-secondary"
							title={template?.templateNome}
							arrow
						>
							<a
								href={clickable ? '# ' : undefined}
								className={`item-title ${clickable ? 'clickable' : ''}`}
								style={{ cursor: clickable ? 'pointer' : 'auto' }}
							>
								{template?.templateNome}
							</a>
						</Tooltip>
					</div>
					<div className="align-items-center d-flex pb-2 pt-2">
						<div className="mt-1 d-flex">
							<p className="align-items-center d-flex mr-2">
								{overflowText(template.descricaoList, 144)}
							</p>
						</div>
					</div>
				</div>
			</div>
		</Box>
	);
};

const Translation = withTranslation('common')(TTemplateCard);

const TemplateCard = (props: IOwnProps) => (
	<Suspense fallback={<div />}>
		<Translation {...props} />
	</Suspense>
);

export { TemplateCard };
