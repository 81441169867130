import React, { useEffect } from 'react';
import { TimelineMax } from 'gsap/all';

interface IOwnProps {
	className?: string
}

const SaveLoader = ({ className }: IOwnProps) => {
	useEffect(() => {
		const loader7Timeline = new TimelineMax({ repeat: -1 });

		loader7Timeline.staggerTo('.loader7 circle', 0.4, {
			attr: {
				'fill-opacity': 1,
			},
		}, 0.2)
			.staggerTo('.loader7 circle', 0.4, {
				attr: {
					'fill-opacity': 0,
				},
			}, 0.2, 0.4);
	}, []);

	return (
		<div className={`loading__wrapper ${className || ''}`}>
			<div>
				<svg className="loader7" height="58" viewBox="0 0 58 58" width="58" xmlns="http://www.w3.org/2000/svg">
					<g fillRule="evenodd" fill="#04486e">
						<g strokeWidth="1.5" stroke="#fff" transform="translate(2 1)">
							<circle cx="42.601" cy="11.462" fillOpacity="0" fill="#04486e" r="5" />
							<circle cx="49.063" cy="27.063" fillOpacity="0" fill="#04486e" r="5" />
							<circle cx="42.601" cy="42.663" fillOpacity="0" fill="#04486e" r="5" />
							<circle cx="27" cy="49.125" fillOpacity="0" fill="#04486e" r="5" />
							<circle cx="11.399" cy="42.663" fillOpacity="0" fill="#04486e" r="5" />
							<circle cx="4.938" cy="27.063" fillOpacity="0" fill="#04486e" r="5" />
							<circle cx="11.399" cy="11.462" fillOpacity="0" fill="#04486e" r="5" />
							<circle cx="27" cy="5" fillOpacity="0" fill="#04486e" r="5" />
						</g>
					</g>
				</svg>
			</div>
		</div>

	);
};

export { SaveLoader };
