import React from 'react';
import { TInput } from '@components/DynamicForm/ComponentsCore';
import { Box } from '@mui/material';

interface IColorPalette {
	value: string
	index: number
}

export const colorsCore: IColorPalette[] = [
	{ value: 'rgb(0, 0, 0)', index: 0 },
	{ value: 'rgb(76, 122, 144)', index: 1 },
	{ value: 'rgb(92, 178, 183)', index: 2 },
	{ value: 'rgb(59, 151, 118)', index: 3 },
	{ value: 'rgb(151, 60, 118)', index: 4 },
	{ value: 'rgb(204, 48, 75)', index: 5 },
	{ value: 'rgb(216, 153, 44)', index: 6 },
	{ value: 'rgb(238, 195, 0)', index: 7 },
	{ value: 'rgb(233, 79, 63)', index: 8 },
	{ value: 'rgb(224, 142, 120)', index: 9 },
	{ value: 'rgb(219, 112, 147)', index: 10 },
];

interface IProps extends TInput { }

const ColorPalette = ({
	id, value, onChange, className, ...rest
}: IProps) => (
	<Box className={`color-palette ${className || ''}`} sx={{ display: 'flex', fontSize: '1.4em' }}>
		{colorsCore.map((color) => (
			<div key={`${color.value}${color.index}`} className={`form-check ${className || ''}`}>
				<input
					className="form-check-input"
					type="radio"
					value={color.value}
					checked={value === color.value}
					id={`${id}-palette`}
					onChange={typeof onChange === 'function' ? onChange : undefined}
					style={{
						backgroundColor: color.value,
						borderColor: color.value,
					}}
					{...rest}
				/>
			</div>
		))}
	</Box>
);

export { ColorPalette };
