/* eslint-disable react/no-danger */
import React, { useEffect, useState } from 'react';
import { useTranslation} from 'react-i18next';
import { Box, IconButton } from '@mui/material';

import Icon from '@components/Shared/Icon';
import { IAnswer, ISavedQuestion } from 'src/@types/IIdea';
import AnsersListComponent from '@components/QA/AnswersList';
import { SCheckbox } from '@components/DynamicForm/ComponentsCore';
import { SBox } from '@components/Background/SBox';
import { SButton } from '@components/Buttons';
import { APIQuestions } from '@services/apis';
import { SToast } from '@modules/SToast';
import { handleAxiosErrors } from '@services/auth';

export interface GenerateAnswersParams {
	text: string
	callback?: () => void
}

interface QuestionAnswerListProps {
	textName?: string;
	question?: string;
	isLoading?: boolean
	answerList?: IAnswer[];
	questionsList?: any[];
	generateAnswers: (params: GenerateAnswersParams) => void;
	ideaId: string
}

const QuestionAnswerList = (props: QuestionAnswerListProps) => {
	const {
		textName = '',
		question = '',
		answerList = [],
		questionsList = [],
		generateAnswers,
		isLoading,
		ideaId,
	} = props;

	const { t } = useTranslation();

	const title = answerList.length > 0 ? 'results' : 'generateQuestions';
	const save = answerList.length > 0 ? 'saveQuestion' : 'saveQuestions';

	const [checked, setChecked] = useState<any>(questionsList.map(() => false));

	const [inProgress, setInProgress] = React.useState<string | undefined>();

	const handleChange = (event: any, index: number) => {
		const newArr = [...checked];

		newArr[index] = event.target.checked;

		setChecked(newArr);
	};

	useEffect(() => {
		if (questionsList.length > 0) {
			setChecked(questionsList.map(() => false));
		} else {
			setChecked([]);
		}
	}, [questionsList]);

	const handleChangeAll = (e: any) => {
		const allChecked = checked.map(() => e.target.checked);
		setChecked(allChecked);
	};

	const handleSaveQuestion = async () => {
		try {
			setInProgress('save-question');
			const questions: object[] = [];

			if (checked.length > 0) {
				checked.forEach((check: boolean, i: number) => {
					if (check && check === true) {
						questions.push({ question: questionsList[i].question, answear: questionsList[i].answear.answers[0] });
					}
				});
			} else {
				questions.push({ question, answear: answerList[0] });
			}

			await APIQuestions.postQuestions({ ideaId, questions });

			SToast.success(t('messages.success.QA.question.saved'));
		} catch (err) {
			handleAxiosErrors(err);
		} finally {
			setInProgress(undefined);
		}
	};

	const seeResults = (item: ISavedQuestion) => {
		setInProgress(`results-${item._id}`);

		if (typeof generateAnswers === 'function') {
			generateAnswers({ text: item.question, callback: () => setInProgress(undefined) });
		}
	};

	const saveRule = ((answerList.length > 0 && questionsList.length < 1)
	|| (answerList.length < 1 && questionsList.length > 0 && checked.some((item: any) => item === true)));

	return (
		<SBox
			loading={isLoading}
			empty={answerList?.length === 0 ? 'answers' : undefined}
		>
			<div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '5%' }}>
				<h5 style={{ fontWeight: '700', marginBottom: '2%' }}>
					{t(`questions.${title}`)}
					:
				</h5>
				<div style={{
					display: 'flex',
					alignItems: 'center',
				}}
				>
					<SButton
						startIcon={<Icon icon="favorite" />}
						variant="text"
						onClick={handleSaveQuestion}
						disabled={!saveRule}
						isLoading={inProgress === 'save-question'}
					>
						{t(`questions.${save}`)}
					</SButton>
				</div>
			</div>
			<ul>
				{questionsList.length > 0 && (
					<>
						<SCheckbox
							id="checkAllQA"
							checked={checked.length > 0 ? checked.every((item: any) => item === true) : false}
							onChange={handleChangeAll}
							label={t('questions.selectAll')}
						/>
						<div>
							{ questionsList.map((item: any, index: number) => (
								<div className="accordion trends" id={item._id}>
									<div className="accordion-item QA-saved-list">
										<div className="QA-generated-questions">
											<div style={{ display: 'flex', alignItems: 'flex-start', maxWidth: '60%' }}>
												<SCheckbox
													checked={checked[index] || false}
													value={checked[index] || false}
													onChange={(e) => handleChange(e, index)}
													style={{ paddingLeft: '0px', paddingTop: '0px', color: '#000' }}
													id={`checkbox-${index}`}
												/>
												<li>{item.question}</li>
											</div>
											<Box className="QA-see-results" sx={{ gap: 1 }}>
												<SButton
													type="button"
													onClick={() => seeResults(item)}
													isLoading={inProgress === `${`results-${item._id}`}`}
													startIcon={<Icon icon="signs" style={{ color: '#000', fontSize: '16px' }} />}
												>
													{t('questions.results')}
												</SButton>
												<IconButton
													type="button"
													data-bs-toggle="collapse"
													data-bs-target={`#acordion-${index}`}
													aria-expanded="true"
													aria-controls={`acordion-${index}`}
												>
													<Icon style={{ color: '#000', fontSize: '16px' }} icon="eye" />
												</IconButton>
											</Box>
										</div>
										<div
											id={`acordion-${index}`}
											className="accordion-collapse collapse"
											aria-labelledby="headingOne"
											data-bs-parent="#accordionExample"
										>
											<div className="accordion-body">
												{item.answear && (
													<AnsersListComponent
														list={[item.answear.answers[0]]}
														textName={textName}
														haveBottomBorder={false}
													/>
												) }
											</div>
										</div>
									</div>
								</div>
							))}
						</div>
					</>
				)}
				<AnsersListComponent list={answerList} textName={textName} />
			</ul>
		</SBox>
	);
};

export default QuestionAnswerList;
