import { Button, ButtonProps, CircularProgress } from '@mui/material';
import React from 'react';

export interface ISButton {
	isLoading?: boolean
	error?: boolean
}

export const SButton = <C extends React.ElementType>(
	props: ButtonProps<C, { component?: C }> & ISButton,
) => {
	const {
		children, className, isLoading, disabled, error, startIcon, color, ...rest
	} = props;
	return (
		<Button
			className={`s-button ${className || ''}`}
			color={error ? 'error' : color}
			disabled={isLoading || disabled}
			startIcon={isLoading ? <CircularProgress color="inherit" size={10} /> : startIcon}
			{...rest}
		>
			{children}
		</Button>
	);
};
