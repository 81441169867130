// @ts-nocheck
import { IFeature } from 'src/@types/IIdea';
import { axiosInstance } from '@services/AxiosInstance';

const APIFeatures = {
	postCreateFeature(feature: IFeature) {
		return axiosInstance.post('/fundings/', feature);
	},
	postFeatureIdea(params: { ideaId: string, templateId: string }) {
		return axiosInstance.post('/fundings/associate', params);
	},
	getFeatures(ideaId: string) {
		return axiosInstance.get(`/ideas/${ideaId}/fundings`);
	},
	getTemplates(ids) {
		const queryParams = typeof ids !== 'undefined' && ids.length > 0 ? `?ids=${ids}` : '';
		return axiosInstance.get(`/templates/funding${queryParams}`);
	},
	deleteFeature(featureId: string) {
		return axiosInstance.delete(`/fundings/${featureId}`);
	},
	deleteSupport(featureId: string) {
		return axiosInstance.delete(`/fundings/${featureId}/support`);
	},
	patchFeature(featureId: string, field) {
		return axiosInstance.patch(`/fundings/${featureId}`, field);
	},
	putResetFeature(featureId: string) {
		return axiosInstance.put(`/fundings/${featureId}/restore_template`, {});
	},
	postSupportFeature(featureId: string) {
		return axiosInstance.post(`/fundings/${featureId}/support`, {});
	},
	getSupporters(featureId: string) {
		return axiosInstance.get(`/fundings/${featureId}/supporters`);
	},
};

export default APIFeatures;
