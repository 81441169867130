import React, { useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import FiltersNavBar from '@components/NavBar/FiltersNavBar';
import { IPaginatedRequest, IPagination } from 'src/@types/IShared';
import { APIIdeas } from '@services/apis';
import GLOBAL from '@constants/GLOBAL';
import { IdeaCard } from '@components/Cards/IdeaCard';
import { IIdea } from 'src/@types/IIdea';
import { Creators } from '@actions';
import { handleAxiosErrors } from '@services/auth';
import CardGrid from '@components/Grids/CardGrid';
import { IListRequest } from '@components/Lists/types';
import routes from '@routes/modal';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import { SButton } from '@components/Buttons';
import Icon from '@components/Shared/Icon';
import { GenericGrid } from '@components/Grids/GenericGrid';
import PageLoader from '@components/Loaders/PageLoader';

interface IState {
	minhas: IPagination<IIdea>
	archived: IPagination<IIdea>
	recomendadas: IPagination<IIdea>
}

type TProps = WithTranslation

const IIdeas = ({ t }: TProps) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();

	const [state, setState] = useState<IState>({
		minhas: { docs: [], isLoading: true },
		archived: { docs: [], isLoading: true },
		recomendadas: { docs: [], isLoading: true },
	});

	const onDeleteHandler = (ideaId: string) => {
		Object.keys(state).forEach((k) => {
			const key = k as keyof IState;
			const index = state[key].docs.findIndex((item) => item._id === ideaId);
			if (index > -1) {
				state[key].docs.splice(index, 1);
				setState((prevState) => ({
					...prevState,
					[key]: {
						...prevState[key],
					},
				}));
			}
		});
	};

	const updateIdea = (idea: IIdea) => {
		Object.keys(state).forEach((k) => {
			const key = k as keyof IState;
			const index = state[key].docs.findIndex((item) => item._id === idea._id);
			if (index > -1) {
				state[key]?.docs.splice(index, 1, { ...state[key]?.docs[index], ...idea });
				setState((prevState) => ({
					...prevState,
					[key]: {
						...prevState[key],
					},
				}));
			}
		});
	};

	const loadIdeas = async (params: IListRequest) => {
		try {
			const { filter } = params;
			const response = await APIIdeas.getList(
				{ ...params, limit: GLOBAL.DOCS_PER_PAGE },
			);

			const key = filter as keyof IState;

			setState((prevState) => ({
				...prevState,
				[key]: {
					...prevState[key],
					...response.data,
					docs: !response.data.hasPrevPage
						? response.data.docs
						: prevState[key].docs?.concat(response.data.docs),
					isLoading: false,
				},
			}));
		} catch (err: any) {
			handleAxiosErrors(err);
		} finally { // loadMore
			if (typeof params.callback === 'function') {
				params.callback();
			}
		}
	};

	const onViewMoreHandler = (params: IPaginatedRequest) => {
		navigate(`?filter=${params.filter}&sort=${params.sort || 'last-first'}`, { replace: true });

		if (params.filter === 'highlights') {
			loadIdeas({ ...params, filter: 'minhas' });
			loadIdeas({ ...params, filter: 'recomendadas' });
			loadIdeas({ ...params, filter: 'archived' });
		} else {
			loadIdeas(params);
		}
	};

	const updateList = (params: IListRequest) => {
		navigate(`?filter=${params.filter}&sort=${params.sort || 'last-first'}`, { replace: true });
		loadIdeas(params);
	};

	React.useEffect(() => {
		const params = {
			filter: new URLSearchParams(location.search)?.get('filter') || 'highlights',
			sort: new URLSearchParams(location.search)?.get('sort') || 'last-first',
			page: 1,
		};

		onViewMoreHandler(params);
		dispatch(Creators.IDEAS.updateIdeas({ syncIdea: updateIdea })); // updateIdea function Store
	}, []);

	const params = {
		filter: new URLSearchParams(location.search)?.get('filter') || 'highlights',
		sort: new URLSearchParams(location.search)?.get('sort') || 'last-first',
		page: 1,
		limit: GLOBAL.DOCS_PER_PAGE,
	};

	const filters = [
		{
			value: 'minhas',
			label: t('page.ideas.my'),
			show: false,
			icon: 'ideas',
		},
		{
			value: 'highlights',
			label: t('global.highlights'),
		},
		{
			value: 'recomendadas',
			label: t('global.recommendations'),
		},
		{
			value: 'archived',
			label: t('global.archived'),
		},
	];

	return (
		<GenericGrid id="ideas-page" className="ideas__page s-grid-page" paddingTop={6}>
			<FiltersNavBar
				id="ideas-nav"
				className="w-grid-1 inner"
				onUpdate={updateList}
				params={params}
				filters={filters}
				controlled
				components={{
					LeftButton: (
						<SButton
							type="button"
							color="inherit"
							variant="text"
							sx={{ borderRadius: 0 }}
							startIcon={filters[0].icon && <Icon icon={filters[0].icon} />}
							onClick={() => updateList({ ...params, filter: filters[0].value })}
						>
							{filters[0]?.label}
						</SButton>),
				}}
			/>
			{
				[
					{
						headerLabel: t('page.ideas.recommended'),
						section: state.recomendadas,
						filter: 'recomendadas',
						filterLabel: t('global.recommendations'),
					},
					{
						headerLabel: t('page.ideas.my'),
						section: state.minhas,
						filter: 'minhas',
						filterLabel: t('page.ideas.my'),
					},
					{
						headerLabel: t('page.ideas.archived'),
						section: state.archived,
						filter: 'archived',
						filterLabel: t('page.ideas.archived'),
					},
				].map((item) => (
					<Box key={item.filter} className="w-grid-1 inner">
						<CardGrid
							id={`${item.filter}-ideas`}
							headerLabel={item.headerLabel}
							headerSubLabel={params.filter === 'highlights' ? t('page.selection') : undefined}
							section={item.section}
							type="ideas"
							queryParams={params}
							GridItemProps={{
								xs: 12, sm: 6, md: 4, xl: 3,
							}}
							hide={params.filter !== 'highlights' && params.filter !== item.filter}
							onViewMore={params.filter === 'highlights'
								? (queryParams) => onViewMoreHandler({ ...queryParams, filter: item.filter })
								: undefined}
							loadMore={updateList}
							component={(idea: IIdea) => (
								<IdeaCard
									routes={routes}
									sectionLocator={item.filter}
									idea={idea}
									onDelete={onDeleteHandler}
									updateIdea={updateIdea}
								/>
							)}
						/>
					</Box>
				))
			}
		</GenericGrid>
	);
};

const Translation = withTranslation('common')(IIdeas);

const Ideas = () => (
	<React.Suspense fallback={<PageLoader />}>
		<Translation />
	</React.Suspense>
);

export default Ideas;
