// @ts-nocheck
import { withTranslation } from 'react-i18next';
import { EmptyState } from '@components/Shared/EmptyState';
import React, { useState } from 'react';
import SpinnerLDSRipple from '@components/Shared/SpinnerLDSRipple';
import { Box } from '@mui/material';
import { BCoverBox } from './styles';

interface IBCoverProps {
	disabled?: boolean
	area: string
	isLoading?: boolean
	cover: string
	onClick: () => void
}

const _BCover = (props: IBCoverProps) => {
	const {
		area, t, onClick, cover, isLoading, disabled,
	} = props;

	const [loaded, setLoaded] = useState(true);

	return (
		<BCoverBox className={`${cover || isLoading ? 'overlay__cover' : ''}`}>
			<div className="overlay" />
			<label
				htmlFor={`inputCoverPathThumb_${area}`}
				className="hover-item"
				onClick={onClick}
				style={{ cursor: disabled ? 'auto' : 'pointer' }}
			>
				<input
					type="hidden"
					value={cover}
					data-area={`${area}`}
					name="coverPath"
					id={`inputCoverPath_${area}`}
				/>

				{isLoading && (
					<Box
						className="progress__bar"
						sx={{
							position: 'absolute',
							height: '100%',
							width: '100%',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							zIndex: '1',
						}}
					>
						<SpinnerLDSRipple />
					</Box>
				)}
				{!cover && !isLoading && (
					<EmptyState disabled={disabled} type="image" sx={{ padding: 2 }} />
				)}
				{cover && loaded && (
					<img
						alt=""
						className="select-cover mb-0"
						onError={() => setLoaded(false)}
						style={{
							backgroundImage: `url(${cover})`,
						}}
					/>
				)}

				{cover && !loaded && (
					<img
						alt=""
						className=""
						onError={() => setLoaded(false)}
						src="/images/default.jpg"
					/>
				)}
			</label>
		</BCoverBox>
	);
};
const BCover = withTranslation('common')(_BCover);
export { BCover };
