// @ts-nocheck
import React, { useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import FiltersNavBar from '@components/NavBar/FiltersNavBar';
import { APIPlatforms } from '@services/apis';
import { IPaginatedRequest, IPagination } from 'src/@types/IShared';
import GLOBAL from '@constants/GLOBAL';
import { PlatformCard } from '@components/Cards/PlatformCard';
import { IPlatform } from 'src/@types/IPlatform';
import { handleAxiosErrors } from '@services/auth';
import { Creators } from '@actions';
import { IListRequest } from '@components/Lists/types';
import CardGrid from '@components/Grids/CardGrid';
import routes from '@routes/modal';
import { useDispatch } from 'react-redux';
import {
	useLocation, useNavigate,
} from 'react-router-dom';
import { SButton } from '@components/Buttons';
import Icon from '@components/Shared/Icon';
import { Box } from '@mui/material';
import { GenericGrid } from '@components/Grids/GenericGrid';
import PageLoader from '@components/Loaders/PageLoader';

declare type TPlatformType = 'challenge' | 'brainstorm'

const DicPlatformType = {
	brainstorms: 'brainstorm',
	challenges: 'challenge',
};

interface IState {
	recommendations: IPagination<IPlatform>
	mine: IPagination<IPlatform>
	participations: IPagination<IPlatform>
}

type TProps = WithTranslation

const _Platforms = ({ t }: TProps) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();

	const [state, setState] = useState({
		mine: { docs: [], isLoading: true },
		archived: { docs: [], isLoading: true },
		participations: { docs: [], isLoading: true },
		recommendations: { docs: [], isLoading: true },
	});

	const loadPlatforms = async (type: TPlatformType, params: IListRequest) => {
		try {
			const response = await APIPlatforms.getList({ ...params, limit: GLOBAL.DOCS_PER_PAGE }, type);

			const key = params.filter as keyof IState | 'highlights';

			if (response.data) {
				setState((prevState) => ({
					...prevState,
					[key]: {
						...prevState[key],
						...response.data,
						docs: response.data.hasPrevPage
							? prevState[key].docs?.concat(response.data.docs)
							: response.data.docs,
						isLoading: false,
					},
				}));
			}
		} catch (err: any) {
			handleAxiosErrors(err);
		} finally { // loadMore
			if (typeof params.callback === 'function') {
				params.callback();
			}
		}
	};

	const onDeleteHandler = (platformId: string) => {
		Object.keys(state).forEach((k) => {
			const key = k as keyof IState;
			const index = state[key].docs.findIndex((item) => item._id === platformId);
			if (index > -1) {
				state[key].docs.splice(index, 1);
				setState((prevState) => ({
					...prevState,
					[key]: {
						...prevState[key],
					},
				}));
			}
		});
	};

	const updatePlatform = (platform: IPlatform) => {
		Object.keys(state).forEach((k) => {
			const key = k as keyof IState;
			const index = state[key].docs.findIndex((item) => item._id === platform._id);
			if (index > -1) {
				state[key]?.docs.splice(index, 1, { ...state[key]?.docs[index], ...platform });
				setState((prevState) => ({
					...prevState,
					[key]: {
						...prevState[key],
					},
				}));
			}
		});
	};

	const updateList = (params: IPaginatedRequest) => {
		const path = location.pathname.split('/')[1];
		const type = DicPlatformType[path];

		navigate(`?filter=${params.filter}&sort=${params.sort || 'last-first'}`, { replace: true });
		loadPlatforms(type, params);
	};

	const onViewMoreHandler = (type: TPlatformType, params: IPaginatedRequest) => {
		navigate(`?filter=${params.filter}&sort=${params.sort || 'last-first'}`, { replace: true });

		if (params.filter === 'highlights') {
			loadPlatforms(type, { ...params, filter: 'mine' });
			loadPlatforms(type, { ...params, filter: 'recommendations' });
			loadPlatforms(type, { ...params, filter: 'participations' });
			loadPlatforms(type, { ...params, filter: 'archived' });
		} else {
			loadPlatforms(type, params);
		}
	};

	React.useEffect(() => {
		const params = {
			filter: new URLSearchParams(location.search)?.get('filter') || 'highlights',
			sort: new URLSearchParams(location.search)?.get('sort') || 'last-first',
		};
		const path = location.pathname.split('/')[1];

		onViewMoreHandler(DicPlatformType[path], params);
		dispatch(Creators.PLATFORMS.updatePlatform({
			syncPlatform: updatePlatform,
		})); // updateIdea function Store
	}, []);

	const params = {
		filter: new URLSearchParams(location.search)?.get('filter') || 'highlights',
		sort: new URLSearchParams(location.search)?.get('sort') || 'last-first',
		limit: GLOBAL.DOCS_PER_PAGE,
	};

	const path = location.pathname.split('/')[1];

	const filters = [
		{
			value: 'mine',
			label: t(`page.${path}.my`),
			show: false,
			icon: <Icon icon={path} />,
		},
		{
			value: 'highlights',
			label: t('global.highlights'),
		},
		{
			value: 'recommendations',
			label: t('global.recommendations'),
		},
		{
			value: 'participations',
			label: t('global.participations'),
		},
		{
			value: 'archived',
			label: t('global.archived'),
		},
	];

	return (
		<GenericGrid id="platforms-page" className="platforms__page s-grid-page" paddingTop={6}>
			<FiltersNavBar
				id="platforms-nav"
				className="w-grid-1 inner"
				onUpdate={updateList}
				params={params}
				filters={filters}
				controlled
				components={{
					LeftButton: (
						<SButton
							type="button"
							color="inherit"
							variant="text"
							sx={{ borderRadius: 0 }}
							startIcon={filters[0].icon && <Icon icon={filters[0].icon} />}
							onClick={() => updateList({ ...params, filter: filters[0].value })}
						>
							{filters[0]?.label}
						</SButton>),
				}}
			/>
			{
				[
					{
						headerLabel: t(`page.${path}.recommended`),
						section: state.recommendations,
						filter: 'recommendations',
						filterLabel: t('global.recommendations'),
					},
					{
						headerLabel: t(`page.${path}.my`),
						section: state.mine,
						filter: 'mine',
						filterLabel: t(`page.${path}.my`),
					},
					{
						headerLabel: t('global.participations'),
						section: state.participations,
						filter: 'participations',
						filterLabel: t('global.participations'),
					},
					{
						headerLabel: t('global.archived'),
						section: state.archived,
						filter: 'archived',
						filterLabel: t('global.archived'),
					},
				].map((item) => (
					<Box className="w-grid-1 inner" key={item.filter}>
						<CardGrid
							id={`${item.filter}-platforms`}
							headerLabel={item.headerLabel}
							headerSubLabel={params.filter === 'highlights' ? t('page.selection') : undefined}
							section={item.section}
							type={path as 'brainstorms' | 'challenges'}
							filter={item.filter}
							hide={params.filter !== 'highlights' && params.filter !== item.filter}
							GridItemProps={{
								xs: 12, sm: 6, md: 4, xl: 3,
							}}
							queryParams={params}
							// @ts-ignore
							onViewMore={params.filter === 'highlights'
								? (queryParams) => onViewMoreHandler(
									DicPlatformType[path],
									{ ...queryParams, filter: item.filter },
								)
								: undefined}
							loadMore={updateList}
							component={(platform: IPlatform) => (
								<PlatformCard
									platform={platform}
									routes={routes}
									sectionLocator={item.filter} // section locator parameter to update and prefix
									updatePlatform={updatePlatform}
									onDelete={onDeleteHandler}
								/>
							)}
						/>
					</Box>
				))
			}
		</GenericGrid>
	);
};

const Translation = withTranslation('common')(_Platforms);

const Platforms = () => (
	<React.Suspense fallback={<PageLoader />}>
		<Translation />
	</React.Suspense>
);

export default Platforms;
