import { spawn } from 'redux-saga/effects';

import forms from './forms/saga';
import ideas from './ideas/saga';
import badges from './badges/saga';
import canvas from './canvas/saga';
import plans from './billing/saga';
import shared from './shared/saga';
import features from './features/saga';
import platforms from './platforms/saga';
import userOrganizationProfile from './userOrganizationProfile/saga';

/** Watcher actions */

export default function* rootSagas() {
	yield spawn(ideas);
	yield spawn(plans);
	yield spawn(forms);
	yield spawn(badges);
	yield spawn(canvas);
	yield spawn(shared);
	yield spawn(features);
	yield spawn(platforms);
	yield spawn(userOrganizationProfile);
}
