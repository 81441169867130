import React, { Suspense } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import URLS from '@constants/URLS';
import { SButton } from '@components/Buttons';
import { useNavigate } from 'react-router-dom';
import {
	AppBar, AppBarProps, Box, IconButton, Toolbar, ToolbarProps,
} from '@mui/material';
import { MdMenu } from 'react-icons/md';
import { SLink } from '@components/Buttons/SLink';

interface UnauthenticatedHeaderProps extends AppBarProps {
	modus?: 'logIn' | 'signUp'
	SToolbarProps?: ToolbarProps
}

declare type TProps = UnauthenticatedHeaderProps & WithTranslation

const _UnauthenticatedHeader = (props: TProps) => {
	const {
		modus, t, SToolbarProps, ...rest
	} = props;

	const navigate = useNavigate();

	const path = {
		logIn: URLS.LOGIN,
		signUp: URLS.SIGN_UP,
	};

	const TABS = {
		zendesk: {
			value: 'zendesk',
			href: t('urls.zendesk'),
			label: t('global.help'),
		},
		terms: {
			value: 'terms',
			href: t('urls.terms'),
			label: t('global.terms'),
		},
	};

	return (
		<AppBar
			id="unauthenticatedAppBar"
			variant="elevation"
			position="static"
			sx={{ backgroundColor: '#F7F7F7', boxShadow: 'none' }}
			{...rest}
		>
			<Toolbar
				id="unauthenticatedToolbar"
				disableGutters
				{...SToolbarProps}
				sx={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
					height: 'var(--header-height)',
					padding: { xs: '1rem', md: 'var(--header-padding-Y) var(--header-padding-X)' },
					...SToolbarProps?.sx,
				}}
			>

				<Box id="unauthenticatedLogo">
					<a href="https://senno.ai" className="navbar-brand" target="_blank" rel="noreferrer">
						<img src="/images/logo-new.svg" width="200" alt="" height={44} />
					</a>
				</Box>

				<Box sx={{ display: 'flex', gap: 6, alignItems: 'center' }}>
					<IconButton sx={{ display: { xs: 'block', sm: 'none' } }}>
						<MdMenu />
					</IconButton>
					{Object.values(TABS).map((item) => (
						<SLink
							href={item.href}
							underline="none"
							color="secondary"
							rel="noopener noreferrer"
							target="_blank"
							sx={{
								color: '#000',
								'&:hover': {
									color: '#000',
									fontWeight: 700,
								},
							}}
						>
							{item.label}
						</SLink>
					))}
					{modus && (
					// @ts-ignore
						<SButton variant="outlined" color="secondary" onClick={() => navigate(path[modus])} size="large">
							{t(`register.${modus}`)}
						</SButton>
					)}
				</Box>
			</Toolbar>
		</AppBar>
	);
};

const Translation = withTranslation('common')(_UnauthenticatedHeader);

const UnauthenticatedHeader = (props: UnauthenticatedHeaderProps) => (
	<Suspense fallback={<div />}>
		<Translation {...props} />
	</Suspense>
);

export default UnauthenticatedHeader;
