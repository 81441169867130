import React, {
	ChangeEvent, Suspense, useState,
} from 'react';
import DataTable, { TableProps, createTheme } from 'react-data-table-component';
import { comparaPorNomeNormalizado } from '@utils/helpers';
import { withTranslation } from 'react-i18next';
import SpinnerLDSRipple from '@components/Shared/SpinnerLDSRipple';
import InputSearch from '../Inputs/InputSearch';

interface IOwnProps extends TableProps<any> {
	placeholder?: string
	hasSearch?: boolean
	value?: string
	className?: string
	onChange?: (event: ChangeEvent<HTMLInputElement>) => void
	background?: string;
	noPagination?: boolean
}

declare type TProps = IOwnProps

const _TableSearch = ({
	// @ts-ignore
	placeholder, columns, className, value, onChange, noPagination,
	hasSearch, data = [], background, disabled, paginationRowsPerPageOptions, ...rest
}: TProps) => {
	const paginationRows = Array.isArray(paginationRowsPerPageOptions) && paginationRowsPerPageOptions.length > 0
		? paginationRowsPerPageOptions : [5, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
	const [filter, setFilter] = useState('');

	const filterByItem = (item: any): boolean => {
		const name = item._nome || item.nome || item.user?._nome || item.user?.nome;
		const email = item.email || item.user?.email;

		if (name && email) {
			return name
				.normalizeString()
				.toLowerCase()
				.includes(filter.normalizeString().toLowerCase())
				|| email
					.normalizeString()
					.toLowerCase()
					.includes(filter.normalizeString().toLowerCase());
		}

		return name
			.normalizeString()
			.toLowerCase()
			.includes(filter.normalizeString().toLowerCase());
	};

	const filteredItems = filter.length > 0
		? data.sort(comparaPorNomeNormalizado).filter(filterByItem)
		: data;

	createTheme('solarized', {
		text: {
			primary: 'rgba(0, 0, 0, 0.75)',
			secondary: 'rgba(0, 0, 0, 0.75)',
			disabled: 'rgba(0, 0, 0, 0.75)',
		},
		// background: {
		//   default: background || 'var(--body-background-color)',
		//   // '#002b36',
		// },
		highlightOnHover: {
			default: 'var(--prussian-blue-color-2)',
			text: '#000',
		},
	}, 'light');

	// @ts-ignore
	const Checkbox = React.forwardRef(({ onClick, ...rest }, ref) => (
		<div className="form-check">
			<input
				type="checkbox"
				id="table-check"
				className="form-check-input"
				disabled={disabled}
				// @ts-ignore
				ref={ref}
				{...rest}
			/>
			<label className="form-check-label" onClick={onClick} htmlFor="table-check" />
		</div>
	));

	return (
		<div className={`data-table-search ${className || ''}`}>
			{hasSearch
				&& (
					<InputSearch
						// @ts-ignore     *REVIEW
						value={hasSearch ? filter : value}
						onChange={(event: ChangeEvent<HTMLInputElement>) => setFilter(event.target.value)}
						placeholder={placeholder}
						className="w-100"
						disabled={disabled}
					/>
				)}
			<DataTable
				// theme="solarized"
				// selectableRowsComponent={<Checkbox />}
				progressComponent={<SpinnerLDSRipple />}
				pagination={!noPagination}
				disabled={disabled}
				data={filteredItems}
				paginationRowsPerPageOptions={paginationRows}
				columns={columns}
				{...rest}
			/>
		</div>
	);
};

const Translation = withTranslation('common')(_TableSearch);

const TableSearch = (props: IOwnProps & TableProps<any>) => (
	<Suspense fallback={<SpinnerLDSRipple />}>
		<Translation {...props} />
	</Suspense>
);

export default TableSearch;
