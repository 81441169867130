// @ts-nocheck
import { SLink } from '@components/Buttons/SLink';
import React, { Suspense, useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Box } from '@mui/material';
// eslint-disable-next-line import/no-extraneous-dependencies
import { styled as MuiStyled } from '@mui/system';

const RedactorBox = MuiStyled(Box)(({ theme }) => ({
	table: {
		td: {
			padding: '0.3rem',
		},
	},
}));

interface IOwnProps {
	limitText?: number
	className?: string
	hasToggle?: boolean
	html: string
}

declare type TProps = IOwnProps & WithTranslation

const RRedactorText = (props: TProps) => {
	const {
		limitText = 250, html, className = '', hasToggle, t,
	} = props;

	const [limit, setLimit] = useState(limitText);
	const text = html?.replace(/<[^>]*>?/gm, '').split('');

	const onToggle = (event: React.MouseEvent<HTMLAnchorElement>) => {
		event.stopPropagation();
		if (limit === limitText) {
			setLimit(limitText.length);
			return;
		}

		setLimit(limitText);
	};

	if (!html) return <div />;

	return hasToggle ? (
		<p className={`redactor-text ${className}`}>
			{text.slice(0, limit).join('')}
			{' '}
			{text.length > limitText
				&& (
					<SLink
						href="# "
						component="button"
						className="see-more"
						underline="always"
						sx={{
							verticalAlign: 'baseline',
						}}
						onClick={onToggle}
					>
						{limit === limitText ? t('global.seeMore') : t('global.seeLess')}
					</SLink>
				)}
		</p>
	)
		: (
			<RedactorBox className="s-jodit-text">
				<div
					className={`jodit_wysiwyg ${className}`}
					style={{ padding: '0px' }}
					dangerouslySetInnerHTML={{
						__html: html,
					}}
				/>
			</RedactorBox>
		);
};

const Translation = withTranslation('common')(RRedactorText);

const RedactorText = (props: IOwnProps) => (
	<Suspense fallback={<div />}>
		<Translation {...props} />
	</Suspense>
);

export default RedactorText;
