interface ICountrie {
	CodigoArea: string
	name: string
	label: string
	value: string
}

const countries: ICountrie[] = [
	{
		CodigoArea: '93',
		name: 'Afeganistão',
		label: 'Afeganistão',
		value: 'AF',
	},
	{
		CodigoArea: '27',
		name: 'África do Sul',
		label: 'África do Sul',
		value: 'ZA',
	},
	{
		CodigoArea: '355',
		name: 'Albânia',
		label: 'Albânia',
		value: 'AL',
	},
	{
		CodigoArea: '49',
		name: 'Alemanha',
		label: 'Alemanha',
		value: 'DE',
	},
	{
		CodigoArea: '376',
		name: 'Andorra',
		label: 'Andorra',
		value: 'AD',
	},
	{
		CodigoArea: '244',
		name: 'Angola',
		label: 'Angola',
		value: 'AO',
	},
	{
		CodigoArea: '1264',
		name: 'Anguila',
		label: 'Anguila',
		value: 'AI',
	},
	{
		CodigoArea: '672',
		name: 'Antártida',
		label: 'Antártida',
		value: 'AQ',
	},
	{
		CodigoArea: '1268',
		name: 'Antígua e Barbuda',
		label: 'Antígua e Barbuda',
		value: 'AG',
	},
	{
		CodigoArea: '599',
		name: 'Antilhas Holandesas',
		label: 'Antilhas Holandesas',
		value: 'AN',
	},
	{
		CodigoArea: '966',
		name: 'Arábia Saudita',
		label: 'Arábia Saudita',
		value: 'SA',
	},
	{
		CodigoArea: '213',
		name: 'Argélia',
		label: 'Argélia',
		value: 'DZ',
	},
	{
		CodigoArea: '54',
		name: 'Argentina',
		label: 'Argentina',
		value: 'AR',
	},
	{
		CodigoArea: '374',
		name: 'Armênia',
		label: 'Armênia',
		value: 'AM',
	},
	{
		CodigoArea: '297',
		name: 'Aruba',
		label: 'Aruba',
		value: 'AW',
	},
	{
		CodigoArea: '61',
		name: 'Austrália',
		label: 'Austrália',
		value: 'AU',
	},
	{
		CodigoArea: '43',
		name: 'Áustria',
		label: 'Áustria',
		value: 'AT',
	},
	{
		CodigoArea: '',
		name: 'Autoridade Palestina',
		label: 'Autoridade Palestina',
		value: 'PS',
	},
	{
		CodigoArea: '994',
		name: 'Azerbaijão',
		label: 'Azerbaijão',
		value: 'AZ',
	},
	{
		CodigoArea: '1242',
		name: 'Bahamas',
		label: 'Bahamas',
		value: 'BS',
	},
	{
		CodigoArea: '973',
		name: 'Bahrein',
		label: 'Bahrein',
		value: 'BH',
	},
	{
		CodigoArea: '880',
		name: 'Bangladesh',
		label: 'Bangladesh',
		value: 'BD',
	},
	{
		CodigoArea: '1246',
		name: 'Barbados',
		label: 'Barbados',
		value: 'BB',
	},
	{
		CodigoArea: '375',
		name: 'Belarus',
		label: 'Belarus',
		value: 'BY',
	},
	{
		CodigoArea: '32',
		name: 'Bélgica',
		label: 'Bélgica',
		value: 'BE',
	},
	{
		CodigoArea: '501',
		name: 'Belize',
		label: 'Belize',
		value: 'BZ',
	},
	{
		CodigoArea: '229',
		name: 'Benin',
		label: 'Benin',
		value: 'BJ',
	},
	{
		CodigoArea: '1441',
		name: 'Bermuda',
		label: 'Bermuda',
		value: 'BM',
	},
	{
		CodigoArea: '591',
		name: 'Bolívia',
		label: 'Bolívia',
		value: 'BO',
	},
	{
		CodigoArea: '387',
		name: 'Bósnia e Herzegovina',
		label: 'Bósnia e Herzegovina',
		value: 'BA',
	},
	{
		CodigoArea: '267',
		name: 'Botsuana',
		label: 'Botsuana',
		value: 'BW',
	},
	{
		CodigoArea: '55',
		name: 'Brazil',
		label: 'Brazil',
		value: 'BR',
	},
	{
		CodigoArea: '673',
		name: 'Brunei',
		label: 'Brunei',
		value: 'BN',
	},
	{
		CodigoArea: '359',
		name: 'Bulgária',
		label: 'Bulgária',
		value: 'BG',
	},
	{
		CodigoArea: '226',
		name: 'Burquina Faso',
		label: 'Burquina Faso',
		value: 'BF',
	},
	{
		CodigoArea: '257',
		name: 'Burundi',
		label: 'Burundi',
		value: 'BI',
	},
	{
		CodigoArea: '975',
		name: 'Butão',
		label: 'Butão',
		value: 'BT',
	},
	{
		CodigoArea: '238',
		name: 'Cabo Verde',
		label: 'Cabo Verde',
		value: 'CV',
	},
	{
		CodigoArea: '237',
		name: 'Camarões',
		label: 'Camarões',
		value: 'CM',
	},
	{
		CodigoArea: '855',
		name: 'Camboja',
		label: 'Camboja',
		value: 'KH',
	},
	{
		CodigoArea: '1',
		name: 'Canadá',
		label: 'Canadá',
		value: 'CA',
	},
	{
		CodigoArea: '974',
		name: 'Catar',
		label: 'Catar',
		value: 'QA',
	},
	{
		CodigoArea: '7',
		name: 'Cazaquistão',
		label: 'Cazaquistão',
		value: 'KZ',
	},
	{
		CodigoArea: '235',
		name: 'Chade',
		label: 'Chade',
		value: 'TD',
	},
	{
		CodigoArea: '56',
		name: 'Chile',
		label: 'Chile',
		value: 'CL',
	},
	{
		CodigoArea: '86',
		name: 'China',
		label: 'China',
		value: 'CN',
	},
	{
		CodigoArea: '357',
		name: 'Chipre',
		label: 'Chipre',
		value: 'CY',
	},
	{
		CodigoArea: '39',
		name: 'Cidade do Vaticano',
		label: 'Cidade do Vaticano',
		value: 'VA',
	},
	{
		CodigoArea: '65',
		name: 'Cingapura',
		label: 'Cingapura',
		value: 'SG',
	},
	{
		CodigoArea: '57',
		name: 'Colômbia',
		label: 'Colômbia',
		value: 'CO',
	},
	{
		CodigoArea: '269',
		name: 'Comoros',
		label: 'Comoros',
		value: 'KM',
	},
	{
		CodigoArea: '242',
		name: 'Congo',
		label: 'Congo',
		value: 'CG',
	},
	{
		CodigoArea: '243',
		name: 'Congo, República Democrática do',
		label: 'Congo, República Democrática do',
		value: 'CD',
	},
	{
		CodigoArea: '82',
		name: 'Coreia',
		label: 'Coreia',
		value: 'KR',
	},
	{
		CodigoArea: '850',
		name: 'Coreia do Norte',
		label: 'Coreia do Norte',
		value: 'KP',
	},
	{
		CodigoArea: '506',
		name: 'Costa Rica',
		label: 'Costa Rica',
		value: 'CR',
	},
	{
		CodigoArea: '225',
		name: "Côte d'Ivoire (Costa do Marfim)",
		label: "Côte d'Ivoire (Costa do Marfim)",
		value: 'CI',
	},
	{
		CodigoArea: '385',
		name: 'Croácia',
		label: 'Croácia',
		value: 'HR',
	},
	{
		CodigoArea: '53',
		name: 'Cuba',
		label: 'Cuba',
		value: 'CU',
	},
	{
		CodigoArea: '45',
		name: 'Dinamarca',
		label: 'Dinamarca',
		value: 'DK',
	},
	{
		CodigoArea: '253',
		name: 'Djibuti',
		label: 'Djibuti',
		value: 'DJ',
	},
	{
		CodigoArea: '1767',
		name: 'Dominica',
		label: 'Dominica',
		value: 'DM',
	},
	{
		CodigoArea: '20',
		name: 'Egito',
		label: 'Egito',
		value: 'EG',
	},
	{
		CodigoArea: '503',
		name: 'El Salvador',
		label: 'El Salvador',
		value: 'SV',
	},
	{
		CodigoArea: '971',
		name: 'Emirados Árabes Unidos',
		label: 'Emirados Árabes Unidos',
		value: 'AE',
	},
	{
		CodigoArea: '593',
		name: 'Equador',
		label: 'Equador',
		value: 'EC',
	},
	{
		CodigoArea: '291',
		name: 'Eritreia',
		label: 'Eritreia',
		value: 'ER',
	},
	{
		CodigoArea: '421',
		name: 'Eslováquia',
		label: 'Eslováquia',
		value: 'SK',
	},
	{
		CodigoArea: '386',
		name: 'Eslovênia',
		label: 'Eslovênia',
		value: 'SI',
	},
	{
		CodigoArea: '34',
		name: 'Espanha',
		label: 'Espanha',
		value: 'ES',
	},
	{
		CodigoArea: '1',
		name: 'Estados Unidos',
		label: 'Estados Unidos',
		value: 'US',
	},
	{
		CodigoArea: '372',
		name: 'Estônia',
		label: 'Estônia',
		value: 'EE',
	},
	{
		CodigoArea: '251',
		name: 'Etiópia',
		label: 'Etiópia',
		value: 'ET',
	},
	{
		CodigoArea: '63',
		name: 'Filipinas',
		label: 'Filipinas',
		value: 'PH',
	},
	{
		CodigoArea: '358',
		name: 'Finlândia',
		label: 'Finlândia',
		value: 'FI',
	},
	{
		CodigoArea: '33',
		name: 'França',
		label: 'França',
		value: 'FR',
	},
	{
		CodigoArea: '241',
		name: 'Gabão',
		label: 'Gabão',
		value: 'GA',
	},
	{
		CodigoArea: '220',
		name: 'Gâmbia',
		label: 'Gâmbia',
		value: 'GM',
	},
	{
		CodigoArea: '233',
		name: 'Gana',
		label: 'Gana',
		value: 'GH',
	},
	{
		CodigoArea: '995',
		name: 'Geórgia',
		label: 'Geórgia',
		value: 'GE',
	},
	{
		CodigoArea: '',
		name: 'Geórgia do Sul e Ilhas Sandwich do Sul',
		label: 'Geórgia do Sul e Ilhas Sandwich do Sul',
		value: 'GS',
	},
	{
		CodigoArea: '350',
		name: 'Gibraltar',
		label: 'Gibraltar',
		value: 'GI',
	},
	{
		CodigoArea: '1473',
		name: 'Granada',
		label: 'Granada',
		value: 'GD',
	},
	{
		CodigoArea: '30',
		name: 'Grécia',
		label: 'Grécia',
		value: 'GR',
	},
	{
		CodigoArea: '299',
		name: 'Groenlândia',
		label: 'Groenlândia',
		value: 'GL',
	},
	{
		CodigoArea: '',
		name: 'Guadalupe',
		label: 'Guadalupe',
		value: 'GP',
	},
	{
		CodigoArea: '1671',
		name: 'Guam',
		label: 'Guam',
		value: 'GU',
	},
	{
		CodigoArea: '502',
		name: 'Guatemala',
		label: 'Guatemala',
		value: 'GT',
	},
	{
		CodigoArea: '',
		name: 'Guernsey',
		label: 'Guernsey',
		value: 'GG',
	},
	{
		CodigoArea: '592',
		name: 'Guiana',
		label: 'Guiana',
		value: 'GY',
	},
	{
		CodigoArea: '',
		name: 'Guiana Francesa',
		label: 'Guiana Francesa',
		value: 'GF',
	},
	{
		CodigoArea: '224',
		name: 'Guiné',
		label: 'Guiné',
		value: 'GN',
	},
	{
		CodigoArea: '240',
		name: 'Guiné Equatorial',
		label: 'Guiné Equatorial',
		value: 'GQ',
	},
	{
		CodigoArea: '245',
		name: 'Guiné-Bissau',
		label: 'Guiné-Bissau',
		value: 'GW',
	},
	{
		CodigoArea: '509',
		name: 'Haiti',
		label: 'Haiti',
		value: 'HT',
	},
	{
		CodigoArea: '504',
		name: 'Honduras',
		label: 'Honduras',
		value: 'HN',
	},
	{
		CodigoArea: '852',
		name: 'Hong Kong S. A. R',
		label: 'Hong Kong S. A. R',
		value: 'HK',
	},
	{
		CodigoArea: '36',
		name: 'Hungria',
		label: 'Hungria',
		value: 'HU',
	},
	{
		CodigoArea: '967',
		name: 'Iêmen',
		label: 'Iêmen',
		value: 'YE',
	},
	{
		CodigoArea: '',
		name: 'Ilha Bouvet',
		label: 'Ilha Bouvet',
		value: 'BV',
	},
	{
		CodigoArea: '61',
		name: 'Ilha Christmas',
		label: 'Ilha Christmas',
		value: 'CX',
	},
	{
		CodigoArea: '44',
		name: 'Ilha de Man',
		label: 'Ilha de Man',
		value: 'IM',
	},
	{
		CodigoArea: '672',
		name: 'Ilha Norfolk',
		label: 'Ilha Norfolk',
		value: 'NF',
	},
	{
		CodigoArea: '1345',
		name: 'Ilhas Cayman',
		label: 'Ilhas Cayman',
		value: 'KY',
	},
	{
		CodigoArea: '61',
		name: 'Ilhas Cocos (Keeling)',
		label: 'Ilhas Cocos (Keeling)',
		value: 'CC',
	},
	{
		CodigoArea: '682',
		name: 'Ilhas Cook',
		label: 'Ilhas Cook',
		value: 'CK',
	},
	{
		CodigoArea: '298',
		name: 'Ilhas Faroés',
		label: 'Ilhas Faroés',
		value: 'FO',
	},
	{
		CodigoArea: '679',
		name: 'Ilhas Fiji',
		label: 'Ilhas Fiji',
		value: 'FJ',
	},
	{
		CodigoArea: '',
		name: 'Ilhas Heard e Ilhas McDonald',
		label: 'Ilhas Heard e Ilhas McDonald',
		value: 'HM',
	},
	{
		CodigoArea: '500',
		name: 'Ilhas Malvinas (Falkland Islands)',
		label: 'Ilhas Malvinas (Falkland Islands)',
		value: 'FK',
	},
	{
		CodigoArea: '1670',
		name: 'Ilhas Marianas do Norte',
		label: 'Ilhas Marianas do Norte',
		value: 'MP',
	},
	{
		CodigoArea: '692',
		name: 'Ilhas Marshall',
		label: 'Ilhas Marshall',
		value: 'MH',
	},
	{
		CodigoArea: '677',
		name: 'Ilhas Salomão',
		label: 'Ilhas Salomão',
		value: 'SB',
	},
	{
		CodigoArea: '1649',
		name: 'Ilhas Turks e Caicos',
		label: 'Ilhas Turks e Caicos',
		value: 'TC',
	},
	{
		CodigoArea: '1340',
		name: 'Ilhas Virgens Americanas',
		label: 'Ilhas Virgens Americanas',
		value: 'VI',
	},
	{
		CodigoArea: '1284',
		name: 'Ilhas Virgens Britânicas',
		label: 'Ilhas Virgens Britânicas',
		value: 'VG',
	},
	{
		CodigoArea: '91',
		name: 'Índia',
		label: 'Índia',
		value: 'IN',
	},
	{
		CodigoArea: '62',
		name: 'Indonésia',
		label: 'Indonésia',
		value: 'ID',
	},
	{
		CodigoArea: '98',
		name: 'Irã',
		label: 'Irã',
		value: 'IR',
	},
	{
		CodigoArea: '964',
		name: 'Iraque',
		label: 'Iraque',
		value: 'IQ',
	},
	{
		CodigoArea: '353',
		name: 'Irlanda',
		label: 'Irlanda',
		value: 'IE',
	},
	{
		CodigoArea: '354',
		name: 'Islândia',
		label: 'Islândia',
		value: 'IS',
	},
	{
		CodigoArea: '972',
		name: 'Israel',
		label: 'Israel',
		value: 'IL',
	},
	{
		CodigoArea: '39',
		name: 'Itália',
		label: 'Itália',
		value: 'IT',
	},
	{
		CodigoArea: '1876',
		name: 'Jamaica',
		label: 'Jamaica',
		value: 'JM',
	},
	{
		CodigoArea: '81',
		name: 'Japão',
		label: 'Japão',
		value: 'JP',
	},
	{
		CodigoArea: '',
		name: 'Jersey',
		label: 'Jersey',
		value: 'JE',
	},
	{
		CodigoArea: '962',
		name: 'Jordânia',
		label: 'Jordânia',
		value: 'JO',
	},
	{
		CodigoArea: '686',
		name: 'Kiribati',
		label: 'Kiribati',
		value: 'KI',
	},
	{
		CodigoArea: '965',
		name: 'Kuaite',
		label: 'Kuaite',
		value: 'KW',
	},
	{
		CodigoArea: '856',
		name: 'Laos',
		label: 'Laos',
		value: 'LA',
	},
	{
		CodigoArea: '266',
		name: 'Lesoto',
		label: 'Lesoto',
		value: 'LS',
	},
	{
		CodigoArea: '',
		name: 'Letônia',
		label: 'Letônia',
		value: 'LV',
	},
	{
		CodigoArea: '961',
		name: 'Líbano',
		label: 'Líbano',
		value: 'LB',
	},
	{
		CodigoArea: '231',
		name: 'Libéria',
		label: 'Libéria',
		value: 'LR',
	},
	{
		CodigoArea: '218',
		name: 'Líbia',
		label: 'Líbia',
		value: 'LY',
	},
	{
		CodigoArea: '423',
		name: 'Liechtenstein',
		label: 'Liechtenstein',
		value: 'LI',
	},
	{
		CodigoArea: '370',
		name: 'Lituânia',
		label: 'Lituânia',
		value: 'LT',
	},
	{
		CodigoArea: '352',
		name: 'Luxemburgo',
		label: 'Luxemburgo',
		value: 'LU',
	},
	{
		CodigoArea: '389',
		name: 'Macedônia, Antiga República Iugoslava da',
		label: 'Macedônia, Antiga República Iugoslava da',
		value: 'MK',
	},
	{
		CodigoArea: '261',
		name: 'Madagascar',
		label: 'Madagascar',
		value: 'MG',
	},
	{
		CodigoArea: '60',
		name: 'Malásia',
		label: 'Malásia',
		value: 'MY',
	},
	{
		CodigoArea: '265',
		name: 'Malaui',
		label: 'Malaui',
		value: 'MW',
	},
	{
		CodigoArea: '960',
		name: 'Maldivas',
		label: 'Maldivas',
		value: 'MV',
	},
	{
		CodigoArea: '223',
		name: 'Mali',
		label: 'Mali',
		value: 'ML',
	},
	{
		CodigoArea: '356',
		name: 'Malta',
		label: 'Malta',
		value: 'MT',
	},
	{
		CodigoArea: '212',
		name: 'Marrocos',
		label: 'Marrocos',
		value: 'MA',
	},
	{
		CodigoArea: '',
		name: 'Martinica',
		label: 'Martinica',
		value: 'MQ',
	},
	{
		CodigoArea: '230',
		name: 'Maurício',
		label: 'Maurício',
		value: 'MU',
	},
	{
		CodigoArea: '222',
		name: 'Mauritânia',
		label: 'Mauritânia',
		value: 'MR',
	},
	{
		CodigoArea: '262',
		name: 'Mayotte',
		label: 'Mayotte',
		value: 'YT',
	},
	{
		CodigoArea: '52',
		name: 'México',
		label: 'México',
		value: 'MX',
	},
	{
		CodigoArea: '691',
		name: 'Micronésia',
		label: 'Micronésia',
		value: 'FM',
	},
	{
		CodigoArea: '258',
		name: 'Moçambique',
		label: 'Moçambique',
		value: 'MZ',
	},
	{
		CodigoArea: '373',
		name: 'Moldova',
		label: 'Moldova',
		value: 'MD',
	},
	{
		CodigoArea: '377',
		name: 'Mônaco',
		label: 'Mônaco',
		value: 'MC',
	},
	{
		CodigoArea: '976',
		name: 'Mongólia',
		label: 'Mongólia',
		value: 'MN',
	},
	{
		CodigoArea: '382',
		name: 'Montenegro',
		label: 'Montenegro',
		value: 'ME',
	},
	{
		CodigoArea: '1664',
		name: 'Montserrat',
		label: 'Montserrat',
		value: 'MS',
	},
	{
		CodigoArea: '95',
		name: 'Myanma',
		label: 'Myanma',
		value: 'MM',
	},
	{
		CodigoArea: '264',
		name: 'Namíbia',
		label: 'Namíbia',
		value: 'NA',
	},
	{
		CodigoArea: '674',
		name: 'Nauru',
		label: 'Nauru',
		value: 'NR',
	},
	{
		CodigoArea: '977',
		name: 'Nepal',
		label: 'Nepal',
		value: 'NP',
	},
	{
		CodigoArea: '505',
		name: 'Nicarágua',
		label: 'Nicarágua',
		value: 'NI',
	},
	{
		CodigoArea: '227',
		name: 'Níger',
		label: 'Níger',
		value: 'NE',
	},
	{
		CodigoArea: '234',
		name: 'Nigéria',
		label: 'Nigéria',
		value: 'NG',
	},
	{
		CodigoArea: '683',
		name: 'Niue',
		label: 'Niue',
		value: 'NU',
	},
	{
		CodigoArea: '47',
		name: 'Noruega',
		label: 'Noruega',
		value: 'NO',
	},
	{
		CodigoArea: '687',
		name: 'Nova Caledônia',
		label: 'Nova Caledônia',
		value: 'NC',
	},
	{
		CodigoArea: '64',
		name: 'Nova Zelândia',
		label: 'Nova Zelândia',
		value: 'NZ',
	},
	{
		CodigoArea: '968',
		name: 'Omã',
		label: 'Omã',
		value: 'OM',
	},
	{
		CodigoArea: '31',
		name: 'Países Baixos',
		label: 'Países Baixos',
		value: 'NL',
	},
	{
		CodigoArea: '680',
		name: 'Palau',
		label: 'Palau',
		value: 'PW',
	},
	{
		CodigoArea: '507',
		name: 'Panamá',
		label: 'Panamá',
		value: 'PA',
	},
	{
		CodigoArea: '675',
		name: 'Papua-Nova Guiné',
		label: 'Papua-Nova Guiné',
		value: 'PG',
	},
	{
		CodigoArea: '92',
		name: 'Paquistão',
		label: 'Paquistão',
		value: 'PK',
	},
	{
		CodigoArea: '595',
		name: 'Paraguai',
		label: 'Paraguai',
		value: 'PY',
	},
	{
		CodigoArea: '51',
		name: 'Peru',
		label: 'Peru',
		value: 'PE',
	},
	{
		CodigoArea: '870',
		name: 'Pitcairn',
		label: 'Pitcairn',
		value: 'PN',
	},
	{
		CodigoArea: '689',
		name: 'Polinésia Francesa',
		label: 'Polinésia Francesa',
		value: 'PF',
	},
	{
		CodigoArea: '48',
		name: 'Polônia',
		label: 'Polônia',
		value: 'PL',
	},
	{
		CodigoArea: '1',
		name: 'Porto Rico',
		label: 'Porto Rico',
		value: 'PR',
	},
	{
		CodigoArea: '351',
		name: 'Portugal',
		label: 'Portugal',
		value: 'PT',
	},
	{
		CodigoArea: '254',
		name: 'Quênia',
		label: 'Quênia',
		value: 'KE',
	},
	{
		CodigoArea: '996',
		name: 'Quirguistão',
		label: 'Quirguistão',
		value: 'KG',
	},
	{
		CodigoArea: '853',
		name: 'RAE de Macau',
		label: 'RAE de Macau',
		value: 'MO',
	},
	{
		CodigoArea: '44',
		name: 'Reino Unido',
		label: 'Reino Unido',
		value: 'UK',
	},
	{
		CodigoArea: '236',
		name: 'República Centro-Africana',
		label: 'República Centro-Africana',
		value: 'CF',
	},
	{
		CodigoArea: '1809',
		name: 'República Dominicana',
		label: 'República Dominicana',
		value: 'DO',
	},
	{
		CodigoArea: '420',
		name: 'República Tcheca',
		label: 'República Tcheca',
		value: 'CZ',
	},
	{
		CodigoArea: '',
		name: 'Reunião',
		label: 'Reunião',
		value: 'RE',
	},
	{
		CodigoArea: '40',
		name: 'Romênia',
		label: 'Romênia',
		value: 'RO',
	},
	{
		CodigoArea: '250',
		name: 'Ruanda',
		label: 'Ruanda',
		value: 'RW',
	},
	{
		CodigoArea: '7',
		name: 'Rússia',
		label: 'Rússia',
		value: 'RU',
	},
	{
		CodigoArea: '508',
		name: 'Saint-Pierre e Miquelon',
		label: 'Saint-Pierre e Miquelon',
		value: 'PM',
	},
	{
		CodigoArea: '685',
		name: 'Samoa',
		label: 'Samoa',
		value: 'WS',
	},
	{
		CodigoArea: '1684',
		name: 'Samoa Americana',
		label: 'Samoa Americana',
		value: 'AS',
	},
	{
		CodigoArea: '378',
		name: 'San Marino',
		label: 'San Marino',
		value: 'SM',
	},
	{
		CodigoArea: '290',
		name: 'Santa Helena',
		label: 'Santa Helena',
		value: 'SH',
	},
	{
		CodigoArea: '1758',
		name: 'Santa Lúcia',
		label: 'Santa Lúcia',
		value: 'LC',
	},
	{
		CodigoArea: '239',
		name: 'São Tomé e Príncipe',
		label: 'São Tomé e Príncipe',
		value: 'ST',
	},
	{
		CodigoArea: '1784',
		name: 'São Vicente e Granadinas',
		label: 'São Vicente e Granadinas',
		value: 'VC',
	},
	{
		CodigoArea: '248',
		name: 'Seicheles',
		label: 'Seicheles',
		value: 'SC',
	},
	{
		CodigoArea: '221',
		name: 'Senegal',
		label: 'Senegal',
		value: 'SN',
	},
	{
		CodigoArea: '232',
		name: 'Serra Leoa',
		label: 'Serra Leoa',
		value: 'SL',
	},
	{
		CodigoArea: '381',
		name: 'Sérvia',
		label: 'Sérvia',
		value: 'RS',
	},
	{
		CodigoArea: '963',
		name: 'Síria',
		label: 'Síria',
		value: 'SY',
	},
	{
		CodigoArea: '252',
		name: 'Somália',
		label: 'Somália',
		value: 'SO',
	},
	{
		CodigoArea: '94',
		name: 'Sri Lanka',
		label: 'Sri Lanka',
		value: 'LK',
	},
	{
		CodigoArea: '1869',
		name: 'St. Kitts e Névis',
		label: 'St. Kitts e Névis',
		value: 'KN',
	},
	{
		CodigoArea: '268',
		name: 'Suazilândia',
		label: 'Suazilândia',
		value: 'SZ',
	},
	{
		CodigoArea: '249',
		name: 'Sudão',
		label: 'Sudão',
		value: 'SD',
	},
	{
		CodigoArea: '46',
		name: 'Suécia',
		label: 'Suécia',
		value: 'SE',
	},
	{
		CodigoArea: '41',
		name: 'Suíça',
		label: 'Suíça',
		value: 'CH',
	},
	{
		CodigoArea: '597',
		name: 'Suriname',
		label: 'Suriname',
		value: 'SR',
	},
	{
		CodigoArea: '',
		name: 'Svalbard',
		label: 'Svalbard',
		value: 'SJ',
	},
	{
		CodigoArea: '992',
		name: 'Tagiquistão',
		label: 'Tagiquistão',
		value: 'TJ',
	},
	{
		CodigoArea: '66',
		name: 'Tailândia',
		label: 'Tailândia',
		value: 'TH',
	},
	{
		CodigoArea: '886',
		name: 'Taiwan',
		label: 'Taiwan',
		value: 'TW',
	},
	{
		CodigoArea: '255',
		name: 'Tanzânia',
		label: 'Tanzânia',
		value: 'TZ',
	},
	{
		CodigoArea: '',
		name: 'Terras Austrais e Antárticas Francesas',
		label: 'Terras Austrais e Antárticas Francesas',
		value: 'TF',
	},
	{
		CodigoArea: '',
		name: 'Território Britânico do Oceano Índico',
		label: 'Território Britânico do Oceano Índico',
		value: 'IO',
	},
	{
		CodigoArea: '',
		name: 'Territórios Insulares dos EUA',
		label: 'Territórios Insulares dos EUA',
		value: 'UM',
	},
	{
		CodigoArea: '670',
		name: 'Timor Leste',
		label: 'Timor Leste',
		value: 'TP',
	},
	{
		CodigoArea: '228',
		name: 'Togo',
		label: 'Togo',
		value: 'TG',
	},
	{
		CodigoArea: '690',
		name: 'Tokelau',
		label: 'Tokelau',
		value: 'TK',
	},
	{
		CodigoArea: '676',
		name: 'Tonga',
		label: 'Tonga',
		value: 'TO',
	},
	{
		CodigoArea: '1868',
		name: 'Trinidad e Tobago',
		label: 'Trinidad e Tobago',
		value: 'TT',
	},
	{
		CodigoArea: '216',
		name: 'Tunísia',
		label: 'Tunísia',
		value: 'TN',
	},
	{
		CodigoArea: '993',
		name: 'Turcomenistão',
		label: 'Turcomenistão',
		value: 'TM',
	},
	{
		CodigoArea: '90',
		name: 'Turquia',
		label: 'Turquia',
		value: 'TR',
	},
	{
		CodigoArea: '688',
		name: 'Tuvalu',
		label: 'Tuvalu',
		value: 'TV',
	},
	{
		CodigoArea: '380',
		name: 'Ucrânia',
		label: 'Ucrânia',
		value: 'UA',
	},
	{
		CodigoArea: '256',
		name: 'Uganda',
		label: 'Uganda',
		value: 'UG',
	},
	{
		CodigoArea: '598',
		name: 'Uruguai',
		label: 'Uruguai',
		value: 'UY',
	},
	{
		CodigoArea: '998',
		name: 'Uzbequistão',
		label: 'Uzbequistão',
		value: 'UZ',
	},
	{
		CodigoArea: '678',
		name: 'Vanuatu',
		label: 'Vanuatu',
		value: 'VU',
	},
	{
		CodigoArea: '58',
		name: 'Venezuela',
		label: 'Venezuela',
		value: 'VE',
	},
	{
		CodigoArea: '84',
		name: 'Vietnã',
		label: 'Vietnã',
		value: 'VN',
	},
	{
		CodigoArea: '681',
		name: 'Wallis e Futuna',
		label: 'Wallis e Futuna',
		value: 'WF',
	},
	{
		CodigoArea: '260',
		name: 'Zâmbia',
		label: 'Zâmbia',
		value: 'ZM',
	},
	{
		CodigoArea: '263',
		name: 'Zimbábue',
		label: 'Zimbábue',
		value: 'ZW',
	},
];

export default countries;
