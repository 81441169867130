// @ts-nocheck
import { IForm, IFormInsert } from 'src/@types/IForm';
import { axiosInstance } from '@services/AxiosInstance';

const APIForms = {
	getFormsByIdea(ideaId: string) {
		return axiosInstance.get(`/forms/${ideaId}/idea/`);
	},
	getFormsByPlatform(platformId: string) {
		return axiosInstance.get(`/forms/${platformId}/platform/`);
	},
	getTemplates(ids) {
		return axiosInstance.get(`/templates/form?_id=${ids}`);
	},
	postCreate(form: IForm) {
		return axiosInstance.post('/forms', form);
	},
	patchForm(formId: string, body) {
		return axiosInstance.patch(`/forms/${formId}`, body);
	},
	postFormAssociate(body: IFormInsert) {
		return axiosInstance.post('/forms/associate/', body);
	},
	deleteForm(formId: string) {
		return axiosInstance.delete(`/forms/${formId}`);
	},
	putRestoreForm(formId: string) {
		return axiosInstance.put(`/forms/${formId}/restore_template`, {});
	},
};

export default APIForms;
