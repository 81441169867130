import React, { Suspense } from 'react';
import { DynamicCircle } from '@components/DynamicCircle';
import { SButton, HelpButton } from '@components/Buttons';
import { createBackground } from '@utils/helpers';
import { WithTranslation, withTranslation } from 'react-i18next';
import Icon from '@components/Shared/Icon';
import { Box, Typography } from '@mui/material';

interface ITemplateDetailsProps {
	readonly name?: string
	readonly onReset?: () => void
	readonly onChange?: () => void
	readonly color?: string
	icon?: string
	isBlank?: string
}

declare type TTemplateDetailsProps = ITemplateDetailsProps & WithTranslation;

const TTemplateDetails = (props: TTemplateDetailsProps) => {
	const {
		color = 'rgb(112, 112, 112)', name, icon = 'template', t, isBlank, onReset,
	  onChange,
	} = props;

	return (
		<Box sx={{ display: 'flex', flexDirection: 'column' }}>
			{isBlank ? (
				<Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
					<Typography className="">{t('templates.noSelected')}</Typography>
					<SButton
						type="button"
						color="primary"
						variant="text"
						size="small"
						onClick={onChange}
						startIcon={<Icon icon="plus" />}
					>
						{t('global.add')}
					</SButton>
				</Box>
			) : (
				<Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
					<Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
						<DynamicCircle
							color={color}
							background={createBackground(color)}
							icon={icon}
						/>
						<Typography>
							{name}
						</Typography>
					</Box>
					{typeof onChange === 'function' && (
						<SButton
							type="button"
							onClick={onChange}
							size="small"
							startIcon={<Icon icon="sync" />}
						>
							{t('global.change')}
						</SButton>
					)}
					{typeof onReset === 'function' && (
						<SButton
							type="button"
							onClick={onReset}
							size="small"
							startIcon={<Icon icon="fa fa-arrow-rotate-left" />}
						>
							{t('form.reset')}
						</SButton>
					)}
				</Box>
			)}
		</Box>
	);
};

const Translation = withTranslation('common')(TTemplateDetails);

const TemplateDetails = (props: ITemplateDetailsProps) => (
	<Suspense fallback={<div />}>
		<Translation {...props} />
	</Suspense>
);

export { TemplateDetails };
