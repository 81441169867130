import ModalTeam from '@components/Modals/Organization/ModalTeam';
import ModalPlan from '@components/Modals/Organization/ModalPlan';
import ModalTrails from '@components/STour/ModalTrails';
import ModalBackup from '@components/Modals/ModalBackup';
import ModalReport from '@components/Modals/ModalReport';
import ModalArticle from '@components/Signals/ModalArticle';
import ModalForm from '@components/Modals/Forms/ModalForm';
import ModalDowngrade from '@components/Modals/Organization/ModalDowngrade';
import ModalDocuments from '@components/Signals/ModalDocuments';
import ModalCreate from '@components/Modals/Shared/ModalCreate';
import ModalConnect from '@components/Modals/Ideas/ModalConnect';
import ModalBadge from '@components/Modals/Badges/ModalBadge';
import ModalInviteMember from '@components/Modals/Organization/ModalInviteMember';
import ModalDashboard from '@components/Modals/Shared/ModalDashboard';
import ModalTemplate from '@components/Modals/Templates/ModalTemplate';
import ModalCanvasEdit from '@components/Modals/Canvas/ModalCanvasEdit';
import ModalIdeaApprove from '@components/Modals/Ideas/ModalIdeaApprove';
import ModalIdeaEvaluation from '@components/Modals/Ideas/ModalIdeaEvaluation';
import ModalPlatformsToAdd from '@components/Modals/Ideas/ModalPlatformsToAdd';
import ModalTemplateCreate from '@components/Modals/Templates/ModalTemplateCreate';
import ModalSponsorsForFeature from '@components/Modals/Features/ModalSponsorsForFeature';
import { ModalOrganizationOut } from '@components/Modals/Organization/ModalOrganizationOut';
import ModalOrganizationCreate from '@components/Modals/Organization/ModalOrganizationCreate';
import ModalEvaluationsForCriteria from '@components/Modals/Ideas/ModalEvaluationsForCriteria';
import ModalBatchInvite from '@components/Modals/Organization/ModalBatchInvite';

const rootPath = '/';

const ModalIsolatedRouter = {
	MODAL_PLATFORMS: {
		path: `${rootPath}:rootPath/:platformType`,
		component: ModalPlatformsToAdd,
		fullScreen: true,
		hideBack: true,
		maxWidth: 'xl',
	},
	MODAL_CANVAS: {
		path: `${rootPath}:rootPath/canvas/:model`,
		component: ModalCanvasEdit,
		fullScreen: true,
		maxWidth: 'xl',
	},
	MODAL_FORMS: {
		path: `${rootPath}:rootPath/<global.form>s/:form`,
		component: ModalForm,
		fullScreen: true,
		maxWidth: 'xl',
	},
	MODAL_VIEW_BADGES_BY_NAME: {
		path: `${rootPath}/<global.badges>/:badge`,
		component: ModalBadge,
		fullScreen: true,
		maxWidth: 'xl',
	},
	MODAL_APPROVE: {
		path: `${rootPath}:idea/<global.evaluation>`,
		component: ModalIdeaApprove,
		fullScreen: true,
		maxWidth: 'xl',
	},
	MODAL_EVALUATION: {
		path: `${rootPath}:idea/<global.evaluation>/:criteria`,
		component: ModalIdeaEvaluation,
		fullScreen: true,
		maxWidth: 'xl',
	},

	MODAL_IDEA_CONNECT: {
		path: `${rootPath}:idea/<global.connect>`,
		component: ModalConnect,
		fullScreen: true,
		maxWidth: 'xl',
	},
	MODAL_IDEA_EVALUATIONS_BY_CRITERIA: {
		path: `${rootPath}idea-evaluators`,
		component: ModalEvaluationsForCriteria,
		maxWidth: 'sm',
	},

	MODAL_SPONSORS_FOR_FEATURE: {
		path: `${rootPath}sponsors-for-feature`,
		component: ModalSponsorsForFeature,
		fullWidth: true,
		maxWidth: 'xs',
	},
	MODAL_ORGANIZATION_CREATE: {
		path: `${rootPath}organization-create`,
		component: ModalOrganizationCreate,
		fullWidth: true,
		maxWidth: 'xs',
	},
	MODAL_INVITE: {
		path: `${rootPath}invite`,
		component: ModalInviteMember,
		fullWidth: true,
		maxWidth: 'sm',
	},
	MODAL_TEAM: {
		path: `${rootPath}team`,
		component: ModalTeam,
		fullWidth: true,
		maxWidth: 'sm',
	},
	MODAL_REPORT: {
		path: `${rootPath}flag`,
		component: ModalReport,
		fullWidth: true,
		maxWidth: 'xs',
	},
	MODAL_PLAN: {
		path: `${rootPath}plan-config`,
		fullWidth: true,
		maxWidth: 'xs',
		component: ModalPlan,
	},
	MODAL_TRAILS: {
		path: `${rootPath}trails`,
		component: ModalTrails,
		fullScreen: true,
		maxWidth: 'xl',
	},
	MODAL_BATCH_INVITE: {
		path: `${rootPath}batch-invite`,
		component: ModalBatchInvite,
		fullWidth: true,
		maxWidth: 'sm',
	},
	MODAL_TRAILS_TEMPLATES: {
		path: `${rootPath}trails/templates`,
		component: ModalTrails,
		fullScreen: true,
		maxWidth: 'xl',
	},
	MODAL_DASHBOARD: {
		path: `${rootPath}:rootPath/:title`,
		component: ModalDashboard,
		fullScreen: true,
		maxWidth: 'xl',
	},
	MODAL_CREATE: {
		path: `${rootPath}flow-create`,
		component: ModalCreate,
		fullWidth: true,
		maxWidth: 'xs',
	},
	MODAL_TEMPLATE_CREATE: {
		path: `${rootPath}template-create`,
		component: ModalTemplateCreate,
		fullWidth: true,
		maxWidth: 'xs',
	},
	MODAL_DOWNGRADE: {
		path: `${rootPath}downgrade`,
		component: ModalDowngrade,
		fullWidth: true,
		maxWidth: 'sm',
	},
	MODAL_TEMPLATE: {
		path: `${rootPath}template`,
		component: ModalTemplate,
		fullScreen: true,
		maxWidth: 'xl',
	},
	MODAL_ORGANIZATION_OUT: {
		path: `${rootPath}organization-out`,
		component: ModalOrganizationOut,
		fullWidth: true,
		maxWidth: 'xs',
	},
	MODAL_BACKUP: {
		path: `${rootPath}backup`,
		component: ModalBackup,
		fullScreen: true,
		maxWidth: 'xl',
	},
	MODAL_TREE_MAP: {
		path: `${rootPath}:title`,
		component: ModalArticle,
		fullScreen: true,
		maxWidth: 'xl',
	},
	MODAL_RELATED_DOCS: {
		path: `${rootPath}:title/<trends.relatedDocs>`,
		component: ModalDocuments,
		fullScreen: true,
		maxWidth: 'xl',
	},
};

export { ModalIsolatedRouter };
