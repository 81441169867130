/* eslint-disable no-underscore-dangle */
// @ts-nocheck
import React, { Suspense, useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import {
	getTextFromHTML,
	overflowText,
} from '@utils/helpers';
import ModalHeader from '@modules/Modal/ModalHeader';
import type { IModal } from '@modules/Modal/types';
import { DialogBox } from '@modules/Modal/Components';
import { Similarity } from '@components/Signals/Similarity';
import RedactorText from '@components/Shared/RedactorText';
import Icon from '@components/Shared/Icon';
import { Creators } from '@actions';
import {
	Box, Chip, DialogContent, Divider, Grid, Typography,
} from '@mui/material';
import { SButton } from '@components/Buttons';
import { W_GRID } from '@components/Grids/GenericGrid';
import { getArticleDate, getLanguage } from './Helpers';
import { RelevanceSurveyBar } from './RelevanceSurveyBar';

declare type TProps = IModal & WithTranslation

const MModalArticle = (props: TProps) => {
	const { t, routes, article, similarity } = props;

	const { year } = (article?.date || {});
	const dispatch = useDispatch();
	const authors = article?.authors ? article?.authors.map((a) => a.fullName || a.name).join('; ') : '';
	const [seeMore, setSeeMore] = useState<number>(10);

	const conceptsArray = [
		'Patent',
		'Economics',
		'Computer software',
		'Microsoft',
		'Invention',
		'Term of patent',
		'Computer',
	];

	const getInfos = () => {
		if (article?.date?.year && article.source) {
			return (
				<>
					<span className="year">{year}</span>
					<span className="mx-2 separator">{' | '}</span>
					<span className="source">{article.source}</span>
				</>
			);
		}

		if (article?.date?.year) {
			return (<span className="year">{year}</span>);
		}

		if (article?.source) {
			return <span className="source">{article.source}</span>;
		}

		return '';
	};

	const onRelatedDocuments = (event: React.MouseEvent) => {
		event.stopPropagation();
		dispatch(Creators.MODAL.showModal(routes?.MODAL_RELATED_DOCS.path, {
			article, similarity,
		}));
	};

	return (
		<DialogContent id="ArticleDialog">
			<ModalHeader
				fullScreen
				BreadcrumbProps={{ className: W_GRID.W_GRID_2 }}
				BackModalProps={{
					article,
				}}
				pathParams={{
					title: article?.title || '...',
				}}
			/>
			<DialogBox
				className="s-dialog-box s-dialog-content inner w-grid-2"
			>
				<Grid
					container
					sx={{
						display: 'flex',
						flexWrap: 'nowrap',
						marginBottom: '120px',
					}}
				>
					<Grid item container xs={7} spacing={2}>
						<Grid item>
							{getInfos()}
						</Grid>
						<Grid item xs={12}>
							<Typography
								variant="h6"
								className="article__title"
								sx={{
									fontWeight: '400',
								}}
							>
								{overflowText(article.title, 100)}
							</Typography>
						</Grid>

						<Grid item xs={12}>
							<RedactorText className="article__authors" html={authors} hasToggle limitText={100} />
						</Grid>

						<Grid
							item
							xs={12}
							sx={{
								display: 'flex',
								gap: 1,
								width: '100%',
								alignItems: 'center',
							}}
						>
							<SButton
								startIcon={<Icon icon="link" />}
								href={article.href}
								target="_blank"
								rel="noreferrer"
								sx={{
									whiteSpace: 'nowrap',
									minWidth: 'auto',
								}}
							>
								{t('trends.seeFullText')}
							</SButton>
							<SButton
								startIcon={<Icon icon="fa fa-sitemap" />}
								onClick={onRelatedDocuments}
								sx={{
									whiteSpace: 'nowrap',
									minWidth: 'auto',
								}}
							>
								{t('trends.relatedDocs')}
							</SButton>
						</Grid>
						<Grid item xs={12}>
							<Typography className="abstract">
								{t('global.abstract')}
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<RedactorText
								html={getTextFromHTML(article.abstract)}
								className="content-text"
								hasToggle
								limitText={500}
							/>
						</Grid>

						{conceptsArray.length > 0 && (
							<Grid item>
								<Typography sx={{ gap: 1, display: 'flex', flexWrap: 'wrap' }}>
									Tags:
									{conceptsArray.slice(0, seeMore)?.map((tag: string, index: number) => (
										<Chip key={index} label={tag} variant="outlined" color="primary" size="small" />
									))}
									{(conceptsArray.length > 10) && (
										<SButton
											color="secondary"
											variant="outlined"
											size="small"
											sx={{
												borderRadius: '50px',
												fontWeight: '400',
												fontSize: '0.8125rem',
												height: '24px',
											}}
											onClick={() => (seeMore === 10
												? setSeeMore(conceptsArray.length)
												: setSeeMore(10))}
										>
											{seeMore === 10 ? `${t('global.seeMore')} ${conceptsArray.length - 10}`
												: t('global.seeLess')}
										</SButton>
									)}
								</Typography>
							</Grid>
						)}

					</Grid>
					<Grid
						item
						xs={1}
						sx={{
							display: 'flex',
							justifyContent: 'center',
						}}
					>
						<Divider
							orientation="vertical"
							sx={{
								height: '100% !important',
								width: '1px',
								color: 'var(--fog-gray-color)',
							}}
						/>
					</Grid>
					<Grid
						item
						container
						xs={4}
						spacing={3}
						sx={{
							position: 'relative',
							display: 'flex',
							flexDirection: 'column',
							height: 'fit-content',
						}}
					>

						<Grid item xs paddingTop="0">
							<Typography variant="caption">
								{t('global.details')}
							</Typography>
						</Grid>

						{article.doi
								&& (
									<Grid item xs>
										<Typography variant="caption" color="rgba(0, 0, 0, 0.75)">{t('trends.identifiers')}</Typography>
										<Box sx={{ gap: 1, display: 'flex' }}>
											<Typography variant="caption" color="rgba(0, 0, 0, 0.75)">
												DOI:
											</Typography>
											<Typography variant="caption" color="rgba(0, 0, 0, 0.55)">
												{article.doi}
											</Typography>

										</Box>

									</Grid>
								)}

						{(
							article.publisher
								|| getArticleDate(article.date).length > 0
								|| getLanguage(article.language))
								&& (
									<Grid item xs>
										<Typography variant="caption" color="rgba(0, 0, 0, 0.75)">
											{t('trends.publishingInformation')}
										</Typography>
										{
											[
												{
													label: t('trends.publisher'),
													text: article.publisher,
												},
												{
													label: t('global.date'),
													text: getArticleDate(article.date),
												},
												{
													label: t('trends.languageFullText'),
													text: getLanguage(article.language),
												},

											].map((item, index) => {
												if (item.text) {
													return (
														<Box className="info" key={index} sx={{ gap: 1, display: 'flex' }}>
															<Typography variant="caption" color="rgba(0, 0, 0, 0.75)">
																{`${item.label}:`}
															</Typography>
															<Typography variant="caption" color="rgba(0, 0, 0, 0.55)">
																{item.text}
															</Typography>

														</Box>
													);
												}
											})
										}
									</Grid>
								)}

						<Grid item sx={{ display: 'flex', gap: 1 }}>
							<Typography sx={{ whiteSpace: 'nowrap' }}>
								{t('global.similarity')}
								:
							</Typography>
							<Similarity
								id={_.uniqueId('view-m-similarity-')}
								similarity={similarity}
							/>
						</Grid>

					</Grid>
				</Grid>
			</DialogBox>
			<RelevanceSurveyBar />
		</DialogContent>
	);
};

const Translation = withTranslation('common')(MModalArticle);

const ModalArticle = (props: IModal) => (
	<Suspense fallback={<DialogContent />}>
		<Translation {...props} />
	</Suspense>
);

export default ModalArticle;
