import React from "react";
import { connect } from "react-redux";
import { Creators } from "../../store/ducks/actionsTypes";

// Error boundaries atualmente tem que ser classes.
class ErrorBoundary extends React.Component {
  state = { hasError: false, error: null };

  static getDerivedStateFromError(error) {
    return {
      hasError: true,
      error,
    };
  }

  render() {
    {
      this.state.hasError && console.log("--- ErrorBoundary", this.state);
    }
    if (this.state.hasError) {
      this.props.clearModal();
      return;
    }
    return this.props.children;
  }
}

function mapStateToProps(state) {
  return {
    state,
    isOpen: state.modal,
  };
}

/** Receives the function showModal via props and send new path */
function mapDispatchToProps(dispatch) {
  return {
    clearModal: () => dispatch(Creators.MODAL.clearModal()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ErrorBoundary);
