/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
// @ts-nocheck
import React, {
	useEffect, Suspense, useState,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ModalHeader from '@modules/Modal/ModalHeader';
import { WithTranslation, withTranslation } from 'react-i18next';
import {
	buildShowTabQueryString,
	normalizesValue, showTab,
} from '@utils/helpers';
import { goToLink } from '@utils/ReactUtils';
import * as EvaluationHelpers from '@utils/evaluationHelpers';
import { FLOWS } from '@constants/GLOBAL';
import URLS from '@constants/URLS';
import type { IModal } from '@modules/Modal/types';
import { IRootState } from 'src/store';
import { SRadio } from '@components/DynamicForm/ComponentsCore';
import { SButton } from '@components/Buttons';
import { DialogBox } from '@modules/Modal/Components';
import { Creators } from '@actions';
import { BEvaluationRow } from '@components/Background/BEvaluationRow';
import { DynamicCircle } from '@components/DynamicCircle';
import { ICriteria, IEvaluation } from 'src/@types/IIdea';
import {
	Box,
	DialogActions,
	DialogContent,
	DialogTitle,
	RadioGroup,
} from '@mui/material';
import { SSlider } from '@components/SSlider';
import { SToast } from '@modules/SToast';
import { W_GRID } from '@components/Grids/GenericGrid';
import { SBox } from '@components/Background/SBox';
import _ from 'lodash';
import { Accordion } from '../../Accordions/Accordion';
import { SwitchFilter } from '../../Switchs/SwitchFilter';

declare type TProps = IModal & WithTranslation

const MModalIdeaEvaluation = (props: TProps) => {
	const { t, routes } = props;

	const send = useSelector((state: IRootState) => state.ideas.criteria?.send);
	const isLoading = useSelector((state: IRootState) => state.ideas.criteria?.isLoading);
	const criteria = useSelector((state: IRootState) => state.ideas.criteria);
	const evaluate = useSelector((state: IRootState) => state.ideas.evaluate);

	const { ideas, userOrganizationProfile } = useSelector((state: IRootState) => state);
	const [inProgress, setInProgress] = useState<'submit' | 'idea'>(undefined);
	const dispatch = useDispatch();
	const { currentModel, pagingPaths } = useSelector(
		(state) => state.modal?.ModalProps,
	);

	const [formType, setFormType] = useState<'slider' | 'form' | undefined>(criteria?.type);

	const media_avaliacao = ideas?.media_avaliacoes?.find((media) => media.name === currentModel.name);
	const [currentModelId, setCurrentModelId] = useState(currentModel._id);
	const rangeDefault = ['avaliacao-retorno', 'avaliacao-tempo'];

	const getAction = () => {
		const evaluated = media_avaliacao?.evaluators.find((evaluator) => evaluator._id === userOrganizationProfile?._id);
		if (evaluate) return 'back';
		if (!criteria?.avaliacao?.valor && ideas.status === 'pendente' && !evaluated) { return 'evaluate'; }
		if (criteria?.avaliacao?.valor && ideas.status === 'pendente' && evaluated) { return 'change'; }

		return 'view';
	};

	const handleDashboard = (focus) => {
		const ideaPage = document.querySelector('#idea-page');

		if (typeof ideas.loadDashboards === 'function') {
			ideas.loadDashboards();
		}

		dispatch(Creators.MODAL.hideModal());

		if (ideaPage) {
			showTab({ navId: 'idea-tabs', tabId: 'evaluations-tab', focusId: focus });
		} else {
			goToLink(buildShowTabQueryString(`${URLS.IDEAS}/${ideas._id}`, 'evaluations-tab', '#idea-tabs', focus));
		}
	};

	const dashboardRender = () => {
		let disabled = !criteria?.avaliacao?.valor;
		let focus;

		if (evaluate) return null;

		if (!evaluate && !criteria?.avaliacao?.valor) return null;

		switch (criteria.tipo) {
			case 'avaliacao-impacto':
			case 'avaliacao-dificuldade':
				const dificulty = ideas?.media_avaliacoes?.find((item) => item.icon === 'avaliacao-dificuldade');
				const impact = ideas?.media_avaliacoes?.find((item) => item.icon === 'avaliacao-impacto');
				disabled = dificulty?.value === 'Pendente' || impact?.value === 'Pendente';
				focus = 'di-matrix';
				break;
			case 'avaliacao-disrupcao':
				const disruption = ideas?.media_avaliacoes?.find((item) => item.icon === 'avaliacao-disrupcao');
				disabled = disruption?.value === 'Pendente';
				focus = 'disruption-matrix';
				break;
			case 'avaliacao-tempo':
			case 'avaliacao-retorno':
				const retorn = ideas?.media_avaliacoes?.find((item) => item.icon === 'avaliacao-retorno');
				const time = ideas?.media_avaliacoes?.find((item) => item.icon === 'avaliacao-tempo');
				disabled = retorn?.value === 'Pendente' || time?.value === 'Pendente';
				focus = 'tr-matrix';
				break;
			default:
				break;
		}

		const getNext: IEvaluation | undefined = () => {
			const mine = ideas?.minhas_avaliacoes.map((item) => item.tipo);
			const pendent = ideas?.media_avaliacoes.find((item) => !mine?.includes(item.icon));

			return pendent;
		};

		// console.log('IDEA', ideas)

		return (
			<div className="evaluation__actions">
				<div
					aria-hidden
					className="link"
					disabled={disabled}
					onClick={() => handleDashboard(focus)}
					style={{ cursor: disabled ? 'auto' : 'pointer' }}
				>
					Dashboard
				</div>
				{ideas?.status === 'pendente' && (
					<>
						<span className="">{t('global.or')}</span>
						<div
							aria-hidden
							className="link"
							style={{ cursor: 'pointer' }}
							onClick={() => {
								if (getNext()) {
									const index = pagingPaths?.findIndex((model) => model.pageModel?.name === getNext().name);

									dispatch(Creators.MODAL.handleModal({ currentPage: index }));
									// update currentModal
									dispatch(Creators.MODAL.handleParams('currentModel', pagingPaths[index].pageModel));
								} else {
									dispatch(Creators.MODAL.backModal());
								}
							}}
						>
							{getNext() ? t('evaluation.continue') : t('page_search.seeAll')}
						</div>
					</>
				)}
			</div>
		);
	};

	const loadTemplateCriteria = (type) => {
		if (!currentModel) {
			return;
		}
		dispatch(
			Creators.IDEAS.getTemplateCriteria({
				criteria: currentModel.name,
				typeForm: type,
				onSuccess: () => {
					dispatch(Creators.IDEAS.updateIdeas({ evaluate: true }));
				},
			}),
		);
	};

	const loadCriteriaForm = (params: { criteria: string, evaluate?: boolean }) => {
		dispatch(
			Creators.IDEAS.getFormByCriteria({
				ideaId: ideas._id,
				criteria: params.criteria,
				onSuccess: (data: ICriteria) => {
					setFormType(data.type);
					dispatch(Creators.IDEAS.updateIdeas({ evaluate: params?.evaluate || false }));
				},
			}),
		);
	};

	const getValue = () => {
		let value = 'Pendente';

		if (criteria?.avaliacao) {
			value = criteria?.avaliacao?.valor;
		}

		if (criteria?.type === 'slider') {
			switch (criteria?.tipo) {
				case 'avaliacao-impacto':
					value = EvaluationHelpers.impactValue(EvaluationHelpers.impactCalc(
						parseInt(criteria.questoes.impacto.value, 10),
					));
					break;
				case 'avaliacao-dificuldade':
					value = EvaluationHelpers.difficultyValue(EvaluationHelpers.difficultyCalc(
						parseInt(criteria.questoes.dificuldade.value, 10),
					));
					break;
				case 'avaliacao-disrupcao':
					const {
						dominioConhecimento: dC,
						definicaoProblema: dP,
					} = EvaluationHelpers.disruptionCalc(
						parseInt(criteria.questoes.dominioConhecimento.value, 10),
						parseInt(criteria.questoes.definicaoProblema.value, 10),
					);
					value = EvaluationHelpers.disruptionValue(dC, dP);
					break;
				case 'avaliacao-tempo':
				case 'avaliacao-retorno':
					const v = (criteria.questoes.tempo?.hasOwnProperty('value')
					&& parseInt(criteria.questoes.tempo.value))
					|| (criteria.questoes.retorno?.hasOwnProperty('value')
					&& parseInt(criteria.questoes.retorno.value));
					value = EvaluationHelpers.returnAndTimeValues(v);
					break;
				default:
					break;
			}
		}

		return normalizesValue(value);
	};

	const submitEvaluation = (name) => {
		setInProgress('submit');

		const isValidated = Object.keys(send).every((key) => typeof send[key] !== 'undefined');

		if (criteria.type === 'slider' && !isValidated) {
			setInProgress(undefined);
			SToast.error(t('messages.answerBeforeSubmitting'));
			return;
		}

		dispatch(
			Creators.IDEAS.submitEvaluationCriteria({
				ideaId: ideas._id,
				criteria: name,
				form: {
					submit: 'avaliar',
					criteria: name,
					type: criteria.type,
					avaliacao: send,
				},
				callback: () => {
					setInProgress(undefined);
				},
			}),
		);
	};

	const verifyForm = (item) => {
		if (item.type === 'multipleOptions') return item.options.every((obj) => Object.hasOwnProperty.call(obj, 'value'));
		return Object.hasOwnProperty.call(item, 'value');
	};

	const handleTypeForm = (p) => {
		setFormType(p.value);
		if (criteria?.avaliacao && criteria?.avaliacao.type === p.value) {
			loadCriteriaForm({ evaluate: true, criteria: currentModel.name });
			return;
		}

		loadTemplateCriteria(p.value);
	};

	useEffect(() => {
		if (currentModel) {
			dispatch(Creators.IDEAS.updateIdeas({ evaluate: false }));
			setCurrentModelId(currentModel._id);
			loadCriteriaForm({ criteria: currentModel.name });
		}
	}, [currentModel]);

	if (!criteria) {
		return (
			<SBox loading={!criteria} />
		);
	}

	return (
		<DialogContent className="modal__evaluation" id="EvaluationDialog">
			<ModalHeader
				fullScreen
				icon={FLOWS.IDEA}
				BreadcrumbProps={{ className: W_GRID.W_GRID_2 }}
				pathParams={{
					idea: ideas?.nome || '...',
					criteria: t(currentModel?.type) || '...',
				}}
			/>
			<DialogBox className="s-dialog-title inner w-grid-2">
				<DialogTitle variant="h1">
					{t('evaluation.idea')}
				</DialogTitle>
			</DialogBox>
			<DialogBox className={`s-dialog-box form__evaluation inner w-grid-2 ${ideas?.status !== 'pendente' ? 'pb-5' : ''}`}>
				<SBox loading={!criteria}>
					{media_avaliacao && criteria && (
						<div className="list mb-30px">
							<div className="list-item">
								<BEvaluationRow
									routes={routes}
									evaluation={media_avaliacao}
									myEvaluationValue={ideas?.minhas_avaliacoes?.find((item) => item.tipo === criteria?.icon)?.valor}
								/>
							</div>
						</div>

					)}

				</SBox>

				{evaluate && !(criteria.tipo === 'avaliacao-retorno' || criteria.tipo === 'avaliacao-tempo')
					&& (
						<div className="form__type">
							<SwitchFilter
								left={{
									value: 'form', label: 'Quiz', selected: formType === 'form', icon: 'tasks',
								}}
								right={{
									value: 'slider', label: 'Slider', selected: formType === 'slider', icon: 'fas fa-sliders-h',
								}}
								name="criteria.type"
								value={formType}
								onChange={handleTypeForm}
							/>
						</div>
					)}
				<SBox
					loading={isLoading || currentModelId !== currentModel._id || !criteria?.questoes || !criteria}
				>
					{(!evaluate || criteria.type === 'slider') && (
						<div className="mine__evaluation">
							<div className="title">{(!evaluate || rangeDefault.includes(criteria.tipo)) && t('evaluation.my')}</div>
							<DynamicCircle
								icon={media_avaliacao.icon}
								onClick={() => {
									switch (getAction()) {
										case 'back':
											dispatch(Creators.IDEAS.updateIdeas({ evaluate: false }));
											break;
										case 'change':
										case 'view':
										case 'evaluate':
											dispatch(Creators.IDEAS.updateIdeas({ evaluate: true }));
											break;
										default:
											break;
									}
								}}
								size="x-large"
								className={`evaluation fill ${getValue()}`}
								action={getAction() ? t(`global.${getAction()}`) : undefined}
							/>
							<div className="criteria__value">
								<span className="criteria__name">
									{t(media_avaliacao.type)}
									:
								</span>
								<strong className="first-letter-capitalize">
									{t(`evaluation.values.${send ? getValue() : criteria?.avaliacao?.valor}`)}
								</strong>
							</div>
							{dashboardRender()}
						</div>
					)}

					{!_.isEmpty(criteria.questoes) && Object.keys(criteria.questoes)?.map((key, index) => (
						<div key={index} className={`${criteria.questoes[key].type === 'range' ? 'd-flex justify-content-center' : ''}`}>
							{criteria.questoes[key].type ? (
								<>
									{(criteria.questoes[key].type === 'range' && evaluate) && (
										<div className="evaluation__slider">
											<Box marginY={3}>
												{criteria.questoes[key].label
															&& (
																<strong className="mb-5">{t(criteria.questoes[key].label)}</strong>
															)}

												<SSlider
													className={`evaluation ${getValue()}`}
													min={parseInt(criteria.questoes[key].min, 10)}
													max={parseInt(criteria.questoes[key].max, 10)}
													required={criteria.questoes[key].required}
													disabled={ideas?.status !== 'pendente'}
													value={criteria.questoes[key].value}
													onChange={(e, value) => {
														dispatch(
															Creators.IDEAS.handleCriteria(
																criteria.questoes[key].name,
																value,
															),
														);
													}}
												/>
											</Box>
										</div>
									)}
									{(criteria.questoes[key].type === 'radio' && evaluate && criteria.type === 'form') && (
										<Accordion
											id={criteria.questoes[key].name}
											className={`evaluation mt-30px ${criteria.questoes[key].value ? 'active' : ''}`}
											title={t(criteria.questoes[key].label)}
											target={criteria.questoes[key].name}
											show={criteria.questoes[key].show}
											error={criteria.questoes[key].error}
											toggle={() => dispatch(
												Creators.IDEAS.handleShowAccordion(
													criteria.questoes[key].name,
													criteria.questoes[key].show,
												),
											)}
										>
											<RadioGroup
												onChange={(e) => {
													dispatch(
														Creators.IDEAS.handleCriteria(
															criteria.questoes[key].name,
															e.target.value,
														),
													);
												}}
											>
												{criteria.questoes[key].options?.map((item, i) => (
													<SRadio
														id={`${criteria.questoes[key].name}-${item.value}`}
														value={item.value}
														className="evaluation__item"
														name={criteria.questoes[key].name}
														label={<span dangerouslySetInnerHTML={{ __html: t(item.label) }} />}
													/>
												))}
											</RadioGroup>
										</Accordion>
									)}
								</>
							) : (evaluate && criteria.type === 'form') && (
								<Accordion
									id={criteria.questoes[key].name}
									className={`evaluation accordion-2 mt-30px ${criteria.questoes[key].options?.every(verifyForm) ? 'active' : ''}`}
									title={t(criteria.questoes[key].label)}
									target={criteria.questoes[key].name}
									show={criteria.questoes[key].show}
									// error={criteria.questoes[key].error}
									toggle={() => dispatch(
										Creators.IDEAS.handleShowAccordion(
											criteria.questoes[key].name,
											criteria.questoes[key].show,
										),
									)}
								>

									{criteria.questoes[key].options?.map((item, i) => (
										<>
											{item.type === 'multipleOptions' && (
												<div key={i} className="multiple__options">
													<span
														className="mb-20px question-left"
														dangerouslySetInnerHTML={{
															__html: t(item.label),
														}}
													/>
													{item.options.map((sub, index) => (
														<div key={index} className="multiple__options--sub">
															<span
																className="question-left"
																dangerouslySetInnerHTML={{
																	__html: t(sub.label),
																}}
															/>
															{sub.error && <i className="fas fa-exclamation-circle text-danger" style={{ right: '-30px', position: 'absolute' }} />}
															<ul className="questions-inline">
																{sub.options.map((deep, x) => (
																	<li key={x}>
																		<SRadio
																			id={`${criteria.questoes[key].name}-${sub.name}-${x}`}
																			name={sub.name}
																			value={deep.value}
																			label={t(deep.label)}
																			disabled={ideas?.status !== 'pendente'}
																			checked={deep.value === sub.value}
																			onChange={(e) => {
																				dispatch(
																					Creators.IDEAS.handleCriteria(
																						criteria.questoes[key]
																							.name,
																						e.target.value === 'false'
																									|| e.target.value === 'true'
																							? /true/i.test(e.target.value)
																							: e.target.value,
																						sub.name,
																						i,
																						index,
																						x,
																					),
																				);
																			}}
																		/>
																	</li>
																))}
															</ul>
														</div>

													))}
												</div>
											)}
											{(item.type === 'radio' && evaluate && item.show) && (
												<div className="evaluation__item">
													<span
														className="question-left"
														dangerouslySetInnerHTML={{
															__html: t(item.label),
														}}
													/>
													{item.error && <i className="fas fa-exclamation-circle text-danger" style={{ right: '-30px', position: 'absolute' }} />}
													<ul className="questions-inline">
														{item.options.map((sub, k) => (
															<li key={k}>
																<SRadio
																	id={`${criteria.questoes[key].name}-${item.name}-${k}`}
																	name={sub.name}
																	value={sub.value}
																	label={t(sub.label)}
																	checked={sub.value === item.value}
																	disabled={ideas?.status !== 'pendente'}
																	onChange={(e) => {
																		dispatch(
																			Creators.IDEAS.handleCriteria(
																				criteria.questoes[key].name,
																				e.target.value === 'false'
																						|| e.target.value === 'true'
																					? /true/i.test(e.target.value)
																					: e.target.value,
																				item.name,
																				i,
																				k,
																			),
																		);
																	}}
																/>
															</li>
														))}
													</ul>
												</div>
											)}
										</>
									))}
								</Accordion>
							)}
						</div>
					))}
					<DialogActions className="s-dialog-actions">
						{(ideas?.status === 'pendente' && evaluate) && (
							<SButton
								type="button"
								isLoading={inProgress === 'submit'}
								variant="outlined"
								fullWidth
								size="large"
								color="primary"
								disabled={send && Object.keys(send).every((key) => typeof send[key] === 'undefined')}
								onClick={() => submitEvaluation(currentModel.name)}
							>
								{t('evaluation.confirm')}
							</SButton>
						)}
					</DialogActions>
				</SBox>
			</DialogBox>
		</DialogContent>
	);
};

const Translation = withTranslation('common')(MModalIdeaEvaluation);
const ModalIdeaEvaluation = (props: IModal) => (
	<Suspense fallback={<div />}>
		<Translation {...props} />
	</Suspense>
);
export default ModalIdeaEvaluation;
