// @ts-nocheck
import { createActions, createReducer } from 'reduxsauce';
import { ISharedActions, ISharedState } from './types';

/** Creating actions types & creators */

export const sharedActions: ISharedActions = createActions({
	clearShared: [''],
	getUsers: ['params'],
	getParticipants: ['params'],
	getTags: ['params'],
	updateShared: ['shared'],
});

const { Types } = sharedActions;

/** Creating reducer handlers */

const INITIAL_STATE: ISharedState = {};

const updateShared = (state = INITIAL_STATE, action) => ({ ...state, ...action.shared });
/** Default functions */

const clearShared = () => ({});
const defaultGlobal = (state = INITIAL_STATE) => ({ ...state });

/** Creating our own reducer */

export default createReducer(INITIAL_STATE, {
	[Types.GET_USERS]: defaultGlobal,
	[Types.GET_PARTICIPANTS]: defaultGlobal,
	[Types.CLEAR_SHARED]: clearShared,
	[Types.GET_TAGS]: defaultGlobal,
	[Types.UPDATE_SHARED]: updateShared,
});
