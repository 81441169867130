// @ts-nocheck
import React, {
	useRef, MouseEvent, ChangeEvent, Suspense,
} from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import Icon from '@components/Shared/Icon';
import { SToast } from '@modules/SToast';
import { SButton } from './SButton';
import { SLink } from './SLink';

declare type TAccept =
	| 'application/pdf'
	| 'image/png,image/jpeg,image/jpg'
	| 'image/png,image/jpeg,image/jpg,application/pdf'
	| '*'
	| 'image/*'
	| 'text/csv'
	| '.csv'

interface IOwnProps {
	component?: 'button' | 'link'
	accept?: TAccept
	disabled?: boolean
	isLoading?: boolean
	onClick: (file: { type: string, data: any } | FormData) => void
	multiple?: boolean
	label?: string
	mode?: 'FormData' | 'File'
	children?: React.ReactNode
}

declare type TProps = IOwnProps & WithTranslation

const _ComputerUploadButton = (props: TProps) => {
	const {
		t,
		disabled,
		isLoading,
		multiple,
		onClick,
		accept = '*',
		children = 'Upload',
		mode = 'FormData',
		component = 'button',
	} = props;

	const inputRef = useRef<HTMLInputElement | undefined>();

	// Computer upload
	const computerUpload = (e: MouseEvent<HTMLButtonElement>) => {
		e.preventDefault();
		(inputRef.current as HTMLInputElement).click();
	};

	const readBLOB = (input: HTMLInputElement) => {
		const { files } = input;
		let validTypes: string[] = [];
		let valid: string[] = [];

		if (!files?.[0]) {
			return;
		}

		if (accept !== '*' && files?.[0]) {
			validTypes = accept.split(',');

			valid = validTypes.filter((type) => {
				if (files[0].type.includes(type)) {
					return type;
				}
			});
		}

		if (accept !== '*' && !files[0].type.includes(valid[0])) {
			SToast.error(t('messages.error.imageUpload'));
			return;
		}

		if (mode === 'FormData') {
			const formData = new FormData();
			formData.append('computer-upload', files[0]);
			if (typeof onClick === 'function') {
				onClick(formData);
			}
		} else if (typeof onClick === 'function') {
			onClick(files[0]);
		}
	};

	const onComputerUploadHandler = (e: ChangeEvent<HTMLInputElement>) => {
		e.stopPropagation();
		e.preventDefault();
		readBLOB(inputRef.current as HTMLInputElement);
	};

	return (
		<>
			<input
				// @ts-ignore
				ref={inputRef}
				type="file"
				accept={accept}
				name="attachmentFile"
				className="form-control-file"
				id="attachmentFile"
				onChange={onComputerUploadHandler}
				multiple={multiple}
			/>
			{component === 'button' && (
				<SButton
					type="button"
					variant="outlined"
					color="secondary"
					onClick={computerUpload}
					disabled={disabled}
					isLoading={isLoading}
					startIcon={<Icon icon="upload" />}
				>
					{children}
				</SButton>
			)}

			{component === 'link' && (
				<SLink
					color="primary"
					onClick={computerUpload}
					href="# "
					disabled={disabled}
					isLoading={isLoading}
				>
					{children}
				</SLink>
			)}

		</>
	);
};
const Translation = withTranslation('common')(_ComputerUploadButton);

const ComputerUploadButton = (props: IOwnProps) => (
	<Suspense fallback={<div />}>
		<Translation {...props} />
	</Suspense>
);

export { ComputerUploadButton };
