import { Box } from '@mui/material';
// eslint-disable-next-line import/no-extraneous-dependencies
import { styled as MuiStyled } from '@mui/system';

export const BCoverBox = MuiStyled(Box)(({ theme }) => ({
	backgroundColor: 'var(--body-background-color)',
	borderRadius: '4px',
	border: '1px dashed rgba(112, 112, 112, 0.3)',
	position: 'relative',
	overflow: 'hidden',
	'&:hover label': {
		backgroundColor: 'rgba(0, 0, 0, 0.5)',
	},
	'&.overlay__cover:hover label': {
		opacity: '0.8',
	},
	img: {
		borderRadius: '4px',
	},
	label: {
		position: 'relative',
		display: 'block',
		width: '100%',
		overflow: 'hidden',
		zIndex: 1,
		objectFit: 'cover',
	},
	'.select-cover': {
		margin: '0',
		backgroundSize: 'cover',
		backgroundPosition: 'center',
		display: 'block',
		filter: 'alpha(opacity=0)',
		position: 'relative',
		textDecoration: 'none',
		width: '100%',
		height: '100%',
		minHeight: '11.375rem', // 182px
		zIndex: 0,
	},
	'.s-empty-state': {
		minHeight: '11.375rem', // 182px
	},
}));
