/* eslint-disable no-underscore-dangle */
// @ts-nocheck
import {
	put, call, takeLatest, delay,
} from 'redux-saga/effects';
import i18next from 'i18next';
import {
	APIOrganizations, APIUnregistered, APIUser,
} from '@services/apis';
import { onLogout, handleAxiosErrors, auth } from '@services/auth';
import { Creators, Types } from '@actions';
import i18n from '@services/i18n';
import { goToLink } from '@utils/ReactUtils';
import URLS from '@constants/URLS';
import { AxiosResponse } from 'axios';
import { IUserIntegration } from 'src/@types/IUser';
import { SToast } from '@modules/SToast';

function* getUserOrganization() {
	try {
		const response: AxiosResponse = yield call(APIUser.getProfile);
		if (response?.data?.user) {
			yield call(i18next.changeLanguage, response.data.user.idioma || i18n.language); // Set Language

			const defaultIntegrations: IUserIntegration[] = [
				{
					app: 'slack',
					image: 'https://cdn.worldvectorlogo.com/logos/slack-new-logo.svg',
					isLoggedIn: !!response?.data?.integrations.find((item) => item.app === 'slack'),
					// eslint-disable-next-line max-len
					loginLink: `https://slack.com/oauth/v2/authorize?scope=incoming-webhook,commands,chat:write,users:read,users:read.email&client_id=${process.env.SLACK_CLIENT_ID}&state=${btoa(JSON.stringify({ id: response.data.currentTenant || '' }))}&redirect_uri=${process.env.SLACK_OAUTH_REDIRECT_URL}`,
				},
				{
					app: 'drive',
					isLoggedIn: !!response?.data?.integrations.find((item) => item.app === 'drive'),
					image: 'https://cdn.worldvectorlogo.com/logos/drive-new-logo-1.svg',
					loginLink: '',
				},
				{
					app: 'miro',
					isLoggedIn: !!response?.data?.integrations.find((item) => item.app === 'miro'),
					image: 'https://cdn.worldvectorlogo.com/logos/miro.svg',
					// eslint-disable-next-line max-len
					loginLink: `https://miro.com/oauth/authorize/?response_type=code&client_id=3074457350789669684&redirect_uri=${process.env.MIRO_OAUTH_REDIRECT_URL}`,
				},
			];

			const a: IUserIntegration[] = [];

			console.log('🚀 ~ file: saga.ts:52 ~ function*getUserOrganization ~ response.data.integrations', response.data.integrations);
			
			yield put(Creators.USER_ORGANIZATION.successUser({ ...response.data, integrations: a.length > 0 ? a : defaultIntegrations }));
			yield put(Creators.USER_ORGANIZATION.getOrganizations({
				organizationId: response.data.currentTenant,
				billingCustomerId: response.data.billingCustomer,
				// user
			}));
		}
	} catch (err) {
		yield call(handleAxiosErrors, err);
	}
}

function* getProfile(action) {
	const { profileId, onSuccess, delayTime } = action.params;
	try {
		if (typeof delayTime === 'number') {
			yield delay(delayTime);
		}

		const response: AxiosResponse = yield call(APIUser.getProfile, profileId);
		if (typeof onSuccess === 'function') {
			yield call(onSuccess, response.data);
		}
	} catch (err) {
		yield call(handleAxiosErrors, err);
	}
}

function* getOrganization(action) {
	try {
		const response: AxiosResponse = yield call(APIOrganizations.get, action.params.organizationId);
		yield put(Creators.USER_ORGANIZATION.successUser({ organization: response.data }));
		yield put(Creators.BILLING.getOverviewPlan());
	} catch (err) {
		yield call(handleAxiosErrors, err);
	}
}

function* getOrganizations(action) {
	try {
		const response: AxiosResponse = yield call(APIUser.getOrganizations);

		yield put(Creators.USER_ORGANIZATION.successUser({ organizations: response.data }));

		if (action.params?.organizationId) {
			yield put(Creators.USER_ORGANIZATION.successUser({
				organization: response.data.find(
					(doc) => doc._id.toString() === action.params.organizationId.toString(),
				),
			}));

			yield put(Creators.BILLING.getOverviewPlan(action.params)); // redirectPath
		}
	} catch (err) {
		yield call(handleAxiosErrors, err);
	}
}

function* changeOrganization(action) {
	try {
		yield put(Creators.USER_ORGANIZATION.getOrganization({
			organizationId: action.organizationId,
		})); // Overview canceled by Saga
		const response: AxiosResponse = yield call(APIOrganizations.postChange, action.organizationId);
		yield delay(500);
		yield call(auth.setToken, response.data.token);
		yield call(goToLink, URLS.HOME);
	} catch (err) {
		yield call(handleAxiosErrors, err);
	}
}

function* requestOrganizationCreation(action) {
	const { email, onSuccess, callback } = action.params;
	try {
		yield call(APIUnregistered.postRequestOrganizationCreation, email);

		if (typeof onSuccess === 'function') {
			yield call(onSuccess);
		}
	} catch (err) {
		yield call(handleAxiosErrors, err);
	} finally {
		if (typeof callback === 'function') {
			yield call(callback);
		}
	}
}

function* userLogout() {
	try {
		// const auth2 = gapi.auth2.getAuthInstance();
		// auth2.signOut();
		yield call(onLogout);
	} catch (err) {
		yield call(handleAxiosErrors, err);
	}
}

function* handleOrganizationImage(action) {
	try {
		const { formData, onSuccess } = action.params;
		const response: AxiosResponse = yield call(APIOrganizations.postOrganizationLogo, formData);

		yield put(Creators.USER_ORGANIZATION.handleOrganization({ image: response.data.image }));

		if (typeof onSuccess === 'function') {
			yield call(onSuccess, response.data.image);
		}
	} catch (err) {
		yield call(handleAxiosErrors, err);
	} finally {
		if (typeof action.params.callback === 'function') {
			yield call(action.params.callback);
		}
	}
}

function* handleUserImage(action) {
	try {
		const { formData } = action.params;
		console.log('FORM', formData);
		const response: AxiosResponse = yield call(APIUser.patchImageProfile, formData);
		yield put(Creators.USER_ORGANIZATION.handleUser({ image: response.data }));
		if (typeof action.params.onSuccess === 'function') {
			yield call(action.params.onSuccess, { image: response.data });
		}
	} catch (err) {
		yield call(handleAxiosErrors, err);
	} finally {
		if (typeof action.params.callback === 'function') {
			yield call(action.params.callback);
		}
	}
}

function* inviteOrganization(action) {
	const {
		invitations, onSuccess, onErrors, callback,
	} = action.params;
	try {
		if (typeof onSuccess === 'function') {
			yield call(onSuccess);
		}

		yield call(APIOrganizations.postOrganizationInvite, invitations);
		yield call(SToast.success, i18next.t('messages.success.invite'));
	} catch (err) {
		if (err.response?.data?.emails && typeof onErrors === 'function') {
			yield call(onErrors, {
				message: i18next.t('messages.invitationAlreadySend'),
				chips: err.response?.data?.emails.map((item) => item.email),
			});

			return;
		}
		yield call(handleAxiosErrors, err);
	} finally {
		if (typeof callback === 'function') {
			yield call(callback);
		}
	}
}

function* resendToken(action) {
	try {
		const { email } = action.params;
		yield call(APIUnregistered.postResendToken, { email });
		yield call(SToast.success, i18next.t('messages.token.sent', i18next.t('global.success')));

		if (typeof action.params.onSuccess === 'function') {
			yield call(action.params.onSuccess);
		}
	} catch (err: any) {
		if (typeof action.params.onError === 'function' && err.response) {
			yield call(action.params.onError, err.response.data.err);
		}
	} finally {
		if (typeof action.params.callback === 'function') {
			yield call(action.params.callback);
		}
	}
}

export default function* () {
	yield takeLatest(Types.USER_ORGANIZATION.GET_PROFILE, getProfile);
	yield takeLatest(Types.USER_ORGANIZATION.GET_USER_ORGANIZATION, getUserOrganization);
	yield takeLatest(Types.USER_ORGANIZATION.USER_LOGOUT, userLogout);
	yield takeLatest(Types.USER_ORGANIZATION.HANDLE_USER_IMAGE, handleUserImage);
	yield takeLatest(Types.USER_ORGANIZATION.GET_ORGANIZATION, getOrganization);
	yield takeLatest(Types.USER_ORGANIZATION.GET_ORGANIZATIONS, getOrganizations);
	yield takeLatest(Types.USER_ORGANIZATION.CHANGE_ORGANIZATION, changeOrganization);
	yield takeLatest(Types.USER_ORGANIZATION.REQUEST_ORGANIZATION_CREATION, requestOrganizationCreation);
	yield takeLatest(Types.USER_ORGANIZATION.HANDLE_ORGANIZATION_IMAGE, handleOrganizationImage);
	yield takeLatest(Types.USER_ORGANIZATION.INVITE_ORGANIZATION, inviteOrganization);
	yield takeLatest(Types.USER_ORGANIZATION.RESEND_TOKEN, resendToken);
}
