import { badgesActions } from './badges';
import { canvasActions } from './canvas';
import { featuresActions } from './features';
import { formsActions } from './forms';
import { sharedActions } from './shared';
import { ideasActions } from './ideas';
import { modalActions } from './modal';
import { billingActions } from './billing';
import { platformsActions } from './platforms';
import { userActions } from './userOrganizationProfile';

export const { Types, Creators } = {
	Creators: {
		IDEAS: ideasActions.Creators,
		PLATFORMS: platformsActions.Creators,
		BADGES: badgesActions.Creators,
		CANVAS: canvasActions.Creators,
		SHARED: sharedActions.Creators,
		FORMS: formsActions.Creators,
		FEATURES: featuresActions.Creators,
		MODAL: modalActions.Creators,
		BILLING: billingActions.Creators,
		USER_ORGANIZATION: userActions.Creators,
	},
	Types: {
		IDEAS: ideasActions.Types,
		PLATFORMS: platformsActions.Types,
		BADGES: badgesActions.Types,
		CANVAS: canvasActions.Types,
		SHARED: sharedActions.Types,
		FORMS: formsActions.Types,
		FEATURES: featuresActions.Types,
		MODAL: modalActions.Types,
		BILLING: billingActions.Types,
		USER_ORGANIZATION: userActions.Types,
	},
};
