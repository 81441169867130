// @ts-nocheck
import { createActions, createReducer } from 'reduxsauce';
import { IBadgesActions, IBadgesState } from './types';

export const badgesActions: IBadgesActions = createActions({
	successBadges: ['key', 'value'],
	handleBadge: ['params'],
	insertBadge: ['params'],
	getBadgesByPlatform: ['params'],
	getBadgesByIdea: ['params'],
	deleteBadge: ['params'],
	setBadge: ['badge'],
	clearBadges: [''],
	restoreBadge: ['p'],
});

const { Types } = badgesActions;

export const STATE_BADGES: IBadgesState = {};

const successBadges = (state = STATE_BADGES, action: any) => {
	const { key, value } = action;
	return {
		...state,
		[key]: value,
	};
};

const handleBadge = (state = STATE_BADGES, action: any) => {
	const { key, value } = action.params;
	return {
		...state,
		[key]: value,
	};
};

const setBadge = (state = STATE_BADGES, action) => (
	{ ...action.badge }
);

const defaultBadges = (state = STATE_BADGES) => (state);

/** Default functions */

const clearBadges = () => ({});

/** Creating our own reducer */

const reducerBadges = createReducer(STATE_BADGES, {
	[Types.SUCCESS_BADGES]: successBadges,
	[Types.HANDLE_BADGE]: handleBadge,
	[Types.INSERT_BADGE]: defaultBadges,
	[Types.DELETE_BADGE]: defaultBadges,
	[Types.GET_BADGES_BY_PLATFORM]: defaultBadges,
	[Types.GET_BADGES_BY_IDEA]: defaultBadges,
	[Types.SET_BADGE]: setBadge,
	[Types.CLEAR_BADGES]: clearBadges,
	[Types.RESTORE_BADGE]: defaultBadges,
});

export default reducerBadges;
