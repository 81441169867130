// @ts-nocheck
import { axiosInstance } from '@services/AxiosInstance';
import { IPaginatedRequest, TObject } from 'src/@types/IShared';
import { getQueryParams } from './utils';

interface ILeavePost {
	newAdmin?: string,
	password: string
	userTransfer?: string
}

const APIOrganizations = {
	get(organizationId: string) {
		return axiosInstance.get(`/organizations/${organizationId}`);
	},
	deleteInvite(inviteId: string) {
		return axiosInstance.delete(`/organizations/invite/${inviteId}`);
	},
	postOrganization(org) {
		return axiosInstance.post('/organizations/', org);
	},
	postChange(organizationId: string) {
		return axiosInstance.post(`/organizations/${organizationId}/change_org`, {});
	},
	deleteOrganization(organizationId: string, name: string) {
		return axiosInstance.delete(`/organizations/${organizationId}?name=${encodeURIComponent(name)}`);
	},

	postOrganizationLogo(formData) {
		return axiosInstance.post('/organizations/upload_image', formData);
	},
	getUsers({ query }) {
		let queryParams = '';
		if (query) queryParams += `?query=${query}`;
		return axiosInstance.get(`/organizations/participants_teams${queryParams}`); // users ? only users
	},
	getParticipants(params: { onlyActive?: boolean, excludeCurrentUser?: boolean }) {
		let queryParams = '';

		if (params) {
			const { onlyActive, excludeCurrentUser } = params;
			if (!onlyActive || onlyActive) queryParams += 'active=true';
			if (typeof onlyActive === 'boolean' && !onlyActive) queryParams += 'active=false';
			if (excludeCurrentUser) queryParams += '&excludeCurrentUser=true';
		} else queryParams = 'active=true&excludeCurrentUser=true';

		return axiosInstance.get(`/organizations/participants?${queryParams}`);
	},
	postActiveLandingPage() {
		return axiosInstance.post('/organizations/landing_page/');
	},
	patchLandingPage(body) {
		return axiosInstance.patch('/organizations/landing_page/', body);
	},
	patchOrganization(organizationId: string, body) {
		return axiosInstance.patch(`/organizations/${organizationId}`, body);
	},
	postOrganizationInvite(body) {
		return axiosInstance.post('/organizations/invite', body);
	},
	getInvites() {
		return axiosInstance.get('/organizations/invites');
	},
	postLeaveOrganization(organizationId: string, params: ILeavePost) {
		return axiosInstance.post(`/organizations/${organizationId}/leave`, params);
	},
	getLandingPage(organizationId: string) {
		return axiosInstance.get(`/lp/org/${organizationId}`);
	},
	getReported(params: IPaginatedRequest) {
		const queryParams = getQueryParams(params || {});
		return axiosInstance.get(`/reports${queryParams}`);
	},
	getNotifications(params: IPaginatedRequest) {
		const queryParams = getQueryParams(params || {});
		return axiosInstance.get(`/notifications${queryParams}`);
	},
	postReadNotification(notificationId: string) {
		return axiosInstance.post(`/notifications/${notificationId}/read`, {});
	},
	postReadAllNotification() {
		return axiosInstance.post('/notifications/read_all', {});
	},
	patchPermissions(permission: TObject) {
		return axiosInstance.patch('/organizations/change_permissions', permission);
	},
	postSlack(code: string) {
		return axiosInstance.post(`/slack/oauth/${code}`, {});
	},
	getIntegrationsUsers(query?: string) {
		const queryParams = query && query !== '' ? `?query=${query}` : '';
		return axiosInstance.get(`/slack/users${queryParams}`);
	},
	getCounters() {
		return axiosInstance.get('/organizations/counters');
	},
	removeUser(userId: string) {
		return axiosInstance.delete(`/organizations/remove_user/${userId}`);
	},
	inviteBatch(formData) {
		return axiosInstance.post('/organizations/invite_batch', formData);
	},
};

export default APIOrganizations;
