/* eslint-disable import/no-cycle */
// @ts-nocheck
import {
	put, call, takeLatest, all, select, delay,
} from 'redux-saga/effects';
import i18next from 'i18next';
import * as Yup from 'yup';
import {
	APICanvas, APIForms, APIGlobal, APIIdeas, APIPlatforms,
} from '@services/apis';
import { goToLink } from '@utils/ReactUtils';
import URLS from '@constants/URLS';
import { getValidationErrors } from '@utils/yup';
import { handleAxiosErrors } from '@services/auth';
import { IRootState } from 'src/store';
import { AxiosResponse } from 'axios';
import { getTextFromHTML } from '@utils/helpers';
import { SToast } from '@modules/SToast';
import { Creators, Types } from '../actionsTypes';

function* getPlatform(action) {
	try {
		const { platformId, redirectPath, onSuccess } = action.params;

		if (redirectPath) {
			yield put(Creators.MODAL.showModal(redirectPath));
		}

		const [platform, canvas] = yield all([
			call(APIPlatforms.get, platformId),
			call(APICanvas.getCanvasByPlatform, platformId),
		]);

		platform.data.canvas = canvas.data; // Miro
		platform.data.mode = platform.data.isFinished || platform.data.status === 'arquivada' ? 'view' : 'edit';

		yield put(Creators.PLATFORMS.updatePlatform(platform.data));
		yield put(Creators.MODAL.handleModal({ flow: platform.data?.type?.toLowerCase() }));

		if (typeof onSuccess === 'function') {
			yield call(onSuccess, platform.data);
		}
	} catch (err) {
		yield call(handleAxiosErrors, err);
	}
}

function* handlePlatformTemplate(action) {
	const {
		platformId, templateId, onSuccess, callback, redirectPath,
	} = action.params;
	try {
		const response = yield call(APIPlatforms.putTemplatePlatform, platformId, templateId);

		if (!response.data) return;

		yield put(Creators.PLATFORMS.clearPlatforms());

		response.data.mode = 'edit';

		yield put(Creators.PLATFORMS.updatePlatform(response.data));
		yield call(SToast.success, i18next.t('messages.success.template.selected'));

		if (typeof onSuccess === 'function') {
			yield call(onSuccess, response.data);
		}

		if (typeof redirectPath === 'string') {
			yield put(Creators.MODAL.showModal(redirectPath, {
				flow: response.data?.type?.toLowerCase(),
			}));
		}
	} catch (err) {
		yield call(handleAxiosErrors, err);
	} finally {
		if (typeof callback === 'function') {
			yield call(callback);
		}
	}
}

function* publishPlatform(action) {
	const { platforms } = yield select((state: IRootState) => state);
	const { onError } = action.params;
	try {
		const newPlatform = {
			nome: platforms.nome,
			senha: action.params.password,
			type: platforms.type,
			nomeTemplate: platforms.nomeTemplate,
			resumo: platforms.resumo,
			permissao: platforms.permissao,
			cover: platforms.cover,
			autor: platforms.autor?._id,
			moderadores: platforms.type === 'Challenge'
				? platforms.membros.moderadores.map((elem) => elem._id)
				: [],
			sponsors: platforms.type === 'Challenge'
				? platforms.membros.sponsors.map((elem) => elem._id)
				: [],
			agentes: platforms.type === 'Challenge'
				? platforms.membros.agentes.map((elem) => elem._id)
				: [],
			participantes: platforms.type === 'Brainstorm'
				? platforms.membros.participantes.map((elem) => elem._id)
				: [],
			tags: platforms.tags,
			challengeFormat: platforms.challengeFormat,
			ideiaFormat: platforms.ideiaFormat,
			hasSponsors: platforms.type === 'Brainstorm' ? false : platforms.hasSponsors,
		};

		let schema = null;

		if (platforms.permissao === 'secreta') {
			schema = Yup.object().shape({
				nome: Yup.string().required(i18next.t('messages.requiredField')),
				// passwordRecurrency: Yup.string().required(i18next.t('messages.requiredField')),
				senha: Yup.string().required(i18next.t('messages.requiredField')),
			});
		} else {
			schema = Yup.object().shape({
				nome: Yup.string().required(i18next.t('messages.requiredField')),
			});

			if (newPlatform.resumo) {
				const text = yield call(getTextFromHTML, newPlatform.resumo);
				if (text.length < 1 && typeof onError === 'function') {
					yield call(onError, { resumo: i18next.t('messages.requiredField') });
					return;
				}
			}
		}

		yield schema.validate(newPlatform, {
			abortEarly: false,
		});

		// const { trendsStatus } = platforms;

		yield call(APIPlatforms.putPublish, platforms._id, newPlatform);

		const href = {
			Challenge: `${URLS.CHALLENGES}/${platforms._id}`,
			Brainstorm: `${URLS.BRAINSTORMS}/${platforms._id}`,
		};

		yield call(SToast.success, i18next.t(
			'messages.success.platform.published',
			{ type: i18next.t(`global.${platforms.type.toLowerCase()}`) },
		));

		yield put(Creators.PLATFORMS.clearPlatforms());
		yield put(Creators.MODAL.handleModal({ flow: undefined }));
		yield put(Creators.MODAL.hideModal());
		yield delay(1000);
		yield call(goToLink, href[platforms.type]);
	} catch (err) {
		if (err instanceof Yup.ValidationError && typeof onError === 'function') {
			yield call(onError, getValidationErrors(err));
			return;
		}

		yield call(SToast.error, i18next.t(
			'messages.error.platform.publish',
			{ type: platforms.type.toLowerCase() },
		));

		yield call(handleAxiosErrors, err);
	} finally {
		if (typeof action.params.callback === 'function') {
			yield call(action.params.callback);
		}
	}
}

function* selectAttachments(action) {
	try {
		const platformID: number = yield select((state: IRootState) => state.platforms?._id);

		yield call(APIPlatforms.postAttachments, platformID, action.formData);

		const response: AxiosResponse = yield call(APIPlatforms.get, platformID);

		yield put(Creators.PLATFORMS.updatePlatform({ anexos: response.data.anexos }));
		yield call(SToast.success, i18next.t('messages.success.fileUploaded'));
	} catch (err) {
		yield call(handleAxiosErrors, err);
	} finally {
		if (typeof action.callback === 'function') {
			yield call(action.callback);
		}
	}
}

function* removeAttachment(action) {
	try {
		const platformID: number = yield select((state: IRootState) => state.platforms?._id);
		yield call(APIGlobal.deleteAttachment, action.attachmentId);
		const platform = yield call(APIPlatforms.get, platformID);

		yield put(Creators.PLATFORMS.updatePlatform({ anexos: platform.data.anexos }));
		yield call(SToast.success, i18next.t('messages.success.fileRemoved'));
	} catch (err) {
		yield call(handleAxiosErrors, err);
	} finally {
		if (typeof action.callback === 'function') {
			yield call(action.callback);
		}
	}
}

function* handlePlatform(action) {
	const {
		key, value, onSuccess, onError, callback, noDelay, hasWait,
	} = action.params;

	const { platforms } = yield select((state: IRootState) => state);
	try {
		const field = { [key]: value };
		let schema = null;

		if (!hasWait) {
			yield put(Creators.PLATFORMS.updatePlatform(field));
		}

		if (['nome', 'senha'].includes(key)) {
			schema = Yup.object().shape({
				[key]: Yup.string().required(i18next.t('messages.requiredField')),
			});
		}

		if (['resumo'].includes(key) && typeof onError === 'function') {
			const text = yield call(getTextFromHTML, value);
			if (text.length < 1) {
				yield call(onError, { [key]: i18next.t('messages.requiredField') });
				return;
			}
		}

		if (schema) {
			yield schema.validate(field, {
				abortEarly: false,
			});
		}

		if (!platforms?._id) return;

		if (!noDelay) {
			yield delay(2000);
		}

		const response: AxiosResponse = yield call(APIPlatforms.patchPlatform, platforms?._id, field);

		if (typeof onSuccess === 'function') {
			yield call(onSuccess, response.data);
		}
	} catch (err) {
		if (err instanceof Yup.ValidationError) {
			const errorMessages = yield call(getValidationErrors, err);
			if (typeof onError === 'function') {
				yield call(onError, errorMessages);
			}
		} else yield call(handleAxiosErrors, err);
	} finally {
		if (typeof callback === 'function') {
			yield call(callback);
		}
	}
}

function* handleCoverPlatform(action) {
	try {
		const {
			cover, coverThumb, coverAuthor, onSuccess,
		} = action.p;

		const platformId = yield select((state) => state.platforms?._id);

		if (!platformId) return;

		const response: AxiosResponse = yield call(APIPlatforms.patchPlatform, platformId, {
			cover,
			coverThumb,
			coverAuthor,
		});

		const updated = {
			cover: response.data.cover,
			coverThumb: response.data.coverThumb,
			coverAuthor: response.data.coverAuthor,
		};

		if (typeof onSuccess === 'function') {
			yield call(onSuccess);
		}

		yield put(Creators.PLATFORMS.updatePlatform(updated));
		yield call(SToast.success, i18next.t('messages.success.coverChanged'));
	} catch (err) {
		yield call(handleAxiosErrors, err);
	} finally {
		if (typeof action.p.callback === 'function') {
			yield call(action.p.callback);
		}
	}
}

function* duplicatePlatform(action) {
	const {
		redirectPath, platformId, onSuccess, callback,
	} = action.params;
	try {
		const [response] = yield all([
			call(APIPlatforms.postDuplicate, platformId),
		]);

		if (!response.data) return;

		yield put(Creators.PLATFORMS.updatePlatform({
			...response.data,
			mode: 'edit',
			canvas: [...response.data.canvas, response.data.miroBoards],
		}));

		yield call(SToast.success, i18next.t(
			'messages.success.platform.duplicated',
			{ type: i18next.t(`global.${response.data.type.toLowerCase()}`) },
		));

		if (typeof redirectPath === 'string') {
			yield put(Creators.MODAL.showModal(redirectPath, {
				flow: response.data?.type?.toLowerCase(),
			}));
		}

		if (typeof onSuccess === 'function') {
			yield call(onSuccess);
		}
	} catch (err) {
		yield call(handleAxiosErrors, err);
		yield call(SToast.error, i18next.t('messages.error.platform.duplicate'));
	} finally {
		if (typeof callback === 'function') {
			yield call(callback);
		}
	}
}

function* deletePlatform(action) {
	const { flow } = action.p;
	try {
		yield call(APIPlatforms.deletePlatform, action.p.platformId);
		yield put(Creators.MODAL.hideSecondModal());
		yield call(SToast.success, i18next.t(
			'messages.success.platform.deleted',
			{ type: i18next.t(`global.${flow}`) },
		));

		if (typeof action.p.onSuccess === 'function') {
			yield call(action.p.onSuccess);
		}
	} catch (err: any) {
		yield call(handleAxiosErrors, err);
		yield call(SToast.error, i18next.t(
			'messages.error.platform.delete',
			{ type: i18next.t(`global.${flow}`) },
		));
	} finally {
		if (typeof action.p.callback === 'function') {
			yield call(action.p.callback);
		}
	}
}

function* getPlatformParticipants(action) {
	const {
		onSuccess, platformId, callback, ...rest
	} = action.params;
	try {
		const response: AxiosResponse = yield call(APIPlatforms.getParticipants, platformId, {
			...rest,
		});

		if (typeof onSuccess === 'function') {
			yield call(onSuccess, response.data);
		}
	} catch (err) {
		yield call(handleAxiosErrors, err);
	} finally {
		if (typeof callback === 'function') {
			yield call(callback);
		}
	}
}

function* removeBoardPlatform(action) {
	const {
		platformId, boardId, callback, onSuccess,
	} = action.params;
	try {
		if (!platformId) return;
		yield call(APIPlatforms.patchPlatform, platformId, { boardId });

		if (typeof onSuccess === 'function') {
			yield call(onSuccess);
		}

		yield put(Creators.MODAL.backModal());
	} catch (err) {
		yield call(handleAxiosErrors, err);
	} finally {
		if (typeof callback === 'function') {
			yield call(callback);
		}
	}
}

function* handleAddIdea(action) {
	const {
		platformId, callback, onSuccess, type,
	} = action.params;
	try {
		const { ideas, modal } = yield select((state: IRootState) => state);
		const ideaId = modal.ModalProps.ideaId || ideas._id;
		const response: AxiosResponse = yield call(APIIdeas.putPlatform, ideaId, platformId);

		if (platformId) {
			yield call(SToast.success, i18next.t('platform.success.addToIdea', { type }));
		} else {
			yield call(SToast.success, i18next.t('platform.success.removed', { type }));
		}

		if (typeof onSuccess === 'function') {
			yield call(onSuccess, response.data);
		} else {
			yield put(Creators.IDEAS.updateIdeas(response.data));
		}
	} catch (err: any) {
		if (['platform.warning.coautors'].includes(err?.response?.data?.err)) {
			i18next.t(err.response.data.er, { type });
			return;
		}
		handleAxiosErrors(err);
	} finally {
		if (typeof callback === 'function') {
			yield call(callback); // close inProgress on select modal
		}
	}
}

export default function* () {
	yield takeLatest(Types.PLATFORMS.PUBLISH_PLATFORM, publishPlatform);
	yield takeLatest(Types.PLATFORMS.HANDLE_PLATFORM, handlePlatform);
	yield takeLatest(Types.PLATFORMS.GET_PLATFORM, getPlatform);
	yield takeLatest(Types.PLATFORMS.SELECT_ATTACHMENTS, selectAttachments);
	yield takeLatest(Types.PLATFORMS.REMOVE_ATTACHMENT, removeAttachment);
	yield takeLatest(Types.PLATFORMS.DUPLICATE_PLATFORM, duplicatePlatform);
	yield takeLatest(Types.PLATFORMS.DELETE_PLATFORM, deletePlatform);
	yield takeLatest(Types.PLATFORMS.GET_PLATFORM_PARTICIPANTS, getPlatformParticipants);
	yield takeLatest(Types.PLATFORMS.REMOVE_BOARD_PLATFORM, removeBoardPlatform);
	yield takeLatest(Types.PLATFORMS.HANDLE_COVER_PLATFORM, handleCoverPlatform);
	yield takeLatest(Types.PLATFORMS.HANDLE_PLATFORM_TEMPLATE, handlePlatformTemplate);
	yield takeLatest(Types.PLATFORMS.HANDLE_ADD_IDEA, handleAddIdea);
}
