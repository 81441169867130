import { Creators } from '@actions';
import { HelpButton } from '@components/Buttons/HelpButton';
import { DynamicCircle } from '@components/DynamicCircle';
import RedactorText from '@components/Shared/RedactorText';
import { UserUpdated } from '@components/Shared/UserUpdated';
import { TRoutes } from '@routes/modal/types';
import { normalizesValue } from '@utils/helpers';
import React, { Suspense } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { IEvaluation, TEvaluationValue } from 'src/@types/IIdea';
import { BoxProps } from '@mui/material';
import { SBox } from './SBox';

interface BEvaluationRowProps extends BoxProps {
	ideaId: string
	evaluation: IEvaluation
	myEvaluationValue?: TEvaluationValue
	routes: TRoutes
	action?: 'view' | 'edit'
	onClick?: () => void
}

declare type TProps = BEvaluationRowProps & WithTranslation;

const _BEvaluationRow = (props: TProps) => {
	const {
		t, evaluation, routes, ideaId, action, onClick, myEvaluationValue, ...rest
	} = props;

	const dispatch = useDispatch();

	return (
		<SBox className="d-flex row-evaluation" {...rest}>
			<DynamicCircle
				icon={evaluation.icon}
				size="x-large"
				className={`evaluation fill ${normalizesValue(evaluation?.value || 'Pendente')}`}
				action={action}
				onClick={typeof onClick === 'function' ? onClick : undefined}
			/>
			<div className="container-content">
				<div className="item">
					<div className="title">
						<span className="font-light">
							{t(evaluation.type)}
							:
						</span>
						<strong className="first-letter-capitalize ml-1">
							{t(`evaluation.values.${normalizesValue(evaluation?.value)}`)}
						</strong>
						<HelpButton size="small" href={t(`urls.criteria.${evaluation.name.toLowerCase()}`)} />
					</div>
				</div>
				{myEvaluationValue
					&& (
						<div className="title">
							<span className="font-light">
								{t('evaluation.my')}
								:
							</span>
							<strong className="first-letter-capitalize ml-1">
								{t(`evaluation.values.${normalizesValue(myEvaluationValue)}`)}
							</strong>
						</div>
					)}
				<RedactorText html={evaluation.description} hasToggle className="content-text" />
				{evaluation.evaluators && (
					<UserUpdated
						prefixBy={t('global.evaluated')}
						group={evaluation.evaluators}
						updatedAt={evaluation.lastEvaluation}
						prefixByUpdatedAt={t('evaluation.last')}
						className="justify-content-between"
						prefixTarget="evaluator"
						hasPopover
						onMore={() => dispatch(
							Creators.MODAL.showModal(
								routes.MODAL_IDEA_EVALUATIONS_BY_CRITERIA.path,
								{ ideaId, criteria: t(evaluation.type) },
							),
						)}
					/>
				)}
			</div>
		</SBox>
	);
};

const Translation = withTranslation('common')(_BEvaluationRow);

const BEvaluationRow = (props: BEvaluationRowProps) => (
	<Suspense fallback={<div />}>
		<Translation {...props} />
	</Suspense>
);

export { BEvaluationRow };
