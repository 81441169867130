import React, { useState, useEffect } from "react";
import { format } from "date-fns";
/*
 ** GOOGLE DRIVE
 */
export function getGoogleDriveSettings() {
  return {
    // The Browser API key obtained from the Google API Console.
    appId: process.env.GOOGLE_APP_ID,
    // The Client ID obtained from the Google API Console. Replace with your own Client ID.
    developerKey: process.env.GOOGLE_DEVELOPER_KEY,
    clientId: process.env.GOOGLE_CLIENT_ID,
    // Scope to use to access user's Drive items.
    scope: process.env.GOOGLE_DRIVE_URL,
    pickerApiLoaded: true,
    oauthToken: null,
  };
}

// Create and render a Picker object for searching images.
function googleDriveCreatePicker(googleDriveSettings, pickerCallback) {
  if (googleDriveSettings.pickerApiLoaded && googleDriveSettings.oauthToken) {
    const view = new google.picker.DocsView()
      .setParent("root")
      .setIncludeFolders(true)
      .setSelectFolderEnabled(true);
    const viewSharedWithMe = new google.picker.DocsView()
      .setIncludeFolders(true)
      .setSelectFolderEnabled(true)
      .setOwnedByMe(false);
    const viewStarredSharedWithMe = new google.picker.DocsView()
      .setIncludeFolders(true)
      .setSelectFolderEnabled(true)
      .setStarred(true)
      .setLabel("Starred");
    const picker = new google.picker.PickerBuilder()
      .enableFeature(google.picker.Feature.MULTISELECT_ENABLED)
      .setAppId(googleDriveSettings.appId)
      .setOAuthToken(googleDriveSettings.oauthToken)
      .addView(view)
      .addView(viewSharedWithMe)
      .addView(viewStarredSharedWithMe)
      .setDeveloperKey(googleDriveSettings.developerKey)
      .setCallback(pickerCallback)
      .build();
    picker.setVisible(true);
  }
}

export function getGoogleDriveMethods(googleDriveSettings, pickerCallback) {
  return {
    handleAuthResult(authResult) {
      if (authResult && !authResult.error) {
        const key = Object.keys(authResult)[1];
        googleDriveSettings.oauthToken = authResult[key].access_token;
        googleDriveCreatePicker(googleDriveSettings, pickerCallback);
      } else {
        console.log(authResult.details);
      }
    },
    onPickerApiLoad() {
      googleDriveSettings.pickerApiLoaded = true;
    },
  };
}
/*
 */

/*
 ** CHART
 */

export function getChartToolboxOpts() {
  return {
    show: true,
    feature: {
      mark: { show: true },
      magicType: {
        show: true,
        type: ["pie", "funnel"],
      },
      restore: { show: true, title: "Restaurar" },
      saveAsImage: { show: true, title: "Salvar\nImagem" },
    },
  };
}

/*
 ** EventHandlers
 */
export function goToAnchorHref(e) {
  window.location.href = e.target.href;
}

export function goToLink(link) {
  window.location.href = link;
}

export function goToLinkNewWindow(link) {
  window.open(link, "_blank");
}

/*
 ** FUNCTIONS
 */

export function between(x, min, max) {
  return x >= min && x < max;
}

export function getColor(percent) {
  let red = 255;
  let green = 0;
  if (between(percent, 0, 50)) green += (255 * percent * 2) / 100;
  else if (between(percent, 50, 100.0001)) {
    red *= 1 + (1 - (percent * 2) / 100);
    green = 255;
  } else {
    red = 0;
    green = 255;
  }
  return `rgb(${red},${green},0)`;
}

export const richtextEditorFocusOnTab = (editor) => {};

export const moveFocusOnTabToRichTextEditor = (e, editor) => {
  let key = e.keyCode || e.which;
  const ctrl = e.ctrlKey || e.metaKey;
  key = e.key;

  if (!ctrl) {
    // ESC
    if (key === "Tab") {
      e.preventDefault();
      if (editor) {
        // window._EDITOR = editor;
        editor.focus();
        // setTimeout(() => { editor.focus() }, 200)
      }
    }
  }

  return true;
};

// Using to persist data on local storage
export function usePersistedState(key, defaultValue) {
  const [state, setState] = useState(
    JSON.parse(localStorage.getItem(key)) || defaultValue
  );
  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(state));
  }, [key, state]);
  return [state, setState];
}

export function minThreeDigits(length) {
  if (length < 10) return `00${length}`;
  if (length > 9 && length < 100) return `0${length}`;
  return length;
}

export function isEmpty(obj) {
  for (const prop in obj) {
    if (obj.hasOwnProperty(prop)) return false;
  }
  return true;
}

export const validEmails = (email) =>
  lodash.uniq(
    email.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi) || []
  );

export const formatDate = (date) => format(new Date(date), "dd/MM/yyyy");
