// @ts-nocheck
import React, { Suspense, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { WithTranslation, withTranslation } from 'react-i18next';
import _ from 'lodash';
import { buildShowTabQueryString } from '@utils/helpers';
import { IPlatform } from 'src/@types/IPlatform';
import { Creators } from '@actions';
import URLS from '@constants/URLS';
import { FLOWS } from '@constants/GLOBAL';
import { UsersGroupCircle } from '@components/Avatar/UsersGroupCircle';
import { goToLink } from '@utils/ReactUtils';
import ModalFeedback, { IFeedbackProps } from '@components/Modals/ModalFeedback';
import { IIdea } from 'src/@types/IIdea';
import { AiFillHeart, AiOutlineHeart } from 'react-icons/ai';
import { SocketIO, SOCKET_EVENTS } from '@services/socket';
import ReceiveButton from '@components/Buttons/ReceiveButton';
import { TRoutes } from '@routes/modal/types';
import { Box, IconButton, Tooltip } from '@mui/material';
import { persistor } from 'src/store';
import { SButton } from '@components/Buttons';
import { PlatformTooltip } from '@components/STooltips/PlatformTooltip';
import { useNavigate } from 'react-router-dom';
import Icon from '../Shared/Icon';
import { PermissionIcon } from '../Permission';
import { Sticker } from '../Stickers/Sticker';
import { ActivityChip } from '../Shared/ActivityChip';

interface IPlatformCardProps {
	platform: IPlatform
	sectionLocator: string
	onDelete?: (platformId: string) => void
	updateIdea?: (idea: IIdea) => void
	updatePlatform?: (platform: IPlatform) => void
	idea?: IIdea
	clickable?: boolean
	routes: TRoutes
}

declare type TProps = IPlatformCardProps & WithTranslation;

const PPlatformCard = (props: TProps) => {
	const {
		platform, t, sectionLocator = 'platform-card', onDelete,
		updateIdea, updatePlatform, idea, clickable = true, routes,
	} = props;

	const navigate = useNavigate();
	const dispatch = useDispatch();

	const flow = platform?.type.toLowerCase();
	const [feedbackProps, setFeedbackProps] = useState<IFeedbackProps>({ open: false });

	const [target, setTarget] = useState({
		comments: `comments_${platform._id}_${_.uniqueId(platform._id)}`,
	});

	const _cover = platform.coverThumb ? platform.coverThumb : platform.cover;
	const ideaLength = platform.ideias?.length;

	const href = {
		Challenge: `${URLS.CHALLENGES}/${platform._id}`,
		Brainstorm: `${URLS.BRAINSTORMS}/${platform._id}`,
	};

	const mode = platform.isArchived
		|| (Object.hasOwnProperty.call(platform, 'isFinished')
			&& platform.isFinished)
		? 'view' : 'edit';

	const onEditHandler = (e: React.MouseEvent) => {
		e.stopPropagation();
		dispatch(Creators.MODAL.handleModal({ flow }));
		if (typeof updatePlatform === 'function') {
			dispatch(Creators.PLATFORMS.updatePlatform({
				workflow: 'UPDATE_PLATFORM',
				syncPlatform: updatePlatform,
			}));
			// state to card
		}

		dispatch(
			Creators.PLATFORMS.getPlatform(
				{
					platformId: platform._id,
					redirectPath: routes.MODAL_PLATFORM.path,
				},
			),
		);
	};

	const onLikeHandler = (event: React.MouseEvent) => {
		event?.stopPropagation();
		SocketIO.emit(SOCKET_EVENTS.PLATFORM_LIKE, { id: platform._id });
	};

	useEffect(() => {
		SocketIO.on(SOCKET_EVENTS.PLATFORM_LIKE, (_platform: IPlatform) => {
			if (typeof updatePlatform === 'function' && _platform._id === platform._id) {
				// @ts-ignore
				updatePlatform({ _id: _platform._id, likes: _platform.likes });
			}
		});
	}, [SocketIO]);

	const onDeleteHandler = () => {
		setFeedbackProps((params) => ({ ...params, isLoading: true }));
		dispatch(Creators.PLATFORMS.deletePlatform({
			platformId: platform._id,
			flow,
			onSuccess: () => {
				if (typeof onDelete === 'function') {
					onDelete(platform._id);
				}
			},
			callback: () => setFeedbackProps((params) => ({ ...params, isLoading: false })),
		}));
	};

	const onAddIdeaHandler = () => {
		setFeedbackProps((params) => ({ ...params, isLoading: true }));
		dispatch(Creators.PLATFORMS.handleAddIdea({
			platformId: platform._id,
			type: t(`global.${platform?.type?.toLowerCase()}`),
			onSuccess: (_idea: IIdea) => {
				if (typeof updateIdea === 'function') {
					updateIdea(_idea);
				}
				setFeedbackProps({ open: false });
			},
			callback: () => setFeedbackProps((params) => ({ ...params, isLoading: false })),
		}));
	};

	const platformButton = (type: string) => {
		const defaultProps = {
			type: 'button',
			color: 'inherit',
			variant: 'outlined',
			size: 'large',
		};
		switch (type) {
			case 'add':
				return (
					<SButton
						onClick={() => setFeedbackProps({
							...feedbackProps,
							title: t('feedback.addPlatform.title', { object: t(`global.${flow}`), name: platform.nome }),
							rightButtonText: t('global.confirm'),
							open: true,
							color: 'success',
							text: idea?.plataforma?.type === 'Challenge' ? t('feedback.addPlatform.text') : '',
							onRightButton: onAddIdeaHandler,
						})}
						{...defaultProps}
					>
						{t('global.toSelect')}
					</SButton>
				);
			case 'newIdea':
				return (
					<SButton
						onClick={(e) => {
							e.stopPropagation();
							persistor.pause();
							dispatch(Creators.MODAL.handleModal({ flow: FLOWS.IDEA }));
							dispatch(Creators.MODAL.showModal(routes.MODAL_CREATE.path, {
								platformId: platform._id,
							}));
						}}
						{...defaultProps}
					>
						{t('idea.new')}
					</SButton>
				);
			case 'edit':
				return (
					<SButton
						onClick={onEditHandler}
						{...defaultProps}
					>
						{t('global.edit')}
					</SButton>
				);
			case 'authenticate':
			case 'invite':
			case 'join':
				return (
					<ReceiveButton
						mode={type}
						feedbackOptions={{
							match: {
								platformId: platform?._id,
								mode: type,
								flow,
								hasInvite: platform.possuiPedidoParticipacao,
								permission: platform.permissao,
								updatePlatform,
							},
						}}
					/>
				);
			default:
				return null;
		}
	};

	const mainButton = () => {
		if (platform.status === 'rascunho') return platformButton('edit');
		if (platform.isArchived) return null;
		if (platform.usuarioParticipa) {
			switch (platform.type) {
				case 'Challenge':
					if (platform.usuarioAgencia && sectionLocator === 'idea-platform-add') return platformButton('add');
					if (platform.usuarioAgencia && !platform.isFinished) return platformButton('newIdea');
					if (platform.usuarioModera) return platformButton('edit');
					if (platform.usuarioPatrocina) return null;
					break;
				case 'Brainstorm':
					if (sectionLocator === 'idea-platform-add') return platformButton('add');
					if (platform?.usuarioParticipa) {
						return platformButton('newIdea');
					}
					break;
				default:
					break;
			}
		} else {
			if (platform.possuiPedidoParticipacao) {
				return platformButton('invite');
			}
			if (platform.permissao === 'publica') {
				return platformButton('join');
			}
		}
		return null;
	};

	const goToPlatform = (e: React.MouseEvent) => {
		e?.stopPropagation();
		if (clickable) {
			// goToLink(href[platform.type]);
			navigate(href[platform.type]);
		}
	};

	const statusSticker = (function () {
		if (platform.isArchived) {
			return <Sticker label={t('platform.archived')} className="dark" />;
		}
		if (platform.usuarioParticipa) {
			return <Sticker label={t('global.joined')} className="primary" />;
		}
		if (platform.possuiPedidoParticipacao) {
			return <Sticker label={t('global.invited')} className="gray" />;
		}
		return null;
	}());

	useEffect(() => {
		setTarget((prevState) => ({ ...prevState }));
	}, []);

	return (
		<Box className="box-item card-box" sx={{ cursor: clickable ? 'pointer' : 'auto' }}>
			<figure
				className="rollover"
				onClick={goToPlatform}
				aria-hidden
				style={{ cursor: clickable ? 'pointer' : 'auto' }}
			>
				<div className="box-photo">
					<img src={_cover} loading="lazy" className="lazy lazy-loaded" alt="" />
				</div>
				<div className="hover-item">
					<Box className="top left">
						<PlatformTooltip type={flow}>
							<IconButton>
								<Icon icon={flow} />
							</IconButton>
						</PlatformTooltip>
						<span className="ml-1">{t(`global.${platform.type.toLowerCase()}`)}</span>
					</Box>
					<Box className="top right" sx={{ gap: 1 }}>
						<ActivityChip
							icon={platform?.likes?.length > 0 ? <AiFillHeart className="heart-red" /> : <AiOutlineHeart />}
							className="has-action"
							count={platform?.likes?.length}
							onClick={onLikeHandler}
						/>
						<ActivityChip
							count={ideaLength}
							icon="idea"
							className={`${platform.usuarioCriouIdeias ? 'active' : ''}`}
							tooltip={t('global.ideas')}
							target={`${sectionLocator}-ideas-${platform._id}`}
						/>
					</Box>
					<div className="middle">{mainButton()}</div>
					<div className="bottom right" />
				</div>
			</figure>
			<div className="box-info">
				<div className="content-body">
					<div className="content-infos">
						<Tooltip
							arrow
							placement="top"
							classes={{ popper: 'secondary' }}
							title={platform.nome}
						>
							<a
								id={`card-name-${platform._id}`}
								href={clickable ? href[platform.type] : undefined}
								className={`item-title ${clickable ? 'clickable' : ''}`}
								style={{ cursor: clickable ? 'pointer' : 'auto' }}
							>
								{platform.nome}
							</a>
						</Tooltip>

						<Tooltip classes={{ popper: 'secondary' }} placement="top" arrow title={t('global.publishDate')}>
							<div id={target.publishedAt} className="publication-date">
								{platform.publishedAt
								&& t('dates.fullDate', { date: new Date(platform.publishedAt) })}
							</div>
						</Tooltip>
					</div>
					<div className="align-items-center d-flex pb-2 pt-2">
						<UsersGroupCircle
							clickable={clickable}
							prefixTarget={sectionLocator}
							group={
								platform.type === 'Challenge'
									? [
										...platform.membros.moderadores,
										...platform.membros.agentes,
										...platform.membros.sponsors,
									]
									: platform.membros.participantes
							}
							id={platform._id}
							hasMore
							hasPopover
							tooltipIsMoreText={t('global.participants')}
							onMore={clickable
								? () => goToLink(buildShowTabQueryString(href[platform.type], 'participants-tab', '#platform-related-tabs'))
								: undefined}
						/>

					</div>
				</div>
				<div className="footer">
					<PermissionIcon permission={platform.permissao} />
					{platform.status !== 'rascunho' ? statusSticker
						: (
							<Box sx={{ display: 'flex', gap: '6px' }}>
								<Tooltip classes={{ popper: 'secondary' }} placement="top" title={t('global.delete')} arrow>
									<IconButton
										size="small"
										onClick={() => setFeedbackProps({
											...feedbackProps,
											title: t('feedback.delete.title-o', { name: `${t(`global.${flow}`).toLowerCase()} ${platform.nome}` }),
											rightButtonText: t('global.delete'),
											open: true,
											color: 'error',
											onRightButton: onDeleteHandler,
										})}
										className="i-button"
									>
										<Icon icon="remove" />
									</IconButton>
								</Tooltip>
								<Tooltip classes={{ popper: 'secondary' }} placement="top" title={t('global.edit')} arrow>
									<IconButton onClick={onEditHandler} className="i-button" size="small">
										<Icon icon="edit" />
									</IconButton>
								</Tooltip>
							</Box>
						)}
				</div>
			</div>
			{feedbackProps.open && (
				<ModalFeedback
					id="platform"
					onRequestClose={() => setFeedbackProps({ open: false })}
					{...feedbackProps}
				/>
			)}
		</Box>
	);
};

const Translation = withTranslation('common')(PPlatformCard);

const PlatformCard = (props: IPlatformCardProps) => {
	const { platform } = props;
	return (
		<Suspense fallback={<div />}>
			{typeof platform === 'undefined' ? <div /> : <Translation {...props} />}
		</Suspense>
	);
};

export { PlatformCard };
