import { Box } from '@mui/material';
// eslint-disable-next-line import/no-extraneous-dependencies
import { styled as MuiStyled } from '@mui/system';

export const NotificationsBox = MuiStyled(Box)(({ theme }) => ({
	color: 'rgb(38, 40, 46)',
	display: 'flex',
	flexDirection: 'column',
	backgroundColor: 'var(--body-backgroundColor)',
	height: '100%',
	cursor: 'auto',
	boxShadow: '0px 5px 20px rgba(0, 0, 0, 0.25)',
	overflowY: 'hidden',
	overflowX: 'hidden',
	justifyContent: 'flex-start !important',
	'.notifications-container': {
		overflow: 'auto',
	},
	'.notification-list': {
		padding: '0 20px',
		'li.read': {
			borderBottom: '1px solid var(--fog-gray-color)',
		},
		li: {
			margin: '1px 0',
			'.notification': {
				display: 'flex',
				padding: '20px 12px',
				borderRadius: '4px',
				cursor: 'pointer',
			},
			'.notification:hover': {
				backgroundColor: 'rgba(0, 0, 0, 0.1)',
			},
			'.notification.unread': {
				backgroundColor: 'rgba(76, 122, 144, 0.25)',
			},
			'.notification.unread:hover': {
				backgroundColor: 'rgba(76, 122, 144, 0.1)',
			},
			'.notification-icon': {
				minWidth: '1.5rem',
				minHeight: '1.5rem',
				maxWidth: '1.5rem',
				maxHeight: '1.5rem',
				fontSize: '0.7rem',
				lineHeight: '0.5rem',
				marginLeft: '55px',
				position: 'relative',
				marginTop: '-25px',
				cursor: 'pointer',
				borderRadius: '50%',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				fontWeight: '400',
				overflow: 'hidden',
				'.background-icon': {
					backgroundColor: '#fff',
					minWidth: '1.5rem',
					minHeight: '1.5rem',
					maxWidth: '1.5rem',
					maxHeight: '1.5rem',
          position: 'absolute',
					borderRadius: '50%',
				},
				'.brainstorm': {
					backgroundColor: 'rgba(76, 122, 144, .4)',
					color: 'rgba(76, 122, 144, 1)',
				},
				'.challenge': {
					backgroundColor: 'rgba(76, 122, 144, .4)',
					color: 'rgba(76, 122, 144, 1)',
				},
				'.idea': {
					backgroundColor: 'rgba(76, 122, 144, .4)',
					color: 'rgba(76, 122, 144, 1)',
				},
				'.report': {
					backgroundColor: 'rgba(76, 122, 144, .4)',
					color: 'rgba(76, 122, 144, 1)',
				},
				'.reproved': {
					backgroundColor: 'var(--error-color)',
				},
				'.approved': {
					backgroundColor: '#3b9776',
				},
				'.team': {
					backgroundColor: '#f39da5',
				},
				'.comments': {
					backgroundColor: '#fcd700',
				},
				'.flag': {
					backgroundColor: 'var(--primary)',
				},
				'.like': {
					backgroundColor: 'var(--error-color)',
				},
				'.member': {
					backgroundColor: 'rgb(98, 210, 111)',
					color: '#fff',
				},
			},
			'.notification-info': {
				width: '70%',
				padding: '0 10px',
				textAlign: 'start',
				color: 'var(--charcoal-color)',
				div: {
					display: 'flex',
					justifyContent: 'flex-start',
					flexDirection: 'column',
				},
				'.notification-title': {
					marginBottom: 0,
					'.element': {
						textTransform: 'capitalize',
						paddingLeft: '.3rem',
						paddingRight: '.3rem',
					},
				},
				'.notification-time': {
					textTransform: 'lowercase',
					color: 'var(--primary)',
					lineHeight: '1rem',
					fontWeight: '400',
					fontSize: '0.75rem',
				},
			},
			'.notification-status': {
				display: 'flex',
				alignItems: 'center',

				'.circle': {
					width: '16px',
					height: '16px',
					'&.unread': {
						backgroundColor: 'var(--primary)',
						border: '1.8px solid #FFFFFF',
						boxSizing: 'border-box',
					},
				},
			},
		},
	},
	'.notifications-header': {
		width: '100%',
		display: 'flex',
		lineHeight: '2.3rem',
		color: 'black',
		position: 'initial',
		justifyContent: 'space-between',
		alignItems: 'center',
		boxSizing: 'border-box',
		flexDirection: 'row',
		padding: '30px 1.5rem',
		height: 'var(--navbar-height)',
	},
}));
