// @ts-nocheck
import React, { useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Creators } from 'src/store/ducks/actionsTypes';
import { IPaginatedRequest, IPagination } from 'src/@types/IShared';
import { IPlatform } from 'src/@types/IPlatform';
import { IIdea } from 'src/@types/IIdea';
import { APIIdeas, APIPlatforms } from '@services/apis';
import GLOBAL from '@constants/GLOBAL';
import { IdeaCard } from '@components/Cards/IdeaCard';
import { PlatformCard } from '@components/Cards/PlatformCard';
import { handleAxiosErrors } from '@services/auth';
import { TPlatformRequest } from '@services/apis/platforms';
import { IListRequest } from '@components/Lists/types';
import CardGrid, { TCards } from '@components/Grids/CardGrid';
import routes from '@routes/modal';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import { SToast } from '@modules/SToast';
import PageLoader from '@components/Loaders/PageLoader';
import { SBox } from '@components/Background/SBox';
import { GenericGrid } from '@components/Grids/GenericGrid';

interface IState {
	challenges: IPagination<IPlatform>
	brainstorms: IPagination<IPlatform>
	ideas: IPagination<IIdea>
}

declare type TPlatformType = 'challenges' | 'brainstorms'

const DicPlatformType = {
	brainstorms: 'brainstorm',
	challenges: 'challenge',
};

type TProps = WithTranslation

const DDrafts = ({ t }: TProps) => {
	const navigate = useNavigate();
	const location = useLocation();
	const dispatch = useDispatch();

	const [state, setState] = useState({
		challenges: { docs: [], isLoading: true },
		brainstorms: { docs: [], isLoading: true },
		ideas: { docs: [], isLoading: true },
	});

	const onDeleteHandler = (elementId: string) => {
		Object.keys(state).forEach((k) => {
			const key = k as keyof IState;
			const index = state[key].docs.findIndex((item) => item._id === elementId);
			if (index > -1) {
				state[key].docs.splice(index, 1);
				setState((prevState) => ({
					...prevState,
					[key]: {
						...prevState[key],
					},
				}));
			}
		});
	};

	const loadIdeas = async (params: IListRequest) => {
		try {
			const queryParams = params || {};
			queryParams.sort = 'last-first';
			queryParams.limit = GLOBAL.DOCS_PER_PAGE;

			const response = await APIIdeas.getDrafts(params);

			setState((prevState) => ({
				...prevState,
				ideas: {
					...prevState.ideas,
					...response.data,
					docs: response.data.hasPrevPage
						? prevState.ideas.docs?.concat(response.data.docs)
						// @ts-ignore
						: response.data.docs,
					isLoading: false,
				},
			}));
		} catch (err: any) {
			handleAxiosErrors(err);
			SToast.error(t('messages.error.idea.drafts'));
		} finally { // loadMore
			if (typeof params.callback === 'function') {
				params.callback();
			}
		}
	};

	const loadPlatforms = async (params: IListRequest, type: TPlatformType) => {
		try {
			const queryParams = params || {};
			queryParams.sort = 'last-first';
			queryParams.limit = GLOBAL.DOCS_PER_PAGE;

			const response = await APIPlatforms.getDrafts(queryParams, DicPlatformType[type] as TPlatformRequest);

			setState((prevState) => ({
				...prevState,
				[type]: {
					...prevState[type],
					...response.data,
					docs: response.data.hasPrevPage
						? prevState[type].docs?.concat(response.data.docs)
						: response.data.docs,
					isLoading: false,
				},
			}));
		} catch (err: any) {
			SToast.error(t(
				'messages.error.platform.drafts',
				{ type: t(`global.${type}`) },
			));
		} finally { // loadMore
			if (typeof params.callback === 'function') {
				params.callback();
			}
		}
	};

	const loadMore = (params: IListRequest) => {
		const filter = new URLSearchParams(location.search)?.get('filter') || '';

		navigate(`?filter=${filter}`, { replace: true });

		switch (filter) {
			case 'ideas':
				loadIdeas(params);
				break;
			default:
				loadPlatforms(params, filter as TPlatformType);
				break;
		}
	};

	const updateHandler = (element: IPlatform | IIdea) => {
		Object.keys(state).forEach((k) => {
			const key = k as keyof IState;
			const index = state[key].docs.findIndex((item) => item._id === element._id);
			if (index > -1) {
				// @ts-ignore
				state[key]?.docs.splice(index, 1, { ...state[key]?.docs[index], ...element });
				setState((prevState) => ({
					...prevState,
					[key]: {
						...prevState[key],
					},
				}));
			}
		});
	};

	const onViewMoreHandler = ({ filter }: IPaginatedRequest) => {
		navigate(`?filter=${filter || ''}`, { replace: true });

		switch (filter) {
			case 'ideas':
				loadIdeas({ page: 1 });
				break;
			case 'brainstorms':
			case 'challenges':
				loadPlatforms({ page: 1 }, filter);
				break;
			default:
				loadIdeas({ page: 1 });
				loadPlatforms({ page: 1 }, 'challenges');
				loadPlatforms({ page: 1 }, 'brainstorms');
				break;
		}
	};

	React.useEffect(() => {
		const params = {
			filter: new URLSearchParams(location.search)?.get('filter') || '',
		};

		onViewMoreHandler(params);

		dispatch(Creators.IDEAS.updateIdeas({ syncIdea: updateHandler }));
		dispatch(Creators.PLATFORMS.updatePlatform({ syncPlatform: updateHandler }));
	}, []);

	const params = {
		filter: new URLSearchParams(location.search)?.get('filter') || '',
	};

	return (
		<GenericGrid id="drafts-page" className="drafts__page s-grid-page" paddingTop={6}>
			{
				[
					{
						section: state.brainstorms,
						type: 'brainstorms',
						component: (platform: IPlatform) => (
							<PlatformCard
								platform={platform}
								routes={routes}
								sectionLocator="brainstorms"
								onDelete={onDeleteHandler}
								updatePlatform={updateHandler}
							/>
						),
					},
					{
						section: state.challenges,
						type: 'challenges',
						component: (platform: IPlatform) => (
							<PlatformCard
								platform={platform}
								sectionLocator="challenges"
								routes={routes}
								onDelete={onDeleteHandler}
								updatePlatform={updateHandler}
							/>
						),
					},
					{
						section: state.ideas,
						type: 'ideas',
						component: (idea: IIdea) => (
							<IdeaCard
								idea={idea}
								routes={routes}
								sectionLocator="ideas"
								onDelete={onDeleteHandler}
								updateIdea={updateHandler}
							/>
						),
					},
				].map((item, index) => (
					// eslint-disable-next-line react/no-array-index-key
					<Box key={index} className="inner w-grid-1">
						<CardGrid
							id={`${item.type}-drafts`}
							section={item.section}
							queryParams={{ limit: GLOBAL.DOCS_PER_PAGE }}
							headerLabel={t(`page.${item.type}.drafts`)}
							headerSubLabel={t(`page.${item.type}.instruction`)}
							GridItemProps={{ xs: 12, sm: 6, md: 4, xl: 3}}
							type={item.type as TCards}
							hide={params.filter !== '' && params.filter !== item.type}
							onViewMore={params.filter === '' ? () => onViewMoreHandler({ filter: item.type }) : undefined}
							loadMore={loadMore}
							component={item.component}
						/>
					</Box>
				))
			}
		</GenericGrid>
	);
};

const Translation = withTranslation('common')(DDrafts);

const Drafts = (props: TProps) => (
	<React.Suspense fallback={<PageLoader />}>
		<Translation {...props} />
	</React.Suspense>
);

export default Drafts;
