// @ts-nocheck
import { BASE_URL } from '@services/AxiosInstance';
import axios from 'axios';

const APIUnregistered = {
	postEmail(body) {
		return axios.post(`${BASE_URL}/register`, body);
	},
	postToken(token: string) {
		return axios.post(`${BASE_URL}/register/${token}/confirm_token`);
	},
	postLogin(form) {
		return axios.post(`${BASE_URL}/login`, form);
	},
	postResendToken(body) {
		return axios.post(`${BASE_URL}/register/resend_confirmation_mail`, body);
	},
	postEmailLandingPage(body) {
		return axios.post(`${BASE_URL}/register/lp`, body);
	},
	postRecoveryPwd(body) {
		return axios.post(`${BASE_URL}/login/password_reset`, body); // ok
	},
	postResetPwd(token: string, body) {
		return axios.post(`${BASE_URL}/login/${token}/password_reset/`, body);
	},
	postCreateOrg(email: string) {
		return axios.post(`${BASE_URL}/unregistred/organizations`, { email });
	},
	postAcceptInvite(token: string, body) {
		return axios.post(`${BASE_URL}/register/${token}/accept_invite`, body);
	},
	deleteOrganization(organizationId: string) {
		return axios.delete(`${BASE_URL}/unregistred/organization/${organizationId}/confirm_delete`);
	},
	postGoogleCheckout(body: {}) {
		return axios.post(`${BASE_URL}/register/google`, body);
	},
	postLinkedInCheckout(code: string) {
		return axios.post(`${BASE_URL}/register/linkedin?code=${code}`, {});
	},
	postRequestOrganizationCreation(email: string) {
		return axios.post(`${BASE_URL}/unregistred/organizations/email`, { email });
	},
	postOrganizationToken(token: string) {
		return axios.post(`${BASE_URL}/unregistred/organizations`, { token });
	},
};

export default APIUnregistered;
