import React from 'react';
import {
	Navigate, Route, Routes,
} from 'react-router-dom';
import { isAuthenticated } from '@services/auth';
import Home from 'src/pages/Home';
import Platform from 'src/pages/Platform';
import Platforms from 'src/pages/Platforms';
import Ideas from 'src/pages/Ideas';
import Drafts from 'src/pages/Drafts';
import Profile from 'src/pages/Profile';
import Team from 'src/pages/Team';
import Teams from 'src/pages/Teams';
import Idea from 'src/pages/Idea';
import Settings from 'src/pages/Settings';

import CheckAccount from 'src/pages/auth/CheckAccount';
import AccountSetup from 'src/pages/AccountSetup';
import Token from 'src/pages/Token';
import RecoverAccount from 'src/pages/RecoverAccount';

import URLS from '@constants/URLS';
import ResetPassword from 'src/pages/ResetPassword';
import Search from 'src/pages/Search';
import Feedback from 'src/pages/Feedback';
import NotFound from 'src/pages/NotFound';
import Favorites from 'src/pages/Favorites';
import Header from '@components/Header';
import { GenericGrid } from '@components/Grids/GenericGrid';

const Protected = ({ component: Component, hideSidebar }: any) => {
	if (isAuthenticated()) {
		if (hideSidebar) {
			return <Component />;
		}
		return (
			<GenericGrid className="s-generic">
				<Header />
				<Component />
			</GenericGrid>
		);
	}
	return <Navigate to="/logIn" replace />; // state route history cache
};

const Routed = () => (
	<Routes>
		<Route path="/" element={<Protected component={Home} />} />
		<Route path={`${URLS.CHALLENGES}`} element={<Protected component={Platforms} />} />
		<Route path={`${URLS.CHALLENGES}/:id`} element={<Protected component={Platform} />} />
		<Route path={`${URLS.BRAINSTORMS}`} element={<Protected component={Platforms} />} />
		<Route path={`${URLS.BRAINSTORMS}/:id`} element={<Protected component={Platform} />} />
		<Route path={`${URLS.IDEAS}`} element={<Protected component={Ideas} />} />
		<Route path={`${URLS.IDEAS}/:id`} element={<Protected component={Idea} />} />
		<Route path={`${URLS.DRAFTS}`} element={<Protected component={Drafts} />} />
		<Route path={`${URLS.PROFILE}/:id`} element={<Protected component={Profile} />} />
		<Route path={`${URLS.SETTINGS}`} element={<Protected component={Settings} />} />
		<Route path={`${URLS.TEAMS}`} element={<Protected component={Teams} />} />
		<Route path={`${URLS.TEAMS}/:id/`} element={<Protected component={Team} />} />
		<Route path={`${URLS.ACCOUNT_SETUP}`} element={<Protected component={AccountSetup} hideSidebar />} />
		<Route path={`${URLS.ORGANIZATION_SETUP}`} element={<Protected component={AccountSetup} hideSidebar />} />
		<Route path={`${URLS.SEARCH}`} element={<Protected component={Search} />} />
		<Route path={`${URLS.FAVORITES}`} element={<Protected component={Favorites} />} />

		<Route path={`${URLS.LOGIN}`} element={<CheckAccount />} />
		<Route path={`${URLS.SIGN_UP}`} element={<CheckAccount />} />
		<Route path={`${URLS.LOGIN}/token/:token`} element={<Token />} />
		<Route path={`${URLS.SIGN_UP}/token`} element={<Token />} />
		<Route path={`${URLS.SIGN_UP}/token/:token`} element={<Token />} />

		<Route path={`${URLS.RECOVER}`} element={<RecoverAccount />} />
		<Route path={`${URLS.RESET}/:token`} element={<ResetPassword />} />
		<Route path={`${URLS.INVITE}/:token`} element={<AccountSetup />} />

		<Route path={`${URLS.DELETE_ORGANIZATION}/:id`} element={<Feedback />} />
		<Route path={`${URLS.DELETE_ACCOUNT}/:id`} element={<Feedback />} />
		<Route path={`${URLS.CREATE_ORGANIZATION}`} element={<Feedback />} />
		<Route path={`${URLS.LINKEDIN_FEEDBACK}`} element={<Feedback />} />
		<Route path={`${URLS.SLACK_FEEDBACK}`} element={<Feedback />} />
		<Route path={`${URLS.MIRO_FEEDBACK}`} element={<Feedback />} />

		<Route path="*" element={<NotFound />} />
	</Routes>
);

export default Routed;
