//@ts-nocheck

import React, { HTMLAttributes } from 'react';

interface IOwnProps extends HTMLAttributes<HTMLDivElement> { }

const SpinnerLDSRipple = ({ className, ...rest }: IOwnProps) => (
	<div className={`lds-ripple-container ${className || ''}`} {...rest}>
		<div className="lds-ripple">
			<div />
			<div />
		</div>
	</div>
);

export default SpinnerLDSRipple;
