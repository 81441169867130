// @ts-nocheck
import React, { useState, useEffect, Suspense } from 'react';
import { useDispatch } from 'react-redux';
import { withTranslation, WithTranslation } from 'react-i18next';
import { buildShowTabQueryString } from '@utils/helpers';
import URLS from '@constants/URLS';
import { AiFillHeart, AiOutlineHeart } from 'react-icons/ai';
import { BsLink } from 'react-icons/bs';
import { IIdea } from 'src/@types/IIdea';
import { UsersGroupCircle } from '@components/Avatar/UsersGroupCircle';
import { goToLink } from '@utils/ReactUtils';
import Icon from '@components/Shared/Icon';
import { FLOWS } from '@constants/GLOBAL';
import { SocketIO, SOCKET_EVENTS } from '@services/socket';
import ModalFeedback, { IFeedbackProps } from '@components/Modals/ModalFeedback';
import { APIIdeas } from '@services/apis';
import { handleAxiosErrors } from '@services/auth';
import { persistor } from 'src/store';
import type { TRoutes } from '@routes/modal/types';
import { Box, IconButton, Tooltip } from '@mui/material';
import { SButton } from '@components/Buttons';
import { PlatformTypeTooltip } from '@components/STooltips/PlatformTypeTooltip';
import { useNavigate } from 'react-router-dom';
import { SToast } from '@modules/SToast';
import { ActivityChip } from '../Shared/ActivityChip';
import { Sticker } from '../Stickers/Sticker';
import { Creators } from '../../store/ducks/actionsTypes';
import { EvaluateIcons } from '../Background/EvaluateIcons';
import { StickerIdea } from '../Stickers/StickerIdea';

interface IOwnProps {
	idea: IIdea
	sectionLocator?: string
	onDelete?: (ideaId: string, mainIdea?: IIdea) => void
	updateIdea?: (idea: IIdea) => void
	onConnect?: (ideaId: string, mainIdea: IIdea) => void
	onDisconnect?: (ideaId: string, mainIdea: IIdea) => void
	match?: IIdea
	clickable?: boolean
	routes: TRoutes
}

declare type TProps = IOwnProps & WithTranslation

const IIdeaCard = (props: TProps) => {
	const {
		idea, t, onDelete, updateIdea,
		sectionLocator, onConnect,
		onDisconnect, match, clickable = true,
		routes,
	} = props;

	const navigate = useNavigate();
	const dispatch = useDispatch();

	const mode = idea?.isArchived
		|| idea?.plataforma?.isFinished
		|| ['aprovada', 'reprovada'].includes(idea.status) ? 'view' : 'edit';

	const isIdeaPage = document.getElementById('idea-page');

	const flow = idea?.plataforma?.type?.toLowerCase();
	const href = `${URLS.IDEAS}/${idea._id}`;
	const _cover = idea.coverThumb ? idea.coverThumb : idea.cover;
	const hasConnectAction = typeof onConnect === 'undefined' && idea.status !== 'rascunho'
		&& ((idea.plataforma?.type === 'Challenge' && idea.plataforma?.usuarioAgencia)
			|| (idea?.plataforma?.type === 'Brainstorm' && idea.plataforma?.usuarioParticipa)
			|| idea.usuarioIsAutor || idea.usuarioCoautora);

	const [feedbackProps, setFeedbackProps] = useState<IFeedbackProps>({ open: false });

	const openModalConnect = (e: React.MouseEvent) => {
		e.stopPropagation();

		dispatch(Creators.IDEAS.updateIdeas({
			...idea, syncIdea: updateIdea,
		}));

		persistor.pause();
		dispatch(Creators.MODAL.showModal(routes.MODAL_IDEA_CONNECT.path));
	};

	const onConnectHandler = (e: React.MouseEvent, action: 'connect' | 'disconnect') => {
		e.stopPropagation();
		setFeedbackProps({
			title: action === 'connect' ? t('idea.connect') : t('global.disconnect'),
			rightButtonText: t(`global.${action}`),
			color: 'success',
			open: true,
			onRightButton: async () => {
				try {
					setFeedbackProps((prevState) => ({ ...prevState, isLoading: true }));
					let response;
					if (action === 'connect') {
						response = await APIIdeas.postConnect(match._id, idea._id);
						onConnect(idea._id, response.data);
					}

					if (action === 'disconnect') {
						response = await APIIdeas.postDisconnect(match._id, idea._id);
						// mainIdea, idea on Page
						onDisconnect(idea._id, response.data);
					}

					setFeedbackProps({ open: false });
					SToast.success(t('messages.success.idea.connected'));
				} catch (err) {
					handleAxiosErrors(err);
					setFeedbackProps((prevState) => ({ ...prevState, isLoading: false }));
					SToast.error(t('messages.error.idea.connect'));
				}
			},
		});
	};

	const onAddPlatformHandler = (e: React.MouseEvent) => {
		e.stopPropagation();
		dispatch(Creators.IDEAS.updateIdeas({
			...idea,
			syncIdea: updateIdea,
		}));
		dispatch(Creators.MODAL.showModal(routes.MODAL_PLATFORMS.path, { close: true, platformType: 'brainstorm' }));
	};

	const onEditHandler = (e: React.MouseEvent) => {
		dispatch(Creators.MODAL.handleModal({ flow: FLOWS.IDEA }));
		if (typeof updateIdea === 'function') {
			dispatch(Creators.IDEAS.updateIdeas({
				workflow: 'UPDATE_IDEA',
				syncIdea: updateIdea,
			}));
		}
		dispatch(
			Creators.IDEAS.getIdea({
				ideaId: idea._id,
				platformId: idea.plataforma?._id,
				redirectPath: routes.MODAL_IDEA.path,
			}),
		);
		e.stopPropagation();
	};

	const onEvaluateHandler = (e: React.MouseEvent) => {
		if (typeof updateIdea === 'function') {
			dispatch(Creators.IDEAS.updateIdeas({
				...idea,
				workflow: 'EVALUATE_IDEA',
				syncIdea: updateIdea,
				filter: sectionLocator,
			}));
		}
		dispatch(Creators.MODAL.showModal(routes.MODAL_APPROVE.path));
		e.stopPropagation();
	};

	const onDeleteHandler = () => {
		setFeedbackProps((prevState) => ({ ...prevState, isLoading: true }));
		dispatch(Creators.IDEAS.deleteIdea({
			ideaId: idea._id,
			onSuccess: () => {
				if (typeof onDelete === 'function') {
					onDelete(idea._id);
				}
			},
			callback: () => setFeedbackProps((prevState) => ({ ...prevState, isLoading: false })),
		}));
	};

	const statusSticker = (function () { // rascunho
		if (idea.plataforma?.usuarioParticipa) {
			return <Sticker label={t('global.joined')} className="primary" />;
		}
		if (idea.plataforma?.possuiPedidoParticipacao) {
			return <Sticker label={t('global.invited')} className="text-charcoal" />;
		}
		return null;
	}());

	const ideaButton = (type: string, className?: string) => {
		const defaultProps = {
			className,
			type: 'button',
			color: 'inherit',
			variant: 'outlined',
			size: 'large',
		};

		switch (type) {
			case 'addPlatform':
				return (
					<div
						className="mr-2 hover-expand"
						aria-hidden
						onClick={onAddPlatformHandler}
					>
						<Icon icon="platform" />
						<span>
							{t('global.add')}
						</span>
					</div>
				);

			case 'modal-connect':
				return (
					<SButton onClick={openModalConnect} {...defaultProps}>
						{t('global.connect')}
					</SButton>
				);
			case 'disconnect':
			case 'connect':
				return (
					<SButton onClick={(e) => onConnectHandler(e, type)} {...defaultProps}>
						{t(`global.${type}`)}
					</SButton>
				);
			case 'evaluate':
				return (
					<SButton onClick={(e) => onEvaluateHandler(e)} {...defaultProps}>
						{t('global.evaluate')}
					</SButton>
				);
			case 'edit':
				return (
					<SButton onClick={onEditHandler} {...defaultProps}>
						{t('global.edit')}
					</SButton>
				);
			case 'sponsor':
				return (
					<SButton
						href={buildShowTabQueryString(href, 'supports-tab', '#plataforma-tabs')}
						{...defaultProps}
					>
						{t('global.support')}
					</SButton>
				);
			default:
				return <div />;
		}
	};

	const mainButton = () => {
		if (idea.status === 'rascunho') {
			return ideaButton('edit');
		}

		if (typeof onConnect === 'function') return ideaButton('connect');
		if (typeof onDisconnect === 'function') return ideaButton('disconnect');
		if (idea.plataforma?.usuarioParticipa) {
			if (idea.plataforma.type === 'Challenge') {
				if (idea.plataforma.usuarioModera) return ideaButton('evaluate');
				if (idea.plataforma.usuarioAgencia) return ideaButton('modal-connect');
				if (idea.plataforma.usuarioPatrocina && !idea.usuarioApoiou) {
					return ideaButton('sponsor');
				}
			}
			if (idea.plataforma?.type === 'Brainstorm') {
				if (idea.usuarioAvaliou) return ideaButton('modal-connect');
				return ideaButton('evaluate');
			}
		}
		return ideaButton('modal-connect');
	};

	const onGoToPlatform = (e: React.MouseEvent<HTMLDivElement>) => {
		e.stopPropagation();

		if (idea.plataforma && clickable) {
			const platformsURL = {
				Challenge: `${URLS.CHALLENGES}/${idea.plataforma?._id}`,
				Brainstorm: `${URLS.BRAINSTORMS}/${idea.plataforma?._id}`,
			};

			navigate(platformsURL[idea?.plataforma?.type]);
		}
	};

	const onLikeHandler = (event: React.MouseEvent) => {
		event?.stopPropagation();
		SocketIO.emit(SOCKET_EVENTS.IDEA_LIKE, { id: idea._id });
	};

	useEffect(() => {
		SocketIO.on(SOCKET_EVENTS.IDEA_LIKE, (_idea: IIdea) => {
			if (typeof updateIdea === 'function' && _idea._id === idea._id) {
				// @ts-ignore
				updateIdea({ _id: _idea._id, likes: _idea.likes });
			}
		});
	}, [SocketIO]);

	const goToIdea = () => {
		if (clickable && isIdeaPage) {
			goToLink(href);
			return;
		}

		if (clickable) {
			navigate(href);
		}
	};

	return (
		<Box className="box-item card-box" sx={{ cursor: clickable ? 'pointer' : 'auto' }}>
			<figure
				className="rollover"
				onClick={goToIdea}
				aria-hidden
				style={{ cursor: clickable ? 'pointer' : 'auto' }}
			>
				<div className="box-photo">
					<img src={_cover} loading="lazy" className="lazy lazy-loaded" alt="" />
				</div>
				<div className="hover-item">
					{Object.hasOwnProperty.call(idea, 'plataforma') && idea.plataforma ? (
						<Box className="top left">

							<PlatformTypeTooltip
								name={idea.plataforma?.nome}
								rulesButton={statusSticker}
								type={idea.plataforma?.type}
							>
								<IconButton onClick={onGoToPlatform}>
									<Icon icon={flow} className="text-white" />
								</IconButton>
							</PlatformTypeTooltip>
						</Box>
					)
						: (
							<div className="top left">
								{((idea.usuarioIsAutor || idea.usuarioCoautora) && clickable)
									&& ideaButton('addPlatform', 'mr-2 hover-expand pl-2 pr-2')}
							</div>
						)}
					<Box className="top right" sx={{ gap: 1 }}>
						<ActivityChip
							icon={idea.likes?.length > 0 ? <AiFillHeart className="heart-red" /> : <AiOutlineHeart />}
							count={idea.likes?.length}
							className="has-action"
							onClick={onLikeHandler}
						/>
						<ActivityChip
							icon={<BsLink />}
							className={`${hasConnectAction ? 'has-action' : ''} ${idea.conexoes?.length > 0 ? 'active' : ''}`}
							count={idea.conexoes?.length}
							onClick={hasConnectAction ? openModalConnect : undefined}
						/>
					</Box>
					<div className="middle">
						{mainButton()}
					</div>
				</div>
			</figure>
			<div className="box-info">
				<div className="content-body">
					<div className="content-infos">
						<Tooltip
							placement="top"
							classes={{ popper: 'secondary' }}
							arrow
							title={idea.nome}
						>
							<a
								id={`card-name-${idea._id}`}
								href={clickable ? href : undefined}
								className={`item-title ${clickable ? 'clickable' : ''}`}
								style={{ cursor: clickable ? 'pointer' : 'auto' }}
							>
								{idea.nome}
							</a>
						</Tooltip>

						<Tooltip classes={{ popper: 'secondary' }} placement="top" arrow title={t('global.publishDate')}>
							<div
								className="publication-date"
							>
								{idea.publishedAt && t('dates.fullDate', { date: new Date(idea.publishedAt) })}
							</div>
						</Tooltip>
					</div>
					<div className="align-items-center d-flex pb-2 pt-2">
						<div className="mt-1 d-flex">
							<span className="align-items-center d-flex mr-2">
								{t('global.by')}
							</span>
							<UsersGroupCircle
								group={idea.coautores}
								id={idea._id}
								clickable={clickable}
								tooltipIsMoreText={t('global.co-authors')}
								prefixTarget={sectionLocator || 'idea-card'}
								hasMore
								hasPopover
								onMore={clickable
									? () => goToLink(buildShowTabQueryString(href, 'coauthors-tab', '#idea-related-tabs'))
									: undefined}
							/>
						</div>

					</div>
				</div>
				<div className="footer">
					<div className="">
						<EvaluateIcons
							ideaId={idea._id}
							averageEvaluations={idea.media_avaliacoes}
							hasPopover
							prefixTarget={sectionLocator}
						/>
					</div>
					{idea.status !== 'rascunho' && (
						<StickerIdea
							id={idea._id}
							hasBadges={idea.premios?.length > 0}
							hasSponsorships={idea.supporters?.length > 0}
							isArchived={idea.isArchived}
							isFinished={idea.isFinished}
							status={idea?.status}
							type={Object.hasOwnProperty.call(idea, 'plataforma') ? idea?.plataforma?.type : 'Brainstorm'}
						/>
					)}

					{idea.status === 'rascunho' && (
						<Box sx={{ display: 'flex', gap: '6px' }}>
							<Tooltip classes={{ popper: 'secondary' }} placement="top" title={t('global.delete')} arrow>
								<IconButton
									onClick={() => setFeedbackProps({
										...feedbackProps,
										title: t('feedback.delete.title-a', { name: `${t('global.idea').toLowerCase()} ${idea.nome}` }),
										rightButtonText: t('global.delete'),
										open: true,
										color: 'error',
										onRightButton: onDeleteHandler,
									})}
									size="small"
									className="i-button"
								>
									<Icon icon="remove" />
								</IconButton>
							</Tooltip>

							<Tooltip classes={{ popper: 'secondary' }} placement="top" title={t('global.edit')} arrow>
								<IconButton
									onClick={onEditHandler}
									className="i-button"
									size="small"
								>
									<Icon icon="edit" />
								</IconButton>
							</Tooltip>
						</Box>
					)}
				</div>
			</div>
			{feedbackProps.open && (
				<ModalFeedback
					id="idea"
					onRequestClose={() => setFeedbackProps({ open: false })}
					{...feedbackProps}
				/>
			)}
		</Box>
	);
};

const Translation = withTranslation('common')(IIdeaCard);

const IdeaCard = (props: IOwnProps) => {
	const { idea } = props;
	return (
		<Suspense fallback={<div />}>
			{typeof idea === 'undefined' ? <div /> : <Translation {...props} />}
		</Suspense>
	);
};

IdeaCard.defaultProps = {
	onDelete: undefined,
	updateIdea: undefined,
};

export { IdeaCard };
