// @ts-nocheck
import React from 'react';
import { SeeMoreCircle } from '@components/Shared/SeeMoreCircle';
import { TSizeCircle } from '@components/DynamicCircle';
import { IUserOrganization } from 'src/@types/IUser';
import { List, ListItem } from '@mui/material';
import { IUserAvatar, UserAvatar } from './UserAvatar';

/** @see UserAvatar */

export interface IUsersGroupCircleProps extends IUserAvatar {
	id?: string
	numberMembers?: number
	isCollapsed?: boolean
	group: IUserOrganization[]
	hasMore?: boolean
	tooltipIsMoreText?: string
	prefixTarget?: string
	clickable?: boolean
	onMore?: () => void
	className?: string
	size?: TSizeCircle
}

export const UsersGroupCircle = (props: IUsersGroupCircleProps) => {
	const {
		id = 'circle',
		numberMembers = 3,
		isCollapsed,
		group = [],
		hasMore,
		tooltipIsMoreText,
		prefixTarget = 'popover',
		clickable,
		size = 'x-small',
		onMore,
		className,
		...rest
	} = props;

	return (
		<List className={`group-circle ${className || ''} `} sx={{ gap: '0.25rem', display: 'flex' }}>
			{group?.map((profile, index) => {
				// @ts-ignore
				const target = `${prefixTarget}-${profile._id || profile.user?._id}-${id}-user`;
				if (isCollapsed) {
					if (group.length > 1) return null;
				}
				if (index > numberMembers - 1) {
					return null;
				}
				return (
					<ListItem
						key={profile._id || profile.user?._id}
						sx={{
							padding: 0, button: { padding: 0 }, borderBottom: 'none !important',
						}}
					>
						<UserAvatar
							{...rest}
							profile={profile}
							hasHref={clickable}
							target={target}
							size={size}
						/>
					</ListItem>
				);
			})}
			{hasMore && (
				<SeeMoreCircle
					id={id || 'more'}
					clickable={clickable}
					numbersToShow={numberMembers}
					isCollapsed={isCollapsed}
					onClick={onMore}
					more={group}
					titleTooltip={tooltipIsMoreText}
					size={size}
				/>
			)}
		</List>
	);
};
