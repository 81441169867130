import * as Yup from 'yup';
import { TObject } from 'src/@types/IShared';

/**
 * Construct a type with a set of properties K of type T
 */
type Record<K extends keyof any, T> = {
	[P in K]: T;
};

declare type Params = Record<string, unknown>;

interface IYupError {
	value: any;
	path?: string;
	type?: string;
	errors: string[];
	params?: Params;
	inner: IYupError[];
}

const getValidationErrors = (err: IYupError) => {
	if (err instanceof Yup.ValidationError) {
		const validationErrors: TObject = {};

		err.inner.forEach((error) => {
			if (error.path) {
				validationErrors[error.path] = error.message;
			}
		});

		return validationErrors;
	}

	return {};
};

const hasError = (obj: Object, field: string): boolean => Object.hasOwnProperty.call(obj, field);

// const translator = (string) => {
//   i18next.t(string)
// }

// setLocale({
//   mixed: {
//     required: () =>translator("messages.requiredField"),
//   },
//   number: {
//     min: 'Deve ser maior que ${min}',
//   },
// });

export { getValidationErrors, hasError };
