// @ts-nocheck
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { WithTranslation, withTranslation } from 'react-i18next';
import {
	useState,
	Suspense,
	FormEvent,
	MouseEvent,
	KeyboardEvent,
} from 'react';

import { Creators } from '@actions';
import { SButton } from '@components/Buttons';
import {
	goToLink, isEmpty,
} from '@utils/ReactUtils';
import {
	APIUnregistered, APIUser,
} from '@services/apis';
import {
	handleAxiosErrors, auth,
} from '@services/auth';
import { SPasswordField, STextField } from '@forms/Components';
import SpinnerLDSRipple from '@components/Shared/SpinnerLDSRipple';
import ImageInput, { IParamsImage } from '@components/Inputs/ImageInput';

import { Form, SFormGroup, SInputLabel } from '@components/DynamicForm/ComponentsCore';

import URLS from '@constants/URLS';
import { IRootState } from 'src/store';
import { TObject } from 'src/@types/IShared';
import { getValidationErrors } from '@utils/yup';

import { maxLength } from '@constants/GLOBAL';
import { passwordRegex, passwordValidation } from '@forms/Components/SPasswordField';
import routes from '@routes/modal';
import { Box, Grid, Typography } from '@mui/material';

interface IUserState {
	password: string
	name: string
	image: ''
	file?: any
}

interface IOwnProps {
	token?: string
	show: boolean
}

declare type TProps = IOwnProps & WithTranslation

const _UserSetup = (props: TProps) => {
	const { t, token, show } = props;
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [inProgress, setInProgress] = useState<string | undefined>(undefined);
	const [errors, setErrors] = useState<TObject>({});
	const { userOrganizationProfile } = useSelector((state: IRootState) => state);
	const isAdministrator = userOrganizationProfile?.user?.isAdministrator;
	const [user, setUser] = useState<IUserState>({
		password: '',
		name: userOrganizationProfile?.user?.nome || userOrganizationProfile?.user?._nome || '',
		image: userOrganizationProfile?.user?.image,
		file: undefined,
	});

	const hasIntegration = userOrganizationProfile?.user?.OAuth?.active;

	const onErrorHandler = (field) => {
		if (!isEmpty(errors)) delete errors[field];
	};

	const onUserHandler = (_user) => {
		Object.keys(user).forEach((key) => onErrorHandler(key));

		setUser({ ...user, ..._user });
	};

	const onGuestSubmit = async (event: MouseEvent<HTMLButtonElement>) => {
		try {
			setInProgress('submit');
			event?.preventDefault();
			if (typeof token === 'undefined') return;

			const formData = new FormData();
			formData.append('password', user.password);
			formData.append('name', user.name);

			if (user.file) {
				formData.append('image', user.file);
			}

			const schema = Yup.object().shape({
				name: Yup.string().required(t('messages.requiredField')),
				password: Yup.string()
					.required(t('messages.requiredField'))
					.matches(passwordRegex, t('messages.password.validation', { min: 6 })),
			});

			await schema.validate(user, {
				abortEarly: false,
			});

			const response = await APIUnregistered.postAcceptInvite(token, formData);

			auth.setToken(response.data.token);
			dispatch(Creators.SHARED.updateShared({ step: 'trails' }));
			dispatch(Creators.MODAL.showModal(routes.MODAL_TRAILS.path));
			navigate(URLS.HOME);
		} catch (err) {
			if (err instanceof Yup.ValidationError) {
				setErrors(getValidationErrors(err));
			} else handleAxiosErrors(err);
		} finally {
			setInProgress(undefined);
		}
	};

	const onSubmitUser = async (event: FormEvent<HTMLButtonElement>) => {
		event.preventDefault();

		const { name, password } = user;
		const state = hasIntegration ? { name } : { name, password };

		try {
			setInProgress('submit');

			let schema;

			if (hasIntegration) {
				schema = Yup.object().shape({
					name: Yup.string().required(t('messages.requiredField')),
				});
			} else {
				if (passwordValidation(password)?.level !== 'strong') {
					setErrors((prevState) => ({
						...prevState,
						password: t('messages.password.level.error'),
					}));
					return;
				}

				schema = Yup.object().shape({
					name: Yup.string().required(t('messages.requiredField')),
					password: Yup.string()
						.required(t('messages.requiredField'))
						.matches(passwordRegex, t('messages.password.validation', { min: 8 })),
				});
			}

			await schema.validate(state, {
				abortEarly: false,
			});

			await APIUser.putProfile(state);
			navigate(`${URLS.ACCOUNT_SETUP}?step=organization`);
		} catch (err) {
			if (err instanceof Yup.ValidationError) {
				setErrors(getValidationErrors(err));
				return;
			}
			handleAxiosErrors(err);
		} finally {
			setInProgress(undefined);
		}
	};

	const onImageHandler = ({ formData, url, file }: IParamsImage) => {
		if (typeof token !== 'undefined') {
			setUser({ ...user, file, image: url });
			return;
		}

		setInProgress('image-user-account-setup');
		dispatch(Creators.USER_ORGANIZATION.handleUserImage({
			formData,
			callback: () => setInProgress(undefined),
			onSuccess: (image) => setUser({ ...user, ...image }),
		}));
	};

	const onKeyUpHandler = (e: KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'Enter' && userOrganizationProfile?.user?.nome !== '') {
			(document.querySelector('#password-setup') as HTMLInputElement)?.focus();
		}
	};

	if (!show) {
		return null;
	}

	return (
		<Form onSubmit={token ? onGuestSubmit : onSubmitUser} className="s-form" autoComplete="off">
			<Grid container textAlign="center" spacing={2}>
				<Grid item xs={12}>
					<Typography variant="h1">
						{t('register.welcomeTo')}
						{' '}
						<b>SENNO!</b>
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<Typography variant="body1">
						{t('register.tellABitYourself')}
					</Typography>
				</Grid>
			</Grid>
			<Box marginY={3}>
				<ImageInput
					id="image-user-account-setup"
					image={user?.image}
					initial={user.name}
					onClick={onImageHandler}
					className="justify-content-center"
					isLoading={inProgress === 'image-user-account-setup'}
				/>
			</Box>
			<SFormGroup>
				<SInputLabel htmlFor="user-setup-name" error={typeof errors.name === 'string'}>
					{t('global.name')}
				</SInputLabel>
				<STextField
					type="text"
					autoComplete="off"
					name="name"
					maxLength={maxLength.userName}
					id="user-setup-name"
					placeholder={t('form.placeholder.user.name')}
					value={user.name}
					pattern="^[a-zA-ZÀ-ÖØ-öø-ÿ ]+$"
					onChange={(e) => onUserHandler({ name: e.target.value })}
					error={typeof errors.name === 'string'}
					helperText={errors.name}
					onKeyUp={onKeyUpHandler}
				/>
			</SFormGroup>
			{!hasIntegration && (
				<SFormGroup>
					<SInputLabel htmlFor="password-setup" error={typeof errors.password === 'string'}>
						{t('form.password')}
					</SInputLabel>
					<SPasswordField
						id="password-setup"
						name="password"
						placeholder={t('form.placeholder.password')}
						value={user.password}
						onChange={(e) => onUserHandler({ password: e.target.value })}
						error={typeof errors.password === 'string'}
						helperText={errors?.password || passwordValidation(user.password)?.message}
					/>
				</SFormGroup>
			)}
			<SButton
				type="submit"
				variant="outlined"
				color="primary"
				className="w-100"
				onClick={typeof token === 'string' ? onGuestSubmit : onSubmitUser}
				isLoading={inProgress === 'submit'}
				disabled={hasIntegration
					? user.name.length === 0
					: (user.password.length === 0 || user.name.length === 0)}
				error={errors.nome || errors.password}
			>
				{isAdministrator ? t('global.continue') : `${t('global.goTo')} SENNO`}
			</SButton>
		</Form>
	);
};

const Translation = withTranslation('common')(_UserSetup);

const UserSetup = (props: IOwnProps) => (
	<Suspense fallback={<SpinnerLDSRipple />}>
		<Translation {...props} />
	</Suspense>
);

export { UserSetup };
