import React, { Suspense, useState, ChangeEvent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import SpinnerLDSRipple from '@components/Shared/SpinnerLDSRipple';
import { APIOrganizations } from '@services/apis';
import {
	SFormGroup, SInputLabel,
} from '@components/DynamicForm/ComponentsCore';
import { SButton } from '@components/Buttons';
import { STextField } from '@forms/Components';
import { SaveLoader } from '@components/Loaders/SaveLoader';
import { useSelector } from 'react-redux';
import { IRootState } from 'src/store';
import { handleAxiosErrors } from '@services/auth';
import { ComputerUploadButton } from '@components/Buttons/ComputerUploadButton';
import Icon from '@components/Shared/Icon';
import { SBox } from '@components/Background/SBox';
import { SToast } from '@modules/SToast';

interface IItemsState {
	pageContent?: string
	mainColor?: string
	imageOrVideo?: string
	footer?: string
	previewLink?: string
	facebook?: string
	linkedin?: string
	instagram?: string
	twitter?: string
}

interface IOwnProps { }

type TProps = IOwnProps & WithTranslation;

const OLandingPage = ({ t }: TProps) => {
	const defaultTranslateObject = 'settings.landingPage';
	const { userOrganizationProfile } = useSelector((state: IRootState) => state);

	const [inProgress, setInProgress] = useState(false);
	const [inProgressActive, setInProgressActive] = useState(false);
	const [items, setItems] = useState<IItemsState>({});
	const [active, setActive] = useState(userOrganizationProfile?.organization?.landingPageActive);

	// if (landingPage && landingPage.css && !items) {
	//   setItems(landingPage);
	// }

	const onChangeHandler = (type: string, value: string) => {
		setItems({
			...items,
			[type]: value,
		});
	};

	const onSubmit = async () => {
		setInProgress(true);
		try {
			const response = await APIOrganizations.patchLandingPage(items);
			SToast.success(t('messages.success.saved'));
		} catch (err) {
			handleAxiosErrors(err);
		} finally {
			setInProgress(false);
		}
	};

	const handleActive = async () => {
		setInProgressActive(true);
		try {
			const response = await APIOrganizations.postActiveLandingPage();

			setTimeout(() => {
				setInProgressActive(false);
				setActive(response.data.landingPageActive);
			}, 1000);
		} catch (err: any) {
			handleAxiosErrors(err);
		} finally {
			setInProgressActive(false);
		}
	};

	const socialMediaInputs = [
		{ name: 'facebook', icon: <Icon icon="facebook" className="social-icon" />, placeholder: 'Add your Facebook page link here...' },
		{ name: 'linkedin', icon: <Icon icon="linkedin" className="social-icon" />, placeholder: 'Add your LinkedIn page link here...' },
		{ name: 'twitter', icon: <Icon icon="twitter" className="social-icon" />, placeholder: 'Add your Twitter page link here...' },
		{ name: 'instagram', icon: <Icon icon="instagram" className="social-icon" />, placeholder: 'Add your Instagram page link here...' },
	];

	// const loadLandingPage = async () => {
	//   try {
	//     const response = await APIOrganizations.getLandingPage(currentOrganization._id);

	//   } catch (err: any) {
	//     console.log('error method getLandingPage', err);
	//   }

	// }

	return (
		<SBox className="organization-page" sx={{ marginY: 6 }}>
			<div className="landingPage-page">
				<div className="landingPage-page">
					<div className="d-flex" style={{ alignItems: 'center' }}>
						<div className="d-flex mt-20px active-lp-label">
							<span className="lp-title">{t(`${defaultTranslateObject}.customize`)}</span>
						</div>
						{/* <Switch
							className="switch-active"
							onChange={handleActive}
							// @ts-ignore
							value={active}
						>
							Ativar
						</Switch> */}
						{inProgressActive && <SaveLoader className="ml-30px" />}
					</div>

					{(active && items.previewLink)
						&& (
							<a href={items.previewLink} target="_blank" rel="noreferrer">
								{items.previewLink}
							</a>
						)}
				</div>
				<div className="d-flex mt-20px">
					<span className="lp-title">
						{t(`${defaultTranslateObject}.pageContent`)}
					</span>
				</div>
				{[
					{
						label: t(`${defaultTranslateObject}.pageContent`),
						className: 'form-settings-group',
						labelClassName: 'color-label',
						element:
							(<input
								type="color"
								onChange={(e) => onChangeHandler('pageContent', e.target.value)}
								value={items.pageContent}
							/>),
					},
					{
						label: t(`${defaultTranslateObject}.mainColor`),
						className: 'form-settings-group',
						labelClassName: 'color-label',
						element:
							(<input
								type="color"
								onChange={(e) => onChangeHandler('mainColor', e.target.value)}
								value={items.mainColor}
							/>),
					},
					{
						label: t(`${defaultTranslateObject}.uploadLogo`),
						className: 'form-settings-group mt-15p',
						style: { display: 'flex' },
						labelClassName: 'upload-label',
						element: (
							<div className="upload-button">
								<ComputerUploadButton
									onClick={(file) => console.log(file)}
								/>
							</div>
						),
					},
					{
						label: t(`${defaultTranslateObject}.addImageOrVideo`),
						className: 'form-settings-group mt-15p',
						labelClassName: 'add-video-image-label',
						element: (
							<div className="video-image-fields">
								<STextField
									type="text"
									className="video-link"
									value={items.imageOrVideo}
									placeholder="https://www.youtube.com/watch?v=dQw4w9WgXcQ"
									onChange={(e: ChangeEvent<HTMLInputElement>) => onChangeHandler('imageOrVideo', e.target.value)}
								/>
								<span className="or-label">or</span>
								<div className="upload-image-video-button">
									<ComputerUploadButton onClick={(file) => console.log(file)} />
								</div>
							</div>),
					},
					{
						label: t(`${defaultTranslateObject}.socialMedia`),
						className: 'form-settings-group mt-15p',
						labelClassName: 'social-media-label',
						element: (
							<div className="social-media">
								{
									socialMediaInputs.map((socialInput, i) => (
										<div className="social-box" key={i}>
											{socialInput.icon}
											<STextField
												type="text"
												className="social-miedia-inputs"
												// @ts-ignore
												value={items[socialInput.name]}
												placeholder={socialInput.placeholder}
												onChange={(e: ChangeEvent<HTMLInputElement>) => onChangeHandler(socialInput.name, e.target.value)}
											/>
										</div>
									))
								}
							</div>),
					},
					{
						label: t(`${defaultTranslateObject}.footerText`),
						className: '',
						labelClassName: 'upload-label',
						element: (
							<STextField
								multiline
								rows={4}
								className="footer-textArea"
								style={{ backgroundColor: '#ffff', border: '1px solid #707070', color: '#707070' }}
								value={items.footer}
								disabled={false}
								onChange={(e: ChangeEvent<HTMLInputElement>) => onChangeHandler('footer', e.target.value)}
							/>),
					},
				].map((item, i) => (
					<li key={i}>
						<div className={item.className}>
							<SFormGroup style={item.style}>
								<SInputLabel className={item.labelClassName}>{item.label}</SInputLabel>
								{Array.isArray(item.element)
									? item.element.map((el, j) => (
										<div key={j}>
											{el}
										</div>
									))
									: item.element}
							</SFormGroup>
						</div>
					</li>
				))}
				<div className="d-flex mt-30px">
					<SButton
						type="button"
						color="primary"
						variant="outlined"
						className="ml-auto "
						// style={{ marginLeft: '5% !important' }}
						disabled={!active}
						isLoading={inProgress}
						href={items.previewLink}
						// target="_blank"
						// rel="noreferrer"
					>
						{t('global.preview')}
					</SButton>
					<SButton
						type="submit"
						color="primary"
						variant="outlined"
						className="save-lp-button"
						// disabled={!fields.landingPageActive}
						onClick={onSubmit}
						isLoading={inProgress}
					>
						{t('global.save')}
					</SButton>
				</div>
			</div>
		</SBox>
	);
};

const Translation = withTranslation('common')(OLandingPage);

const LandingPage = (props: IOwnProps) => (
	<Suspense fallback={<SpinnerLDSRipple />}>
		<Translation {...props} />
	</Suspense>
);

export default LandingPage;
