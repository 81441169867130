// @ts-nocheck
import { axiosInstance } from '@services/AxiosInstance';

const APICanvas = {
	getCanvasByPlatform(platformId: string) {
		return axiosInstance.get(`/canvas/${platformId}/platform`);
	},
	getCanvasByIdea(ideaId: string) {
		return axiosInstance.get(`/canvas/${ideaId}/idea`);
	},
	patchCanvas(canvasId: string, name: string, description: string) {
		return axiosInstance.patch(`/canvas/${canvasId}`, { name, description });
	},
	deleteCanvas(canvasId: string) {
		return axiosInstance.delete(`/canvas/${canvasId}`);
	},
	postCanvasPlatform(platformId: string, templateId: string) {
		return axiosInstance.post('/canvas/', { platformId, templateId });
	},
	postCanvasIdea(ideaId: string, templateId: string) {
		return axiosInstance.post('/canvas/', { ideaId, templateId });
	},
	getTemplates(ids: Array<string>) {
		const queryParams = typeof ids !== 'undefined' && ids.length > 0 ? `?ids=${ids}` : '';
		return axiosInstance.get(`/templates/canvas${queryParams}`);
	},
	putRestore(canvasId: string) {
		return axiosInstance.get(`/canvas/restore_template/${canvasId}`);
	},
};

export default APICanvas;
