import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TPlatform } from 'src/@types/IPlatform';
import { DynamicCircle } from '@components/DynamicCircle';
import {
	Divider, Grid, Tooltip, TooltipProps, Typography,
} from '@mui/material';
import Icon from '../Shared/Icon';

interface PlatformTypeTooltipProps extends TooltipProps {
	rulesButton: React.ReactNode
	type: TPlatform
	name: string
}

export const PlatformTypeTooltip = (props: PlatformTypeTooltipProps) => {
	const {
		name, rulesButton, type, ...rest
	} = props;

	const { t } = useTranslation();
	const flow = type?.toLowerCase();

	return (
		<Tooltip
			arrow
			{...rest}
			classes={{ popper: 'primary', tooltip: 'box-shadow small' }}
			title={(
				<Grid
					container
					padding={1}
					spacing={2}
					sx={{
						alignItems: 'center',
						'.MuiGrid-item': {
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center',
							alignItems: 'center',
						},
					}}
				>
					<Grid item xs={12}>
						<DynamicCircle
							size="x-large"
							initial={name}
							background="rgb(4, 72, 109)"
						/>
					</Grid>
					<Grid item className="word-ellipsis" xs={12}>
						<Typography>{name}</Typography>
					</Grid>
					<Grid item xs={12}>
						<Divider />
					</Grid>
					<Grid item xs={12}>
						<Typography>
							<Icon icon={flow} />
							<span className="pl-1">{t(`global.${flow}`)}</span>
						</Typography>
					</Grid>
					{rulesButton && (
						<Grid item xs={12}>
							{rulesButton}
						</Grid>
					)}
				</Grid>
			)}
		/>
	);
};
