import React, { useState } from 'react';
import { useTour } from '@reactour/tour';
import { useDispatch } from 'react-redux';
import { withTranslation, WithTranslation } from 'react-i18next';

import { Creators } from '@actions';
import { BuildTour } from '@components/STour';
import { IPagination, TFlow } from 'src/@types/IShared';
import { ICanvas, IMiroBoard } from 'src/@types/ICanvas';
import RedactorText from '@components/Shared/RedactorText';
import { DynamicCircle, DynamicCircleProps, TSizeCircle } from '@components/DynamicCircle';
import { List, ListItem, Typography } from '@mui/material';
import { SBox } from './SBox';

export declare type TCanvasActions = 'edit' | 'view' | 'add' | 'insert'

interface IOwnProps {
	id: string
	rootPath?: string
	className?: string
	size?: TSizeCircle
	CreateProps?: DynamicCircleProps
	isDisabled?: boolean
	readonly flow: TFlow
	hasEmptyState?: string
	readonly template?: string
	readonly mode: TCanvasActions
	readonly canvas: IPagination<ICanvas>
	orientation?: 'vertical' | 'horizontal'
	routes: { models?: string, model: string, nav?: string }
	onInsert?: (modelId: string, callback: Function) => void
	components?: {title: React.ReactNode}
}

declare type TProps = IOwnProps & WithTranslation;

const _BCanvas = (props: TProps) => {
	const {
		t, mode, canvas, flow, orientation = 'horizontal', id, rootPath,
		routes, template, className, hasEmptyState, CreateProps, onInsert, components,
		size, isDisabled = false,
	} = props;
	const tour = useTour();
	const dispatch = useDispatch();
	const [inProgress, setInProgress] = useState(['', '']);

	const onViewHandler = (model: ICanvas | IMiroBoard, action: TCanvasActions) => {
		BuildTour.close(tour);
		dispatch(Creators.MODAL.handleModal({ flow }));
		if (action === 'add' && typeof onInsert === 'function') {
			const canvasId = (model as ICanvas)._id || (model as IMiroBoard).id;
			setInProgress(['add', canvasId]);
			onInsert(canvasId, () => setInProgress(['', '']));
			return;
		}

		if (routes.nav) {
			dispatch(Creators.MODAL.showModal(routes.nav, {
				action,
				currentModel: model,
				rootPath,
				pagingPaths: canvas.docs.map((item) => ({
					pagePath: routes.nav,
					pageModel: item,
				})),
			}));
			return;
		}

		dispatch(Creators.CANVAS.successCanvas(model));
		dispatch(Creators.MODAL.showModal(routes.model, {
			template, action,
		}));
	};

	return (
		<SBox
			id={`${id || ''}-list`}
			className={`background__canvas ${className || ''}`}
			empty={canvas.docs?.length === 0 && hasEmptyState ? 'canvas' : undefined}
			loading={canvas.isLoading || !canvas || !flow}
		>
			{components?.title}
			<List className={orientation === 'vertical' ? 'list' : 'horizontal-list'}>
				{CreateProps && typeof routes.models === 'string' && (
					<ListItem className="new-canvas list-item" key="new-canvas">
						<DynamicCircle
							icon="plus"
							size="middle"
							className="default-colors"
							clickable={!isDisabled}
							disabled={isDisabled}
							onClick={() => {
								BuildTour.close(tour);
								dispatch(Creators.MODAL.showModal(routes.models));
							}}
						/>
						<Typography variant="caption" className="item-description">
							{CreateProps.title}
						</Typography>
					</ListItem>
				)}
				{canvas.docs?.map((model: ICanvas | IMiroBoard, index) => {
					// @ts-ignore
					if (!model || (!model?.embedHtml && !(model as ICanvas).color)) return null;
					const action: TCanvasActions = (function () {
						if (mode === 'view') return 'view';
						if (mode === 'add') return 'add';
						return 'edit';
					}());
					return (
						<ListItem
							key={(model as ICanvas)._id || (model as IMiroBoard).id}
							data-tut={`template-${index}-${orientation}`}
							className={`list-item ${(['add'].includes(inProgress[0])
								&& [(model as ICanvas)._id || (model as IMiroBoard).id].includes(inProgress[1])) ? 'loading' : ''}`}
						>
							<DynamicCircle
								color={(model as IMiroBoard).embedHtml ? undefined : (model as ICanvas).color}
								background={(model as IMiroBoard).embedHtml
									? undefined : (model as ICanvas).background}
								className={(model as IMiroBoard).embedHtml ? 'miro' : ''}
								icon="canvas"
								size={size || 'middle'}
								action={t(`global.${action}`)}
								onClick={() => onViewHandler(model, action)}
							/>
							{orientation === 'vertical' && (
								<div
									aria-hidden
									className="container-content"
									onClick={mode === 'add'
										? () => onViewHandler(model, 'insert')
										: undefined}
									style={{ cursor: mode === 'add' ? 'pointer' : 'auto' }}
								>
									{/* @ts-ignore */}
									<div className="mb-2 bold">{model.title || model.name}</div>
									{/* @ts-ignore */}
									<RedactorText html={model.description} hasToggle className="content-text" />
								</div>
							)}
							{orientation === 'horizontal' && (
								<Typography variant="caption" className="item-description">
									{/* @ts-ignore */}
									{model.title || model.name}
								</Typography>
							)}
						</ListItem>
					);
				})}
			</List>
		</SBox>
	);
};

const BCanvas = withTranslation('common')(_BCanvas);

export { BCanvas };
