import React, { HTMLAttributes, DetailedHTMLProps } from 'react';
import { IconsMap } from '@utils/IconsMap';

interface IOwnProps extends DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement> {
	icon: typeof IconsMap | string
}

const Icon = (props: IOwnProps) => {
	const { icon, className, ...rest } = props;
	// @ts-ignore
	const _icon = IconsMap[icon] || icon;
	// @ts-ignore

	if (!_icon) return null;

	if (typeof _icon === 'string') {
		return <i className={`${_icon} ${className || ''}`} {...rest} />;
	}

	return _icon;
};

export default Icon;
