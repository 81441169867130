import React from 'react';
import { IMaskInput } from 'react-imask';
import {
	InputBaseComponentProps,
	TextField, TextFieldProps, InputLabel, Box, InputAdornment,
} from '@mui/material';
import { SInputStatus } from './SInputStatus';

interface ITextMaskProps {
  // onChange: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const TextMask = React.forwardRef<HTMLElement, ITextMaskProps>(
	(props, ref) => {
		const { ...other } = props;
		return (
			<IMaskInput
				{...other}
				// @ts-ignore
				inputRef={ref}
				overwrite
			/>
		);
	},
);

interface ISMaskField extends InputBaseComponentProps {
  isLoading?: boolean
  isAsync?: boolean
}

export const SMaskField = (props: ISMaskField & TextFieldProps) => {
	const {
		id, label, className, InputProps, inputProps, isLoading, isAsync, error, ...rest
	} = props;
	return (
		<Box className={`s-input ${className || ''}`}>
			<InputLabel htmlFor={id} error={error} className="s-label">{label}</InputLabel>
			<TextField
				id={id}
				variant="outlined"
				error={error}
				InputProps={{
					inputProps,
					...InputProps,
					inputComponent: TextMask as any,
					endAdornment: (
						<InputAdornment position="end">
							<SInputStatus isLoading={isLoading} isAsync={isAsync} />
						</InputAdornment>
					),
				}}
				sx={{
					'.MuiFormHelperText-root.MuiFormHelperText-sizeMedium': {
						margin: 0,
					},
				}}
				{...rest}
			/>
		</Box>
	);
};
