// @ts-nocheck
import {
	put,
	call,
	takeLatest,
	select,
	delay,
} from 'redux-saga/effects';
import i18next from 'i18next';
import * as Yup from 'yup';
import APIFeatures from '@services/apis/features';
import { handleAxiosErrors } from '@services/auth';
import { IRootState } from 'src/store';
import { getValidationErrors } from '@utils/yup';
import { AxiosResponse } from 'axios';
import { SToast } from '@modules/SToast';
import { Creators, Types } from '../actionsTypes';

function* insertFeature(action) {
	const {
		templateId, ideaId, onSuccess, callback,
	} = action.params;
	try {
		const response: AxiosResponse = yield call(APIFeatures.postFeatureIdea, {
			templateId,
			ideaId,
		});

		if (typeof onSuccess === 'function') {
			yield call(onSuccess, response.data);
		}
		yield call(SToast.success, i18next.t('messages.success.feature.added'));
	} catch (err) {
		yield call(handleAxiosErrors, err);
	} finally {
		if (typeof callback === 'function') {
			yield call(callback);
		}
	}
}

function* getFeatures(action) {
	const { ideaId, onSuccess } = action.params;
	try {
		const response: AxiosResponse = yield call(APIFeatures.getFeatures, ideaId);

		yield put(Creators.IDEAS.updateIdeas({ funding: response.data }));

		if (typeof onSuccess === 'function') {
			yield call(onSuccess, { docs: response.data });
		}
		if (action.params.redirectPath) {
			yield put(Creators.MODAL.showModal(action.params.redirectPath));
		}
	} catch (err) {
		yield call(handleAxiosErrors, err);
	}
}

function* deleteFeature(action) {
	const { featureId, onSuccess, callback } = action.params;
	try {
		yield call(APIFeatures.deleteFeature, featureId);
		if (typeof onSuccess === 'function') {
			yield call(onSuccess);
		}
		yield put(Creators.MODAL.backModal());
		yield call(SToast.success, i18next.t('messages.success.feature.removed'));
	} catch (err) {
		yield call(handleAxiosErrors, err);
	} finally {
		if (typeof callback === 'function') {
			yield call(callback);
		}
	}
}

function* handleFeature(action) {
	const {
		featureId, key, value, onError, callback,
	} = action.params;
	try {
		const field = { [key]: value };

		const { modal } = yield select((state: IRootState) => state);

		if (!featureId || modal.ModalProps.action !== 'edit') return;

		let schema = null;

		switch (key) {
			case 'nome':
			case 'resumo':
			case 'budget':
				schema = Yup.object().shape({
					[key]: Yup.string().required(i18next.t('messages.requiredField')),
				});

				yield schema.validate(field, {
					abortEarly: false,
				});
				break;

			default:
				break;
		}

		yield delay(1000);

		yield call(APIFeatures.patchFeature, featureId, field);
	} catch (err) {
		if (err instanceof Yup.ValidationError) {
			const errorMessages = yield call(getValidationErrors, err);
			if (typeof onError === 'function') {
				yield call(onError, errorMessages);
			}
		} else yield call(handleAxiosErrors, err);
	} finally {
		if (typeof callback === 'function') {
			yield call(callback);
		}
	}
}

function* restoreFeature(action) {
	try {
		const response: AxiosResponse	= yield call(
			APIFeatures.putResetFeature,
			action.params.featureId,
		);
		yield put(Creators.FEATURES.setFeature(response.data));
		yield call(SToast.success, i18next.t('messages.success.template.reseted'));
		if (typeof action.params.onSuccess === 'function') {
			yield call(action.params.onSuccess);
		}
	} catch (err) {
		yield call(handleAxiosErrors, err);
	} finally {
		if (typeof action.params.callback === 'function') {
			yield call(action.params.callback);
		}
	}
}

function* getSupporters(action) {
	try {
		const { featureId, onSuccess } = action.params;

		const response: AxiosResponse = yield call(APIFeatures.getSupporters, featureId);

		if (typeof onSuccess === 'function') {
			yield call(onSuccess, response.data);
		}
	} catch (err) {
		handleAxiosErrors(err);
	}
}

export default function* () {
	yield takeLatest(Types.FEATURES.GET_FEATURES, getFeatures);
	yield takeLatest(Types.FEATURES.RESTORE_FEATURE, restoreFeature);
	yield takeLatest(Types.FEATURES.INSERT_FEATURE, insertFeature);
	yield takeLatest(Types.FEATURES.DELETE_FEATURE, deleteFeature);
	yield takeLatest(Types.FEATURES.HANDLE_FEATURE, handleFeature);
	yield takeLatest(Types.FEATURES.GET_SUPPORTERS, getSupporters);
}
