// @ts-nocheck
import { useTranslation, withTranslation, WithTranslation } from 'react-i18next';
import { IPaginatedRequest, IPagination } from 'src/@types/IShared';
import { Link } from 'react-router-dom';
import React from 'react';
import { SButton } from '@components/Buttons';
import { EmptyState } from '@components/Shared/EmptyState';
import { IIdea } from 'src/@types/IIdea';
import { IPlatform } from 'src/@types/IPlatform';
import { IUser } from 'src/@types/IUser';
import { ITeam } from 'src/@types/ITeam';
import { Box, BoxProps, Grid, GridProps } from '@mui/material';
import CardLoader from '../Loaders/CardLoader';
import { IListRequest } from '../Lists/types';

export type TCards = 'platforms' | 'ideas' | 'connections' | 'teams'
	| 'users' | 'brainstorms' | 'challenges';

export type TCardsElements = IIdea | IPlatform | ITeam | IUser

interface CardGridProps extends BoxProps {
	id: string
	href?: string
	className?: string
	type: TCards
	headerLabel?: string
	headerSubLabel?: string
	section?: IPagination<TCardsElements>
	loadMore?: (params: IListRequest) => void
	onViewMore?: (params: IPaginatedRequest) => void
	component?: (item) => React.ReactChild // 1 child
	hide?: boolean
	queryParams?: IPaginatedRequest
	itemsList?: boolean
	GridItemProps?: GridProps
}

type TProps = CardGridProps & WithTranslation

const CCardGrid = (props: TProps) => {
	const { t } = useTranslation();
	const {
		href,
		type,
		headerLabel,
		headerSubLabel = '',
		section = {},
		loadMore,
		onViewMore,
		component, id,
		GridItemProps,
		hide, queryParams, ...rest
	} = props;

	const [isLoadingMore, setIsLoadingMore] = React.useState(false);

	const loadMoreHandler = () => {
		setIsLoadingMore(true);
		loadMore({
			...queryParams,
			page: section.nextPage,
			callback: () => setIsLoadingMore(false),
		});
	};

	function sectionContent() {
		if (!section || (section.isLoading && !isLoadingMore) || !section.docs) {
			return (
				<Box className="s-card-grid" sx={{ width: '100%' }}>
					<CardLoader
						GridItemProps={GridItemProps}
						limit={queryParams?.limit || section.limit}
					/>
				</Box>
			);
		}
		if (section.docs?.length < 1) {
			return (
				<Box className="s-card-grid" sx={{ width: '100%' }}>
					<EmptyState type={type} />
				</Box>
			);
		}
		return (
			<Box className="s-card-grid" sx={{ width: '100%' }}>
				<Grid container spacing={2} sx={{ flexWrap: 'wrap' }}>
					{section.docs?.map((item, index) => (
						<Grid item xs={12} className={`item-${index}`} key={item._id} {...GridItemProps}>
							{component(item)}
						</Grid>
					))}
				</Grid>
				{typeof onViewMore === 'undefined' && typeof loadMore === 'function' && (
					<Grid container className="footer__list" marginY={6}>
						<Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
							{section.hasNextPage ? (
								<SButton
									type="button"
									variant="outlined"
									isLoading={isLoadingMore}
									onClick={loadMoreHandler}
								>
									{t('global.loadMore')}
								</SButton>
							) : <span>{t(`messages.loadedAll.${type}`)}</span>}
						</Grid>
					</Grid>
				)}
			</Box>
		);
	}

	if (hide) {
		return null;
	}

	return (
		<Box id={`${id}-section`} {...rest }>
			{(headerLabel || (typeof onViewMore === 'function' && section.hasNextPage)) && (
				<Grid container className="box-heading">
					<Grid item className="infos-left">
						<span className="title">{headerLabel}</span>
						<span className="sub-title">{headerSubLabel}</span>
					</Grid>
					<Grid item>
						{(typeof href === 'string') && (
							<Link
								id="filter-section"
								to={href}
								className="link see-more"
							>
								{t('global.viewMore')}
							</Link>
						)}
						{(typeof onViewMore === 'function' && section.hasNextPage)
								&& (
									<SButton
										type="button"
										className="link see-more"
										isLoading={section.page !== section.nextPage - 1}
										onClick={() => onViewMore({ ...queryParams, page: 1 })}
									>
										{t('global.viewMore')}
									</SButton>
								)}
					</Grid>
				</Grid>
			)}
			{sectionContent()}
		</Box>
	);
};

const CardGrid = (props: CardGridProps) => (
	<CCardGrid {...props} />
);

export default CardGrid;
