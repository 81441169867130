// @ts-nocheck
import React, { Suspense, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withTranslation, WithTranslation } from 'react-i18next';
import SpinnerLDSRipple from '@components/Shared/SpinnerLDSRipple';
import routes from '@routes/modal';
import { IRootState, persistor } from 'src/store';
import { IPlan, IPrice } from 'src/@types/IBilling';
import { SButton } from '@components/Buttons';
import { Creators } from '../../store/ducks/actionsTypes';

interface IOwnsProps {
	plan: IPlan
	interval: 'year' | 'month'
}

declare type TProps = IOwnsProps & WithTranslation

const PPlanCard = (props: TProps) => {
	const { t, plan, interval } = props;

	const dispatch = useDispatch();

	const updatePlan = useSelector((state: IRootState) => state.billing?.updatePlan);
	const subscription = useSelector((state: IRootState) => state.billing?.subscription);
	const price = plan.prices?.find((v) => v?.recurring?.interval === interval && v.active) as IPrice;
	const currency = price?.currency || 'brl';

	const [quantity, setQuantity] = useState(updatePlan?.quantity);

	const standardQuantity = (function () {
		if (subscription?.plan.name === 'Standard' && updatePlan?.name === 'Standard') {
			return updatePlan?.quantity || 0;
		}

		return plan.metadata.minLicenses;
	}());

	const generatorArray = (li: number, ls: number, label: string) => {
		const array = [];
		let _li = li;
		for (_li; _li <= ls; _li += 1) {
			array.push({
				value: _li,
				label: `${_li} ${label}`,
			});
		}
		return array;
	};

	const setPlan = () => {
		dispatch(Creators.BILLING.handlePlan({
			quantity,
			currency,
			id: plan.id,
			name: plan.name,
			amount: price.unit_amount,
			interval: price.recurring.interval,
		}));

		console.log('------', subscription?.plan.name, updatePlan)

		persistor.pause();
		switch (subscription?.plan.name) {
			case 'Free':
				// Free to Free
				dispatch(Creators.BILLING.handlePlan({ quantity: plan.metadata.minLicenses }));

				// Free to Standard
				if (plan.name === 'Standard') {
					dispatch(Creators.BILLING.handlePlan({ quantity: quantity || standardQuantity }));
				}

				// Default action
				dispatch(Creators.MODAL.showModal(routes.MODAL_PLAN.path));
				break;
			case 'Standard':
				if (plan.name === 'Free') {
					dispatch(Creators.BILLING.handlePlan({ quantity: plan.metadata.minLicenses }));
					dispatch(Creators.MODAL.showModal(routes.MODAL_DOWNGRADE.path));
					return;
				}
				if (plan.name === 'Standard') {
					dispatch(Creators.BILLING.handlePlan({ quantity: quantity || standardQuantity }));
					dispatch(Creators.MODAL.showModal(routes.MODAL_PLAN.path));
				}
				break;
			case 'Enterprise':
				if (['Standard', 'Free'].includes(updatePlan.name)) {
					dispatch(Creators.MODAL.showModal(routes.MODAL_DOWNGRADE.path));
				}
				break;
			default:
				break;
		}
	};


	// @ts-ignore
	const onQuantityHandler = (e) => {
		setQuantity(parseInt(e.target.value, 10));
		dispatch(Creators.BILLING.handlePlan({
			id: plan.id,
			name: plan.name,
			interval: price.recurring.interval,
			quantity: parseInt(e.target.value, 10),
			currency,
			amount: price.unit_amount,
		}));
	};

	return (
		<div
			aria-hidden="true"
			className={`generic_content p-3 card 
			${subscription?.plan?.name === plan.name ? 'active' : ''}`}
		>
			<div className="plan__header">
				<span>{plan.name}</span>
				<div className="align-items-center d-flex">
					<svg xmlns="http://www.w3.org/2000/svg" width="14" height="16">
						{ /* eslint-disable-next-line max-len */}
						<path d="M9.8 9.5c-.9 0-1.328.5-2.8.5s-1.9-.5-2.8-.5A4.2 4.2 0 000 13.7v.8A1.5 1.5 0 001.5 16h11a1.5 1.5 0 001.5-1.5v-.8a4.2 4.2 0 00-4.2-4.2zm2.7 5h-11v-.8A2.7 2.7 0 014.2 11a12.235 12.235 0 002.8.5 12.118 12.118 0 002.8-.5 2.7 2.7 0 012.7 2.7zM7 9a4.5 4.5 0 10-4.5-4.5A4.5 4.5 0 007 9zm0-7.5a3 3 0 11-3 3 3 3 0 013-3z" fill="#444" />
					</svg>
					<div className="licenses">
						{plan.name === 'Enterprise' ? `${plan.metadata.minLicenses}+` : plan.metadata.maxLicenses}
					</div>
				</div>
			</div>
			<div className="info">
				<div className="price">
					{plan.name === 'Free' && (
						Intl.NumberFormat(
							'pt-BR',
							{ style: 'currency', currency: price?.currency },
						)
							.format(price?.unit_amount)
					)}
					{plan.name === 'Standard' && (
						Intl.NumberFormat(
							'pt-BR',
							{ style: 'currency', currency: price?.currency },
						)
							.format((price.unit_amount / 100) * (standardQuantity || 0) * (price.recurring.interval === 'year' ? 12 : 1))
					)}
					{plan.name === 'Enterprise' && t('global.customPricing')}
				</div>
				{plan.name === 'Standard'
					&& (
						<span className="">
							/
							{t(`settings.${price.recurring.interval}`)}
						</span>
					)}
				{plan.name === 'Enterprise'
					&& (
						<p>
							{t('plans.contactUs')}
						</p>
					)}
			</div>
			<div className="generic_feature_list">
				<strong>{t('global.features')}</strong>
				{plan.metadata.Features.map((feature, index) => (
					// eslint-disable-next-line react/no-array-index-key
					<span key={index}>
						{t(`${feature}`)}
					</span>
				))}
			</div>
			<SButton
				type="button"
				color="secondary"
				variant="outlined"
				onClick={setPlan}
			>
				{t(`plans.action.${plan.name}`)}
			</SButton>
		</div>
	);
};

const Translation = withTranslation('common')(PPlanCard);

const PlanCard = (props: IOwnsProps) => (
	<Suspense fallback={<SpinnerLDSRipple />}>
		<Translation {...props} />
	</Suspense>
);

export default PlanCard;
