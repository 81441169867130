// @ts-nocheck
import React, { Suspense } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import SpinnerLDSRipple from '@components/Shared/SpinnerLDSRipple';
import { ISTab, STabs } from '@components/STabs';
import { STabPanel } from '@components/STabs/STabPanel';
import { TABS_CLASSNAMES } from '..';
import Permissions from './Permissions';
import Enterprise from './Enterprise';

interface IAccountTabs {
	PERMISSIONS: ISTab
	ENTERPRISE_FEATURES: ISTab
}

declare type TProps = WithTranslation
// SECURITY
const _Account = (props: TProps) => {
	const { t } = props;
	const TABS: IAccountTabs = {
		PERMISSIONS: { value: 'security-permissions' },
		ENTERPRISE_FEATURES: { value: 'security-enterprise' },
	};

	// @ts-ignore
	Object.keys(TABS).forEach((key) => TABS[key].label = t(`settings.tabs.${TABS[key].value}`));

	return (
		<STabs
			tabs={Object.values(TABS)}
			className={TABS_CLASSNAMES}
			active={TABS.PERMISSIONS.value}
			id="security-tabs"
			historyMode="secondary"
			TabsProps={{
				sx: {
					borderBottom: 1,
					borderColor: 'divider',
				},
			}}
			TabPanelProps={{
				sx: {
					marginY: 6,
				},
			}}
		>
			<STabPanel value={TABS.PERMISSIONS.value}>
				<Permissions />
			</STabPanel>
			<STabPanel value={TABS.ENTERPRISE_FEATURES.value}>
				<Enterprise />
			</STabPanel>
		</STabs>
	);
};

const Translation = withTranslation('common')(_Account);

const Account = (props: {}) => (
	<Suspense fallback={<SpinnerLDSRipple />}>
		<Translation {...props} />
	</Suspense>
);

export default Account;
