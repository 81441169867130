import React from 'react';
import { Box, IconButton, Tooltip } from '@mui/material';
import { WithTranslation, withTranslation } from 'react-i18next';
import Icon from '../Shared/Icon';

interface IActionBar {
	action: () => void
	hide?: boolean
}

interface FormActionsBarProps {
	insertParams: IActionBar
	deleteParams: IActionBar
	copyParams: IActionBar
	disabled?: boolean
}

declare type TProps = FormActionsBarProps & WithTranslation

const _FormActionsBar = ({
	insertParams, deleteParams, copyParams, disabled, t,
}: TProps) => {
	const options = (function () {
		return [
			{
				hide: insertParams?.hide || false,
				icon: 'plus',
				tooltip: t('form.addQuestion'),
				target: 'insert_button',
				action: () => (typeof insertParams?.action === 'function' ? insertParams.action() : null),
			},
			{
				hide: copyParams?.hide || false,
				icon: 'copy',
				tooltip: t('global.copy'),
				target: 'copy_button',
				action: () => (typeof copyParams?.action === 'function' ? copyParams.action() : null),
			},
			{
				hide: deleteParams?.hide || false,
				icon: 'remove',
				tooltip: t('global.delete'),
				target: 'remove_button',
				action: () => (typeof deleteParams?.action === 'function' ? deleteParams.action() : null),
			},
		];
	}());

	return (
		<Box
			className="actions__bar"
			sx={{
				maxHeight: deleteParams?.hide ? '108px' : 'auto',
				width: '48px',
				padding: '6px',
				minHeight: '35%',
				position: 'absolute',
				right: '-55px',
				backgroundColor: '#fff',
				borderRadius: '4px',
				boxShadow: '0 2px 1px -1px rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%), 0 1px 3px 0 rgb(0 0 0 / 12%)',
				transition: 'box-shadow 280ms cubic-bezier(0.4,0.0,0.2,1)',
				display: 'flex',
				marginTop: '-16px',
				flexDirection: 'column',
				alignItems: 'center',
			}}
		>
			{options.map((item) => (!item.hide
				? (
					<Tooltip key={item.icon} title={item.tooltip} placement="right">
						<IconButton
							id={item.target}
							type="button"
							color="primary"
							size="small"
							disabled={disabled}
							onClick={item.action}
							sx={{
								height: '36px',
								width: '36px',
							}}
						>
							{typeof item.icon === 'string'
								? <Icon icon={item.icon} />
								: item.icon}
						</IconButton>
					</Tooltip>
				)

				: null))}
		</Box>
	);
};

const Translate = withTranslation('common')(_FormActionsBar);

const FormActionsBar = (props: FormActionsBarProps) => (
	<Translate {...props} />
);

export { FormActionsBar };
