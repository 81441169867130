import { DynamicCircle } from '@components/DynamicCircle';
import RedactorText from '@components/Shared/RedactorText';
import { UserUpdated } from '@components/Shared/UserUpdated';
import { Box, BoxProps } from '@mui/material';
import { createBackground } from '@utils/helpers';
import React, { Suspense } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { IForm } from 'src/@types/IForm';

interface IOwnProps extends BoxProps {
	form: IForm
	onInsert?: () => void
	onView?: () => void
	action: 'edit' | 'create' | 'insert' | 'answer' | 'view'
}

declare type TProps = IOwnProps & WithTranslation

const _BFormLine = ({
	form, action, onInsert, onView, t,
}: TProps) => (
	<>
		<DynamicCircle
			icon="form"
			size="x-large"
			color={form.color}
			background={createBackground(form.color)}
			action={action}
			onClick={() => (typeof onInsert === 'function' ? onInsert() : null)}
		/>
		<div
			className="container-content"
			aria-hidden
			onClick={() => (typeof onView === 'function' ? onView() : null)}
		>
			<Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
				<span className="title">{form.name}</span>
				<span>
					<strong>{`${t('form.difficulty')}: `}</strong>
					<span className="first-letter-capitalize">{form.difficulty}</span>
				</span>
				{
					form.useCases && (
						<span className="mb-2">
							<strong>{`${t('form.useCases')}: `}</strong>
							<span>
								{form.useCases}
							</span>
						</span>
					)
				}
			</Box>
			<RedactorText html={form.description} hasToggle className="content-text" />
			{typeof form.author !== 'undefined' && (
				<UserUpdated
					className="mt-20px"
					profile={form.author}
					updatedAt={form.updatedAt}
				/>
			)}
		</div>
	</>
);

const Translation = withTranslation('common')(_BFormLine);

const BFormLine = (props: IOwnProps) => (
	<Suspense fallback={<div />}>
		<Translation {...props} />
	</Suspense>
);

export { BFormLine };
