import React from 'react';

const reorder = (arr: any[], startIndex: number, endIndex: number): Array<any> => {
	const result = Array.from(arr);
	const [removed] = result.splice(startIndex, 1);
	result.splice(endIndex, 0, removed);

	return result;
};

const generatorArrayNumbers = (li = 1, ls = 10) => {
	const array = [];
	for (li; li <= ls; li++) {
		array.push({
			value: `${li}`,
			label: `${li}`,
		});
	}
	return array;
};

export { reorder, generatorArrayNumbers };
