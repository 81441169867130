import { Box } from '@mui/material';
// eslint-disable-next-line import/no-extraneous-dependencies
import { styled as MuiStyled } from '@mui/system';

const Common = {
	fullScreen: {
		paddingY: '0.625rem', // 10px
	},
};

interface DialogBoxProps {
}

export const DialogBox = MuiStyled(Box)<DialogBoxProps>(({ theme }) => ({
	color: 'var(--charcoal-color)',
	[theme.breakpoints.up('xs')]: {
	},
	[theme.breakpoints.up('md')]: {
	},
	'.MuiTypography-root': {
		padding: 0,
	},
	'.MuiTypography-h6.MuiDialogTitle-root': {
		fontWeight: 700,
	},
	'.MuiFormLabel-root': {
		color: 'var(--charcoal-color)',
	},
	'&.s-dialog-breadcrumbs': {
		paddingTop: Common.fullScreen.paddingY,
		paddingBottom: Common.fullScreen.paddingY,
		a: {
			color: 'var(--charcoal-color)',
			textDecoration: 'none',
		},
	},
	'&.s-dialog-title': {
		display: 'flex',
		alignItems: 'center',
		paddingTop: Common.fullScreen.paddingY,
		paddingBottom: Common.fullScreen.paddingY,
		gap: 1,
	},
	'&.s-dialog-content': {
		paddingTop: Common.fullScreen.paddingY,
		paddingBottom: Common.fullScreen.paddingY,
	},
	'.MuiDialogActions-root': {
		gap: 1,
		paddingTop: Common.fullScreen.paddingY,
		paddingBottom: 0,
		paddingLeft: 0,
		paddingRight: 0,
	},
}));
