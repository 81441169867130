
import { EmptyState } from '@components/Shared/EmptyState';
import RedactorText from '@components/Shared/RedactorText';
import { getTextFromHTML } from '@utils/helpers';
import React, { Suspense } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

interface IOwnProps {
	html: string
	type: 'summary' | 'description'
	className?: string
}

declare type TProps = IOwnProps & WithTranslation;

const _HTMLDescription = (props: TProps) => {
	const { html, type, className } = props;

	const text = getTextFromHTML(html);
	const hasText = typeof text !== 'undefined'
		&& text !== null
		&& text !== '';

	if (!hasText) {
		return <EmptyState type={type} />;
	}

	return (
		<div className={`${className || ''} text-justify`}>
			<RedactorText html={html} />
		</div>
	);
};

const Translation = withTranslation('common')(_HTMLDescription);

const HTMLDescription = (props: IOwnProps) => (
	<Suspense fallback={<div />}>
		<Translation {...props} />
	</Suspense>
);

export { HTMLDescription };
