// @ts-nocheck
import React, {
	Suspense, useEffect, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { WithTranslation, withTranslation } from 'react-i18next';
import ModalHeader from '@modules/Modal/ModalHeader';
import { APIPlatforms } from '@services/apis';
import { IPaginatedRequest, IPagination } from 'src/@types/IShared';
import { IRootState } from 'src/store';
import { PlatformCard } from '@components/Cards/PlatformCard';
import type { IModal } from '@modules/Modal/types';
import { IPlatform } from 'src/@types/IPlatform';
import { DialogBox } from '@modules/Modal/Components';
import { IListRequest } from '@components/Lists/types';
import CardGrid from '@components/Grids/CardGrid';
import { DialogContent, DialogTitle } from '@mui/material';
import { SToast } from '@modules/SToast';
import { Creators } from '@actions';
import { GenericGrid, W_GRID } from '@components/Grids/GenericGrid';
import FiltersNavBar from '../../NavBar/FiltersNavBar';

interface IOwnProps extends IModal { }

declare type TProps = IOwnProps & WithTranslation;

const MModalPlatformsToAdd = (props: TProps) => {
	const {
		t, routes, hideBack, hideClose,
	} = props;

	const dispatch = useDispatch();

	const [platforms, setPlatforms] = useState<IPagination<IPlatform>>({ docs: [], isLoading: true });
	const { close, platformType } = useSelector((state: IRootState) => state.modal?.ModalProps);
	const { ideas } = useSelector((state: IRootState) => state);

	const [queryParams, setQueryParams] = useState<IPaginatedRequest>({
		page: 1,
		limit: screen.width <= 1536 ? 2 : 3,
		filter: platformType,
		sort: 'last-first',
	});

	const loadPlatforms = async ({ callback, ...rest }: IListRequest) => {
		try {
			setPlatforms((prevState) => ({ ...prevState, isLoading: true }));
			setQueryParams((prevState) => ({ ...prevState, ...rest }));
			const response = await APIPlatforms.getPlatformsJoined(ideas._id, {
				...rest, type: rest.filter,
			});

			setPlatforms({
				...response.data,
				docs: response.data.hasPrevPage
					? platforms.docs?.concat(response.data.docs)
					: response.data.docs,
				isLoading: false,
			});
		} catch (err) {
			SToast.error(t('messages.error.platform.search'));
		} finally { // loadMore
			if (typeof callback === 'function') {
				callback();
			}
		}
	};

	// updated idea
	const onAddIdeaHandler = (idea) => {
		if (close) {
			if (typeof ideas.syncIdea === 'function') {
				ideas.syncIdea(idea); // page
			}
			dispatch(Creators.MODAL.hideModal());
			return;
		}

		dispatch(Creators.IDEAS.updateIdeas(idea));
		dispatch(Creators.MODAL.backModal());
	};

	const filters = [
		{
			value: 'brainstorm',
			label: t('global.brainstorms'),
		},
		{
			value: 'challenge',
			label: t('global.challenges'),
		},
	];

	useEffect(() => {
		loadPlatforms({ ...queryParams, filter: platformType });
	}, []);

	return (
		<DialogContent id="PlatformsAddDialog">
			<ModalHeader
				icon="idea"
				fullScreen
				hideClose={hideClose}
				hideBack={hideBack}
				BreadcrumbProps={{ className: W_GRID.W_GRID_1 }}
				onBack={() => {
					if (queryParams.filter) {
						dispatch(Creators.MODAL.handleParams('platformType', queryParams.filter));
					} // If No, the initial platform type remains
					dispatch(Creators.MODAL.backModal());
				}}
				pathParams={{
					platformType: t(`global.${queryParams.filter}s`),
					template: ideas?.template?.templateNome || '...',
					idea: ideas.nome || '...',
					rootPath: ideas.nome || '...',
				}}
			/>
			<DialogBox className="s-dialog-title inner w-grid-1">
				<DialogTitle variant="h1">
					{t('idea.choosePlatform', { type: t(`global.${queryParams.filter}`) })}
				</DialogTitle>
			</DialogBox>
			<DialogBox className="s-dialog-content inner w-grid-1">
				<FiltersNavBar
					id="platforms-add-nav"
					onSearch={loadPlatforms} // Debounced
					onUpdate={loadPlatforms}
					type="platforms"
					filters={filters}
					params={queryParams}
				>
					<CardGrid
						id="modal-platforms-add"
						section={platforms}
						type={`${platformType}s`}
						GridItemProps={{
							xs: 12, sm: 6, md: 4, xl: 3,
						}}
						loadMore={loadPlatforms}
						component={(platform: IPlatform) => (
							<PlatformCard
								clickable={false}
								platform={platform}
								routes={routes}
								sectionLocator="idea-platform-add"
								updateIdea={onAddIdeaHandler}
								idea={ideas}
							/>
						)}
					/>
				</FiltersNavBar>

			</DialogBox>
		</DialogContent>
	);
};

const Translation = withTranslation('common')(MModalPlatformsToAdd);

const ModalPlatformsToAdd = (props) => (
	<Suspense fallback={<div />}>
		<Translation {...props} />
	</Suspense>
);

export default ModalPlatformsToAdd;
