// @ts-nocheck
import React, { Suspense } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import URLS from '@constants/URLS';
import { UserAvatar } from '@components/Avatar/UserAvatar';
import { IUserOrganization } from 'src/@types/IUser';
import { SButton } from '@components/Buttons';
import { Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ActivityChip } from '../Shared/ActivityChip';

interface IOwnProps {
	profile: IUserOrganization
}

declare type TProps = IOwnProps & WithTranslation

const UUserCard = (props: TProps) => {
	const { profile, t } = props;
	const navigate = useNavigate();

	return (
		<div className="card w-100">
			<div className="avatar--header text-center">
				<div style={{ backgroundColor: profile?.user?.cor || 'var(--primary)', height: '131px' }} />
				<div className="d-flex justify-content-center" style={{ marginTop: '-76px' }}>
					<UserAvatar
						size="xx-large"
						profile={profile}
						hasHref
					/>
				</div>
			</div>
			<div className="card-body">
				<div className="d-flex flex-column justify-content-center mb-20px pl-4 pr-4 align-items-center">
					<Typography mb={1} textOverflow="ellipsis" fontWeight="600">
						{profile?.user?._nome || profile?.user?.nome}
					</Typography>
					{profile?.user?.createdAt && (
						<span className="d-flex font-light justify-content-center">
							{t('global.joinedIn', { date: new Date(profile?.user?.createdAt) })}
						</span>
					)}

				</div>
				<ul className="d-flex justify-content-center">
					{[
						{
							icon: 'platform',
							count: profile.plataformas_count,
							tooltip: t('global.platforms'),
						},
						{
							icon: 'idea',
							count: profile.ideasCount,
							className: 'mr-10px ml-10px',
							tooltip: t('global.ideas'),
						},
						{
							icon: 'team',
							count: profile.teamsCount,
							tooltip: t('global.teams'),
						},
					].map((item) => (
						<li className={item.className} key={`${item.icon}-card`}>
							<ActivityChip count={item.count} icon={item.icon} tooltip={item.tooltip} target={`user_${item.icon}_${profile?._id}`} />
						</li>
					))}
				</ul>
				<div className="d-flex justify-content-center p-4">
					<SButton
						color="secondary"
						variant="outlined"
						onClick={() => navigate(`${URLS.PROFILE}/${profile?._id}`)}
					>
						{t('global.viewProfile')}
					</SButton>
				</div>
			</div>
		</div>
	);
};

const Translation = withTranslation('common')(UUserCard);

const UserCard = (props: IOwnProps) => {
	const { profile } = props;
	return (
		<Suspense fallback={<div />}>
			{typeof profile === 'undefined' ? <div /> : <Translation {...props} />}
		</Suspense>
	);
};

export { UserCard };
