// @ts-nocheck
import React, { Suspense, useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import ModalHeader from '@modules/Modal/ModalHeader';
import { FLOWS } from '@constants/GLOBAL';
import { SButton } from '@components/Buttons';
import type { IModal } from '@modules/Modal/types';
import { IRootState } from 'src/store';
import { isEmpty } from '@utils/ReactUtils';
import { DialogBox } from '@modules/Modal/Components';
import RedactorText from '@components/Shared/RedactorText';
import { DynamicCircle } from '@components/DynamicCircle';
import RichTextEditor from '@components/RichTextEditor';
import { Creators } from '@actions';
import ModalFeedback, { IFeedbackProps } from '@components/Modals/ModalFeedback';
import { DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useTour } from '@reactour/tour';
import { W_GRID } from '@components/Grids/GenericGrid';
import SpinnerLDSRipple from '../../Shared/SpinnerLDSRipple';

declare type TProps = IModal & WithTranslation

const MModalCanvasEdit = (props: TProps) => {
	const { t } = props;
	const dispatch = useDispatch();
	const tour = useTour();
	const { flow } = useSelector((state: IRootState) => state.modal);
	const {
		canvas, ideas, platforms, shared,
	} = useSelector((state: IRootState) => state);
	// const currentPageModal = useSelector((state: IRootState) => state.modal?.currentPage);
	const [inProgress, setInProgress] = useState(undefined);
	const [feedbackProps, setFeedbackProps] = useState<IFeedbackProps>({ open: false });

	const {
		currentModel, pagingPaths, action, rootPath, template,
	} = useSelector(
		(state) => state.modal?.ModalProps,
	);

	const [active, setActive] = useState('');

	let model = canvas;

	if (currentModel) {
		model = currentModel;
	}

	const disabled = ['view', 'insert'].includes(action)
		|| ['delete', 'insert', 'reset'].includes(inProgress) || !model;

	const onTourHandler = () => {
		if (shared?.tour === FLOWS.IDEA) {
			tour.setCurrentStep(7);
		}
		if (shared.tour === FLOWS.CHALLENGE) {
			tour.setCurrentStep(9);
		}
		if (shared.tour === FLOWS.BRAINSTORM) {
			tour.setCurrentStep(7);
		}
		tour.setIsOpen(false);
	};

	const onDeleteHandler = () => {
		const params = {
			templateId: model.idTemplate,
			canvasId: model._id,
			ideaId: ideas?._id,
			platformId: platforms?._id,
			onSuccess: () => {
				onTourHandler();
				dispatch(Creators.CANVAS.getCanvas({
					flow,
					ideaId: ideas?._id,
					platformId: platforms?._id,
					onSuccess: (response) => {
						if ([FLOWS.IDEA].includes(flow)) {
							dispatch(Creators.IDEAS.updateIdeas({ canvas: response.docs }));
						}
						if ([FLOWS.BRAINSTORM, FLOWS.CHALLENGE].includes(flow)) {
							dispatch(Creators.PLATFORMS.updatePlatform({ canvas: response.docs }));
						}
					},
				}));
			},
			callback: () => setFeedbackProps((prevState) => ({ ...prevState, isLoading: false })),
		};

		if (model.embedHtml) {
			params.boardId = model.id;
		}

		setFeedbackProps({
			open: true,
			title: t('feedback.remove.title-o', { name: `canvas ${canvas.name}` }),
			rightButtonText: t('global.remove'),
			color: 'error',
			onRightButton: () => {
				setFeedbackProps((prevState) => ({ ...prevState, isLoading: true }));
				switch (flow) {
					case FLOWS.CHALLENGE:
					case FLOWS.BRAINSTORM:
						if (model.embedHtml) {
							dispatch(Creators.PLATFORMS.removeBoardPlatform(params));
						}
						break;
					case FLOWS.IDEA:
						if (model.embedHtml) {
							dispatch(Creators.IDEAS.removeBoardIdea(params));
						}
						break;
					default:
						break;
				}

				if (model.embedHtml) return;

				dispatch(
					Creators.CANVAS.deleteCanvas(params),
				);
			},
		});
	};

	const onInsertHandler = () => {
		setInProgress('insert');
		dispatch(
			Creators.CANVAS.insertCanvas({
				templateId: model._id,
				ideaId: ideas._id,
				platformId: platforms._id,
				callback: () => setInProgress(undefined),
				onSuccess: (_canvas) => {
					dispatch(Creators.CANVAS.successCanvas(_canvas));
					dispatch(Creators.MODAL.handleParams('action', 'edit'));
				},
			}),
		);
	};

	const onBackHandler = (params) => {
		onTourHandler();

		if ([FLOWS.IDEA].includes(flow)) {
			const index = ideas.canvas.findIndex((item) => item._id === model._id);
			if (index > -1) {
				ideas.canvas.splice(index, 1, model);
				dispatch(Creators.IDEAS.updateIdeas({ canvas: ideas.canvas }));
			}
		}
		if ([FLOWS.CHALLENGE, FLOWS.BRAINSTORM].includes(flow)) {
			const index = platforms.canvas?.findIndex((item) => item._id === model._id);
			if (index > -1) {
				platforms.canvas.splice(index, 1, model);
				dispatch(Creators.PLATFORMS.updatePlatform({ canvas: platforms.canvas }));
			}
		}

		dispatch(Creators.MODAL.backModal(params));
		dispatch(Creators.CANVAS.clearCanvas());
	};

	const isLoading = typeof model === 'undefined' || !model || isEmpty(model);

	return (
		<DialogContent id="CanvasEditDialog">
			<ModalHeader
				fullScreen
				onBack={onBackHandler}
				BackModalProps={{ anchor: '#canvas-modal-create' }}
				hideBack={pagingPaths}
				BreadcrumbProps={{ className: model.embedHtml ? W_GRID.W_GRID_1 : W_GRID.W_GRID_2 }}
				hideClose
				icon={flow}
				pathParams={{
					rootPath,
					platform: platforms?.nome || '...',
					idea: ideas?.nome || '...',
					type: flow,
					template,
					model: model?.name || '...',
				}}
			/>
			<DialogBox className={`s-dialog-title ${model.embedHtml ? W_GRID.W_GRID_1 : W_GRID.W_GRID_2}`}>
				<DialogTitle variant="h1">{t('form.details')}</DialogTitle>
			</DialogBox>
			<DialogBox className={`s-dialog-box ${model.embedHtml ? W_GRID.W_GRID_1 : W_GRID.W_GRID_2}`}>
				{(isLoading || !model?.createdAt) && <SpinnerLDSRipple />}
				{!isLoading && model.fields && (
					<>
						<DialogBox className="s-dialog-content list" sx={{ display: 'flex', gap: 1 }}>
							<DynamicCircle
								icon="canvas"
								size="x-large"
								color={model.color}
								background={model.background}
							/>
							<div
								aria-hidden
								className="container-content"
							>
								{/* @ts-ignore */}
								<div className="mb-2 bold">{model.title || model.name}</div>
								{/* @ts-ignore */}
								<RedactorText html={model.description} hasToggle className="content-text" />
							</div>
						</DialogBox>
						<DialogBox className="s-dialog-content">
							<ul>
								{model.fields?.map((field, i) => (
									<li key={field.name}>
										<div className={`card p-3 mb-20px canvas ${active === field.name ? 'active' : ''}`}>
											<div className="d-flex align-items-center justify-content-between">
												{disabled
													? <RedactorText html={field.description} className="text-charcoal w-100 font-light" />
													: (
														<RichTextEditor
														// setRef={editor}
															value={field.description}
															name={field.name}
															isAsync
															minHeight="160px"
															disabled={disabled}
															isLoading={inProgress === field.name}
															onChange={(value) => {
																if (disabled) return;
																setInProgress(field.name);
																dispatch(
																	Creators.CANVAS.handleCanvas({
																		canvasId: model._id,
																		key: i,
																		name: field.name,
																		description: value,
																		callback: () => setInProgress(),
																	}),
																);
															}}
															onBlur={() => setActive('')}
															onFocus={() => setActive(field.name)}
														/>
													)}
												<div className="align-items-center d-flex flex-column justify-content-center m-4">
													<img src={field.image} className="mb-20px" alt={field.title} />
													{field.title}
												</div>
											</div>
										</div>
									</li>
								))}
							</ul>
						</DialogBox>

					</>
				)}

				{!isLoading && model.embedHtml && (
					<div dangerouslySetInnerHTML={{ __html: model.embedHtml }} style={{ height: '75vh' }} />
				)}
				{action && (
					<DialogActions className="s-dialog-actions">
						{action === 'insert' && (
							<SButton
								type="button"
								onClick={onInsertHandler}
								variant="outlined"
								color="primary"
								fullWidth
								size="large"
								isLoading={inProgress === 'insert'}
							>
								{t('form.canvas.insert')}
							</SButton>
						)}
						{action === 'edit' && (
							<SButton
								type="button"
								color="error"
								variant="outlined"
								size="large"
								fullWidth
								onClick={onDeleteHandler}
							>
								{t('form.canvas.remove')}
							</SButton>
						)}
					</DialogActions>
				)}

			</DialogBox>
			{feedbackProps.open && (
				<ModalFeedback
					id="canvas"
					onRequestClose={() => setFeedbackProps({ open: false })}
					{...feedbackProps}
				/>
			)}
		</DialogContent>
	);
};

const Translation = withTranslation('common')(MModalCanvasEdit);
const ModalCanvasEdit = (props: IModal) => (
	<Suspense fallback={<DialogContent />}>
		<Translation {...props} />
	</Suspense>
);
export default ModalCanvasEdit;
