import React, { Suspense, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { isEmpty } from '@utils/ReactUtils';
import { overflowText, showTab } from '@utils/helpers';
import { DynamicCircle } from '@components/DynamicCircle';
import URLS from '@constants/URLS';
import { UserAvatar } from '@components/Avatar/UserAvatar';
import { onLogout } from '@services/auth';
import { SButton } from '@components/Buttons';
import {
	Box,
	Divider, Link, ListItemIcon, ListItemText, Menu, MenuItem, Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { IRootState } from '../../store';

interface IOwnProps {

}

type TProps = WithTranslation & IOwnProps

const _HeaderUser = (props: TProps) => {
	const { t } = props;
	const navigate = useNavigate();
	const { userOrganizationProfile, billing } = useSelector((state: IRootState) => state);
	const subscription = billing?.subscription;
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

	const handleBuy = () => {
		if (document.getElementById('settings-page')) {
			showTab({ tabId: 'billing-tab' });
			setTimeout(() => {
				showTab({ tabId: 'billing-plans-tab' });
			}, 300);

			navigate('?tab=billing-tab&focus=billing-plans-tab', { replace: true });
		} else {
			navigate(`${URLS.SETTINGS}?tab=billing-tab&focus=billing-plans-tab`);
		}
	};

	if (!userOrganizationProfile || isEmpty(userOrganizationProfile?.user)) {
		return <div />;
	}

	const open = Boolean(anchorEl);

	return (
		<>
			<UserAvatar
				profile={userOrganizationProfile}
				size="small"
				aria-controls={open ? 'account-settings-menu' : undefined}
				aria-haspopup="true"
				aria-expanded={open ? 'true' : undefined}
				onClick={(event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget)}
				TooltipProps={{
					title: t('settings.account'), arrow: true, placement: 'bottom', classes: { popper: 'secondary' },
				}}
			/>
			<Menu
				id="account-settings-menu"
				open={open}
				anchorEl={anchorEl}
				onClose={() => setAnchorEl(null)}
				onClick={() => setAnchorEl(null)}
				className="s-menu"
				sx={{ paddingTop: 0 }}
				PaperProps={{
					elevation: 0,
					sx: {
						minWidth: '220px',
						overflow: 'visible',
						filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
						mt: 1.5,
					},
				}}
				transformOrigin={{ horizontal: 'center', vertical: 'top' }}
				anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
			>
				<Box
					sx={{
						marginTop: '-8px',
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'flex-start',
						backgroundColor: '#F7F7F7',
						whiteSpace: 'nowrap',
						gap: '10px',
						padding: '12px 16px',
					}}
				>
					<Typography sx={{
						fontWeight: 'bolder',
						span: {
							maxWidth: '12rem', textOverflow: 'ellipsis', overflow: 'hidden',
						},
					}}
					>
						{userOrganizationProfile?.user._nome}
					</Typography>
					<Typography>
						{overflowText(userOrganizationProfile?.organization?.nome, 28)}
					</Typography>
					<Link
						component="button"
						href="# "
						textAlign="start"
						onClick={() => navigate(`${URLS.PROFILE}/${userOrganizationProfile?._id}`)}
						underline="hover"
					>
						{t('global.viewProfile')}
					</Link>
				</Box>
				<MenuItem onClick={() => navigate(`${URLS.SETTINGS}?tab=user-tab&focus=user-settings-tab`)}>
					<ListItemIcon><i className="fa fa-cog" /></ListItemIcon>
					<ListItemText>
						{t('global.settings')}
					</ListItemText>
				</MenuItem>
				<MenuItem onClick={onLogout}>
					<ListItemIcon><i className="fa fa-sign-out-alt" /></ListItemIcon>
					<ListItemText>
						{t('global.logout')}
					</ListItemText>
				</MenuItem>

				{(userOrganizationProfile.user?.isAdministrator && subscription?.plan.name === 'Free') && (
					<Box>
						<Divider />
						<MenuItem sx={{ paddingY: '1rem', justifyContent: 'center' }}>
							<SButton
								type="button"
								color="secondary"
								variant="contained"
								onClick={handleBuy}
								className="w-auto"
							>
								{`${t('global.buy')} SENNO`}
							</SButton>
						</MenuItem>
					</Box>
				)}
			</Menu>
		</>
	);
};

const Translation = withTranslation('common')(_HeaderUser);

const HeaderUser = (props: IOwnProps) => {
	const { userOrganizationProfile } = useSelector((state: IRootState) => state);

	const _fallback = (
		<div
			id="menu_header_user"
			aria-hidden
			className="d-inline-block"
			style={{ cursor: 'pointer' }}
		>
			<DynamicCircle background="#04486e" className="loading" size="small" />
		</div>
	);

	return (
		<Suspense fallback={_fallback}>
			{isEmpty(userOrganizationProfile?.user) ? _fallback : <Translation {...props} />}
		</Suspense>
	);
};

export default HeaderUser;
