// @ts-nocheck
import React, { useEffect, MouseEvent } from 'react';
import { withTranslation } from 'react-i18next';
import { getGoogleDriveMethods, getGoogleDriveSettings } from '@utils/ReactUtils';
import Icon from '@components/Shared/Icon';
import { SButton } from './SButton';

interface IOwnsProps {
	disabled?: boolean
	isLoading?: boolean
	onClick: (file: { type: string, data: any } | FormData) => void
}

let auth2;

const _GoogleUploadButton = (props: IOwnsProps) => {
	const {
		disabled,
		isLoading,
		onClick,
	} = props;

	const googleDrivePickerCallback = (data: any) => {
		// @ts-ignore
		if (data.action === window.google.picker.Action.PICKED && data.docs) {
			const file = { type: 'google-drive', data: data.docs[0] };
			if (typeof onClick === 'function') {
				onClick(file);
			}
		}
	};

	// GoogleDrive
	const googleDriveSettings = getGoogleDriveSettings();
	const googleDriveMethods = getGoogleDriveMethods(
		googleDriveSettings,
		googleDrivePickerCallback,
	);

	// Google Drive
	const googleDriveUpload = async (e: MouseEvent<HTMLButtonElement>) => {
		e.preventDefault();
		// @ts-ignore
		const googleAuth = gapi.auth2.getAuthInstance();
		const sigIn = await googleAuth.signIn({
			scope: googleDriveSettings.scope,
		});

		googleDriveMethods.handleAuthResult(sigIn);
	};

	useEffect(() => {
		gapi.load('auth2', () => {
			auth2 = gapi.auth2.init({
				client_id: process.env.GOOGLE_CLIENT_ID,
				cookiepolicy: 'single_host_origin',
			});
			auth2.then(() => {

			});
		});

		// @ts-ignore
		gapi.load('picker', googleDriveMethods.onPickerApiLoad);
	}, []);

	return (
		<SButton
			type="button"
			variant="outlined"
			color="secondary"
			className="google"
			onClick={googleDriveUpload}
			disabled={disabled}
			isLoading={isLoading}
			startIcon={<Icon icon="google-driver" />}
		>
			Google Drive
		</SButton>
	);
};
const GoogleUploadButton = withTranslation('common')(_GoogleUploadButton);

export { GoogleUploadButton };
