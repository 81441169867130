// @ts-nocheck
import { createActions, createReducer } from 'reduxsauce';
import { IPlatformsActions, IPlatformsState } from './types';

/** Creating actions types & creators */

export const platformsActions: IPlatformsActions = createActions({
	handlePlatformTemplate: ['params'],
	publishPlatform: ['params'],
	handlePlatform: ['params'],
	selectAttachments: ['formData', 'callback'],
	removeAttachment: ['attachmentId', 'callback'],
	getPlatform: ['params'],
	duplicatePlatform: ['params'],
	updatePlatform: ['platform'],
	deletePlatform: ['p'],
	arvichePlatform: ['p'],
	getPlatformParticipants: ['params'],
	removeBoardPlatform: ['params'],
	handleCoverPlatform: ['p'],
	clearPlatforms: [''],
	handleAddIdea: ['params'],
});

const { Types } = platformsActions;

/** Creating reducer handlers */

const INITIAL_STATE: IPlatformsState = {

};

const updatePlatform = (state = INITIAL_STATE, action) => {
	const { platform } = action;
	const mode = platform.isArchived || (Object.hasOwnProperty.call(platform, 'isFinished') && platform.isFinished) ? 'view' : 'edit';

	const DEFAULT = {
		...state,
		mode,
		isFinished: false,
		...platform,
		nomeTemplate: platform.nomeTemplate || 'Blank',
	};

	if (platform.membros) {
		const agents = [
			...platform.membros.agentes,
			...platform.pedidos_participacao.filter((item) => item.status === 'pendente'
				&& item.papel === 'agentes').map((item) => {
				const member = item.autor;
				member.status = item.status;
				return member;
			}),
		];
		const moderators = [
			...platform.membros.moderadores.map((item) => {
				const member = item;
				member.isAuthor = platform.autor._id === item._id;
				return member;
			}),
			...platform.pedidos_participacao.filter((item) => item.status === 'pendente'
				&& item.papel === 'moderadores').map((item) => {
				const member = item.autor;
				member.status = item.status;
				member.isAuthor = platform.autor._id === item._id;
				return member;
			}),
		];
		const sponsors = [
			...platform.membros.sponsors,
			...platform.pedidos_participacao.filter((item) => item.status === 'pendente'
				&& item.papel === 'sponsors').map((item) => {
				const member = item.autor;
				member.status = item.status;
				return member;
			}),
		];
		const participants = [
			...platform.membros.participantes.map((item) => {
				const member = item;
				member.isAuthor = platform.autor._id === item._id;
				return member;
			}),
			...platform.pedidos_participacao.filter((item) => item.status === 'pendente').map((item) => {
				const member = item.autor;
				member.status = item.status;
				return member;
			}),
		];

		return ({
			...DEFAULT,
			membros: {
				agentes: agents,
				moderadores: moderators,
				sponsors,
				participantes: participants,
			},
		});
	}

	return DEFAULT;
};

/** Default functions */

const clearPlatforms = (state = INITIAL_STATE) => ({
	syncPlatform: state.syncPlatform,
});

const defaultPlatform = (state = INITIAL_STATE) => ({ ...state });

/** Creating our own reducer */

export default createReducer(INITIAL_STATE, {
	[Types.GET_PLATFORM_PARTICIPANTS]: defaultPlatform,
	[Types.PUBLISH_PLATFORM]: defaultPlatform,
	[Types.HANDLE_PLATFORM]: defaultPlatform,
	[Types.SELECT_ATTACHMENTS]: defaultPlatform,
	[Types.REMOVE_ATTACHMENT]: defaultPlatform,
	[Types.GET_PLATFORM]: defaultPlatform,
	[Types.DUPLICATE_PLATFORM]: clearPlatforms,
	[Types.UPDATE_PLATFORM]: updatePlatform,
	[Types.CLEAR_PLATFORMS]: clearPlatforms,
	[Types.DELETE_PLATFORM]: defaultPlatform,
	[Types.REMOVE_BOARD_PLATFORM]: defaultPlatform,
	[Types.HANDLE_COVER_PLATFORM]: defaultPlatform,
	[Types.HANDLE_PLATFORM_TEMPLATE]: defaultPlatform,
	[Types.HANDLE_ADD_IDEA]: defaultPlatform,
});
