// @ts-nocheck
import { WithTranslation, withTranslation } from 'react-i18next';
import React from 'react';
import SpinnerLDSRipple from '@components/Shared/SpinnerLDSRipple';
import { ISTab, STabs } from '@components/STabs';
import { STabPanel } from '@components/STabs/STabPanel';
import Profile from './Profile';
import Integrations from './Integrations';
import Notifications from './Notifications';
import { TABS_CLASSNAMES } from '..';

interface IUserTabs {
	SETTINGS: ISTab
	NOTIFICATIONS: ISTab
	INTEGRATIONS: ISTab
}

declare type TProps = WithTranslation

const _User = (props: TProps) => {
	const { t } = props;
	const TABS: IUserTabs = {
		SETTINGS: { value: 'user-settings' },
		NOTIFICATIONS: { value: 'user-notifications' },
		INTEGRATIONS: { value: 'user-integrations' },
	};

	// @ts-ignore
	Object.keys(TABS).forEach((key) => TABS[key].label = t(`settings.tabs.${TABS[key].value}`));

	return (
		<STabs
			tabs={Object.values(TABS)}
			className={TABS_CLASSNAMES}
			id="user-tabs"
			active={TABS.SETTINGS.value}
			historyMode="secondary"
			TabsProps={{
				sx: {
					borderBottom: 1,
					borderColor: 'divider',
				},
			}}
			TabPanelProps={{
				sx: {
					marginY: 6,
				},
			}}
		>
			<STabPanel value={TABS.SETTINGS.value}>
				<Profile />
			</STabPanel>
			<STabPanel value={TABS.NOTIFICATIONS.value}>
				<Notifications />
			</STabPanel>
			<STabPanel value={TABS.INTEGRATIONS.value}>
				<Integrations />
			</STabPanel>
		</STabs>
	);
};

const Translation = withTranslation('common')(_User);

const User = (props: {}) => (
	<React.Suspense fallback={<SpinnerLDSRipple />}>
		<Translation {...props} />
	</React.Suspense>
);

export default User;
