// @ts-nocheck
import React, { Suspense, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withTranslation, WithTranslation } from 'react-i18next';
import { UsersGroupCircle } from '@components/Avatar/UsersGroupCircle';
import { ITeam } from 'src/@types/ITeam';
import routes from '@routes/modal';
import { APITeams } from '@services/apis';
import { handleAxiosErrors } from '@services/auth';
import { IRootState, persistor } from 'src/store';
import { maxLength } from '@constants/GLOBAL';
import { goToLink, isEmpty } from '@utils/ReactUtils';
import { Creators } from '@actions';
import ModalFeedback, { IFeedbackProps } from '@components/Modals/ModalFeedback';
import { STextField } from '@forms/Components';
import URLS from '@constants/URLS';
import { SMenu } from '@components/SMenu';
import { SButton } from '@components/Buttons';
import Icon from '@components/Shared/Icon';
import { Box } from '@mui/material';
import { SFormGroup, SInputLabel } from '@components/DynamicForm/ComponentsCore';

interface IOwnProps {
	team: ITeam
}

declare type TProps = IOwnProps & WithTranslation

const TTeamCover = (props: TProps) => {
	const { t, team, updateTeam } = props;

	const dispatch = useDispatch();
	const { userOrganizationProfile } = useSelector((state: IRootState) => state);
	const [errors, setErrors] = useState({});
	const [feedbackProps, setFeedbackProps] = useState<IFeedbackProps>({ open: false });
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

	if (typeof team === 'undefined') return <div />;

	const onLeaveTeamHandler = async () => {
		try {
			setFeedbackProps((prevState) => ({ ...prevState, isLoading: true }));
			const response = await APITeams.postLeaveTeam(team._id);
			if (typeof updateTeam === 'function') {
				// updateTeam(response.data);
				updateTeam({ ...team, usuarioParticipa: false });
			}
			setFeedbackProps({ open: false });
		} catch (err) {
			handleAxiosErrors(err);
		} finally {
			setFeedbackProps((prevState) => ({ ...prevState, isLoading: true }));
		}
	};

	const onErrorHandler = (key: string) => {
		if (!isEmpty(errors)) delete errors[key];
	};

	const onDeleteHandler = async ({ match }) => {
		try {
			setFeedbackProps((prevState) => ({ ...prevState, isLoading: true }));
			if (typeof match?.value === 'undefined' || match.value?.length < 1) {
				setErrors({ match: t('messages.requiredField') });
				document.querySelector('#team-name')?.focus();
				return;
			}

			await APITeams.deleteTeam(team._id, match.value);
			setFeedbackProps({ open: false });

			setTimeout(() => {
				goToLink(URLS.TEAMS);
			}, 500);
		} catch (err) {
			if (err.response?.data?.err === 'messages.error.nameNotMatch') {
				setErrors({ match: t('messages.error.nameNotMatch') });
			} else handleAxiosErrors(err);
		} finally {
			setFeedbackProps((prevState) => ({ ...prevState, isLoading: false }));
		}
	};

	const options = [
		{
			icon: <Icon icon="edit" />,
			label: t('global.edit'),
			show: userOrganizationProfile._id === team.autor._id,
			onClick: () => {
				persistor.pause();
				dispatch(Creators.MODAL.showModal(routes.MODAL_TEAM.path, {
					teamId: team._id, syncTeam: updateTeam,
				}));
			},
		},
		{
			icon: <Icon icon="leave" />,
			show: team.usuarioParticipa && userOrganizationProfile._id !== team.autor._id,
			label: t('global.leave'),
			onClick: () => setFeedbackProps({
				open: true,
				title: t('team.leave'),
				rightButtonText: t('global.leave'),
				onRightButton: onLeaveTeamHandler,
				match: { value: '', action: 'leave' },
			}),
		},
		{
			icon: <Icon icon="remove" />,
			show: userOrganizationProfile._id === team.autor._id,
			label: t('global.delete'),
			onClick: () => setFeedbackProps({
				open: true,
				color: 'error',
				title: t('feedback.delete.title-o', { name: `${t('global.team').toLocaleLowerCase()} ${team.nome}` }),
				rightButtonText: t('global.delete'),
				onRightButton: onDeleteHandler,
				match: { value: '', action: 'delete' },
			}),
		},
	];

	return (
		<Box className="team cover-box" style={{ backgroundColor: team.cor }}>
			<div className="cover__content">
				<div className="cover-content w-grid-1 inner">
					<div className="top">
						<div className="top left">
							{team.usuarioParticipa && (
								<SMenu
									id="idea-menu"
									open={Boolean(anchorEl)}
									anchorEl={anchorEl}
									setAnchorEl={setAnchorEl}
									options={options}
								>
									<SButton color="inherit" size="small">
										{t('global.options')}
									</SButton>
								</SMenu>
							)}
						</div>
					</div>
					<div className="middle">
						<div className="left">
							<div className="cover-title">{team.nome}</div>
							<Box className="cover-subtitle" sx={{ gap: 1 }}>
								<UsersGroupCircle
									prefixTarget="team_cover"
									group={team.membros}
									tooltipIsMoreText={t('global.participants')}
									id={team._id}
									hasMore
									onMore={() => goToLink('#members_team')}
									size="small"
									goProfile
									hasPopover
								/>
							</Box>
						</div>
						<div className="center" />
						<div className="right" />
					</div>
					<div className="bottom" />
				</div>
			</div>
			{feedbackProps.open && (
				<ModalFeedback
					id="team"
					className="s-form"
					onRequestClose={() => setFeedbackProps({ open: false })}
					rightButtonDisabled={feedbackProps.match?.action === 'delete'
						&& feedbackProps.match?.value.length < 1}
					{...feedbackProps}
				>
					{feedbackProps.match.action === 'delete' && (
						<SFormGroup>
							<SInputLabel htmlFor="team-name" error={typeof errors?.match === 'string'}>
								{t('team.nameToDelete')}
							</SInputLabel>
							<STextField
								id="team-name"
								onKeyDown={(e) => e.key === 'Enter' && onDeleteHandler(feedbackProps.match)}
								value={feedbackProps.match.value}
								maxLength={maxLength.teamName}
								placeholder={t('form.placeholder.team.current')}
								onChange={(event) => {
									onErrorHandler('match');
									setFeedbackProps((prevState) => ({
										...prevState,
										match: { ...prevState.match, value: event.target.value },
									}));
								}}
								error={typeof errors?.match === 'string'}
								helperText={errors?.match}
							/>
						</SFormGroup>
					)}
				</ModalFeedback>
			)}
		</Box>
	);
};

const Translation = withTranslation('common')(TTeamCover);

const TeamCover = (props: IOwnProps) => (
	<Suspense fallback={<div />}>
		<Translation {...props} />
	</Suspense>
);

export default TeamCover;
