// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { APIUnregistered } from '@services/apis';
import { goToLink } from '@utils/ReactUtils';
import URLS from '@constants/URLS';
import { auth, handleAxiosErrors } from '@services/auth';
import Icon from '@components/Shared/Icon';
import { SButton } from './SButton';

let auth2;

interface IGoogleButtonProps {
	className?: string
	onError?: () => void
	onSuccess?: () => void
	children: React.ReactNode
}

const GoogleButton = ({
	children, className, org, onError, onSuccess,
}: IGoogleButtonProps) => {
	const [inProgress, setInProgress] = useState(false);

	const attachSignIn = () => {
		const element = document.querySelector('#google-register-btn');

		auth2.attachClickHandler(
			element,
			{},
			async (googleUser) => {
				try {
					setInProgress(true);
					const profile = googleUser.getBasicProfile();
					const email = profile.getEmail();
					const token = googleUser.getAuthResponse().id_token;
					const response = await APIUnregistered.postGoogleCheckout({ email, token, org });

					if (response.data?.token) {
						auth.setToken(response.data?.token);
					}

					if (response?.status === 200) { // created
						goToLink(URLS.HOME);
						return;
					}
					if (response?.status === 201) { // register
						goToLink(URLS.ACCOUNT_SETUP);
						if (typeof onSuccess === 'function') {
							onSuccess();
						}
					}
				} catch (err) {
					switch (err?.response?.status) {
						case 409:
							handleAxiosErrors(err);
							break;
						case 404:
							if (typeof onError === 'function') { // no Org
								onError({ code404: true, email: err.response.data.email });
							}
							break;
						default:
					}
				} finally {
					setInProgress(false);
				}
			},
		);
	};

	useEffect(() => {
		gapi.load('auth2', () => {
			auth2 = gapi.auth2.init({
				client_id: process.env.GOOGLE_CLIENT_ID,
				cookiepolicy: 'single_host_origin',
			});
			auth2.then(() => {
				attachSignIn();
			});
		});
	}, []);

	return (
		<SButton
			id="google-register-btn"
			variant="contained"
			className={`btn-integration g-plus ${className || ''}`}
			startIcon={<Icon icon="google" className="social-icon" />}
			isLoading={inProgress}
		>
			{children}
		</SButton>
	);
};

export { GoogleButton };
