import React, { useState, ChangeEvent } from 'react';
import {
	InputBaseComponentProps,
	TextField, TextFieldProps, Box, InputAdornment, FormHelperText,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { SInputStatus } from './SInputStatus';

interface ISTextFieldProps extends InputBaseComponentProps {
	isLoading?: boolean
	noMatch?: boolean
	isAsync?: boolean
}

export const STextField = (props:ISTextFieldProps & TextFieldProps) => {
	const [innerError, setInnerError] = useState<string | undefined>(undefined);

	const { t } = useTranslation();

	const {
		id, className, noMatch, isLoading, error, helperText, InputProps, inputProps,
		isAsync, type = 'text', maxLength, value, onChange, ...rest
	} = props;

	// console.log('PROPS', props)

	const onChangeHandler = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | undefined>) => {
		setInnerError(undefined);

		if (!e) return;
		let event = _.cloneDeep(e);

		if ((!e.target?.value.match(/([~,!,@,#,$,%,^,&,*,-,_,+,=,?,>,<,/])/) && type === 'text') || noMatch || ['password', 'email'].includes(type)) {
			if (maxLength && event.target?.value.length > maxLength) {
				event = Object.assign(e, {
					target: { value: e.target?.value.slice(0, maxLength) },
					currentTarget: { value: e.currentTarget?.value.slice(0, maxLength) },
				});
			}
			if (typeof onChange === 'function') {
				onChange(event);
			}
		} else {
			setInnerError(
				`${t('messages.notAllowed')} ${
					(event?.nativeEvent as InputEvent)?.data ? (event?.nativeEvent as InputEvent)?.data : ''
				}`,
			);
		}
	};

	return (
		<Box className={`s-input ${className || ''}`} data-tut={id}>
			<TextField
				id={id}
				type={type}
				variant="outlined"
				fullWidth
				error={error}
				value={value}
				onChange={onChangeHandler}
				sx={{
					'.MuiFormHelperText-root.MuiFormHelperText-sizeMedium': {
						margin: 0,
					},
				}}
				InputProps={{
					...InputProps,
					endAdornment: (
						<>
							<InputAdornment position="end">
								<SInputStatus isLoading={isLoading} isAsync={isAsync} />
							</InputAdornment>
							{InputProps?.endAdornment}
						</>
					),
					inputProps: {
						autoComplete: 'new-password',
						...InputProps?.inputProps,
						...inputProps,
						maxLength,
					},
				}}
				{...rest}
			/>
			<Box sx={{ display: 'flex' }}>
				{(helperText || innerError) && (
					<FormHelperText id={`helper-text-${id}`} error={error}>
						{innerError || helperText}
					</FormHelperText>
				)}
				{maxLength && (
					<FormHelperText id={`helper-text-${id}-max`} className="ml-auto">
						{`${(value as string)?.length || 0} / ${maxLength}`}
					</FormHelperText>
				)}
			</Box>
		</Box>
	);
};
