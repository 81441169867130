import React, {
	Suspense, ChangeEvent, useState,
} from 'react';
import * as Yup from 'yup';
import { withTranslation, WithTranslation } from 'react-i18next';
import UnauthenticatedHeader from '@components/Header/UnauthenticatedHeader';
import { Form, SFormGroup, SInputLabel } from '@components/DynamicForm/ComponentsCore';
import { SButton } from '@components/Buttons';
import { isEmpty } from '@utils/ReactUtils';
import APIUnregistered from '@services/apis/unregistered';
import PageLoader from '@components/Loaders/PageLoader';
import { getValidationErrors } from '@utils/yup';
import URLS from '@constants/URLS';
import { handleAxiosErrors } from '@services/auth';
import { TObject } from 'src/@types/IShared';
import { passwordRegex, passwordValidation } from '@forms/Components/SPasswordField';
import { SPasswordField } from '@components/DynamicForm/Components';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Box, Grid, Typography } from '@mui/material';
import { GenericGrid } from '@components/Grids/GenericGrid';
import { MessageBox } from '@components/Shared/MessageBox';

declare type TProps = WithTranslation

const _ResetPassword = (props: TProps) => {
	const { t } = props;
	const location = useLocation();
	const navigate = useNavigate();
	const match = useParams<{ token: string }>();
	const [errors, setErrors] = useState<TObject>({});
	const [inProgress, setInProgress] = useState<boolean>(false);
	const [step, setStep] = useState<number>(1);
	const [password, setPassword] = useState('');

	const onSubmitHandler = async (event: React.MouseEvent | React.KeyboardEvent) => {
		event?.preventDefault();
		try {
			setInProgress(true);

			if (passwordValidation(password)?.level !== 'strong') {
				setErrors((prevState) => ({ ...prevState, password: t('messages.password.level.error') }));
				return;
			}

			const schema = Yup.object().shape({
				password: Yup.string()
					.required(t('messages.requiredField'))
					.matches(passwordRegex, t('fields.password.validation', { min: 6 })),
			});

			await schema.validate({ password }, {
				abortEarly: false,
			});

			// @ts-ignore
			await APIUnregistered.postResetPwd(match?.token, { password });
			setStep(2);
		} catch (err: any) {
			if (err instanceof Yup.ValidationError) {
				setErrors((prevState) => ({ ...prevState, ...getValidationErrors(err) }));
			} else handleAxiosErrors(err);
		} finally {
			setInProgress(false);
		}
	};

	const email = new URLSearchParams(location.search)?.get('email');

	return (
		<GenericGrid id="reset-password-page" className="reset__password--page s-grid-page">
			<UnauthenticatedHeader modus="logIn" />
			<Box className="flex-column inner w-grid-3 unauthenticated">
				{step === 1 && (
					<Form onSubmit={onSubmitHandler} className="s-form">
						<Grid container textAlign="center" spacing={2}>
							<Grid item xs={12}>
								<Typography variant="h1">{t('register.recovery.password')}</Typography>
							</Grid>
							<Grid item xs={12}>
								<Typography variant="body1">
									{`${t('form.email')}: `}
									<b>{email}</b>
								</Typography>
							</Grid>
						</Grid>
						<Box className="unauthenticated-content">
							<SFormGroup>
								<SInputLabel htmlFor="reset-password" error={typeof errors.password === 'string'}>
									{t('form.newPassword')}
								</SInputLabel>
								<SPasswordField
									id="reset-password"
									autoFocus
									autoComplete="off"
									placeholder={t('form.placeholder.newPassword')}
									name="password"
									value={password}
									onChange={(e: ChangeEvent<HTMLInputElement>) => {
										delete errors?.password;
										setPassword(e.target.value);
									}}
									error={typeof errors.password === 'string'}
									helperText={errors?.password || passwordValidation(password)?.message}
								/>
							</SFormGroup>
							<SButton
								type="submit"
								color="primary"
								variant="outlined"
								onClick={onSubmitHandler}
								isLoading={inProgress}
								error={!isEmpty(errors)}
								disabled={!isEmpty(errors) || password?.length < 1}
							>
								{t('global.continue')}
							</SButton>
						</Box>

					</Form>

				)}
				{step === 2 && (
					<MessageBox
						title={`${t('global.success')}!`}
						content={t('messages.password.changed')}
						button={(
							<SButton type="button" color="primary" variant="outlined" onClick={() => navigate(URLS.LOGIN)}>
								{t('register.backToLogin')}
							</SButton>
						)}
					/>
				)}
			</Box>
		</GenericGrid>
	);
};

const Translation = withTranslation('common')(_ResetPassword);

const ResetPassword = () => (
	<Suspense fallback={<PageLoader />}>
		<Translation />
	</Suspense>
);

export default ResetPassword;
