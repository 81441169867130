import React from 'react';

const PageLoader = () => (
	<div className="page-loader">
		<div className="loader-logo">
			<svg xmlns="http://www.w3.org/2000/svg" width="317.725" height="76.819" viewBox="0 0 317.725 76.819">
				<g id="Group_2685" data-name="Group 2685" transform="translate(-828.275 -504.744)">
					<g id="layer1">
						<path id="path4506-0-6-0" d="M848.226,575.893c17.956-.172,27.1,0,51.074,0V530.608h-4.549v40.836H843.39l-5.347-5.722h51.074V530.608h-4.581v30.657H833.191l-4.916-5.231v-51.29h51.074c1.916,1.931,3.851,3.835,5.782,5.742H833.638v45.161h4.549v-40.74h51.341l5.782,5.726H843.837v34.982h4.584V525.074h51.306l5.084,5.087v51.4h-51.1Zm30.688-45.285H854v25.015h24.914Z" fill="#05486e" />
					</g>
					<g id="SENNO" style={{ isolation: 'isolate' }}>
						<g id="Group_2684" data-name="Group 2684" style={{ isolation: 'isolate' }}>
							<path id="Path_3627" data-name="Path 3627" d="M939.965,559.086h7.664c0,6.644,5.748,8.433,10.155,8.433,5.492,0,8.366-2.49,8.366-5.814,0-3.511-3.513-4.727-10.345-7.279l-1.023-.386c-8.431-3.191-13.6-5.81-13.6-13.093,0-7.789,6.067-13.026,16.095-13.026,9.451,0,16.605,4.278,16.605,15.072h-7.664c0-6.005-4.343-8.046-9.006-8.046-5.108,0-8.3,2.3-8.3,5.428,0,3.768,3.129,4.855,9.642,7.154l.894.319c7.92,2.81,14.819,5.619,14.819,13.475,0,7.536-5.685,13.221-16.925,13.221C948.013,574.544,939.965,569.56,939.965,559.086Z" fill="#05486e" />
							<path id="Path_3628" data-name="Path 3628" d="M983.825,528.879H1012.5v6.707H991.49v12.387h18.01v6.387H991.49v12.52h21.077v6.706H983.825Z" fill="#05486e" />
							<path id="Path_3629" data-name="Path 3629" d="M1021.494,528.879h7.022l19.164,30.464V528.879h7.661v44.707h-7.022l-19.164-30.464v30.464h-7.661Z" fill="#05486e" />
							<path id="Path_3630" data-name="Path 3630" d="M1066.82,528.879h7.026l19.16,30.464V528.879h7.664v44.707h-7.025l-19.16-30.464v30.464h-7.665Z" fill="#05486e" />
							<path id="Path_3631" data-name="Path 3631" d="M1110.872,558.447V544.014c0-10.155,6.831-16.093,17.562-16.093S1146,533.859,1146,544.014v14.433c0,10.159-6.835,16.1-17.566,16.1S1110.872,568.606,1110.872,558.447Zm27.463.386v-15.2c0-4.789-3.257-8.685-9.9-8.685s-9.9,3.9-9.9,8.685v15.2c0,4.789,3.258,8.686,9.9,8.686S1138.335,563.622,1138.335,558.833Z" fill="#05486e" />
						</g>
					</g>
				</g>
			</svg>
		</div>
		<div className="loader-bar">
			<div className="loader-indicator" />
		</div>
	</div>
);

export default PageLoader;
