// @ts-nocheck
import { BoxProps } from '@mui/material';
import { Creators } from '@actions';
import { IRootState } from 'src/store';
import { goToLink } from '@utils/ReactUtils';
import { APIOrganizations } from '@services/apis';
import { handleAxiosErrors } from '@services/auth';
import { useDispatch, useSelector } from 'react-redux';
import React, { useState, useEffect, Suspense } from 'react';
import { Trans, withTranslation, WithTranslation } from 'react-i18next';
import SpinnerLDSRipple from '@components/Shared/SpinnerLDSRipple';
import { SFormGroup, SInputLabel } from '@components/DynamicForm/ComponentsCore';
import { Tag, Tags } from '@components/DynamicForm/Components/SSelect/Components';
import { IAutocompleteOption, SAutocomplete } from '@components/DynamicForm/Components/SSelect/SAutocomplete';
import { SLink } from '@components/Buttons/SLink';
import { showTab } from '@utils/helpers';
import URLS from '@constants/URLS';

interface InviteFormProps extends BoxProps {
	invites: IAutocompleteOption[]
	error?: boolean
	helperText?: string
	inBatch?: boolean
}

type TProps = InviteFormProps & WithTranslation

const _InviteForm = ({
	invites, handleInvites, t, sx, inBatch, ...rest
}: TProps) => {
	const dispatch = useDispatch();
	const { userOrganizationProfile } = useSelector((state: IRootState) => state);

	const [options, setOptions] = useState<string[]>([]);
	const { SLACK_CLIENT_ID, SLACK_OAUTH_REDIRECT_URL } = process.env;
	const [inProgress, setInProgress] = useState<string | undefined>();

	const loadIntegrationsUsers = async () => {
		try {
			const response = await APIOrganizations.getIntegrationsUsers();
			setOptions(response.data.slack.map((email) => ({ value: email, label: email })));
		} catch (err) {
			handleAxiosErrors(err);
		}
	};

	const handleEmails = (
		event: React.SyntheticEvent<Element, Event>,
		value: IAutocompleteOption[],
	) => {
		handleInvites(value);
	};

	const onSlackHandler = (event: React.MouseEvent<HTMLAnchorElement>) => {
		event.preventDefault();

		dispatch(Creators.MODAL.handleModal({
			pagePath: window.location.href,
		}));
		// eslint-disable-next-line max-len
		goToLink(`https://slack.com/oauth/v2/authorize?scope=incoming-webhook,commands,chat:write,users:read,users:read.email&client_id=${SLACK_CLIENT_ID}&state=${btoa(JSON.stringify({ id: userOrganizationProfile._id }))}&redirect_uri=${SLACK_OAUTH_REDIRECT_URL}`);
	};

	const slackIntegration = userOrganizationProfile?.integrations.find((item) => item.app === 'slack');

	const goBatch = () => {
		if (document.getElementById('settings-page')) {
			showTab({ navId: '#settings-tabs', tabId: 'invitations-tab' });
		} else {
			goToLink(`${URLS.SETTINGS}?tab=settings-tabs&focus=invitations-tab`);
		}

		dispatch(Creators.MODAL.hideModal());
	};


	const label = () => {
		if (inBatch && !slackIntegration?.isLoggedIn) {
			return (
				<Trans
					i18nKey="member.invite.slackBulk"
					components={{
						1: <SLink onClick={onSlackHandler} sx={{ cursor: 'pointer' }} />,
						2: (
							<SLink sx={{ cursor: 'pointer' }} onClick={goBatch} />
						),
					}}
				/>
			);
		}

		if (inBatch && slackIntegration?.isLoggedIn) {
			return (
				<Trans
					i18nKey="member.invite.bulk"
					components={{
						2: <SLink onClick={goBatch} sx={{ cursor: 'pointer' }} />,
					}}
				/>
			);
		}

		if (!inBatch) {
			return (
				<Trans
					i18nKey="member.invite.slack"
					components={{
						1: <SLink onClick={onSlackHandler} sx={{ cursor: 'pointer' }} />,
					}}
				/>
			);
		}

		return <Trans i18nKey="member.enterEmails" />;
	};

	useEffect(() => {
		if (slackIntegration) {
			loadIntegrationsUsers();
		}
	}, []);

	return (
		<SFormGroup sx={sx}>
			<SInputLabel htmlFor="invite-autocomplete" sx={{ display: 'block !important' }}>
				{label()}
			</SInputLabel>
			<SAutocomplete
				freeSolo
				id="invite-autocomplete"
				fullWidth
				rows={6}
				className="s-autocomplete invite"
				multiple
				unique="value"
				placeholder={t('form.typeOrPasteEmails')}
				value={invites || []}
				options={options}
				onChange={handleEmails}
				regex={/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi}
				renderTags={(_value, getTagProps) => (
					<Tags
						value={_value}
						component={(option, index) => (
							<Tag renderTags option={option} {...getTagProps({ index })} />
						)}
					/>
				)}
				{...rest}
			/>

		</SFormGroup>
	);
};

const Translation = withTranslation('common')(_InviteForm);

const InviteForm = (props: InviteFormProps) => (
	<Suspense fallback={<SpinnerLDSRipple />}>
		<Translation {...props} />
	</Suspense>

);
export default InviteForm;
