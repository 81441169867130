import { axiosInstance } from '@services/AxiosInstance';

export declare type TPlatformRequest = 'challenge' | 'brainstorm';

const URL_BASE = '/trends';

const APITrends = {
	getRelatedDocs(params: any) {
		return axiosInstance.get(`${URL_BASE}/related-documents?TEXT=${params.query.TEXT}`);
	},
	getQAresponse(params: any) {
		return axiosInstance.get(`${URL_BASE}/qa?id=${params.id}&text=${params.text}`);
	},
	getGeneratedQuestions(params: any) {
		return axiosInstance.get(`${URL_BASE}/generate-question?id=${params.id}`);
	},
};

export default APITrends;
