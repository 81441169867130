/* eslint-disable no-param-reassign */
import React, {
	MouseEvent, useState, useEffect,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { WithTranslation, withTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import URLS from '@constants/URLS';
import routes from '@routes/modal';
import Icon from '@components/Shared/Icon';
import { IRootState, persistor } from 'src/store';
import { APIOrganizations } from '@services/apis';
import {
	Box,
	Chip, IconButton, List, ListItem, SwipeableDrawer, Tooltip,
} from '@mui/material';
import { AiOutlinePlus } from 'react-icons/ai';
import { MdMenuOpen } from 'react-icons/md';
import { SLink } from '@components/Buttons/SLink';
import { Creators } from '../../store/ducks/actionsTypes';
import { IOrganization } from '../../@types/IOrganization';

const styles = {
	iconContainer: {
		width: '16px',
		display: 'flex',
		justifyContent: 'center',
	},
};

interface SidebarProps {
	open: boolean
	setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

type TProps = WithTranslation & SidebarProps

const SSidebar = (props: TProps) => {
	const { t, open, setOpen } = props;
	const location = useLocation();
	const navigate = useNavigate();
	const { userOrganizationProfile } = useSelector((state: IRootState) => state);
	const [counters, setCounters] = useState<any>({});
	const dispatch = useDispatch();

	const { pathname } = location;
	const title = t(`global.${pathname.split('/')[1] || 'home'}`);

	// @ts-ignore
	useEffect(async () => {
		const response = await APIOrganizations.getCounters();

		setCounters(response.data);
	}, []);

	const onAddOrganization = (e: MouseEvent<HTMLLIElement>) => {
		e.stopPropagation();

		if (userOrganizationProfile) {
			dispatch(Creators.MODAL.showModal(routes.MODAL_ORGANIZATION_CREATE.path, {
				room: userOrganizationProfile._id,
			}));
		}
	};

	interface ISidebarItem {
		label?: string
		path: string
		icon: string
		hasSeparator?: boolean
		features?: number
		target?: string
	}

	const items: Array<ISidebarItem> = [
		{
			path: `${URLS.HOME}`,
			icon: 'home',
		},
		{
			path: URLS.FAVORITES,
			icon: 'favorites',
			features: counters?.favorites || 0,
			hasSeparator: true,
		},
		{
			path: `${URLS.BRAINSTORMS}?filter=highlights&sort=last-first`,
			icon: 'brainstorms',
			features: counters?.brainstorms || 0,
		},
		{
			path: `${URLS.CHALLENGES}?filter=highlights&sort=last-first`,
			icon: 'challenges',
			features: counters?.challenges || 0,
		},
		{
			path: `${URLS.IDEAS}?filter=highlights&sort=last-first`,
			icon: 'ideas',
			hasSeparator: true,
			features: counters?.ideas || 0,
		},
		{
			path: URLS.TEAMS,
			icon: 'teams',
			features: counters?.teams || 0,
		},
		{
			path: URLS.DRAFTS,
			icon: 'drafts',
			features: counters?.drafts || 0,
		},
		{
			path: t('urls.zendesk'),
			icon: 'help',
			target: '_blank',
		},
	];

	items.forEach((item) => {
		item.label = t(`global.${item.icon}`);
	});

	const _organizations = userOrganizationProfile?.organizations?.map((organization: IOrganization) => (
		<ListItem
			sx={{ paddingX: 0, justifyContent: 'center' }}
			id={`org_sidebar_${organization._id}`}
			key={organization._id}
		>
			<Tooltip classes={{ popper: 'primary' }} arrow title={organization.nome} placement="right">
				<Chip
					className="s-chip s-workspace"
					label={(organization.nome || '')?.getInitials()}
					variant={organization._id === userOrganizationProfile.currentTenant ? 'filled' : 'outlined'}
					onClick={(e) => {
						if (organization._id === userOrganizationProfile.currentTenant) {
							return;
						}
						e.preventDefault();
						dispatch(Creators.USER_ORGANIZATION.changeOrganization(organization._id));
					}}
				/>
			</Tooltip>
		</ListItem>
	)) || [];

	return (
		<SwipeableDrawer
			anchor="left"
			open={open}
			onClose={() => setOpen(false)}
			onOpen={() => setOpen(true)}
			variant="persistent"
		>
			<Box className="main__sidebar" sx={{ display: 'flex', flexDirection: 'column' }}>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between',
						height: 'calc(var(--header-height) - 72px)',
						backgroundColor: 'var(--primary)',
						alignItems: 'center',
						paddingX: '1rem',
						position: 'relative',
						img: {
							width: { xs: '117px' },
							height: { xs: '30px' },
						},
					}}
				>
					<a href="/">
						<img src="/images/logo.svg" alt="logo" />
					</a>
					<IconButton onClick={() => setOpen(false)} sx={{ color: '#ffffff' }}>
						<MdMenuOpen />
					</IconButton>

					<SLink
						underline="none"
						href="# "
						sx={{
							color: 'var(--charcoal-color)',
							cursor: 'pointer',
							right: '-70px',
							position: 'absolute',
							opacity: open ? 1 : 0,
							animationDelay: '.2s',
							'&:hover': {
								fontWeight: '700',
							},
						}}
						// @ts-ignore
						onClick={() => navigate(URLS[pathname?.split('/')[1]?.toUpperCase()])}
					>
						{title}
					</SLink>
				</Box>
				<Box display={{ display: 'flex' }}>
					<Box id="sidebar--workspaces">
						<List className="workspaces-list">
							{_organizations}
							<ListItem
								sx={{ justifyContent: 'center' }}
								id="workspace_add"
								key={_organizations?.length}
								onClick={onAddOrganization}
							>
								<Tooltip classes={{ popper: 'primary' }} arrow title={t('sidebar.organizationsSidebar.addNew')} placement="right">
									<IconButton
										sx={{
											height: '30px',
											width: '30px',
											color: '#fff',
										}}
									>
										<AiOutlinePlus />
									</IconButton>
								</Tooltip>
							</ListItem>
						</List>
					</Box>
					<nav id="sidebar">
						<ul>
							{items.map((item) => {
								const className = location.pathname === item.path ? 'active' : '';
								return (

									<li key={item.icon}>

										<a
											id={`${item.icon}-sidebar`}
											href={item.path}
											target={item.target}
											className={className}
											rel="noopener noreferrer"
										>
											<div style={styles.iconContainer}>
												<Icon icon={item.icon} />
											</div>
											<div className="ml-10px">
												{item.label}
												{typeof item.features === 'number' && (
													<span className="nav__features_counter">
														{item.features}
													</span>
												)}
											</div>
										</a>
										{item.hasSeparator && <div className="divisor" />}
									</li>
								);
							})}
						</ul>
						<a href="https://senno.ai/" target="_blank" rel="noreferrer">
							<div className="sidebar__sennoLogo" />
						</a>
					</nav>
				</Box>
			</Box>
		</SwipeableDrawer>
	);
};

const Translator = withTranslation('common')(SSidebar);

const Sidebar = (props: SidebarProps) => (
	<React.Suspense fallback={<div />}>
		<Translator {...props} />
	</React.Suspense>
);

export default Sidebar;
