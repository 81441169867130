// @ts-nocheck
import { createActions, createReducer } from 'reduxsauce';
import { IFormsActions, IFormsState } from './types';

export const formsActions: IFormsActions = createActions({
	deleteForm: ['params'],
	insertForm: ['params'],
	updateForm: ['params'],
	handleField: ['p'],
	restoreForm: ['p'],
	getForms: ['p'],
	clearForms: [''],
	setForm: ['form'],
});

const { Types } = formsActions;

export const INITIAL_STATE: IFormsState = {

};

const handleField = (state = INITIAL_STATE, action) => {
	const { key, value } = action.p;

	state.fields[key].value = value;
	delete state.fields[key].error;

	return {
		...state,
	};
};

const updateForm = (state = INITIAL_STATE, action) => {
	const { key, value } = action.params;
	return {
		...state,
		[key]: value,
	};
};

const setForm = (state = INITIAL_STATE, action) => ({
	...action.form,
});

/** Default functions */
const defaultForms = (state = INITIAL_STATE) => ({ ...state });
const clearForms = () => ({});

export default createReducer(INITIAL_STATE, {
	[Types.DELETE_FORM]: defaultForms,
	[Types.INSERT_FORM]: defaultForms,
	[Types.UPDATE_FORM]: updateForm,
	[Types.RESTORE_FORM]: defaultForms,
	[Types.GET_FORMS]: defaultForms,
	[Types.HANDLE_FIELD]: handleField,
	[Types.CLEAR_FORMS]: clearForms,
	[Types.SET_FORM]: setForm,
});
