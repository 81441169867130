// @ts-nocheck

// https://carrotsearch.com/foamtree/

import React, { useEffect, useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { FoamTree } from '@carrotsearch/foamtree';
import { useDispatch } from 'react-redux';
import { Creators } from '@actions';
import routes from '@routes/modal';
import { persistor } from 'src/store';
import Icon from '@components/Shared/Icon';
import { SCheckbox } from '@components/DynamicForm/ComponentsCore';
import { SButton } from '@components/Buttons';
import { IArticle, ICoordinates, IPaginatedRequest } from 'src/@types/IShared';
import FiltersNavBar from '@components/NavBar/FiltersNavBar';
import { SBox } from '@components/Background/SBox';
import { Box } from '@mui/material';
import { GroupGraph } from './GroupGraph';

interface ITreeMapProps {
	isLoading?: boolean;
	groups: []
	classes?: { zoomIn?: string, zoomOut?: string }
}

const foamColors = [
	'rgba(243, 157, 165, 1)', 'rgba(230, 216, 189, 1)',
	'rgba(127, 181, 160, 1)', 'rgba(145, 209, 218, 1)',
	'rgba(76, 122, 144, 1)', 'rgba(238, 195, 0, 1)',
	'rgba(151, 60, 118, 1)',
];

declare type TProps = ITreeMapProps & WithTranslation

const _TreeMap = (props: TProps) => {
	const {
		groups, isLoading, t, classes,
	} = props;

	const dispatch = useDispatch();
	const [expanded, setExpanded] = useState(classes?.zoomOut);

	const [article, setArticle] = useState<IArticle>();
	const [group, setGroup] = useState({});

	const [contentType, setContentType] = useState({ article: true, patent: true });

	const [state, setState] = useState({
		resizeHandler: null,
		carrotSearchFoamTree: null,
	});

	const mapTree = document.getElementById('map-tree');

	// const tooltip = () => {
	// 	const tip = new Tooltips(document.getElementById('maptreecanvas'));

	// 	console.log('TIPS', tip);

	// 	tip.content("<h2 style='margin: 0'>Sticky tooltip</h2>");

	// 	let containerWidth, containerHeight;
	// 	let offsetX, offsetY;

	// 	const hide = () => {
	// 		tip.hide();
	// 	};

	// 	const position = (x: number, y: number) => {
	// 		if (x < 0 || y < 0 || x > containerWidth || y > containerHeight) {
	// 			// Hide if outside of the container
	// 			tip.hide();
	// 		} else {
	// 			tip.show();
	// 			tip.position(x + offsetX, y + offsetY);
	// 		}
	// 	};

	// 	const show = () => {
	// 		tip.show();
	// 	};

	// 	const relativeTo = (element) => {
	// 		const rect = element.getBoundingClientRect();
	// 		offsetX = rect.left;
	// 		offsetY = rect.top;
	// 		containerWidth = rect.width;
	// 		containerHeight = rect.height;
	// 	};

	// 	return {
	// 		hide,
	// 		show,
	// 		position,
	// 		relativeTo,
	// 	};
	// };

	/**
	 * Set resizeHandler after carrotSearchFoamTree being set
	 */

	// useEffect(() => {
	// 	tooltip();
	// 	// Position tooltips relative to visualization area
	// 	tooltip.relativeTo(document.getElementById('map-tree'));
	// }, []);

	useEffect(() => {
		if (!state.carrotSearchFoamTree) return;

		setState((prevState) => ({
			...prevState,
			resizeHandler() {
				if (!state.carrotSearchFoamTree) return;
				window.setTimeout(state.carrotSearchFoamTree.resize, 100);
			},
		}));
	}, [state.carrotSearchFoamTree]);

	/**
	 * Add resizeHandler to resize event after the handler have been set
	 */
	useEffect(() => {
		if (!state.resizeHandler) return;
		console.log('useEffect resizeHandler');
		window.removeEventListener('resize', state.resizeHandler);
		window.addEventListener('resize', state.resizeHandler);
	}, [state.resizeHandler]);

	/**
	 * Did update
	 */

	// view-source:https://get.carrotsearch.com/foamtree/latest/demos/label-editing.html

	const showTooltip = ({ x, y }: ICoordinates, info, cSFT) => {
		const tooltip = document.getElementById('tooltip-foam');
		const tooltipArrow = document.querySelector('#tooltip-foam #tooltip-foam-arrow');

		const mapTreeBounding = document.getElementById('map-tree')?.getBoundingClientRect();
		const offsetX = mapTreeBounding.left;
		const offsetY = mapTreeBounding.top;
		const containerWidth = mapTreeBounding.width;
		const containerHeight = mapTreeBounding.height;

		const tooltipArrowBounding = tooltipArrow.getBoundingClientRect();
		tooltip.style.width = `${Math.floor(mapTreeBounding.width / 2) - 10}px`;
		tooltip.style.maxWidth = '450px';

		const spacing = 50;

		document.addEventListener('mousemove', (event: MouseEvent) => {
			tooltip.style.top = `${event.clientY - offsetY + spacing + 30}px`;
			tooltip.style.left = `${event.clientX - offsetX + spacing}px`;
		});

		// Position

		if (x < 0 || y < 0 || x > containerWidth || y > containerHeight) {
			// Hide if outside of the container
			console.log('HERE');
			// tip.hide();
		} else {
			// tip.show();

		}

		// Measure the offset of FoamTree container
		const { offsetLeft, offsetTop } = cSFT.get('element');

		// Compute the position of the tooltip
		// const { geometry } = info.group;

		// If mouse is after half width of canvas
		if (x > mapTreeBounding.width / 2) {
			const cursorRight = (offsetX + x + tooltipArrowBounding.width) / 2;
			const tooltipRight = (cursorRight);
			tooltipArrow.style.right = `${tooltipRight}px`;
			// newStyle.transform = 'translateX(50%)';
			if (tooltipRight >= cursorRight) {
				tooltipArrow.style.right = `${tooltipRight - cursorRight}px`;
			} else {
				tooltipArrow.style.right = '0px';
			}
		} else {
			const cursorLeft = mapTreeBounding.x + x - tooltipArrowBounding.width / 2;
			const tooltipLeft = cursorLeft;
			tooltipArrow.style.left = `${tooltipLeft}px`;
			// newStyle.transform = 'translateX(-50%)';
			if (tooltipLeft >= cursorLeft) {
				tooltipArrow.style.left = '0px';
			} else {
				tooltipArrow.style.left = `${cursorLeft - tooltipLeft}px`;
			}
		}

		// If mouse is after half height of canvas
		if (y > mapTreeBounding.height / 2) {
			tooltipArrow.style.bottom = `${(mapTreeBounding.height - y) + 15}px`;
			tooltipArrow.classList.add('down');
		} else {
			tooltipArrow.style.top = `${y + tooltip.offsetHeight - 30}px`;
			tooltipArrow.classList.add('up');
		}

		if (tooltipArrow) {
			tooltipArrow.classList.remove('up', 'down', 'left', 'right');
		}

		// CarrotSearchFoamTree.geometry.polygonCentroid(polygon)

		const tooltipInnerGroup = document.querySelector('#tooltip-foam .inner__group');
		const tooltipInnerArticle = document.querySelector('#tooltip-foam .inner__article');

		tooltip?.classList.add('d-block');

		if (info.group?.groups) {
			tooltipInnerArticle?.classList.remove('d-block');
			tooltipInnerArticle?.classList.add('d-none');
			tooltipInnerGroup?.classList.remove('d-none');
			tooltipInnerGroup?.classList.add('d-block');
			setGroup(info.group);
		}

		// article

		if (!info.group?.groups) {
			tooltipInnerGroup?.classList.add('d-none');
			tooltipInnerGroup?.classList.remove('d-block');
			tooltipInnerArticle?.classList.remove('d-none');
			tooltipInnerArticle?.classList.add('d-block');
			setArticle(info.group);
		}

		tooltip?.classList.remove('d-none');
		tooltip?.classList.add('d-block');

		if (!tooltip) {
			return;
		}

		const tooltipArrowRect = tooltipArrow?.getBoundingClientRect();
		tooltipArrowRect.width = 14;
		const arrowStyle = { right: '', left: '' };

		if (tooltipArrow) {
			tooltipArrow.classList.remove('up', 'down', 'left', 'right');
			// tooltipArrow.classList.add(arrowClass);
			Object.assign(tooltipArrow.style, arrowStyle);
		}

		// Set newStyle
		// Object.assign(tooltip.style, style);

		// console.log('tooltip', tooltip.style)
	};

	const hideTooltip = () => {
		const tooltip = document.getElementById('tooltip-foam');

		if (tooltip) {
			tooltip?.classList.remove('d-block');
			tooltip?.classList.add('d-none');
		}
	};

	useEffect(() => {
		mapTree?.addEventListener('mouseleave', () => {
			hideTooltip();
		});
	});

	useEffect(() => {
		if (!groups || groups?.length < 1) {
			console.log('FoamTree have no data');
			return;
		}
		setState((prevState) => {
			let cSFT;
			try {
				cSFT = new FoamTree({
					id: 'map-tree',
					pixelRatio: window.devicePixelRatio || 1,
					dataObject: {
						name: 'categories', groups, trendsStatus: 'analyzed', weight: 13,
					},
					groupLabelDecorator: (opts, props, vars) => {
						if (vars.labelText) {
							vars.labelText = vars.labelText?.capitalize();
						}
					},
					// The code FoamTree will call to do the custom drawing
					groupContentDecorator: (opts, props, vars) => {
						// If there's no data to draw, do nothing
						const { group } = props;

						group.tooltipAnchorX = props.labelBoxLeft + props.labelBoxWidth / 2;
						group.tooltipAnchorY = props.labelBoxTop - 20;

						if (!group.groups) return;

						if (props.level === 0 && foamColors?.length) {
							group.color = foamColors[Math.round(Math.random() * foamColors.length - 1)];
						}

						// The center of the polygon
						const {
							polygonCenterX, polygonCenterY,
						} = props;

						// https://get.carrotsearch.com/foamtree/demo/api/index.html#geometry.boundingBox

						group.geometry = {
							boxHeight: props.boxHeight,
							boxTop: props.boxTop,
							boxLeft: props.boxLeft,
							boxWidth: props.boxWidth,
							polygonArea: props.polygonArea,
							polygonCenterX: props.polygonCenterX,
							polygonCenterY: props.polygonCenterY,
						};

						// vars.groupLabelDrawn = false;

						const isMobile = screen.width <= 1080;
						const isFirefox = window.navigator.userAgent.indexOf('Firefox') >= 0;
						// if (!group.item) {
						// 	return;
						// }

						// Initialize the per-group state
						const iconCrossFadeFrames = isMobile ? 1 : 10;
						if (typeof group.loadingAnimationFrame === 'undefined') {
							// The current frame of the per-polygon icon loading indicator animation
							group.loadingAnimationFrame = 0;

							// The number of indicator->icon cross-fade animation frames left
							group.iconCrossFadeFrame = iconCrossFadeFrames;

							// The text layout cache
							group.cache = {};
						}

						if (props.shapeDirty) {
							// If the shape of the polygon changed, we need to recompute the
							// layout of the polygon's contents. If the shape didn't change,
							// (e.g. when the user zooms or pans around), we'll just render
							// the layout we prepared here.
							prepare();
						}

						// Render the contents
						render();

						/**
						 * Lays out the custom content of the polygon. The layout is:
						 * owner's avatar in the top-left corner, short repository stats
						 * in the top-right corner and the repository full name in the
						 * bottom part of the polygon. If the polygon is very small,
						 * we'll only render the icon in the center.
						 */
						function prepare() {
							// Reference to the data object this polygon represents

							const {
								polygonArea, polygon, context,
							} = props;

							// Defines the alignment of the icon box relative to the polygon center
							// See the documentation of CarrotSearchFoamTree.geometry.rectangleInPolygon
							// for more details and examples.
							let fx; let
								fy;

							// If the area of the polygon is less than 1024 pixels, we'll render only the icon.
							group.fullInformationRendered = polygonArea >= 1024;
							if (group.fullInformationRendered) {
								// Put the icon box's alignment point towards the bottom-right
								// of the box. This will shift the box towards top-left to
								// make some space for the stats and the repository name.
								fx = 1.2;
								fy = 0.75;
							} else {
								// Put the icon box's alignment point in the center of the box,
								// it will make it centered exactly around the polygon's center.
								fx = 0.5;
								fy = 0.5;
							}

							// Keep the alignments for the future
							group.fx = fx; group.fy = fy;

							// Compute the rectangle into which we'll render the image. Icons are square,
							// so the width-to-height ratio is 1.0.
							const widthToHeightRatio = 1.0;

							// Compute the largest box that is inside the polygon and meets the alignment
							// criteria we set above. In the same call, scale the polygon down by 20% so that
							// there is some padding between the icon and the polygon boundary
							const iconBox = CarrotSearchFoamTree.geometry.rectangleInPolygon(
								polygon, // fit inside our main polygon
								polygonCenterX,
								polygonCenterY, // the reference point is the polygon's center
								widthToHeightRatio,
								0.8, // scale down by 20% to ensure some padding
								fx,
								fy, // pass the alignment of the reference point
							);

							// Keep the box's coordinates for rendering
							group.iconBox = iconBox;

							// If we're displaying the full information, lay out the stats and repository name
							if (group.fullInformationRendered) {
								// Compute how much height was left for the repository name.
								// This is the distance between the bottom edge of the polygon's box
								// and the bottom edge of the icon box we just computed.
								const heightLeftForName = props.boxTop + props.boxHeight - (iconBox.y + iconBox.h);

								// Lay out the repository name text.
								// Since we're only preparing the layout and not doing the actual drawing,
								// here we'll "draw" the label to a scratch buffer that the rendering function
								// will actually render.

								// If name does not fit, center the icon and stats vertically.
								// This will happen very rarely, if at all.

								group.iconBox = CarrotSearchFoamTree.geometry.rectangleInPolygon(
									polygon,
									polygonCenterX,
									polygonCenterY,
									widthToHeightRatio,
									0.8,
									fx,
									0.5, // this time we center the icon box vertically
								);
								// Lay out the stats part (the top-right corner) into another scratch buffer
								group.statsCtx = context.scratch();

								const createImage = (url: string) => {
									const img = new Image();
									img.src = url;
									return img;
								};

								group.iconProperties = {
									size: isFirefox
										? Math.round((group.iconBox.h * 0.35) / 2) * 2
										: group.iconBox.h * 0.35,
									position: {
										x: group.iconBox.x + group.iconBox.w,
										y: group.iconBox.y + group.iconBox.h,
									},
								};

								// https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/drawImage

								group.statsCtx.drawImage(
									createImage('/images/chart-line.svg'),
									group.iconProperties.position.x,
									group.iconProperties.position.y, // the second row of stats
									group.iconProperties.size,
									group.iconProperties.size,
								);
							}
						}

						/**
						 * Actually draws the layout we earlier computed.
						 */
						function render() {
							// The polygon's center
							const cx = props.polygonCenterX;
							const cy = props.polygonCenterY;

							// The context we need to draw to
							const ctx = props.context;

							// The icon box alignment point we computed
							const { fx, fy } = group;

							// Draw the contents
							if (group.fullInformationRendered) {
								// Draw the stats
								group.statsCtx.replay(ctx);
							}

							// Draw the contents
							if (group.fullInformationRendered) {
								// Draw the stats
								group.statsCtx.replay(ctx);
							}
						}
					},
					onGroupMouseMove(info) {
						if (!info.group || info.group?.attribution) {
							hideTooltip();
							return;
						}

						// Article
						if (!info.group.groups) {
							// tooltip.show();
							showTooltip({ x: info.group.tooltipAnchorX, y: info.group.tooltipAnchorY }, info, cSFT);
						}

						if (info.group.groups) {
							// tooltip.show();
							showTooltip({ x: info.group.tooltipAnchorX, y: info.group.tooltipAnchorY }, info, cSFT);
						}
					},
					// Leave
					onGroupHover(info) {
						if (!info.group || info.group?.attribution) {
							hideTooltip();
						}

						// showTooltip({ x: info.group.tooltipAnchorX, y: info.group.tooltipAnchorY }, info, cSFT);
					},

					// Hide the tooltip on zoom, open/close and expose
					onGroupMouseWheel: hideTooltip(),
					onGroupExposureChanging: hideTooltip(),
					onGroupOpenOrCloseChanging: hideTooltip(),

					// onGroupSelectionChanging: function(info) {
					//     console.log('onGroupSelectionChanging');
					//     console.log(info);
					// },
					// onGroupSelectionChanged: function(info) {
					//     console.log('onGroupSelectionChanged');
					//     console.log(info);
					// },

					onGroupClick(e) {
						cSFT.expose({
							groups: e.group,
							keepPrevious: false,
						}).then(() => {
							cSFT.select(e.group);
						});
						if (!e.group) {
							cSFT.open({
								all: true,
								open: false,
							});
						} else if (!e.bottommostOpenGroup) {
							cSFT.open({
								all: true,
								open: false,
							}).then(() => {
								cSFT.open([e.group]);
							});
						} else if (!e.group.groups) {
							// last group
							persistor.pause();
							dispatch(Creators.MODAL.showModal(routes.MODAL_TREE_MAP.path, { article: e.group }));

							// const dotsDivs = articleInfo.querySelectorAll('.relevance .dot');
							// dotsWidths.forEach((dotWidth, index) => {
							// 	if (!dotsDivs[index]) return;
							// 	dotsDivs[index].style.cssText = `--width:${dotWidth * 100}%;`;
							// });
							// articleInfo.style.zIndex = '100';
							// setTimeout(() => {
							// 	articleInfo.style.opacity = '1';
							// }, 100);
						} else {
							cSFT.open([e.group]);
						}

						/*
												if (!e.group || this.get("state", e.group).exposure <= 0) {
													this.set("exposure", null);
												}
												*/
					},
					/// ///////////
					// Layout
					/// ///////////

					onRedraw() {
						const group = this.get('dataObject').groups[0];

						hideTooltip();

						// Get container-relative coordinates
						const containerCoordinates = this.get('containerCoordinates', group, { x: group.tooltipAnchorX, y: group.tooltipAnchorY });
						if (group.containerX !== containerCoordinates.x || group.containerY != containerCoordinates.y) {
							// Update position if coordinates changed
							// tooltip.position(containerCoordinates.x, containerCoordinates.y);
							if (group.containerX === undefined) {
								// tooltip.show();
								// showTooltip({ x: containerCoordinates.x, y: containerCoordinates.y }, group, cSFT);
							}
							group.containerX = containerCoordinates.x;
							group.containerY = containerCoordinates.y;
						}
					},
					onGroupOpenOrCloseChanged(info) {
						console.log('Close', info);
					},

					groupColorDecorator(opts, params, vars) { // We change the color only for top-level groups. For child groups,
						// FoamTree will generate brightness variations of the parent group's color
						if (params.level === 0 && params.group?.color) {
							vars.groupColor.model = 'rgb';
							vars.groupColor = params.group.color;
						}
					},

					// groupMinDiameter: 50, Não setar esta propriedade, senão os níveis mais internos não aparecem
					rectangleAspectRatioPreference: -1.27,
					descriptionGroupPosition: 255,
					descriptionGroupSize: 0.09,
					/// ///////////
					// Relaxation
					/// ///////////
					relaxationInitializer: 'random',
					relaxationMaxDuration: 0,
					relaxationQualityThreshold: 0,
					// relaxationVisible: true, Não setar esta propriedade, senão os níveis mais internos não aparecem
					/// ///////////
					// Group borders
					/// ///////////
					groupBorderRadius: 0.25,
					groupBorderWidth: 2,
					groupBorderWidthScaling: 0.4,
					groupInsetWidth: 3,
					groupBorderRadiusCorrection: 2,
					/// ///////////
					// Group Selection
					/// ///////////
					// groupSelectionOutlineColor: "hsla(60, 0%, 100%, 01)",
					groupSelectionOutlineColor: '#000',
					groupSelectionOutlineWidth: 1,
					// groupSelectionOutlineShadowColor: "hsla(60, 0%, 0%, 0.3)",
					groupSelectionOutlineShadowColor: '#000',
					groupSelectionOutlineShadowSize: 0,
					groupSelectionFillSaturationShift: -100,
					groupSelectionFillLightnessShift: 100,
					groupSelectionStrokeLightnessShift: -32,
					groupSelectionFillHueShift: 0,
					groupSelectionStrokeHueShift: 0,
					groupSelectionStrokeSaturationShift: 0,
					/// ///////////
					// Group fill
					/// ///////////
					groupFillType: 'plain',
					groupFillGradientRadius: 0.96,
					groupFillGradientCenterHueShift: -3,
					groupFillGradientCenterSaturationShift: 0,
					groupFillGradientCenterLightnessShift: 20,
					groupFillGradientRimHueShift: 0,
					groupFillGradientRimSaturationShift: 0,
					groupFillGradientRimLightnessShift: -5,
					/// ///////////
					// Group stroke
					/// ///////////
					groupStrokeType: 'gradient',
					groupStrokeWidth: 0,
					groupStrokePlainLightnessShift: 100,
					groupStrokeGradientLowerLightnessShift: 0,
					groupHoverFillLightnessShift: 25,
					/// ///////////
					// Group exposure
					/// ///////////
					groupExposureScale: 1.1,
					groupExposureShadowColor: 'hsla(60, 0%, 100%, 1)',
					groupExposureShadowSize: 0,
					groupExposureZoomMargin: 0.05,
					exposeDuration: 600,
					exposeEasing: 'cubicInOut',
					/// ///////////
					// Group opening/closing
					/// ///////////
					openCloseDuration: 0,
					groupUnexposureLightnessShift: -28,
					groupUnexposureSaturationShift: -100,
					groupUnexposureLabelColorThreshold: 0,
					/// ///////////
					// Group colors
					/// ///////////
					rainbowStartColor: 'hsla(187, 50%, 71%, 1)',
					rainbowEndColor: 'hsla(199, 31%, 43%, 1)',
					rainbowColorDistribution: 'radial',
					rainbowColorDistributionAngle: -54,
					rainbowLightnessDistributionAngle: 18,
					rainbowSaturationCorrection: 0.5,
					rainbowLightnessCorrection: 0.2,
					rainbowLightnessShift: 17,
					rainbowLightnessShiftCenter: 0.4,
					/// ///////////
					// Group hierarchy
					/// ///////////
					parentFillOpacity: 0.85,
					/// ///////////
					// Rendering
					/// ///////////
					maxGroupLevelsDrawn: 100,
					maxGroupLabelLevelsDrawn: 100,
					wireframeDrawMaxDuration: 600,
					finalCompleteDrawMaxDuration: 601,
					finalIncrementalDrawMaxDuration: 300,
					/// ///////////
					// Group labels
					/// ///////////
					groupLabelFontFamily: 'Roboto',
					groupLabelFontWeight: '300',
					groupLabelLineHeight: 1,
					groupLabelMaxFontSize: 37,
					groupLabelMaxTotalHeight: 0.55,
					groupLabelUpdateThreshold: 0,
					groupLabelDarkColor: 'hsla(60, 0%, 0%, 1)',
					groupLabelColorThreshold: 0,
					/// ///////////
					// Rollout
					/// ///////////
					rolloutEasing: 'squareInOut',
					rolloutScalingStrength: -0.3,
					rolloutRotationStrength: 0,
					/// ///////////
					// Pullback
					/// ///////////
					pullbackEasing: 'squareInOut',
					pullbackDuration: 2000,
					pullbackScalingStrength: -0.3,
					pullbackRotationStrength: 0,
					pullbackPolygonDelay: 0.1,
					/// ///////////
					// Fading
					/// ///////////
					fadeDuration: 0,
					/// ///////////
					// Title Bar
					/// ///////////
					maxLabelSizeForTitleBar: 0,
					titleBarMinFontSize: 0,
					titleBarMaxFontSize: 25,
					titleBarTextPaddingLeftRight: 0,
					/// ///////////
					// Attribution
					/// ///////////
					attributionPosition: 45,
					/// ///////////
					// Zoom
					/// ///////////
					zoomMouseWheelDuration: 20,
				});
			} catch (error) {
				console.log('FoamTree Error', error);
			}
			if (cSFT) {
				console.log('cSFT');
				console.log(cSFT);
			}
			return {
				...prevState,
				carrotSearchFoamTree: cSFT,
			};
		});
	}, [groups]);

	const onFilterHandler = ({ filter }: IPaginatedRequest) => {
		setContentType((prevState) => ({ ...prevState, [filter]: !prevState[filter] }));
	};

	const getFilterLabel = () => {
		if (contentType.article && !contentType.patent) {
			return `${t('trends.contentType.article')}s`;
		}
		if (contentType.patent && !contentType.article) {
			return `${t('trends.contentType.patent')}s`;
		}

		return t('global.all');
	};

	return (
		<SBox
			className={expanded || classes?.zoomOut || ''}
			loading={isLoading}
			empty={groups.length < 1 ? 'trends' : undefined}
		>
			<Box
				id="maptreecanvas"
				className="tree__map"
				sx={{
					backgroundColor: '#fff',
					padding: '0.938rem',
				}}
			>
				<Box
					className="map__header"
					sx={{
						display: 'flex',
						justifyContent: 'space-between',
					}}
				>
					<div className="map__header--left">
						<Box className="map__title" paddingBottom="0.5rem">
							{t('trends.map')}
						</Box>
						{expanded === classes?.zoomIn && (
							<Box className="content__infos" paddingBottom="0.5rem">
								<span className="">{t('trends.resultsBySource', { length: groups.length })}</span>
								<span className="mx-1">| </span>
								<span className="">{`100 ${t('trends.contentType.article')}s, 10 ${`${t('trends.contentType.patent')}s`}`}</span>
							</Box>
						)}
					</div>
					<Box className="map__header--right" sx={{ display: 'flex' }}>
						{expanded === classes?.zoomIn && (
							<FiltersNavBar
								id="tree-map-filters"
								params={{ filter: '' }}
								// onUpdate={onFilterHandler}
								sorts={[]}
								filters={[
									{
										value: 'article',
										label: (
											<SCheckbox
												id="article-filter"
												value="article"
												name="article"
												className="article"
												checked={contentType.article === true}
												label={`${t('trends.contentType.article')}s`}
												onChange={(e) => onFilterHandler({ filter: e.target.name })}
											/>
										),
									},
									{
										value: 'patent',
										label: (
											<SCheckbox
												id="patent-filter"
												value="patent"
												className="patent"
												name="patent"
												label={`${t('trends.contentType.patent')}s`}
												checked={contentType.patent === true}
												onChange={(e) => onFilterHandler({ filter: e.target.name })}
											/>
										),
									},
								]}
								label={getFilterLabel()}
							/>
						)}
					</Box>
				</Box>
				<div id="map-tree" style={{ width: '100%', height: '80vh' }} />
				<Box
					className="map-footer"
					sx={{
						display: 'flex',
						alignItems: 'center',
						marginTop: '3px',
					}}
				>
					<SButton
						type="button"
						color="primary"
						onClick={() => {
						// state.resizeHandler();
						// https://get.carrotsearch.com/foamtree/demo/api/index.html#reset
							state.carrotSearchFoamTree.reset();
						}}
					>
						<Icon icon="fa fa-arrow-rotate-left" />
						{t('form.restore')}
					</SButton>
					<SButton
						type="button"
						disabled={isLoading}
						color="primary"
						className="ml-auto"
						onClick={() => {
							if (expanded === classes?.zoomIn) {
								setExpanded(classes?.zoomOut);
							} else {
								setExpanded(classes?.zoomIn);
							}
							state.resizeHandler();
							state.carrotSearchFoamTree.redraw();
						}}
					>
						<Icon
							className="mr-0"
							icon={expanded === classes?.zoomIn
								? 'fa fa-minimize' : 'fa fa-expand'}
						/>
					</SButton>
				</Box>
				<div id="tooltip-foam" className="tooltip__foam d-none" style={{ position: 'absolute' }} role="tooltip">
					<div className="tooltip-inner d-none inner__group">
						<div className="title group__title">
							<span className="trends__category article">{`10 ${t('trends.contentType.article')}s`}</span>
							<Icon icon="fa fa-circle" />
							<span className="trends__category patent">{`20 ${t('trends.contentType.patent')}s`}</span>
						</div>
						<div className="graph__content">
							<span className="year">1988</span>
							<GroupGraph />
						</div>

					</div>
					<div className="tooltip-inner d-none inner__article">
						<div className="article__title">
							{article?.title}
						</div>
						<div className="article__infos">
							{(article?.source && article?.date?.year) && `${article.source} | ${article?.date?.year}`}
							{!article?.source && article?.date?.year && article?.date?.year}
							{article?.source && !article?.date?.year && article.source}
						</div>
						<div className="article__similarity">
							{`${t('global.similarity')}: ${Math.round(article?.similarity)}%`}
						</div>
					</div>
					<div id="tooltip-foam-arrow" className="arrow tooltip-arrow" data-popper-arrow />
				</div>
			</Box>
		</SBox>
	);
};

const TreeMap = withTranslation('common')(_TreeMap);

export default TreeMap;
