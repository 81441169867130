// @ts-nocheck
import { createActions, createReducer } from 'reduxsauce';
import { IModalActions, IModalState } from './types';

/** Creating actions types & creators */

export const modalActions: IModalActions = createActions({
	showModal: ['path', 'props', 'currentPage'],
	showSecondModal: ['path', 'params'],
	hideModal: [''],
	hideSecondModal: ['anchor'],
	backModal: ['props'],
	handleModal: ['params'],
	clearModal: [''],
	handleParams: ['key', 'value'],
});

const { Types } = modalActions;

/** Creating reducer handlers */

export const INITIAL_STATE_MODAL: IModalState = {
	isOpen: false,
	path: undefined,
	ModalProps: {},
	secondPath: undefined,
	paramsSubModal: {},
	currentPage: 0,
};

const showModal = (state = INITIAL_STATE_MODAL, action) => (
	{
		...state,
		isOpen: true,
		path: action.path,
		ModalProps: action.props || {},
		currentPage: action.currentPage || 0,
	}
);

const handleParams = (state = INITIAL_STATE_MODAL, action) => ({
	...state,
	ModalProps: {
		...state.ModalProps,
		[action.key]: action.value,
	},
});

const hideModal = (state = INITIAL_STATE_MODAL, action) => ({
	...state,
	isOpen: false,
	secondPath: null,
	ModalProps: {},
	currentPage: 0,
	path: null,
});

const showSecondModal = (state = INITIAL_STATE_MODAL, action) => ({
	...state,
	secondPath: action.path,
	paramsSubModal: action.params,
});

const hideSecondModal = (state = INITIAL_STATE_MODAL, action) => ({
	...state,
	paramsSubModal: {},
	currentPage: 0,
	secondPath: undefined,
	ModalProps: { ...state.ModalProps, anchor: action.anchor },
});

const backModal = (state = INITIAL_STATE_MODAL, action) => ({
	...state,
	isOpen: true,
	secondPath: undefined,
	path: state.path?.substring(0, state.path.lastIndexOf('/')),
	ModalProps: action.props || {},
	currentPage: 0,
});

const handleModal = (state = INITIAL_STATE_MODAL, { params }) => ({
	...state,
	...params,
});

const clearModal = () => ({});

/** Creating our own reducer */

export default createReducer(INITIAL_STATE_MODAL, {
	[Types.SHOW_MODAL]: showModal,
	[Types.HIDE_MODAL]: hideModal,
	[Types.SHOW_SECOND_MODAL]: showSecondModal,
	[Types.HIDE_SECOND_MODAL]: hideSecondModal,
	[Types.BACK_MODAL]: backModal,
	[Types.HANDLE_MODAL]: handleModal,
	[Types.CLEAR_MODAL]: clearModal,
	[Types.HANDLE_PARAMS]: handleParams,
});
