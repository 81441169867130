// @ts-nocheck
import { withTranslation, WithTranslation } from 'react-i18next';
import React, { Suspense } from 'react';
import SpinnerLDSRipple from '@components/Shared/SpinnerLDSRipple';
import { ISTab, STabs } from '@components/STabs';
import { STabPanel } from '@components/STabs/STabPanel';
import Invoices from './Invoices';
import Plans from './Plans';
import PaymentMethod from './PaymentMethod';
import Customer from './Customer';
import Overview from './Overview';
import { TABS_CLASSNAMES } from '..';

interface IOwnProps {

}

interface IBillingTabs {
	OVERVIEW: ISTab
	INVOICES: ISTab
	SETTINGS: ISTab
	PAYMENT_METHOD: ISTab
	PLANS: ISTab
}

declare type TProps = IOwnProps & WithTranslation

const _Billing = (props: TProps) => {
	const { t } = props;
	const TABS: IBillingTabs = {
		OVERVIEW: { value: 'billing-overview' },
		INVOICES: { value: 'billing-invoices' },
		SETTINGS: { value: 'billing-settings' },
		PAYMENT_METHOD: { value: 'billing-payment-method' },
		PLANS: { value: 'billing-plans' },
	};

	// @ts-ignore
	Object.keys(TABS).forEach((key) => TABS[key].label = t(`settings.tabs.${TABS[key].value}`));

	return (
		<STabs
			tabs={Object.values(TABS)}
			className={TABS_CLASSNAMES}
			active={TABS.OVERVIEW.value}
			id="billing-tabs"
			historyMode="secondary"
			TabsProps={{
				sx: {
					borderBottom: 1,
					borderColor: 'divider',
				},
			}}
			TabPanelProps={{
				sx: {
					marginY: 6,
				},
			}}
		>
			<STabPanel value={TABS.OVERVIEW.value}>
				<Overview />
			</STabPanel>
			<STabPanel value={TABS.INVOICES.value}>
				<Invoices />
			</STabPanel>
			<STabPanel value={TABS.SETTINGS.value}>
				<Customer />
			</STabPanel>
			<STabPanel value={TABS.PAYMENT_METHOD.value}>
				<PaymentMethod />
			</STabPanel>
			<STabPanel value={TABS.PLANS.value}>
				<Plans />
			</STabPanel>
		</STabs>
	);
};

const Translation = withTranslation('common')(_Billing);

const Billing = (props: IOwnProps) => (
	<Suspense fallback={<SpinnerLDSRipple />}>
		<Translation {...props} />
	</Suspense>
);

export default Billing;
