// @ts-nocheck
import React from 'react';
import { useTranslation } from 'react-i18next';
import { normalizesValue } from '@utils/helpers';
import { DynamicCircle } from '@components/DynamicCircle';
import {
	Grid, Tooltip, TooltipProps,
} from '@mui/material';
import SpinnerLDSRipple from '@components/Shared/SpinnerLDSRipple';
import { HelpButton } from '../Buttons/HelpButton';

interface CriteriaTooltipProps extends TooltipProps {
	icon: string
	title: string
	value: string
	criteria: string
}

const CCriteriaTooltip = (props: CriteriaTooltipProps) => {
	const {
		title, icon, value, criteria, ...rest
	} = props;

	const { t } = useTranslation();

	return (
		<Tooltip
			arrow
			{...rest}
			classes={{ popper: 'primary', tooltip: 'box-shadow' }}
			title={(
				<Grid
					container
					padding={1}
					spacing={2}
					sx={{
						alignItems: 'center',
						'.MuiGrid-item': {
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center',
							alignItems: 'center',
						},
						'.h-button': {
							position: 'absolute',
							right: '5px',
							top: '5px',
						},
					}}
				>
					<Grid item xs={12}>
						<HelpButton href={t(`urls.criteria.${criteria}`)} />
						<DynamicCircle
							size="x-large"
							icon={icon}
							className={`evaluation fill ${normalizesValue(value)}`}
						/>
					</Grid>
					<Grid item sx={{ display: 'flex', flexDirection: 'row' }} xs={12}>
						<div className="text-dark mr-1">
							{`${title}: `}
						</div>
						<div className={`evaluation bold ${normalizesValue(value)}`}>
							{t(`evaluation.values.${normalizesValue(value)}`)}
						</div>
					</Grid>
				</Grid>
			)}
		/>
	);
};

export const CriteriaTooltip = (props: CriteriaTooltipProps) => (
	<React.Suspense fallback={<SpinnerLDSRipple />}>
		<CCriteriaTooltip {...props} />
	</React.Suspense>
);
