import { axiosInstance } from '@services/AxiosInstance';

const URL_BASE = '/question';

const APIQuestions = {
	postQuestions(params: any) {
		return axiosInstance.post(`${URL_BASE}`, { ideaId: params.ideaId, questions: params.questions });
	},

	getSavedQuestions(params: any) {
		return axiosInstance.get(`${URL_BASE}?ideaId=${params.ideaId}`);
	},

	deleteSavedQuestion(params: any) {
		return axiosInstance.delete(`${URL_BASE}?id=${params.id}`);
	},
};

export default APIQuestions;
