import React from 'react';
import {
	MdLinearScale, MdRadioButtonChecked, MdCheckBox, MdSubject, MdShortText,
} from 'react-icons/md';
import { FaCaretSquareDown } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

const inputTypes = {
	TEXT_INPUT: 'textinput',
	NUMBER_INPUT: 'numberinput',
	MASKER_INPUT: 'masterinput',
	TEXT_PICKER: 'textpicker',
	TEXT_AREA: 'textarea',
	CHECKBOX: 'checkbox',
	MULTIPLE_CHOICE: 'multiplechoice',
	SELECT_OPTION: 'selectoption',
	RADIO: 'radio',
	UPLOAD: 'upload',
	LINEAR_SCALE: 'linearscale',
};

export type TInputType = 'textinput' | 'textpicker' | 'linearscale' | 'multiplechoice' | 'selectoption'
	| 'upload' | 'checkbox' | 'numberinput' | 'masterinput' | 'textarea' | 'radio';

interface IMenuType {
	icon?: string | React.ReactNode,
	label: string
}
const MenuTypeOption = ({ icon, label }:IMenuType) => {
	const { t } = useTranslation();
	return (
		<>
			{icon && <div className="menu__icon">{icon}</div>}
			<span className="menu__label">{t(label)}</span>
		</>
	);
};

const FormatGroupLabel = () => <hr className="groped" />;

const groupedOptions = [
	{ grouped: <FormatGroupLabel />, value: '', label: '' },
	{
		value: inputTypes.TEXT_INPUT,
		label: <MenuTypeOption icon={<MdShortText />} label={`form.types.${inputTypes.TEXT_INPUT}`} />,
	},
	{
		value: inputTypes.TEXT_AREA,
		label: <MenuTypeOption icon={<MdSubject />} label={`form.types.${inputTypes.TEXT_AREA}`} />,
	},
	{ grouped: <FormatGroupLabel />, value: '', label: '' },
	{
		value: inputTypes.TEXT_PICKER,
		label: <MenuTypeOption icon={<FaCaretSquareDown />} label={`form.types.${inputTypes.TEXT_PICKER}`} />,
	},
	{
		value: inputTypes.MULTIPLE_CHOICE,
		label: <MenuTypeOption icon={<MdCheckBox />} label={`form.types.${inputTypes.MULTIPLE_CHOICE}`} />,
	},
	{
		value: inputTypes.SELECT_OPTION,
		label: <MenuTypeOption icon={<MdRadioButtonChecked />} label={`form.types.${inputTypes.SELECT_OPTION}`} />,
	},
	{ grouped: <FormatGroupLabel />, value: '', label: '' },
	{
		value: inputTypes.LINEAR_SCALE,
		label: <MenuTypeOption icon={<MdLinearScale />} label={`form.types.${inputTypes.LINEAR_SCALE}`} />,
	},
	// {
	//   value: inputTypes.UPLOAD,
	//   label: 'Upload',
	//   icon: <MdCloudUpload />,
	// }

];

const defaultField = {
	label: '',
	required: false,
	value: '',
};

// TODO: REVISAR E DEFINIR VALORES DEFAULT
const fieldGenerator = (type: TInputType) => {
	switch (type) {
		case inputTypes.TEXT_INPUT:
		case inputTypes.TEXT_AREA:
		case inputTypes.NUMBER_INPUT:
			return {
				...defaultField,
				inputype: type,
			};
		case inputTypes.RADIO:
		case inputTypes.TEXT_PICKER:
		case inputTypes.SELECT_OPTION:
			return {
				...defaultField,
				inputype: type,
				options: [{ value: '', label: '' }],
			};
		case inputTypes.LINEAR_SCALE:
			return {
				label: '',
				required: false,
				inputype: type,
				value: '',
				min: '0',
				max: '10',
			};
		case inputTypes.CHECKBOX:
		case inputTypes.MULTIPLE_CHOICE:
			return {
				inputype: type,
				label: '',
				required: false,
				value: [],
				options: [{ value: '', label: '' }],
			};
		case inputTypes.UPLOAD:
		default:
			return {
				...defaultField,
				inputype: type,
			};
	}
};

export { inputTypes, groupedOptions, fieldGenerator };
