import React from 'react';
import { EmptyState, TEmptyState } from '@components/Shared/EmptyState';
import SpinnerLDSRipple from '@components/Shared/SpinnerLDSRipple';
import { Box, BoxProps } from '@mui/material';
import PageLoader from '@components/Loaders/PageLoader';

interface SBoxProps extends BoxProps {
  loading?: boolean
  modus?: 'page' | 'box'
	empty?: TEmptyState
}
const SBox = ({
	children, loading, sx, className = '', modus, empty, ...rest
}: SBoxProps) => (
	<Box
		className={`s-box ${className}`}
		width="100%"
		sx={loading ? {
			minHeight: '20vh', justifyContent: 'center', display: 'flex', alignItems: 'center',
		} : sx}
		{...rest}
	>
		{loading && <Box>{modus === 'page' ? <PageLoader /> : <SpinnerLDSRipple /> }</Box>}
		{!loading && typeof empty === 'string' && (
			<EmptyState type={empty} />
		)}
		{!loading && !empty && children}
	</Box>
);

export { SBox };
