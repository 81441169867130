// @ts-nocheck
import React, {
	useState, ChangeEvent, useEffect, Suspense,
} from 'react';
import { Creators } from '@actions';
import * as Yup from 'yup';
import { Form, SFormGroup, SInputLabel } from '@components/DynamicForm/ComponentsCore';
import { SButton } from '@components/Buttons';
import { STextField, SPasswordField } from '@forms/Components';
import {
	getUserOptions, IUserOption, Tags, UserTag,
} from '@components/DynamicForm/Components/SSelect/Components';
import { SAutocomplete } from '@components/DynamicForm/Components/SSelect/SAutocomplete';
import {
	Box, DialogActions, DialogContent, DialogTitle, InputLabel,
} from '@mui/material';
import { APIOrganizations } from '@services/apis';
import { handleAxiosErrors, onLogout } from '@services/auth';
import { getValidationErrors } from '@utils/yup';
import { useDispatch, useSelector } from 'react-redux';
import { IPagination, TObject } from 'src/@types/IShared';
import { IUserOrganization } from 'src/@types/IUser';
import { IRootState, persistor } from 'src/store';
import { isEmpty } from '@utils/ReactUtils';
import { useTranslation, withTranslation } from 'react-i18next';
import { DialogBox } from '@modules/Modal/Components';
import ModalFeedback, { IFeedbackProps } from '@components/Modals/ModalFeedback';
import Icon from '@components/Shared/Icon';

interface ModalOrganizationOutProps {
  mode: 'leave' | 'delete'
	routes: object
}

const MModalOrganizationOut = (props: ModalOrganizationOutProps) => {
	const { t } = useTranslation();
	const { routes, mode } = props;

	const [participants, setParticipants] = useState<IPagination<IUserOrganization>>(
		{ docs: [], isLoading: true },
	);
	const { organization, organizations, user } = useSelector(
		(state: IRootState) => state.userOrganizationProfile,
	) || {};
	const [errors, setErrors] = useState<TObject>({});
	const [feedbackProps, setFeedbackProps] = useState<IFeedbackProps>({ open: false });
	const [inProgress, setInProgress] = useState<string | undefined>(undefined);
	const [userTransfer, setUserTransfer] = useState<IUserOption[]>([]);
	const [newAdmin, setNewAdmin] = useState<IUserOption[]>([]);
	const [text, setText] = useState<string>('');
	const [password, setPassword] = useState<string>('');

	const dispatch = useDispatch();
	const { userOrganizationProfile } = useSelector(
		(state: IRootState) => state,
	);

	const onErrorHandler = (field: string) => {
		if (!isEmpty(errors)) delete errors[field];
	};

	const loadParticipants = () => {
		setParticipants((prevState) => ({ ...prevState, isLoading: true }));
		dispatch(Creators.SHARED.getParticipants({
			onSuccess: (response: IPagination<IUserOrganization>) => {
				setParticipants({ ...response, isLoading: false });
			},
		}));
	};

	const onAvailableOrganization = () => {
		const orgs = organizations?.map((item) => item._id);
		const target = orgs?.filter((item) => item !== organization?._id)[0];

		dispatch(Creators.MODAL.hideModal());

		if (target) {
			dispatch(Creators.USER_ORGANIZATION.changeOrganization(target));
			return;
		}
		onLogout();
	};

	const onLeaveHandler = async () => {
		try {
			setInProgress('leave');

			const params = {
				password,
				newAdmin: newAdmin[0]?._id,
				userTransfer: userTransfer[0]?._id,
			};

			let schema = null;

			schema = Yup.object().shape({
				password: Yup.string().required(t('messages.requiredField')),
			});

			if (user?.isAdministrator && organization?.admins?.length === 1) {
				schema = Yup.object().shape({
					password: Yup.string().required(t('messages.requiredField')),
					newAdmin: Yup.string().required(t('messages.requiredField')),
				});
			}

			await schema.validate(params, {
				abortEarly: false,
			});
			// @ts-ignore
			await APIOrganizations.postLeaveOrganization(userOrganizationProfile?.currentTenant, params);

			dispatch(Creators.MODAL.hideModal());

			onAvailableOrganization();
		} catch (err) {
			if (err instanceof Yup.ValidationError) {
				setErrors(getValidationErrors(err));
			} else handleAxiosErrors(err);
		} finally {
			setInProgress(undefined);
		}
	};

	const onDeleteHandler = async () => {
		try {
			setInProgress('delete');

			if (!organization) return;

			let schema = null;

			if (['google', 'linkedin'].includes(user.OAuth.gateway)) {
				schema = Yup.object().shape({
					name: Yup.string().required(t('messages.requiredField')),
				});
			} else {
				schema = Yup.object().shape({
					name: Yup.string().required(t('messages.requiredField')),
					password: Yup.string().required(t('messages.requiredField')),
				});
			}

			await schema?.validate({ password, name: text }, {
				abortEarly: false,
			});

			if (organization.nome !== text) {
				setErrors({ name: t('messages.namesDoNotMatch') });
				return;
			}

			await APIOrganizations.deleteOrganization(organization._id, text);

			setFeedbackProps({
				title: t('feedback.organization.delete.message.title'),
				text: t(
					'feedback.organization.delete.message.text',
					{ email: user?.email || '' },
				),
				hideLeftButton: true,
				container: undefined,
				open: true,
				rightButtonText: t('global.close'),
				onRightButton: onAvailableOrganization,
			});
		} catch (err) {
			if (err instanceof Yup.ValidationError) {
				setErrors({ password: t('messages.password.noMatch') });
			} else {
				handleAxiosErrors(err);
			}
		} finally {
			setInProgress(undefined);
		}
	};

	// @ts-ignore
	const onChangeUsers = (event, value, key) => {
		onErrorHandler(key);

		if (key === 'newAdmin') {
			setNewAdmin(value);
		}

		if (key === 'userTransfer') {
			setUserTransfer(value);
		}
	};

	if (!mode) return null;

	useEffect(() => {
		loadParticipants();
	}, []);

	return (
		<DialogContent className="modal__organization--out">
			<DialogBox className="s-dialog-title">
				<DialogTitle variant="h6">
					{t(`feedback.organization.${mode}.title`)}
				</DialogTitle>
			</DialogBox>
			<DialogBox className="s-dialog-content s-form">
				<p className="">
					<span
						// eslint-disable-next-line react/no-danger
						dangerouslySetInnerHTML={{
							__html: t('feedback.organization.delete.text', {
								platforms: userOrganizationProfile?.plataformas_count,
								ideas: userOrganizationProfile?.ideasCount,
							}),
						}}
					/>
					<br />
					<span className="mr-1">{t('feedback.organization.delete.text3')}</span>
					<a
						href="# "
						className="bold underline"
						onClick={() => {
							persistor.pause();
							dispatch(Creators.MODAL.showSecondModal(routes.MODAL_BACKUP.path));
						}}
					>
						{t('global.saveBackups')}
					</a>
					<span className="ml-1">{t('global.beforeLeaving')}</span>
				</p>
				<Form className="s-form">
					{(!user?.OAuth.active && (
						<SFormGroup>
							<SInputLabel htmlFor="user-password" error={typeof errors?.password === 'string'}>
								{t('feedback.organization.leave.password')}
							</SInputLabel>
							<SPasswordField
								id="user-password"
								value={password}
								error={typeof errors?.password === 'string'}
								helperText={errors?.password}
								placeholder={t('form.placeholder.yourPassword')}
								// onKeyDown={(e) => e.key === 'Enter' && onLeaveHandler(feedbackProps.match)}
								autoFocus
								onChange={(e: ChangeEvent<HTMLInputElement>) => {
									delete errors?.password;
									setPassword(e.currentTarget.value);
								}}
							/>
						</SFormGroup>
					))}

					{ mode === 'delete' && (
						<SFormGroup>
							<SInputLabel htmlFor="organization-name" error={typeof errors.name === 'string'}>
								{t('organization.nameToDelete')}
							</SInputLabel>
							<STextField
								type="text"
								id="organization-name"
								value={text}
								error={typeof errors.name === 'string'}
								helperText={errors.name}
								autoFocus
								autoComplete="off"
								placeholder={t('form.placeholder.organization.current')}
								onChange={(e: ChangeEvent<HTMLInputElement>) => {
									onErrorHandler('name');
									setText(e.currentTarget.value);
								}}
							/>
						</SFormGroup>
					)}

					{(mode === 'leave' && user?.isAdministrator) && (
						<SFormGroup>
							<InputLabel className="s-label" htmlFor="new-admin-autocomplete" error={typeof errors?.newAdmin === 'string'}>
								{t('organization.chooseNewAdmin')}
							</InputLabel>
							<SAutocomplete
								id="new-admin-autocomplete"
								name="newAdmin"
								placeholder={t('form.select')}
								single
								unique="_id"
								limitTags={1}
								helperText={errors?.newAdmin}
								error={typeof errors?.newAdmin === 'string'}
								value={newAdmin || []}
								options={getUserOptions(participants.docs)}
								onChange={(event, value) => onChangeUsers(event, value, 'newAdmin')}
								renderTags={(opts, getTagProps) => (
									<Tags
										value={opts}
										component={(option, index) => (
											<UserTag renderTags single option={option} {...getTagProps({ index })} />
										)}
									/>
								)}
								renderOption={(_props, option) => (
									<li {..._props}><UserTag option={option} /></li>
								)}
							/>
						</SFormGroup>
					)}

					{(mode === 'leave' && (
						<SFormGroup>
							<InputLabel className="s-label" htmlFor="user-transfer-autocomplete">
								{t('organization.chooseUserTransfer')}
							</InputLabel>
							<SAutocomplete
								id="user-transfer-autocomplete"
								single
								unique="_id"
								value={userTransfer || []}
								placeholder={t('form.select')}
								options={getUserOptions(participants.docs) || []}
								onChange={(event, value) => onChangeUsers(event, value, 'userTransfer')}
								renderTags={(opts, getTagProps) => (
									<Tags
										value={opts}
										component={(option, index) => (
											<UserTag renderTags single option={option} {...getTagProps({ index })} />
										)}
									/>
								)}
								renderOption={(_props, option) => (
									<li {..._props}><UserTag option={option} /></li>
								)}
							/>
						</SFormGroup>
					))}

					<Box className="box__info--small">
						<Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
							<Icon icon="warning" />
							{t('global.youllBeSignedOut')}
						</Box>
						{/* eslint-disable-next-line react/no-danger */}
						<p dangerouslySetInnerHTML={{ __html: t('feedback.organization.delete.text2') }} />
					</Box>
				</Form>
				<DialogActions className="s-dialog-actions">
					<SButton
						type="button"
						color="inherit"
						onClick={() => dispatch(Creators.MODAL.hideModal())}
					>
						{t('global.cancel')}
					</SButton>
					<SButton
						id="feedback-right-button"
						isLoading={['delete', 'leave'].includes(inProgress)}
						type="button"
						color="error"
						onClick={mode === 'leave' ? onLeaveHandler : onDeleteHandler}
					// disabled={}
					// error={error}
					>
						{t(`global.${mode}`)}
					</SButton>
				</DialogActions>
			</DialogBox>

			{feedbackProps.open && (
				<ModalFeedback
					id="organization-out"
					onRequestClose={() => setFeedbackProps({ open: false })}
					{...feedbackProps}
				/>
			)}

		</DialogContent>
	);
};

const Translation = withTranslation('common')(MModalOrganizationOut);

export const ModalOrganizationOut = (props) => (
	<Suspense fallback={<div />}>
		<Translation {...props} />
	</Suspense>
);
