import { WithTranslation, withTranslation } from 'react-i18next';
import React, { Suspense } from 'react';
import { TPermissions } from 'src/@types/IPlatform';
import { Box, Tooltip } from '@mui/material';
import Icon from '../Shared/Icon';

interface PermissionIconProps {
	permission: TPermissions;
}

declare type TProps = PermissionIconProps & WithTranslation

const PPermissionIcon = (props: TProps) => {
	const {
		t,
		permission = 'publica',
	} = props;

	const iconPermission = {
		publica: 'public',
		livre: 'public',
		privada: 'fa fa-user-shield',
		secreta: 'fa fa-lock',
	};

	return typeof permission !== 'undefined' ? (
		<Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
			<Icon icon={iconPermission[permission]} />
			<Tooltip placement="top" title={t('global.permission')} arrow>
				<span>
					{t(`platform.permission.${permission}`).capitalizeFirstLetter()}
				</span>
			</Tooltip>
		</Box>
	) : null;
};

const Translation = withTranslation('common')(PPermissionIcon);

const PermissionIcon = (props: PermissionIconProps) => (
	<Suspense fallback={<div />}>
		<Translation {...props} />
	</Suspense>
);

export { PermissionIcon };
