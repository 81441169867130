// @ts-nocheck

import i18next from 'i18next';
import lodash from 'lodash';
import $ from 'jquery';
import moment from 'moment';
import { goToLink } from './ReactUtils';

export function debounce(func: Function, wait: number, immediate?: boolean) {
	let timeout;
	return function () {
		const context = this;
		const args = arguments;
		const later = () => {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		const callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
}

export const extractEmails = (text) => lodash.uniq(
	text.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi) || [],
);

export const serializeDataContainer = function (selectorOrElement) {
	const obj = {};
	const $target = $(selectorOrElement);

	let a = [];

	if ($target.is('form')) {
		a = $target.serializeArray();
	} else {
		a = $target.find('input,textarea,select').toArray();
	}

	$.each(a, function () {
		const matches = this.name.match(/{[^}]*}/g);
		let { name } = this;
		let currObj = obj;
		if (matches) {
			name = this.name.replace(/{.*}/g, '');
			for (const i in matches) {
				if (typeof matches[i] === 'function') continue;
				const key = matches[i].replace(/{/, '').replace(/}/, '');
				if (i == matches.length - 1) {
					currObj[key] = this.value || '';
				} else {
					currObj[key] = {};
					currObj = currObj[key];
				}
			}
		} else if (obj[name]) {
			if (typeof obj[name] === 'object' && !obj[name].push) {
				obj[name] = lodash.merge(obj[name], currObj);
			} else {
				if (!obj[name].push) {
					obj[name] = [obj[name]];
				}
				obj[name].push(this.value || '');
			}
		} else {
			obj[name] = this.value || '';
		}
	});

	return obj;
};

export function elapsedTimeSinceCreation(start?: string, end?: string) {
	if (typeof start === 'undefined' || start == null) {
		start = new Date();
	} else if (start.constructor.name === 'String') {
		start = new Date(start);
	}
	if (typeof end === 'undefined' || end == null) {
		end = new Date();
	}
	const diff = end - start;
	// Finally how many seconds left after removing days, hours and minutes.
	const secs = diff / 1000;
	if (secs < 60) {
		return `${parseInt(secs)} ${i18next.t('global.second')}${parseInt(secs) <= 1 ? '' : `s ${i18next.t('global.ago')}`}`;
	}
	// After days and hours , how many minutes are left
	const minutes = secs / 60;
	if (minutes < 60) {
		return `${parseInt(minutes)} ${i18next.t('global.minute')}${parseInt(minutes) <= 1 ? '' : `s ${i18next.t('global.ago')}`}`;
	}
	// After deducting the days calculate the number of hours left
	const hours = minutes / 60;
	if (hours < 24) {
		return `${parseInt(hours)} ${i18next.t('global.hour')}${parseInt(hours) <= 1 ? '' : `s ${i18next.t('global.ago')}`}`;
	}
	// Calculate the number of days left
	const days = hours / 24;
	if (days < 7) {
		return `${parseInt(days)} ${i18next.t('global.day')}${parseInt(days) <= 1 ? '' : `s ${i18next.t('global.ago')}`}`;
	}
	const weeks = days / 7;
	if (weeks < 4) {
		return `${parseInt(weeks)} ${i18next.t('global.week')}${parseInt(weeks) <= 1 ? '' : `s ${i18next.t('global.ago')}`}`;
	}
	const months = weeks / 4;
	if (months < 12) {
		return `${parseInt(months)} ${parseInt(months) <= 1 ? i18next.t('global.month') : i18next.t('global.months') + i18next.t('global.ago')}`;
	}
	const years = months / 12;
	return `${parseInt(years)} ${i18next.t('global.year')}${parseInt(years) <= 1 ? '' : `s${i18next.t('global.ago')}`}`;
}

export const unwind = function (o, field) {
	return o[field].map((val) => {
		const cloned = { ...o };
		cloned[field] = val;
		return cloned;
	});
};

export const h = {
	getRandomInt(min, max) {
		return parseInt(Math.floor(Math.random() * (max - min + 1)) + min)
			.toLocaleString('pt-BR')
			.replace(/,/g, '#')
			.replace(/\./g, ',')
			.replace(/#/g, '.');
	},
	short_date(date) {
		return moment(date).format('DD/MM/YYYY');
	},
	getTimeRemainingString(endDate) {
		const timeRemaining = getTimeRemaining(endDate);
		return `${timeRemaining.dias} dias, ${timeRemaining.horas} horas e ${timeRemaining.minutos} minutos`;
	},
	getTimeRemainingStringFromDays(createDate, days) {
		const timeRemaining = getTimeRemaining(
			moment(createDate).add(days, 'days').toDate(),
		);
		if (timeRemaining.total <= 0) {
			return null;
		}
		return `${timeRemaining.dias} D, ${timeRemaining.horas}:${timeRemaining.minutos}:${timeRemaining.segundos}`;
	},
};

const SHOWCOMPONENTS = {
	modal: 'modal',
	tab: 'tab',
};
const showComponentQueryString = (href, component, target, tabTarget, focus) => `${href}?showComponent=${component}&target=${encodeURIComponent(target)}${tabTarget ? `&tabTarget=${encodeURIComponent(tabTarget)}` : ''}${focus ? `&focus=${encodeURIComponent(focus)}` : ''}`;

export const buildShowModalQueryString = (href, target) => showComponentQueryString(href, SHOWCOMPONENTS.modal, target);
export const buildShowTabQueryString = (href: string, target: string, tabTarget: string, focus?: string) => showComponentQueryString(href, SHOWCOMPONENTS.tab, target, tabTarget, focus);

export const getTextFromHTML = (html: string):string => {
	const div = document.createElement('div');
	div.innerHTML = html || '';
	return div.textContent.trim();
};

export function scrollToSenno(selector) {
	const $el = $(selector);
	if (!$el.offset()) return;
	$([document.documentElement, document.body]).animate(
		{
			scrollTop: $el.offset().top - 90,
		},
		1000,
	);
}
interface IShowTabParams {
	navId?: string
	tabId?: string
	focusId?: string
	scroll?: boolean
}

export function showTab({
	navId, tabId, focusId, scroll,
}: IShowTabParams) {
	const selector = `a#${tabId}`;
	$(selector).get(0)?.click();

	if (scroll) {
		scrollToSenno(selector);
	}

	if (focusId) {
		window.setTimeout(() => {
			goToLink(`#${focusId}`);
		}, 1000);
	}
}

export function capitalize(str, lower = false) {
	return (lower ? str.toLowerCase() : str).replace(/(?:^|\s|["'([{])+\S/g, (match) => match.toUpperCase());
}

export function parse_query_string(query) {
	const vars = query.split('&');
	const query_string = {};
	for (let i = 0; i < vars.length; i++) {
		const pair = vars[i].split('=');
		const key = decodeURIComponent(pair[0]);
		const value = decodeURIComponent(pair[1]);
		// If first entry with this name
		if (typeof query_string[key] === 'undefined') {
			query_string[key] = decodeURIComponent(value);
			// If second entry with this name
		} else if (typeof query_string[key] === 'string') {
			const arr = [query_string[key], decodeURIComponent(value)];
			query_string[key] = arr;
			// If third or later entry with this name
		} else {
			query_string[key].push(decodeURIComponent(value));
		}
	}
	return query_string;
}

export function showComponentByQueryString(queryString) {
	const params = parse_query_string(document.location.search.substring(1));

	if (params.showComponent && params.target) {
		window.setTimeout(() => {
			if (params.showComponent === SHOWCOMPONENTS.modal) { $(params.target).modal('show'); }
			if (params.showComponent === SHOWCOMPONENTS.tab && params.tabTarget) {
				showTab({ navId: params.tabTarget, tabId: params.target });
				if (params.focus) {
					window.setTimeout(() => {
						goToLink(`#${params.focus}`);
					}, 1000);
				}
			}
		}, 1000);
	}
}

export function normalizedStringComparison(a, b) {
	const n1 = typeof a === 'string' ? a : String(a);
	const n2 = typeof b === 'string' ? b : String(b);
	return n1.normalizeString().toLowerCase().localeCompare(n2.normalizeString().toLowerCase());
}

export function comparaPorNome(a, b) {
	const n1 = a.nome || '';
	const n2 = b.nome || '';
	return n1.localeCompare(n2);
}

export function comparaPorNomeNormalizado(a, b) {
	const n1 = (a.nome || '');
	const n2 = (b.nome || '');
	return normalizedStringComparison(n1, n2);
}

// export function propertyStringComparison({
//   stringA = '',
//   stringB = '',
//   propA = null,
//   propB = null,
//   normalize = false,
// }) {
//   let n1 = typeof stringA === 'string' ? stringA : String(stringA);
//   let n2 = typeof stringB === 'string' ? stringB : String(stringB);
//   if (propA !== null && typeof propA !== 'undefined') {
//     if (stringA[propA] !== null && typeof stringA[propA] !== 'undefined') {
//       n1 = String(stringA[propA]);
//     } else {
//       n1 = '';
//     }
//   }
//   if (propB !== null && typeof propB !== 'undefined') {
//     if (stringB[propB] !== null && typeof stringB[propB] !== 'undefined') {
//       n2 = String(stringB[propB]);
//     } else {
//       n2 = '';
//     }
//   }
//   if (normalize === true) {
//     return normalizedStringComparison(n1, n2);
//   }
//   return n1.localeCompare(n2);
// }

export const unsetLoading = (that, callback) => {
	const keys = Object.keys(that.state).filter((k) => k.startsWith('loading'));
	if (typeof that.setState === 'function') {
		that.setState(
			(state) => {
				const prevState = state;
				keys.forEach((k) => {
					prevState[k] = false;
				});
				return prevState;
			},
			() => {
				if (typeof callback === 'function') {
					callback();
				}
			},
		);
	}
};

export const normalizesValue = (valor: string): string => (
	valor?.normalizeString().toLowerCase().replace(' ', '_')
);

export function comparaUserPorNome(a, b) {
	const n1 = a.user ? a.user._nome || '' : '';
	const n2 = b.user ? b.user._nome || '' : '';
	return n1.localeCompare(n2);
}

export function readURL(input) {
	if (input.files && input.files[0]) {
		const reader = new FileReader();

		reader.onload = function (e) {
			$('.select-cover').css({
				'background-size': 'cover',
				'background-position': 'center',
				'background-image': `url(${e.target.result})`,
			});
		};
		reader.readAsDataURL(input.files[0]);
	}
}

export const datetimePickerChangeHandler = function (e) {
	const $el = $(e.currentTarget);
	if ($el.val() !== $el.attr('value')) {
		$el.attr('value', $el.val()).trigger('change').trigger('keyup');
	}
};

export const overflowText = (str, limit = 45) => {
	if (!str) return;

	if (str.length <= limit) {
		return str;
	}
	return `${str.substring(0, limit)}...`;
};

export const createBackground = (color, opacity = 0.3) => {
	if (!color) {
		return 'var(--primary)';
	}
	let background = color?.split('') || '';
	background[background.length - 1] = `, ${opacity}`;
	background = background.join('');

	return background;
};

export function getExtensionByMimeType(mimetype) {
	const files = [];

	files['video/3gpp2'] = '3g2';
	files['video/3gp'] = '3gp';
	files['video/3gpp'] = '3gp';
	files['application/x-compressed'] = '7zip';
	files['audio/x-acc'] = 'aac';
	files['audio/ac3'] = 'ac3';
	files['application/postscript'] = 'ai';
	files['audio/x-aiff'] = 'aif';
	files['audio/aiff'] = 'aif';
	files['audio/x-au'] = 'au';
	files['video/x-msvideo'] = 'avi';
	files['video/msvideo'] = 'avi';
	files['video/avi'] = 'avi';
	files['application/x-troff-msvideo'] = 'avi';
	files['image/bmp'] = 'bmp';
	files['image/x-bmp'] = 'bmp';
	files['image/x-bitmap'] = 'bmp';
	files['image/x-xbitmap'] = 'bmp';
	files['image/x-win-bitmap'] = 'bmp';
	files['image/x-windows-bmp'] = 'bmp';
	files['image/ms-bmp'] = 'bmp';
	files['image/x-ms-bmp'] = 'bmp';
	files['application/bmp'] = 'bmp';
	files['application/x-bmp'] = 'bmp';
	files['application/x-win-bitmap'] = 'bmp';
	files['application/cdr'] = 'cdr';
	files['application/coreldraw'] = 'cdr';
	files['application/x-cdr'] = 'cdr';
	files['application/x-coreldraw'] = 'cdr';
	files['image/cdr'] = 'cdr';
	files['image/x-cdr'] = 'cdr';
	files['zz-application/zz-winassoc-cdr'] = 'cdr';
	files['text/x-comma-separated-values'] = 'csv';
	files['text/comma-separated-values'] = 'csv';
	files['application/vnd.msexcel'] = 'csv';
	files['application/vnd.openxmlformats-officedocument.wordinProgressml.document'] = 'docx';
	files['video/x-f4v'] = 'f4v';
	files['audio/x-flac'] = 'flac';
	files['video/x-flv'] = 'flv';
	files['image/gif'] = 'gif';
	files['application/x-gtar'] = 'gtar';
	files['application/x-gzip'] = 'gzip';
	files['text/html'] = 'html';
	files['text/calendar'] = 'ics';
	files['image/jpeg'] = 'jpeg';
	files['image/pjpeg'] = 'jpeg';
	files['application/x-javascript'] = 'js';
	files['application/json'] = 'json';
	files['text/json'] = 'json';
	files['audio/x-m4a'] = 'm4a';
	files['application/vnd.mpegurl'] = 'm4u';
	files['audio/midi'] = 'mid';
	files['video/quicktime'] = 'mov';
	files['audio/mpeg'] = 'mp3';
	files['audio/mpg'] = 'mp3';
	files['audio/mpeg3'] = 'mp3';
	files['audio/mp3'] = 'mp3';
	files['video/mp4'] = 'mp4';
	files['video/mpeg'] = 'mpeg';
	files['audio/ogg'] = 'ogg';
	files['video/ogg'] = 'ogg';
	files['application/ogg'] = 'ogg';
	files['application/pdf'] = 'pdf';
	files['application/octet-stream'] = 'pdf';
	files['application/x-httpd-php-source'] = 'php';
	files['image/png'] = 'png';
	files['image/x-png'] = 'png';
	files['application/powerpoint'] = 'ppt';
	files['application/vnd.ms-powerpoint'] = 'ppt';
	files['application/vnd.ms-office'] = 'ppt';
	files['application/msword'] = 'ppt';
	files['application/vnd.openxmlformats-officedocument.presentationml.presentation'] = 'pptx';
	files['application/x-photoshop'] = 'psd';
	files['image/vnd.adobe.photoshop'] = 'psd';
	files['application/x-rar'] = 'rar';
	files['application/rar'] = 'rar';
	files['application/x-rar-compressed'] = 'rar';
	files['text/rtf'] = 'rtf';
	files['text/richtext'] = 'rtx';
	files['image/svg+xml'] = 'svg';
	files['application/x-shockwave-flash'] = 'swf';
	files['application/x-tar'] = 'tar';
	files['application/x-gzip-compressed'] = 'tgz';
	files['image/tiff'] = 'tiff';
	files['text/plain'] = 'txt';
	files['audio/x-wav'] = 'wav';
	files['audio/wave'] = 'wav';
	files['audio/wav'] = 'wav';
	files['audio/x-ms-wma'] = 'wma';
	files['video/x-ms-wmv'] = 'wmv';
	files['video/x-ms-asf'] = 'wmv';
	files['application/excel'] = 'xl';
	files['application/msexcel'] = 'xls';
	files['application/x-msexcel'] = 'xls';
	files['application/x-ms-excel'] = 'xls';
	files['application/x-excel'] = 'xls';
	files['application/x-dos_ms_excel'] = 'xls';
	files['application/xls'] = 'xls';
	files['application/x-xls'] = 'xls';
	files['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'] = 'xlsx';
	files['application/vnd.ms-excel'] = 'xlsx';
	files['application/xml'] = 'xml';
	files['text/xml'] = 'xml';
	files['text/xsl'] = 'xsl';
	files['application/x-zip'] = 'zip';
	files['application/zip'] = 'zip';
	files['application/x-zip-compressed'] = 'zip';
	files['application/s-compressed'] = 'zip';
	files['multipart/x-zip'] = 'zip';

	// google
	files['application/vnd.google-apps.document'] = 'doc';
	files['application/vnd.google-apps.kix'] = 'doc';
	files['application/vnd.google-apps.presentation'] = 'ppt';
	files['application/vnd.google-apps.spreadsheet'] = 'xls';
	files['application/vnd.google-apps.kix'] = 'doc';

	return files[mimetype];
}

export const removeButtons = ['classSpan', '|', 'source', 'video', 'print', 'hr', 'superscript', 'subscript', 'about', 'copyformat', 'font', 'undo', 'redo', 'symbol', 'selectall', 'cut', 'copy', 'paste', 'file', '|'];

export const removeButtons2 = ['classSpan', '|', 'source', 'print', 'hr', 'about', 'superscript', 'subscript', 'copyformat', 'font', 'undo', 'redo', 'symbol', 'selectall', 'cut', 'copy', 'paste', 'file', '|'];
