// @ts-nocheck
import {
	put,
	call,
	takeLatest,
	select,
	delay,
} from 'redux-saga/effects';
import i18next from 'i18next';
import * as Yup from 'yup';
import { Creators, Types } from '@actions';
import { FLOWS } from '@constants/GLOBAL';
import APIForms from '@services/apis/forms';
import { handleAxiosErrors } from '@services/auth';
import { IRootState } from 'src/store';
import { getValidationErrors } from '@utils/yup';
import { AxiosResponse } from 'axios';
import { SToast } from '@modules/SToast';

function* getForms(action) {
	try {
		const {
			ideaId, platformId, onSuccess,
		} = action.p;

		const { modal } = yield select((state: IRootState) => state);
		const flow = action?.p?.flow || modal?.flow;

		let response: AxiosResponse = {};

		switch (flow) {
			case FLOWS.IDEA:
				response = yield call(APIForms.getFormsByIdea, ideaId);
				break;
			case FLOWS.BRAINSTORM:
			case FLOWS.CHALLENGE:
				response = yield call(APIForms.getFormsByPlatform, platformId);
				break;
			default:
				break;
		}

		if (typeof onSuccess === 'function') {
			yield call(onSuccess, { docs: response.data });
		}
	} catch (err) {
		yield call(handleAxiosErrors, err);
	} finally {
		if (typeof action.p.callback === 'function') {
			yield call(action.p.callback);
		}
	}
}

function* deleteForm(action) {
	const { formId, callback, onSuccess } = action.params;
	try {
		if (!formId) {
			return;
		}

		yield call(APIForms.deleteForm, formId);
		if (typeof onSuccess === 'function') {
			yield call(onSuccess);
		}

		yield call(SToast.success, i18next.t('messages.success.form.removed'));
	} catch (err) {
		yield call(handleAxiosErrors, err);
	} finally {
		if (typeof callback === 'function') {
			yield call(callback);
		}
	}
}

function* insertForm(action) {
	const {
		platformId, ideaId, templateId, onSuccess, callback, flow,
	} = action.params;
	try {
		const data = { templateId };
		switch (flow) {
			case FLOWS.BRAINSTORM:
			case FLOWS.CHALLENGE:
				data.platformId = platformId;
				break;
			case FLOWS.IDEA:
				data.ideaId = ideaId;
				break;
			default:
				break;
		}

		const response: AxiosResponse = yield call(APIForms.postFormAssociate, data);

		if (typeof onSuccess === 'function') {
			yield call(onSuccess, response.data);
		}
		yield call(SToast.success, i18next.t('messages.success.form.added'));
	} catch (err) {
		yield call(handleAxiosErrors, err);
	} finally {
		if (typeof callback === 'function') {
			yield call(callback);
		}
	}
}

function* updateForm(action) {
	const {
		formId, key, value, callback, onErrors, fieldsSchema, onSuccess,
	} = action.params;
	try {
		let schema = null;
		const { modal } = yield select((state: IRootState) => state);

		const field = { [key]: value };

		if (!formId || modal?.ModalProps?.action !== 'edit') return;

		switch (key) {
			case 'name':
			case 'description':
			case 'useCases':
				schema = Yup.object().shape({
					[key]: Yup.string().required(i18next.t('messages.requiredField')),
				});
				break;
			case 'fields':
				schema = Yup.object().shape({
					fields: fieldsSchema,
				});
				break;
			default:
				break;
		}

		if (schema) {
			yield schema.validate(field, {
				abortEarly: false,
			});
		}

		yield delay(1000);
		yield call(APIForms.patchForm, formId, field);

		if (typeof onSuccess === 'function') {
			yield call(onSuccess);
		}
	} catch (err) {
		if (err instanceof Yup.ValidationError && typeof onErrors === 'function') {
			const errorMessages = yield call(getValidationErrors, err);
			yield call(onErrors, errorMessages);
		} else yield call(handleAxiosErrors, err);
	} finally {
		if (typeof callback === 'function') {
			yield call(callback);
		}
	}
}

function* restoreForm(action) {
	try {
		const response: AxiosResponse = yield call(APIForms.putRestoreForm, action.p.formId);

		yield put(Creators.FORMS.setForm(response.data));

		yield call(SToast.success, i18next.t('messages.success.template.reseted'));

		if (typeof action.p.onSuccess === 'function') {
			yield call(action.p.onSuccess);
		}
	} catch (err) {
		yield call(handleAxiosErrors, err);
	} finally {
		if (typeof action.p.callback === 'function') {
			yield call(action.p.callback);
		}
	}
}

export default function* () {
	yield takeLatest(Types.FORMS.DELETE_FORM, deleteForm);
	yield takeLatest(Types.FORMS.INSERT_FORM, insertForm);
	yield takeLatest(Types.FORMS.UPDATE_FORM, updateForm);
	yield takeLatest(Types.FORMS.RESTORE_FORM, restoreForm);
	yield takeLatest(Types.FORMS.GET_FORMS, getForms);
}
