/* eslint-disable import/no-extraneous-dependencies */
import { Box } from '@mui/material';
import { styled as MuiStyled } from '@mui/system';

export const MatrixGrid = MuiStyled(Box)(({ theme }) => ({
	[theme.breakpoints.up('xs')]: {},
	margin: '0 auto',
	'&.printing': {
		'.matrix .matrix__footer': {
			display: 'none',
		},
	},
	'&.g-3x3 .matrix__body': {
		'&:before': {
			content: '""',
			position: 'absolute',
			width: '100%',
			height: '8px',
			backgroundColor: '#fff',
			top: '66.333%',
			left: '0',
			zIndex: '1',
		},
		' &:after': {
			content: '""',
			position: 'absolute',
			width: '8px',
			height: '100%',
			background: '#fff',
			left: '66.333%',
			top: '0',
			zIndex: '1',
		},
		'.chart__matrix .topLeft': {
			transform: 'rotate(-90deg) translate(-100%)',
			top: '0',
			left: '20px',
		},
	},
	'.matrix': {
		marginBottom: '2%',
		backgroundColor: '#fff',
		'.matrix__header': {
			borderBottom: '1px solid rgba(208, 208, 208, 0.5)',
			justifyContent: 'space-between',
			padding: '1rem',
			display: 'flex',
			h5: {
				color: 'rgba(0, 0, 0, 0.75)',
				marginBottom: '0 !important',
				fontWeight: '400',
				lineHeight: '1.25rem',
			},
		},
		'.matrix__params': {
			position: 'absolute',
			fontSize: '0.875rem',
			color: 'rgba(0, 0, 0, 0.55)',
			margin: 0,
		},
		'.matrix__footer': {
			display: 'flex',
			justifyContent: 'flex-end',
			alignItems: 'center',
			paddingLeft: '30px',
			paddingRight: '30px',
		},
		'.matrix__body': {
			image: {
				borderRadius: '50%',
			},
			position: 'relative !important',
			'.topLeft': {
				fontSize: '20px',
				position: 'absolute',
				fontWeight: '500',
				top: '50px',
				left: '50px',
				color: 'var(--byzantine-color)',
			},
			'.topRight': {
				fontSize: '20px',
				fontWeight: '500',
				position: 'absolute',
				top: '50px',
				right: '50px',
				color: 'var(--air-force-color)',
			},
			'.bottomLeft': {
				fontSize: '20px',
				fontWeight: '500',
				position: 'absolute',
				bottom: '50px',
				left: '50px',
				margin: '0',
				color: 'var(--dijon-yellow-color)',
			},
			'.bottomRight': {
				fontSize: '20px',
				fontWeight: '500',
				position: 'absolute',
				bottom: '50px',
				right: '50px',
				margin: '0',
				color: 'var(--lobster-color)',
			},
		},
	},
	'.chart__matrix': {
		canvas: {
			zIndex: 2,
			cursor: 'pointer',
		},
		backgroundColor: '#F7F7F7',
		position: 'relative',
		boxShadow: 'inset 0px 0px 0px 30px #fff',
		'&:before': {
			content: '""',
			position: 'absolute',
			background: '#fff',
			zIndex: '0',
		},

		'&:after': {
			content: '""',
			position: 'absolute',
			background: '#fff',
			zIndex: '0',
		},
	},
	'&.g-2x2 .chart__matrix': {
		'&:before': {
			height: '8px',
			top: '50%',
			left: '0',
			width: '100%',
		},
		'&:after': {
			left: '50%',
			top: '0',
			width: '8px',
			height: '100%',
		},
	},
	'&.g-3x3 .chart__matrix': {
		'&:before': {
			height: '8px',
			width: '100%',
			top: '33.333%',
		},
		'&:after': {
			width: '8px',
			left: '33.333%',
			top: '0',
			zIndex: '0',
			height: '100%',
		},
	},

}));
