// @ts-nocheck
import React from 'react';
import { io } from 'socket.io-client';
import { getToken } from './auth';
import i18n from './i18n';

const SOCKET_EVENTS = {
	IDEA_LIKE: 'idea_like',
	PLATFORM_LIKE: 'platform_like',
	COMMENT: 'comment',
	COMMENT_LIKE: 'comment_like',
	COMMENT_UPDATE: 'comment_update',
	COMMENT_DELETE: 'comment_delete',
	ERROR: 'error',
	JOIN: 'join',
	JOIN_COMMENT: 'comment_join',
	JOIN_PLATFORM: 'platform_join',
	JOIN_IDEA: 'idea_join',
};

const SocketIO = (function () {
	const port = process.env.BACKEND_PORT ? `:${process.env.BACKEND_PORT}` : '';
	const connection = document.createElement('div');
	connection.id = 'connection';
	document.body.appendChild(connection);

	const socket = io.connect(`${process.env.BACKEND_URL}${port}`, {
		transports: ['websocket'],
		auth: { token: `Bearer ${getToken()}` },
	});

	socket.on('connect', (data) => {
		connection?.classList.remove('offline');
		console.log('socket.io: connected');

		socket.on('broadcast', (data) => {
			console.log('socket.io: broadcast', data);
		});

		socket.on('disconnect', (data) => {
			console.log('socket.io: disconnect');
			connection?.classList.add('offline');
			connection.innerHTML = i18n.t('messages.connection.lost');
		});

		socket.on('connect_failed', (data) => {
			connection?.classList.remove('reconnected');
			connection?.classList.remove('update');
			connection?.classList.add('offline');
			connection.innerHTML = i18n.t('messages.connection.lost');
		});

		socket.on('connect_error', (data) => {
			connection?.classList.remove('reconnected');
			connection?.classList.remove('update');
			connection?.classList.add('offline');
			connection.innerHTML = i18n.t('messages.connection.lost');
		});

		socket.on('connect_timeout', (timeout) => {
			console.log('socket.io: connect_timeout: ', timeout);
		});

		socket.on('reconnect', (data) => {
			console.log('socket.io: reconnect');
			connection?.classList.remove('reconnected');
			connection?.classList.remove('update');
			connection?.classList.remove('offline');
		});
	});

	return socket;
}());

const SocketIOContext = React.createContext(SocketIO);

export {
	SocketIO,
	SocketIOContext,
	SOCKET_EVENTS,
};

export default (Component) => {
	class withSocket extends React.Component {
		static contextType = SocketIOContext;

		constructor(props) {
			super(props);
			this.socketEvents = {};
		}

		setSocketEvents = (socketEvents) => {
			this.socketEvents = socketEvents;
		};

		listenToSocketEvents = () => {
			if (!this.context) return;
			console.log('Setting socket events');
			for (const event of Object.keys(this.socketEvents)) {
				this.context.on(event, this.socketEvents[event]);
			}
		};

		unlistenSocketEvents = () => {
			if (!this.context) return;
			console.log('Removing socket events');
			for (const event of Object.keys(this.socketEvents)) {
				this.context.off(event, this.socketEvents[event]);
			}
		};

		render() {
			return (
				<Component
					socket={this.context}
					setSocketEvents={this.setSocketEvents}
					listenToSocketEvents={this.listenToSocketEvents}
					unlistenSocketEvents={this.unlistenSocketEvents}
					{...this.props}
				/>
			);
		}
	}

	return withSocket;
};
