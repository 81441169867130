// @ts-nocheck
import React, { useEffect, Suspense, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withTranslation, WithTranslation } from 'react-i18next';
import ModalHeader from '@modules/Modal/ModalHeader';
import APIBadges from '@services/apis/badges';
import type { IModal } from '@modules/Modal/types';
import { IRootState } from 'src/store';
import { DialogBox } from '@modules/Modal/Components';
import { handleAxiosErrors } from '@services/auth';
import { BBadges } from '@components/Background/BBadges';
import { Creators } from '@actions';
import { DynamicCircle } from '@components/DynamicCircle';
import { DialogContent, DialogTitle, Typography } from '@mui/material';
import { useTour } from '@reactour/tour';
import { FLOWS } from '@constants/GLOBAL';
import { BuildTour } from '@components/STour';
import { GenericGrid, W_GRID } from '@components/Grids/GenericGrid';
import { HelpButton } from '../../Buttons/HelpButton';

declare type TProps = IModal & WithTranslation;

const MModalBadges = (props: TProps) => {
	const { t, routes } = props;
	const { platforms, modal, shared } = useSelector((state: IRootState) => state);
	const dispatch = useDispatch();
	const tour = useTour();
	const [templates, setTemplates] = useState({ docs: [], isLoading: true });
	const [badges, setBadges] = useState({ docs: [], isLoading: true });

	const { disabled } = useSelector((state: IRootState) => state.modal?.ModalProps);

	const flow = platforms?.type?.toLowerCase() || modal.flow;

	const newBadge = {
		nome: '',
		descricao: '',
		dificuldade: 'medio',
		cor: 'rgb(76, 122, 144)',
	};

	const TOUR_STEPS = [
		{
			id: templates.docs.length > 1 ? 'template-0-vertical' : 'badge-create',
			selector: templates.docs.length > 1 ? '[data-tut="template-0-vertical"]' : '[data-tut="badge-create"]',
			title: t(`tour.forms.${templates.docs.length > 1 ? 'add' : 'create'}.title`),
			description: t(`tour.forms.${templates.docs.length > 1 ? 'add' : 'create'}.description`),
			action: t(`tour.forms.${templates.docs.length > 1 ? 'add' : 'create'}.action`),
		},
		{
			id: 'modal-back',
			selector: '[data-tut="modal-back"]',
			title: t('tour.modal.back.title'),
			description: t('tour.modal.back.description'),
			action: t('tour.modal.back.action'),
		},
	];

	const paramsPaths = {
		badge: routes.MODAL_PLATFORM_BADGE.path,
	};

	const onTourHandler = () => {
		if (shared.tour === FLOWS.CHALLENGE) {
			tour.setCurrentStep(10);
		}
		tour.setIsOpen(false);
	};

	const onViewHandler = ({ badge, action }) => {
		if (platforms?.mode === 'view') return;
		dispatch(Creators.BADGES.setBadge(badge));
		dispatch(Creators.MODAL.showModal(paramsPaths.badge, { action }));
	};

	const loadTemplates = async (ids: string[], startTour?: boolean) => {
		try {
			setTemplates({ ...templates, isLoading: true });
			const response = await APIBadges.getTemplates(ids);

			setTemplates({ docs: response.data, isLoading: false });
			if (startTour) {
				BuildTour.setTour(tour, TOUR_STEPS, { currentStep: 0 });
			}
		} catch (err) {
			handleAxiosErrors(err);
		}
	};

	const loadBadges = (startTour: boolean) => {
		setBadges({ ...badges, isLoading: true });
		dispatch(Creators.BADGES.getBadgesByPlatform({
			platformId: platforms._id,
			onSuccess: (response) => {
				dispatch(Creators.PLATFORMS.updatePlatform({ medalhas: response.docs }));
				setBadges({ ...response, isLoading: false });
				loadTemplates(response.docs.map((item) => item.template._id), startTour);
			},
		}));
	};

	const onInsertHandler = (templateId: string, callback) => {
		if (!disabled) {
			dispatch(
				Creators.BADGES.insertBadge({
					platformId: platforms._id,
					templateId,
					onSuccess: () => loadBadges(),
					callback,
				}),
			);
		}
	};

	const onBackHandler = (params) => {
		onTourHandler();
		dispatch(Creators.MODAL.backModal(params));
	};

	useEffect(() => {
		loadBadges([FLOWS.CHALLENGE].includes(shared.tour));
	}, []);

	return (
		<DialogContent id="BadgesDialog">
			<ModalHeader
				fullScreen
				hideClose
				icon={flow}
				BreadcrumbProps={{ className: W_GRID.W_GRID_2 }}
				pathParams={{
					platform: platforms?.nome,
					type: platforms?.type,
				}}
				onBack={onBackHandler}
				BackModalProps={{ anchor: '#badges-modal-create' }}
			/>
			<DialogBox className="s-dialog-title inner w-grid-2">
				<DialogTitle variant="h1">
					{t('form.platform.badges')}
				</DialogTitle>
				<HelpButton
					size="medium"
					href={t('urls.howAddBadgesToPlatform')}
					onClick={() => BuildTour.setTour(tour, TOUR_STEPS, { currentStep: 0 })}
				/>
			</DialogBox>
			<DialogBox className="s-dialog-box inner w-grid-2">
				<BBadges
					id="idea-badges"
					flow={flow}
					badges={badges}
					mode={platforms.mode}
					routes={paramsPaths}
					size="x-large"
					hasEmptyState
				/>
				<DialogBox className="s-dialog-content">
					<Typography variant="h6" fontWeight={700}>{t('form.badges.add')}</Typography>
				</DialogBox>
				<BBadges
					id="idea-badges-templates"
					flow={flow}
					badges={templates}
					routes={paramsPaths}
					size="x-large"
					orientation="vertical"
					mode={disabled || platforms.mode === 'view' ? 'view' : 'add'}
					onInsert={onInsertHandler}
					CreateProps={platforms.mode === 'edit' ? {
						title: t('form.badges.new'),
						onClick: platforms.mode === 'edit' ? () => onViewHandler({ action: 'create', badge: newBadge }) : undefined,
						size: 'x-large',
						action: platforms.mode === 'edit' ? t('global.create') : undefined,
						clickable: platforms.mode === 'edit',
					} : undefined}
				/>
			</DialogBox>
		</DialogContent>
	);
};

const Translation = withTranslation('common')(MModalBadges);

const ModalBadges = (props: IModal) => (
	<Suspense fallback={<div />}>
		<Translation {...props} />
	</Suspense>
);
export default ModalBadges;
