// @ts-nocheck
import { createActions, createReducer } from 'reduxsauce';
import { IBillingActions, IBillingState } from './types';

/** Creating actions types & creators */

export const billingActions: IBillingActions = createActions({
	successBilling: ['billing'],
	successCurrentPlan: ['data'],
	subscribePlan: ['p'],
	handlePlan: ['plan'],
	getOverviewPlan: ['params'],
	clearBilling: [''],
});

const { Types } = billingActions;

/** creating reducer handlers */

export const INITIAL_STATE: IBillingState = {
	plans: { data: [], isLoading: true },
	updatePlan: undefined,
	inProgress: false,
	stripe: false,
};

const handlePlan = (state = INITIAL_STATE, { plan }) => (
	{
		...state,
		updatePlan: {
			...state.updatePlan,
			...plan,
		},
	}
);

const successBilling = (state = INITIAL_STATE, action) => {
	const { billing } = action;
	return {
		...state,
		...billing,
	};
};

/** Default functions */

const clearBilling = () => ({});
const defaultBilling = (state = INITIAL_STATE) => ({ ...state });

/** Creating our own reducer */

export default createReducer(INITIAL_STATE, {
	[Types.SUCCESS_BILLING]: successBilling,
	[Types.SUBSCRIBE_PLAN]: defaultBilling,
	[Types.HANDLE_PLAN]: handlePlan,
	[Types.GET_OVERVIEW_PLAN]: defaultBilling,
	[Types.CLEAR_BILLING]: clearBilling,
});
