import { Grid, styled } from '@mui/material';
import Slider, { SliderProps } from '@mui/material/Slider';

const PrettoSlider = styled(Slider)({
	color: '#52af77',
	height: 8,
	'& .MuiSlider-track': {
		border: 'none',
	},
	'& .MuiSlider-thumb': {
		height: 24,
		width: 24,
		border: '2px solid currentColor',
		'&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
			boxShadow: 'inherit',
		},
		'&:before': {
			display: 'none',
		},
	},
	'& .MuiSlider-valueLabel': {
		lineHeight: 1.2,
		fontSize: 12,
		background: 'unset',
		padding: 0,
		width: 32,
		height: 32,
		borderRadius: '50% 50% 50% 0',
		backgroundColor: '#52af77',
		transformOrigin: 'bottom left',
		transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
		'&:before': { display: 'none' },
		'&.MuiSlider-valueLabelOpen': {
			transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
		},
		'& > *': {
			transform: 'rotate(45deg)',
		},
	},
});

const SSlider = (props: SliderProps) => (
	<Grid container spacing={2}>
		<Grid item className={`${props.className || ''}`}>
			{props.min}
		</Grid>
		<Grid item xs>
			<PrettoSlider
				aria-labelledby="discrete-slider-always"
				valueLabelDisplay="on"
				{... props}
			/>
		</Grid>
		<Grid item className={`${props.className || ''}`}>
			{props.max}
		</Grid>
	</Grid>
);

export { SSlider };
