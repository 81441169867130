// @ts-nocheck

export const orderOptions = (values, compareFunction: () => void) => values
	.filter((v) => v.isFixed)
	.sort((typeof compareFunction === 'function' ? compareFunction : (a, b) => a - b))
	.concat(
		values
			.filter((v) => !v.isFixed)
			.sort((typeof compareFunction === 'function' ? compareFunction : (a, b) => a - b)),
	);
