/* eslint-disable react/no-array-index-key */
// @ts-nocheck
import React, {
	useCallback,
	useEffect,
	useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';
import axios, { AxiosResponse } from 'axios';
import { debounce } from '@utils/helpers';
import { FLOWS } from '@constants/GLOBAL';
import {
	DialogBox,
} from '@modules/Modal/Components';
import { handleAxiosErrors } from '@services/auth'; import { ComputerUploadButton } from '@components/Buttons/ComputerUploadButton';
import {
	Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, ModalProps,
} from '@mui/material';
import { useTour } from '@reactour/tour';
import { IRootState } from 'src/store';
import { BuildTour } from '@components/STour';
import { HelpButton } from '@components/Buttons';
import { TFlow } from 'src/@types/IShared';
import { APIIdeas, APIPlatforms } from '@services/apis';
import { SToast } from '@modules/SToast';
import { GenericGrid } from '@components/Grids/GenericGrid';
import InputSearch from '../../Inputs/InputSearch';
import { Creators } from '../../../store/ducks/actionsTypes';
import SpinnerLDSRipple from '../../Shared/SpinnerLDSRipple';

interface IModalCover extends ModalProps {
	flow: TFlow
	query: string
}

const MModalCover = (props: IModalCover) => {
	const {
		t, id, onClose, open, flow, query,
	} = props;

	const dispatch = useDispatch();
	const tour = useTour();
	const { shared } = useSelector((state: IRootState) => state);
	const [photos, setPhotos] = useState([]);
	const [params, setParams] = useState({
		page: 1,
	});
	const [search, setSearch] = useState(query);
	const [fetching, setFetching] = useState(true);
	const [inProgress, setInProgress] = useState(undefined);

	const TOUR_STEPS = BuildTour.steps([
		'cover.upload',
	]);

	const accessKey = 'c83223950b3435bf0a5e531d9e8e9b850ee63c1dec90d5a0aff35d867439bb95'; // SENNO
	// eslint-disable-next-line max-len
	// const accessKey = '1c4569a4087f898dd9fc4a376a2d9139839b38245fb38319bd40f1bcf01e81dd';      //CANZIAN
	const baseUrl = 'https://api.unsplash.com';
	const perPage = 20;

	const fetchPhotos = useCallback(
		debounce((q: string, page: number, startTour) => {
			if (!page) return;
			const parameters = {
				page,
				per_page: perPage,
				query: encodeURIComponent(q),
			};
			const headers = {
				Authorization: `Client-ID ${accessKey}`,
			};
			const url = q !== '' ? `${baseUrl}/search` : baseUrl;
			axios
				.get(`${url}/photos`, { params: parameters, headers })
				.then((response) => {
					const data = q !== '' ? response.data.results : response.data;
					setPhotos((prevState) => prevState.concat(data));
					setParams({
						hasNextPage: q !== '' ? response.data.total_pages > page : response.data.length === 0, // stop
						nextPage: page += 1,
					});

					setFetching(false);

					if (startTour) {
						BuildTour.setTour(tour, TOUR_STEPS, { currentStep: 0, bridge: flow });
					}
				})
				.catch((err) => {
					handleAxiosErrors(err);
				});
		}, 1000),
		[],
	);

	const onUpload = async (formData) => {
		try {
			setInProgress('upload');
			let response: AxiosResponse = null;
			if ([FLOWS.BRAINSTORM, FLOWS.CHALLENGE].includes(flow)) {
				response = await APIPlatforms.postCover(id, formData);
				dispatch(Creators.PLATFORMS.updatePlatform({
					cover: response.data.cover,
					coverThumb: response.data.coverThumb,
					coverAuthor: response.data.coverAuthor,
				}));
			}

			if ([FLOWS.IDEA].includes(flow)) {
				response = await APIIdeas.postCoverUpload(id, formData);
				dispatch(Creators.IDEAS.updateIdeas({
					cover: response.data.cover,
					coverThumb: response.data.coverThumb,
					coverAuthor: response.data.coverAuthor,
				}));
			}

			SToast.success((t('messages.success.coverChanged')));
			BuildTour.close(tour);
			onClose?.();
		} catch (err) {
			handleAxiosErrors(err);
		} finally {
			setInProgress(undefined);
		}
	};

	const onSelect = async (photo) => {
		try {
			const headers = {
				Authorization: `Client-ID ${accessKey}`,
			};
			setInProgress(photo.id);

			await axios.get(`${photo.links.download_location}`, { headers }); // ???

			const img = new Image();
			img.src = photo.urls.full;

			const cover = {
				cover: photo.urls.full,
				coverThumb: photo.urls.small,
				coverAuthor: { name: photo.user.name, portforlio: photo.user.links.html },
				onSuccess: () => {
					BuildTour.close(tour);
					onClose?.();
				},
				callback: () => setInProgress(undefined),
			};

			if ([FLOWS.BRAINSTORM, FLOWS.CHALLENGE].includes(flow)) {
				dispatch(Creators.PLATFORMS.handleCoverPlatform(cover));
			}

			if ([FLOWS.IDEA].includes(flow)) {
				dispatch(Creators.IDEAS.handleCoverIdea(cover));
			}
		} catch (err) {
			SToast.error(err);
		}
	};

	const handleInputChange = (e) => {
		setFetching(true);
		setPhotos([]);
		setSearch(e.target.value);
		fetchPhotos(e.target.value, 1);
	};

	useEffect(() => {
		const gallery = document.querySelector('.gallery');
		const more = document.querySelector('#more-cover');

		gallery?.addEventListener('scroll', () => {
			if ((parseInt(gallery.scrollTop + gallery.offsetHeight, 10)
			=== parseInt(gallery.scrollHeight, 10)) && more) {
				fetchPhotos(search, parseInt(more.dataset.next, 10));
			}
		});
	});

	let _body;
	let _more;
	if (fetching) {
		_body = (
			<Box className="no__content">
				<SpinnerLDSRipple />
			</Box>
		);
	} else if (photos.length < 1) {
		_body = (
			<Box
				className="no__content"
			>
				NO PHOTOS
			</Box>
		);
	} else {
		_body = photos.map((photo, index) => (
			<div
				key={photo.id + index}
				className="gallery__item"
			>
				<Button
					className="gallery__item_body "
					onClick={() => onSelect(photo)}
				>
					<img
						src={`${photo.urls.small}`}
						srcSet={`${photo.urls.small}`}
						alt=""
						loading="lazy"
					/>
					{inProgress === photo.id && <SpinnerLDSRipple />}
				</Button>
			</div>
		));
		if (!params.hasNextPage) {
			_more = <Box />;
		} else {
			_more = (
				<Box
					id="more-cover"
					className="no__content"
					data-next={params.nextPage}
				>
					<SpinnerLDSRipple />
				</Box>
			);
		}
	}

	useEffect(() => {
		fetchPhotos(search, 1, shared?.tour === flow);
	}, []);

	return (
		<Dialog
			id={`modal-cover-${id}`}
			keepMounted
			open={open}
			maxWidth="sm"
			fullWidth
			onClose={onClose}
			PaperProps={{
				sx: {
					maxWidth: {
						xs: '100%',
						sm: '600px',
						xl: '800px',
					},
				},

			}}
		>
			<GenericGrid className="s-grid-modal">
				<DialogContent>
					<DialogBox className="s-dialog-title">
						<DialogTitle variant="h6">
							{t('global.selectACover')}
						</DialogTitle>
						<HelpButton
							size="large"
							onClick={() => BuildTour.setTour(tour, TOUR_STEPS, { currentStep: 0 })}
						>
							Tour
						</HelpButton>
					</DialogBox>
					<DialogBox className="s-dialog-content" sx={{ display: 'flex', alignItems: 'center' }}>
						<InputSearch
							value={search}
							placeholder={t('global.search')}
							className="w-100"
							onChange={handleInputChange}
						/>
						<Box data-tut="cover-upload">
							<ComputerUploadButton
								accept="image/png,image/jpeg,image/jpg"
								onClick={onUpload}
								isLoading={inProgress === 'upload'}
							/>
						</Box>
					</DialogBox>
					<DialogBox className="s-dialog-content gallery">
						{_body}
						{_more}
					</DialogBox>
					<DialogBox className="s-dialog-content">
						<DialogActions>
							<Button
								type="button"
								color="secondary"
								onClick={onClose}
							>
								{t('global.cancel')}
							</Button>
						</DialogActions>
					</DialogBox>
				</DialogContent>
			</GenericGrid>
		</Dialog>
	);
};

const Translation = withTranslation('common')(MModalCover);

const ModalCover = (props) => (
	<React.Suspense fallback={<div />}>
		<Translation {...props} />
	</React.Suspense>
);

export default ModalCover;
