// @ts-nocheck
import React, { Suspense, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { WithTranslation, withTranslation } from 'react-i18next';

import { Creators } from '@actions';
import { FLOWS } from '@constants/GLOBAL';
import type { IRootState } from 'src/store';
import { createBackground } from '@utils/helpers';
import type { IModal } from '@modules/Modal/types';
import CardGrid from '@components/Grids/CardGrid';
import ModalHeader from '@modules/Modal/ModalHeader';
import { DynamicCircle } from '@components/DynamicCircle';
import RedactorText from '@components/Shared/RedactorText';
import { TemplateCard } from '@components/Cards/TemplateCard';
import { SButton } from '@components/Buttons';
import Icon from '@components/Shared/Icon';
import { HelpButton } from '@components/Buttons/HelpButton';
import { APIIdeas, APIPlatforms } from '@services/apis';
import { handleAxiosErrors } from '@services/auth';
import {
	Box,
	DialogContent, DialogTitle, Divider, Grid, List, ListItem, ListItemIcon, ListItemText, Typography,
} from '@mui/material';
import SpinnerLDSRipple from '@components/Shared/SpinnerLDSRipple';
import { IPagination, ITemplate } from 'src/@types/IShared';
import { BuildTour } from '@components/STour';
import { useTour } from '@reactour/tour';
import { SBox } from '@components/Background/SBox';
import { GenericGrid, W_GRID } from '@components/Grids/GenericGrid';
import { DialogBox } from '@modules/Modal/Components';

declare type TProps = IModal & WithTranslation;
interface IRelatedList<T> {
	title: string
	related: T
	icon: 'list' | 'form' | 'canvas' | 'badge'
}

const RelatedList = (props: IRelatedList) => {
	const { title, related, icon } = props;

	if (!related || related.length < 1) {
		return null;
	}
	return (
		<Box className="related-items">
			<strong>{title}</strong>
			<List>
				{related?.map((form) => (
					<ListItem key={form.helper} sx={{ padding: 0, alignItems: 'baseline' }}>
						<ListItemIcon sx={{ minWidth: '35px' }}>
							<Icon icon={icon} style={{ color: form.color }} />
						</ListItemIcon>
						<ListItemText
							primary={(
								<>
									{form.name}
									{' '}
									<HelpButton helperURL="# " size="small" />
								</>
							)}
							sx={{
								display: 'flex',
								'.MuiTypography-root': {
									color: 'rgba(0, 0, 0, 0.75)',
									fontWeight: '300',
								},
							}}
						/>
					</ListItem>
				))}
			</List>
		</Box>
	);
};

const MModalTemplate = (props: TProps) => {
	const { routes, t, template } = props;
	console.log('TEMPLATE', template);
	const tour = useTour();
	const dispatch = useDispatch();
	const { platforms, ideas, shared } = useSelector((state: IRootState) => state);
	const { flow } = useSelector((state: IRootState) => state.modal);
	const [templates, setTemplates] = useState<IPagination<ITemplate>>({ docs: [], isLoading: true });

	const rootPath = {
		idea: ideas.nome,
		brainstorm: platforms.nome,
		challenge: platforms.nome,
	};

	const [inProgress, setInProgress] = useState<'change' | undefined>(undefined);

	const TOUR_STEPS = BuildTour.steps(['template.use']);

	const loadTemplates = async (startTour?: boolean) => {
		try {
			setTemplates({ ...templates, isLoading: true });

			let response;
			switch (flow) {
				case FLOWS.IDEA:
					response = await APIIdeas.getTemplates({ ids: [template._id], limit: 2 });
					break;
				case FLOWS.CHALLENGE:
				case FLOWS.BRAINSTORM:
					response = await APIPlatforms.getTemplates({
						ids: [template._id],
						limit: 2,
						filter: flow.capitalizeFirstLetter(),
					});
					break;
				default:
					break;
			}
			setTemplates({ ...response.data, isLoading: false });

			if (startTour) {
				BuildTour.setTour(tour, TOUR_STEPS);
			}
		} catch (err) {
			handleAxiosErrors(err);
		}
	};

	const onChangeTemplate = () => {
		tour.setIsOpen(false);
		const templateId = template._id;
		setInProgress('change');
		switch (flow) {
			case FLOWS.IDEA:
				dispatch(Creators.IDEAS.handleIdeaTemplate({
					templateId,
					ideaId: ideas._id,
					platformId: ideas.plataforma?._id || undefined,
					redirectPath: routes.MODAL_IDEA.path,
					callback: () => setInProgress(undefined),
				}));
				break;
			case FLOWS.BRAINSTORM:
			case FLOWS.CHALLENGE:
				dispatch(Creators.PLATFORMS.handlePlatformTemplate({
					templateId,
					platformId: platforms._id,
					redirectPath: routes.MODAL_PLATFORM.path,
					callback: () => setInProgress(undefined),
				}));
				break;
			default:
				break;
		}
	};

	useEffect(() => {
		loadTemplates(shared?.tour === flow);
	}, []);

	return (
		<DialogContent className="modal__template" id="TemplateDialog">
			<ModalHeader
				fullScreen
				hideClose
				icon={flow}
				BreadcrumbProps={{ className: W_GRID.W_GRID_2 }}
				pathParams={{
					template: template?.nome || '...',
					idea: rootPath?.[flow] || '...',
					platform: rootPath?.[flow] || '...',
				}}
			/>
			<SBox loading={!template}>
				<Grid container spacing={3} sx={{ flexDirection: { xs: 'column', md: 'row' } }}>
					<Grid
						item
						xs
						sx={{
							flexDirection: 'row',
							display: 'flex',
						}}
					>
						<Grid
							container
							spacing={3}
							sx={{
								marginTop: { md: 12 },
								paddingX: { lg: 'auto' },
								alignContent: 'center',
								flexDirection: 'column',
							}}
						>
							<Grid item>
								<RelatedList
									title={t('form.canvas.related')}
									related={template?.relatedCanvas}
									icon="canvas"
								/>
							</Grid>
							<Grid item>
								<RelatedList
									title={t('form.related')}
									related={template?.relatedForms}
									icon="list"
								/>
							</Grid>
							<Grid item>
								<RelatedList
									title={t('form.badges.related')}
									related={template?.relatedBadges}
									icon="badge"
								/>
							</Grid>
							<Grid item>
								<RelatedList
									title={t('form.features.related')}
									related={ideas?.plataforma?.type === 'Challenge' ? template?.relatedFundings : []}
									icon="feature"
								/>
							</Grid>
							<Grid item>
								<strong>{t('form.useCases')}</strong>
								<List className="tags__group">
									{template?.useCases?.map((useCase, index) => (
										<ListItem key={`use-case-${index}`} className="tag" sx={{ width: 'auto' }}>
											{useCase}
										</ListItem>
									))}
								</List>
							</Grid>
						</Grid>
						<Divider
							orientation="vertical"
							sx={{
								height: '100% !important',
								width: '1px',
								display: { xs: 'none', lg: 'block' },
								marginX: { sm: 1, xl: 6 },
								color: 'var(--fog-gray-color)',
							}}
						/>
					</Grid>
					<Grid item className="inner w-grid-2">
						<DialogBox
							className="s-dialog-title"
							data-tut="template-use"
							sx={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}
						>
							<Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
								<DynamicCircle
									icon={template.slug || 'template'}
									color={template.color}
									size="x-large"
									background={createBackground(template.color)}
								/>
								<DialogTitle variant="h1">
									{template.templateNome}
								</DialogTitle>
								<HelpButton
									size="medium"
									href={t(`urls.${flow}`)}
									onClick={() => BuildTour.setTour(tour, TOUR_STEPS, { currentStep: 0 })}
								/>
							</Box>
							<SButton
								type="button"
								variant="outlined"
								color="primary"
								isLoading={inProgress === 'change'}
								onClick={onChangeTemplate}
							>
								{t('templates.use')}
							</SButton>
						</DialogBox>
						<Grid
							container
							sx={{
								flexDirection: 'column',
								'.section-title': {
									fontWeight: '700', color: 'rgba(0, 0, 0, 0.75)',
								},
							}}
							spacing={3}
						>
							<Grid item xs={12} className="summary">
								<RedactorText html={template.templateDescricao} />
							</Grid>
							<Grid item xs={12} className="figure">
								<img width="100%" src="/images/template_image.png" alt="template_image" />
							</Grid>
							{template.examples?.length > 0 && (
								<Grid item xs={12} className="examples">
									<Typography variant="h6" className="section-title">{`${t('global.examples')}:`}</Typography>
									{template.examples.map((example) => (
										<div>
											<span style={{ marginTop: '15px' }}>{example.title}</span>
											<ul>
												{example.items.map((item) => (
													<li className="">{item}</li>
												))}
											</ul>
										</div>
									))}
								</Grid>
							)}
							{template?.workflowImage && (
								<Grid item xs={12} className="workflow">
									<Typography variant="h6" className="section-title">Workflow</Typography>
									<img src={template.workflowImage} alt="" className="" />
								</Grid>
							)}
							<Grid item xs={12} className="cards">
								<Typography variant="h6" className="section-title">
									{t('templates.more', { flow: t(`global.${flow}`) })}
								</Typography>
								<CardGrid
									id="template"
									queryParams={{ limit: 2 }}
									section={templates}
									type="ideas"
									GridItemProps={{
										xs: 12, sm: 6, md: 6, lg: 6, xl: 6,
									}}
									// loadMore={loadIdeas}
									component={(_template) => (
										<TemplateCard template={_template} />
									)}
								/>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs />
				</Grid>
			</SBox>
		</DialogContent>
	);
};

const Translation = withTranslation('common')(MModalTemplate);

const ModalTemplate = (props: IModal) => (
	<Suspense fallback={<div />}>
		<Translation {...props} />
	</Suspense>
);

export default ModalTemplate;
