// @ts-nocheck
import routes from '@routes/modal';
import { Creators } from '@actions';
import { Grid } from '@mui/material';
import { useDispatch } from 'react-redux';
import Icon from '@components/Shared/Icon';
import SpinnerLDSRipple from '@components/Shared/SpinnerLDSRipple';
import { APIOrganizations } from '@services/apis';
import { IInvite } from 'src/@types/IOrganization';
import { handleAxiosErrors } from '@services/auth';
import { SBox } from '@components/Background/SBox';
import { Suspense, useEffect, useState } from 'react';
import { UserCell } from '@components/DataTable/Cells';
import { TableColumn } from 'react-data-table-component';
import { WithTranslation, withTranslation } from 'react-i18next';
import ModalFeedback, { IFeedbackProps } from '@components/Modals/ModalFeedback';
import TableSearch from '@components/DataTable/TableSearch';
import { SButton } from '@components/Buttons';
import { persistor } from 'src/store';

interface IInvitesState {
	docs: IInvite[]
	isLoading: boolean
}

type TProps = WithTranslation
const _Invites = (props: TProps) => {
	const { t } = props;
	const [invites, setInvites] = useState<IInvitesState>({ docs: [], isLoading: true });

	const [feedbackProps, setFeedbackProps] = useState<IFeedbackProps>({ open: false });

	const dispatch = useDispatch();

	const loadInvites = async () => {
		try {
			setInvites({ docs: [], isLoading: true });
			const response = await APIOrganizations.getInvites();
			setInvites({ ...response.data, isLoading: false });
		} catch (err) {
			handleAxiosErrors(err);
		}
	};

	useEffect(() => {
		loadInvites();
	}, []);

	const onRevokeHandler = async () => {
		try {
			setFeedbackProps((params) => ({ ...params, isLoading: true }));
			await APIOrganizations.deleteInvite(feedbackProps.match._id);
			loadInvites();
			setFeedbackProps({ open: false });
		} catch (err) {
			handleAxiosErrors(err);
			setFeedbackProps({ ...feedbackProps, isLoading: false });
		}
	};

	const columns: TableColumn<IInvite>[] = [
		{
			name: t('form.email'),
			sortable: true,
			maxWidth: '600px', // when using custom you should use width or maxWidth, otherwise, the table will default to flex grow behavior
			minWidth: '210px',
			cell: (row) => <UserCell initial={row._nome || row.nome || row.invitedUser.email} user={{ email: row.invitedUser.email }} />,
		},
		{
			name: t('global.status'),
			// @ts-ignore
			selector: (row) => t(`page_settings.tabcontent.invites.status.${row.status}`),
			sortable: true,
			center: true,
		},
		{
			name: t('global.invite-acceptedAt'),
			selector: (row) => (row.acceptedAt ? t('dates.fullDate', { date: new Date(row.acceptedAt) }) : '-'),
			sortable: true,
			center: true,
		},
		{
			name: t('global.actions'),
			center: true,
			cell: (row) => (
				<SButton
					type="button"
					className="link"
					disabled={row.status === 'canceled' || row.status === 'accepted'}
					onClick={() => setFeedbackProps({ open: true, match: row })}
				>
					{t('settings.revokeInvitation')}
				</SButton>
			),
		},
	];

	return (
		<SBox id="settingsInvites">
			<Grid container className="section__header" spacing={3} flexDirection="column">
				<Grid item xs={12} sx={{ gap: 1, display: 'flex' }}>
					<SButton
						type="button"
						color="secondary"
						className="s-link"
						startIcon={<Icon icon="fa fas fa-user-plus" />}
						onClick={() => dispatch(Creators.MODAL.showModal(
							routes.MODAL_INVITE.path,
							{ callback: loadInvites },
						))}
					>
						<span className="bold">{t('member.inviteNew')}</span>
					</SButton>
					<SButton
						type="button"
						color="secondary"
						className="s-link"
						startIcon={<Icon icon="team" />}
						onClick={() => {
							persistor.pause();
							dispatch(Creators.MODAL.showModal(
								routes.MODAL_BATCH_INVITE.path,
							));
						}}
					>
						<span className="bold">{t('member.bulkInvite')}</span>
					</SButton>
				</Grid>
				<Grid item xs={12}>
					<TableSearch
						columns={columns}
						data={invites.docs}
						progressPending={invites.isLoading}
					/>
				</Grid>
			</Grid>
			{feedbackProps.open && (
				<ModalFeedback
					id="invite"
					onRequestClose={() => setFeedbackProps({ open: false })}
					title={t('page_settings.tabcontent.invites.removeInvite')}
					onRightButton={onRevokeHandler}
					{...feedbackProps}
				/>
			)}

		</SBox>
	);
};

const Translation = withTranslation('common')(_Invites);

const Invites = () => (
	<Suspense fallback={<SpinnerLDSRipple />}>
		<Translation />
	</Suspense>
);

export default Invites;
