// @ts-nocheck
import React, { Suspense, useState, useEffect } from 'react';
import * as Yup from 'yup';
import { WithTranslation, withTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { goToLink, isEmpty } from '@utils/ReactUtils';
import { APITeams } from '@services/apis';
import type { IModal } from '@modules/Modal/types';
import { IRootState } from 'src/store';
import URLS from '@constants/URLS';
import { handleAxiosErrors } from '@services/auth';
import { getValidationErrors } from '@utils/yup';
import {
	SInputLabel, Form, SFormGroup,
} from '@components/DynamicForm/ComponentsCore';
import { HelpButton, SButton } from '@components/Buttons';
import { STextField } from '@forms/Components';
import {
	DialogBox,
} from '@modules/Modal/Components';
import { maxLength } from '@constants/GLOBAL';
import { IPagination } from 'src/@types/IShared';
import { IUserOrganization } from 'src/@types/IUser';
import {
	Box,
	Button, DialogActions, DialogContent, DialogTitle, InputLabel,
} from '@mui/material';
import { SAutocomplete } from '@components/DynamicForm/Components/SSelect/SAutocomplete';
import { getUserOptions, Tags, UserTag } from '@components/DynamicForm/Components/SSelect/Components';
import { SToast } from '@modules/SToast';
import { SBox } from '@components/Background/SBox';
import { ColorPalette, colorsCore } from '@components/Shared/ColorPalette';
import { Creators } from '../../../store/ducks/actionsTypes';

declare type TProps = IModal & WithTranslation;

const MModalTeam = (props: TProps) => {
	const { t, routes } = props;
	const { userOrganizationProfile } = useSelector((state: IRootState) => state);
	const params = useSelector((state: IRootState) => state.modal?.ModalProps);

	const dispatch = useDispatch();

	const [users, setUsers] = useState({
		docs: [],
		isLoading: true,
	});
	const [inProgress, setInProgress] = useState(false);
	const [errors, setErrors] = useState({});
	const hasId = Object.hasOwnProperty.call(params, 'teamId');
	const [team, setTeam] = useState({
		nome: '',
		descricao: '',
		cor: colorsCore[8].value,
		membros: [],
		isLoading: hasId,
	});

	const [hasDescription, setHasDescription] = useState(params.teamId && team.descricao?.length > 0);

	const onSubmit = async (event) => {
		event?.preventDefault();
		try {
			setInProgress(true);
			const schema = Yup.object().shape({
				nome: Yup.string().required(t('messages.requiredField')),
				// membros: Yup.array().min(1, t('messages.requiredField')),
			});

			const newTeam = {
				membros: team.membros.map((item) => item._id),
				nome: team.nome,
				descricao: team.descricao,
				cor: team.cor,
			};

			await schema.validate(newTeam, {
				abortEarly: false,
			});

			if (hasId) {
				await APITeams.patchTeam(params.teamId, newTeam);
				SToast.success(t('messages.success.team.updated'));
				dispatch(Creators.MODAL.hideModal());
			} else {
				const response = await APITeams.postCreateTeam(newTeam);
				SToast.success(t('messages.success.team.created'));
				dispatch(Creators.MODAL.hideModal());
				setTimeout(() => {
					goToLink(`${URLS.TEAMS}/${response.data._id}`);
				}, 1000);
			}
		} catch (err) {
			if (err instanceof Yup.ValidationError) {
				setErrors(getValidationErrors(err));
				return;
			}

			handleAxiosErrors(err);
		} finally {
			setInProgress(false);
		}
	};

	const loadTeam = async () => {
		try {
			const response = await APITeams.getTeam(params.teamId);

			setTeam({
				...response.data,
				isLoading: false,
			});
		} catch (err) {
			handleAxiosErrors(err);
		}
	};

	const loadParticipants = () => {
		setUsers({ ...users, isLoading: true });
		dispatch(Creators.SHARED.getParticipants({
			onSuccess: (response: IPagination<IUserOrganization>) => {
				setUsers({ ...response, isLoading: false });
			},
		}));
	};

	useEffect(() => {
		if (hasId) {
			loadTeam();
		}

		loadParticipants();
	}, []);

	const onErrorHandler = (field) => {
		if (!isEmpty(errors)) delete errors[field];
	};

	const handleTeam = (e: React.KeyboardEvent<HTMLInputElement>) => {
		onErrorHandler(e.target.name);

		if (hasId && typeof params.syncTeam === 'function') {
			params.syncTeam({ [e.target.name]: e.target.value });
		}

		setTeam({
			...team,
			[e.target.name]: e.target.value,
		});
	};

	return (
		<DialogContent id="TeamDialog">
			<DialogBox className="s-dialog-title">
				<DialogTitle variant="h6">
					{hasId ? t('team.edit') : t('team.create')}
				</DialogTitle>
			</DialogBox>
			<SBox loading={team.isLoading}>
				<DialogBox className="s-dialog-box">
					<Form
						id="team-form"
						className="s-form"
					>
						<SFormGroup>
							<SInputLabel htmlFor="team-name" error={typeof errors?.nome === 'string'}>
								{t('team.name')}
							</SInputLabel>
							<STextField
								id="team-name"
								name="nome"
								fullWidth
								autoFocus
								maxLength={maxLength.teamName}
								value={team.nome}
								onChange={handleTeam}
								placeholder={t('form.placeholder.team.name')}
								error={typeof errors?.nome === 'string'}
								helperText={errors?.nome}
							/>
						</SFormGroup>
						<SFormGroup>
							{hasDescription ? (
								<>
									<SInputLabel htmlFor="team-description">
										{t('form.description')}
									</SInputLabel>
									<STextField
										multiline
										name="descricao"
										maxRows="4"
										rows="4"
										maxLength={maxLength.default250}
										id="team-description"
										value={team.descricao}
										placeholder={t('form.placeholder.description')}
										onChange={handleTeam}
									/>
								</>

							) : (
								<div
									className="text-blue bold s-label"
									aria-hidden
									onClick={() => setHasDescription(!hasDescription)}
								>
									{t('global.insert_description')}
								</div>
							)}
						</SFormGroup>
						<SFormGroup>
							<Box className="s-label-group s-label">
								<InputLabel htmlFor="members-autocomplete">
									{t('global.members')}
								</InputLabel>
								<HelpButton color="secondary" className="tour" href={t('urls.members')} />
							</Box>
							<SInputLabel htmlFor="members-autocomplete">
								{t('global.members')}
							</SInputLabel>
							<SAutocomplete
								id="members-autocomplete"
								placeholder={t('form.select')}
								unique="_id"
								value={getUserOptions(team.membros) || []}
								options={getUserOptions(users.docs)}
								onChange={(event, value) => handleTeam({ target: { name: 'membros', value } })}
								disableCloseOnSelect
								renderTags={(opts, getTagProps) => (
									<Tags
										value={opts}
										component={(option, index) => (
											<UserTag renderTags option={option} {...getTagProps({ index })} />
										)}
									/>
								)}
								renderOption={(_props, option, { selected }) => <li {..._props}><UserTag option={option} /></li>}
							/>
						</SFormGroup>
						<SFormGroup>
							<SInputLabel htmlFor="team-color">
								{t('global.color')}
							</SInputLabel>
							<ColorPalette
								id="team-color"
								value={team.cor}
								onChange={handleTeam}
								name="cor"
							/>
						</SFormGroup>
					</Form>
				</DialogBox>
			</SBox>
			<DialogActions className="s-dialog-actions">
				<Button type="button" color="secondary" onClick={() => dispatch(Creators.MODAL.hideModal())}>
					{t('global.cancel')}
				</Button>
				<SButton
					type="submit"
					color="success"
					onClick={onSubmit}
					isLoading={inProgress}
					error={!isEmpty(errors)}
				>
					{hasId ? t('global.save') : t('global.create')}
				</SButton>
			</DialogActions>
		</DialogContent>
	);
};

const Translation = withTranslation('common')(MModalTeam);

const ModalTeam = (props: IModal) => (
	<Suspense fallback={<DialogContent />}>
		<Translation {...props} />
	</Suspense>
);

export default ModalTeam;
