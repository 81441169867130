import { useTour } from '@reactour/tour';
import { useDispatch } from 'react-redux';
import React, { Suspense, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Creators } from '@actions';
import { APIIdeas } from '@services/apis';
import { IBadge } from 'src/@types/IBadge';
import { BuildTour } from '@components/STour';
import { IPagination } from 'src/@types/IShared';
import { createBackground } from '@utils/helpers';
import { handleAxiosErrors } from '@services/auth';
import RedactorText from '@components/Shared/RedactorText';
import { UserUpdated } from '@components/Shared/UserUpdated';
import { DynamicCircle, DynamicCircleProps, TSizeCircle } from '@components/DynamicCircle';
import ModalFeedback, { IFeedbackProps } from '@components/Modals/ModalFeedback';
import { SToast } from '@modules/SToast';
import { Box, List, ListItem, Typography } from '@mui/material';
import { SBox } from './SBox';

export declare type TBadgeActions = 'edit' | 'view' | 'reward' | 'remove' | 'insert' | 'add' | undefined

interface IBBadgesProps {
	id: string
	ideaId: string
	className?: string
	size?: TSizeCircle
	isDisabled: boolean
	CreateProps?: DynamicCircleProps
	hasEmptyState?: boolean
	updateBadges?: () => void
	readonly mode: TBadgeActions
	flow: 'brainstorm' | 'challenge'
	readonly badges: IPagination<IBadge>
	orientation?: 'vertical' | 'horizontal'
	routes: { badges?: string, badge: string }
	onInsert?: (badgeId: string, callback: Function) => void
	components?: {title: React.ReactNode}
}

/* Modal Badges Actions
		- Horizontal View | Edit
		- Vertical View | Add, Insert
*/

declare type TProps = IBBadgesProps & WithTranslation

const _BBadges = (props: TProps) => {
	const {
		t, CreateProps, badges, hasEmptyState, routes, flow, mode, size,
		orientation = 'horizontal', id, className, components,
		ideaId, updateBadges, onInsert, isDisabled,
	} = props;

	const tour = useTour();
	const [feedbackProps, setFeedbackProps] = useState<IFeedbackProps<IBadge>>({ open: false });
	const [inProgress, setInProgress] = useState(['', '']);

	const dispatch = useDispatch();

	const setModalFeedback = (_badge: IBadge) => {
		if (_badge.premiada) {
			setFeedbackProps({
				title: t('feedback.delete.badge.title', { badge: `${_badge.nome}` }),
				rightButtonText: t('global.delete'),
				open: true,
				color: 'error',
				match: _badge,
			});
			return;
		}

		setFeedbackProps({
			title: t('idea.reward'),
			color: 'success',
			rightButtonText: t('global.confirm'),
			open: true,
			match: _badge,
		});
	};

	const onAward = async () => {
		try {
			setFeedbackProps((prevState) => ({ ...prevState, isLoading: true }));
			const { rewardId, _id, premiada } = (feedbackProps.match as IBadge);
			if (premiada && rewardId) {
				await APIIdeas.postAwardIdea(ideaId, rewardId);
				SToast.success(t('messages.success.awardRemoved'));
			} else {
				await APIIdeas.postReward(ideaId, _id);
				SToast.success(t('messages.success.ideaAwarded'));
			}
			if (typeof updateBadges === 'function') {
				updateBadges();
			}
			setFeedbackProps({ open: false });
		} catch (err: unknown) {
			handleAxiosErrors(err);
		} finally {
			setFeedbackProps((prevState) => ({ ...prevState, isLoading: false }));
		}
	};

	const onViewHandler = (badge: IBadge, action: TBadgeActions) => {
		if (!action) return;
		BuildTour.close(tour);
		dispatch(Creators.MODAL.handleModal({ flow }));

		if (action === 'add' && typeof onInsert === 'function') {
			setInProgress(['add', badge._id]);
			onInsert(badge._id, () => setInProgress(['', '']));
			return;
		}

		if (mode === 'reward') {
			setModalFeedback(badge);
			return;
		}
		dispatch(Creators.BADGES.setBadge(badge));
		dispatch(Creators.MODAL.showModal(routes.badge, { action }));
	};

	return (
		<SBox
			id={id}
			loading={badges.isLoading || !badges || !badges.docs}
			empty={(badges.docs?.length === 0 && hasEmptyState) ? 'badges' : undefined}
			className={`background__badges ${className || ''}`}
		>
			{components?.title}
			<List className={orientation === 'vertical' ? 'list' : 'horizontal-list'}>
				{CreateProps && (
					<ListItem className="list-item" key="badge-new" data-tut="badge-create">
						<DynamicCircle
							icon="plus"
							size="middle"
							className="default-colors"
							clickable={!isDisabled}
							disabled={isDisabled}
							onClick={() => {
								BuildTour.close(tour);
								dispatch(Creators.MODAL.showModal(routes.badges));
							}}
							{...CreateProps}
						/>
						{ orientation === 'vertical' && (
							<div className="container-content">
								<div className="title">{CreateProps.title}</div>
								<div className="content-text">
									{t('form.badges.scratch')}
								</div>
							</div>
						)}

						{orientation === 'horizontal' && (
							<Typography variant="caption" className="item-description">
								{CreateProps.title}
							</Typography>
						)}
					</ListItem>
				)}
				{badges.docs?.map((badge, index) => {
					if (!badge || !badge.cor) return null;
					const action: TBadgeActions = (function () {
						if (mode === 'view') return 'view';

						if (mode === 'reward') {
							if (badge.premiada) return 'remove';
							return 'reward';
						}
						if (mode === 'add') return 'add';
						if (mode === 'edit') return 'edit';
						return undefined;
					}());

					return (
						<ListItem
							key={badge._id}
							data-tut={`template-${index}-${orientation}`}
							className={`list-item ${(['add'].includes(inProgress[0])
								&& [badge._id].includes(inProgress[1])) ? 'loading' : ''}`}
						>
							<DynamicCircle
								color={badge?.cor}
								background={createBackground(badge.cor)}
								icon="badge"
								size={size || 'middle'}
								action={action ? t(`global.${action}`) : undefined}
								onClick={() => onViewHandler(badge, action)}
							/>
							{orientation === 'vertical' && (
								<div
									aria-hidden
									className="container-content"
									onClick={mode === 'add'
										? () => onViewHandler(badge, 'insert')
										: undefined}
									style={{ cursor: mode === 'add' ? 'pointer' : 'auto' }}
								>
									<Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
										<div className="bold">{badge.nome}</div>
										<p className="">
											{`${t('form.difficulty')}: `}
											<span className="first-letter-capitalize">{t(`form.badges.level.${badge.dificuldade}`)}</span>
										</p>
									</Box>

									<RedactorText html={badge.descricao} hasToggle className="content-text" />
									{badge.moderador
										&& (
											<UserUpdated
												profile={badge.moderador}
												updatedAt={badge.data_premiacao}
												prefixTarget={`badge-${badge._id}`}
												hasPopover
											/>
										)}
								</div>
							)}
							{orientation === 'horizontal' && (
								<Typography variant="caption" className="item-description">{badge.nome}</Typography>
							)}
						</ListItem>
					);
				})}
			</List>
			{feedbackProps.open && (
				<ModalFeedback
					id="idea-badge"
					onRequestClose={() => setFeedbackProps({ open: false })}
					onRightButton={onAward}
					{...feedbackProps}
				/>
			)}
		</SBox>
	);
};

const Translation = withTranslation('common')(_BBadges);

const BBadges = (props: IBBadgesProps) => (
	<Suspense fallback={<div />}>
		<Translation {...props} />
	</Suspense>
);

export { BBadges };
