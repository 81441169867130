// @ts-nocheck
import Icon from '@components/Shared/Icon';
import React from 'react';

const SwitchFilter = (props) => {
	const {
		left = {},
		right = {},
		className,
	} = props;

	return (
		<label className={`switch-btn ${className || ''}`}>
			{/* <input
				type="checkbox"
				name={props.name}
				value={props.value}
				checked={props.checked}
				onChange={(value) => props.onChange(value)}
			/> */}
			<div
				aria-hidden
				className={left.selected ? 'switch-left' : ''}
				onClick={() => props.onChange(left)}
			>
				<Icon icon={left.icon} />
				<span>{left.label}</span>
			</div>
			<div
				aria-hidden
				className={right.selected ? 'switch-right' : ''}
				onClick={() => props.onChange(right)}
			>
				<Icon icon={right.icon} />
				<span>{right.label}</span>
			</div>

		</label>
	);
};

export { SwitchFilter };
