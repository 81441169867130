import React from 'react';
import {
	Tabs, Tab, AppBar, useTheme, SxProps, Theme, Grid, TabProps,
} from '@mui/material';
import { showTab } from '@utils/helpers';
import { useLocation, useNavigate } from 'react-router-dom';

export interface ISTab {
	value?: any
	label?: React.ReactNode
	hide?: boolean
	iconPosition?: 'top' | 'bottom' | 'start' | 'end'
	icon?: string | React.ReactElement<any, string | React.JSXElementConstructor<any>> | undefined
}

export interface ISTabsProps {
	id: string
  tabs: Array<ISTab & TabProps>
	active?: string
	children?: React.ReactNode
	className?: string
	historyMode?: 'primary' | 'secondary' | 'inherit'
	textColor?: 'secondary' | 'primary' | 'inherit'
	variant?: 'standard' | 'scrollable' | 'fullWidth'
	indicatorColor?: 'secondary' | 'primary' | undefined
	TabIndicatorProps?: React.HTMLAttributes<HTMLDivElement> & { sx?: SxProps<Theme> }
	classes?: { appBar?: string}
	sx?: { tabs?: SxProps<Theme>, sTabs?: SxProps<Theme>, appBar?: SxProps<Theme>}
	noAccess?: boolean
	orientation?: 'horizontal' | 'vertical' | undefined
	TabsProps?: React.HTMLAttributes<HTMLDivElement> & { sx?: SxProps<Theme> }
	TabPanelProps?: React.HTMLAttributes<HTMLDivElement> & { sx?: SxProps<Theme> }
	centered?: boolean
	components?: { BeforeTabs?: React.ReactNode}
	onChange?: (newValue: string, event: React.SyntheticEvent) => void
}

export const STabs = (props: ISTabsProps) => {
	const {
		tabs, children, className = '', id, active, historyMode, variant = 'fullWidth', sx, classes, noAccess,
		TabPanelProps, TabsProps, components, onChange,
		...rest
	} = props;

	const navigate = useNavigate();
	const location = useLocation();

	const theme = useTheme();
	const [value, setValue] = React.useState<string>(active || tabs[0].value);

	const noHistory: string[] = [];

	tabs.forEach((item) => {
		if (typeof item.onClick === 'function') {
			noHistory.push(item.value);
		}
	});

	const handleHistory = (tab: string) => {
		let url = '';
		const params = {
			tab: new URLSearchParams(location.search)?.get('tab'),
			focus: new URLSearchParams(location.search)?.get('focus'),
		};

		if (historyMode === 'primary' && id) {
			url += `?tab=${`${tab}-tab`}`;
		}

		if (params.tab && historyMode === 'secondary') {
			url += `?tab=${params.tab}&focus=${`${tab}-tab`}`;
		}

		navigate(url, { replace: true });
	};

	const handleChange = (event: React.SyntheticEvent, newValue: string) => {
		setValue(newValue);

		if (typeof onChange === 'function') {
			onChange(newValue, event);
		}

		if (noHistory.includes(newValue)) {
			return;
		}

		if (historyMode && ['primary', 'secondary'].includes(historyMode)) {
			handleHistory(newValue);
		}
	};

	React.useEffect(() => {
		const params = {
			tabId: new URLSearchParams(location.search)?.get('tab'),
			focusId: new URLSearchParams(location.search)?.get('focus'),
		};

		if (typeof params.tabId === 'string') {
			showTab({ tabId: params.tabId });
			if (typeof params.focusId === 'string') {
				setTimeout(() => {
					// @ts-ignore
					showTab({ tabId: params.focusId });
				}, 300);
			}
		}
	}, []);

	const _children = Array.isArray(children)
		? children
		: [children];

	const indexActivated = tabs.findIndex((item) => item.value === value);

	return (
		<Grid container className={`s-tabs ${className}`} sx={sx?.sTabs}>
			<Grid item xs={12} {...TabsProps} className="s-appBar">
				{components?.BeforeTabs}
				<AppBar
					position="static"
					className={classes?.appBar || ''}
					color="transparent"
					sx={{
						...sx?.appBar,
						boxShadow: 'none',
						'.MuiTab-root': {
							textTransform: 'none',
						},
					}}
				>
					<Tabs
						id={id}
						value={value}
						onChange={handleChange}
						variant={variant}
						aria-label="full width tabs example"
						sx={sx?.tabs}
						scrollButtons
						allowScrollButtonsMobile
						{...rest}
					>
						{tabs.map((tab) => {
							const { hide, ...p } = tab;
							if (hide) return null;
							return (
								// @ts-ignore
								<Tab
									key={tab.value}
									data-tut={`${id}-${tab.value}`}
									id={`${tab.value}-tab`}
									aria-controls={`tabpanel-${tab.value}`}
									component="a"
									sx={{ minHeight: '48px' }}
									{...p}
								/>
							);
						})}
					</Tabs>
				</AppBar>
			</Grid>

			{/* <SwipeableViews
				id={`${id}-view`}
				axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
				index={indexActivated}
				// onChangeIndex={handleChangeIndex}
			> */}
			<Grid item xs={12} {...TabPanelProps} className="s-tabpanel">
				{_children
					.filter((child) => child !== false && typeof child !== 'undefined')
					.map((sTab, index) => React.cloneElement(sTab, {
					// eslint-disable-next-line comma-dangle, react/no-array-index-key
						dir: theme.direction, active: value, key: index, noAccess
					}), this)}
				{/* </SwipeableViews> */}
			</Grid>
		</Grid>
	);
};
