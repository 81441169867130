// @ts-nocheck

import React from 'react';
import { SButton } from '@components/Buttons';
import Icon from '@components/Shared/Icon';

interface ILinkedInButtonProps {
	className?: string
	children: React.ReactNode
	orgId: string
}

const LinkedInButton = ({
	children, className, orgId,
}: ILinkedInButtonProps) => {
	const linkedInLogin = () => {
		const url = 'https://www.linkedin.com/oauth/v2';
		const response = 'response_type=code';

		const { LINKED_IN_CLIENT_ID, LINKED_IN_REDIRECT_LINK } = process.env;

		const linkedIn = `${url}/authorization?${response}&state=${orgId}&scope=r_liteprofile r_emailaddress&client_id=${LINKED_IN_CLIENT_ID}&redirect_uri=${LINKED_IN_REDIRECT_LINK}`;
		window.location.replace(linkedIn);
	};

	return (
		<SButton
			id="inSignInButton"
			onClick={linkedInLogin}
			variant="contained"
			className={`btn-integration linkedin ${className || ''}`}
			startIcon={<Icon icon="linkedin" className="social-icon" />}
		>
			{children}
		</SButton>
	);
};

export { LinkedInButton };
