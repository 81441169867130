import React from 'react';
import { IconButton } from '@mui/material';
import Icon from '@components/Shared/Icon';
import { IAnswer } from 'src/@types/IIdea';
import AnsersListComponent from '@components/QA/AnswersList';
import { SBox } from '@components/Background/SBox';

export interface GenerateAnswersParams {
	text: string
	callback?: () => void
}

interface SeeResultsProps {
	isLoading: boolean
	textName: string
	setSeeResults: React.Dispatch<React.SetStateAction<boolean>>
	list: IAnswer[]
}

const SeeResults = (props: SeeResultsProps) => {
	const {
		list,
		textName,
		isLoading,
		setSeeResults,
	} = props;

	return (
		<SBox loading={isLoading}>
			<IconButton
				aria-label="back"
				sx={{ marginBottom: 3 }}
				onClick={() => setSeeResults(false)}
			>
				<Icon icon="go-back" />
			</IconButton>
			<div className="accordion-item" style={{ border: 'none' }} />
			{ list.length > 0
      && <AnsersListComponent list={list || []} textName={textName} haveBottomBorder /> }
		</SBox>
	);
};

export default SeeResults;
