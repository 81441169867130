import ModalBadges from '@components/Modals/Badges/ModalBadges';
import ModalBadge from '@components/Modals/Badges/ModalBadge';
import ModalPlatform from '@components/Modals/ModalPlatform';
import ModalTemplates from '@components/Modals/Templates/ModalTemplates';
import ModalCanvasEdit from '@components/Modals/Canvas/ModalCanvasEdit';
import ModalForm from '@components/Modals/Forms/ModalForm';
import ModalForms from '@components/Modals/Forms/ModalForms';
import ModalCanvas from '@components/Modals/Canvas/ModalCanvas';
import ModalTemplate from '@components/Modals/Templates/ModalTemplate';

const rootPath = '/:platform';

const ModalPlatformsRouter = {
	MODAL_PLATFORM: {
		path: rootPath,
		component: ModalPlatform,
		fullScreen: true,
		maxWidth: 'xl',
	},
	MODAL_PLATFORM_TEMPLATES: {
		path: `${rootPath}/<global.template>s`,
		component: ModalTemplates,
		fullScreen: true,
		maxWidth: 'xl',
	},
	MODAL_PLATFORM_TEMPLATE: {
		path: `${rootPath}/<global.template>s/:template`,
		component: ModalTemplate,
		fullScreen: true,
		maxWidth: 'xl',
	},
	MODAL_PLATFORM_BADGES: {
		path: `${rootPath}/<global.badges>`,
		component: ModalBadges,
		fullScreen: true,
		maxWidth: 'xl',
	},
	MODAL_PLATFORM_BADGE: {
		path: `${rootPath}/<global.badges>/:badge`,
		component: ModalBadge,
		fullScreen: true,
		maxWidth: 'xl',
	},
	MODAL_PLATFORM_BADGE_DIRECT: {
		path: `${rootPath}/:badge`,
		component: ModalBadge,
		fullScreen: true,
		maxWidth: 'xl',
	},
	MODAL_PLATFORM_CANVAS: {
		path: `${rootPath}/canvas`,
		component: ModalCanvas,
		fullScreen: true,
		maxWidth: 'xl',
		next: `${rootPath}/canvas/:model`,
		prev: `${rootPath}`,
	},
	MODAL_PLATFORM_CANVAS_MODEL: {
		path: `${rootPath}/canvas/:model`,
		component: ModalCanvasEdit,
		fullScreen: true,
		maxWidth: 'xl',
	},
	MODAL_PLATFORM_CANVAS_DIRECT: {
		path: `${rootPath}/:model`,
		component: ModalCanvasEdit,
		fullScreen: true,
		maxWidth: 'xl',
	},
	MODAL_PLATFORM_FORMS: {
		path: `${rootPath}/<global.form>s`,
		component: ModalForms,
		fullScreen: true,
		maxWidth: 'xl',
	},
	MODAL_PLATFORM_FORM: {
		path: `${rootPath}/<global.form>s/:form`,
		component: ModalForm,
		fullScreen: true,
		maxWidth: 'xl',
	},
	MODAL_PLATFORM_FORM_DIRECT: {
		path: `${rootPath}/:form`,
		component: ModalForm,
		fullScreen: true,
		maxWidth: 'xl',
	},
};

export { ModalPlatformsRouter };
