// @ts-nocheck
import React from 'react';
import { Provider } from 'react-redux';
import ReactGA from 'react-ga4';
import { hotjar } from 'react-hotjar';
import { BrowserRouter, MemoryRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import ErrorBoundary from 'src/modules/ErrorBoundary';
import GLOBAL from '@constants/GLOBAL';
import PageLoader from '@components/Loaders/PageLoader';
import ModalModule from '@modules/Modal';
import { TourProvider, useTour } from '@reactour/tour';
import { SocketIO, SocketIOContext } from '@services/socket';
import { ThemeProvider } from '@mui/material';
import { ToastContainer } from 'react-toastify';
import { store, persistor } from './store';
import appPackage from '../package';

import { theme } from './styles/theme';
import Routed from './routes';

console.log('IS DEVELOPMENT', GLOBAL.IS_DEVELOPMENT);
console.log(`=== Version ${appPackage.version} ===`);
console.log('Width', screen.width);

const App = () => {
	ReactGA.initialize(process.env.GOOGLE_ANALYTICS_ID);
	ReactGA.send('pageview');

	const { steps } = useTour();

	hotjar.initialize(process.env.HOTJAR_ID, 6);

	return (
		<ThemeProvider theme={theme}>
			<Provider store={store}>
				<ErrorBoundary>
					<SocketIOContext.Provider value={SocketIO}>
						<PersistGate loading={<PageLoader />} persistor={persistor}>
							<ToastContainer
								hideProgressBar={false}
								newestOnTop={false}
								closeOnClick
								rtl={false}
								pauseOnFocusLoss
								draggable
								pauseOnHover
								limit={3}
							/>
							<TourProvider
								steps={steps}
								className="reactour dark p-3"
								showNavigation={false}
								position={window.screen.width > 1366 ? 'right' : 'top'}
								showBadge={false}
								showCloseButton={false}
								afterOpen={() => {
									const modal = document.querySelector('.MuiDialogContent-root');
									modal?.classList.add('scrollableY-hidden');
									document.body.style.overflowY = 'hidden';
								}}
								beforeClose={() => {
									const modal = document.querySelector('.MuiDialogContent-root');
									modal?.classList.remove('scrollableY-hidden');
									document.body.style.overflowY = 'auto';
								}}
							>
								<MemoryRouter>
									<ModalModule />
								</MemoryRouter>
								<BrowserRouter>
									<Routed />
								</BrowserRouter>
							</TourProvider>
						</PersistGate>
					</SocketIOContext.Provider>
				</ErrorBoundary>
			</Provider>
		</ThemeProvider>
	);
};

export default App;
