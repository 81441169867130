// @ts-nocheck
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { IUsersGroupCircleProps, UsersGroupCircle } from '@components/Avatar/UsersGroupCircle';
import { UserAvatar } from '@components/Avatar/UserAvatar';
import { IUserOrganization } from 'src/@types/IUser';

interface IOwnProps extends IUsersGroupCircleProps {
	prefixTarget?: string
	hasPopover?: boolean
	updatedAt?: string
	className?: string
	prefixByUpdatedAt?: string
	prefixBy?: string
	tooltipIsMoreText?: string
	group?: IUserOrganization[]
	profile?: IUserOrganization
}

declare type TProps = IOwnProps & WithTranslation

const UUserUpdated = (props: TProps) => {
	const {
		t,
		profile,
		className = '',
		fontSize = '14px',
		group = [],
		updatedAt,
		prefixBy,
		prefixByUpdatedAt,
		onMore,
		hasPopover,
		tooltipIsMoreText,
		prefixTarget = 'user_updated',
	} = props;

	return updatedAt ? (
		<div
			className={`align-items-center font-light d-flex ${className}`}
			style={{ fontSize }}
		>
			<div className="align-items-center d-flex">
				<div className="mr-1 font-light">
					{prefixBy && <span className="mr-1">{prefixBy}</span>}
					<span className="">{t('global.by')}</span>
				</div>
				{group.length > 0
					? (
						<UsersGroupCircle
							id="evaluators"
							group={group}
							hasMore
							hasPopover={hasPopover}
							prefixTarget={prefixTarget}
							tooltipIsMoreText={tooltipIsMoreText}
							onMore={typeof onMore === 'function' ? onMore : undefined}
						/>
					)
					: profile && (
						<div className="align-items-center d-flex">
							<UserAvatar
								profile={profile}
								size="x-small"
								hasPopover={hasPopover}
								target={`${prefixTarget}_${profile._id}`}
							/>

							<span className="bold ml-1" style={{ fontSize }}>
								{profile.user._nome || profile.user.nome}
							</span>
						</div>
					)}
			</div>
			<div style={{ fontSize }} className="ml-1 font-light">
				{prefixByUpdatedAt ? `${prefixByUpdatedAt} ${t('dates.fullDate', { date: new Date(updatedAt) })}`
					: t('global.in', { date: new Date(updatedAt) })}
			</div>
		</div>
	) : null;
};

const Translation = withTranslation('common')(UUserUpdated);

const UserUpdated = (props: IOwnProps) => (
	<Translation {...props} />
);

export { UserUpdated };
