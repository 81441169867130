// @ts-nocheck
import React, { Suspense, useEffect, useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import _ from 'lodash';
import { UsersGroupCircle } from '@components/Avatar/UsersGroupCircle';
import URLS from '@constants/URLS';
import { ITeam } from 'src/@types/ITeam';
import { Box, Tooltip } from '@mui/material';
import { useNavigate } from 'react-router-dom';

interface IOwnProps {
	team: ITeam
	clickable?: boolean
}

declare type TProps = IOwnProps & WithTranslation

const TTeamCard = (props: TProps) => {
	const { team, t, clickable = true } = props;
	const navigate = useNavigate();

	const href = `${URLS.TEAMS}/${team._id}`;

	const [persistValues, setPersistValues] = useState({
		targetPublishedAt: `data_published_${team._id}_${_.uniqueId(
			team._id,
		)}`,
	});

	const onClickCard = (event) => {
		event.stopPropagation();
		navigate(href);
	};

	useEffect(() => {
		setPersistValues((prevState) => ({ ...prevState }));
	}, []);

	return (
		<Box className="box-item card-box" sx={{ cursor: clickable ? 'pointer' : 'auto' }}>
			<figure className="rollover" onClick={(event) => onClickCard(event)} aria-hidden>
				<div
					className="align-items-center box-photo d-flex justify-content-center"
					style={{
						backgroundColor: team.cor || 'var(--primary)',
						fontSize: '4rem',
						color: 'white',
					}}
				>
					{team.nome?.getInitials()}
				</div>
				<div>
					<div className="hover-item">
						<div className="top">
							<div className="top-left" />
							<div className="top-right" />
						</div>
						<div className="middle" />
						<div className="bottom" />
					</div>
				</div>
			</figure>
			<div className="box-info">
				<div className="content-body">
					<div className="content-infos">
						<Tooltip
							placement="top"
							classes={{ popper: 'secondary' }}
							arrow
							title={team.nome}
						>
							<a href={href} className={`item-title ${clickable ? 'clickable' : ''}`}>
								{team.nome}
							</a>
						</Tooltip>
					</div>
					<div className="d-flex pt-2 pb-3">
						<div className="mt-1 d-flex">
							<Tooltip
								classes={{ popper: 'secondary' }}
								placement="top"
								title={t('global.publishDate')}
								arrow
							>
								<div
									id={persistValues.targetPublishedAt}
									className="date ml-auto align-items-center d-flex "
								>
									{t('global.created_in', { date: new Date(team.createdAt) })}
								</div>

							</Tooltip>
						</div>
					</div>
				</div>
				<div className="footer style2">
					<UsersGroupCircle
						prefixTarget="team_card"
						group={team.membros}
						numberMembers="5"
						tooltipIsMoreText={t('global.participants')}
						hasPopover
						hasMore
						clickable
					/>
				</div>
			</div>
		</Box>
	);
};

const Translation = withTranslation('common')(TTeamCard);

const TeamCard = (props: IOwnProps) => (
	<Suspense fallback={<div />}>
		<Translation {...props} />
	</Suspense>
);

export { TeamCard };
