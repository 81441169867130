// @ts-nocheck
import React, { useEffect, Suspense, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { WithTranslation, withTranslation } from 'react-i18next';
import ModalHeader from '@modules/Modal/ModalHeader';
import { APIForms } from '@services/apis';
import { FLOWS } from '@constants/GLOBAL';
import type { IModal } from '@modules/Modal/types';
import { IRootState } from 'src/store';
import { IForm } from 'src/@types/IForm';
import { DialogBox } from '@modules/Modal/Components';
import { handleAxiosErrors } from '@services/auth';
import { Creators } from '@actions';
import { BForms, TFormActions } from '@components/Background/BForms';
import { DynamicCircle } from '@components/DynamicCircle';
import { DialogContent, DialogTitle, Typography } from '@mui/material';
import { useTour } from '@reactour/tour';
import { BuildTour, STour } from '@components/STour';
import { GenericGrid, W_GRID } from '@components/Grids/GenericGrid';
import { HelpButton } from '../../Buttons/HelpButton';

declare type TProps = IModal & WithTranslation
const ICON = 'form';
const MModalForms = (props: TProps) => {
	// @ts-ignore
	const { t, routes } = props;
	const { ideas, platforms, shared } = useSelector((state: IRootState) => state);
	const { flow } = useSelector((state: IRootState) => state.modal);
	const [templates, setTemplates] = useState({ docs: [], isLoading: true });
	const [forms, setForms] = useState({ docs: [], isLoading: true });
	const mode = {
		idea: ideas?.mode,
		brainstorm: platforms.mode,
		challenge: platforms.mode,
	};

	const tour = useTour();
	const dispatch = useDispatch();

	const rootPath = {
		idea: ideas.nome,
		challenge: platforms.nome,
		brainstorm: platforms.nome,
	};

	const TOUR_STEPS = [
		{
			id: templates.docs.length > 1 ? 'template-0-vertical' : 'form-create',
			selector: templates.docs.length > 1 ? '[data-tut="template-0-vertical"]' : '[data-tut="form-create"]',
			title: t(`tour.forms.${templates.docs.length > 1 ? 'add' : 'create'}.title`),
			description: t(`tour.forms.${templates.docs.length > 1 ? 'add' : 'create'}.description`),
			action: t(`tour.forms.${templates.docs.length > 1 ? 'add' : 'create'}.action`),
		},
		{
			id: 'modal-back',
			selector: '[data-tut="modal-back"]',
			title: t('tour.modal.back.title'),
			description: t('tour.modal.back.description'),
			action: t('tour.modal.back.action'),
		},
	];

	const paramsPaths = (function () {
		if ([FLOWS.IDEA].includes(flow)) {
			return { form: routes.MODAL_IDEA_FORM.path };
		}
		if ([FLOWS.CHALLENGE, FLOWS.BRAINSTORM].includes(flow)) {
			return { form: routes.MODAL_PLATFORM_FORM.path };
		}
		return undefined;
	}());

	const newForm = {
		name: t('form.blank'),
		difficulty: 'alta',
		color: 'rgb(76, 122, 144)',
		useCases: '',
		fields: [
			{
				id: 'field-0',
				name: 'field-0',
				inputype: 'textinput',
				value: '',
				required: false,
			},
		],
	};

	const onTourHandler = () => {
		if (shared?.tour === FLOWS.IDEA) {
			tour.setCurrentStep(6);
		}
		if (shared.tour === FLOWS.CHALLENGE) {
			tour.setCurrentStep(8);
		}
		if (shared.tour === FLOWS.BRAINSTORM) {
			tour.setCurrentStep(6);
		}
		tour.setIsOpen(false);
	};

	const loadTemplates = async (ids: string[], startTour?: boolean) => {
		try {
			setTemplates({ ...templates, isLoading: true });
			const response = await APIForms.getTemplates(ids);
			setTemplates({ docs: response.data, isLoading: false });
			if (startTour) {
				BuildTour.setTour(tour, TOUR_STEPS, { currentStep: 0 });
			}
		} catch (err) {
			handleAxiosErrors(err);
		}
	};

	const loadForms = (startTour?: boolean) => {
		setForms({ ...forms, isLoading: true });
		dispatch(Creators.FORMS.getForms({
			platformId: platforms?._id,
			ideaId: ideas?._id,
			flow,
			onSuccess: (response) => {
				setForms({ ...response, isLoading: false });
				if (flow === FLOWS.IDEA) {
					dispatch(Creators.IDEAS.updateIdeas({ forms: response.docs }));
				}
				if ([FLOWS.CHALLENGE, FLOWS.BRAINSTORM].includes(flow)) {
					dispatch(Creators.PLATFORMS.updatePlatform({ forms: response.docs }));
				}

				const currentIds = [];
				response.docs.forEach((item) => {
					if (item.template) {
						currentIds.push(item.template._id);
					}
				});
				loadTemplates(currentIds, startTour);
			},
		}));
	};

	interface IViewHandler {
		action: TFormActions
		form?: IForm
	}

	const onViewHandler = ({ action, form }: IViewHandler) => {
		if (mode[flow] === 'view') return;
		dispatch(Creators.FORMS.setForm(form));
		dispatch(Creators.MODAL.showModal(paramsPaths.form, { action }));
	};

	const onInsertHandler = (formId: string, callback: () => void) => {
		if (mode[flow] === 'view') return;

		dispatch(Creators.FORMS.insertForm({
			templateId: formId,
			ideaId: ideas._id,
			platformId: platforms._id,
			flow,
			onSuccess: () => loadForms(),
			callback,
		}));
	};

	const onBackHandler = (params) => {
		onTourHandler();
		dispatch(Creators.MODAL.backModal(params));
	};

	useEffect(() => {
		loadForms(shared.tour === flow);
	}, []);

	return (
		<DialogContent id="FormsDialog">
			<ModalHeader
				fullScreen
				icon={flow}
				BreadcrumbProps={{ className: W_GRID.W_GRID_2 }}
				BackModalProps={{ anchor: '#forms-modal-create' }}
				hideClose
				onBack={onBackHandler}
				pathParams={{
					idea: rootPath[flow] || '...',
					platform: rootPath[flow] || '...',
					template: ideas?.templateNome || '...',
					type: platforms?.type || '...',
				}}
			/>
			<DialogBox className="s-dialog-box inner w-grid-2">
				<DialogBox className="s-dialog-title">
					<DialogTitle variant="h1">
						{flow === FLOWS.IDEA ? t('form.idea.forms') : t('form.platform.forms', { flow: t(`global.${flow}`) })}
					</DialogTitle>
					<HelpButton
						size="medium"
						// href={t('urls.forms')}
						onClick={() => BuildTour.setTour(tour, TOUR_STEPS, { currentStep: 0 })}
					/>
				</DialogBox>
				<DialogBox className="s-dialog-content">
					<BForms
						id="forms"
						routes={paramsPaths}
						forms={forms}
						size="x-large"
						flow={flow}
						hasEmptyState
						mode={mode[flow]}
					/>
				</DialogBox>
				<DialogBox className="s-dialog-content">
					<Typography variant="h6" fontWeight={700}>
						{t('form.add')}
					</Typography>
				</DialogBox>
				<DialogBox className="s-dialog-content">
					<BForms
						id="forms-templates"
						routes={paramsPaths}
						forms={templates}
						size="x-large"
						mode={mode[flow] === 'view' ? 'view' : 'add'}
						flow={flow}
						orientation="vertical"
						onInsert={onInsertHandler}
						CreateProps={mode[flow] === 'edit' ? {
							title: t('global.new'),
							size: 'x-large',
							onClick: mode[flow] === 'edit' ? () => onViewHandler({ action: 'create', form: newForm }) : undefined,
							clickable: mode[flow] === 'edit',
							action: mode[flow] === 'edit' ? t('global.create') : undefined,
						} : undefined}
					/>

				</DialogBox>

			</DialogBox>
		</DialogContent>
	);
};

const Translation = withTranslation('common')(MModalForms);

const ModalForms = (props: IModal) => (
	<Suspense fallback={<div />}>
		<Translation {...props} />
	</Suspense>
);
export default ModalForms;
