// @ts-nocheck
import React, { Suspense, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import routes from '@routes/modal';
import { FLOWS } from '@constants/GLOBAL';
import { IRootState, persistor } from 'src/store';
import { isEmpty } from '@utils/ReactUtils';
import { SMenu } from '@components/SMenu';
import Icon from '@components/Shared/Icon';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { SButton } from '@components/Buttons';
import { Creators } from '../../store/ducks/actionsTypes';

type TProps = WithTranslation

const HHeaderMenuNew = (props: TProps) => {
	const { t } = props;
	const dispatch = useDispatch();
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

	const { userOrganizationProfile } = useSelector((state: IRootState) => state);
	const options = [
		{
			icon: <Icon icon="idea" />,
			label: t('global.idea'),
			className: 'idea_create',
			onClick: () => {
				persistor.pause();
				dispatch(Creators.MODAL.handleModal({ flow: FLOWS.IDEA }));
				dispatch(Creators.MODAL.showModal(routes.MODAL_CREATE.path));
			},
		},
		{
			icon: <Icon icon="brainstorm" />,
			label: t('global.brainstorm'),
			onClick: () => {
				persistor.pause();
				dispatch(Creators.MODAL.handleModal({ flow: FLOWS.BRAINSTORM }));
				dispatch(Creators.MODAL.showModal(routes.MODAL_CREATE.path));
			},
		},
		{
			icon: <Icon icon="challenge" />,
			className: 'platform_create',
			label: t('global.challenge'),
			onClick: () => {
				persistor.pause();
				dispatch(Creators.MODAL.handleModal({ flow: FLOWS.CHALLENGE }));
				dispatch(Creators.MODAL.showModal(routes.MODAL_CREATE.path));
			},
		},
		{
			icon: <Icon icon="team" />,
			label: t('global.team'),
			onClick: () => {
				persistor.pause();
				dispatch(Creators.MODAL.showModal(routes.MODAL_TEAM.path));
			},
		},
		{
			icon: <Icon icon="fas fa-user-plus" />,
			label: t('global.member'),
			show: !!userOrganizationProfile?.papeis?.find((papel) => papel.nome === 'Administrador'),
			onClick: () => {
				dispatch(Creators.MODAL.showModal(routes.MODAL_INVITE.path));
			},
		},
	];

	const open = Boolean(anchorEl);

	return (
		<SMenu
			id="new-menu"
			open={open}
			anchorEl={anchorEl}
			setAnchorEl={setAnchorEl}
			options={options}
			sx={{
				'.MuiMenu-paper': {
					maxWidth: '9rem',
					width: '9rem',
				},
			}}
		>
			<SButton
				color="secondary"
				variant="outlined"
				size="large"
				className={`arrow ${open ? 'down' : 'up'}`}
				endIcon={<MdKeyboardArrowDown />}
				sx={{ width: '9rem' }}
			>
				{t('global.new')}
			</SButton>
		</SMenu>
	);
};

const Translation = withTranslation('common')(HHeaderMenuNew);

const HeaderMenuNew = () => {
	const { userOrganizationProfile } = useSelector((state: IRootState) => state);

	const _fallback = (
		<SButton
			color="secondary"
			variant="outlined"
			className="loading"
			style={{ cursor: 'pointer' }}
		>
			<div
				className="d-flex icon"
				style={{ width: '10px', marginRight: '10px' }}
			/>
			&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
		</SButton>
	);

	return (
		<Suspense fallback={_fallback}>
			{isEmpty(userOrganizationProfile?.user) ? _fallback : <Translation />}
		</Suspense>
	);
};

export default HeaderMenuNew;
