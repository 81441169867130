// @ts-nocheck
import React, { Suspense, useEffect, useState } from 'react';
import { useDispatch, connect } from 'react-redux';
import { TableColumn } from 'react-data-table-component';
import { WithTranslation, withTranslation } from 'react-i18next';

import routes from '@routes/modal';
import { Creators } from '@actions';
import { IRootState, persistor } from 'src/store';
import { APIUser } from '@services/apis';
import Icon from '@components/Shared/Icon';
import { IPagination } from 'src/@types/IShared';
import { handleAxiosErrors } from '@services/auth';
import { IUserOrganization } from 'src/@types/IUser';
import { UserCell } from '@components/DataTable/Cells';
import TableSearch from '@components/DataTable/TableSearch';
import SpinnerLDSRipple from '@components/Shared/SpinnerLDSRipple';
import ModalFeedback, { IFeedbackProps } from '@components/Modals/ModalFeedback';
import ModalRemoveUser from '@components/Modals/ModalRemoveUser';
import { SButton } from '@components/Buttons';
import { SMenu } from '@components/SMenu';
import { SBox } from '@components/Background/SBox';
import { Grid, IconButton } from '@mui/material';

interface IOwnProps {
	userOrganizationProfile: any
}

type TProps = IOwnProps & WithTranslation;

const _ActiveUsers = (props: TProps) => {
	const { t, userOrganizationProfile } = props;
	const [feedbackProps, setFeedbackProps] = useState<IFeedbackProps>({ open: false });
	const [activeUsers, setActiveUsers] = useState<IPagination<IUserOrganization>>({ docs: [], isLoading: true });
	const [open, setOpen] = useState<IUserOrganization | undefined>();
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

	const dispatch = useDispatch();

	const CellLastLogin = ({ row }: { row: IUserOrganization }) => (
		<div style={{ display: 'flex' }}>
			{row.user.lastLoginAt
				? new Date(row.user.lastLoginAt).toLocaleString()
				: 'Nunca'}
		</div>
	);

	const loadParticipants = () => {
		dispatch(Creators.SHARED.getParticipants({
			queryParams: { excludeCurrentUser: false },
			onSuccess: (response: IPagination<IUserOrganization>) => setActiveUsers(
				{ ...response, isLoading: false },
			),
		}));
	};

	const onRoleHandler = async () => {
		try {
			setFeedbackProps((prevState) => ({ ...prevState, isLoading: true }));

			if (!(feedbackProps?.match as IUserOrganization)?._id) return;

			const newRole = (feedbackProps?.match as IUserOrganization)?.papeis
				&& (feedbackProps?.match as IUserOrganization)?.papeis[0].nome === 'Administrador' ? 'Usuario' : 'Administrador';
			await APIUser.postRole((feedbackProps?.match as IUserOrganization)?._id, newRole);

			loadParticipants();
			dispatch(Creators.USER_ORGANIZATION.getUserOrganization());
			setFeedbackProps({ ...feedbackProps, open: false, isLoading: false });
		} catch (err) {
			handleAxiosErrors(err);
			setFeedbackProps((prevState) => ({ ...prevState, isLoading: false }));
		}
	};

	const onHandleClose = async () => {
		setOpen(undefined);

		setActiveUsers({ ...activeUsers, isLoading: true });

		loadParticipants();
	};

	const onHandleRemoveUser = async (row: any) => {
		setOpen(row);
	};

	const columns: TableColumn<IUserOrganization>[] = [
		{
			name: t('global.name'),
			// selector: (row) => row.user?._nome || row.user?.nome || '',
			sortable: true,
			maxWidth: '600px', // when using custom you should use width or maxWidth, otherwise, the table will default to flex grow behavior
			minWidth: '210px',
			cell: (row) => <UserCell user={row.user} />,
			// sortFunction: (row1, row2) => (row1.user?._nome || row1.user?.nome || '')
			//   .normalizeString()
			//   .toLowerCase()
			//   .localeCompare(row2.user?._nome || row2.user?.nome || ''
			//     .normalizeString()
			//     .toLowerCase()),
		},
		{
			name: t('global.role'),
			sortable: true,
			center: true,
			// selector: (row) => row.user?._nome || row.user?.nome || '',

			cell: (row) => <span>{row.papeis ? t(`roles.${row.papeis[0]?.nome.toLowerCase()}`) : ''}</span>,
			// @ts-ignore
			// sortFunction: (row1, row2) => row1.papeis[0]?.nome?.normalizeString()
			//   .toLowerCase()
			//   .localeCompare(row2.papel?.nome?.normalizeString().toLowerCase() || ''),
		},
		{
			name: t('global.platforms'),
			sortable: true,
			selector: (row) => row.plataformas_count || 0,
			center: true,

		},
		{
			name: t('global.ideas'),
			sortable: true,
			selector: (row) => row.ideiasCount || 0,
			center: true,

		},
		{
			name: t('global.teams'),
			sortable: true,
			selector: (row) => row.teamsCount || 0,
			center: true,

		},
		{
			name: t('global.lastLoginAt'),
			selector: (row) => row.user.lastLoginAt || '',
			sortable: true,
			center: true,
			cell: (row) => <CellLastLogin row={row} />,
		},
		{
			name: t('global.status'),
			selector: (row) => row.active || '',
			sortable: true,
			center: true,
			cell: (row) => <span>{t(`active.${row.active}`)}</span>,
		},
		{
			name: t('global.actions'),
			sortable: false,
			center: true,

			cell: (row) => (
				<SMenu
					id={`user-status-${row._id}-menu`}
					anchorEl={anchorEl}
					open={Boolean(anchorEl)}
					setAnchorEl={setAnchorEl}
					options={[
						{
							icon: <Icon icon="trash" />,
							onClick: () => onHandleRemoveUser(row),
							disabled: userOrganizationProfile._id.toString() === row._id.toString(),
							label: t('page_settings.tabcontent.users.removeUser', { orgName: userOrganizationProfile.organization.nome }),
						},
						{
							onClick: () => setFeedbackProps({ open: true, match: row }),
							disabled: userOrganizationProfile._id.toString() === row._id.toString(),
							icon: <Icon icon={row.papeis && row.papeis[0]?.nome === 'Administrador' ? 'user' : 'admin'} />,
							label: row.papeis && row.papeis[0]?.nome === 'Administrador' ? t('roles.usuario') : t('roles.administrador'),
						},
					]}
				>
					<IconButton>
						<Icon icon="ellipsis" />
					</IconButton>
				</SMenu>
			),
		},
	];

	useEffect(() => {
		loadParticipants();
	}, []);

	return (
		<SBox id="activeUsers">
			<Grid container className="section__header" spacing={3} flexDirection="column">
				<Grid item xs={12}>
					<SButton
						type="button"
						className="s-link"
						color="secondary"
						startIcon={<Icon icon="fa fas fa-user-plus" />}
						onClick={() => {
							persistor.pause();
							dispatch(Creators.MODAL.showModal(
								routes.MODAL_INVITE.path,
								{ callback: loadParticipants },
							));
						}}
					>
						<span className="bold">{t('member.inviteNew')}</span>
					</SButton>
				</Grid>
				<Grid item xs={12}>
					<TableSearch
						hasSearch
						progressPending={activeUsers.isLoading}
						columns={columns}
						data={activeUsers.docs}
					/>
				</Grid>
			</Grid>

			{feedbackProps.open && (
				<ModalFeedback
					id="active-users"
					color="success"
					onRequestClose={() => setFeedbackProps({ ...feedbackProps, open: false })}
					title={(feedbackProps?.match as IUserOrganization)?.papeis
						&& (feedbackProps?.match as IUserOrganization)?.papeis[0].nome === 'Administrador'
						? t('settings.toUser') : t('settings.toAdmin')}
					onRightButton={onRoleHandler}
					{...feedbackProps}
				/>
			)}

			{open
				&& <ModalRemoveUser open={typeof open !== 'undefined'} onClose={onHandleClose} user={open} />}

		</SBox>
	);
};

function mapStateToProps(state: IRootState) {
	return state;
}

const Translation = connect(mapStateToProps)(withTranslation('common')(_ActiveUsers));

const ActiveUsers = (props: IOwnProps) => (
	<Suspense fallback={<SpinnerLDSRipple />}>
		<Translation {...props} />
	</Suspense>
);

export default ActiveUsers;
