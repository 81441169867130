// @ts-nocheck
import React, { Suspense, useState } from 'react';
import { Creators } from '@actions';
import { Form } from '@components/DynamicForm/ComponentsCore';
import { SButton } from '@components/Buttons';
import InviteForm from '@components/Forms/InviteForm';
import SpinnerLDSRipple from '@components/Shared/SpinnerLDSRipple';
import { isEmpty } from '@utils/ReactUtils';
import { WithTranslation, withTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { TObject } from 'src/@types/IShared';
import { IInvite } from 'src/@types/IOrganization';
import URLS from '@constants/URLS';
import { IAutocompleteOption } from '@components/DynamicForm/Components/SSelect/SAutocomplete';
import { useNavigate } from 'react-router-dom';
import routes from '@routes/modal';
import { Grid, Typography } from '@mui/material';

interface IOwnProps {
	show: boolean
}

declare type TProps = IOwnProps & WithTranslation

const _InvitesSetup = (props: TProps) => {
	const { t, show } = props;
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [inProgress, setInProgress] = useState<string | undefined>(undefined);
	const [errors, setErrors] = useState<TObject>({});
	const [invites, setInvites] = useState<IAutocompleteOption[]>([]);

	const onSubmitInvites = (event: React.MouseEvent | React.KeyboardEvent) => {
		event.preventDefault();

		if (invites.length === 0) {
			dispatch(Creators.SHARED.updateShared({ step: 'trails' }));
			dispatch(Creators.MODAL.showModal(routes.MODAL_TRAILS.path));
			navigate(URLS.HOME);
			return;
		}

		dispatch(Creators.USER_ORGANIZATION.inviteOrganization({
			invitations: { invites: invites.map((item) => item.value) },
			onSuccess: () => {
				navigate(URLS.HOME);
				dispatch(Creators.SHARED.updateShared({ step: 'trails' }));
				dispatch(Creators.MODAL.showModal(routes.MODAL_TRAILS.path));
			},
			// onErrors: (err) => setErrors({ invites: err }),
			callback: () => setInProgress(undefined),
		}));
	};

	const onErrorHandler = (field: string) => {
		if (!isEmpty(errors)) delete errors[field];
	};

	const onInvitesHandler = (_invites: IInvite[]) => {
		onErrorHandler('invites');
		setInvites(_invites);
	};

	if (!show) {
		return null;
	}

	return (
		<Form className="invite__form s-form" id="invite-form" onSubmit={onSubmitInvites}>
			<Grid container textAlign="center" spacing={2}>
				<Grid item xs={12}>
					<Typography variant="h1">
						{t('register.inviteYourTeam')}
					</Typography>
				</Grid>
			</Grid>
			<InviteForm
				sx={{ marginTop: '3rem' }}
				invites={invites}
				handleInvites={onInvitesHandler}
				error={typeof errors?.invites === 'string'}
				name="invites"
			/>
			{/* <SFormGroup>
										<SInputLabel className="bold">{t("global.shareLink")}<HelpButton size="small" helperURL="# " /></SInputLabel>
										<div>
											<STextField /> <button id="btnCopy" className="default ml-3">{t("global.copy")}</button>
										</div>
									</SFormGroup> */}
			{/* {!org.isPublicDomain
											&& (
												<SFormGroup>
													<SCheckBox
														color="blue"
														value={org.onlyDomainUsers}
														label={`${t('settings.allowAnyoneWithDomain')} @${org.domain} ${t('settings.toEnter')}`}
														checked={org.onlyDomainUsers === true}
														onChange={this.handleOnlyDomainUsers}
													/>
												</SFormGroup>
											)} */}
			<SButton
				type="submit"
				color="primary"
				variant="outlined"
				autoFocus
				onClick={onSubmitInvites}
				isLoading={inProgress === 'invitations'}
				error={!isEmpty(errors)}
			>
				{`${t('global.goTo')} SENNO`}
			</SButton>
		</Form>
	);
};

const Translation = withTranslation('common')(_InvitesSetup);

const InvitesSetup = (props: IOwnProps) => (
	<Suspense fallback={<SpinnerLDSRipple />}>
		<Translation {...props} />
	</Suspense>
);

export { InvitesSetup };
