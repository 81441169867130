import React, { Suspense } from 'react';
import { DynamicCircle, DynamicCircleProps } from '@components/DynamicCircle';
import Icon from '@components/Shared/Icon';
import { BoxProps } from '@mui/material';
import { ImageBox } from './styles';

export interface IParamsImage {
	formData: FormData
	file: File
	url: string
}

// @ts-ignore
interface ImageInputProps extends BoxProps {
	id: string
	onClick?: (params: IParamsImage) => void
	disabled?: boolean
	isLoading?: boolean
}

type TProps = ImageInputProps & DynamicCircleProps

const IImageInput = ({
	className, onClick, size, color, disabled, id, isLoading, marginTop,
	...rest
}: TProps) => {
	const onImageHandler = (event: React.FormEvent<HTMLInputElement>) => {
		if (disabled) return;
		const input = event.currentTarget as HTMLInputElement;

		if (input.files && input.files[0]) {
			const formData = new FormData();
			formData.append('computer-upload', input.files[0]);

			if (typeof onClick === 'function') {
				onClick({ formData, file: input.files[0], url: URL.createObjectURL(input.files[0]) });
			}
		}
	};

	return (
		<ImageBox className={`image-input ${className || ''}`}>
			<label htmlFor={id}>
				<input
					type="file"
					name="cover"
					id={id}
					onChange={onImageHandler}
					disabled={isLoading}
					accept="image/png, image/jpeg, image/gif"
				/>
				<DynamicCircle
					className={`${isLoading ? 'loading' : ''}`}
					size={size || 'xx-large'}
					background={color || 'rgb(4, 72, 109)'}
					clickable={!disabled}
					{...rest}
				/>
				<div aria-hidden="true" className="circle camera large">
					<Icon icon="fas fa-camera" />
				</div>
			</label>
		</ImageBox>
	);
};

const ImageInput = (props: TProps) => (
	<Suspense fallback={<div />}>
		<IImageInput {...props} />
	</Suspense>
);

export default ImageInput;
