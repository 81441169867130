// @ts-nocheck
import React from 'react';
import URLS from '@constants/URLS';
import { IUserOrganization } from 'src/@types/IUser';
import { DynamicCircle, DynamicCircleProps } from '@components/DynamicCircle';
import { ProfileTooltip } from '@components/STooltips/ProfileTooltip';
import {
	IconButton, SxProps, Theme, TooltipClasses,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

export interface IUserAvatar extends DynamicCircleProps {
	target?: string
	hasPopover?: boolean
	isOnline?: boolean
	profile: IUserOrganization
	hasHref?: boolean
	goProfile?: boolean
	TooltipProps?: { arrow?: boolean, title?: React.ReactNode, sx?: SxProps<Theme>,
		open?: boolean;
		classes?: Partial<TooltipClasses>
		placement?:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top'}
}

const UserAvatar = (props: IUserAvatar) => {
	const {
		profile,
		hasPopover,
		target,
		placement = 'top-start',
		hasHref,
		goProfile,
		isOnline,
		onClick,
		TooltipProps,
		...rest
	} = props;
	
	const navigate = useNavigate();

	const color = profile?.user?.cor || '#04486e';
	const image = profile?.user?.image;
	const userName = profile?.user?._nome || profile?.user?.nome;

	// const { isOnline } = user;

	const handleClick = (event) => {
		if (typeof onClick === 'function') {
			onClick(event);
		}

		if (hasHref || goProfile) {
			event?.preventDefault();
			navigate(`${URLS.PROFILE}/${profile._id}`);
		}
	};

	return (
		<ProfileTooltip
			clickable={hasHref}
			profileId={profile._id}
			placement={placement}
			{...TooltipProps}
		>
			<IconButton onClick={handleClick} sx={{ cursor: hasHref || goProfile ? 'pointer' : 'auto' }}>
				<DynamicCircle
					id={target}
					background={color}
					className="avatar"
					image={typeof image === 'string' && image.length > 0 ? image : undefined}
					initial={userName}
					{...rest}
				/>
			</IconButton>
		</ProfileTooltip>
	);
};

export { UserAvatar };
