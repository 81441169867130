export default {
	DOCS_PER_PAGE: screen.width <= 1536 ? 3 : 4,
	IS_DEVELOPMENT: process.env.NODE_ENV === 'development',
	IDEA: 'idea',
	PLATFORM: 'platform',

};

const FLOWS = {
	IDEA: 'idea',
	PLATFORM: 'platform',
	CHALLENGE: 'challenge',
	BRAINSTORM: 'brainstorm',
};

const maxLength = {
	default: 40,
	default20: 20,
	default250: 250,
	default500: 500,
	orgName: 20,
	teamName: 40,
	flowName: 60,
	userName: 30,
};

export { FLOWS, maxLength };
