// @ts-nocheck
import { createActions, createReducer } from 'reduxsauce';
import { ICanvasActions } from './types';

/** reating actions types & creators */

export const canvasActions: ICanvasActions = createActions({
	insertCanvas: ['params'],
	handleCanvas: ['params'],
	deleteCanvas: ['params'],
	getCanvas: ['p'],
	successCanvas: ['canvas'],
	clearCanvas: [''],
	restoreCanvas: ['p'],
});

const { Types } = canvasActions;

/** Creating reducer handlers */

export const INITIAL_STATE_CANVAS = {

};

const handleCanvas = (state = INITIAL_STATE_CANVAS, action) => {
	const { key, description } = action.params;
	state.fields[key].description = description;
	return {
		...state,
	};
};

/** From platform template */

const successCanvas = (state = INITIAL_STATE_CANVAS, action) => ({
	...action.canvas,
});

/** Default functions */

const clearCanvas = () => ({});
const defaultCanvas = (state = INITIAL_STATE_CANVAS) => (state);

/** Creating our own reducer */

export default createReducer(INITIAL_STATE_CANVAS, {
	[Types.INSERT_CANVAS]: defaultCanvas,
	[Types.DELETE_CANVAS]: defaultCanvas,
	[Types.HANDLE_CANVAS]: handleCanvas,
	[Types.GET_CANVAS]: defaultCanvas,
	[Types.SUCCESS_CANVAS]: successCanvas,
	[Types.CLEAR_CANVAS]: clearCanvas,
});
