/* eslint-disable no-param-reassign */
/* eslint-disable react/no-array-index-key */
// @ts-nocheck
import _ from 'lodash';
import { useState } from 'react';
import {
	Draggable,
	Droppable,
	DropResult,
	DraggingStyle,
	DragDropContext,
	NotDraggingStyle,
} from 'react-beautiful-dnd';

import { TObject } from 'src/@types/IShared';
import { Box } from '@mui/material';
import { FieldBuilder, IFieldBuilder } from './FieldBuilder';
import { fieldGenerator, inputTypes } from './FieldsTypes';
import { FormActionsBar } from './FormActionsBar';
import { reorder } from './FormUtils';

const getItemStyle = (
	isDragging: boolean,
	draggableStyle: DraggingStyle | NotDraggingStyle | undefined,
) => ({
	...draggableStyle,
});

interface FormBuilderProps {
	handleFields: (fields: IFieldBuilder[], schema) => void
	fields: IFieldBuilder[]
	disabled?: boolean
	errors: TObject
}

const FormBuilder = ({
	fields, handleFields, disabled, errors,
}: FormBuilderProps) => {
	const [currentFieldIndex, setCurrentField] = useState(0);

	const onFieldsHandler = (_fields: IFieldBuilder[], _errors) => {
		if (typeof handleFields === 'function') {
			handleFields(_fields, _errors);
		}
	};

	const handleDelete = (index) => {
		fields.splice(index, 1);
		setCurrentField(index - 1);
		onFieldsHandler(fields);
	};

	const handleInsert = (index) => {
		fields.splice(index + 1, 0, fieldGenerator(inputTypes.TEXT_INPUT));
		fields[index].name = `field-${index}`;
		fields[index].id = `field-${index}`;

		onFieldsHandler(fields);
		setCurrentField(index + 1);
		setTimeout(() => {
			document.querySelector(`#question-${index + 1}`)?.focus();
		}, 500);
	};

	const handleCopy = (index) => {
		const copy = _.cloneDeep(fields[index]);

		fields.splice(index, 0, copy);

		onFieldsHandler(fields);
		setCurrentField(index + 1);
		setTimeout(() => {
			document.querySelector(`#question-${index + 1}`)?.focus();
		}, 500);
	};

	const onDragEnd = (result: DropResult) => {
		const { destination } = result;
		if (!destination) {
			return;
		}
		if (result.destination.index === result.source.index) {
			return;
		}

		if (result?.destination) {
			onFieldsHandler(reorder(fields, result.source.index, result.destination.index));
		}
	};

	const onFieldChange = (field, index, _errors) => {
		fields[index] = field;

		onFieldsHandler(fields, _errors);
	};

	return (
		<Box
			className="s-dymanic-form"
		>
			<DragDropContext onDragEnd={onDragEnd}>
				<Droppable droppableId="list">
					{(provided) => (
						<div
							ref={provided.innerRef}
							{...provided.droppableProps}
						>
							{fields?.map((field, index) => {
								const id = `draggable-item-${index}`;
								return (
									<Draggable key={index} draggableId={id} index={index}>
										{(p, snap) => (
											<Box
												className="draggable-field"
												ref={p.innerRef}
												sx={{
													display: 'flex',
													position: 'relative',
													marginY: '20px',
													padding: '16px',
													borderRadius: '4px',
													backgroundColor: '#fff',
													boxShadow: '0 2px 1px -1px rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%), 0 1px 3px 0 rgb(0 0 0 / 12%)',
													transition: 'box-shadow .25s cubic-bezier(0.0, 0.0, 0.2, 1)',
													...getItemStyle(
														snap.isDragging,
														p.draggableProps.style,
													),
												}}
												{...p.draggableProps}
											>
												<FieldBuilder
													provided={p}
													disabled={disabled}
													field={field}
													index={index}
													edit={index === currentFieldIndex}
													setCurrentField={() => setCurrentField(index)}
													handleField={(_field, _errors) => onFieldChange(_field, index, _errors)}
													errors={errors}
												/>
												{index === currentFieldIndex
													&& (
														<FormActionsBar
															disabled={disabled}
															insertParams={{ action: () => handleInsert(index) }}
															deleteParams={{
																action: () => handleDelete(index),
																hide: fields.length === 1,
															}}
															copyParams={{ action: () => handleCopy(index) }}
														/>
													)}
											</Box>
										)}
									</Draggable>
								);
							})}
						</div>
					)}
				</Droppable>
			</DragDropContext>
		</Box>

	);
};

export default FormBuilder;
