import React from 'react';
import { IAnswer } from 'src/@types/IIdea';
import { Trans, useTranslation } from 'react-i18next';
import { SLink } from '@components/Buttons/SLink';
import Icon from '../Shared/Icon';

interface IOwnProps {
	textName?: string;
	list: IAnswer[];
  haveBottomBorder?: boolean
}

const getColor = (value: number) => {
	if (value > 50) {
		return '#3B9776';
	} if (value < 50 && value > 25) {
		return '#D8992C';
	}

	return '#E94F40';
};

const AnsersListComponent = (props: IOwnProps) => {
	const { textName, list, haveBottomBorder = true } = props;
	const { t } = useTranslation();

	return (
		<span>
			{list.length > 0 && list.map((item) => {
				const { start, end } = item.offsets_in_context[0];
				const text = item.context;
				// eslint-disable-next-line max-len
				const highlitedText = `${text.substring(0, start)}<span class='QA-answer-highlight'>${text.substring(start, end + 1)}</span>${text.substring(end + 1, text.length)}`;
				const relevance = +(item.score * 100).toFixed(0);
				const color = getColor(relevance);

				return (
					<div>
						{textName && item.answer === textName && (
							<div className="QA-relevance-info">
								<Icon
									style={{
										marginTop: '3px',
										marginRight: '10px',
										color: '#04486E',
									}}
									icon="info"
								/>
								<p>{t('questions.relevanceWarning')}</p>
							</div>
						)}
						<p>
							<span className="QA-relevance">
								<strong>
									{t('questions.relevance')}
									:
								</strong>
							</span>
							{' '}
							<span className="QA-relevance" style={{ color, fontWeight: '500' }}>{`${relevance}%`}</span>
						</p>
						{/* eslint-disable-next-line react/no-danger */ }
						<li dangerouslySetInnerHTML={{ __html: highlitedText }} />
						<div className="QA-infos" style={{ borderBottom: haveBottomBorder ? '0.5px solid #ddd' : '' }}>
							<p className="QA-source-link">
								<Trans
									i18nKey="questions.source"
									values={{ title: item.meta.name || item.meta.href }}
									components={{
										1: (
											<SLink
												target="_blank"
												rel="noreferrer"
												underline="always"
												href={item.meta.href}
											/>
										),
									}}
								/>
							</p>
						</div>
					</div>
				);
			})}
		</span>
	);
};

export default AnsersListComponent;
