import React from 'react';
import { Theme } from '@mui/material';
import {
	DragDropContext, Draggable, DraggingStyle, Droppable, DropResult, NotDraggingStyle, ResponderProvided,
} from 'react-beautiful-dnd';
import { reorder } from '@components/DynamicForm/FormUtils';

const getItemStyle = (
	isDragging: boolean,
	draggableStyle: DraggingStyle | NotDraggingStyle | undefined,
) => ({
	...draggableStyle,
	cursor: isDragging ? 'grab' : 'auto',
});

const getListStyle = (isDraggingOver: boolean) => ({
	border: isDraggingOver ? '1px dotted var(--iron-gray-color)' : 'none',
});

interface DraggableProps {
	value: any[]
	onReorder?: (value: any[]) => void
	component: (option: any[], index: number) => React.ReactNode
}

export const SDraggable = (props: DraggableProps) => {
	const { onReorder, value, component } = props;

	const onDragEnd = (result: DropResult, provided: ResponderProvided) => {
		const { destination } = result;
		if (!destination) {
			return;
		}
		if (result?.destination?.index === result.source.index) {
			return;
		}

		if (result?.destination) {
			const items = reorder(value, result.source.index, result?.destination?.index);

			if (typeof onReorder === 'function') {
				onReorder(items);
			}
		}
	};

	return (
		<DragDropContext onDragEnd={onDragEnd}>
			<Droppable droppableId="list">
				{(provided, snapshot) => (
					<div
						ref={provided.innerRef}
						className="autocomplete-list"
						style={getListStyle(snapshot.isDraggingOver)}
						{...provided.droppableProps}
					>
						{value.map((option, index) => {
							const id = `draggable-item-${index}`;
							return (
								<Draggable
									key={id}
									draggableId={id}
									index={index}
									isDragDisabled={typeof onReorder === 'undefined'}
								>
									{(p, snap) => (
										<div
											className="draggable-item"
											ref={p.innerRef}
											style={getItemStyle(
												snap.isDragging,
												p.draggableProps.style,
											)}
											{...p.draggableProps}
											{...p.dragHandleProps}
										>
											{component(option, index)}
										</div>
									)}
								</Draggable>
							);
						})}
					</div>
				)}
			</Droppable>
		</DragDropContext>
	);
};
