import React, {
	KeyboardEvent, MouseEvent, Suspense, ChangeEvent, useState,
} from 'react';
import * as Yup from 'yup';
import { withTranslation, WithTranslation } from 'react-i18next';
import UnauthenticatedHeader from '@components/Header/UnauthenticatedHeader';
import {
	Form, SFormGroup, SInputLabel,
} from '@components/DynamicForm/ComponentsCore';
import { SButton } from '@components/Buttons';
import { STextField } from '@forms/Components';
import { isEmpty } from '@utils/ReactUtils';
import APIUnregistered from '@services/apis/unregistered';
import PageLoader from '@components/Loaders/PageLoader';
import { getValidationErrors } from '@utils/yup';
import { useNavigate } from 'react-router-dom';
import URLS from '@constants/URLS';
import { TObject } from 'src/@types/IShared';
import { Box, Grid, Typography } from '@mui/material';
import { SLink } from '@components/Buttons/SLink';
import { GenericGrid } from '@components/Grids/GenericGrid';
import { MessageBox } from '@components/Shared/MessageBox';

type TProps = WithTranslation

const _RecoverAccount = (props: TProps) => {
	const { t } = props;
	const navigate = useNavigate();
	const [errors, setErrors] = useState<TObject>({});
	const [inProgress, setInProgress] = useState<boolean>(false);

	const [step, setStep] = useState<number>(1);
	const [email, setEmail] = useState<string>('');

	const onSubmitHandler = async (event?: MouseEvent<HTMLElement>) => {
		event?.preventDefault();
		try {
			setInProgress(true);
			const schema = Yup.object().shape({
				email: Yup.string().email(t('messages.email.isInvalid')).required(t('messages.requiredField')),
			});

			await schema.validate({ email }, {
				abortEarly: false,
			});

			await APIUnregistered.postRecoveryPwd({ email });
			setStep(2);
		} catch (err: any) {
			if (err instanceof Yup.ValidationError) {
				setErrors((prevState) => ({ ...prevState, ...getValidationErrors(err) }));
				return;
			}

			if (err?.response?.status === 404) {
				setErrors((prevState) => ({ ...prevState, email: t('messages.email.notRegistered') }));
			}
		} finally {
			setInProgress(false);
		}
	};

	const onKeyUpHandler = (e: KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'Enter') { onSubmitHandler(); }
	};

	React.useEffect(() => {
		(document.querySelector('#email') as HTMLInputElement)?.focus();
	}, []);

	return (
		<GenericGrid id="recover-account-page" className="recover__account--page s-grid-page">
			<UnauthenticatedHeader modus="logIn" />
			<Box
				className="w-grid-3 inner unauthenticated"
				sx={{
					display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%',
				}}
			>
				{step === 1
					&& (
						<Grid container sx={{ justifyContent: 'center' }} spacing={3}>
							<Grid container textAlign="center" spacing={2}>
								<Grid item xs={12}>
									<Typography variant="h1">{t('register.recovery.password')}</Typography>
								</Grid>
								<Grid item xs={12}>
									<Typography variant="body1">{t('register.recovery.enterTheEmail')}</Typography>
								</Grid>
							</Grid>
							<Grid item xs={12}>
								<Form onSubmit={onSubmitHandler} className="s-form">
									<SFormGroup>
										<SInputLabel htmlFor="recover-email" error={typeof errors.email === 'string'}>
											{t('form.email')}
										</SInputLabel>
										<STextField
											id="recover-email"
											type="email"
											required
											placeholder={t('form.placeholder.yourEmail')}
											onKeyUp={onKeyUpHandler}
											value={email}
											onChange={(e: ChangeEvent<HTMLInputElement>) => {
												delete errors?.email;
												setEmail(e.target.value);
											}}
											error={typeof errors.email === 'string'}
											helperText={errors.email}
										/>
									</SFormGroup>
									<SButton
										type="submit"
										color="primary"
										variant="outlined"
										fullWidth
										onClick={onSubmitHandler}
										isLoading={inProgress}
										disabled={!isEmpty(errors) || email?.length < 1}
										error={!isEmpty(errors)}
									>
										{t('global.continue')}
									</SButton>
								</Form>
							</Grid>
							<Grid item xs={12} sx={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
								<SLink color="primary" href={URLS.LOGIN} underline="always">
									{t('register.backToLogin')}
								</SLink>
							</Grid>
						</Grid>
					)}
				{step === 2
								&& (
									<MessageBox
										title={`${t('global.thankYou')}!`}
										htmlContent={t('register.recovery.sendPasswordResetInstructions')}
										button={(
											<SButton type="button" color="primary" variant="outlined" onClick={() => navigate(URLS.LOGIN)}>
												{t('register.backToLogin')}
											</SButton>
										)}
									/>
								)}

				{step === 1 && (
					<Box className="footer__info">
						<p dangerouslySetInnerHTML={{ __html: t('register.acknowledgement') }} />
					</Box>
				)}

			</Box>
		</GenericGrid>
	);
};

const Translation = withTranslation('common')(_RecoverAccount);

const RecoverAccount = () => (
	<Suspense fallback={<PageLoader />}>
		<Translation />
	</Suspense>
);

export default RecoverAccount;
