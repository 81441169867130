import React from 'react';
import {
	Box,
	FormHelperText, InputAdornment,
	MenuItem, Select, SelectProps,
} from '@mui/material';
import { SInputStatus } from '../SInputStatus';

interface SSelectOption {
	value?: string
	label?: string | React.ReactNode
	grouped?: string | React.ReactNode
}

interface SSelectProps extends SelectProps {
	options?: SSelectOption[]
	helperText?: string
	optionClass?: string
	selectable?: boolean
	isLoading?: boolean
	isAsync?: boolean
}

export const SSelect = (props: SSelectProps) => {
	const {
		id, options, className, helperText, optionClass,
		isLoading, isAsync, error, selectable, ...rest
	} = props;

	return (
		<Box className={`s-select ${className || ''} ${selectable ? 'selectable' : ''}`}>
			<Select
				id={id}
				error={error}
				endAdornment={(
					<InputAdornment position="end">
						<SInputStatus isLoading={isLoading} isAsync={isAsync} />
					</InputAdornment>
				)}
				{...rest}
			>
				{options?.map((item, index) => {
					const key = `menu-item-${index}`;
					const disabled = !!item.grouped;
					return (
						<MenuItem key={key} value={item.value} disabled={disabled} className={`s-menu-item ${optionClass || ''}`}>
							{item.grouped && <span className="grouped">{item.grouped}</span>}
							{item.label}
						</MenuItem>
					);
				})}
			</Select>
			<FormHelperText>
				{helperText}
			</FormHelperText>
		</Box>
	);
};
