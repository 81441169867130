// @ts-nocheck
import { SRadio } from '@components/DynamicForm/ComponentsCore';
import React from 'react';
import { Box, FormHelperText } from '@mui/material';
import { generatorArrayNumbers } from '../FormUtils';

interface LinearScaleProps {
	id: string
	className?: string
	value?: string
	helperText?: string
	error?: boolean
	disabled?: boolean
}

const LinearScale = (props: LinearScaleProps) => {
	const {
		field, value, onChange, className = '', helperText, error, id, disabled, ...rest
	} = props;
	return (
		<Box className={`s-linear-scale ${className}`}>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'space-around',
					'.marker': {
						display: 'flex',
						alignItems: 'flex-end',
					},
					'.s-check': {
						'.MuiFormControlLabel-root': {
							display: 'flex',
							flexDirection: 'column-reverse',
							'.MuiFormControlLabel-label': {
								marginBottom: '15px',
							},
						},
					},
				}}
			>
				{field.markerMin && <div className="marker">{field.markerMin}</div>}
				{generatorArrayNumbers(parseInt(field.min), parseInt(field.max)).map((item, index) => (
					<SRadio
						key={index}
						label={item.label}
						value={value}
						disabled={disabled}
						checked={item.value == value}
						onChange={typeof onChange === 'function' ? () => onChange(item.value) : undefined}
						{...rest}
					/>
				))}
				{field.markerMax && <div className="marker">{field.markerMax}</div>}
			</Box>
			<FormHelperText id={`helper-text-${id}`} error={error}>
				{helperText}
			</FormHelperText>
		</Box>

	);
};

export { LinearScale };
