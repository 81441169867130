// @ts-nocheck
/* eslint-disable react/jsx-props-no-spreading */

import React, { useState, Suspense } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { isEmpty } from '@utils/ReactUtils';
import { APIGlobal } from '@services/apis';
import type { IModal } from '@modules/Modal/types';
import { TObject } from 'src/@types/IShared';
import { DialogBox } from '@modules/Modal/Components';
import {
	SRadio, Form,
} from '@components/DynamicForm/ComponentsCore';
import { SButton } from '@components/Buttons';
import { STextField } from '@forms/Components';
import { handleAxiosErrors } from '@services/auth';
import { maxLength } from '@constants/GLOBAL';
import { DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { Creators } from '@actions';
import ModalFeedback, { IFeedbackProps } from '@components/Modals/ModalFeedback';
import { SToast } from '@modules/SToast';

interface IReport {
	motivo: string
	outro_motivo?: string
	objeto: string
	href: string
	_id: string
}

interface IOwnProps {
	_id: string
	objeto: string
}

declare type TProps = IOwnProps & WithTranslation & IModal

const MModalReport = (props: TProps) => {
	const { t, _id, objeto } = props; // MProps
	const dispatch = useDispatch();

	const [feedbackProps, setFeedbackProps] = useState<IFeedbackProps>({ open: false });
	const [flag, setFlag] = useState<IReport>({
		motivo: '',
		outro_motivo: '',
		objeto,
		href: window.location.href,
		_id,
	});

	const [errors, setErrors] = useState<TObject>({});
	const [inProgress, setInProgress] = useState<boolean>(false);

	const onErrorHandler = (key: string) => {
		if (!isEmpty(errors)) delete errors[key];
	};

	const handleSubmit = async (event: React.FormEvent) => {
		try {
			event?.preventDefault();
			setInProgress(true);
			if (flag.motivo === '') {
				SToast.error(t('messages.error.requiredOption'));
				return;
			}

			if (flag.motivo === 'other' && flag?.outro_motivo?.length === 0) {
				setErrors({ outro_motivo: t('messages.requiredField') });
				return;
			}

			if (flag.motivo !== 'other') {
				delete flag.outro_motivo;
			}

			await APIGlobal.postReport(flag);

			setFeedbackProps({
				...feedbackProps,
				title: t('feedback.report.title'),
				text: t('feedback.report.text'),
				rightButtonText: t('global.close'),
				open: true,
				color: 'success',
				hideLeftButton: true,
				onRightButton: () => {
					setFeedbackProps({ open: false });
					dispatch(Creators.MODAL.hideModal());
				},
			});
		} catch (err) {
			handleAxiosErrors(err);
		} finally {
			setInProgress(false);
		}
	};

	const options = [
		{
			value: 'porn_content',
			label: t('report.porn_content'),
		},
		{
			value: 'repulsive_violent_content',
			label: t('report.repulsive_violent_content'),
		},
		{
			value: 'abusive_prejudiced_content',
			label: t('report.abusive_prejudiced_content'),
		},
		{
			value: 'dangerous_content',
			label: t('report.dangerous_content'),
		},
		{
			value: 'child_abuse',
			label: t('report.child_abuse'),
		},
		{
			value: 'terrorism_apology',
			label: t('report.terrorism_apology'),
		},
		{ value: 'spam_fake', label: t('report.spam_fake') },
		{
			value: 'copyright_infringement',
			label: t('report.copyright_infringement'),
		},
		{ value: 'other', label: t('report.other') },
	];

	return (
		<DialogContent id="ReportDialog">
			<DialogBox className="s-dialog-title">
				<DialogTitle variant="h6">
					{t('report.title')}
				</DialogTitle>
			</DialogBox>

			<DialogBox className="s-dialog-box">

				<Form id="form-report" className="s-form" onSubmit={handleSubmit}>
					{options.map((item) => (
						<div className="form-group mb-0" key={item.value}>
							<SRadio
								name="motivo"
								id={`reason-${item.value}`}
								value={item.value}
								checked={flag.motivo === item.value}
								onChange={(event) => setFlag({ ...flag, motivo: event.target.value })}
								label={item.label}
							/>
						</div>
					))}
					{flag.motivo === 'other' && (
						<STextField
							id="outro_motivo"
							name="outro_motivo"
							multiline
							value={flag.outro_motivo}
							rows={5}
							error={typeof errors?.outro_motivo === 'string'}
							helperText={errors?.outro_motivo}
							maxLength={maxLength.default250}
							onChange={(event) => {
								onErrorHandler('outro_motivo');
								setFlag({ ...flag, outro_motivo: event.target.value });
							}}
						/>
					)}
				</Form>
				<DialogActions className="s-dialog-actions">
					<SButton
						type="button"
						color="secondary"
						onClick={() => dispatch(Creators.MODAL.hideModal())}
					>
						{t('global.cancel')}
					</SButton>
					<SButton
						type="submit"
						color="success"
						isLoading={inProgress}
						onClick={handleSubmit}
						disabled={flag.motivo === '' || (flag.motivo === 'other' && flag?.outro_motivo?.length === 0)}
					>
						{t('global.report')}
					</SButton>
				</DialogActions>
			</DialogBox>
			{feedbackProps.open && (
				<ModalFeedback
					id="report"
					onRequestClose={() => setFeedbackProps({ open: false })}
					{...feedbackProps}
				/>
			)}
		</DialogContent>
	);
};

const Translation = withTranslation('common')(MModalReport);

const ModalReport = (props: IModal) => (
	<Suspense fallback={<div />}>
		<Translation {...props} />
	</Suspense>
);

export default ModalReport;
