import { Box } from '@mui/material';
// eslint-disable-next-line import/no-extraneous-dependencies
import { styled as MuiStyled } from '@mui/system';

const sx = {
	checkbox: {
		height: '20px',
		width: '20px',
		border: '2px solid var(--dark-color)',
		borderRadius: '3px',
	},
	radio: {
		minHeight: '20px',
		minWidth: '20px',
		maxHeight: '20px',
		maxWidth: '20px',
		border: '2px solid var(--dark-color)',
		borderRadius: '50%',
	},
};

export const OptionPrefixBox = MuiStyled(Box)(({ theme }) => ({
	marginRight: '10px',
	'&.multiplechoice': sx.checkbox,
	'&.checkbox': sx.checkbox,
	'&.selectoption': sx.radio,
	'&.radio': sx.radio,
}));
