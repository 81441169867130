// @ts-nocheck
import { axiosInstance } from '@services/AxiosInstance';

const APIBilling = {
	postSubscribePlan(plan) {
		return axiosInstance.post('/stripe/plan/sign', plan);
	},
	putCustomer(data) {
		return axiosInstance.put('/stripe/user', data);
	},
	postPaymentMethod(body) {
		return axiosInstance.post('/user/payment_method', body);
	},
	getPlans() {
		return axiosInstance.get('/stripe/plans');
	},
	getOverviewPlan() {
		return axiosInstance.get('/stripe/overview');
	},

	getInvoices() {
		return axiosInstance.get('/stripe/invoices');
	},
	postDowngrade(body) {
		return axiosInstance.post('/stripe/subscription/downgrade', body);
	},
	getSubscription() {
		return axiosInstance.get('/stripe/licenses');
	},
};

export default APIBilling;
