import { Box } from '@mui/material';
// eslint-disable-next-line import/no-extraneous-dependencies
import { styled as MuiStyled } from '@mui/system';

export const ImageBox = MuiStyled(Box)(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	'.camera.large': {
		marginLeft: '95px',
		position: 'absolute',
		fontSize: '12px !important',
		cursor: 'pointer',
		marginTop: '-45px',
		background: '#04486e',
		color: 'white',
		height: '2.5rem',
		width: '2.5rem',
	},
	label: {
		display: 'block',
		input: {
			overflow: 'hidden',
			position: 'absolute',
			zIndex: '-1',
			opacity: '0',
		},
	},
}));
