// @ts-nocheck
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import React, { useState, Suspense } from 'react';
import { withTranslation } from 'react-i18next';
import { isEmpty } from '@utils/ReactUtils';
import { SButton } from '@components/Buttons';
import { maxLength } from '@constants/GLOBAL';
import { STextField } from '@forms/Components';
import { getValidationErrors } from '@utils/yup';
import { handleAxiosErrors } from '@services/auth';
import { DialogBox } from '@modules/Modal/Components';
import { Form, SFormGroup, SInputLabel } from '@components/DynamicForm/ComponentsCore';

import { Creators } from '@actions';
import { APIOrganizations } from '@services/apis';
import InviteForm from '@components/Forms/InviteForm';
import {
	Button, DialogActions, DialogContent, DialogTitle,
} from '@mui/material';
import { IAutocompleteOption } from '@components/DynamicForm/Components/SSelect/SAutocomplete';
import { SToast } from '@modules/SToast';

const MModalOrganizationCreate = (props: any) => {
	const { t } = props;
	const [inProgress, setInProgress] = useState(false);
	const dispatch = useDispatch();

	const [state, setState] = useState<{nome: string, invites: IAutocompleteOption[]}>({
		nome: undefined,
		invites: [],
	});

	const [errors, setErrors] = useState({});

	const onErrorHandler = (key: string) => {
		if (!isEmpty(errors)) delete errors[key];
	};

	const onCreateHandler = async (event) => {
		event?.preventDefault();
		try {
			setInProgress(true);
			const newOrg = {
				nome: state.nome,
				invites: state.invites.map((item) => item.value),
			};

			const schema = Yup.object().shape({
				nome: Yup.string().required(t('messages.requiredField')),
			});

			await schema.validate(newOrg, {
				abortEarly: false,
			});

			const response = await APIOrganizations.postOrganization(newOrg);

			dispatch(Creators.MODAL.hideModal());
			dispatch(Creators.USER_ORGANIZATION.changeOrganization(response.data._id));

			SToast.success(t('organization.created'), t('global.success'));
		} catch (err) {
			if (err instanceof Yup.ValidationError) {
				setErrors(getValidationErrors(err));
				return;
			}

			if (['organization.alreadyExists'].contains(err.response?.data?.err)) {
				setErrors({
					...errors,
					nome: t('organization.alreadyExists'),
				});
				return;
			}

			if (err.response?.data?.data && Array.isArray(err.response.data.data)) {
				setErrors({
					...errors,
					invites: {
						message: t('messages.invitationAlreadySend'),
						chips: err.response?.data?.data.map((item) => item.email),
					},
				});
				return;
			}

			handleAxiosErrors(err);
		} finally {
			setInProgress(false);
		}
	};

	const onChangeHandler = (key: string, value: any) => {
		onErrorHandler([key]);
		setState({ ...state, [key]: value });
	};

	return (
		<DialogContent className="modal__org" id="OrganizationDialog">
			<DialogBox className="s-dialog-title">
				<DialogTitle variant="h6">
					{t('organization.new')}
				</DialogTitle>
			</DialogBox>
			<DialogBox className="s-dialog-box">

				<Form className="s-form" id="modal-org">
					<SFormGroup>
						<SInputLabel htmlFor="organization-name" error={typeof errors?.nome === 'string'}>
							{t('global.name')}
						</SInputLabel>
						<STextField
							type="text"
							name="nome"
							fullWidth
							placeholder={t('form.placeholder.organization.name')}
							maxLength={maxLength.orgName}
							pattern="^[a-zA-ZÀ-ÖØ-öø-ÿ0-9 ]+$"
							autoFocus
							id="organization-name"
							value={state.nome}
							onChange={(e) => onChangeHandler('nome', e.target.value)}
							error={typeof errors?.nome === 'string'}
							helperText={errors?.nome}
							onKeyDown={(event) => {
								switch (event.key) {
									case 'Enter':
										document.getElementById('organization-create-invites-input')?.focus();
										break;

									default:
										break;
								}
							}}
						/>
					</SFormGroup>
					<InviteForm
						invites={state.invites}
						handleInvites={(arr) => onChangeHandler('invites', arr)}
						name="invites"
						error={errors?.invites}
					/>
					<DialogActions className="s-dialog-actions">
						<Button
							type="button"
							color="inherit"
							onClick={() => dispatch(Creators.MODAL.hideModal())}
						>
							{t('global.cancel')}
						</Button>
						<SButton
							type="button"
							className="btn success"
							onClick={onCreateHandler}
							isLoading={inProgress}
							error={!isEmpty(errors)}
						>
							{t('global.create')}
						</SButton>
					</DialogActions>
				</Form>
			</DialogBox>
		</DialogContent>
	);
};

const Translation = withTranslation('common')(MModalOrganizationCreate);

const ModalOrganizationCreate = (props) => (
	<Suspense fallback={<div />}>
		<Translation {...props} />
	</Suspense>
);
export default ModalOrganizationCreate;
