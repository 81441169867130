import { createStore, applyMiddleware, Store } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { persistStore, persistReducer } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';
import rootReducer from './ducks/rootReducer';
import rootSaga from './ducks/sagas';

export declare type IRootState = ReturnType<typeof rootReducer>

// https://bestofreactjs.com/repo/rt2zz-redux-persist-react-redux-tools
// https://github.com/rt2zz/redux-persist#nested-persists

const persistConfig = {
	key: 'SENNO_DELTA',
	storage: storageSession,
	// storage,
	// blacklist: ['modal'],
	// whitelist: ['modal']
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
const sagaMiddleware = createSagaMiddleware();
const store: Store<IRootState> = createStore(persistedReducer, applyMiddleware(sagaMiddleware));
const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);

export { store, persistor };
