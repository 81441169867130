// @ts-nocheck
import { createActions, createReducer } from 'reduxsauce';
import { IIdeasActions, IIdeasState } from './types';

/** Creating actions types & creators */

export const ideasActions: IIdeasActions = createActions({
	publishIdea: ['params'],
	handleIdea: ['params'],
	updateIdeas: ['idea'],
	uploadIdeaAttachments: ['formData', 'callback'],
	removeIdeaAttachment: ['attachmentId', 'callback'],
	getIdea: ['params'],
	getFormByCriteria: ['p'],
	getTemplateCriteria: ['p'],
	successFormByCriteria: ['p'],
	handleCriteria: ['key', 'value', 'name', 'index', 'indexSub', 'indexDeep'],
	submitEvaluationCriteria: ['p'],
	duplicateIdea: ['params'],
	handleIdeaTemplate: ['params'],
	deleteIdea: ['p'],
	getEvaluatorsByIdea: ['params'],
	handleShowAccordion: ['key', 'value'],
	handleErrorEvaluation: ['form'],
	clearIdeas: [''],
	clearIdeaEdit: [''],
	removeBoardIdea: ['params'],
	handleCoverIdea: ['p'],
	getCoauthors: ['params'],
	removePlatform: ['params'],
});

const { Types } = ideasActions;

/** Creating reducer handlers */

const INITIAL_STATE: IIdeasState = {
	criteria: {
		isLoading: true,
		error: '',
		index: null,
		submit: 'avaliar',
	},
	evaluators: null,
	platformId: null,
	isLoading: true,
	evaluate: false,
	updateIdea: undefined,
	question: '',
};

const handleShowAccordion = (state = INITIAL_STATE, action) => {
	const { questoes } = state.criteria;
	Object.keys(questoes).forEach(
		(key) => {
			if (questoes[key].name !== action.key) {
				questoes[key].show = false;
			}
		},
	);

	return {
		...state,
		criteria: {
			...state.criteria,
			questoes: {
				...state.criteria.questoes,
				[action.key]: {
					...state.criteria.questoes[action.key],
					show: !action.value,
				},
			},
		},
	};
};
// Criterio
const handleErrorEvaluation = (state = INITIAL_STATE, action) => (
	{
		...state,
		criteria: {
			...state.criteria,
			...action.form,
		},
	}
);

const handleCriteria = (state = INITIAL_STATE, action) => {
	const { criteria } = state;

	if (criteria.type === 'form' && !(criteria.tipo === 'avaliacao-disrupcao')) {
		if (['avaliacao-dificuldade', 'avaliacao-impacto'].includes(criteria.tipo)) {
			if (action.index >= 0) {
				if (criteria.questoes[action.key].options[action.index].type === 'radio') {
					criteria.questoes[action.key].options[action.index].value = action.value;
					criteria.questoes[action.key].options[action.index].error = false;

					if (criteria.questoes[action.key].options[action.index].name === 'projeto_projeto_similar_realizado'
						&& action.value === true) {
						criteria.questoes[action.key].options[action.index + 1].show = true;
						criteria.questoes[action.key].options[action.index + 2].show = false;
						criteria.questoes[action.key].options[action.index + 2].value = undefined;
					}

					if (criteria.questoes[action.key].options[action.index].name === 'projeto_projeto_similar_realizado'
						&& action.value === false) {
						criteria.questoes[action.key].options[action.index + 1].show = false;
						criteria.questoes[action.key].options[action.index + 1].value = undefined;
						criteria.questoes[action.key].options[action.index + 2].show = true;
					}

					return {
						...state,
						criteria: {
							...state.criteria,
							send: {
								...state.criteria.send,
								[action.name]: action.value,
							},
						},
					};
				}
				if (
					criteria.questoes[action.key].options[action.index].type === 'multipleOptions') {
					criteria.questoes[action.key].options[action.index].options[action.indexSub].value = action.value;
					criteria.questoes[action.key].options[action.index].options[action.indexSub].error = false;
					return {
						...state,
						criteria: {
							...state.criteria,
							send: {
								...state.criteria.send,
								[action.name]: action.value,
							},
						},
					};
				}
			}
		}
	}
	return {
		...state,
		criteria: {
			...state.criteria,
			send: {
				...state.criteria.send,
				[action.key]: action.value,
			},
			questoes: {
				...criteria.questoes,
				[action.key]: {
					...criteria.questoes[action.key],
					value: action.value,
					error: false,
				},
			},

		},
	};
};

const getFormByCriteria = (state = INITIAL_STATE, action) => (
	{
		...state,
		criteria: {
			isLoading: true,
			index: action.p.index,
		},
	}
);

const successFormByCriteria = (state = INITIAL_STATE, action) => {
	const send = {};
	const { questoes, tipo } = action.p;

	if (Array.isArray(Object.keys(questoes))) {
		Object.keys(questoes).forEach(
			(key) => {
				if (questoes[key].type === 'range' || tipo === 'avaliacao-disrupcao') {
					send[questoes[key].name] = questoes[key].value;
					return;
				}
				if (tipo === 'avaliacao-dificuldade' || tipo === 'avaliacao-impacto') {
					questoes[key].options.forEach((item) => {
						if (item.type !== 'multipleOptions') {
							send[item.name] = item.value;
						} else {
							item.options.forEach((sub) => {
								send[sub.name] = sub.value;
							});
						}
					});
				}
			},
		);
	}

	return {
		...state,
		criteria: {
			...action.p,
			send,
			isLoading: false,
		},
	};
};

const updateIdeas = (state = INITIAL_STATE, action) => {
	const { idea } = action;
	const DEFAULT = { ...state, ...idea, mode: 'edit' };

	if (idea?.isArchived) { /// review
		return { ...DEFAULT, mode: idea?.isArchived };
	}

	if (idea.status && ['aprovada', 'reprovada'].includes(idea.status)) {
		return { ...DEFAULT, mode: 'view' };
	}

	return DEFAULT;
};

/** Default functions */

const clearIdeas = (state = INITIAL_STATE) => ({
	syncIdea: state.syncIdea,
});

const clearIdeaEdit = (state = INITIAL_STATE) => {
	const { criteria } = state;

	return {
		criteria,
	};
};

const defaultIdea = (state = INITIAL_STATE) => ({ ...state });

/** Creating our own reducer */

export default createReducer(INITIAL_STATE, {
	[Types.GET_IDEA]: defaultIdea,
	[Types.HANDLE_IDEA]: defaultIdea,
	[Types.PUBLISH_IDEA]: defaultIdea,
	[Types.UPLOAD_IDEA_ATTACHMENTS]: defaultIdea,
	[Types.REMOVE_IDEA_ATTACHMENT]: defaultIdea,
	[Types.GET_FORM_BY_CRITERIA]: getFormByCriteria,
	[Types.GET_TEMPLATE_CRITERIA]: getFormByCriteria,
	[Types.SUCCESS_FORM_BY_CRITERIA]: successFormByCriteria,
	[Types.HANDLE_CRITERIA]: handleCriteria,
	[Types.SUBMIT_EVALUATION_CRITERIA]: defaultIdea,
	[Types.DUPLICATE_IDEA]: clearIdeas,
	[Types.UPDATE_IDEAS]: updateIdeas,
	[Types.HANDLE_IDEA_TEMPLATE]: defaultIdea,
	[Types.CLEAR_IDEAS]: clearIdeas,
	[Types.CLEAR_IDEA_EDIT]: clearIdeaEdit,
	[Types.DELETE_IDEA]: defaultIdea,
	[Types.GET_EVALUATORS_BY_IDEA]: defaultIdea,
	[Types.HANDLE_SHOW_ACCORDION]: handleShowAccordion,
	[Types.HANDLE_ERROR_EVALUATION]: handleErrorEvaluation,
	[Types.REMOVE_BOARD_IDEA]: defaultIdea,
	[Types.HANDLE_COVER_IDEA]: defaultIdea,
	[Types.GET_COAUTHORS]: defaultIdea,
});
