/* eslint-disable react/prop-types */
/* eslint-disable dot-notation */
import React, {
	Suspense, useEffect, useState,
} from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import ModalHeader from '@modules/Modal/ModalHeader';
import type { IModal } from '@modules/Modal/types';
import { IRootState, persistor } from 'src/store';
import { DialogBox } from '@modules/Modal/Components';
import { handleAxiosErrors } from '@services/auth';
import { SButton } from '@components/Buttons';
import { HelpButton } from '@components/Buttons/HelpButton';
import { Creators } from '@actions';
import { TrailCard } from '@components/STour/TrailCard';
import { APIIdeas, APIPlatforms, APIUser } from '@services/apis';
import { useTour } from '@reactour/tour';
import {
	DialogContent, DialogTitle, Grid, Box, Dialog, Typography, IconButton, DialogActions,
} from '@mui/material';
import { FLOWS } from '@constants/GLOBAL';
import { ITemplate, TObject } from 'src/@types/IShared';
import { SLink } from '@components/Buttons/SLink';
import { SToast } from '@modules/SToast';
import { SBox } from '@components/Background/SBox';
import { MdOutlineArrowBackIos, MdOutlineArrowForwardIos } from 'react-icons/md';

declare type TProps = IModal & WithTranslation

interface ITrail extends ITemplate {
	title: string
	content: string
	active?: boolean
}

const LOTTIE_FILE = {
	idea: '/images/json/content_idea.json',
	platform: '/images/json/content_platform.json',
	brainstorm: '/images/json/content_ar.json',
	challenge: '/images/json/content_consulting.json',
	'open-innovation': '/images/json/content_ar.json',
	product: '/images/json/header_services.json',
	'business-model': '/images/json/content_proto_lab.json',
	marketing: '/images/json/content_workshop.json',
	service: '/images/json/content_idea.json',
	process: '/images/json/pop_mockup.json',
};

const MModalTrails = (props: TProps) => {
	const { t, routes, path } = props;
	console.log('props', props);
	const tour = useTour();

	const STEP = useSelector((state: IRootState) => state.shared?.step) || 'trails';

	const dispatch = useDispatch();
	const [inProgress, setInProgress] = useState('');
	const [open, setOpen] = useState<'template' | undefined >(undefined);

	const [trails, setTrails] = useState<ITrail[]>([]);
	const [template, setTemplate] = useState<ITrail | undefined>(undefined);
	const [index, setIndex] = useState<number>(0);

	const handleFirstLogin = async () => {
		try {
			setInProgress('start');
			dispatch(Creators.SHARED.updateShared({ tour: undefined, step: 'trails' }));
			await APIUser.putProfile({ firstLogin: false });
			dispatch(Creators.MODAL.hideModal());
		} catch (err) {
			handleAxiosErrors(err);
		} finally {
			setInProgress('');
		}
	};

	const loadTemplates = async (id: string) => {
		try {
			setInProgress('templates');
			let response = null;
			switch (id) {
				case FLOWS.IDEA:
					response = await APIIdeas.getTemplates({ page: 1 });
					break;
				case FLOWS.CHALLENGE:
					response = await APIPlatforms.getTemplates({ page: 1, filter: 'Challenge' });
					break;
				case FLOWS.BRAINSTORM:
					response = await APIPlatforms.getTemplates({ page: 1, filter: 'Brainstorm' });
					break;
				default:
					break;
			}

			setInProgress('');
			setTrails(response?.data.docs
				.map((item: ITemplate) => ({ ...item, title: t(`trails.slugs.${item.slug}`), type: item.type || 'idea' }))
				.filter((item: ITemplate) => item.isDefault) || []);
			setTemplate(undefined);
			dispatch(Creators.MODAL.handleModal({ flow: id }));
		} catch (err) {
			setInProgress('');
			handleAxiosErrors(err);
		}
	};

	const onCreateHandler = async () => {
		try {
			let response = null;

			const flow = trails[index].type?.toLowerCase();
			setInProgress('create');
			const name = trails[index].nome || 'Template';

			dispatch(Creators.SHARED.updateShared({ tour: flow }));
			persistor.persist();
			tour.setCurrentStep(0);
			dispatch(Creators.MODAL.handleModal({ flow }));

			if (!flow || !trails[index].type) return;

			if ([FLOWS.CHALLENGE, FLOWS.BRAINSTORM].includes(flow)) {
				response = await APIPlatforms.postCreatePlatform({
					nome: name,
					templateId: trails[index]._id,
					// @ts-ignore
					type: trails[index].type,
				});
				dispatch(Creators.PLATFORMS.updatePlatform({ workflow: 'CREATE_PLATFORM', ...response.data }));
				SToast.success(t('messages.success.platform.created', { type: t(`global.${trails[index].type?.toLowerCase()}`) }));
				dispatch(Creators.MODAL.showModal(routes?.MODAL_PLATFORM.path));
			}
			if ([FLOWS.IDEA].includes(flow)) {
				response = await APIIdeas.postCreateIdea({ nome: name, templateId: trails[index]._id });
				dispatch(Creators.IDEAS.updateIdeas({ workflow: 'CREATE_IDEA', ...response.data }));
				SToast.success(t('messages.success.idea.created'));
				dispatch(Creators.MODAL.showModal(routes?.MODAL_IDEA.path));
			}
		} catch (err) {
			handleAxiosErrors(err);
			SToast.error(t('messages.error.platform.create', { type: t(`global.${trails[index].type?.toLowerCase()}`) }));
		} finally {
			setInProgress('');
		}
	};

	const MainButton = (step: string) => {
		let children = t('global.continue');
		let onClick;

		switch (step) {
			case 'trails':
				if (template?.slug === 'idea') {
					onClick = () => {
						dispatch(Creators.SHARED.updateShared({ step: 'idea' }));
						dispatch(Creators.MODAL.showModal(routes?.MODAL_TRAILS_TEMPLATES.path));
					};
				} else {
					onClick = () => {
						dispatch(Creators.SHARED.updateShared({ step: 'platform' }));
						dispatch(Creators.MODAL.showModal(routes?.MODAL_TRAILS_TEMPLATES.path));
					};
				}

				break;
			case 'platform':
				onClick = () => {
					if (template?.slug) {
						dispatch(Creators.SHARED.updateShared({ step: template?.slug }));
						dispatch(Creators.MODAL.showModal(routes?.MODAL_TRAILS_TEMPLATES.path));
					}
				};
				break;
			case 'challenge':
			case 'brainstorm':
			case 'idea':
				onClick = onCreateHandler;
				children = t('global.createExample');
				break;
			default:
				break;
		}

		return (
			<SButton
				type="button"
				color="primary"
				variant="outlined"
				size="large"
				isLoading={['templates', 'create'].includes(inProgress)}
				disabled={!template}
				onClick={onClick}
			>
				{children}
			</SButton>
		);
	};

	const nextTemplate = () => {
		setIndex((prevState) => prevState + 1);
	};

	const prevTemplate = () => {
		setIndex((prevState) => prevState - 1);
	};

	useEffect(() => {
		if (path === '/trails/templates') {
			if (STEP === 'platform') {
				setTrails([
					{
						slug: 'brainstorm',
						title: t('trails.platform.brainstorm.title'),
						content: t('trails.platform.brainstorm.text'),
					},
					{
						slug: 'challenge',
						title: t('trails.platform.challenge.title'),
						content: t('trails.platform.challenge.text'),
					},
				]);
			}

			if (['brainstorm', 'challenge', 'idea'].includes(STEP)) {
				loadTemplates(STEP);
			}
		}

		if (path === '/trails') {
			dispatch(Creators.SHARED.updateShared({ step: 'trails' }));
			setTrails([
				{
					slug: 'idea',
					title: t('trails.idea.title'),
					content: t('trails.idea.text'),
				},
				{
					slug: 'platform',
					title: t('trails.platform.title'),
					content: t('trails.platform.text'),
				},
			]);
		}
	}, [path, STEP]);

	return (
		<DialogContent id="TrailsDialog">
			<ModalHeader
				fullScreen
				hidePath
				onClose={handleFirstLogin}
				onBack={(BackModalProps: TObject) => {
					if ([FLOWS.CHALLENGE, FLOWS.BRAINSTORM].includes(STEP)) {
						dispatch(Creators.SHARED.updateShared({ step: 'platform' }));
						// eslint-disable-next-line no-useless-return
						return;
					}

					dispatch(Creators.MODAL.backModal(BackModalProps));
				}}
			/>
			<DialogBox className="s-dialog-title inner w-grid-2">
				<Grid
					container
					spacing={2}
					sx={{
						display: 'flex', justifyContent: 'center', textAlign: 'center', alignItems: 'center',
					}}
				>
					<Grid item xs={12}>
						<DialogTitle variant="h1" className="title">{t('trails.howCanWeHelpYou')}</DialogTitle>
					</Grid>
					<Grid item xs={12}>
						<Box
							sx={{
								display: 'flex', alignItems: 'center', flexDirection: { xs: 'column', sm: 'row' }, justifyContent: 'center', gap: 1,
							}}
						>
							<Typography variant="body1">
								{t('trails.dontNeedHelpStart')}
							</Typography>
							<SLink
								href="# "
								underline="always"
								onClick={handleFirstLogin}
								isLoading={inProgress === 'start'}
							>
								{t('global.startFromScratch')}
							</SLink>
							<HelpButton size="small" href="# " />
						</Box>
					</Grid>
				</Grid>
			</DialogBox>
			<Box className="inner w-grid-2 s-dialog-box">
				<SBox loading={['templates'].includes(inProgress)}>
					<Grid container spacing={2} marginY={6} sx={{ display: 'flex', justifyContent: 'center' }}>
						{trails?.map((item, i) => (
							<Grid
								item
								xs={12}
								sm={4}
								key={item.slug}
							>
								<TrailCard
									id={item.slug}
									// @ts-ignore
									src={LOTTIE_FILE[item.slug]}
									title={item.title}
									sx={{
										height: trails.length === 2 ? '360px' : '202px',
										'.trail-title': {
											fontWeight: trails.length === 2 ? '400' : '700',
											fontSize: trails.length === 2 ? '28px' : '1rem',
											lineHeight: trails.length === 2 ? '28px' : '1rem',
										},
									}}
									checked={template?.slug === item.slug}
									onChange={() => setTemplate(item)}
								>
									{item.content
										? <Typography fontSize="14px">{item.content}</Typography>
										: (
											<SButton
												sx={{ zIndex: 1 }}
												onClick={(event: MouseEvent) => {
													event.stopPropagation();
													setTemplate(item);
													setIndex(i);
													setOpen('template');
												}}
											>
												{t('global.seeExample')}
											</SButton>
										)}
								</TrailCard>
							</Grid>
						)) }
					</Grid>
					<DialogBox>
						<DialogActions className="s-dialog-actions" sx={{ justifyContent: 'center' }}>
							{MainButton(STEP)}
						</DialogActions>
					</DialogBox>
				</SBox>

			</Box>
			{open === 'template' && (
				<Dialog
					id={`modal-template-${trails[index].slug}`}
					keepMounted
					open={open === 'template'}
					maxWidth="xs"
					fullWidth
					className="s-modal-xs"
					PaperProps={{ sx: { borderRadius: 0 } }}
					onClose={() => setOpen(undefined)}
				>
					<DialogContent sx={{ padding: 0 }}>
						<Box className="box-item card-box">
							<Box
								sx={{
									height: '216px',
									backgroundImage: `url(/images/templates/${trails[index].slug}.png)`,
									backgroundSize: 'cover',
									backgroundPosition: 'center',
									position: 'relative',
								}}
							/>
						</Box>
						<DialogBox
							className="s-dialog-box"
							sx={{
								paddingTop: 3, paddingX: 3, display: 'flex', alignItems: 'center',
							}}
						>
							<IconButton
								onClick={prevTemplate}
								disabled={index === 0}
								sx={{ marginLeft: '-8px' }}
							>
								<MdOutlineArrowBackIos />
							</IconButton>
							<Box sx={{ marginX: 2 }}>
								<Typography variant="body1" fontWeight="700" marginBottom={2}>{trails[index].title}</Typography>
								<Typography variant="body1">{trails[index].descricaoList}</Typography>
							</Box>
							<IconButton
								onClick={nextTemplate}
								disabled={index === trails.length - 1}
								sx={{ marginRight: '-8px' }}
							>
								<MdOutlineArrowForwardIos />
							</IconButton>
						</DialogBox>
						<DialogBox className="s-dialog-box" sx={{ padding: 3 }}>
							<DialogActions>
								<SButton
									type="button"
									size="large"
									color="secondary"
									onClick={() => setOpen(undefined)}
								>
									{t('global.cancel')}
								</SButton>
								<SButton
									type="button"
									variant="outlined"
									color="secondary"
									size="large"
									isLoading={inProgress === 'create'}
									onClick={onCreateHandler}
								// error={error}
								>
									{t('global.createExample')}
								</SButton>
							</DialogActions>
						</DialogBox>

					</DialogContent>
				</Dialog>
			)}
		</DialogContent>
	);
};

const Translation = withTranslation('common')(MModalTrails);

const ModalTrails = (props: IModal) => (
	<Suspense fallback={<DialogContent />}>
		<Translation {...props} />
	</Suspense>
);

export default ModalTrails;
