import React, { Suspense, useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import {
	IconButton, Tooltip, ClickAwayListener,
} from '@mui/material';
import { IoHelpCircle } from 'react-icons/io5';
import { goToLinkNewWindow } from '@utils/ReactUtils';

interface IHelpButtonProps {
	size?: 'small' | 'medium' | 'large'
	href?: string
	className?: string
	children?: React.ReactNode
	color?: 'secondary' | 'primary' | ''
	placement?: 'bottom-end'
	| 'bottom-start'
	| 'bottom'
	| 'left-end'
	| 'left-start'
	| 'left'
	| 'right-end'
	| 'right-start'
	| 'right'
	| 'top-end'
	| 'top-start'
	| 'top'

	onClick?: (event: React.MouseEvent) => void
}

declare type TProps = IHelpButtonProps & WithTranslation

const HHelpButton = (props: TProps) => {
	const {
		children, href, placement = 'top', t, className = '', color = '', size = 'small', onClick,
	} = props;
	const [open, setOpen] = useState(false);

	const onClickHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
		setOpen(true);

		if (typeof href === 'string') {
			goToLinkNewWindow(href);
		 	return;
		}

		if (typeof onClick === 'function') {
			onClick(event);
		}
	};

	return (
		<ClickAwayListener onClickAway={() => setOpen(false)}>
			<Tooltip
				placement={placement}
				classes={{ popper: `${color} ${className}` }}
				title={
					children || t('global.toNowMore')
				}
				open={open}
				onClose={() => setOpen(false)}
				// onOpen={() => setOpen(true)}
				arrow
			>
				<IconButton
					aria-label="help"
					size={size}
					onClick={onClickHandler}
					onMouseMove={() => setOpen(true)}
					className={`h-button ${className} ${color}`}
				>
					<IoHelpCircle />
				</IconButton>
			</Tooltip>
		</ClickAwayListener>
	);
};

const Translation = withTranslation('common')(HHelpButton);

const HelpButton = (props: IHelpButtonProps) => (
	<Suspense fallback={<div />}>
		<Translation {...props} />
	</Suspense>
);

export { HelpButton };
