import React, { Suspense, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { WithTranslation, withTranslation } from 'react-i18next';
import SpinnerLDSRipple from '@components/Shared/SpinnerLDSRipple';
import { SwitchFilter } from '@components/Switchs/SwitchFilter';
import { IRootState } from 'src/store';
import PlanCard from '@components/Cards/PlanCard';
import { APIBilling } from '@services/apis';
import { handleAxiosErrors } from '@services/auth';
import { IPlan } from 'src/@types/IBilling';
import { Grid } from '@mui/material';
import { SBox } from '@components/Background/SBox';

interface IPlansState {
	docs: IPlan[], isLoading: boolean
}

type TProps = WithTranslation

const BPlans = (props: TProps) => {
	const { t } = props;
	const subscription = useSelector((state: IRootState) => state.billing?.subscription);
	const [plans, setPlans] = useState<IPlansState>({ docs: [], isLoading: true });
	const [interval, setInterval] = useState(subscription?.plan?.interval || 'month');

	const loadPlans = async () => {
		try {
			const response = await APIBilling.getPlans();
			setPlans({ docs: response.data, isLoading: false });
		} catch (err) {
			handleAxiosErrors(err);
		}
	};

	useEffect(() => {
		loadPlans();
	}, []);

	return (
		<SBox className="plans">
			<Grid container spacing={3} display="flex" flexDirection="column">
				<Grid item display="flex" justifyContent="center">
					<SwitchFilter
						left={{ value: 'year', label: t('settings.yearly'), selected: interval === 'year' }}
						right={{ value: 'month', label: t('settings.monthly'), selected: interval === 'month' }}
						name="interval"
						value={interval}
						// @ts-ignore
						onChange={plans?.isLoading ? undefined : (event) => setInterval(event.value)}
						className={plans?.isLoading ? 'loading' : ''}
					/>
				</Grid>
				<Grid item>
					<ul className="plans-list">
						{plans?.isLoading ? (
							<>
								<li key="plan-item-1" className="plan-item loading" />
								<li key="plan-item-2" className="plan-item loading" />
								<li key="plan-item-3" className="plan-item loading" />
							</>
						)
							: plans?.docs.map((item) => (
								<li key={item.id} className="plan-item">
									<PlanCard
										plan={item}
										interval={interval}
									/>
								</li>
							))}
					</ul>
				</Grid>
				<Grid item className="hire__plan-actions">
					<a href="https://senno.ai/" rel="noopener noreferrer" target="_blank" className="link">
						{t('plans.compareAllFeatures')}
					</a>
				</Grid>
			</Grid>
		</SBox>
	);
};

const Translation = withTranslation('common')(BPlans);

const Plans = () => (
	<Suspense fallback={<SpinnerLDSRipple />}>
		<Translation />
	</Suspense>
);

export default Plans;
