/* eslint-disable import/no-extraneous-dependencies */
import { Box } from '@mui/material';
import { styled as MuiStyled } from '@mui/system';

export const W_GRID = {
	W_GRID_1: 'inner w-grid-1',
	W_GRID_2: 'inner w-grid-2',
	W_GRID_3: 'inner w-grid-3',
}

const Common = {
	fullScreen: {
		paddingY: '1.25rem',
	}
};

export const GenericGrid = MuiStyled(Box)(({ theme }) => ({
	[theme.breakpoints.up('xs')]: {
		'.w-grid-1': {
			width: '100%',
			paddingLeft: '1rem',
			paddingRight: '1rem',
		},
		'.w-grid-2': {
			width: '100%',
			paddingLeft: '1rem',
			paddingRight: '1rem',
		},
		'.w-grid-3': {
			width: '100%',
			paddingLeft: '1rem',
			paddingRight: '1rem',
		},
		'.s-grid-page .s-empty-state': {
			padding: '2rem',
		},
		'.unauthenticated': {
			paddingTop: '3rem',
			'.MuiButton-root.MuiButton-outlined': {
				width: '100%',
				margin: '2rem 0 0',
				height: '40px',
				padding: '0 1rem',
				borderWidth: '2px',
				fontWeight: 'bold',
			},
			'.unauthenticated-content': {
				marginTop: '24px',
			},
		},
	},
	[theme.breakpoints.up('sm')]: {
		'.w-grid-3': {
			width: '482px',
		},
	},

	[theme.breakpoints.up('md')]: {
		'.w-grid-1.inner': {
			paddingLeft: '0',
			paddingRight: '0',
		},
		'.w-grid-1': {
			width: '1166px',
		},
		'.w-grid-2': {
			width: '732px',
		},
		'.s-grid-page .s-empty-state': {
			padding: '60px 120px',
		},
	},
	[theme.breakpoints.up('lg')]: {
		'.w-grid-1': {
			width: '1480px',
		},
		'.w-grid-2': {
			width: '812px',
		},
		'.unauthenticated': {
			paddingTop: '120px',
		},
	},
	[theme.breakpoints.up('xl')]: {
		'.w-grid-1': {
			width: '1640px',
		},
		'.w-grid-3': {
			width: '536px',
		},
	},
	'.w-grid-1': {
		position: 'relative',
		margin: '0 auto',
	},
	'.w-grid-2': {
		position: 'relative',
		margin: '0 auto',
	},
	'.w-grid-3': {
		position: 'relative',
		margin: '0 auto',
	},
	width: '100%',

	'&.s-grid-modal.xl': {
		'.s-dialog-breadcrumbs': {
			paddingTop: Common.fullScreen.paddingY,
			paddingBottom: Common.fullScreen.paddingY,
		},
		'.s-dialog-content': {
			paddingTop: Common.fullScreen.paddingY,
			paddingBottom: Common.fullScreen.paddingY,
		},
		'.s-dialog-title': {
			paddingTop: Common.fullScreen.paddingY,
			paddingBottom: Common.fullScreen.paddingY,
		},
		'.MuiDialogActions-root': {
			paddingTop: Common.fullScreen.paddingY,
			paddingBottom: Common.fullScreen.paddingY,
			paddingLeft: 0,
			paddingRight: 0,
		},
		'.s-form': {
			'.form-group': {
				paddingTop: Common.fullScreen.paddingY,
				paddingBottom: Common.fullScreen.paddingY,
			},
		},
	},
	'.s-form': {
		'.form-group': {
			paddingTop: '0.625rem',
			paddingBottom: '0.625rem',
		},
		'.s-label': {
			gap: 1,
			paddingBottom: '8px',
			display: 'flex',
			alignItems: 'center',
		},
	},
	'&.s-grid-page': {
		paddingBottom: '2rem',
	},
}));
