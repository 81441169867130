import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import URLS from '@constants/URLS';
import { ActivityChip } from '@components/Shared/ActivityChip';
import { IUserOrganization } from 'src/@types/IUser';
import { useDispatch } from 'react-redux';
import { Creators } from '@actions';
import { DynamicCircle } from '@components/DynamicCircle';
import {
	Divider, Grid, List, ListItem, Tooltip, TooltipProps,
} from '@mui/material';
import { SBox } from '@components/Background/SBox';
import { SButton } from '@components/Buttons';
import { useNavigate } from 'react-router-dom';

interface ProfileTooltipProps extends TooltipProps {
	profileId: string
	clickable?: boolean
}

export const ProfileTooltip = (props: ProfileTooltipProps) => {
	const {
		profileId, clickable, title, ...rest
	} = props;

	const { t } = useTranslation();
	const navigate = useNavigate();

	const dispatch = useDispatch();
	const [profile, setProfile] = useState<IUserOrganization>();
	const [loaded, setLoaded] = useState<boolean>(false);

	const loadProfile = () => {
		dispatch(Creators.USER_ORGANIZATION.getProfile({
			profileId,
			delayTime: 300,
			onSuccess: (response: IUserOrganization) => {
				setProfile(response);
				setLoaded(true);
			},
		}));
	};

	return (
		<Tooltip
			arrow
			classes={{ popper: 'primary', tooltip: 'box-shadow' }}
			onOpen={loadProfile}
			title={title || (
				<SBox loading={!loaded || !profile} minWidth="10vw">
					<Grid
						container
						spacing={1}
						paddingY={2}
						sx={{
							color: 'var(--charcoal-color)',
							'.activity-count': {
								color: 'var(--charcoal-color)',
							},
							alignItems: 'center',
							'.MuiGrid-item': {
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'center',
								alignItems: 'center',
							},
						}}
					>
						<Grid item xs={12}>
							<DynamicCircle
								background="var(--primary)"
								size="x-large"
								image={profile?.user?.image || undefined}
								initial={profile?.user?._nome || profile?.user?.nome}
							/>
						</Grid>
						<Grid item xs={12} className="word-ellipsis">
							{profile?.user._nome || profile?.user.nome}
						</Grid>
						<Grid item xs={12}>
							<Divider />
						</Grid>
						<Grid item xs={12}>
							<List sx={{
								display: 'flex',
								'.MuiListItem-root': {
									paddingX: 0,
								},
							}}
							>
								<ListItem>
									<ActivityChip
										icon="brainstorm"
										count={profile?.brainstormsCount}
										tooltip={t('global.brainstorms')}
									/>
								</ListItem>
								<ListItem>
									<ActivityChip
										icon="challenge"
										count={profile?.challengesCount}
										tooltip={t('global.challenges')}
										target={`profile-challenges-${profile?._id}`}
									/>
								</ListItem>
								<ListItem>
									<ActivityChip
										icon="idea"
										count={profile?.ideasCount}
										tooltip={t('global.ideas')}
									/>
								</ListItem>
							</List>
						</Grid>
						<Grid item xs={12}>
							{clickable && (
								<SButton
									variant="outlined"
									onClick={() => navigate(`${URLS.PROFILE}/${profile?._id}`)}
								>
									{t('global.profile')}
								</SButton>
							)}
						</Grid>
					</Grid>
				</SBox>
			)}
			{...rest}
		/>
	);
};
