// @ts-nocheck
import { axiosInstance } from '@services/AxiosInstance';
import { IBackupOptions, IPaginatedRequest } from 'src/@types/IShared';
import {
	getQueryParams,
} from './utils';

const APIUser = {
	getProfile(userId?: string) {
		const params = userId ? `?id=${userId}` : '';
		return axiosInstance.get(`/user${params}`);
	},
	getOrganizations() {
		return axiosInstance.get('/user/organizations');
	},
	putProfile(body) {
		return axiosInstance.put('/user', body);
	},
	patchEmailProfile(email, currentPassword) {
		return axiosInstance.patch('/user/change_email', { email, currentPassword });
	},
	patchImageProfile(image) {
		return axiosInstance.patch('/user/change_image', image);
	},
	patchPasswordProfile(password, currentPassword) {
		return axiosInstance.patch('/user/change_password', { password, currentPassword });
	},
	pathNotificationsProfile(notifications) {
		return axiosInstance.put('/user/notifications', notifications);
	},
	postRole(userId: string, role: string) {
		return axiosInstance.post(`/user/${userId}/change_role`, { role });
	},
	postEvaluationApp(evaluation: string) {
		return axiosInstance.post('/user/evaluate_senno', { value: parseInt(evaluation, 10) });
	},
	getTeams(userId: string, params: IPaginatedRequest) {
		const queryParams = getQueryParams(params || {});
		return axiosInstance.get(`/user/${userId}/teams${queryParams}`);
	},
	getPlatforms(userId: string, params: IPaginatedRequest) {
		const queryParams = getQueryParams(params || {});
		return axiosInstance.get(`/user/${userId}/platforms${queryParams}`);
	},
	getIdeas(userId: string, params: IPaginatedRequest) {
		const queryParams = getQueryParams(params || {});
		return axiosInstance.get(`/user/${userId}/ideas${queryParams}`);
	},
	postBackup(backupOptions: IBackupOptions) {
		return axiosInstance.post('/user/backup', backupOptions);
	},
	deleteAccount(userId: string, password?: string) {
		return axiosInstance.delete(`/user/${userId}?password=${encodeURIComponent(password)}`);
	},
};

export default APIUser;
