// @ts-nocheck
import React, { useState, useEffect, Suspense } from 'react';
import { useDispatch } from 'react-redux';
import { withTranslation, WithTranslation } from 'react-i18next';
import { showTab } from '@utils/helpers';
import { FLOWS } from '@constants/GLOBAL';
import routes from '@routes/modal';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import URLS from '@constants/URLS';
import { IIdea } from 'src/@types/IIdea';
import { persistor } from 'src/store';
import { AiFillHeart, AiOutlineHeart } from 'react-icons/ai';
import { BsLink } from 'react-icons/bs';
import { UsersGroupCircle } from '@components/Avatar/UsersGroupCircle';
import { SocketIO, SOCKET_EVENTS } from '@services/socket';
import ModalFeedback, { IFeedbackProps } from '@components/Modals/ModalFeedback';
import { Box, IconButton, Tooltip } from '@mui/material';
import { SMenu } from '@components/SMenu';
import { SButton } from '@components/Buttons';
import { PlatformTypeTooltip } from '@components/STooltips/PlatformTypeTooltip';
import { SToast } from '@modules/SToast';
import { APIIdeas } from '../../services/apis';
import { ActivityChip } from '../Shared/ActivityChip';
import { CreditsAuthor } from '../Shared/CreditsAuthor';
import { Sticker } from '../Stickers/Sticker';
import { Creators } from '../../store/ducks/actionsTypes';
import { EvaluateIcons } from '../Background/EvaluateIcons';
import { StickerIdea } from '../Stickers/StickerIdea';
import Icon from '../Shared/Icon';

interface IIdeaCoverProps {
	idea?: IIdea
	isHome?: boolean
	updateIdea?: (idea) => void
}

declare type TProps = IIdeaCoverProps & WithTranslation

const IIdeaCover = (props: TProps) => {
	const {
		idea, t, isHome, updateIdea,
	} = props;
	const navigate = useNavigate();
	const location = useLocation();

	if (typeof idea === 'undefined') return <div />;

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const dispatch = useDispatch();
	const href = `${URLS.IDEAS}/${idea?._id}`;
	const [feedbackProps, setFeedbackProps] = useState<IFeedbackProps>({ open: false });
	const platformsURL = {
		Challenge: `${URLS.CHALLENGES}/${idea?.plataforma?._id}`,
		Brainstorm: `${URLS.BRAINSTORMS}/${idea?.plataforma?._id}`,
	};

	const statusSticker = (function () {
		if (idea?.isArchived) {
			return <Sticker label={t('global.joined')} className="dark" />;
		}

		if (idea?.plataforma?.usuarioParticipa) {
			return <Sticker label={t('global.joined')} className="primary" />;
		}
		if (idea?.plataforma?.possuiPedidoParticipacao) {
			return <Sticker label={t('global.invited')} className="text-charcoal" />;
		}
		return null;
	}());

	const onAddPlatformHandler = (e) => {
		e.stopPropagation();

		// initial params to add idea into platform

		dispatch(Creators.IDEAS.updateIdeas({
			...idea,
			syncIdea: updateIdea,
		}));

		dispatch(Creators.MODAL.showModal(routes.MODAL_PLATFORMS.path, { close: true, platformType: 'brainstorm' }));
	};

	const onConnectHandler = (e) => {
		e.stopPropagation();
		dispatch(Creators.IDEAS.updateIdeas({ ...idea, syncIdea: updateIdea }));
		persistor.pause();
		dispatch(Creators.MODAL.showModal(routes.MODAL_IDEA_CONNECT.path));
	};

	const onArchiveHandler = async () => {
		try {
			setFeedbackProps((params) => ({ ...params, isLoading: true }));
			let response;
			if (idea?.isArchived) {
				response = await APIIdeas.postUnarchiveIdea(idea?._id);
				SToast.success(t('messages.success.idea.unarchived'));
			} else {
				response = await APIIdeas.postArchiveIdea(idea?._id);
				SToast.success(t('messages.success.idea.archived'));
			}

			if (response?.data) {
				if (typeof updateIdea === 'function') {
					updateIdea({
						isArchived: response.data.isArchived,
						mode: response.data?.isArchived
							|| ['aprovada', 'reprovada'].includes(response?.data?.status) ? 'view' : 'edit',
					});
				}
			}

			setFeedbackProps({ open: false });
		} catch (err) {
			console.log('====', err, err.response);
			const message = idea?.isArchived
				? t('messages.error.idea.unarchived')
				: t('messages.error.idea.archive');
			SToast.error(message);
		} finally {
			setFeedbackProps((prevProps) => ({ ...prevProps, isLoading: false }));
		}
	};

	const onCreateTemplate = () => {
		persistor.pause();
		dispatch(Creators.MODAL.showModal(
			routes.MODAL_TEMPLATE_CREATE.path,
			{ _id: idea?._id, flow: FLOWS.IDEA },
		));
	};

	const onEditHandler = () => {
		dispatch(Creators.MODAL.handleModal({ flow: FLOWS.IDEA }));
		dispatch(Creators.IDEAS.updateIdeas({
			workflow: 'UPDATE_IDEA',
			syncIdea: updateIdea,
		}));
		dispatch(
			Creators.IDEAS.getIdea(
				{
					ideaId: idea?._id,
					platformId: idea.plataforma?._id,
					redirectPath: routes.MODAL_IDEA.path,
				},
			),
		);
	};

	const onDuplicateHandler = () => {
		setFeedbackProps((prevProps) => ({ ...prevProps, isLoading: true }));
		dispatch(Creators.IDEAS.updateIdeas({ workflow: 'DUPLICATE_IDEA', syncIdea: updateIdea }));
		dispatch(
			Creators.IDEAS.duplicateIdea({
				ideaId: idea?._id,
				redirectPath: routes.MODAL_IDEA.path,
				onSuccess: () => setFeedbackProps({ open: false }),
				callback: () => setFeedbackProps((prevProps) => ({ ...prevProps, isLoading: false })),
			}),
		);
	};

	const onDeleteHandler = () => {
		setFeedbackProps((prevProps) => ({ ...prevProps, isLoading: true }));
		dispatch(Creators.IDEAS.deleteIdea({
			ideaId: idea?._id,
			onSuccess: () => {
				if (location.pathname.includes(URLS.DRAFTS)) {
					navigate(URLS.DRAFTS);
					return;
				}
				if (location.pathname.includes(URLS.IDEAS)) {
					navigate(URLS.IDEAS);
					return;
				}
				if (location.pathname.includes(URLS.HOME)) {
					navigate(URLS.HOME);
				}

				setFeedbackProps({ open: false });
			},
			callback: () => setFeedbackProps((prevProps) => ({ ...prevProps, isLoading: false })),
		}));
	};

	const onLikeHandler = (event: React.MouseEvent) => {
		event?.stopPropagation();
		SocketIO.emit(SOCKET_EVENTS.IDEA_LIKE, { id: idea._id });
	};

	const options = [
		{
			show: idea?.usuarioIsAutor || idea?.usuarioCoautora,
			icon: <Icon icon="edit" />,
			label: t('global.edit'),
			onClick: onEditHandler,
		},
		{
			show: idea?.usuarioIsAutor || idea?.usuarioCoautora,
			icon: <Icon icon="template" />,
			label: t('templates.new'),
			onClick: onCreateTemplate,
		},
		{
			show: idea?.usuarioIsAutor || idea?.usuarioCoautora,
			icon: <Icon icon="fas fa-clone" />,
			label: t('global.duplicate'),
			onClick: () => setFeedbackProps({
				...feedbackProps,
				title: t('feedback.duplicate.title-a', { name: `${t('global.idea').toLowerCase()} ${idea.nome}` }),
				rightButtonText: t('global.duplicate'),
				open: true,
				color: 'success',
				onRightButton: onDuplicateHandler,
			}),
		},
		{
			show: idea?.status !== 'rascunho' && idea?.usuarioIsAutor,
			icon: <Icon icon="fas fa-folder" />,
			label: idea?.isArchived ? t('global.unarchive') : t('global.archive'),
			onClick: () => setFeedbackProps({
				...feedbackProps,
				title: t(idea.isArchived
					? 'feedback.unarchive.title-a'
					: 'feedback.archive.title-a', { name: `${t('global.idea').toLocaleLowerCase()} ${idea.nome}` }),
				rightButtonText: idea?.isArchived ? t('global.unarchive') : t('global.archive'),
				open: true,
				color: 'success',
				onRightButton: onArchiveHandler,
			}),
		},
		{
			show: ((idea?.status === 'rascunho' || !idea?.plataforma) && idea?.usuarioIsAutor),
			icon: <Icon icon="remove" />,
			label: t('global.delete'),
			onClick: () => setFeedbackProps({
				...feedbackProps,
				title: t('feedback.delete.title-a', { name: `${t('global.idea').toLowerCase()} ${idea.nome}` }),
				rightButtonText: t('global.delete'),
				open: true,
				color: 'error',
				onRightButton: onDeleteHandler,
			}),
		},
	];

	useEffect(() => {
		SocketIO.on(SOCKET_EVENTS.IDEA_LIKE, (_idea: IIdea) => {
			if (typeof updateIdea === 'function' && _idea._id === idea._id) {
				// @ts-ignore
				updateIdea({ _id: _idea._id, likes: _idea.likes });
			}
		});
	}, [SocketIO]);

	return (
		<Box
			onClick={isHome ? () => navigate(href) : undefined}
			className="cover-box"
			sx={{
				backgroundImage: idea?.cover?.includes('unsplash') ? `url('${idea?.cover}&w=2048&dpr=2')` : `url('${idea?.cover}')`,
				backgroundColor: 'rgb(4, 72, 109)',
				cursor: isHome ? 'pointer' : 'auto',
			}}
		>
			<div className="overlay" />
			<div className="cover__content">
				<div className="cover-content w-grid-1 inner">
					<div className="top">
						<Box className="left" sx={{ gap: 1 }}>
							{idea?.plataforma
								? (
									<PlatformTypeTooltip
										platformId={idea?.plataforma._id}
										name={idea?.plataforma.nome}
										rulesButton={statusSticker}
										type={idea?.plataforma.type}
									>
										<Link
											className="position-relative circle"
											to={platformsURL[idea.plataforma.type]}
										>
											<Icon icon={idea.plataforma?.type.toLowerCase()} />
										</Link>
									</PlatformTypeTooltip>
								)
								: (idea?.usuarioIsAutor || idea?.usuarioCoautora) && (
									<div
										className="hover-expand"
										aria-hidden
										onClick={onAddPlatformHandler}
									>
										<Icon icon="platform" />
										<span>
											{t('global.add')}
										</span>
									</div>
								)}

							{(idea?.usuarioIsAutor || idea?.usuarioCoautora) && (!isHome) && (
								<SMenu
									id="idea-menu"
									open={Boolean(anchorEl)}
									anchorEl={anchorEl}
									setAnchorEl={setAnchorEl}
									options={options}
								>
									<SButton color="inherit" size="small">
										{t('global.options')}
									</SButton>
								</SMenu>
							)}
						</Box>
						<div className="center" />
						<Box className="right" sx={{ gap: 1 }}>
							<ActivityChip
								icon={idea?.likes?.length > 0 ? <AiFillHeart className="heart-red" /> : <AiOutlineHeart />}
								className="has-action"
								count={idea?.likes?.length}
								onClick={onLikeHandler}
							/>
							<ActivityChip
								icon={<BsLink />}
								className={`${idea.hasConnectAction && 'has-action'} ${idea?.conexoes?.length > 0 && 'active'}`}
								count={idea?.conexoes?.length}
								onClick={idea.hasConnectAction ? onConnectHandler : undefined}
							/>
						</Box>
					</div>
					<div className="middle">
						<div className="left">
							<div className="cover-title">
								{idea?.nome}
							</div>
							<Box className="cover-subtitle" sx={{ gap: 1 }}>
								<Icon icon="idea" />
								<span className="flow__name">{t('global.idea')}</span>
								<span >{t('global.by')}</span>
								<UsersGroupCircle
									size="small"
									clickable
									hasPopover
									hasMore
									group={idea?.coautores}
									id={idea?._id}
									tooltipIsMoreText={t('global.co-authors')}
									onMore={() => showTab({ navId: '#idea-related-tabs', tabId: 'coauthors-tab', scroll: true })}
									prefixTarget="popover_coauthor"
								/>
								{t('global.in', { date: new Date(idea?.createdAt) })}
							</Box>
						</div>
						<div className="center" data-tut="idea-evaluations">
							<EvaluateIcons
								ideaId={idea?._id}
								averageEvaluations={idea?.media_avaliacoes}
								isCover
							/>
						</div>
						<div className="right" />
					</div>
					<div className="bottom">
						<div className="left">
							<StickerIdea
								id={idea?._id}
								hasBadges={idea?.premios?.find((reward) => reward.moderador)}
								hasSponsorships={idea?.supporters?.length > 0}
								isArchived={idea.isArchived}
								isFinished={idea.isFinished}
								status={idea?.status}
								type={Object.hasOwnProperty.call(idea, 'plataforma') ? idea?.plataforma?.type : 'Brainstorm'}
								isCover
							/>
						</div>
						{idea?.coverAuthor
						&& (
							<CreditsAuthor
								className="center"
								cover={{ author: idea?.coverAuthor, url: idea?.cover }}
							/>
						)}
						<div className="right">
							{!idea?.usuarioIsAutor
							&& (
								<Tooltip classes={{ popper: 'primary' }} arrow title={t('global.reportContent')} placement="top">
									<IconButton
										color="inherit"
										className="position-relative circle"
										onClick={(e) => {
											persistor.pause();
											dispatch(Creators.MODAL.showModal(routes.MODAL_REPORT.path, { _id: idea?._id, objeto: 'ideia' }));
											e.stopPropagation();
										}}
									>
										<Icon icon="flag" className="text-white" />
									</IconButton>
								</Tooltip>
							)}
						</div>
					</div>
				</div>

			</div>
			{ feedbackProps.open && (
				<ModalFeedback
					id="idea"
					onRequestClose={() => setFeedbackProps({ open: false })}
					{...feedbackProps}
					container={feedbackProps.container || 'success'}
				/>
			)}
		</Box>
	);
};

const Translation = withTranslation('common')(IIdeaCover);

const IdeaCover = (props: IIdeaCoverProps) => {
	const { idea } = props;
	return (
		<Suspense fallback={<div />}>
			{typeof idea === 'undefined' ? <div /> : <Translation {...props} />}
		</Suspense>
	);
};

export default IdeaCover;
