// @ts-nocheck
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import XHR from 'i18next-xhr-backend';
import {
	format as formatDate,
	formatRelative,
	formatDistance,
	isDate,
} from 'date-fns';
import { enUS, ptBR } from 'date-fns/locale'; // import all locales we need

// yarn add -D @types/i18next-xhr-backend

export const locales = { enUS, ptBR };

const options = {
	interpolation: {
		escapeValue: false, // not needed for react!!
		format: (value: number | Date, format: string) => {
			if (isDate(value)) {
				const lng = {
					'en-US': 'enUS',
					'pt-BR': 'ptBR',
				};
				const locale = locales[lng[i18n.language]];

				// https://dev.to/ekeijl/react-automatic-date-formatting-in-translations-i18next-date-fns-8df
				// https://codesandbox.io/s/react-date-formatting-in-translations-v27h6?from-embed=&file=/src/translation.json:136-414

				if (format === 'short') { return formatDate(value, 'PP', { locale }); }
				if (format === 'long') { return formatDate(value, 'p PPPP', { locale }); }
				if (format === 'relative') { return formatRelative(value, new Date(), { locale }); }
				if (format === 'ago') {
					return formatDistance(value, new Date(), {
						locale,
						addSuffix: true,
					});
				}

				return formatDate(value, format, { locale });
			}

			return value;
		},
	},
	debug: true,
	fallbackLng: localStorage.getItem('i18nextLng') || 'en-US',
	lng: localStorage.getItem('i18nextLng') || 'en-US',
	ns: ['common'],
	defaultNS: 'common',
	react: {
		wait: false,
		bindI18n: 'languageChanged loaded',
		bindStore: 'added removed',
		nsMode: 'default',
	},
	backend: {
		loadPath: '/locales/{{lng}}/{{ns}}.json',
	},
};

i18n
	.use(XHR)
	.use(LanguageDetector)
	.use(initReactI18next)
	.init(options);

export default i18n;
