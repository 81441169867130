/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
// @ts-nocheck
import { useEffect } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { withTranslation, WithTranslation } from 'react-i18next';
import { ComputerUploadButton } from '@components/Buttons/ComputerUploadButton';
import { GoogleUploadButton } from '@components/Buttons/GoogleUploadButton';
import { TObject } from 'src/@types/IShared';
import {
	Box, Button, IconButton, Tooltip,
} from '@mui/material';
import { STextField } from '@forms/Components';
import { MdDragIndicator } from 'react-icons/md';
import Icon from '@components/Shared/Icon';
import { SButton } from '@components/Buttons';
import { fieldGenerator, groupedOptions, inputTypes } from './FieldsTypes';
import {
	SCheckbox,
} from './ComponentsCore';
import {
	OptionPrefixBox,
} from './styles';
import { reorder } from './FormUtils';
import { LinearScale } from './Components/LinearScale';
import { LinearScaleBuilder } from './ComponentsBuilder/LinearScaleBuilder';
import { SSelect } from './Components/SSelect';

interface IOptionPrefixProps {
	type: string
	index: number
}

const OptionPrefix = ({ type, index }: IOptionPrefixProps) => {
	switch (type) {
		case inputTypes.LINEAR_SCALE:
			return <OptionPrefixBox className={type}>{`${index}`}</OptionPrefixBox>;
		case inputTypes.TEXT_PICKER:
			return <OptionPrefixBox className={type}>{`${index}.`}</OptionPrefixBox>;
		case inputTypes.CHECKBOX:
		case inputTypes.MULTIPLE_CHOICE:
		case inputTypes.SELECT_OPTION:
		case inputTypes.RADIO:
			return <OptionPrefixBox className={type} id={`radio-${index}`} />;
		default:
			return null;
	}
};

interface IOptionBuilder {
	value: string
	label: string
	isOther?: boolean
	hasOther?: boolean
}

export interface IFieldBuilder {
	required?: boolean
	id: string
	name: string
	label: string
	value: string
	options?: IOptionBuilder[]
}

interface IFieldBuilderProps {
	errors?: TObject
	index: number
	disabled?: boolean
	field: IFieldBuilder
	handleField: (field: IFieldBuilder, errors?: TObject) => void
}

declare type TFieldBuilderProps = WithTranslation & IFieldBuilderProps

const _FieldBuilder = (props: TFieldBuilderProps) => {
	const {
		index,
		handleField,
		setCurrentField,
		edit,
		disabled,
		provided,
		field, t,
		errors,
	} = props;

	let observe;

	const innerType = groupedOptions.find((type) => type?.value === field?.inputype);
	const multipleEntries = [inputTypes.SELECT_OPTION, inputTypes.MULTIPLE_CHOICE];

	const onFieldHandler = (key: string, value: any) => {
		field[key] = value;
		delete errors[`fields[${index}].label`];
		if (typeof handleField === 'function') {
			handleField(field, errors);
		}
	};

	const onOptionHandler = (optionIndex: number, value: any) => {
		field.options[optionIndex] = { ...field.options[optionIndex], value, label: value };
		delete errors[`fields[${index}].options[${optionIndex}].label`];

		if (typeof handleField === 'function') {
			handleField(field, errors);
		}
	};

	const generateFormField = (type) => {
		switch (type.toLowerCase()) {
			case inputTypes.TEXT_INPUT:
			case inputTypes.TEXT_AREA:
			case inputTypes.NUMBER_INPUT:
				return (
					<input
						rows="2"
						className="form-control"
						disabled
					/>
				);
			case inputTypes.UPLOAD:
				if (edit) { return <div />; }
				return (
					<div className="d-flex">
						<GoogleUploadButton disabled />
						<ComputerUploadButton disabled />
					</div>
				);
			case inputTypes.LINEAR_SCALE:
				if (edit) {
					return <LinearScaleBuilder field={field} onChange={(key, value) => onFieldHandler(key, value)} />;
				}
				return <LinearScale field={field} disabled />;
			default:
				return <div />;
		}
	};

	const onInnerTypeHandler = (type: string) => {
		const innerFields = fieldGenerator(type.toLowerCase());
		// const optionsEntries = [...multipleEntries, inputTypes.TEXT_PICKER];

		// Check if you have multiple entries, options
		if (innerFields.options) {
			// Remove isOther from options TEXT_PICKER
			if ((innerFields.inputype === inputTypes.TEXT_PICKER) && field.options) {
				const optionIndex = field.options.findIndex((item) => item.isOther);
				if (optionIndex !== -1) {
					field.options.splice(optionIndex, 1);
				}
			}
			innerFields.label = field.label;
			if (field.options) {
				innerFields.options = field.options;
			}
		} else {
			innerFields.label = field.label;
			innerFields.value = field.value;

			if (field.options) {
				delete field.options;
			}
		}

		if (type) {
			handleField(innerFields, {});
		}

		setTimeout(() => {
			document.querySelector(`#option-${0}`)?.focus();
		}, 500);
	};

	const onDragEnd = (result) => {
		const { destination } = result;
		if (!destination) {
			return;
		}
		if (result.destination.index === result.source.index) {
			return;
		}

		field.options = reorder(field.options, result.source.index, result.destination.index);

		handleField(field);
	};

	const handleDeleteOption = (_index: number) => {
		field.options.splice(_index, 1);
		handleField(field);
		document.querySelector(`#option-${_index - 1}`)?.focus();
	};

	const handleInsertOption = (e) => {
		const other = {
			value: '',
			label: t('global.other'),
			isOther: true,
			hasOther: false,
		};
		const optionIndex = field.options.findIndex((item) => item.isOther);

		if (e === 'other') {
			field.options.push(other);
		} else {
			field.options.push({ value: '', label: '' });
			if (optionIndex !== -1) {
				field.options.splice(optionIndex, 1);
				field.options.push(other);
			}
		}

		if (typeof handleField === 'function') {
			handleField(field);
		}

		setTimeout(() => {
			if (optionIndex !== -1) {
				document.querySelector(`#option-${field.options.length - 2}`)?.focus();
				return;
			}
			document.querySelector(`#option-${field.options.length - 1}`)?.focus();
		}, 100);
	};

	const handleKeyUpOption = (e, _index) => {
		if (e.keyCode === 13) {
			field.options.splice(_index + 1, 0, { value: '', label: '' });
			if (typeof handleField === 'function') { handleField(field); }
			setTimeout(() => {
				document.querySelector(`#option-${_index + 1}`)?.focus();
			}, 100);
		}
	};

	const observerTextarea = () => {
		const text = document.getElementById(`field-${index}`);

		if (window.attachEvent) {
			observe = function (element, event, handler) {
				element?.attachEvent(`on${event}`, handler);
			};
		} else {
			observe = function (element, event, handler) {
				element?.addEventListener(event, handler, false);
			};
		}

		function resize() {
			if (text) {
				text.style.height = 'auto';
				text.style.height = `${text?.scrollHeight}px`;
			}
		}

		/* 0-timeout to get the already changed text */
		function delayedResize() {
			window.setTimeout(resize, 0);
		}
		observe(text, 'change', resize);
		observe(text, 'cut', delayedResize);
		observe(text, 'paste', delayedResize);
		observe(text, 'drop', delayedResize);
		observe(text, 'keydown', delayedResize);

		// text?.focus();
		// text?.select();
		resize();
	};

	const handleClick = () => {
		if (typeof setCurrentField === 'function') { setCurrentField(); }

		observerTextarea();
	};

	useEffect(() => {
		observerTextarea();
	}, []);

	return (
		<Box
			className={`field s-dynamic-form ${Object.keys(errors).filter((key) => key.includes(`fields[${index}]`))[0] ? 'is-invalid' : ''}`}
			edit={edit}
			onClick={handleClick}
		>
			<Box
				className="draggable__list"
				{...provided.dragHandleProps}
				sx={{
					textAlign: 'center',
					opacity: edit ? 1 : 0,
				}}
			>
				<MdDragIndicator />
			</Box>
			<Box
				className="field-builder"
				sx={{
					marginBottom: '15px',
					display: 'flex',
					justifyContent: 'space-between',
					gap: '130px',
				}}
			>
				{(edit || typeof errors[`fields[${index}].label`] === 'string')
					? (
						<STextField
							multiline
							rows="1"
							variant="standard"
							id={`field-${index}`}
							error={typeof errors[`fields[${index}].label`] === 'string'}
							helperText={errors[`fields[${index}].label`]}
							disabled={disabled}
							className="create__question"
							value={field.label}
							placeholder={`${t('form.question')} ${index + 1}`}
							onChange={(e) => {
								onFieldHandler(
									'label',
									e.target.value,
								);
							}}
							pattern="[A-Za-z_]+"
						/>
					)
					: (
						<p className="field__question">
							{field.label?.length > 0 ? field.label : `${t('form.question')}`}
						</p>
					)}

				{edit
					&& (
						<SSelect
							id="form-builder"
							optionClass="select__form--builder"
							className="select__form--builder"
							options={groupedOptions}
							onChange={({ target }) => onInnerTypeHandler(target.value)}
							disabled={disabled}
							value={innerType.value}
						/>
					)}
			</Box>
			{(!field.options && innerType.value) ? generateFormField(innerType.value)
				: (
					<DragDropContext onDragEnd={onDragEnd}>
						<Droppable droppableId="listOptions">
							{(_provided) => (
								<div ref={_provided.innerRef} {..._provided.droppableProps}>
									{field.options.map((option, i) => {
										const id = `draggable-item-${i}`;
										return (
											<Draggable key={i} draggableId={id} index={i}>
												{(p) => (
													<div
														data-item="draggable"
														className={`draggable-item ${edit ? 'justify-content-between' : ''}`}
														ref={p.innerRef}
														style={{ marginLeft: '-20px' }}
														{...p.draggableProps}
														{...p.dragHandleProps}
													>
														<div
															className="drag-icon"
															{...p.dragHandleProps}
														>
															<MdDragIndicator />
														</div>
														<OptionPrefix type={field.inputype} index={i + 1} />
														{(edit || typeof errors[`fields[${index}].options[${i}].label`] === 'string')
															? (
																<STextField
																	id={`option-${i}`}
																	value={option.label}
																	placeholder={`${t('form.option')} ${i + 1}`}
																	disabled={option.isOther || disabled}
																	variant="standard"
																	fullWidth
																	onKeyUp={(e) => handleKeyUpOption(e, i)}
																	onChange={(e) => onOptionHandler(i, e.target.value)}
																	error={typeof errors[`fields[${index}].options[${i}].label`] === 'string'}
																	helperText={errors[`fields[${index}].options[${i}].label`]}
																/>
															)
															: (
																<span className="m-0">
																	{option.label.length > 0
																		? option.label : option.other
																			? `${t('global.other')}` : `${t('form.option')} ${i + 1}`}
																</span>
															)}

														{(field.options.length > 1 && edit)
															&& (
																<Tooltip title={t('global.delete')}>
																	<IconButton
																		type="button"
																		size="small"
																		color="primary"
																		onClick={() => handleDeleteOption(i)}
																		sx={{
																			height: '36px',
																			width: '36px',
																		}}
																	>
																		<Icon icon="remove" />
																	</IconButton>
																</Tooltip>

															)}
													</div>
												)}
											</Draggable>
										);
									})}
									{edit && (
										<Box
											className="row-add-options"
											sx={{
												display: 'flex',
											}}
										>
											<OptionPrefix type={field.inputype} index={field.options.length + 1} />
											<STextField
												type="button"
												variant="standard"
												disabled={disabled}
												onClick={handleInsertOption}
												value={`${t('global.add')} ${t('form.option')}`}
												sx={{
													input: {
														textAlign: 'left',
													},
												}}
											/>
											{(!(field.options.some((option) => option.isOther))
												&& multipleEntries.includes(field.inputype))
												&& (
													<Button
														type="button"
														size="small"
														onClick={() => handleInsertOption('other')}
														sx={{
															whiteSpace: 'nowrap',
															paddingY: '8px',
															marginLeft: '16px',
															fontSize: '14px',
															minWidth: '130px',
														}}
													>
														{`${t('global.add')} "${t('global.other')}"`}
													</Button>
												)}
										</Box>
									)}
								</div>
							)}
						</Droppable>
					</DragDropContext>
				)}
			<Box
				className="footer"
				sx={{
					alignItems: 'center',
					display: 'flex',
					borderTop: '1px solid #dadce0',
					height: '64px',
					marginTop: '30px',
					justifyContent: 'flex-end',
					paddingRight: '0',
					label: {
						fontWeight: '500',
					},
				}}
			>
				<SCheckbox
					id={`required-item-${index}`}
					onChange={() => onFieldHandler('required', !field.required)}
					disabled={disabled}
					value={field.required || false}
					label={t('messages.requiredField')}
				/>
			</Box>
		</Box>
	);
};

const FieldBuilder = withTranslation('common')(_FieldBuilder);
export { FieldBuilder };
