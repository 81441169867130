/* eslint-disable no-underscore-dangle */
// @ts-nocheck
import {
	put, call, takeLatest, all, delay, select,
} from 'redux-saga/effects';
import i18next from 'i18next';
import * as Yup from 'yup';
import { Creators, Types } from '@actions';
import {
	APIIdeas,
	APIGlobal,
	APIPlatforms,
	APICanvas,
	APIForms,
} from '@services/apis';
import { goToLink } from '@utils/ReactUtils';
import { FLOWS } from '@constants/GLOBAL';
import { getValidationErrors } from '@utils/yup';
import { handleAxiosErrors } from '@services/auth';
import URLS from '@constants/URLS';
import { IRootState } from 'src/store';
import { AxiosResponse } from 'axios';
import { getTextFromHTML } from '@utils/helpers';
import { SToast } from '@modules/SToast';

function* handleIdeaTemplate(action) {
	const {
		ideaId, templateId, onSuccess, callback, redirectPath,
	} = action.params;

	try {
		const response = yield call(APIIdeas.putChangeTemplateIdea, ideaId, templateId);

		if (!response.data) return;

		yield put(Creators.IDEAS.clearIdeas());
		yield call(SToast.success, i18next.t('messages.success.template.selected'));

		response.data.mode = 'edit';

		yield put(Creators.IDEAS.updateIdeas(response.data));

		if (typeof onSuccess === 'function') {
			yield call(onSuccess, response.data);
		}

		if (typeof redirectPath === 'string') {
			yield put(Creators.MODAL.showModal(redirectPath, { flow: FLOWS.IDEA }));
		}
	} catch (err) {
		yield call(handleAxiosErrors, err);
	} finally {
		if (typeof callback === 'function') {
			yield call(callback);
		}
	}
}

function* publishIdea(action) {
	const { onError, callback } = action.params;
	try {
		const { ideas } = yield select((state: IRootState) => state);
		const schema = Yup.object().shape({
			nome: Yup.string().required(i18next.t('messages.requiredField')),
		});

		if (ideas.resumo) {
			const text = yield call(getTextFromHTML, ideas.resumo);
			if (text.length < 1 && typeof onError === 'function') {
				yield call(onError, { resumo: i18next.t('messages.requiredField') });
				return;
			}
		}

		yield schema.validate(ideas, {
			abortEarly: false,
		});

		const response: AxiosResponse = yield call(APIIdeas.putPublish, ideas._id);
		yield call(SToast.success, i18next.t('messages.success.idea.published'));
		yield put(Creators.IDEAS.clearIdeaEdit());
		yield put(Creators.MODAL.handleModal({ flow: undefined }));

		yield put(Creators.MODAL.hideModal());
		yield delay(1000);
		yield call(goToLink, `${URLS.IDEAS}/${response.data._id}`);
	} catch (err) {
		if (err instanceof Yup.ValidationError && typeof onError === 'function') {
			yield call(onError, getValidationErrors(err));
			return;
		}
		yield call(handleAxiosErrors, i18next.t('messages.error.idea.publish'));
	} finally {
		if (typeof callback === 'function') {
			yield call(callback);
		}
	}
}

function* handleIdea(action) {
	const {
		key, value, onSuccess, onError, noDelay, callback, hasWait,
	} = action.params;
	try {
		const { ideas } = yield select((state) => state);
		const field = { [key]: value };
		let schema = null;

		if (!ideas._id) return;

		if (!hasWait) {
			yield put(Creators.IDEAS.updateIdeas({ [key]: value }));
		}

		if (['nome'].includes(key)) {
			schema = Yup.object().shape({
				[key]: Yup.string().required(i18next.t('messages.requiredField')),
			});
		}

		if (['resumo'].includes(key) && typeof onError === 'function') {
			const text = yield call(getTextFromHTML, value);
			if (text.length < 1) {
				yield call(onError, { [key]: i18next.t('messages.requiredField') });
				return;
			}
		}

		if (schema) {
			yield schema.validate(field, {
				abortEarly: false,
			});
		}

		if (!noDelay) {
			yield delay(2000);
		}

		const response: AxiosResponse = yield call(APIIdeas.patchIdea, ideas._id, field);

		if (typeof onSuccess === 'function') {
			yield call(onSuccess, response.data);
		}
	} catch (err) {
		if (err instanceof Yup.ValidationError && typeof onError === 'function') {
			yield call(onError, getValidationErrors(err));
		} yield call(handleAxiosErrors, err);
	} finally {
		if (typeof callback === 'function') {
			yield call(callback);
		}
	}
}

function* getIdea(action) {
	const { ideaId, onSuccess, redirectPath } = action.params;
	try {
		if (typeof redirectPath === 'string') {
			yield put(Creators.MODAL.showModal(redirectPath, { flow: FLOWS.IDEA }));
		}
		const [idea, forms, canvas] = yield all([
			call(APIIdeas.getIdea, ideaId),
			call(APIForms.getFormsByIdea, ideaId),
			call(APICanvas.getCanvasByIdea, ideaId),
		]);

		idea.data.canvas = canvas.data;
		idea.data.forms = forms.data;

		idea.mode = idea?.data?.isArchived
			|| ['aprovada', 'reprovada'].includes(idea.data.status) ? 'view' : 'edit';

		idea.hasConnectAction = idea?.data?.status !== 'rascunho'
			&& ((idea?.data?.plataforma?.type === 'Challenge' && idea?.data?.plataforma?.usuarioAgencia)
				|| (idea?.data?.plataforma?.type === 'Brainstorm' && idea?.data?.plataforma?.usuarioParticipa)
				|| idea?.data?.usuarioCoautora || idea?.data?.usuarioIsAutor);

		yield put(Creators.IDEAS.updateIdeas(idea.data));

		if (typeof onSuccess === 'function') {
			yield call(onSuccess, idea.data);
		}
	} catch (err) {
		yield call(handleAxiosErrors, err);
	}
}

function* getTemplateCriteria(action) {
	try {
		const { typeForm, criteria, onSuccess } = action.p;
		const response: AxiosResponse = yield call(
			APIIdeas.getTemplateCriteria,
			criteria,
			typeForm,
		);

		yield put(Creators.IDEAS.successFormByCriteria(response.data));

		if (typeof onSuccess === 'function') {
			yield call(onSuccess, response.data);
		}
	} catch (err) {
		yield call(handleAxiosErrors, err);
	}
}

function* getFormByCriteria(action) {
	try {
		const { ideaId, criteria, onSuccess } = action.p;
		const response: AxiosResponse = yield call(
			APIIdeas.getFormByCriteria,
			ideaId,
			criteria,
		);

		yield put(Creators.IDEAS.successFormByCriteria(response.data));

		if (typeof onSuccess === 'function') {
			yield call(onSuccess, response.data);
		}
	} catch (err) {
		yield call(handleAxiosErrors, err);
	}
}

function* handleCoverIdea(action) {
	try {
		const {
			cover, coverThumb, coverAuthor, onSuccess,
		} = action.p;

		const { ideas } = yield select((state: IRootState) => state);

		if (!ideas._id) return;

		const response: AxiosResponse = yield call(APIIdeas.patchIdea, ideas._id, {
			cover,
			coverThumb,
			coverAuthor,
		});

		const updated = {
			_id: response.data._id,
			cover: response.data.cover,
			coverThumb: response.data.coverThumb,
			coverAuthor: response.data.coverAuthor,
		};

		yield put(Creators.IDEAS.updateIdeas(updated));
		if (typeof onSuccess === 'function') {
			yield call(onSuccess);
		}
		yield call(SToast.success, i18next.t('messages.success.coverChanged'));
	} catch (err) {
		yield call(handleAxiosErrors, err);
	}
	if (typeof action.p.callback === 'function') {
		yield call(action.p.callback);
	}
}

function* uploadIdeaAttachments(action) {
	try {
		const { ideas } = yield select((state: IRootState) => state);
		yield call(APIIdeas.postAttachments, ideas._id, action.formData);

		const response: AxiosResponse = yield call(APIIdeas.getIdea, ideas._id);
		const updated = {
			_id: response.data._id,
			anexos: response.data.anexos,
		};
		yield put(Creators.IDEAS.updateIdeas(updated));

		yield call(SToast.success, i18next.t('messages.success.fileUploaded'));
	} catch (err) {
		yield call(handleAxiosErrors, err);
	} finally {
		if (typeof action.callback === 'function') {
			yield call(action.callback);
		}
	}
}

function* duplicateIdea(action) {
	try {
		const { redirectPath } = action.params;
		const [response] = yield all([
			call(APIIdeas.postIdeaDuplicate, action.params.ideaId),
		]);

		if (!response.data) return;

		yield put(Creators.IDEAS.updateIdeas({
			...response.data,
			mode: 'edit',
			canvas: [...response.data.canvas, response.data.miroBoards],
		}));

		yield call(SToast.success, i18next.t('messages.success.idea.duplicated'));

		if (typeof redirectPath === 'string') {
			yield put(Creators.MODAL.showModal(redirectPath, {
				flow: FLOWS.IDEA,
			}));
		}

		if (typeof action.params.onSuccess === 'function') {
			yield call(action.params.onSuccess);
		}
	} catch (err) {
		yield call(handleAxiosErrors, err);
		yield call(SToast.error, i18next.t('messages.error.idea.duplicate'));
	} finally {
		const { callback } = action.params;
		if (typeof callback === 'function') {
			yield call(callback);
		}
	}
}

function* removeIdeaAttachment(action) {
	try {
		const ideaId = yield select((state: IRootState) => state.ideas._id);
		yield call(APIGlobal.deleteAttachment, action.attachmentId);

		const response: AxiosResponse = yield call(APIIdeas.getIdea, ideaId);
		yield put(Creators.IDEAS.updateIdeas({ anexos: response.data.anexos }));
		yield call(SToast.success, i18next.t('messages.success.fileRemoved'));
	} catch (err) {
		yield call(handleAxiosErrors, err);
	} finally {
		if (typeof action.callback === 'function') {
			yield call(action.callback);
		}
	}
}

function* submitEvaluationCriteria(action) {
	const { criteria } = yield select((state) => state.ideas);
	const { onSuccess } = action.p;
	try {
		const evaluation = action.p;
		const response = yield call(
			APIIdeas.postEvaluationByCriteria,
			evaluation.ideaId,
			evaluation.form,
		);

		const idea = yield call(APIIdeas.getIdea, evaluation.ideaId);

		yield put(Creators.IDEAS.updateIdeas({
			...idea.data,
			evaluate: false,
			criteria: {
				...criteria,
				avaliacao: { ...criteria.avaliacao, valor: response.data?.valor },
			},
		}));

		yield call(SToast.success, i18next.t('idea.success.criteriaEvaluated'));

		if (typeof onSuccess === 'function') {
			yield call(onSuccess);
		}
	} catch (err) {
		if (err.response?.data) {
			if (err?.response?.data?.err) {
				yield call(handleAxiosErrors, err);
			}
			if (['avaliacao-dificuldade', 'avaliacao-impacto'].includes(criteria.tipo)) {
				Object.keys(criteria.questoes).forEach((key) => {
					criteria.questoes[key].show = false;
					if (criteria.questoes[key].name === err.response.data.name) {
						criteria.questoes[key].show = true;
						criteria.questoes[key].options.find((item) => {
							if (item.type === 'radio') {
								if (item.name === err.response.data.key) {
									item.error = true;
								} else {
									item.error = false;
								}
							}
							if (item.type === 'multipleOptions') {
								item.options.forEach((item) => {
									if (item.name === err.response.data.key) {
										item.error = true;
									} else {
										item.error = false;
									}
								});
							}
							criteria.questoes[err.response.data.name].show = true;
						});
					} else {
						criteria.questoes[key].options?.forEach((item) => {
							item.error = false;
						});
					}
				});
			}

			if (criteria.tipo === 'avaliacao-disrupcao') {
				Object.keys(criteria.questoes).forEach(
					(key) => {
						if (criteria.questoes[key].name === err.response.data.name) {
							criteria.questoes[key].error = true;
						} else criteria.questoes[key].error = false;
					},
				);
				yield put(Creators.IDEAS.handleErrorEvaluation(criteria.questoes));
				yield put(Creators.IDEAS.handleShowAccordion(err.response.data.name, false));
			}
		}
	} finally {
		if (typeof action.p.callback === 'function') {
			yield call(action.p.callback);
		}
	}
}

function* deleteIdea(action) {
	try {
		yield call(APIIdeas.deleteIdea, action.p.ideaId);
		yield put(Creators.MODAL.hideSecondModal());

		yield call(SToast.success, i18next.t('messages.success.idea.deleted'));

		if (typeof action.p.onSuccess === 'function') {
			yield call(action.p.onSuccess, true);
		}
	} catch (err) {
		yield call(handleAxiosErrors, err);
		yield call(SToast.error, i18next.t('messages.error.idea.delete'));
	} finally {
		if (typeof action.p.callback === 'function') {
			yield call(action.p.callback);
		}
	}
}

function* getEvaluatorsByIdea(action) {
	const { queryParams, onSuccess, ideaId } = action.params;
	try {
		const response: AxiosResponse = yield call(APIIdeas.getEvaluatorsByIdea, ideaId, queryParams);

		if (typeof onSuccess === 'function') {
			yield call(onSuccess, response.data); // paginated
		}
	} catch (err) {
		yield call(handleAxiosErrors, err);
	}
}

function* removeBoardIdea(action) {
	const {
		boardId, ideaId, callback, onSuccess,
	} = action.params;
	try {
		yield call(APIIdeas.patchIdea, ideaId, { boardId });

		if (typeof onSuccess === 'function') {
			yield call(onSuccess);
		}
		yield put(Creators.MODAL.backModal());
	} catch (err) {
		yield call(handleAxiosErrors, err);
	} finally {
		if (typeof callback === 'function') {
			yield call(callback);
		}
	}
}

function* getCoauthors(action) {
	try {
		const { platformId, onSuccess } = action.params;
		yield delay(1000);
		const response: AxiosResponse = yield call(APIPlatforms.getCoauthors, platformId);

		if (typeof onSuccess === 'function') {
			yield call(onSuccess, response.data);
		}
	} catch (err) {
		handleAxiosErrors(err);
	}
}

export default function* () {
	yield takeLatest(Types.IDEAS.GET_IDEA, getIdea);
	yield takeLatest(Types.IDEAS.GET_FORM_BY_CRITERIA, getFormByCriteria);
	yield takeLatest(Types.IDEAS.GET_TEMPLATE_CRITERIA, getTemplateCriteria);
	yield takeLatest(Types.IDEAS.GET_EVALUATORS_BY_IDEA, getEvaluatorsByIdea);
	yield takeLatest(Types.IDEAS.PUBLISH_IDEA, publishIdea);
	yield takeLatest(Types.IDEAS.UPLOAD_IDEA_ATTACHMENTS, uploadIdeaAttachments);
	yield takeLatest(Types.IDEAS.REMOVE_IDEA_ATTACHMENT, removeIdeaAttachment);
	yield takeLatest(Types.IDEAS.HANDLE_IDEA, handleIdea);
	yield takeLatest(Types.IDEAS.HANDLE_COVER_IDEA, handleCoverIdea);
	yield takeLatest(Types.IDEAS.HANDLE_IDEA_TEMPLATE, handleIdeaTemplate);
	yield takeLatest(Types.IDEAS.SUBMIT_EVALUATION_CRITERIA, submitEvaluationCriteria);
	yield takeLatest(Types.IDEAS.DUPLICATE_IDEA, duplicateIdea);
	yield takeLatest(Types.IDEAS.DELETE_IDEA, deleteIdea);
	yield takeLatest(Types.IDEAS.REMOVE_BOARD_IDEA, removeBoardIdea);

	yield takeLatest(Types.IDEAS.GET_COAUTHORS, getCoauthors);
}
