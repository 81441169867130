// @ts-nocheck
import React, { Suspense, useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import SpinnerLDSRipple from '@components/Shared/SpinnerLDSRipple';
import { IAttachment, IPagination } from 'src/@types/IShared';
import TableSearch from '@components/DataTable/TableSearch';
import { TableColumn } from 'react-data-table-component';
import { SButton } from '@components/Buttons';
import axios from 'axios';
import { getGoogleDriveMethods, getGoogleDriveSettings } from '@utils/ReactUtils';
import Icon from '@components/Shared/Icon';
import { Box, BoxProps, IconButton, Typography } from '@mui/material';
import { SLink } from '@components/Buttons/SLink';
import { SToast } from '@modules/SToast';
import { SBox } from '@components/Background/SBox';

interface AttachmentsListProps extends BoxProps {
	attachments: IPagination<IAttachment>
	components?: { title: React.ReactNode }
}

declare type TProps = AttachmentsListProps & WithTranslation

let auth2;

const AAttachmentsList = (props: TProps) => {
	const { t, attachments, components, ...rest } = props;
	const [field, setField] = useState(undefined);

	const downloadURI = (metadata, id) => {
		setField(id);
		fetch(metadata.url)
			.then((resp) => resp.blob())
			.then((blob) => {
				const url = window.URL.createObjectURL(blob);
				const a = document.createElement('a');
				a.style.display = 'none';
				a.href = url;
				// the filename you want
				a.download = `${metadata.name.split('.')[0]}`;
				document.body.appendChild(a);
				a.click();
				window.URL.revokeObjectURL(url);
			})
			.catch(() => SToast.error(t('messages.error.download')))
			.finally(() => setField(undefined));
	};

	const columns: TableColumn<IAttachment>[] = [
		{
			id: 'name',
			name: t('global.name'),
			sortable: true,
			maxWidth: '310px', // when using custom you should use width or maxWidth, otherwise, the table will default to flex grow behavior
			selector: (row) => row.metadata?.name,
			minWidth: '310px',
			cell: (row) => (
				<Box className="attachment__name" sx={{ gap: 1 }}>
					<div className="circle">
						<Icon icon="fa fa-paperclip" />
					</div>
					<Typography>{row.metadata?.name}</Typography>
				</Box>
			),
		},
		{
			id: 'size',
			// name: t('global.size'),
			sortable: true,
			selector: (row) => `${parseInt(row.metadata.size / 1000, 10)} kB`,
			center: true,

		},
		{
			name: t('global.view'),
			center: true,
			cell: (row) => (
				<IconButton
					href={row.path}
					color="primary"
					size="small"
					rel="noopener noreferrer"
					target="_blank"
					style={{ textDecoration: 'none' }}
				>
					<Icon icon="eye" />
				</IconButton>
			),
		},
		{
			id: 'download',
			name: t('global.platforms'),
			center: true,
			minWidth: '130px',
			cell: (row) => {
				if (row.attType === 'google-drive') {
					return (
						<SButton
							href={row.path}
							rel="noopener noreferrer"
							target="_blank"
							startIcon={<Icon icon="fa fa-paperclip" />}
						>
							Download
						</SButton>
					);
				}
				return (
					<SButton
						type="button"
						isLoading={field === row._id}
						onClick={() => downloadURI(row.metadata, row._id)}
						startIcon={<Icon icon="fa fa-paperclip" />}
					>
						Download
					</SButton>
				);
			},
		},
	];

	// https://developers.google.com/drive/api/v3/reference/files/get
	// https://developers.google.com/drive/api/guides/ref-export-formats

	const downloadGoogleDrive = async (attachment) => {
		try {
			// @ts-ignore
			const googleAuth = gapi.auth2.getAuthInstance();
			const sigIn = await googleAuth.signIn({
				scope: 'https://www.googleapis.com/auth/drive',
			});

			console.log('sigIn', sigIn.zc.access_token);

			const response = await axios.get(`https://www.googleapis.com/drive/v3/files/${attachment.metadata.id}/export?mimeType=${attachment.mimeType}`, { headers: { Authorization: `Bearer ${sigIn.zc.access_token}` } });
		} catch (err) {
			SToast.error(t('messages.error.download'));
		}
	};

	const googleDrivePickerCallback = (data: any) => {
		// @ts-ignore
		if (data.action === window.google.picker.Action.PICKED && data.docs) {
			const file = { type: 'google-drive', data: data.docs[0] };
			if (typeof onClick === 'function') {

			}
		}
	};

	const googleDriveSettings = getGoogleDriveSettings();
	const googleDriveMethods = getGoogleDriveMethods(
		googleDriveSettings,
		googleDrivePickerCallback,
	);

	const downloadFiles = async (attachment) => {
		// @ts-ignore

		// @ts-ignore
		const googleAuth = gapi.auth2.getAuthInstance();
		const sigIn = await googleAuth.signIn({
			scope: 'https://www.googleapis.com/auth/drive.file',
		});

		googleDriveMethods.handleAuthResult(sigIn);

		const dest = fs.createWriteStream('/images');
		drive.files.get({
			fileId: attachment.metadata.id,
			alt: 'media',
			mimeType: attachment.mimeType,
		})
			.on('end', () => {
				console.log('Done');
			})
			.on('error', (err) => {
				console.log('Error during download', err);
			})
			.pipe(dest);
	};

	// useEffect(() => {
	// 	gapi.load('auth2', () => {
	// 		auth2 = gapi.auth2.init({
	// 			client_id: process.env.GOOGLE_CLIENT_ID,
	// 			cookiepolicy: 'single_host_origin',
	// 		});
	// 		auth2.then(() => {

	// 		});
	// 	});
	// }, []);

	return (
		<SBox
			className="attachments-list"
			empty={attachments.docs.length === 0 ? 'attachments' : undefined}
			loading={!attachments || attachments?.isLoading}
			sx={{
				'.rdt_TableRow': {
					backgroundColor: 'var(--body-background-color)',
				},
			}}
			{...rest}
		>
			{components?.title}
			<TableSearch
				noTableHead
				noPagination
				columns={columns}
				data={attachments.docs}
				paginationPerPage={Number.MAX_SAFE_INTEGER}
			/>
		</SBox>
	);
};

const Translation = withTranslation('common')(AAttachmentsList);

const AttachmentsList = (props: AttachmentsListProps) => (
	<Suspense fallback={<SpinnerLDSRipple />}>
		<Translation {...props} />
	</Suspense>
);

export default AttachmentsList;
