/* eslint-disable no-underscore-dangle */
// @ts-nocheck
import React, {
	useState, useEffect, Suspense,
} from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { WithTranslation, withTranslation } from 'react-i18next';
import { showTab } from '@utils/helpers';
import routes from '@routes/modal';
import URLS from '@constants/URLS';
import { persistor } from 'src/store';
import { IPlatform } from 'src/@types/IPlatform';
import { UsersGroupCircle } from '@components/Avatar/UsersGroupCircle';
import ModalFeedback, { IFeedbackProps } from '@components/Modals/ModalFeedback';
import { SocketIO, SOCKET_EVENTS } from '@services/socket';
import { AiFillHeart, AiOutlineHeart } from 'react-icons/ai';
import ReceiveButton from '@components/Buttons/ReceiveButton';
import { Creators } from '@actions';
import { Box, IconButton, Tooltip } from '@mui/material';
import { SMenu } from '@components/SMenu';
import { SButton } from '@components/Buttons';
import { PlatformTooltip } from '@components/STooltips/PlatformTooltip';
import { useNavigate } from 'react-router-dom';
import { SToast } from '@modules/SToast';
import Icon from '../Shared/Icon';
import { PermissionIcon } from '../Permission';
import { CreditsAuthor } from '../Shared/CreditsAuthor';
import { ActivityChip } from '../Shared/ActivityChip';
import { APIPlatforms } from '../../services/apis';

interface IPlatformCoverProps {
	platform: IPlatform
	isHome?: boolean
	updatePlatform?: (platform: IPlatform) => void
}

declare type TProps = IPlatformCoverProps & WithTranslation

const PPlatformCover = (props: TProps) => {
	const {
		platform, t, isHome, updatePlatform,
	} = props;

	const navigate = useNavigate();

	if (typeof platform === 'undefined') return <div />;
	const flow = platform?.type.toLowerCase();

	const dispatch = useDispatch();
	const [stopwatch, setStopwatch] = useState({
		days: 0,
		hours: 0,
		minutes: 0,
		seconds: 0,
	});

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [feedbackProps, setFeedbackProps] = useState<IFeedbackProps>({ open: false });

	const ideaLength = platform?.ideias?.length;

	const href = {
		Challenge: `${URLS.CHALLENGES}/${platform?._id}`,
		Brainstorm: `${URLS.BRAINSTORMS}/${platform?._id}`,
	};

	const participants = platform.type === 'Challenge'
		? [
			...platform.membros.moderadores,
			...platform.membros.agentes,
			...platform.membros.sponsors,
		]
		: platform.membros.participantes;

	const limitChallenge = platform.challengeFormat
		&& platform.challengeFormat.type.toString() === 'tempo'
		&& platform.challengeFormat.value;

	const timeLimit = platform.challengeFormat
		&& platform.challengeFormat.type.toString() === 'tempo'
		&& parseInt(limitChallenge.replace('horas', '').replace(' ', ''), 10);

	useEffect(() => {
		const start = moment();
		const end = moment(platform?.publishedAt).add(timeLimit, 'hours');

		let duration = moment.duration(end.diff(start));
		const decrement = 1;

		if (duration.seconds() <= 0) {
			setStopwatch({
				hours: '00',
				minutes: '00',
				seconds: '00',
			});
		} else {
			setInterval(() => {
				duration = moment.duration(duration.asSeconds() - decrement, 'seconds');

				setStopwatch({
					// months:
					//   duration.months() < 10 ? `0${duration.months()}` : duration.months(),
					days:
						duration.days() < 10 ? `0${Math.trunc(duration.asDays())}` : Math.trunc(duration.asDays()),
					hours:
						duration.hours() < 10 ? `0${duration.hours()}` : duration.hours(),
					minutes:
						duration.minutes() < 10
							? `0${duration.minutes()}`
							: duration.minutes(),
					seconds:
						duration.seconds() < 10
							? `0${duration.seconds()}`
							: duration.seconds(),
				});
			}, 1000);
		}
	}, []);

	const onArchiveHandler = async () => {
		try {
			setFeedbackProps((params) => ({ ...params, isLoading: true }));
			let response;
			if (platform?.isArchived) {
				response = await APIPlatforms.postUnarchivePlatform(platform?._id);
				SToast.success(t('messages.success.platform.unarchived', { type: t(`global.${flow}`) }));
			} else {
				response = await APIPlatforms.postArchivePlatform(platform?._id);
				SToast.success(t('messages.success.platform.archived', { type: t(`global.${flow}`) }));
			}

			if (response?.data) {
				if (typeof updatePlatform === 'function') {
					updatePlatform({
						...response.data,
						mode: response?.data?.isArchived
							|| (Object.hasOwnProperty.call(response?.data, 'isFinished')
								&& response?.data?.isFinished) ? 'view' : 'edit',
					});
				}
			}
			setFeedbackProps({ open: false });
		} catch (err) {
			SToast.error(platform?.isArchived
				? t('messages.error.unarchived')
				: t('messages.error.archive'));
		} finally {
			setFeedbackProps((params) => ({ ...params, isLoading: false }));
		}
	};

	const onDeleteHandler = () => {
		setFeedbackProps((prevProps) => ({ ...prevProps, isLoading: true }));
		dispatch(Creators.PLATFORMS.deletePlatform({
			platformId: platform?._id,
			flow,
			onSuccess: () => {
				setFeedbackProps({ open: false });
				navigate(-1);
			},
			callback: () => setFeedbackProps((prevProps) => ({ ...prevProps, isLoading: false })),
		}));
	};

	const onDuplicateHandler = () => {
		dispatch(Creators.PLATFORMS.updatePlatform({ workflow: 'DUPLICATE_PLATFORM', syncPlatform: updatePlatform }));
		setFeedbackProps((prevProps) => ({ ...prevProps, isLoading: true }));
		dispatch(
			Creators.PLATFORMS.duplicatePlatform({
				platformId: platform?._id,
				redirectPath: routes.MODAL_PLATFORM.path,
				onSuccess: () => setFeedbackProps({ open: false }),
				callback: () => setFeedbackProps((prevProps) => ({ ...prevProps, isLoading: false })),
			}),
		);
	};

	const onCreateTemplate = () => {
		persistor.pause();
		dispatch(Creators.MODAL.showModal(
			routes.MODAL_TEMPLATE_CREATE.path,
			{ _id: platform?._id, flow },
		));
	};

	const onEditHandler = () => {
		dispatch(Creators.MODAL.handleModal({ flow }));
		if (typeof updatePlatform === 'function') {
			dispatch(Creators.PLATFORMS.updatePlatform({ syncPlatform: updatePlatform, workflow: 'UPDATE_PLATFORM' }));
		}
		dispatch(
			Creators.PLATFORMS.getPlatform(
				{
					platformId: platform._id,
					redirectPath: routes.MODAL_PLATFORM.path,
				},
			),
		);
	};

	const onLikeHandler = (event: React.MouseEvent) => {
		event?.stopPropagation();
		SocketIO.emit(SOCKET_EVENTS.PLATFORM_LIKE, { id: platform._id });
	};

	useEffect(() => {
		SocketIO.on(SOCKET_EVENTS.PLATFORM_LIKE, (_platform: IPlatform) => {
			if (typeof updatePlatform === 'function' && _platform._id === platform._id) {
				// @ts-ignore
				updatePlatform({ _id: _platform._id, likes: _platform.likes });
			}
		});
	}, [SocketIO]);

	const options = [
		{
			show: ((platform.type === 'Challenge' && (platform?.usuarioModera || platform?.usuarioIsAutor))
				|| (platform.type === 'Brainstorm' && platform.usuarioParticipa)),
			icon: <Icon icon="edit" />,
			label: t('global.edit'),
			onClick: onEditHandler,
		},
		{
			show: true,
			icon: <Icon icon="template" />,
			label: t('templates.new'),
			onClick: onCreateTemplate,
		},
		{
			show: true,
			icon: <Icon icon="fas fa-clone" />,
			label: t('global.duplicate'),
			onClick: () => setFeedbackProps({
				...feedbackProps,
				title: t('feedback.duplicate.title-o', { name: `${t(`global.${flow}`).toLocaleLowerCase()} ${platform.nome}` }),
				rightButtonText: t('global.duplicate'),
				open: true,
				color: 'success',
				onRightButton: onDuplicateHandler,
			}),
		},
		{
			show: platform.status !== 'rascunho' && (platform.usuarioIsAutor || platform.usuarioModera),
			icon: <Icon icon="fas fa-folder" />,
			label: platform.isArchived ? t('global.unarchive') : t('global.archive'),
			onClick: () => setFeedbackProps({
				...feedbackProps,
				title: t(platform.isArchived
					? 'feedback.unarchive.title-o'
					: 'feedback.archive.title-o', { name: `${t(`global.${flow}`).toLocaleLowerCase()} ${platform.nome}` }),
				rightButtonText: platform.isArchived ? t('global.unarchive') : t('global.archive'),
				open: true,
				color: 'success',
				onRightButton: onArchiveHandler,
			}),
		},
		{
			show: ((platform.status === 'rascunho' || platform.ideias?.length < 1) && (platform.usuarioIsAutor || platform.usuarioModera)),
			icon: <Icon icon="remove" />,
			label: t('global.delete'),
			onClick: () => setFeedbackProps({
				...feedbackProps,
				title: t('feedback.delete.title-o', { name: `${t(`global.${flow}`).toLowerCase()} ${platform.nome}` }),
				rightButtonText: t('global.delete'),
				open: true,
				color: 'error',
				onRightButton: onDeleteHandler,
			}),
		},
	];

	const receiveButton = () => {
		if (platform.status === 'rascunho') return null;
		if (platform.possuiPedidoParticipacao) {
			return (
				<ReceiveButton
					mode="invite"
					feedbackOptions={{
						match: {
							platformId: platform?._id,
							flow,
							hasInvite: platform.possuiPedidoParticipacao,
							permission: platform.permissao,
							updatePlatform,
						},
					}}
				/>
			);
		}

		if (platform.usuarioParticipa) {
			if (platform.type === 'Brainstorm' && !platform.usuarioIsAutor) {
				return (
					<ReceiveButton
						className="ml-2"
						mode="leave"
						feedbackOptions={{
							match: {
								platformId: platform?._id,
								flow,
								updatePlatform,
							},
						}}
					/>
				);
			}
		}

		if (!platform.usuarioParticipa && platform.permissao !== 'privada') {
			return (
				<ReceiveButton
					className="ml-2"
					mode="join"
					feedbackOptions={{
						match: {
							platformId: platform?._id,
							flow,
							updatePlatform,
						},
					}}
				/>
			);
		}
		return null;
	};

	const goToPlatform = (e: React.MouseEvent) => {
		e?.stopPropagation();
		if (isHome) {
			navigate(href[platform.type]);
		}
	};

	return (
		<Box
			onClick={goToPlatform}
			className="cover-box"
			style={{
				backgroundImage: platform?.cover?.includes('unsplash') ? `url('${platform?.cover}&w=2048&dpr=2')` : `url('${platform?.cover}')`,
				backgroundColor: 'rgb(4, 72, 109)',
				cursor: isHome ? 'pointer' : 'auto',
			}}
		>
			<div className="overlay" />
			<div className="cover__content">
				<div className="cover-content w-grid-1 inner">
					<div className="top">
						<Box className="top left" sx={{ gap: 1 }}>
							<PlatformTooltip type={flow}>
								<IconButton
									color="inherit"
									className="position-relative circle"
									onClick={(e) => { e.stopPropagation(); }}
								>
									<Icon icon={flow} />
								</IconButton>
							</PlatformTooltip>
							{((platform?.type === 'Challenge' && (platform?.usuarioModera || platform?.usuarioIsAutor))
							|| (platform.type === 'Brainstorm' && platform.usuarioParticipa)) && (!isHome)
							&& (
								<SMenu
									id="platform-menu"
									open={Boolean(anchorEl)}
									anchorEl={anchorEl}
									setAnchorEl={setAnchorEl}
									options={options}
								>
									<SButton color="inherit" size="small">
										{t('global.options')}
									</SButton>
								</SMenu>
							)}
						</Box>
						<div className="center" />
						<Box className="right" sx={{ gap: 1 }}>
							<ActivityChip
								icon={platform?.likes?.length > 0 ? <AiFillHeart className="heart-red" /> : <AiOutlineHeart />}
								className="has-action"
								count={platform?.likes?.length}
								onClick={onLikeHandler}
							/>
							<ActivityChip
								count={ideaLength}
								icon="idea"
								className={`${platform.usuarioCriouIdeias ? 'active' : ''}`}
								tooltip={t('global.ideas')}
								target={`cover_ideas_${platform._id}`}
								onClick={() => showTab({ navId: '#platform-related-tabs', tabId: 'ideas-tab', scroll: true })}
							/>
						</Box>
					</div>
					<div className="middle">
						<div className="left">
							<div className="cover-title">{platform.nome}</div>
							<Box className="cover-subtitle" sx={{ gap: 1 }}>
								<Icon icon={flow} />
								<span className="flow__name">
									{t(`global.${flow}`)}
								</span>
								<span className="created__at">
									{(t('global.created_in', { date: new Date(platform.createdAt) }))?.capitalizeFirstLetter()}
								</span>
								<span className="vertical__bar">|</span>
								<PermissionIcon permission={platform.permissao} />
							</Box>
						</div>
						<div className="center">
							{(platform.type === 'Challenge' && platform?.challengeFormat?.type === 'tempo') && (
								<Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
									<Tooltip classes={{ popper: 'primary' }} arrow title={t('global.timeLeft')} placement="top">
										<Box
											id="deadline"
											className="challenge__deadline"
											sx={{ gap: 1 }}
										>
											<Icon icon="fa fa-stopwatch" className="text-white" />
											<Box className="challenge__clock" sx={{ gap: 1 }}>
												{stopwatch.days && (
													<div>
														<div className="">{stopwatch.days}</div>
														<span>D</span>
													</div>
												)}
												<div>
													<div className="">{stopwatch.hours}</div>
													<span>H</span>
												</div>
												:
												<div>
													<div className="">{stopwatch.minutes}</div>
													<span>M</span>
												</div>
												:
												<div>
													<div className="">{stopwatch.seconds}</div>
													<span>S</span>
												</div>
											</Box>
										</Box>
									</Tooltip>

									<div className="challenge__deadline--total">
										<span>{t('global.challenge')}</span>
										<strong>
											{parseInt(platform.challengeFormat?.value.split(' ')[0], 10) > 24
												? `${parseInt(platform.challengeFormat?.value.split(' ')[0], 10) / 24} ${`${t('global.day')}s`}`
												: platform.challengeFormat?.value}
										</strong>
									</div>
								</Box>
							)}
							{(platform.type === 'Challenge' && platform?.challengeFormat?.type === 'quantidade') && (
								<div>
									<div className="challenge__deadline">
										<Icon icon="sort" />
										<div>{`${platform.ideias?.length} ${t('global.ideas')}`}</div>
									</div>
									<div className="challenge__deadline--total">
										<span>{t('global.challenge')}</span>
										<strong>
											{platform.challengeFormat.value}
										</strong>
									</div>
								</div>
							)}
						</div>
						<div className="right" />
					</div>
					<div className="bottom">
						<div className="left">
							{receiveButton()}
							<div className="ml-2 align-items-center d-flex">
								<UsersGroupCircle
									prefixTarget="platform_cover"
									size="small"
									clickable
									hasMore
									hasPopover
									group={participants}
									id={platform._id}
									tooltipIsMoreText={t('global.participants')}
									onMore={() => showTab({ navId: '#platform-related-tabs', tabId: 'participants-tab', scroll: true })}
								/>
							</div>
						</div>
						{platform.coverAuthor
						&& (
							<CreditsAuthor
								className="center"
								cover={{ author: platform.coverAuthor, url: platform.cover }}
							/>
						)}
						<div className="right">
							{!platform.usuarioIsAutor
							&& (
								<Tooltip classes={{ popper: 'primary' }} arrow title={t('global.reportContent')} placement="top">
									<IconButton
										className="position-relative circle"
										color="inherit"
										onClick={(e) => {
											persistor.pause();
											dispatch(Creators.MODAL.showModal(routes.MODAL_REPORT.path, { _id: platform._id, objeto: 'plataforma' }));
											e.stopPropagation();
										}}
									>
										<Icon icon="flag" className="text-white" />
									</IconButton>
								</Tooltip>
							)}
						</div>
					</div>
				</div>
			</div>
			{feedbackProps.open && (
				<ModalFeedback
					id="platform-cover"
					onRequestClose={() => setFeedbackProps({ open: false })}
					{...feedbackProps}
				/>
			)}
		</Box>
	);
};

const Translation = withTranslation('common')(PPlatformCover);

const PlatformCover = (props: IPlatformCoverProps) => {
	const { platform } = props;
	return (
		<Suspense fallback={<div />}>
			{typeof platform === 'undefined' ? <div /> : <Translation {...props} />}
		</Suspense>
	);
};

export default PlatformCover;
