import { UserAvatar } from '@components/Avatar/UserAvatar';
import { SButton } from '@components/Buttons';
import RichTextEditor from '@components/RichTextEditor';
import Preview, { IPreviewProps } from '@components/Shared/Preview';
import { SToast } from '@modules/SToast';
import { APIGlobal } from '@services/apis';
import { SocketIO } from '@services/socket';
import { getTextFromHTML } from '@utils/helpers';
import React, { Suspense, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IComment } from 'src/@types/IShared';
import { IRootState } from 'src/store';

interface ICommentAreaProps {
	isReply?: boolean
	selectorPreview: string
	edit?: boolean
	objectId?: string
	comment?: IComment
	id?: string
	onSuccess?: () => void
}

declare type TCommentsProps = ICommentAreaProps & WithTranslation

const CCommentArea = (props: TCommentsProps) => {
	const {
		t, isReply, selectorPreview, edit, objectId, comment, onSuccess,
	} = props;

	const { userOrganizationProfile } = useSelector((state: IRootState) => state);
	const [previewData, setPreviewData] = useState<IPreviewProps | undefined>();
	const [forceUpdate, setForceUpdate] = useState<boolean>(false);
	const [content, setContent] = useState(edit ? comment?.conteudo : '');
	const avatarSize = isReply ? 'middle' : 'large';
	const classComment = isReply ? 'comment__sm' : 'comment__lg';
	const buttonLabel = isReply ? t('global.reply') : t('global.comment');

	const text = getTextFromHTML(content || '');

	const handleForceUpdate = () => {
		setForceUpdate(true);
		setTimeout(() => {
			setForceUpdate(false);
		}, 100);
	};

	const onSubmit = (value?: string) => {
		if (typeof value !== 'undefined' && value !== '') {
			if (edit) {
				SocketIO.emit('comment_update', {
					_id: comment?._id,
					conteudo: value,
				});
				if (typeof onSuccess === 'function') {
					onSuccess();
				}
				return;
			}
			SocketIO.emit('comment', {
				objectId,
				comment: value,
				preview: previewData,
				_id: comment?._id,
			});

			setContent('');
			handleForceUpdate();
			setPreviewData(undefined);

			const preview = document.querySelector(`#${selectorPreview}`);

			if (preview) {
				preview.classList.remove('transition');
			}

			const replyBox = document.querySelectorAll('.reply-box');

			if (replyBox) {
				replyBox.forEach((item) => item.classList.add('d-none'));
			}
		} else SToast.error('Faça um comentário');
	};

	const extractUrl = async (value: string) => {
		const urls = value.match(/(\b(https?|http):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig);
		if (urls && urls.length > 0) {
			try {
				const { data } = await APIGlobal.getPreviewUrl(urls[0]);

				setPreviewData({
					link: data['og:url'],
					title: data['og:title'] || data.title,
					image: data.image || data['og:image'],
					description: data['og:description'] || data.description,
				});
				document.querySelector(`#${selectorPreview}`)?.classList.add('transition');
			} catch (err) {
				setPreviewData(undefined);
				console.log('error method onChange', err);
			}
		} else {
			setPreviewData(undefined);
			document.querySelector(`#${selectorPreview}`)?.classList.remove('transition');
		}
	};

	const onChange = (value: string) => {
		extractUrl(value);
		setContent(value);
	};

	const onKeyDown = (e: React.KeyboardEvent) => {
		const ctrl = e.ctrlKey || e.metaKey;
		const shift = e.shiftKey || e.metaKey;
		if (e.key === 'Enter' && !shift) { onSubmit(e.currentTarget.innerHTML); }
	};

	if (!userOrganizationProfile) return <div />;

	return (
		<>
			<div className="comment__author">
				<UserAvatar profile={userOrganizationProfile} size={avatarSize} hasPopover={false} />
			</div>
			<div className={classComment}>
				<div className="panel panel__default">
					<div className="panel__body">
						<header className="text-left mb-10px">
							<div className="comment__user d-inline mr-3">
								{` ${userOrganizationProfile?.user?._nome || userOrganizationProfile?.user?.nome}`}
							</div>
						</header>
						<div className="comment__post">
							<Preview
								title={previewData?.title}
								description={previewData?.description}
								image={previewData?.image}
								link={previewData?.link}
								id={selectorPreview}
							/>
							<RichTextEditor
								value={content}
								onChange={onChange}
								forceUpdate={forceUpdate}
								toolbar={false}
								minHeight="38px"
								showCharsCounter={false}
								showWordsCounter={false}
								showXPathInStatusbar={false}
								onKeyDown={onKeyDown}
							/>
						</div>
					</div>
					<div className="comment__footer">
						<SButton
							type="button"
							color="primary"
							variant="outlined"
							className={`comment ${!edit ? 'ml-2' : ''}`}
							disabled={text.length < 1}
							onClick={() => onSubmit(content)}
						>
							{edit ? t('global.save') : buttonLabel}
						</SButton>
					</div>
				</div>
			</div>
		</>
	);
};

const Translation = withTranslation('common')(CCommentArea);

const CommentArea = (props: ICommentAreaProps) => (
	<Suspense fallback={<div />}>
		<Translation {...props} />
	</Suspense>
);

export { CommentArea };
