/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/no-array-index-key */
// @ts-nocheck
import { useDispatch, useSelector } from 'react-redux';
import React, { useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import routes from '@routes/modal';
import GLOBAL from '@constants/GLOBAL';
import { IRootState } from 'src/store';
import { IIdea } from 'src/@types/IIdea';
import { IPlatform } from 'src/@types/IPlatform';
import { IdeaCard } from '@components/Cards/IdeaCard';
import { IListRequest } from '@components/Lists/types';
import { Creators } from 'src/store/ducks/actionsTypes';
import { PlatformCard } from '@components/Cards/PlatformCard';
import CardGrid, { TCards } from '@components/Grids/CardGrid';
import { IPaginatedRequest, IPagination } from 'src/@types/IShared';
import { handleAxiosErrors } from '@services/auth';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import { SToast } from '@modules/SToast';
import { GenericGrid } from '@components/Grids/GenericGrid';
import PageLoader from '@components/Loaders/PageLoader';
import { APIIdeas, APIPlatforms } from '@services/apis';
import { TPlatformRequest } from '@services/apis/platforms';

interface IState {
	challenges: IPagination<IPlatform>
	brainstorms: IPagination<IPlatform>
	ideas: IPagination<IIdea>
}

type TProps = WithTranslation

const DicPlatformType = {
	challenges: 'challenge',
	brainstorms: 'brainstorm',
};

const _Favorites = ({ t }: TProps) => {
	const navigate = useNavigate();
	const location = useLocation();
	const dispatch = useDispatch();

	const { userOrganizationProfile } = useSelector((state: IRootState) => state);

	const [state, setState] = useState({
		ideas: { docs: [], isLoading: true },
		challenges: { docs: [], isLoading: true },
		brainstorms: { docs: [], isLoading: true },
	});

	const onDeleteHandler = (elementId: string) => {
		Object.keys(state).forEach((k) => {
			const key = k as keyof IState;
			const index = state[key].docs.findIndex((item) => item._id === elementId);
			if (index > -1) {
				state[key].docs.splice(index, 1);
				setState((prevState) => ({
					...prevState,
					[key]: {
						...prevState[key],
					},
				}));
			}
		});
	};

	const loadIdeas = async (params: IListRequest) => {
		try {
			const response = await APIIdeas.getFavorites({ ...params, limit: GLOBAL.DOCS_PER_PAGE });

			setState((prevState) => ({
				...prevState,
				ideas: {
					...prevState.ideas,
					...response.data,
					docs: response.data.hasPrevPage
						? prevState.ideas.docs?.concat(response.data.docs)
						// @ts-ignore
						: response.data.docs,
					isLoading: false,
				},
			}));
		} catch (err: any) {
			handleAxiosErrors(err);
			SToast.error(t('messages.error.idea.favorites'));
		} finally { // loadMore
			if (typeof params.callback === 'function') {
				params.callback();
			}
		}
	};

	const loadPlatforms = async (params: IListRequest, type: TPlatformRequest) => {
		try {
			const response = await APIPlatforms.getFavorites({ ...params, limit: GLOBAL.DOCS_PER_PAGE }, type);

			setState((prevState) => ({
				...prevState,
				[`${type}s`]: {
					...prevState[`${type}s`],
					...response.data,
					docs: response.data.hasPrevPage
						? prevState[`${type}s`].docs?.concat(response.data.docs)
						: response.data.docs,
					isLoading: false,
				},
			}));
		} catch (err: any) {
			SToast.error(t(
				'messages.error.platform.favorites',
				{ type: t(`global.${type}`) },
			));
		} finally { // loadMore
			if (typeof params.callback === 'function') {
				params.callback();
			}
		}
	};

	const loadMore = (params: IListRequest) => {
		const filter = new URLSearchParams(location.search)?.get('filter') || '';

		navigate(`?filter=${filter}`, { replace: true });

		if (['ideas'].includes(filter)) {
			loadIdeas(params);
		}
		if (['challenges', 'brainstorms'].includes(filter)) {
			loadPlatforms(params, DicPlatformType[filter]);
		}
	};

	const updateHandler = (element: IPlatform | IIdea) => {
		const validKeys = Object.keys(state).filter((_key) => ['challenges', 'brainstorms', 'ideas'].includes(_key));

		validKeys.forEach((k) => {
			const key = k as keyof IState;
			const index = state[key].docs.findIndex((item) => item._id === element._id);
			const likeRemoved = !element.likes.find((like) => like.user.toString() === userOrganizationProfile._id.toString());

			if (index > -1 && likeRemoved) {
				// @ts-ignore
				state[key]?.docs.splice(index, 1);

				setState((prevState) => ({
					...prevState,
					[key]: {
						...prevState[key],
					},
				}));
			}
		});
	};

	const onViewMoreHandler = (params: IPaginatedRequest) => {
		navigate(`?filter=${params.filter || ''}`, { replace: true });
		if (['ideas'].includes(params.filter)) {
			loadIdeas(params);
		}

		if (['challenges', 'brainstorms'].includes(params.filter)) {
			loadPlatforms(params, DicPlatformType[params.filter]);
		}
	};

	React.useEffect(() => {
		const params = {
			filter: new URLSearchParams(location.search)?.get('filter') || 'last-first',
		};

		loadIdeas(params);
		loadPlatforms(params, 'challenge');
		loadPlatforms(params, 'brainstorm');

		dispatch(Creators.IDEAS.updateIdeas({ syncIdea: updateHandler }));
		dispatch(Creators.PLATFORMS.updatePlatform({ syncPlatform: updateHandler }));
	}, []);

	const params = {
		filter: new URLSearchParams(location.search)?.get('filter') || '',
	};

	return (
		<GenericGrid id="favorites-page" className="drafts__page s-grid-page" paddingTop={6}>
			{[
				{
					section: state.brainstorms,
					type: 'brainstorms',
					component: (platform: IPlatform) => (
						<PlatformCard
							platform={platform}
							routes={routes}
							sectionLocator="brainstorms"
							onDelete={onDeleteHandler}
							updatePlatform={updateHandler}
						/>
					),
				},
				{
					section: state.challenges,
					type: 'challenges',
					component: (platform: IPlatform) => (
						<PlatformCard
							platform={platform}
							sectionLocator="challenges"
							routes={routes}
							onDelete={onDeleteHandler}
							updatePlatform={updateHandler}
						/>
					),
				},
				{
					section: state.ideas,
					type: 'ideas',
					component: (idea: IIdea) => (
						<IdeaCard
							idea={idea}
							routes={routes}
							sectionLocator="ideas"
							onDelete={onDeleteHandler}
							updateIdea={updateHandler}
						/>
					),
				},
			].map((item, index) => (
				<Box key={index} className="w-grid-1 inner">
					<CardGrid
						id={`${item.type}-favorites`}
						section={item.section}
						queryParams={{ limit: GLOBAL.DOCS_PER_PAGE }}
						headerLabel={t(`page.${item.type}.favorites`)}
						GridItemProps={{
							xs: 12, sm: 6, md: 4, xl: 3,
						}}
						type={item.type as TCards}
						hide={params.filter !== '' && params.filter !== item.type}
						onViewMore={params.filter === '' ? () => onViewMoreHandler({ filter: item.type }) : undefined}
						loadMore={loadMore}
						component={item.component}
					/>
				</Box>
			))}
		</GenericGrid>
	);
};

const Translation = withTranslation('common')(_Favorites);

const Favorites = (props: TProps) => (
	<React.Suspense fallback={<PageLoader />}>
		<Translation {...props} />
	</React.Suspense>
);

export default Favorites;
