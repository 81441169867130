/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/extensions */
import React from 'react';
import ReactDOM from 'react-dom';
import './services/i18n';
import 'bootstrap';
import './prototypes/index.d.ts';
import App from './App';

ReactDOM.render(<React.StrictMode><App /></React.StrictMode>, document.getElementById('root'));
