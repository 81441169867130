import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from 'src/store';
import { Creators } from '@actions';
import {
	AppBar, Box, ClickAwayListener, IconButton, Toolbar, ToolbarProps, Typography,
} from '@mui/material';
import { MdMenu } from 'react-icons/md';
import Sidebar from '@components/Header/Sidebar';
import _ from 'lodash';
import { useLocation, useNavigate } from 'react-router-dom';
import routes from '@routes/modal';
import { useTranslation } from 'react-i18next';
import HeaderMenuNew from './HeaderMenuNew';
import HeaderNotifications from './HeaderNotifications';
import HeaderSearch from './HeaderSearch';
import HeaderUser from './HeaderUser';
import URLS from '@constants/URLS';
import { SButton } from '@components/Buttons';
import { SLink } from '@components/Buttons/SLink';

interface HeaderProps {
	SToolbarProps?: ToolbarProps
}

const HHeader = (props: HeaderProps) => {
	const { t } = useTranslation();
	const { SToolbarProps } = props;

	const { userOrganizationProfile } = useSelector((state: IRootState) => state);
	const state = useSelector((state: IRootState) => state);
	const dispatch = useDispatch();
	const [open, setOpen] = React.useState<boolean>(false);

	const location = useLocation();
	const navigate = useNavigate();
	const { pathname } = location;
	const title = t(`global.${pathname.split('/')[1] || 'home'}`);

	document.title = `${title} | SENNO`;

	console.log('STORE >>>>', state);

	useEffect(() => {
		dispatch(Creators.USER_ORGANIZATION.getUserOrganization());
	}, []);

	return (
		<AppBar
			id="sAppBar"
			variant="elevation"
			position="static"
			sx={{ backgroundColor: '#F7F7F7', boxShadow: 'none' }}
		>
			<Toolbar
				id="sToolbar"
				disableGutters
				{...SToolbarProps}
				sx={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
					height: 'calc(var(--header-height) - 72px)',
					padding: { xs: '1rem', md: 'var(--header-padding-Y) var(--header-padding-X)' },

					...SToolbarProps?.sx,
				}}
			>
				<ClickAwayListener onClickAway={() => setOpen(false)}>
					<Box sx={{ display: 'flex', alignItems: 'center' }}>
						<Sidebar open={open} setOpen={setOpen} />
						<IconButton
							className="bt-expand-menu"
							onClick={!_.isEmpty(userOrganizationProfile) ? () => setOpen(true) : undefined}
						>
							<MdMenu />
						</IconButton>
						<SLink
							underline="none"
							href="# "
							sx={{
								color: 'var(--charcoal-color)',
								cursor: 'pointer',
								marginLeft: '0.25rem',
								animationDelay: '.2s',
								'&:hover': {
									fontWeight: '700',
								},
							}}
							// @ts-ignore
							onClick={() => navigate(URLS[pathname?.split('/')[1]?.toUpperCase()])}
						>
							{title}
						</SLink>
					</Box>
				</ClickAwayListener>
				<Box sx={{
					display: 'flex',
					alignItems: 'center',
					'.item-menu': {
						marginLeft: '8px',
					},
				}}
				>
					<Box
						className="item-menu"
						sx={{
							padding: '8px',
							width: { xs: '100%', sm: '200px' },
							'.MuiPaper-root': {
								width: { xs: '100%', sm: '200px' },
							},
						}}
					>
						<HeaderSearch id="search-form-desktop" />
					</Box>
					<Box
						className="item-menu"
						sx={{
							padding: '8px',
						}}
					>
						<HeaderMenuNew />
					</Box>
					<Box
						className="item-menu"
						sx={{ padding: '8px', cursor: 'pointer' }}
						onClick={() => {
							dispatch(Creators.SHARED.updateShared({ step: 'trails' }));
							dispatch(Creators.MODAL.showModal(routes.MODAL_TRAILS.path));
						}}
					>
						<Typography
							id="menu-help"
							color="secondary"
						>
							Tour
						</Typography>
					</Box>
					<Box className="item-menu">
						<HeaderNotifications />
					</Box>
					<Box className="item-menu">
						<HeaderUser />
					</Box>
				</Box>
			</Toolbar>
		</AppBar>
	);
};

const Header = (props: HeaderProps) => (
	<React.Suspense fallback={<div />}>
		<HHeader {...props} />
	</React.Suspense>
);

export default Header;
