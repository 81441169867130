import React from 'react';
import { Box } from '@mui/material';

interface IStepsProps {
	step: number
	hide?: boolean
	options: {
		step: number
		onClick?: () => void
	}[]
}

const StepsGenerator = (props: IStepsProps) => {
	const {
		step = 1, hide, options,
	} = props;

	if (hide) return null;

	return (
		<Box
			className="steps"
			sx={{
				padding: '30px',
				display: 'flex',
				justifyContent: 'center',

				div: {
					height: '10px',
					width: '10px',
					backgroundColor: 'var(--light-gray-color)',
					borderRadius: '50%',
					margin: '0 5px',

					'&.active': {
						backgroundColor: 'var(--primary)',
					},
				},
			}}
		>
			{options.map((item, index) => (
				<div
					aria-hidden
					onClick={index + 1 <= step && typeof item.onClick === 'function' ? item.onClick : undefined}
					className={`${step === item.step ? 'active' : ''}`}
					key={item.step}
					style={{ cursor: index + 1 <= step && typeof item.onClick === 'function' ? 'pointer' : 'auto' }}
				/>
			))}
		</Box>
	);
};

export { StepsGenerator };
