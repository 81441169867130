// @ts-nocheck
import React, { Suspense, useEffect, useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import ModalHeader from '@modules/Modal/ModalHeader';
import { normalizesValue } from '@utils/helpers';
import type { IModal } from '@modules/Modal/types';
import { IPaginatedRequest } from 'src/@types/IShared';
import { useDispatch, useSelector } from 'react-redux';
import { Creators } from '@actions';
import { DialogBox } from '@modules/Modal/Components';
import { DynamicCircle } from '@components/DynamicCircle';
import { IRootState } from 'src/store';
import SpinnerLDSRipple from '@components/Shared/SpinnerLDSRipple';
import { DialogContent, DialogTitle } from '@mui/material';
import { SBox } from '@components/Background/SBox';
import { GenericGrid, W_GRID } from '@components/Grids/GenericGrid';

interface IOwnProps extends IModal { }
declare type TProps = IOwnProps & WithTranslation;

const MModalEvaluationsForCriteria = (props: TProps) => {
	const { t } = props;
	const dispatch = useDispatch();

	const ModalProps = useSelector((state: IRootState) => state.modal?.paramsSubModal);
	const [evaluations, setEvaluations] = useState({ docs: [], isLoading: true });

	const loadEvaluators = (params: IPaginatedRequest) => {
		dispatch(Creators.IDEAS.getEvaluatorsByIdea({
			ideaId: ModalProps.ideaId,
			queryParams: params,
			onSuccess: (_evaluations) => setEvaluations({
				..._evaluations,
				isLoading: false,
			}),
		}));
	};

	useEffect(() => {
		loadEvaluators({ page: 1 });
	}, []);

	return (
		<DialogContent className="modal__evaluations" id="EvaluationsDialog">
			<ModalHeader
				hasButtonBack={false}
				BreadcrumbProps={{ className: W_GRID.W_GRID_2 }}
				hideNav
			/>
			<DialogBox className="s-dialog-box inner w-grid-2">
				<DialogTitle variant="h6">
					<div className="d-flex">
						<span className="mr-1 first-letter-capitalize">{t('evaluation.for')}</span>
						<strong className="first-letter-capitalize">{t(ModalProps.criteria)}</strong>
					</div>
				</DialogTitle>
				<SBox
					className="modal__content"
					loading={!evaluations || evaluations.isLoading || !evaluations.docs}
				>
					<ul className="list">
						{evaluations?.docs?.map((item) => (
							<li className="" key={item._id}>
								<div className="col-3 d-flex pl-0 align-items-center">
									<DynamicCircle
										icon={item.avaliacoes[0].icon}
										size="small"
										className={`evaluation ${normalizesValue(item.avaliacoes[0].value)}`}
									/>
									<span className="ml-2">{t(item.avaliacoes[0].type)}</span>
								</div>
								<div className="align-items-center col-6 d-flex">
									<span className="mr-2 font-light">{t('global.by')}</span>
									<DynamicCircle
										image={item.moderador.user.image}
										initial={item.moderador.user._nome || item.moderador.user.nome}
										background={item.moderador.user.cor || 'var(--primary)'}
									/>
									<span className="font-light ml-2 word-ellipsis">{item.moderador.user._nome || item.moderador.user.nome}</span>
								</div>
								<div className="align-items-end font-light d-flex ml-auto mr-1">
									{t('global.in', { date: new Date(item.lastEvaluation) })}
								</div>
							</li>
						))}
					</ul>
				</SBox>
			</DialogBox>
		</DialogContent>
	);
};

const Translation = withTranslation('common')(MModalEvaluationsForCriteria);
const ModalEvaluationsForCriteria = (props: IOwnProps) => (
	<Suspense fallback={<div />}>
		<Translation {...props} />
	</Suspense>
);

export default ModalEvaluationsForCriteria;
