import React from 'react';
import { useTranslation } from 'react-i18next';
import { TPlatform } from 'src/@types/IPlatform';
import { DynamicCircle } from '@components/DynamicCircle';
import {
	Divider, Grid, Tooltip, TooltipProps, Typography,
} from '@mui/material';
import { HelpButton } from '../Buttons/HelpButton';

interface PlatformTooltipProps extends TooltipProps {
	type: TPlatform
}

export const PlatformTooltip = (props: PlatformTooltipProps) => {
	const {
		type, ...rest
	} = props;
	const { t } = useTranslation();

	return (
		<Tooltip
			arrow
			{...rest}
			placement="bottom-start"
			classes={{ popper: 'primary' }}
			title={(
				<Grid
					container
					padding={1}
					spacing={2}
					sx={{
						alignItems: 'center',
						'.MuiGrid-item': {
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center',
							alignItems: 'center',
						},
					}}
				>
					<Grid item xs={12}>
						<DynamicCircle
							icon={type}
							size="x-large"
							className="default-colors"
						/>
					</Grid>
					<Grid item xs={12}>
						<Typography textTransform="none">
							{t(`global.${type}`).toUpperCase()}
							<HelpButton href={t(`urls.${type}`)} />
						</Typography>
					</Grid>
					<Grid item>
						<Divider />
					</Grid>
					<Grid item className="pt-0">
						<p className="text-center mb-0">
							{t(`platform.${type}`)}
						</p>
					</Grid>
				</Grid>
			)}
		/>
	);
};
