import UnauthenticatedHeader from '@components/Header/UnauthenticatedHeader';
import PageLoader from '@components/Loaders/PageLoader';
import URLS from '@constants/URLS';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { GenericGrid } from '@components/Grids/GenericGrid';
import { SLink } from '@components/Buttons/SLink';
import { useNavigate } from 'react-router-dom';

declare type TProps = WithTranslation

const _NotFound = ({ t }: TProps) => {
	const navigate = useNavigate();
	return (
		<GenericGrid id="not-found-page" className="not__found--page s-grid-page">
			<UnauthenticatedHeader />
			<div className="not__found--container inner w-grid-3 unauthenticated">
				<div className="">
					{/* @ts-ignore */}
					<lottie-player
						src="images/json/header_whitepaper.json"
						background="transparent"
						speed="1"
						loop=""
						autoplay=""
						style={{ maxWidth: '220px' }}
					/>
				</div>
				<p className="error">404</p>
				<p className="text">{t('feedback.pageNotFound')}</p>
				<SLink onClick={() => navigate(URLS.HOME)} href="# ">
					{`${t('global.goTo')} SENNO`}
				</SLink>
			</div>
		</GenericGrid>
	);
};

const Translation = withTranslation('common')(_NotFound);

const NotFound = () => (
	<React.Suspense fallback={<PageLoader />}>
		<Translation />
	</React.Suspense>
);

export default NotFound;
