// @ts-nocheck
import React, { Suspense, useEffect, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from 'src/store';
import type { IModal } from '@modules/Modal/types';
import {
	 DialogBox,
} from '@modules/Modal/Components';
import { handleAxiosErrors } from '@services/auth';
import { IPaginatedRequest } from 'src/@types/IShared';
import ModalHeader from '@modules/Modal/ModalHeader';
import FiltersNavBar from '@components/NavBar/FiltersNavBar';
import { APIPlatforms } from '@services/apis';
import { DynamicCircle } from '@components/DynamicCircle';
import { IUserOrganization } from 'src/@types/IUser';
import { UsersGroupCircle } from '@components/Avatar/UsersGroupCircle';
import { IListRequest } from '@components/Lists/types';
import {
	DialogContent, DialogTitle, List, ListItem, ListItemAvatar, ListItemText,
} from '@mui/material';
import { STabs } from '@components/STabs';
import { STabPanel } from '@components/STabs/STabPanel';
import { SBox } from '@components/Background/SBox';
import { GenericGrid, W_GRID } from '@components/Grids/GenericGrid';

declare type TProps = IModal & WithTranslation;

interface IState {
	[key: string]: {
		avaliadores: IUserOrganization[]
		ideia_id: string
		ideia_image: string
		ideia_nome: string
		tipo: string
	}
}

const MModalDashboard = (props: TProps) => {
	const { t, routes, matrix } = props;

	console.log('props', props);

	const dispatch = useDispatch();
	const { flow, ModalProps } = useSelector((state: IRootState) => state.modal);
	const [activeTab, setActiveTab] = useState(undefined);

	const [evaluatedIdeas, setEvaluatedIdeas] = useState<IState>({ isLoading: true });
	const [queryParams, setQueryParams] = useState<IPaginatedRequest>({
		sort: 'high-low',
	});

	const loadEvaluatedIdeas = async (params: IListRequest) => {
		try {
			setQueryParams(params);

			const { filter } = params;

			setEvaluatedIdeas((prevState) => ({ ...prevState, isLoading: true }));

			const { platformId } = ModalProps;

			const response = await APIPlatforms.getEvaluatedIdeas(
				platformId,
				matrix,
				params.filter && matrix === 'time-return' ? { ...params, filter: '' } : params,
			);

			setEvaluatedIdeas((prevState) => ({
				...prevState,
				[filter]: response?.data[filter] || response?.data || '',
				isLoading: false,
			}));
		} catch (err) {
			handleAxiosErrors(err);
		} finally { // loadMore
			if (typeof params.callback === 'function') {
				params.callback();
			}
		}
	};

	const onToggle = (tab: string) => {
		console.log('tab', tab);
		setActiveTab(tab);
		loadEvaluatedIdeas({ ...queryParams, filter: tab });
	};

	const getParams = (matrix: string) => {
		switch (matrix) {
			case 'disruption':
				return {
					TABS: {
						breakthrough: {
							value: 'breakthrough',
							label: `Breakthrough (${evaluatedIdeas?.breakthrough?.length || 0})`,
							className: 'evaluation breakthrough',
							onClick: () => onToggle('breakthrough'),
						},
						incremental: {
							value: 'incremental',
							label: `Incremental (${evaluatedIdeas?.incremental?.length || 0})`,
							className: 'evaluation incremental',
							onClick: () => onToggle('incremental'),
						},
						search: {
							value: 'pesquisa',
							label: `Search Basic (${evaluatedIdeas?.pesquisa?.length || 0})`,
							className: 'evaluation pesquisa',
							onClick: () => onToggle('pesquisa'),
						},
						radical: {
							value: 'radical',
							label: `Radical (${evaluatedIdeas?.radical?.length || 0})`,
							className: 'evaluation radical',
							onClick: () => onToggle('radical'),
						},
					},
					sorts: [
						{ value: 'high-low', label: t('sort.high-low') },
						{ value: 'low-high', label: t('sort.low-high') },
					],
				};

			case 'difficulty-impact':
				return {
					TABS: {
						quickWin: {
							value: 'quick-win',
							label: 'Quick Win',
							className: 'evaluation breakthrough',
							onClick: () => onToggle('quick-win'),
						},
						majorProject: {
							value: 'major-project',
							label: 'Major Project',
							className: 'evaluation incremental',
							onClick: () => onToggle('major-project'),
						},
						fillIns: {
							value: 'fill-ins',
							label: 'Fill Ins',
							className: 'evaluation pesquisa',
							onClick: () => onToggle('fill-ins'),
						},
						hardSlogs: {
							value: 'hard-slogs',
							label: 'Hard Slogs',
							className: 'evaluation radical',
							onClick: () => onToggle('hard-slogs'),
						},
					},
					sorts: [
						{ value: 'high-low', label: t('sort.high-low') },
						{ value: 'low-high', label: t('sort.low-high') },
					],
				};
			case 'time-return':
				return {
					TABS: {
						timeReturn: { value: 'time-return', label: '' },
					},
					sorts: [
						{ value: 'low-time-low-return', label: t('sort.time-return.low-time-low-return') },
						{ value: 'low-time-high-return', label: t('sort.time-return.low-time-high-return') },
						{ value: 'high-time-low-return', label: t('sort.time-return.high-time-low-return') },
						{ value: 'high-time-high-return', label: t('sort.time-return.high-time-high-return') },
					],
				};
			default:
				break;
		}

		return null;
	};

	const hideTabs = matrix === 'time-return';

	const onRenderHandler = (items, tab) => (
		<SBox loading={items.isLoading} empty={items[tab]?.length === 0 && 'evaluations'}>
			<List>
				{items[tab]?.map((item) => (
					<ListItem key={item.ideia_id} sx={{ alignItems: 'center', borderBottom: '1px solid var(--fog-gray-color)', gap: 1 }}>
						<ListItemAvatar sx={{ minWidth: 'auto' }}>
							<DynamicCircle
								image={item.ideia_image}
								initial={item.ideia_nome || item.idea.nome}
								background="var(--primary)"
							/>
						</ListItemAvatar>
						<ListItemText
							primary={item.ideia_nome || item.idea.nome}
							secondary={t('idea.lastEvaluation', { date: new Date(item.lastEvaluation) })}
						/>

						{item.avaliadores && (
							<UsersGroupCircle
								id="dashboard-evaluators"
								group={item.avaliadores}
								hasMore
								hasPopover
								prefixTarget={`evaluator-${item.ideia_id}`}
							/>
						)}
					</ListItem>
				))}
			</List>
		</SBox>
	);

	useEffect(() => {
		if (matrix) {
			const tab = Object.values(getParams(matrix)?.TABS)[0].value;
			setActiveTab(tab);

			Object.values(getParams(matrix)?.TABS).forEach((item) => {
				const params = {
					...queryParams,
					filter: item.value,
					sort: Object.values(getParams(matrix)?.sorts)[0].value,
				};

				loadEvaluatedIdeas(params);
			});
		}
	}, [matrix]);

	return (
		<DialogContent className="modal__dashboard">
			<ModalHeader
				fullScreen
				icon={flow}
				hideBack
				BreadcrumbProps={{ className: W_GRID.W_GRID_2 }}
				pathParams={{
					rootPath: ModalProps.rootPath,
					title: ModalProps.title,
				}}
			/>
			<DialogBox className="s-dialog-box inner w-grid-2">
				<SBox loading={!flow}>
					<DialogTitle variant="h1">
						{ModalProps.title}
					</DialogTitle>
					<STabs
						tabs={Object.values(getParams(matrix)?.TABS)}
						id="dashboard-tabs"
						classes={{ appBar: hideTabs ? 'd-none' : '' }}
						active={Object.values(getParams(matrix)?.TABS)[0].id}
						onChange={onToggle}
						sx={{ sTabs: { marginY: 6 } }}
						TabPanelProps={{
							sx: {
								marginY: 3,
							},
						}}
					>
						<STabPanel
							value={activeTab}
							// onMount={() => setActiveTab(Object.values(getParams()?.TABS)[0].id)}
						>
							<FiltersNavBar
								id="dashboard-nav"
								onSearch={loadEvaluatedIdeas}
								onUpdate={(params) => loadEvaluatedIdeas({ ...params, filter: activeTab })}
								sorts={getParams(ModalProps.matrix)?.sorts || []}
								params={queryParams}
								controlled
								sx={{
									marginY: 3,
								}}
							/>
							{onRenderHandler(evaluatedIdeas, activeTab)}
						</STabPanel>
					</STabs>
				</SBox>
			</DialogBox>
		</DialogContent>
	);
};

const Translation = withTranslation('common')(MModalDashboard);

const ModalDashboard = (props: IModal) => (
	<Suspense fallback={<DialogContent />}>
		<Translation {...props} />
	</Suspense>
);
export default ModalDashboard;
