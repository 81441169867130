// @ts-nocheck
import React, {
	InputHTMLAttributes, FormHTMLAttributes,
	DetailedHTMLProps,
} from 'react';
import {
	Checkbox, FormControlLabel,
	FormGroup,
	FormGroupProps,
	InputLabel, InputLabelProps,
	Radio,
	RadioProps,
} from '@mui/material';
import { SInputStatus } from './Components/SInputStatus';

export type TInput = DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;
export type TLabel = React.DetailedHTMLProps<React.LabelHTMLAttributes<HTMLLabelElement>, HTMLLabelElement>;
export type TForm = DetailedHTMLProps<FormHTMLAttributes<HTMLFormElement>, HTMLFormElement>;

export const SInputLabel = ({ className, ...rest }: InputLabelProps) => (
	<InputLabel className={`s-label ${className}`} {...rest} />
);
export const SFormGroup = ({ className = '', ...rest }: FormGroupProps) => (
	<FormGroup className={`form-group ${className}`} {...rest} />
);

export const Form = ({ children, ...rest }) => (
	<form {...rest} autoComplete="off">
		{children}
	</form>
);

interface ICheckProps extends TInput {
	id: string
	children?: string | React.ReactChildren
	labelClass?: string
	className?: string
	isLoading?: boolean
	isAsync?: boolean
	label?: React.ReactNode
}

export const SRadio = (props: ICheckProps & RadioProps) => {
	const {
		className = '', isLoading, isAsync, disabled, ...rest
	} = props;

	return (
		<div className={`s-check ${className}`}>
			<FormControlLabel
				control={<Radio color="primary" />}
				disabled={disabled || isLoading}
				{...rest}
			/>
			<SInputStatus isLoading={isLoading} isAsync={isAsync} />
		</div>
	);
};

export const SCheckbox = (props: ICheckProps) => {
	const {
		className = '', isLoading, isAsync, id, ...rest
	} = props;
	return (
		<div className={`s-check ${className}`}>
			<FormControlLabel
				control={<Checkbox id={id} color="primary" />}
				{...rest}
			/>
			<SInputStatus isLoading={isLoading} isAsync={isAsync} />
		</div>
	);
};

