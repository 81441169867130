/* eslint-disable no-underscore-dangle */
// @ts-nocheck
import React, { Suspense } from 'react';
import { normalizesValue } from '@utils/helpers';
import { withTranslation, WithTranslation } from 'react-i18next';
import { IEvaluation } from 'src/@types/IIdea';
import { DynamicCircle } from '@components/DynamicCircle';
import { CriteriaTooltip } from '@components/STooltips/CriteriaTooltip';
import {
	Box, IconButton, List, ListItem,
} from '@mui/material';
import Icon from '@components/Shared/Icon';

interface EvaluateIconsProps {
	ideaId: string
	isCover?: boolean
	className?: string
	averageEvaluations: IEvaluation[]
	prefixTarget?: string
}

declare type TProps = EvaluateIconsProps & WithTranslation

const EEvaluateIcons = (props: TProps) => {
	const {
		t,
		averageEvaluations = [],
		ideaId,
		isCover,
		className = '',
		prefixTarget = 'evaluation',
	} = props;

	return (
		<List
			className={`pl-0 evaluations-icons ${className}`}
			data-id={ideaId}
			sx={{ display: 'flex' }}
		>
			{averageEvaluations?.map((criteria) => {
				const target = `criteria-${criteria.name}${ideaId}-${prefixTarget}`;
				return (
					<ListItem
						key={`${criteria.name}-${ideaId}-${prefixTarget}`}
						id={target}
						sx={{ padding: 0, textAlign: 'center', borderBottom: 'none !important' }}
					>
						{isCover ? (
							<Box display="flex" flexDirection="column">
								<DynamicCircle
									size="x-large"
									icon={criteria.icon}
									className={`evaluation ${normalizesValue(criteria.value || 'Pendente')}`}
								/>
								<span className="sub__label mt-10px">
									{t(criteria.type)}
								</span>
								{criteria.value !== 'Pendente' && (
									<span className="mt-5px sub__label">
										{t(`evaluation.values.${normalizesValue(criteria.value)}`)}
									</span>
								)}
							</Box>
						) : (
							<CriteriaTooltip
								id={target}
								placement="top"
								title={t(criteria.type)}
								criteria={criteria.name}
								icon={criteria.icon}
								value={criteria.value || 'Pendente'}
							>
								<IconButton
									size="small"
									className={`evaluation ${normalizesValue(criteria.value || 'Pendente')}`}
									sx={{ width: '30px', height: '30px' }}
								>
									<Icon icon={criteria.icon} />
								</IconButton>
							</CriteriaTooltip>
						)}
					</ListItem>
				);
			})}
		</List>
	);
};

const Translation = withTranslation('common')(EEvaluateIcons);

const EvaluateIcons = (props: EvaluateIconsProps) => (
	<Suspense fallback={<div />}>
		<Translation {...props} />
	</Suspense>
);

export { EvaluateIcons };
