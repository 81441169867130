// @ts-nocheck

import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import React, { ChangeEvent, useState } from 'react';
import { DialogBox } from '@modules/Modal/Components';
import { SFormGroup, SInputLabel } from '@components/DynamicForm/ComponentsCore';
import {
	Dialog, DialogActions, DialogContent, DialogTitle, ModalProps,
} from '@mui/material';
import { TObject } from 'src/@types/IShared';
import { SButton } from '@components/Buttons';
import { APIOrganizations } from '@services/apis';
import { IUserOrganization } from 'src/@types/IUser';
import { STextField } from '@components/DynamicForm/Components';
import { SToast } from '@modules/SToast';
import { GenericGrid } from '@components/Grids/GenericGrid';

interface IOwnProps extends ModalProps {
	user: IUserOrganization
}

const ModalRemoveUser = (props: IOwnProps) => {
	const { t } = useTranslation();

	const { user, onClose, open } = props;

	const [userName, setUsername] = useState('');
	const [errors, setErrors] = useState<TObject>();

	const onHandleRemoveUser = async () => {
		if (userName !== user.user.nome) {
			setErrors({ name: t('messages.namesDoNotMatch') });
			return;
		}

		try {
			const response = await APIOrganizations.removeUser(user._id);

			if (response.status === 204) {
				onClose?.();
				SToast.success(t('page_settings.tabcontent.users.removeSucces'));
			}
		} catch (err: any) {
			SToast.error(err.response.data);
		}

		// const result =
	};

	const onErrorHandler = (field: string) => {
		if (!isEmpty(errors)) delete errors[field];
	};

	return (
		<Dialog
			id="RemoveUserDialog"
			keepMounted
			open={open}
			maxWidth="xs"
			fullWidth
			onClose={onClose}
		>
			<GenericGrid className="s-grid-modal">
				<DialogContent>
					<DialogBox className="s-dialog-title">
						<DialogTitle variant="h6">
							{t('page_settings.tabcontent.users.removeTitle')}
						</DialogTitle>
					</DialogBox>

					<DialogBox className="s-dialog-box s-form">
						<p>
							{t('page_settings.tabcontent.users.removeUserText', { platformsCount: user.plataformas_count, ideasCount: user.ideiasCount })}
						</p>
						<SFormGroup>
							<SInputLabel htmlFor="user-name" error={typeof errors?.name === 'string'}>
								{t('page_settings.tabcontent.users.insertName')}
							</SInputLabel>
							<STextField
								type="text"
								id="user-name"
								value={userName}
								autoFocus
								autoComplete="off"
								error={typeof errors?.name === 'string'}
								helperText={errors?.name}
								onChange={(e: ChangeEvent<HTMLInputElement>) => {
									onErrorHandler('name');
									setUsername(e.currentTarget.value);
								}}
							/>
						</SFormGroup>
					</DialogBox>
					<DialogActions className="s-dialog-actions">
						<SButton
							type="button"
							color="secondary"
							onClick={() => onClose()}
						>
							{t('global.cancel')}
						</SButton>
						<SButton
							id="feedback-right-button"
							type="button"
							color="error"
							onClick={onHandleRemoveUser}
						>
							{t('global.delete')}
						</SButton>
					</DialogActions>
				</DialogContent>
			</GenericGrid>
		</Dialog>
	);
};

export default ModalRemoveUser;
