import i18next from 'i18next';
import { TLanguages } from 'src/@types/IShared';

export const languages = {
	'pt-BR': { value: 'pt-BR', label: 'Português (Brasil)', details: 'Portuguese (Brazil)' },
	'en-US': { value: 'en-US', label: 'English (United States)', details: 'English (United States)' },
};

// @ts-ignore
export const languagesOptions = Object.keys(languages).map((lang: TLanguages) => languages[lang]);

export const LanguageMap = {
	en: 'English',
	pt: 'Português',
};
