import React, { Suspense } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Icon from '@components/Shared/Icon';
import SpinnerLDSRipple from '@components/Shared/SpinnerLDSRipple';
import { Creators } from '@actions';
import routes from '@routes/modal';
import TableSearch from '@components/DataTable/TableSearch';
import { IOrganization } from 'src/@types/IOrganization';
import { TableColumn } from 'react-data-table-component';
import { IRootState, persistor } from 'src/store';
import { SButton } from '@components/Buttons';
import {
	Box, Chip, Grid, Typography,
} from '@mui/material';
import { SBox } from '@components/Background/SBox';

type TProps = WithTranslation;

const LList = ({ t }: TProps) => {
	const { userOrganizationProfile } = useSelector((state: IRootState) => state);
	const dispatch = useDispatch();

	const columns: TableColumn<IOrganization>[] = [
		{
			name: t('global.name'),
			selector: (row) => row.nome,
			sortable: true,
			cell: (row) => (
				<Box sx={{
					display: 'flex',
					alignItems: 'center',
					'.s-chip': {
						marginRight: '5px',
					},
					'.MuiChip-outlined': {
						color: '#fff',
						backgroundColor: 'var(--primary)',
					},
					'.MuiChip-filled': {
						color: 'rgba(0, 0, 0, 0.6)',
						backgroundColor: '#cbd4db',
						boxShadow: 'inset 0 -2px rgba(0, 0, 0, 0.05)',
					},
				}}
				>
					<Chip
						className="s-chip s-workspace"
						label={(row.nome || '').getInitials()}
						variant={userOrganizationProfile?.currentTenant === row._id ? 'filled' : 'outlined'}
					/>
					<Typography>
						{row.nome}
					</Typography>
				</Box>

			),
		},
		{
			name: t('global.members'),
			sortable: true,
			selector: (row) => row.totalUsers,
			center: true,

		},
		{
			name: t('global.platforms'),
			sortable: true,
			selector: (row) => row.totalPlataformas,
			center: true,
		},
		{
			name: t('global.ideas'),
			sortable: true,
			selector: (row) => row.totalIdeias,
			center: true,
		},
		{
			name: 'Status',
			center: true,
			cell: (row) => {
				const isCurrentOrg = row._id === userOrganizationProfile?.currentTenant;
				return (
					<SButton
						type="button"
						disabled={isCurrentOrg}
						className="link"
						onClick={!isCurrentOrg
							? () => dispatch(Creators.USER_ORGANIZATION.changeOrganization(row._id))
							: undefined}
					>
						{isCurrentOrg ? t('global.currentSession') : t('organization.switch')}
					</SButton>
				);
			},
		},
	];

	return (
		<SBox className="organizations__list">
			<Grid container className="section__header" spacing={3} flexDirection="column">
				<Grid item xs={12}>
					<SButton
						type="button"
						color="secondary"
						className="s-link"
						startIcon={<Icon icon="organization" />}
						onClick={() => {
							persistor.pause();
							dispatch(Creators.MODAL.showModal(routes.MODAL_ORGANIZATION_CREATE.path));
						}}
					>
						<span className="bold">{t('organization.create')}</span>
					</SButton>
				</Grid>
				<Grid item xs={12}>
					<TableSearch
						columns={columns}
						data={userOrganizationProfile?.organizations || []}
						progressPending={false}
					/>
				</Grid>
			</Grid>
		</SBox>
	);
};

const Translation = withTranslation('common')(LList);

const List = () => (
	<Suspense fallback={<SpinnerLDSRipple />}>
		<Translation />
	</Suspense>
);

export default List;
