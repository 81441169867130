/* eslint-disable react/no-danger */
import React, {
	Suspense, useRef, useEffect, useState,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation, withTranslation, WithTranslation } from 'react-i18next';
import { goToLink, isEmpty } from '@utils/ReactUtils';
import {
	elapsedTimeSinceCreation, overflowText, createBackground, normalizesValue,
} from '@utils/helpers';
import URLS from '@constants/URLS';
import { UserAvatar } from '@components/Avatar/UserAvatar';
import { handleAxiosErrors } from '@services/auth';
import { SocketIO } from '@services/socket';
import { IPagination } from 'src/@types/IShared';
import { INotification } from 'src/@types/IOrganization';
import { EmptyState } from '@components/Shared/EmptyState';
import { Creators } from '@actions';
import { DynamicCircle } from '@components/DynamicCircle';
import { FLOWS } from '@constants/GLOBAL';
import { NOTIFICATIONS_ACTIONS } from '@constants/notifications';
import {
	Box, BoxProps, ClickAwayListener, IconButton, SwipeableDrawer, Tooltip,
} from '@mui/material';
import { ISMenuOption, SMenu } from '@components/SMenu';
import { SButton } from '@components/Buttons';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { NotificationsBox } from './styles';
import { APIOrganizations, APIUser } from '../../services/apis';
import { IRootState } from '../../store';
import { SRating } from '../Shared/SRating';
import SpinnerLDSRipple from '../Shared/SpinnerLDSRipple';
import Icon from '../Shared/Icon';

const HHeaderNotifications = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const evaluationValue = useSelector((state: IRootState) => state.userOrganizationProfile?.user?.evaluationValue);
	const [evaluation, setEvaluation] = useState<boolean | null>();
	const [element, setElement] = useState(null);
	const [anchorFilters, setAnchorFilters] = useState<null | HTMLElement>(null);
	const [anchorSettings, setAnchorSettings] = useState<null | HTMLElement>(null);

	const [open, setOpen] = React.useState(false);

	const [filter, setFilter] = useState('');
	const [timestamp, setTimestamp] = useState(new Date().getTime());
	const [notifications, setNotifications] = useState<IPagination<INotification>>({ docs: [], isLoading: true });
	const menuItem = useRef(null);
	const prevY = useRef(0);

	const getNotifications = async (page: number, filter?: string) => {
		try {
			const response = await APIOrganizations.getNotifications({ page, limit: 4, filter });

			if (response.data) {
				setNotifications((_notifications) => ({
					...response.data,
					docs: response.data?.hasPrevPage
						? _notifications.docs.concat(response.data.docs)
						: response.data.docs,
					isLoading: false,
				}));
			}
		} catch (err) {
			handleAxiosErrors(err);
		}
	};

	const observer = useRef(
		new IntersectionObserver(
			(entries) => {
				const firstEntry = entries[0];
				const { y } = firstEntry.boundingClientRect;
				const more = document.querySelector('#more') as HTMLDivElement;
				if (prevY.current > y && more) {
					// @ts-ignore
					getNotifications(parseInt(more.dataset.next, 10), more.dataset.filter);
				}
				prevY.current = y;
			},
			{ threshold: 1 },
		),
	);

	const [reading, setReading] = useState(undefined);

	useEffect(() => {
		SocketIO.on('new_notification', (notification: INotification) => {
			setNotifications((prevState) => (
				{
					...prevState,
					docs: [notification, ...prevState.docs],
					// @ts-ignore
					naoLidasCount: prevState.naoLidasCount + 1,
				}
			));

			const audio = new Audio('/sounds/pop.mp3');
			audio.play();
		});
	}, [SocketIO]);

	const hideImage = [
		NOTIFICATIONS_ACTIONS.report.COMMENT_CHALLENGE,
		NOTIFICATIONS_ACTIONS.report.COMMENT_BRAINSTORM,
		NOTIFICATIONS_ACTIONS.report.COMMENT_IDEA,
		NOTIFICATIONS_ACTIONS.report.CHALLENGE,
		NOTIFICATIONS_ACTIONS.report.BRAINSTORM,
		NOTIFICATIONS_ACTIONS.report.IDEA,
	];

	const markAllAsRead = async () => {
		try {
			await APIOrganizations.postReadAllNotification();
			getNotifications(1, '');
		} catch (err) {
			handleAxiosErrors(err);
		}
	};

	const organizationEvaluate = async (event: React.SyntheticEvent<Element, Event>, value: number | null) => {
		try {
			// @ts-ignore
			await APIUser.postEvaluationApp(value);
			setEvaluation(true);
			setTimeout(() => {
				dispatch(Creators.USER_ORGANIZATION.getUserOrganization());
			}, 1000);
		} catch (err) {
			handleAxiosErrors(err);
		}
	};

	const markAsRead = async (event: React.MouseEvent<HTMLButtonElement | HTMLButtonElement>
		| React.KeyboardEvent<HTMLButtonElement | HTMLButtonElement>) => {
		const url = event.currentTarget?.dataset?.url;
		try {
			event.stopPropagation();

			if (!event.currentTarget.dataset.id) return;
			// @ts-ignore
			setReading(event.currentTarget.dataset.id);

			const response = await APIOrganizations.postReadNotification(event.currentTarget.dataset.id);
			const index = notifications.docs.findIndex((item) => item._id === response.data._id);

			if (index > -1) {
				notifications.docs.splice(index, 1, { ...notifications.docs[index], lida: true });
				setNotifications((prevProps) => (
					{
						...prevProps,
						docs: notifications.docs,
						// @ts-ignore
						naoLidasCount: notifications.naoLidasCount - 1,
					}
				));
			}
		} catch (err: any) {
			handleAxiosErrors(err);
		} finally {
			setReading(undefined);
			if (url) {
				goToLink(url);
			}
		}
	};

	const goToNotification = (event: React.MouseEvent<HTMLDivElement>
		| React.KeyboardEvent<HTMLDivElement>) => {
		event.preventDefault();

		if (event.currentTarget?.dataset?.read === 'true' && event.currentTarget?.dataset?.url) {
			goToLink(event.currentTarget?.dataset?.url);
			return;
		}
		// @ts-ignore
		markAsRead(event);
	};

	const setURL = (notification: INotification) => {
		switch (notification.acao) {
			case NOTIFICATIONS_ACTIONS.brainstorm.INVITE:
			case NOTIFICATIONS_ACTIONS.brainstorm.PARTICIPATION:
			case NOTIFICATIONS_ACTIONS.brainstorm.COMMENT_LIKE:
			case NOTIFICATIONS_ACTIONS.brainstorm.LIKE:
			case NOTIFICATIONS_ACTIONS.brainstorm.NEW_COMMENT:
			case NOTIFICATIONS_ACTIONS.brainstorm.NEW_COMMENT_ANSWER:
			case NOTIFICATIONS_ACTIONS.brainstorm.UPDATE:
			case NOTIFICATIONS_ACTIONS.brainstorm.TEMPLATE:
			case NOTIFICATIONS_ACTIONS.report.BRAINSTORM:
			case NOTIFICATIONS_ACTIONS.report.COMMENT_BRAINSTORM:
				return `${URLS.BRAINSTORMS}/${notification.objeto._id}`;
			case NOTIFICATIONS_ACTIONS.challenge.COMMENT_LIKE:
			case NOTIFICATIONS_ACTIONS.challenge.LIKE:
			case NOTIFICATIONS_ACTIONS.challenge.NEW_COMMENT:
			case NOTIFICATIONS_ACTIONS.challenge.NEW_COMMENT_ANSWER:
			case NOTIFICATIONS_ACTIONS.challenge.UPDATE:
			case NOTIFICATIONS_ACTIONS.challenge.TEMPLATE:
			case NOTIFICATIONS_ACTIONS.challenge.INVITED_AS_AGENT:
			case NOTIFICATIONS_ACTIONS.challenge.INVITED_AS_MODERATOR:
			case NOTIFICATIONS_ACTIONS.challenge.INVITED_AS_SPONSOR:
			case NOTIFICATIONS_ACTIONS.challenge.PARTICIPATION_AS_AGENT:
			case NOTIFICATIONS_ACTIONS.challenge.PARTICIPATION_AS_MODERATOR:
			case NOTIFICATIONS_ACTIONS.challenge.PARTICIPATION_AS_SPONSOR:
			case NOTIFICATIONS_ACTIONS.report.CHALLENGE:
			case NOTIFICATIONS_ACTIONS.report.COMMENT_CHALLENGE:
				return `${URLS.CHALLENGES}/${notification.objeto._id}`;
			case NOTIFICATIONS_ACTIONS.idea.EVALUATED:
			case NOTIFICATIONS_ACTIONS.idea.EVALUATED_WITHOUT_PLATFORM:
			case NOTIFICATIONS_ACTIONS.idea.SUPPORTED:
			case NOTIFICATIONS_ACTIONS.report.COMMENT_IDEA:
			case NOTIFICATIONS_ACTIONS.report.IDEA:
			case NOTIFICATIONS_ACTIONS.idea.AWARDED:
			case NOTIFICATIONS_ACTIONS.idea.COMMENT:
			case NOTIFICATIONS_ACTIONS.idea.COMMENT_ANSWER:
			case NOTIFICATIONS_ACTIONS.idea.NEW:
				return `${URLS.IDEAS}/${notification.objeto._id}`;
			case NOTIFICATIONS_ACTIONS.team.NEW:
			case NOTIFICATIONS_ACTIONS.team.INVITE:
			case NOTIFICATIONS_ACTIONS.team.REMOVE:
			case NOTIFICATIONS_ACTIONS.team.UPDATE:
				return `${URLS.TEAMS}/${notification.objeto._id}`;
			default:
				break;
		}

		return '# ';
	};

	const setIcon = (notification: INotification) => {
		let paramsProps = {};
		switch (notification.acao) {
			case NOTIFICATIONS_ACTIONS.brainstorm.INVITE:
			case NOTIFICATIONS_ACTIONS.brainstorm.PARTICIPATION:
			case NOTIFICATIONS_ACTIONS.brainstorm.COMMENT_LIKE:
			case NOTIFICATIONS_ACTIONS.brainstorm.LIKE:
			case NOTIFICATIONS_ACTIONS.brainstorm.NEW_COMMENT:
			case NOTIFICATIONS_ACTIONS.brainstorm.NEW_COMMENT_ANSWER:
			case NOTIFICATIONS_ACTIONS.brainstorm.UPDATE:
			case NOTIFICATIONS_ACTIONS.brainstorm.TEMPLATE:
				paramsProps = {
					icon: FLOWS.BRAINSTORM,
					className: FLOWS.BRAINSTORM,
				};
				break;
			case NOTIFICATIONS_ACTIONS.challenge.COMMENT_LIKE:
			case NOTIFICATIONS_ACTIONS.challenge.LIKE:
			case NOTIFICATIONS_ACTIONS.challenge.NEW_COMMENT:
			case NOTIFICATIONS_ACTIONS.challenge.NEW_COMMENT_ANSWER:
			case NOTIFICATIONS_ACTIONS.challenge.UPDATE:
			case NOTIFICATIONS_ACTIONS.challenge.TEMPLATE:
			case NOTIFICATIONS_ACTIONS.challenge.INVITED_AS_AGENT:
			case NOTIFICATIONS_ACTIONS.challenge.INVITED_AS_MODERATOR:
			case NOTIFICATIONS_ACTIONS.challenge.INVITED_AS_SPONSOR:
			case NOTIFICATIONS_ACTIONS.challenge.PARTICIPATION_AS_AGENT:
			case NOTIFICATIONS_ACTIONS.challenge.PARTICIPATION_AS_MODERATOR:
			case NOTIFICATIONS_ACTIONS.challenge.PARTICIPATION_AS_SPONSOR:
				paramsProps = {
					icon: FLOWS.CHALLENGE,
					className: FLOWS.CHALLENGE,
				};
				break;
			case NOTIFICATIONS_ACTIONS.idea.EVALUATED:
			case NOTIFICATIONS_ACTIONS.idea.EVALUATED_WITHOUT_PLATFORM:
				paramsProps = {
					className: `evaluation ${normalizesValue(notification.objeto?.value || 'Pendente')}`,
					icon: notification.icon,
				};
				break;

			case NOTIFICATIONS_ACTIONS.idea.LIKE:
			case NOTIFICATIONS_ACTIONS.idea.INVITE_TO_CO_AUTHOR:
			case NOTIFICATIONS_ACTIONS.idea.NEW_WITHOUT_PLATFORM:
			case NOTIFICATIONS_ACTIONS.idea.COMMENT:
			case NOTIFICATIONS_ACTIONS.idea.COMMENT_ANSWER:
			case NOTIFICATIONS_ACTIONS.idea.COMMENT_LIKE:
			case NOTIFICATIONS_ACTIONS.idea.TEMPLATE:
			case NOTIFICATIONS_ACTIONS.idea.UPDATE:
			case NOTIFICATIONS_ACTIONS.idea.NEW:
				paramsProps = {
					className: FLOWS.IDEA,
					icon: 'idea',
				};
				break;
			case NOTIFICATIONS_ACTIONS.idea.SUPPORTED:
				paramsProps = {
					className: 'feature',
					icon: 'feature',
					color: notification.objeto.color,
					background: createBackground(notification.objeto.color),
				};

				break;
			case NOTIFICATIONS_ACTIONS.report.COMMENT_CHALLENGE:
			case NOTIFICATIONS_ACTIONS.report.COMMENT_BRAINSTORM:
			case NOTIFICATIONS_ACTIONS.report.COMMENT_IDEA:
			case NOTIFICATIONS_ACTIONS.report.CHALLENGE:
			case NOTIFICATIONS_ACTIONS.report.BRAINSTORM:
			case NOTIFICATIONS_ACTIONS.report.IDEA:
				paramsProps = {
					className: 'report',
					icon: 'report',
				};
				break;
			case NOTIFICATIONS_ACTIONS.idea.AWARDED:
				paramsProps = {
					icon: notification.icon,
					className: notification.icon || '',
					color: notification.objeto.color,
					background: createBackground(notification.objeto.color),
				};
				break;
			case NOTIFICATIONS_ACTIONS.team.NEW:
			case NOTIFICATIONS_ACTIONS.team.INVITE:
			case NOTIFICATIONS_ACTIONS.team.REMOVE:
			case NOTIFICATIONS_ACTIONS.team.UPDATE:
				paramsProps = {
					icon: 'team',
					className: 'team',
					color: notification.objeto.color,
					background: createBackground(notification.objeto.color || ''),
				};
				break;
			default:
				paramsProps = {
					icon: notification.icon,
					className: notification.icon || '',
				};
				break;
		}

		return (
			<div className="notification-icon">
				<div className="background-icon" />
				<DynamicCircle
					size="auto"
					{...paramsProps}
				/>
			</div>

		);
	};

	// console.log('notifications', notifications);

	const setContent = (notification: INotification) => {
		if (!notification.acao || !notification.objeto) return null;

		switch (notification.acao) {
			case NOTIFICATIONS_ACTIONS.brainstorm.INVITE:
			case NOTIFICATIONS_ACTIONS.brainstorm.COMMENT_LIKE:
			case NOTIFICATIONS_ACTIONS.brainstorm.LIKE:
			case NOTIFICATIONS_ACTIONS.brainstorm.NEW_COMMENT:
			case NOTIFICATIONS_ACTIONS.brainstorm.NEW_COMMENT_ANSWER:
			case NOTIFICATIONS_ACTIONS.brainstorm.UPDATE:
			case NOTIFICATIONS_ACTIONS.brainstorm.TEMPLATE:
			case NOTIFICATIONS_ACTIONS.challenge.COMMENT_LIKE:
			case NOTIFICATIONS_ACTIONS.challenge.LIKE:
			case NOTIFICATIONS_ACTIONS.challenge.NEW_COMMENT:
			case NOTIFICATIONS_ACTIONS.challenge.NEW_COMMENT_ANSWER:
			case NOTIFICATIONS_ACTIONS.challenge.UPDATE:
			case NOTIFICATIONS_ACTIONS.challenge.TEMPLATE:
			case NOTIFICATIONS_ACTIONS.challenge.INVITED_AS_AGENT:
			case NOTIFICATIONS_ACTIONS.challenge.INVITED_AS_MODERATOR:
			case NOTIFICATIONS_ACTIONS.challenge.INVITED_AS_SPONSOR:
			case NOTIFICATIONS_ACTIONS.challenge.PARTICIPATION_AS_AGENT:
			case NOTIFICATIONS_ACTIONS.challenge.PARTICIPATION_AS_MODERATOR:
			case NOTIFICATIONS_ACTIONS.challenge.PARTICIPATION_AS_SPONSOR:
			case NOTIFICATIONS_ACTIONS.idea.NEW:
				return (
					<p
						className="notification-title"
						dangerouslySetInnerHTML={{
							__html: t(notification.acao, {
								author: notification?.ator?.user?._nome || notification?.ator?.user?.nome,
								name: overflowText(notification.objeto.name),
							}),
						}}
					/>
				);
			case NOTIFICATIONS_ACTIONS.idea.EVALUATED:
			case NOTIFICATIONS_ACTIONS.idea.EVALUATED_WITHOUT_PLATFORM:
				return (
					<p
						className="notification-title"
						dangerouslySetInnerHTML={{
							__html: t(notification.acao, {
								author: notification.ator?.user?._nome || notification.ator?.user?.nome,
								name: overflowText(notification.objeto.name),
								item: t(notification.objeto.type),
								value: t(`evaluation.values.${normalizesValue(notification?.objeto?.value || '')}`),
							}),
						}}
					/>
				);

			case NOTIFICATIONS_ACTIONS.idea.AWARDED:
			case NOTIFICATIONS_ACTIONS.idea.SUPPORTED:
				return (
					<p
						className="notification-title"
						dangerouslySetInnerHTML={{
							__html: t(notification.acao, {
								author: notification.ator?.user?._nome || notification.ator?.user?.nome,
								name: overflowText(notification.objeto.name),
								item: overflowText(notification.objeto.item),
							}),
						}}
					/>
				);
			case NOTIFICATIONS_ACTIONS.report.COMMENT_CHALLENGE:
			case NOTIFICATIONS_ACTIONS.report.COMMENT_BRAINSTORM:
			case NOTIFICATIONS_ACTIONS.report.COMMENT_IDEA:
			case NOTIFICATIONS_ACTIONS.report.CHALLENGE:
			case NOTIFICATIONS_ACTIONS.report.BRAINSTORM:
			case NOTIFICATIONS_ACTIONS.report.IDEA:
				return (
					<p
						className="notification-title"
						dangerouslySetInnerHTML={{
							__html: t(notification.acao, {
								name: overflowText(notification.objeto.name),
								value: t(`report.${notification.objeto.motivo}`),
							}),
						}}
					/>
				);
			default:
				return (
					<p
						className="notification-title"
						dangerouslySetInnerHTML={{
							__html: `${t(notification.acao, {
								author: notification.ator?.user?._nome || notification.ator?.user?.nome,
								name: notification.objeto.type
									? `${t(`global.${notification.objeto.type.toLowerCase()}`)} ${overflowText(notification.objeto.name)}`
									: overflowText(notification.objeto.name),
							})} ${notification.in ? t('notifications.idea.on', { platform: notification.in }) : ''}`,
						}}
					/>
				);
		}
	};

	const updateList = (value: string) => {
		setFilter(value);
		getNotifications(1, value);
	};

	const options: ISMenuOption[] = [
		{
			value: '',
			label: t('global.allUpdates'),
			onClick: () => updateList(''),
		},
		{
			value: 'unread',
			label: t('global.unread'),
			onClick: () => updateList('unread'),
		},
		{
			value: 'read',
			label: t('global.read'),
			onClick: () => updateList('read'),
		},
	];

	let _body;
	let _more;
	if (notifications.isLoading) {
		_body = (
			<SpinnerLDSRipple />
		);
	} else if (notifications.docs.length < 1) {
		_body = (
			// @ts-ignore
			<div className="" ref={setElement} data-next={notifications.nextPage} data-filter={filter}>
				<EmptyState
					type="notifications"
					sx={{ padding: 3 }}
				/>
			</div>

		);
	} else {
		_body = notifications.docs.map((notification, index) => (
			<li
				key={`notification-${notification._id}-${index}`}
				className={`${notification.lida ? 'read' : 'unread'} 
				${reading === notification._id ? 'loading' : ''}`}

			>
				<div
					className={`notification ${notification.lida ? '' : 'unread'}`}
					data-id={notification._id}
					data-url={notification.objeto_url}
					data-read={notification.lida}
					aria-hidden
					onClick={goToNotification}
				>
					<div className="">
						{hideImage.includes(notification.acao) ? (
							<DynamicCircle
								initial={notification.objeto.name}
								background="var(--primary)"
								size="x-large"
							/>
						) : (
							<UserAvatar profile={notification.ator} size="x-large" />
						)}

						{notification && setIcon(notification)}
					</div>
					<div className="notification-info">
						{notification && setContent(notification)}
						<div className="notification-time">
							{elapsedTimeSinceCreation(notification.createdAt)}
						</div>
					</div>
					<div className="notification-status">

						{!notification.lida
							&& (
								<Tooltip classes={{ popper: 'secondary' }} placement="top" title={t('notifications.markAsRead')} arrow>
									<IconButton
										onClick={markAsRead}
										data-id={notification._id}
									>
										<div className={`circle  ${notification.lida ? '' : 'unread'}`} />
									</IconButton>
								</Tooltip>
							)}
					</div>
				</div>
			</li>
		));
		if (!notifications.hasNextPage) {
			_more = <div />;
		} else {
			_more = (
				// @ts-ignore
				<div className="text-center m-3 more" id="more" ref={setElement} data-next={notifications.nextPage} data-filter={filter}>
					<SpinnerLDSRipple />
				</div>
			);
		}
	}

	useEffect(() => {
		const currentElement = element;
		const currentObserver = observer.current;

		if (currentElement) {
			currentObserver.observe(currentElement);
		}
		return () => {
			if (currentElement) {
				currentObserver.unobserve(currentElement);
			}
		};
	}, [element]);

	useEffect(() => {
		getNotifications(1, '');
	}, []);

	useEffect(() => {
		const listHeight = document.querySelector('.notification-list') as HTMLDivElement;
		const containerHeight = document.querySelector('.notifications-container') as HTMLDivElement;

		if ((listHeight?.offsetHeight < containerHeight?.offsetHeight) && notifications.nextPage) {
			// @ts-ignore
			getNotifications(parseInt(notifications.nextPage, 10), filter);
		}
	});

	const openFilters = Boolean(anchorFilters);

	return (
		<ClickAwayListener onClickAway={() => setOpen(false)}>
			<div
				ref={menuItem}
				id="menu-notifications"
			>
				<IconButton
					onClick={() => setOpen(true)}
					size="medium"
					color="default"
					sx={{ color: 'var(--charcoal-color)', height: '40px', width: '40px' }}
				>
					<Box
						className="circle notifications unread"
						style={{
							// @ts-ignore
							visibility: notifications.naoLidasCount > 0 ? 'visible' : 'hidden',
						}}
					/>

					<Icon icon="notificacao" />
				</IconButton>
				<SwipeableDrawer
					anchor="right"
					open={open}
					onClose={() => setOpen(false)}
					onOpen={() => setOpen(true)}
					PaperProps={{
						sx: {
							width: '384px',
						},
					}}
					variant="persistent"
				>
					<NotificationsBox id="menu_notifications">
						<div className="notifications-header">
							<SMenu
								id="notifications-filters-menu"
								open={openFilters}
								anchorEl={anchorFilters}
								setAnchorEl={setAnchorFilters}
								options={options}
								sx={{
									display: 'flex',
									justifyContent: 'space-between',
									'.MuiButton-endIcon': {
										transform: 'scale(1)',
										svg: {
											transform: openFilters ? 'rotate(180deg)' : 'rotate(0deg);',
										},
									},
								}}
							>
								<SButton color="secondary" endIcon={<MdKeyboardArrowDown />} className={`arrow ${open ? 'down' : 'up'}`}>
									{options?.find((item) => item.value === filter)?.label}
								</SButton>
							</SMenu>
							<SMenu
								id="notifications-settings-menu"
								open={Boolean(anchorSettings)}
								anchorEl={anchorSettings}
								setAnchorEl={setAnchorSettings}
								options={[{
									label: t('notifications.markAllAsRead'),
									value: 'all',
									icon: <Icon icon="fas fa-eye" />,
									onClick: markAllAsRead,
									close: false,
								},
								{
									label: t('global.settings'),
									icon: <Icon icon="fa fa-cog" />,
									onClick: () => navigate(URLS.SETTINGS),
								},
								]}
							>
								<IconButton color="primary" size="small">
									<Icon icon="ellipsis" />
								</IconButton>
							</SMenu>
						</div>
						<div className="notifications-container">
							<ul className="notification-list">
								<SRating
									title={t('notifications.howYourExperience')}
									feedbackMessage={`${t('global.thankYou')}!`}
									// @ts-ignore
									onChange={organizationEvaluate}
									value={evaluationValue}
								/>
								{_body}
								{_more}
							</ul>
						</div>
					</NotificationsBox>
				</SwipeableDrawer>
			</div>
		</ClickAwayListener>
	);
};

const HeaderNotifications = () => {
	const { userOrganizationProfile } = useSelector((state: IRootState) => state);

	const _fallback = (

		<div id="menu-notifications" className="d-flex" style={{ cursor: 'pointer' }}>
			<div
				className="circle notifications unread"
				style={{
					// visibility: naoLidasCount > 0 ? 'visible' : 'hidden',
				}}
			/>
			<Icon icon="notificacao" className="fa-1_5x" />
		</div>
	);

	return (
		<Suspense fallback={_fallback}>
			{!userOrganizationProfile || isEmpty(userOrganizationProfile?.user)
				? _fallback
				: <HHeaderNotifications />}
		</Suspense>
	);
};

export default HeaderNotifications;
