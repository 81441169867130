import React, { Suspense } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import {
	Dialog, DialogActions, DialogContent, DialogTitle,
} from '@mui/material';

import { SButton } from '@components/Buttons';
import { DialogBox } from '@modules/Modal/Components';

import { GenericGrid } from '@components/Grids/GenericGrid';
import Icon from '../Shared/Icon';

interface IRightButtonParams<match = any> {
	event: React.MouseEvent | React.KeyboardEvent,
	match?: match,
}

export interface IFeedbackProps<match = any> {
	id?: string
	open?: boolean
	rightButtonText?: string
	leftButtonText?: string
	rightButtonIcon?: string | React.ReactNode
	rightButtonDisabled?: boolean // state
	hideRightButton?: boolean
	hideLeftButton?: boolean
	onRightButton?: (params: IRightButtonParams<match>) => void
	onLeftButton?: (event: React.MouseEvent | React.KeyboardEvent) => void
	onRequestClose?: (event: React.MouseEvent | React.KeyboardEvent) => void;
	children?: React.ReactNode;
	title?: string;
	className?: string
	text?: string
	color?: 'primary' | 'success' | 'warning' | 'error'
	error?: boolean
	isLoading?: boolean
	match?: match
	size?: 'x-sm' | 'sm' | 'md'
	/* Boolean indicating if pressing the esc key should close the modal */
}

declare type TProps = IFeedbackProps & WithTranslation

const MModalFeedback = (props: TProps) => {
	const {
		t, rightButtonText, rightButtonIcon, onRightButton, onRequestClose, open, id = '001', color = 'success',
		onLeftButton, className, children, title, leftButtonText, error, isLoading, text,
		hideRightButton, hideLeftButton, match, size = 'x-sm', rightButtonDisabled,
	} = props;

	const onLeftButtonHandler = (event: React.MouseEvent | React.KeyboardEvent) => {
		event.stopPropagation();
		if (typeof onLeftButton === 'function') {
			onLeftButton(event);
			return;
		}

		if (typeof onRequestClose === 'function') {
			onRequestClose(event);
		}
	};

	const onRightButtonHandler = async (event: React.MouseEvent | React.KeyboardEvent) => {
		event.stopPropagation();
		if (typeof onRightButton === 'function') {
			await onRightButton({ event, match });
		}
	};

	const onChildrenRender = () => {
		if (children) {
			return children;
		}
		if (text) {
			return <p className="" dangerouslySetInnerHTML={{ __html: text }} />;
		}

		return null;
	};

	return (
		<Dialog
			id={`modal-feedback-${id}`}
			keepMounted
			open={open || false}
			maxWidth="xs"
			fullWidth
			className={`s-modal-xs modal__feedback ${className || ''}`}
			onClose={onRequestClose}
		>
			<GenericGrid className="s-grid-modal">
				<DialogContent className={`${size}`}>
					<DialogBox className="s-dialog-title">
						<DialogTitle variant="h6" dangerouslySetInnerHTML={{ __html: title || '' }} />
					</DialogBox>
					<DialogBox className="s-dialog-box">
						{onChildrenRender()}
						<DialogActions className="s-dialog-actions">
							{!hideLeftButton && (
								<SButton
									type="button"
									color="secondary"
									onClick={onLeftButtonHandler}
								>
									{leftButtonText || t('global.cancel')}
								</SButton>
							)}

							{!hideRightButton && typeof onRightButton === 'function' && (
								<SButton
									id="feedback-right-button"
									isLoading={isLoading}
									type="button"
									color={color}
									onClick={async (event: any) => onRightButtonHandler(event)}
									sx={{ marginLeft: '10px' }}
									disabled={(typeof match === 'string' && (!match || match?.length < 1)) || rightButtonDisabled}
									error={error}
								>
									{typeof rightButtonIcon === 'string'
										? <Icon icon={rightButtonIcon} />
										: rightButtonIcon}
									{rightButtonText || t('global.confirm')}
								</SButton>
							)}
						</DialogActions>
					</DialogBox>
				</DialogContent>
			</GenericGrid>
		</Dialog>
	);
};

const Translation = withTranslation('common')(MModalFeedback);

const ModalFeedback = (props: IFeedbackProps) => (
	<Suspense fallback={<div />}>
		<Translation {...props} />
	</Suspense>
);

export default ModalFeedback;
