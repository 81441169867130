// @ts-nocheck
import React, { Suspense, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import SpinnerLDSRipple from '@components/Shared/SpinnerLDSRipple';
import {
	SInputLabel, SRadio,
} from '@components/DynamicForm/ComponentsCore';
import { STextField } from '@forms/Components';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from 'src/store';
import APIOrganizations from '@services/apis/organizations';
import { handleAxiosErrors } from '@services/auth';
import { Creators } from '@actions';
import { SBox } from '@components/Background/SBox';

interface IOwnProps { }

type TProps = IOwnProps & WithTranslation;

const _Security = ({ t }: TProps) => {
	const organization = useSelector((state: IRootState) => state.userOrganizationProfile?.organization);
	const dispatch = useDispatch();
	const [permissions, setPermissions] = useState(organization?.permissions);

	interface IPermissionHandler { key: string, multiKey?: string, value: string }
	const onPermissionHandler = async ({ key, multiKey, value }: IPermissionHandler) => {
		try {
			const response = await APIOrganizations.patchPermissions({ key, multiKey, value });
			dispatch(Creators.USER_ORGANIZATION.handleOrganization(response.data.permissions));
		} catch (err) {
			handleAxiosErrors(err);
		}
	};

	return (
		<SBox id="accountPermissions" loading={!organization || !organization?.permissions}>
			<ul className="">
				{permissions && Object.keys(permissions)
					.map((key, index) => {
						if (permissions[key].type === 'single') {
							return (
								<li className="d-flex flex-column" key={index}>
									<h5>{t(permissions[key].label)}</h5>
									{/* @ts-ignore */}
									{permissions[key].options.map((item, i) => {
										const isChecked = permissions[key].value === item.name;
										return (
											<div key={i} className="security-form-group">
												<SRadio
													name={item.name}
													id={i}
													value={item.name}
													checked={isChecked}
													onChange={(e) => {
														setPermissions({
															...permissions,
															[key]: {
																...permissions[key],
																value: e.target.value,
															},
														});
														onPermissionHandler({ key, value: e.target.value });
													}}
													label={(
														<div aria-label="radio">
															<span style={{ display: 'block', color: 'black' }}>
																{t(item.label)}
															</span>
															<span style={{ display: 'block', fontWeight: 'lighter' }}>
																{t(item.secondLabel)}
															</span>
														</div>
													)}
												/>

												{item.name === 'discoverableOpen'
															&& (
																<div className="form-group d-flex mt-15px">
																	<STextField
																		type="text"
																		value=""
																		className="w-25"
																		placeholder={t('page_settings.tabcontent.permissions.typeEmailDomain')}
																	/>
																	<button className="permissions-button" type="button">Add</button>
																</div>
															)}
											</div>

										);
									})}
								</li>
							);
						}
						return (
							<li className="d-flex flex-column" key={index}>
								<h5>{t(permissions[key].label)}</h5>
								{Object.keys(permissions[key]).map((multiKey, j) => (
									<div key={j}>
										{typeof permissions[key][multiKey] === 'object'
											? (
												<>
													<SInputLabel className="mb-20px">{t(permissions[key][multiKey].label)}</SInputLabel>
													{/* @ts-ignore */}
													{permissions[key][multiKey].options.map((opt, k) => {
														const isChecked = permissions[key][multiKey].value === opt.name;
														return (
															<div key={k} className="form-group">
																<SRadio
																	name={opt.name}
																	id={k}
																	value={opt.name}
																	label={t(opt.label)}
																	checked={isChecked}
																	onChange={(e) => {
																		setPermissions({
																			...permissions,
																			[key]: {
																				...permissions[key],
																				[multiKey]: {
																					...permissions[key][multiKey],
																					value: e.target.value,
																				},
																			},
																		});
																		onPermissionHandler({ key, multiKey, value: e.target.value });
																	}}
																/>
															</div>
														);
													})}
												</>
											) : ''}
									</div>
								))}
							</li>
						);
					})}
			</ul>
		</SBox>
	);
};

const Translation = withTranslation('common')(_Security);

const Security = (props: IOwnProps) => (
	<Suspense fallback={<SpinnerLDSRipple />}>
		<Translation {...props} />
	</Suspense>
);

export default Security;
