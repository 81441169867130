import { ModalIdeasRouter } from './ModalIdeasRouter';
import { ModalIsolatedRouter } from './ModalIsolatedRouter';
import { ModalPlatformsRouter } from './ModalPlatformsRouter';

const routes = {
	...ModalIsolatedRouter,
	...ModalPlatformsRouter,
	...ModalIdeasRouter,
};

export default routes;
