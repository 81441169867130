import React from 'react';
import { Skeleton, Box, Grid, GridProps } from '@mui/material';

interface IOwnProps extends GridProps {
	readonly className?: string
	readonly limit?: number
	GridItemProps?: GridProps
}

const CardLoader = (props: IOwnProps) => {
	const {
		className, GridItemProps,
		limit = screen.width <= 1536 ? 6 : 8,
	} = props;
	const color = '#f5f5f5';

	const generatorArray = () => {
		const array = [];
		for (let i = 0; i < limit; i += 1) {
			array.push(i);
		}
		return array;
	};

	return (
		<Grid container spacing={2}>
			{generatorArray().map((el) => (
				<Grid item xs={12} className={`item-loading-${el}`} key={el} {...GridItemProps}>
					<Box className="box-item card-box" style={{ backgroundColor: '#ffff' }}>
						<Skeleton className="box-photo" variant="rectangular" />
						<Box className="box-info" sx={{ '.MuiBox-root': { padding: '0.5rem' } }}>
							<Box className="footer" sx={{ display: 'flex', justifyContent: 'space-between' }}>
								<Skeleton variant="text" width="60%" />
								<Skeleton variant="text" width="20%" />
							</Box>
							<Box className="pt-0" sx={{ display: 'flex', '.MuiSkeleton-root': { marginRight: '0.05rem' } }}>
								<Skeleton variant="circular" width={30} height={30} />
								<Skeleton variant="circular" width={30} height={30} />
								<Skeleton variant="circular" width={30} height={30} />
								<Skeleton variant="circular" width={30} height={30} />
							</Box>
							<Box className="footer" sx={{ display: 'flex', justifyContent: 'space-between' }}>
								<Skeleton variant="text" width="50%" />
								<Skeleton variant="text" width="30%" />
							</Box>
						</Box>
					</Box>
				</Grid>
			))}
		</Grid>
	);
};

export default CardLoader;
