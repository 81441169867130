import i18n from '@services/i18n';
import { LanguageMap } from '@utils/index';

export const getArticleDate = (date: { year?: string, month?: string, day?: string }): string => {
	let h = '';

	if (date.day && date.month && date.year) {
		if (i18n.language === 'pt-BR') {
			h += date.day;
			h += `-${date.month}`;
			h += `-${date.year}`;
		}

		if (i18n.language === 'en-US') {
			h += `${date.year}`;
			h += `-${date.month}`;
			h += `-${date.day}`;
		}
		return h;
	}

	if (date.year) {
		h += date.year;
	}

	return h;
};

export const nlpCategories = () => [
	{
		value: 'all',
		label: i18n.t('global.all'),
	},
	{
		value: 'article',
		label: `${i18n.t('trends.contentType.article')}s`,
	},
	{
		value: 'patent',
		label: `${i18n.t('trends.contentType.patent')}s`,
	},
	{
		value: 'news',
		label: `${i18n.t('trends.contentType.new')}s`,
	},
	// {
	//   value: 'publications',
	//   label: 'Publications'
	// }
];

export const getLanguage = (language: 'en' | 'pt') => LanguageMap[language] || language;
