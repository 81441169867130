// @ts-nocheck
import React, { useState } from 'react';
import {
	Box,
	Paper, Rating, Typography,
} from '@mui/material';

interface IOwnProps {
	title?: string
	feedbackMessage?: string
	value?: number | null | undefined
	onChange?: (event: React.SyntheticEvent<Element, Event>, value: number | null) => void
}

const SRating = ({
	title, onChange, feedbackMessage = 'Thank you for your evaluation', value,
}: IOwnProps) => {
	const [isOpen, setIsOpen] = useState(!value);

	if (!isOpen) {
		return null;
	}

	return (
		<Paper
			sx={{
				display: value ? 'none' : 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				alignItems: 'center',
				opacity: value ? 0 : 1,
				marginBottom: 3,
				position: 'relative',
			}}
			elevation={3}
		>

			{/* <IconButton
				onClick={() => setIsOpen(false)}
				sx={{
					position: 'absolute',
					top: '8px',
					right: '8px',
				}}
			>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="14.707"
					height="14.707"
					preserveAspectRatio="none"
					viewBox="0 0 30 30"
				>
					<g stroke="#000">
						<path d="M.354.354l30 30M.354 30.354l30-30" />
					</g>
				</svg>
			</IconButton> */}

			{title && <Typography variant="h6" fontWeight="700" marginTop={2}>{title}</Typography>}

			<Box
				sx={{
					width: '100%',
					display: 'flex',
					justifyContent: 'center',
				}}
			>
				<Rating
					name="simple-controlled"
					value={value}
					size="large"
					onChange={onChange}
					sx={{
						marginY: 2,
						label: {
							fontSize: '2.5rem',
						},
					}}
				/>
			</Box>
		</Paper>
	);
};

export { SRating };
