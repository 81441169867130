// @ts-nocheck
import { axiosInstance } from '@services/AxiosInstance';
import { ICriteria, IEvaluation } from 'src/@types/IIdea';
import { IPaginatedRequest, ITrendsList } from 'src/@types/IShared';
import { getQueryParams } from './utils';

const APIIdeas = {
	getTemplates(params: IPaginatedRequest & {ids?: Array<string>}) {
		const queryParams = getQueryParams(params || {});
		return axiosInstance.get(`/templates/idea${queryParams}`);
	},
	getFavorites(params: IPaginatedRequest) {
		const queryParams = getQueryParams(params);
		return axiosInstance.get(`/favorites/ideas${queryParams}`);
	},
	getDrafts(params) {
		const queryParams = getQueryParams(params || {});
		return axiosInstance.get(`/drafts/idea${queryParams}`);
	},
	getList(params: IPaginatedRequest) {
		const queryParams = getQueryParams(params || {});
		return axiosInstance.get(`/ideas${queryParams}`);
	},
	postCreateTemplate(newTemplate) {
		return axiosInstance.post('/templates/idea', newTemplate);
	},
	postCreateIdea(idea: {nome: string, platformId?: string, templateId?: string}) {
		return axiosInstance.post('/ideas', idea);
	},
	patchIdea(ideaId: string, field) {
		return axiosInstance.patch(`/ideas/${ideaId}`, field);
	},
	putPublish(ideaId: string, field) {
		return axiosInstance.put(`/ideas/${ideaId}/publish`, field);
	},
	postAttachments(ideaId: string, formData) {
		return axiosInstance.post(`/ideas/${ideaId}/upload`, formData);
	},
	postCoverUpload(ideaId: string, formData) {
		return axiosInstance.post(`/ideas/${ideaId}/upload_cover`, formData);
	},
	getIdea(ideaId: string) {
		return axiosInstance.get(`/ideas/${ideaId}`);
	},
	getSupportersByIdea(ideaId: string) {
		return axiosInstance.get(`/ideas/supporters/${ideaId}`);
	},
	postEvaluationByCriteria(ideaId: string, evaluation: IEvaluation) {
		return axiosInstance.post(`/ideas/${ideaId}/evaluate`, evaluation);
	},
	getFormByCriteria(ideaId: string, criteria: string) {
		return axiosInstance.get(`/ideas/${ideaId}/evaluations/${criteria}/`);
	},
	getTemplateCriteria(criteria: ICriteria, type) {
		return axiosInstance.get(`/templates/evaluation/${criteria}?type=${type}`);
	},
	postApproveEvaluation(ideaId: string, evaluation: IEvaluation) {
		return axiosInstance.post(`/ideas/${ideaId}/status`, evaluation);
	},
	postIdeaToTemplate(newTemplate) {
		return axiosInstance.post('/ideas_templates', newTemplate);
	},
	postIdeaDuplicate(ideaId: string) {
		return axiosInstance.post(`/ideas/${ideaId}/duplicate`, {});
	},
	postTrendsIdea(ideaId: string) {
		return axiosInstance.post(`/ideas/${ideaId}/trends`, { test: true });
	},
	postReward(ideaId: string, badgeId: string) {
		return axiosInstance.post(`/ideas/${ideaId}/reward`, { badgeId });
	},
	postAwardIdea(ideaId: string, rewardId: string) {
		return axiosInstance.post(`/ideas/${ideaId}/depreciate`, { rewardId });
	},
	putChangeTemplateIdea(ideaId: string, templateId) {
		return axiosInstance.put(`/ideas/${ideaId}/change_template`, { template: templateId });
	},
	getConnections(ideaId: string, params: IPaginatedRequest) {
		const queryParams = getQueryParams(params || {});
		return axiosInstance.get(`/ideas/${ideaId}/connections${queryParams}`);
	},
	deleteIdea(ideaId: string) {
		return axiosInstance.delete(`/ideas/${ideaId}`);
	},
	getCoauthors(ideaId: string, params: IPaginatedRequest) {
		const queryParams = getQueryParams(params || {});
		return axiosInstance.get(`/ideas/${ideaId}/coauthors${queryParams}`);
	},
	getIdeasToConnect(ideaId: string, params: IPaginatedRequest) {
		const queryParams = getQueryParams(params);
		return axiosInstance.get(`/ideas/${ideaId}/connect${queryParams}`);
	},
	getEvaluatorsByIdea(ideaId: string, params: IPaginatedRequest) {
		const queryParams = getQueryParams(params);
		return axiosInstance.get(`/ideas/${ideaId}/evaluations${queryParams}`);
	},
	postArchiveIdea(ideaId: string) {
		return axiosInstance.post(`/ideas/${ideaId}/archive`, {});
	},
	postUnarchiveIdea(ideaId: string) {
		return axiosInstance.post(`/ideas/${ideaId}/unarchive`, {});
	},
	getIdeaTrendsList(ideaId: string, limit: number, sort?: string, contentType?: string): Promise<ITrendsList> {
		let filter = '';
		if (contentType && contentType?.length > 0 && contentType !== 'all') filter += `&contentType=${contentType}`;
		if (sort && sort?.length > 0) filter += `&sort=${sort}`;
		return axiosInstance.get(`/ideas/${ideaId}/trends?limit=${limit}${filter}`);
	},
	getIdeaTrendsNLPs(ideaId: string) {
		return axiosInstance.get(`/ideas/${ideaId}/trends/nlps`);
	},
	postNotificate(ideaId: string) {
		return axiosInstance.post(`/ideas/${ideaId}/notificate`, {});
	},
	getDashboard(ideaId: string) {
		return axiosInstance.get(`/ideas/${ideaId}/dashboard`);
	},
	putPlatform(ideaId: string, platformId: string) {
		return axiosInstance.put(`/ideas/${ideaId}/change_platform`, { platform: platformId });
	},
	postDisconnect(ideaId: string, selectedIdeaId: string) {
		return axiosInstance.post(`/ideas/${ideaId}/disconnect`, { ideiaId: selectedIdeaId });
	},
	postConnect(ideaId: string, selectedIdeaId: string) {
		return axiosInstance.post(`/ideas/${ideaId}/connect`, { ideiaId: selectedIdeaId });
	},
};

export default APIIdeas;
