// @ts-nocheck
import {
	put,
	call,
	takeLatest,
	select,
	delay,
} from 'redux-saga/effects';
import i18next from 'i18next';
import { FLOWS } from '@constants/GLOBAL';
import APICanvas from '@services/apis/canvas';
import { handleAxiosErrors } from '@services/auth';
import { AxiosResponse } from 'axios';
import { SToast } from '@modules/SToast';
import { Creators, Types } from '../actionsTypes';

function* getCanvas(action) {
	try {
		const {
			ideaId, platformId, onSuccess, flow,
		} = action.p;
		let response: AxiosResponse = {};
		if ([FLOWS.BRAINSTORM, FLOWS.CHALLENGE].includes(flow)) {
			response = yield call(APICanvas.getCanvasByPlatform, platformId);
		}
		if ([FLOWS.IDEA].includes(flow)) {
			response = yield call(APICanvas.getCanvasByIdea, ideaId);
		}
		if (typeof onSuccess === 'function') {
			yield call(onSuccess, { docs: response.data });
		}
	} catch (err) {
		yield call(handleAxiosErrors, err);
	}
}

function* insertCanvas(action) {
	const {
		ideaId, templateId, platformId, onSuccess, callback,
	} = action.params;
	try {
		const { flow } = yield select((state) => state.modal);

		let response: AxiosResponse = {};

		if ([FLOWS.IDEA].includes(flow)) {
			response = yield call(APICanvas.postCanvasIdea, ideaId, templateId);
		}
		if ([FLOWS.BRAINSTORM, FLOWS.CHALLENGE].includes(flow)) {
			response = yield call(APICanvas.postCanvasPlatform, platformId, templateId);
		}

		if (typeof onSuccess === 'function') {
			yield call(onSuccess, response.data);
		}

		yield call(SToast.success, i18next.t('messages.success.canvas.added'));
	} catch (err) {
		yield call(handleAxiosErrors, err);
	} finally {
		if (typeof callback === 'function') {
			yield call(callback);
		}
	}
}

function* deleteCanvas(action) {
	const { canvasId, callback, onSuccess } = action.params;
	try {
		yield call(APICanvas.deleteCanvas, canvasId);

		if (typeof onSuccess === 'function') {
			yield call(onSuccess);
		}

		yield put(Creators.MODAL.backModal());
		yield call(SToast.success, i18next.t('messages.success.canvas.removed'));
	} catch (err) {
		yield call(handleAxiosErrors, err);
		yield call(SToast.error, i18next.t('messages.error.canvas.remove'));
	} finally {
		if (typeof callback === 'function') {
			yield call(callback);
		}
	}
}

function* handleCanvas(action) {
	const {
		canvasId, callback, name, description,
	} = action.params;
	try {
		yield delay(1000);
		yield call(APICanvas.patchCanvas, canvasId, name, description);
	} catch (err) {
		yield call(handleAxiosErrors, err);
	} finally {
		if (typeof callback === 'function') {
			yield call(callback);
		}
	}
}

function* restoreCanvas(action) {
	try {
		const response: AxiosResponse = yield call(APICanvas.putRestore, action.p.canvaId);

		yield put(Creators.CANVAS.successCanvas(response.data));
		yield call(SToast.success, i18next.t('messages.success.template.reseted'));
	} catch (err) {
		yield call(handleAxiosErrors, err);
	} finally {
		if (typeof action.p.callback === 'function') {
			yield call(action.p.callback);
		}
	}
}

export default function* () {
	yield takeLatest(Types.CANVAS.GET_CANVAS, getCanvas);
	yield takeLatest(Types.CANVAS.INSERT_CANVAS, insertCanvas);
	yield takeLatest(Types.CANVAS.DELETE_CANVAS, deleteCanvas);
	yield takeLatest(Types.CANVAS.HANDLE_CANVAS, handleCanvas);
	yield takeLatest(Types.CANVAS.RESTORE_CANVAS, restoreCanvas);
}
