/// @ts-nocheck
/* eslint-disable import/no-cycle */
/* eslint-disable no-underscore-dangle */
import { useTour } from '@reactour/tour';
import { useSelector, useDispatch } from 'react-redux';
import { WithTranslation, withTranslation } from 'react-i18next';
import {
	useState,
	Suspense,
	useEffect,
	useCallback,
} from 'react';
import {
	Box,
	DialogTitle,
	DialogContent,
	AutocompleteChangeReason,
	AutocompleteChangeDetails,
	DialogActions,
	InputLabel,
} from '@mui/material';

import URLS from '@constants/URLS';
import { Creators } from '@actions';
import { IRootState } from 'src/store';
import Icon from '@components/Shared/Icon';
import { SButton } from '@components/Buttons';
import { STextField } from '@forms/Components';
import { IPlatform } from 'src/@types/IPlatform';
import type { IModal } from '@modules/Modal/types';
import { SBox } from '@components/Background/SBox';
import { handleAxiosErrors } from '@services/auth';
import CardGrid from '@components/Grids/CardGrid';
import ModalHeader from '@modules/Modal/ModalHeader';
import { FLOWS, maxLength } from '@constants/GLOBAL';
import { IUserOrganization } from 'src/@types/IUser';
import { goToLink, isEmpty } from '@utils/ReactUtils';
import { BCover } from '@components/Background/BCover';
import { BForms } from '@components/Background/BForms';
import { APIGlobal, APIPlatforms } from '@services/apis';
import { BCanvas } from '@components/Background/BCanvas';
import { debounce, getTextFromHTML } from '@utils/helpers';
import { BFeatures } from '@components/Background/BFeatures';
import { PlatformCard } from '@components/Cards/PlatformCard';
import { UploadFiles } from '@components/Background/UploadFiles';
import { IPaginatedRequest, IPagination } from 'src/@types/IShared';
import { DialogBox } from '@modules/Modal/Components';
import { TemplateDetails } from '@components/Background/TemplateDetails';
import { SAutocomplete } from '@components/DynamicForm/Components/SSelect/SAutocomplete';
import ModalFeedback, { IFeedbackProps } from '@components/Modals/ModalFeedback';
import {
	BuildTour,
	STour,
} from '@components/STour';
import {
	Form,
	SRadio,
	SFormGroup,
	SInputLabel,
} from '@components/DynamicForm/ComponentsCore';
import {
	Tag,
	Tags,
	UserTag,
	IUserOption,
	getUserOptions,
} from '@components/DynamicForm/Components/SSelect/Components';
import { SToast } from '@modules/SToast';
import { W_GRID } from '@components/Grids/GenericGrid';
import _ from 'lodash';
import ModalCover from '../Shared/ModalCover';
import RichTextEditor from '../../RichTextEditor';
import { HelpButton } from '../../Buttons/HelpButton';

declare type TProps = IModal & WithTranslation;

const MModalIdea = (props: TProps) => {
	const { t, routes } = props;
	const tour = useTour();
	const dispatch = useDispatch();
	const [inProgress, setInProgress] = useState<'GET_IDEA' | 'PUBLISH' | undefined>(undefined);
	const [tagOptions, setTagOptions] = useState({ docs: [], isLoading: false });
	const [errors, setErrors] = useState({});

	const [platforms, setPlatforms] = useState<IPagination<IPlatform>>({
		docs: [],
		isLoading: true,
		limit: 2,
	});

	const { ideas, shared } = useSelector((state: IRootState) => state);

	const ideaPage = document.getElementById('idea-page');
	const [feedbackProps, setFeedbackProps] = useState<IFeedbackProps>({ open: false });
	const [participantsOptions, setParticipantsOptions] = useState([]);
	const [open, setOpen] = useState<'cover' | 'template' | undefined>(undefined);

	if (!ideas) return null;

	const disabled = ideas.mode === 'view' || !ideas.createdAt;
	const [platformType, setPlatformType] = useState();
	const isEmptyTags = getTextFromHTML(ideas?.resumo || '').length < 8;

	const handlePublish = (event) => {
		event.preventDefault();
		event.stopPropagation();
		tour.setIsOpen(false);
		if (!isEmpty(errors)) return;
		setInProgress('PUBLISH');
		dispatch(Creators.IDEAS.publishIdea({
			ideaId: ideas._id,
			callback: () => setInProgress(undefined),
			onError: (messages) => setErrors({ ...errors, ...messages }),
		}));

		tour.setIsOpen(false);
	};

	const loadTagOptions = () => {
		setTagOptions({ ...tagOptions, isLoading: true });
		dispatch(Creators.SHARED.getTags({
			onSuccess: (tags) => {
				setTagOptions({
					...tags,
					isLoading: false,
				});
			},
		}));
	};

	const loadPlatforms = async (type: string, params: IPaginatedRequest) => {
		try {
			setPlatformType(type);

			const queryParams = params || {};
			queryParams.page = 1;
			queryParams.limit = 2;
			queryParams.type = type;
			setPlatforms({ ...platforms, isLoading: true });

			const response = await APIPlatforms.getPlatformsJoined(ideas._id, queryParams);

			setPlatforms({
				...response.data,
				isLoading: false,
			});
		} catch (err) {
			handleAxiosErrors(err);
		}
	};

	const onUsersHandler = (
		key: 'string',
		value: readonly IUserOption[],
		reason: AutocompleteChangeReason,
		details?: AutocompleteChangeDetails<IUserOption>,
	) => {
		setInProgress('coautores');
		if (details?.option?.isFixed && reason === 'removeOption') return;
		dispatch(Creators.IDEAS.handleIdea({
			key,
			value,
			noDelay: true,
			hasWait: true,
			onSuccess: (response) => dispatch(
				Creators.IDEAS.updateIdeas({ coautores: response.coautores }),
			),
			callback: () => setInProgress(undefined),
		}));
	};

	const registerTags = useCallback(debounce(async (html: string) => {
		try {
			const response = await APIGlobal.postRegisterTags(html);

			setInProgress('tags');

			if (!response) return;

			dispatch(
				Creators.IDEAS.handleIdea({
					noDelay: true,
					key: 'tags',
					value: response.data.keywords.map((k) => ({ nome: k[0], value: k[0], label: k[0] })),
					onSuccess: () => SToast.success(t('messages.success.tags.added')),
					callback: () => setInProgress(undefined),
				}),
			);
			SToast.success(t('messages.success.tags.generated'));
		} catch (err) {
			handleAxiosErrors(err);
		}
	}, 1000), []);

	const onTagsHandler = (html: string) => {
		if (isEmptyTags) return;
		setInProgress('generate-tags');
		registerTags(html);
	};

	const loadParticipants = () => {
		if (disabled) return;
		if (ideas.plataforma?._id) {
			dispatch(Creators.IDEAS.getCoauthors({
				platformId: ideas.plataforma?._id,
				onSuccess: (response) => {
					if (response?.length > 0) {
						setParticipantsOptions(response);
					}
				},
			}));
		} else {
			dispatch(Creators.SHARED.getParticipants({
				onSuccess: (response: IPagination<IUserOrganization>) => {
					if (response?.length !== 0) {
						setParticipantsOptions(response.docs);
					}
				},
			}));
		}
	};

	const onTemplateHandler = (mode: 'reset' | 'update') => {
		tour.setIsOpen(false);
		setFeedbackProps({
			...feedbackProps,
			title: t(`feedback.${mode}.title`),
			text: t(`feedback.${mode}.content2`),
			rightButtonText: t('global.confirm'),
			onLeftButton: () => {
				if (shared?.tour) {
					tour.setIsOpen(true);
				}
				setFeedbackProps((prevState) => ({ ...prevState, open: false }));
			},
			open: true,
			color: 'success',
		});

		switch (mode) {
			case 'update':
				if (!ideas.template) {
					setFeedbackProps((prevProps) => ({
						...prevProps,
						onRightButton: () => {
							dispatch(Creators.MODAL.showModal(routes?.MODAL_IDEA_TEMPLATES.path));
						},
					}));
					return;
				}
				dispatch(Creators.MODAL.showModal(routes?.MODAL_IDEA_TEMPLATES.path));
				break;
			case 'reset':
				setFeedbackProps((prevProps) => ({
					...prevProps,
					onRightButton: () => {
						setFeedbackProps((prevState) => ({ ...prevState, isLoading: true }));
						dispatch(Creators.IDEAS.handleIdeaTemplate({
							templateId: ideas.template?._id,
							ideaId: ideas._id,
							platformId: ideas.plataforma?._id || undefined,
							onSuccess: () => {
								setFeedbackProps({ open: false });
								if (shared?.tour) {
									tour.setIsOpen(true);
								}
							},
							callback: () => setFeedbackProps((prevState) => ({ ...prevState, isLoading: false })),
						}));
					},
				}));
				break;
			default:
				break;
		}
	};

	const onCloseHandler = (e) => {
		BuildTour.close(tour);
		dispatch(Creators.SHARED.updateShared({ tour: undefined }));
		dispatch(Creators.MODAL.hideModal());

		if (e.target.dataset.action === 'view' && !ideaPage) {
			setTimeout(() => {
				goToLink(`${URLS.IDEAS}/${ideas._id}`);
			}, 300);
		} else if (typeof ideas.syncIdea === 'function' && ['UPDATE_IDEA'].includes(ideas.workflow)) {
			ideas.syncIdea(ideas);
		}

		dispatch(Creators.IDEAS.clearIdeaEdit());
	};

	const getPlatformsToAdd = () => {
		if (disabled) return;

		dispatch(Creators.MODAL.showModal(
			routes.MODAL_IDEA_PLATFORMS.path,
			{
				platformType,
				template: ideas?.template?.templateNome || 'Blank',
			},
		));
	};

	const onErrorHandler = (key: string, value?: string) => {
		if (typeof value === 'string') {
			setErrors((prevState) => ({ ...prevState, [key]: value }));
			return;
		}
		if (!isEmpty(errors)) delete errors[key];
	};

	const onIdeaHandler = (key, value) => {
		setInProgress(key);
		onErrorHandler(key);
		switch (key) {
			case 'tags':
				dispatch(Creators.IDEAS.handleIdea({
					key,
					value: value.map((item) => ({ value: item.value, label: item.value })),
					callback: () => setInProgress(undefined),
					onError: (messages) => setErrors({ ...errors, ...messages }),
				}));
				break;
			case 'resumo':
				dispatch(Creators.IDEAS.handleIdea(
					{
						key,
						value,
						callback: () => setInProgress(undefined),
						onError: (messages) => setErrors({ ...errors, ...messages }),
					},
				));

				break;
			case 'anexos':
				dispatch(Creators.IDEAS.uploadIdeaAttachments(value, () => setInProgress(undefined)));
				break;
			default:
				dispatch(Creators.IDEAS.handleIdea({
					key,
					value,
					callback: () => setInProgress(undefined),
					onError: (messages) => setErrors({ ...errors, ...messages }),
				}));
				break;
		}
	};

	const onCoverHandler = (action: 'edit' | 'delete') => {
		if (disabled) return;
		setInProgress('cover');

		if (action === 'delete') {
			dispatch(Creators.IDEAS.handleCoverIdea({
				cover: '',
				coverThumb: '',
				coverAuthor: undefined,
				callback: () => setInProgress(undefined),
			}));
		}

		if (action === 'edit') {
			BuildTour.close(tour);
			setOpen('cover');
		}
	};

	const focus = (selector) => {
		const mainInput = document.querySelector(selector);
		if (mainInput) mainInput.focus();
	};

	useEffect(() => {
		if (errors?.nome) {
			focus('#idea-name');
		}
	}, [errors]);

	const TOUR_STEPS = BuildTour.steps([
		'template.update', 'idea.coauthors', 'idea.summary', 'tags',
		'idea.cover', 'idea.platform.select', 'forms.new', 'canvas.new', 'attachments.add', 'idea.publish',
	], { images: ['forms.new'] });

	useEffect(() => {
		if (shared?.tour === FLOWS.IDEA) {
			BuildTour.setTour(tour, TOUR_STEPS);
		}

		loadParticipants();
		loadTagOptions();
		dispatch(Creators.PLATFORMS.clearPlatforms());
	}, []);

	return (
		<DialogContent id="IdeaDialog">
			<ModalHeader
				fullScreen
				icon={FLOWS.IDEA}
				onClose={onCloseHandler}
				BreadcrumbProps={{ className: W_GRID.W_GRID_2 }}
				pathParams={{
					idea: ideas?.nome || '...',
					template: ideas?.template?.templateNome || 'Blank',
				}}
			/>

			<DialogBox className="s-dialog-title inner w-grid-2">
				<DialogTitle variant="h1">
					{t('form.idea.addDetails')}
				</DialogTitle>
				<HelpButton
					size="large"
					onClick={() => BuildTour.setTour(tour, TOUR_STEPS, { currentStep: 0 })}
				>
					Tour
				</HelpButton>
			</DialogBox>

			<SBox loading={!ideas || _.isEmpty(ideas) || !ideas?.createdAt} className="inner w-grid-2">
				<DialogBox className="s-dialog-box">
					<Form className="s-form modal-edit">
						<SFormGroup>
							<SInputLabel htmlFor="idea-name" error={typeof errors?.nome === 'string'}>
								{t('form.idea.name')}
							</SInputLabel>
							<STextField
								name="nome"
								id="idea-name"
								fullWidth
								autoFocus
								variant="standard"
								maxLength={maxLength.flowName}
								autoComplete="off"
								disabled={disabled}
								placeholder={t('form.placeholder.flow.idea')}
								isAsync
								onError={onErrorHandler}
								isLoading={inProgress === 'nome'}
								value={ideas.nome}
								error={typeof errors?.nome === 'string'}
								helperText={errors?.nome}
								onChange={(event) => onIdeaHandler('nome', event.target.value)}
							/>
						</SFormGroup>
						{(ideas.status === 'rascunho' || ideas.template) && (
							<SFormGroup data-tut="template-update">
								<Box className="s-label-group s-label">
									<InputLabel>
										{t('global.template')}
									</InputLabel>
									<HelpButton color="secondary" className="tour">
										<STour {...TOUR_STEPS?.[0]} hideActions helperURL={t('urls.ideaTemplates')} />
									</HelpButton>
								</Box>

								<TemplateDetails
									icon={ideas?.template?.slug || 'template'}
									color={ideas?.template?.color || undefined}
									isBlank={!ideas.template?.templateNome}
									name={ideas.template?.templateNome}
									onReset={ideas.mode === 'edit' && ideas.status === 'rascunho'
										? () => onTemplateHandler('reset') : undefined}
									onChange={ideas.mode === 'edit' && ideas.status === 'rascunho'
										? () => onTemplateHandler('update') : undefined}
								/>
							</SFormGroup>
						)}
						<SFormGroup>
							<Box className="s-label-group s-label">
								<InputLabel htmlFor="platform-summary" error={typeof errors?.coautores === 'string'}>
									{t('global.co-authors')}
								</InputLabel>
								<HelpButton color="secondary" className="tour">
									<STour {...TOUR_STEPS?.[1]} hideActions helperURL={t('urls.coautors')} />
								</HelpButton>
							</Box>
							<SAutocomplete
								id="idea-coauthors"
								disabled={disabled}
								unique="_id"
								placeholder={t('form.select')}
								multiple
								// isClearable={ideas.coautores?.some((v) => !v.isFixed)}
								value={getUserOptions(ideas.coautores) || []}
								loading={inProgress === 'coautores'}
								isAsync
								options={getUserOptions(participantsOptions)}
								onChange={(event, value, reason, details) => onUsersHandler('coautores', value, reason, details)}
								disableCloseOnSelect
								renderTags={(opts, getTagProps) => (
									<Tags
										value={opts}
										component={(option, index) => (
											<UserTag renderTags option={option} {...getTagProps({ index })} />
										)}
									/>
								)}
								renderOption={(_props, option) => <li {..._props}><UserTag option={option} /></li>}
							/>
						</SFormGroup>

						<SFormGroup data-tut="idea-summary">
							<Box className="s-label-group s-label">
								<InputLabel htmlFor="idea-summary" error={typeof errors?.resumo === 'string'}>
									{t('form.summary')}
								</InputLabel>
								<HelpButton color="secondary" className="tour">
									<STour {...TOUR_STEPS[2]} hideActions helperURL={t('urls.summary')} />
								</HelpButton>
							</Box>

							<RichTextEditor
								disabled={disabled}
								id="idea-summary"
								name="resumo"
								minHeight="160px"
								// onPaste={onTags}
								isAsync
								isLoading={inProgress === 'resumo'}
								placeholder={t('form.placeholder.summary')}
								value={ideas.resumo}
								error={typeof errors?.resumo === 'string'}
								helperText={errors?.resumo}
								onChange={(value) => onIdeaHandler('resumo', value)}
							/>
						</SFormGroup>
						<SFormGroup data-tut="tags">
							<Box className="d-flex align-items-center justify-content-between s-label">
								<Box className="s-label-group">
									<InputLabel htmlFor="tags-idea-autocomplete">
										Tags
									</InputLabel>
									<HelpButton color="secondary" className="tour">
										<STour {...TOUR_STEPS[3]} hideActions helperURL={t('urls.tags')} />
									</HelpButton>
								</Box>
								<SButton
									type="button"
									color="primary"
									size="small"
									onClick={() => onTagsHandler(ideas.resumo)}
									id="platform-generate-tags"
									isLoading={inProgress === 'generate-tags'}
									disabled={isEmptyTags || disabled}
									startIcon={<Icon icon="magic" />}
								>
									{t('global.generate')}
								</SButton>
							</Box>
							<SAutocomplete
								id="tags-idea-autocomplete"
								placeholder={t('form.tagsSelectOrCreate')}
								value={ideas.tags || []}
								multiple
								freeSolo
								renderTags={(opts, getTagProps) => (
									<Tags
										value={opts}
										onReorder={(value) => onIdeaHandler('tags', value)}
										component={(option, index) => (
											<Tag renderTags option={option} draggable {...getTagProps({ index })} />
										)}
									/>
								)}
								options={tagOptions.docs || []}
								onChange={(event, value) => onIdeaHandler('tags', value)}
								loading={inProgress === 'tags'}
								isAsync
								disabled={disabled}
							/>
						</SFormGroup>
						<SFormGroup className="cover__group" id="cover" data-tut="idea-cover">
							<Box className="d-flex align-items-center justify-content-between s-label">
								<Box className="s-label-group">
									<InputLabel>
										Cover
									</InputLabel>
									<HelpButton color="secondary" className="tour">
										<STour {...TOUR_STEPS[4]} hideActions helperURL={t('urls.cover')} />
									</HelpButton>
								</Box>
								<SButton
									type="button"
									color="primary"
									size="small"
									disabled={disabled}
									isLoading={inProgress === 'cover'}
									onClick={() => onCoverHandler('edit')}
									startIcon={(ideas.cover !== '' && !disabled) ? <Icon icon="sync" /> : <Icon icon="remove" />}
								>
									{(ideas.cover !== '' && !disabled) ? t('global.change') : t('global.remove')}
								</SButton>
							</Box>
							<BCover
								area="idea"
								disabled={disabled}
								cover={ideas.coverThumb || ideas.cover}
								isLoading={inProgress === 'cover'}
								onClick={() => onCoverHandler('edit')}
							/>
						</SFormGroup>
						<SFormGroup className="platform__type--idea" data-tut="idea-platform-select">
							<Box className="s-label-group s-label">
								<InputLabel>
									{ideas.plataforma?.type
										? t(`global.${ideas.plataforma?.type?.toLowerCase()}`)
										: t('global.add')}
								</InputLabel>
								<HelpButton size="small" color="secondary" className="tour">
									<STour {...TOUR_STEPS[5]} hideActions />
								</HelpButton>
							</Box>

							<div className="d-flex align-items-center">
								{[
									{ type: FLOWS.CHALLENGE },
									{ type: FLOWS.BRAINSTORM },
								].map((item) => {
									const type = ideas.plataforma?.type?.toLowerCase();
									if (typeof type === 'string' && (type !== item.type)) return null;
									return (
										<Box key={type} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
											<SRadio
												key={item.type}
												checked={item.type === platformType || item.type === type}
												value={item.type}
												disabled={(ideas.status !== 'rascunho' && ideas.plataforma?.type) || disabled}
												onChange={(event) => loadPlatforms(event.target.value, {
													page: 1,
												})}
												label={(
													<>
														<Icon icon={item.type} className="icon__type" />
														<span className="text">{t(`global.${item.type}`)}</span>
														{ideas.plataforma && (
															<span className="text">
																{`: ${ideas.plataforma.nome}`}
															</span>
														)}
													</>
												)}
											/>
											{type === item.type && (
												<div className="d-flex">
													{ideas.status === 'rascunho' && (
														<SButton
															type="button"
															color="primary"
															size="small"
															startIcon={<Icon icon="remove" />}
															isLoading={inProgress === 'platform'}
															onClick={() => {
																setInProgress('platform');
																dispatch(Creators.PLATFORMS.handleAddIdea(
																	{
																		ideaId: ideas._id,
																		platformId: null,
																		type: t(`global.${ideas.plataforma?.type.toLowerCase()}`),
																		callback: () => setInProgress(undefined),
																		onSuccess: (idea) => {
																			setPlatformType();
																			dispatch(
																				Creators.IDEAS.updateIdeas({
																					plataforma: idea.plataforma,
																				}),
																			);
																		},
																	},
																));
															}}
														>
															{t('global.remove')}
														</SButton>

													)}
												</div>
											)}
										</Box>
									);
								})}
							</div>
							<CardGrid
								id="idea-platforms-add"
								className="mt-10px"
								section={platforms}
								type={`${platformType}s`}
								GridItemProps={{
									xs: 12, sm: 6, md: 6, lg: 6, xl: 6,
								}}
								hide={!platformType
										|| disabled}
								onViewMore={getPlatformsToAdd}
								component={(platform) => (
									<PlatformCard
										clickable={false}
										platform={platform}
										routes={routes}
										updateIdea={(_idea) => {
											dispatch(Creators.IDEAS.updateIdeas(_idea));
											setPlatformType();
										}}
										sectionLocator="idea-platform-add"
										idea={ideas}
									/>
								)}
							/>
						</SFormGroup>
						<SFormGroup id="forms-modal-create" data-tut="forms-new">
							<SInputLabel>
								{`${t('global.form')}s`}
								<HelpButton color="secondary" className="tour">
									<STour {...TOUR_STEPS[6]} hideActions helperURL={t('urls.forms')} />
								</HelpButton>
							</SInputLabel>
							<BForms
								routes={{
									form: routes.MODAL_IDEA_FORM_DIRECT.path,
									forms: routes.MODAL_IDEA_FORMS.path,
								}}
								CreateProps={{
									title: t('global.new'),
								}}
								forms={{ docs: ideas?.forms }}
								isDisabled={disabled}
								flow={FLOWS.IDEA}
								mode={ideas.mode === 'view' ? 'view' : 'answer'}
							/>
						</SFormGroup>
						<SFormGroup id="canvas-modal-create" data-tut="canvas-new">
							<Box className="s-label-group s-label">
								<InputLabel>
									Canvas
								</InputLabel>
								<HelpButton color="secondary" className="tour">
									<STour {...TOUR_STEPS[7]} hideActions helperURL={t('urls.canvas')} />
								</HelpButton>
							</Box>
							<BCanvas
								routes={{
									model: routes.MODAL_IDEA_CANVAS_DIRECT.path,
									models: routes.MODAL_IDEA_CANVAS.path,
								}}
								canvas={{ docs: ideas.canvas }}
								mode={ideas.mode}
								flow={FLOWS.IDEA}
								CreateProps={{
									title: t('global.new'),
								}}
								template={ideas.templateNome || ideas.template?.nome || '...'}
							/>
						</SFormGroup>
						{ideas.plataforma?.type === 'Challenge' && (
							<SFormGroup id="supports-idea-create" data-tut="features-new">
								<Box className="s-label-group s-label">
									<InputLabel>
										{t('global.features')}
									</InputLabel>
									<HelpButton color="secondary" className="tour">
										<STour {...TOUR_STEPS[7]} hideActions helperURL={t('urls.features')} />
									</HelpButton>
								</Box>
								<BFeatures
									CreateProps={{
										title: t('global.new'),
									}}
									features={{ docs: ideas.funding }}
									mode={ideas.mode}
									routes={{
										features: routes.MODAL_IDEA_FEATURES.path,
										feature: routes.MODAL_IDEA_FEATURE_DIRECT.path,
									}}
								/>
							</SFormGroup>
						)}

						<SFormGroup className="upload-files" data-tut="attachments-add">
							<Box className="s-label-group s-label">
								<InputLabel htmlFor="attachments-add">
									{t('global.attachments')}
								</InputLabel>
								<HelpButton color="secondary" className="tour">
									<STour {...TOUR_STEPS[8]} hideActions helperURL={t('urls.attachments')} />
								</HelpButton>
							</Box>
							<UploadFiles
								id="attachments-add"
								values={ideas.anexos}
								isDisabled={disabled}
								onRemove={(id, callback) => {
									setInProgress('anexos');
									dispatch(
										Creators.IDEAS.removeIdeaAttachment(
											id,
											() => { callback(); setInProgress(undefined); },
										),
									);
								}}
								onClick={(file) => onIdeaHandler('anexos', file)}
								isLoading={inProgress === 'anexos'}
							/>
						</SFormGroup>

					</Form>
				</DialogBox>
				<DialogBox className="s-dialog-box">
					<DialogActions className="s-dialog-actions" data-tut="idea-publish">
						<SButton
							type="button"
							variant="outlined"
							color="primary"
							fullWidth
							size="large"
							isLoading={inProgress === 'PUBLISH'}
							onClick={ideas.status === 'rascunho' ? handlePublish : onCloseHandler}
							error={!isEmpty(errors)}
						>
							<span className="idea_publish">
								{ideas.status === 'rascunho' ? t('form.flow.publish', { flow: t('global.idea') }) : t('idea.view')}
							</span>
						</SButton>
					</DialogActions>
				</DialogBox>
			</SBox>
			{feedbackProps.open && (
				<ModalFeedback
					id="idea-edit"
					onRequestClose={() => setFeedbackProps({ open: false })}
					{...feedbackProps}
				/>
			)}
			{ open === 'cover' && (
				<ModalCover
					flow={FLOWS.IDEA}
					id={ideas._id}
					open={open === 'cover'}
					onClose={() => {
						if (shared?.tour) {
							BuildTour.setTour(tour, TOUR_STEPS, { currentStep: 4 });
						}
						setOpen(undefined);
						setInProgress(undefined);
					}}
					query={
						ideas.tags && ideas.tags.length > 0
							? ideas.tags[0].value
							: ''
					}
				/>
			)}
		</DialogContent>
	);
};

// required to persist store
const Translation = withTranslation('common')(MModalIdea);

const ModalIdea = (props: IModal) => (
	<Suspense fallback={<div />}>
		<Translation {...props} />
	</Suspense>
);

export default ModalIdea;
