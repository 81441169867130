/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
// @ts-nocheck
import { Fragment } from 'react';
import { STextField } from '@forms/Components';
import {
	Form, SInputLabel, SFormGroup, SRadio, SCheckbox,
} from './ComponentsCore';
import { LinearScale } from './Components/LinearScale';
import { MultipleChoice } from './Components/MultipleChoice';
import { SelectOption } from './Components/SelectOption';
import { inputTypes } from './FieldsTypes';
import { SSelect } from './Components/SSelect';

const FieldArray = (props) => {
	const { inputype } = props;

	switch (inputype) {
		case inputTypes.TEXT_INPUT:
			return <STextField {...props} />;
		case inputTypes.CHECKBOX:
			return <SCheckbox {...props} />;
		case inputTypes.MULTIPLE_CHOICE:
			return <MultipleChoice {...props} />;
		case inputTypes.RADIO:
			return <SRadio {...props} />;
		case inputTypes.SELECT_OPTION:
			return <SelectOption {...props} />;
		case inputTypes.LINEAR_SCALE:
			return <LinearScale {...props} />;
		case inputTypes.NUMBER_INPUT:
			return <STextField type="number" {...props} />;
		case inputTypes.TEXT_PICKER:
			return <SSelect {...props} />;
		case inputTypes.TEXT_AREA:
			return <STextField multiline {...props} />;
		default:
			return <STextField {...props} />;
	}
};

const DynamicForm = ({
	className, form, id, disabled, children, errors
}) => {
	const inputText = [inputTypes.TEXT_INPUT, inputTypes.TEXT_AREA, inputTypes.MASKER_INPUT, inputTypes.NUMBER_INPUT];

	return (
		<Form id={id || ''} className={className || ''}>
			{Array.isArray(form.fields) && form.fields.map((field, index) => (
				<Fragment key={index}>
					{field.options
						&& (
							<SFormGroup>
								<SInputLabel
									htmlFor={field.id}
									className={field.required ? 'required' : ''}
									error={typeof errors?.[field.name] === 'string'}
								>
									{field.label}
								</SInputLabel>
								<FieldArray
									id={field.id || ''}
									inputype={field.inputype || inputTypes.TEXT_INPUT}
									onChange={(event) => (typeof field.handleField === 'function' ? field.handleField(event) : null)}
									onBlur={field.onBlur}
									value={field.value}
									name={field.name}
									className={field.className || ''}
									error={typeof errors?.[field.name] === 'string'}
									helperText={errors?.[field.name] || field.helperText}
									disabled={field.disabled || disabled}
									options={field.options}
									maxLength={field.maxLength}
									components={field.components}
									selectable={field.selectable}
									placeholder={field.placeholder}
								/>
							</SFormGroup>
						)}
					{field.component
						&& (
							<SFormGroup>
								<SInputLabel htmlFor={field.id} error={typeof errors?.[field.name] === 'string'}>
									{field.label}
								</SInputLabel>
								{field.component}
							</SFormGroup>
						)}
					{field.inputype === inputTypes.LINEAR_SCALE
						&& (
							<SFormGroup>
								<SInputLabel
									htmlFor={field.id}
									className={field.required ? 'required' : ''}
									error={typeof errors?.[field.name] === 'string'}
								>
									{field.label}
								</SInputLabel>
								<FieldArray
									field={field}
									value={field.value}
									inputype={field.inputype}
									disabled={disabled}
									error={typeof errors?.[field.name] === 'string'}
									helperText={errors?.[field.name] || field.helperTex}
									onChange={typeof field.handleField === 'function' ? (event) => field.handleField(event) : undefined}
								/>
							</SFormGroup>
						)}
					{inputText.includes(field.inputype)
						&& (
							<SFormGroup>
								<SInputLabel
									htmlFor={field.id}
									name={field.name}
									className={field.required ? 'required' : ''}
									error={typeof errors?.[field.name] === 'string'}
								>
									{field.label}
								</SInputLabel>
								<FieldArray
									id={field.id}
									inputype={field.inputype || inputTypes.TEXT_INPUT}
									onChange={(event) => (typeof field.handleField === 'function' ? field.handleField(event) : null)}
									onBlur={field.onBlur}
									value={field.value || ''}
									helperText={errors?.[field.name]}
									name={field.name}
									className={field.className || ''}
									error={typeof errors?.[field.name] === 'string'}
									type={field.type}
									mask={field.mask}
									disabled={field.disabled || disabled}
									required={field.required}
									rows={field.rows}
									maxLength={field.maxLength}
									placeholder={field.placeholder}
								/>
							</SFormGroup>
						)}
				</Fragment>
			))}
			{children}
		</Form>
	);
};

export { DynamicForm, FieldArray };
