// @ts-nocheck
import React, { useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import TeamCover from '@components/Covers/TeamCover';
import { Creators } from '@actions';
import RedactorText from '@components/Shared/RedactorText';
import { MembersList } from '@components/Lists/MembersList';
import { IRootState } from 'src/store';
import PageLoader from '@components/Loaders/PageLoader';
import { ITeam } from 'src/@types/ITeam';
import { APITeams } from '@services/apis';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { SToast } from '@modules/SToast';
import { GenericGrid } from '@components/Grids/GenericGrid';

type TProps = WithTranslation

const TTeam = ({ t }: TProps) => {
	const match = useParams<{ id: string }>();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { modal } = useSelector((state: IRootState) => state);
	const [team, setTeam] = useState<ITeam>();
	const [loaded, setLoaded] = useState(false);

	const loadTeam = async (id: string) => {
		try {
			setLoaded(false);
			const response = await APITeams.getTeam(id);
			setTeam(response.data);
			setLoaded(true);
		} catch (err) {
			if (err?.response?.data?.err) {
				SToast.error(t(err?.response?.data?.err));
				navigate(-1); // only not found 400
			}
		}
	};

	const updateTeam = (team: ITeam) => {
		setTeam((prevState) => ({ ...prevState, team }));
	};

	React.useEffect(() => {
		if (match.id) {
			loadTeam(match.id);

			if (modal?.isOpen) {
				dispatch(Creators.MODAL.handleParams('teamId', match.id));
				dispatch(Creators.MODAL.handleParams('syncTeam', updateTeam));
			}
		}
	}, [match.id]);

	if (!team || !loaded) {
		return <PageLoader />;
	}

	return (
		<GenericGrid id="team-page" className="s-grid-page">
			<TeamCover
				team={team as ITeam}
				updateTeam={updateTeam}
			/>
			<div className="block has-tablet">
				<div className="inner w-grid-2 first-section">
					<div className="box__page--info">
						<RedactorText html={team.descricao} />
					</div>
				</div>
			</div>
			<div className="block pt-0">
				<div className="w-grid-2 inner">
					<MembersList
						id="teams-list"
						areTeams
						members={{ docs: team.membros }}
					/>
				</div>
			</div>
		</GenericGrid>
	);
};

const Translation = withTranslation('common')(TTeam);
const Team = () => (
	<React.Suspense fallback={<PageLoader />}>
		<Translation />
	</React.Suspense>

);

export default Team;
