// @ts-nocheck
import React, {
	ClipboardEvent, KeyboardEvent, Suspense, useState,
} from 'react';
import { Trans, withTranslation, WithTranslation } from 'react-i18next';
import UnauthenticatedHeader from '@components/Header/UnauthenticatedHeader';
import PageLoader from '@components/Loaders/PageLoader';
import { APIUnregistered } from '@services/apis';
import { IRootState } from 'src/store';
import { Creators } from '@actions';
import { auth, handleAxiosErrors } from '@services/auth';
import { goToLink } from '@utils/ReactUtils';
import URLS from '@constants/URLS';
import {
	Alert, Box, Grid, Typography,
} from '@mui/material';
import { SLink } from '@components/Buttons/SLink';
import { useLocation, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { GenericGrid } from '@components/Grids/GenericGrid';

interface IToken {
	code0: string
	code1: string
	code2: string
	code3: string
	code4: string
	code5: string
}

type TProps = WithTranslation & IRootState

const _Token = (props: TProps) => {
	const { t } = props;
	const location = useLocation();
	const dispatch = useDispatch();
	const match = useParams<{ token: string }>();
	const [token, setToken] = useState<IToken>({
		code0: '',
		code1: '',
		code2: '',
		code3: '',
		code4: '',
		code5: '',
	});

	const [errors, setErrors] = useState({});
	const [inProgress, setInProgress] = useState();

	const isCreateOrganization = location.search.includes('createOrganization');

	const onSubmit = async () => {
		try {
			setInProgress('submit');
			let _token = '';
			Object.values(token).forEach((v) => _token += v);

			let response;

			if (isCreateOrganization) {
				response = await APIUnregistered.postOrganizationToken(_token.toUpperCase());
				if (response?.data?.token) {
					auth.setToken(response.data.token);
				}
				goToLink(`${URLS.ORGANIZATION_SETUP}?step=organization`);
			} else {
				response = await APIUnregistered.postToken(_token.toUpperCase());

				if (response?.data?.token) {
					auth.setToken(response.data.token);
				}
				goToLink(`${URLS.ACCOUNT_SETUP}?step=user`);
			}
		} catch (err: any) {
			if (err?.response?.data?.err) {
				this.setState({ errors: { token: t(err?.response?.data?.err) } });
				return;
			}
			handleAxiosErrors(err);
		} finally {
			setInProgress(undefined);
		}
	};

	const onTokenHandler = (index: number, value: string) => {
		token[`code${index}` as keyof IToken] = value;
		setToken((prevState) => ({ ...prevState }));
	};

	const onPasteHandler = (e: ClipboardEvent<HTMLInputElement>) => {
		const pastedText = e.clipboardData?.getData('Text');

		if (pastedText) {
			pastedText.trim(); // method removes whitespace from both ends of a string and returns a new string, without modifying the original string.
			const arrText = pastedText.split('', Object.values(token).length);

			arrText.forEach((item: string, index: number) => {
				onTokenHandler(index, item);
			});

			if (Object.values(token).every((v) => v !== '')) {
				onSubmit();
			}
		}
	};

	const onResendTokenHandler = (event: React.MouseEvent | React.KeyboardEvent) => {
		event.preventDefault();
		const email = new URLSearchParams(location.search)?.get('email');

		setInProgress('resend');

		if (isCreateOrganization) {
			dispatch(Creators.USER_ORGANIZATION.requestOrganizationCreation({
				email,
				callback: () => setInProgress(undefined),
			}));

			return;
		}
		dispatch(Creators.USER_ORGANIZATION.resendToken({
			email,
			onError: (err: string) => setErrors({ token: t(err) }),
			callback: () => setInProgress(undefined),
		}));
	};

	const onKeyUpHandler = (e: KeyboardEvent<HTMLInputElement>) => {
		const item = e.target as HTMLInputElement;

		// e.stopPropagation();

		if (e.defaultPrevented) {
			return; // Do nothing if the event was already inProgressed
		}

		const shiftPressed = e.shiftKey;
		const cntrlPressed = e.ctrlKey;

		if (shiftPressed || cntrlPressed) {
			return;
		}

		// https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/key
		// https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/key/Key_Values
		switch (e.key) {
			// backspace / delete
			case 'Backspace':
				if (item?.previousElementSibling) {
					(item.previousElementSibling as HTMLElement).focus();
				}
				break;
			// delete, ond one 46
			// [1] On keyboards without a dedicated Del key, the Mac generates the "Delete" value when Fn is pressed in tandem with Delete (which is Backspace on other platforms).
			case 'Delete':
				if (item.nextElementSibling) {
					(item.nextElementSibling as HTMLElement).focus();
				}
				break;
			case 'Left': // IE/Edge specific value
			case 'ArrowLeft':
				if (item.previousElementSibling) {
					(item.previousElementSibling as HTMLElement).focus();
				}
				break;
			case 'Right': // IE/Edge specific value
			case 'ArrowRight':
				if (item.nextElementSibling) {
					(item.nextElementSibling as HTMLElement).focus();
				}
				break;
			case 'Shift': // Shift
			case 'Control': // Control, Ctrl, or Ctl
			case 'Alt': // Alt
			case 'Esc': // IE/Edge specific value
			case 'Escape':
			case 'Up': // IE/Edge specific value
			case 'ArrowUp':

				break;
			default:
				// @ts-ignore
				if (item.value.length === +item.attributes.maxLength.value) {
					if (item.nextElementSibling) (item.nextElementSibling as HTMLElement).focus();
					else if (Object.values(token).every((v) => v !== '')) {
						onSubmit();
					}
				}
				break;
		}

		// Cancel the default action to avoid it being handled twice
		e.preventDefault();
	};

	React.useEffect(() => {
		const inputCode0 = document.querySelector('#code0') as HTMLElement;
		if (inputCode0) {
			inputCode0.focus();
		}

		if (match.token) {
			match.token.split('').forEach((item, index) => {
				token[`code${index}` as keyof IToken] = item;
			});

			onSubmit();
		}
	}, []);

	const email = new URLSearchParams(location.search)?.get('email');

	return (
		<GenericGrid id="token-page" className="token__page s-grid-page">
			<UnauthenticatedHeader modus="logIn" />
			<Grid container className="inner w-grid-3 unauthenticated" spacing={3}>
				<Grid item container textAlign="center" spacing={2}>
					<Grid item xs={12}>
						<Typography variant="h1">{t('register.checkYourEmail')}</Typography>
					</Grid>
					<Grid item xs={12}>
						<Typography variant="body1">
							{`${t('register.sentDigitConfirmationCode')} `}
							<strong>{`${email}. `}</strong>
							{t('register.enterCode')}
						</Typography>
					</Grid>
				</Grid>
				<Grid
					item
					xs={12}
					sx={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						input: {
							height: '48px',
							width: '40px',
							border: '1px solid var(--form-color)',
							borderRadius: '4px',
							margin: '0 3px',
							padding: '0.75rem',
							textTransform: 'uppercase',
						},
					}}
				>
					{Object.values(token).map((item, index) => (
						<input
							key={index}
							id={`code${index}`}
							className={`form form-control ${index === 0 ? 'ml-0' : ''}`}
							type="text"
							autoComplete="off"
							data-index={index}
							maxLength={1}
							onKeyUp={onKeyUpHandler}
							onPaste={onPasteHandler}
							name={`code${index}`}
							value={item}
							onChange={(e) => onTokenHandler(index, e.target.value)}
							disabled={inProgress === 'submit'}
						/>
					))}
				</Grid>

				{errors.token && (
					<Grid item xs={12}>
						<Alert severity="error" className="text-center">
							{errors.token}
						</Alert>
					</Grid>
				)}

				<Grid item xs={12}>
					<Typography variant="h6" sx={{ fontWeight: 500, button: { verticalAlign: 'baseline' } }}>
						<Trans
							i18nKey="register.sendCodeToken"
							components={{
								1: <SLink
									href="# "
									component="button"
									underline="always"
									onClick={onResendTokenHandler}
									isLoading={inProgress === 'resend'}
								/>,
								2: <SLink
									href={t('urls.zendesk')}
									target="_blank"
									rel="noreferrer"
									underline="always"

								/>,
							}}
						/>

					</Typography>
				</Grid>
			</Grid>
		</GenericGrid>
	);
};

const Translation = withTranslation('common')(_Token);

const Token = () => (
	<Suspense fallback={<PageLoader />}>
		<Translation />
	</Suspense>
);

export default Token;
