/* eslint-disable react/no-array-index-key */
/* eslint-disable no-param-reassign */
/* eslint-disable react/prop-types */
// @ts-nocheck
import { Box, FormHelperText } from '@mui/material';

import { SRadio } from '../ComponentsCore';

const SelectOption = ({
	options, error, value, innerClass = 'form', id, radioClass, className, onChange, helperText, ...rest
}) => {
	const handleValue = (option) => {
		const otherIndex = options.findIndex((item) => item.isOther);

		// eslint-disable-next-line no-prototype-builtins
		if (option.hasOwnProperty('hasOther')) {
			if (option.isOther) {
				option.hasOther = !option.hasOther;
				option.value = '';
			} else {
				options[otherIndex].hasOther = false;
				options[otherIndex].value = '';
			}
		}
		if (typeof onChange === 'function') { onChange(option.value); }
	};

	const handleOther = (option, otherValue) => {
		option.value = otherValue;
		if (typeof onChange === 'function') { onChange(option.value); }
	};

	return Array.isArray(options)
		? (
			<Box className={className}>
				{options?.map((option, index) => (
					<div key={index} className={`radio ${radioClass || ''}`}>
						<SRadio
							id={`${id}-${index}`}
							value={option.value}
							checked={option.value === value}
							onChange={() => handleValue(option)}
							label={option.label}
							{...rest}
						/>
						{option.hasOther
							&& (
								<textarea
									rows="6"
									className={`form-control mt-15px ${innerClass}`}
									value={option.value}
									onChange={(e) => handleOther(option, e.target.value)}
								/>
							)}
					</div>
				))}
				{helperText && <FormHelperText error={error}>{helperText}</FormHelperText>}
			</Box>
		)
		: null;
};

export { SelectOption };
