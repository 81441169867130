import React from 'react';

interface IOwnProps {
	children: React.ReactNode
	target: string
	show?: boolean
	className?: string
	title?: string
	error?: boolean
	toggle: () => void
}

const Accordion = (props: IOwnProps) => {
	const {
		title = '', children, target = 'collapseOne', className = '', show, error, toggle,
	} = props;
	return (
		<div id="accordion" className={`accordion ${className}`}>
			<div className="card">
				<div className="card-header">
					<a className={`p-0 accordion-collapse ${show ? '' : 'collapsed'}`} data-bs-toggle="collapse" href={`#accordion_${target}`} aria-controls={target} role="button" onClick={toggle}>
						<span>{title}</span>
						<svg xmlns="http://www.w3.org/2000/svg" width="10.001" height="6.458">
							<path d="M5.532.22l4.249 4.249a.747.747 0 010 1.059l-.706.706a.747.747 0 01-1.059 0L5.001 3.225 1.989 6.238a.747.747 0 01-1.059 0l-.709-.706a.747.747 0 010-1.059L4.47.224A.748.748 0 015.532.22z" fill="#fff" />
						</svg>
					</a>
				</div>
				{error && <i className="fas fa-exclamation-circle text-danger mt-10px" style={{ right: '-30px', position: 'absolute' }} />}
				<div id={`accordion_${target}`} className={`collapse ${show ? 'show' : ''}`}>
					<div className="card-body">
						{children}
					</div>
				</div>
			</div>
		</div>
	);
};

export { Accordion };
