// @ts-nocheck
import i18next from 'i18next';
import { AxiosResponse } from 'axios';
import { Creators, Types } from '@actions';
import { APIBilling } from '@services/apis';
import { handleAxiosErrors } from '@services/auth';
import {
	put,
	call,
	select,
	takeLatest,
} from 'redux-saga/effects';
import { SToast } from '@modules/SToast';

function* subscribePlan(action) {
	try {
		const updatePlan = yield select((state) => state.billing?.updatePlan);

		yield call(
			APIBilling.postSubscribePlan,
			{
				planId: updatePlan.id,
				interval: updatePlan.interval,
				quantity: updatePlan.quantity,
			},
		);

		yield call(SToast.success, i18next.t('settings.planChanged'));
		yield put(Creators.BILLING.getOverviewPlan());
		yield put(Creators.MODAL.hideModal());
	} catch (err) {
		yield call(handleAxiosErrors, err);
		if (err?.response?.status) {
			switch (err.response.status) {
				case 402:
					if (err.response.data === 'card_declined') {
						yield call(SToast.error, i18next.t(`messages.${err.response.data}`));
					} else {
						yield call(SToast.error, i18next.t('settings.addPaymentMethod'));
					}
					break;
				default:
					yield call(handleAxiosErrors, err);
					break;
			}
		}
	} finally {
		if (typeof action.p?.callback === 'function') {
			yield call(action.p.callback);
		}
	}
}

function* getOverviewPlan(action) {
	try {
		const isOpen = yield select((state) => state.modal?.isOpen);
		const response: AxiosResponse = yield call(APIBilling.getOverviewPlan);

		// if (!response?.data?.defaultPaymentMethod && response.data.subscription?.plan?.name !== 'Free') {
		// 	yield call(Notify.message, {
		// 		text: i18next.t('messages.stripe.paymentMethod.register'),
		// 		callback: () => {
		// 			if (document.getElementById('settings-page')) {
		// 				showTab({ tabId: 'billing-tab' });
		// 				setTimeout(() => {
		// 					showTab({ tabId: 'billing-plans-tab' });
		// 				}, 300);

		// 				navigate('?tab=billing-tab&focus=billing-plans-tab', { replace: true });
		// 			} else {
		// 				navigate(`${URLS.SETTINGS}?tab=billing-tab&focus=billing-plans-tab`);
		// 			}
		// 		},
		// 	});
		// }

		if (response?.data?.subscription && !isOpen) {
			const { subscription } = response.data;
			yield put(Creators.BILLING.handlePlan({
				id: subscription.plan.product,
				name: subscription.plan.name,
				quantity: parseInt(subscription.quantity, 10),
				currency: subscription.plan.currency,
				amount: subscription.plan.amount,
				interval: subscription.plan.interval,
			}));
		}

		yield put(Creators.BILLING.successBilling(response.data));

		if (typeof action?.params?.success === 'function') {
			yield call(action.params.success);
		}
	} catch (err) {
		yield call(handleAxiosErrors, err);
	} finally {
		if (typeof action?.params?.callback === 'function') {
			yield call(action.params.callback);
		}
	}
}

export default function* () {
	yield takeLatest(Types.BILLING.SUBSCRIBE_PLAN, subscribePlan);
	yield takeLatest(Types.BILLING.GET_OVERVIEW_PLAN, getOverviewPlan);
}
