import React, { useState } from 'react';
import Icon from '@components/Shared/Icon';
import {
	AccordionDetails, Box, CircularProgress, IconButton, Typography,
} from '@mui/material';

import { UserAvatar } from '@components/Avatar/UserAvatar';
import AnsersListComponent from '@components/QA/AnswersList';
import { useTranslation } from 'react-i18next';
import { SBox } from '@components/Background/SBox';
import { SToast } from '@modules/SToast';
import { APIQuestions } from '@services/apis';
import { IPagination } from 'src/@types/IShared';
import { handleAxiosErrors } from '@services/auth';
import { SButton } from '@components/Buttons';
import { FaMapSigns } from 'react-icons/fa';
import { ISavedQuestion } from 'src/@types/IIdea';

export interface GenerateAnswersParams {
	text: string
	callback?: () => void
	onSuccess?: () => void
}

interface IOwnProps {
	userId: string;
	ideaId: string
	setSeeResults: React.Dispatch<React.SetStateAction<boolean>>
	generateAnswers: (params: GenerateAnswersParams) => void;
}

const SavedQuestionList = (props: IOwnProps) => {
	const {
		userId, ideaId,
		generateAnswers,
		setSeeResults
	} = props;

	const { t } = useTranslation();
	const [inProgress, setInProgress] = useState<string | undefined>();
	const [questions, setQuestions] = useState<IPagination<any>>({ docs: [], isLoading: true });

	const loadQuestions = async () => {
		try {
			setQuestions((prevState) => ({ ...prevState, isLoading: true }));
			const response = await APIQuestions.getSavedQuestions({ ideaId });
			setQuestions({ docs: response.data, isLoading: false });
		} catch (err: any) {
			handleAxiosErrors(err);
		}
	};

	const deleteQuestion = async (questionId: string) => {
		try {
			setInProgress(`delete-question-${questionId}`);
			await APIQuestions.deleteSavedQuestion({ id: questionId });

			const index = questions.docs.findIndex((item) => item._id === questionId);
			if (index > -1) {
				questions.docs.splice(index, 1);
				setQuestions((prevState) => ({ ...prevState, questions }));
			}

			SToast.success(t('messages.success.questionRemoved'));
		} catch (err) {
			handleAxiosErrors(err);
		} finally {
			setInProgress(undefined);
		}
	};

	const seeResults = (item: ISavedQuestion) => {
		setInProgress(`results-${item._id}`);

		if (typeof generateAnswers === 'function') {
			generateAnswers({
				text: item.question,
				callback: () => setInProgress(undefined),
				onSuccess: () => setSeeResults(true),
			});
		}
	};

	React.useEffect(() => {
		loadQuestions();
	}, []);

	return (
		<SBox loading={questions.isLoading} empty={questions.docs.length === 0 ? 'questions' : undefined}>
			<Box marginBottom={3}>
				<Typography variant="h5" fontWeight={700}>
					{`${t('questions.savedQuestions')}:`}
				</Typography>
			</Box>
			<ul>
				{questions.docs?.map((item) => (
					<div className="accordion trends" id={item._id}>
						<div className="accordion-item QA-saved-list">
							<div className="QA-generated-questions">
								<div style={{ display: 'flex', alignItems: 'flex-start' }}>
									<li>{item.question}</li>
								</div>
								<Box className="QA-see-results" sx={{ gap: 1 }}>
									<SButton
										type="button"
										color="primary"
										size="small"
										onClick={() => seeResults(item)}
										startIcon={<FaMapSigns />}
										isLoading={inProgress === `results-${item._id}`}
									>
										{t('questions.results')}
									</SButton>
									<IconButton
										type="button"
										color="secondary"
										size="small"
										data-bs-toggle="collapse"
										data-bs-target={`#acordion-${item._id}`}
										aria-expanded="true"
										aria-controls={`acordion-${item._id}`}
									>
										<Icon icon="eye" />
									</IconButton>
									{ item.autor._id.toString() === userId.toString()
									&& (
										<IconButton
											type="button"
											color="secondary"
											size="small"
											onClick={() => deleteQuestion(item._id)}
										>
											{inProgress === `delete-question-${item._id}`
												? <CircularProgress color="inherit" size={10} />
												: <Icon icon="remove" />}
										</IconButton>
									) }
								</Box>
							</div>
							<Box className="QA-question-autor" sx={{ gap: 1, display: 'flex', alignItems: 'center' }}>
								<Typography variant="caption" color="rgba(0, 0, 0, 0.55)">{t('questions.by')}</Typography>
								<UserAvatar
									profile={item.autor}
									size="small"
									hasPopover
									initial={item.autor.user._nome || item.autor.user.nome}
									target={`popover_evaluator_${item.autor.user._id}`}
									hasHref
								/>
								<Typography variant="caption" color="rgba(0, 0, 0, 0.55)">
									{`${item.autor.user.nome} ${t('global.in', { date: new Date(item.createdAt) })}`}
								</Typography>
							</Box>
							<div
								id={`acordion-${item._id}`}
								className="accordion-collapse collapse"
								aria-labelledby="headingOne"
								data-bs-parent="#accordionExample"
							>
								<AccordionDetails sx={{ paddingX: 0 }}>
									{ item.responses.length > 0 && (<AnsersListComponent list={item.responses} haveBottomBorder={false} />) }
								</AccordionDetails>
							</div>
						</div>
					</div>
				))}
			</ul>
		</SBox>
	);
};

export default SavedQuestionList;
