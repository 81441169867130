// @ts-nocheck
import React, { Suspense, useState, useEffect } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import ModalHeader from '@modules/Modal/ModalHeader';
import type { IModal } from '@modules/Modal/types';
import { DialogBox } from '@modules/Modal/Components';
import { DynamicCircle } from '@components/DynamicCircle';
import RedactorText from '@components/Shared/RedactorText';
import Icon from '@components/Shared/Icon';
import FiltersNavBar from '@components/NavBar/FiltersNavBar';
import { SCheckbox } from '@components/DynamicForm/ComponentsCore';
import { IPaginatedRequest } from 'src/@types/IShared';
import { APITrends } from '@services/apis';
import { DialogContent, DialogTitle, IconButton } from '@mui/material';
import { W_GRID } from '@components/Grids/GenericGrid';
import { SBox } from '@components/Background/SBox';
import { handleAxiosErrors } from '@services/auth';

declare type TProps = IModal & WithTranslation;

const MModalDocuments = (props: TProps) => {
	const { t, routes, article, similarity } = props;
	const [documents, setDocuments] = useState({ docs: [], isLoading: true });
	const [contentType, setContentType] = useState({ article: true, patent: true });

	const loadDocuments = async () => {
		try {
			setDocuments((prevState) => ({ ...prevState, isLoading: true }));
			const response = await APITrends.getRelatedDocs({ query: { TEXT: article.abstract } });

			const docs = response.data.trends.map(({ artigo }) => ({
				title: artigo.title,
				href: artigo.href,
				abstract: artigo.abstract,

			}));

			setDocuments({ docs, isLoading: false });
		} catch (err) {
			handleAxiosErrors(err);
		}
	};

	const getFilterLabel = () => {
		if (contentType.article && !contentType.patent) {
			return `${t('trends.contentType.article')}s`;
		}
		if (contentType.patent && !contentType.article) {
			return `${t('trends.contentType.patent')}s`;
		}

		return t('global.all');
	};

	const onFilterHandler = ({ filter }: IPaginatedRequest) => {
		setContentType((prevState) => ({ ...prevState, [filter]: !prevState[filter] }));
	};

	useEffect(() => {
		loadDocuments();
	}, []);

	return (
		<DialogContent className="modal__documents" id="DocumentsDialog">
			<ModalHeader
				fullScreen
				BreadcrumbProps={{ className: W_GRID.W_GRID_2 }}
				BackModalProps={{ article, similarity}}
				pathParams={{
					title: article.title,
				}}
			/>
			<DialogBox className="s-dialog-title inner w-grid-2">
				<DialogTitle variant="h1">
					{t('trends.relatedDocs')}
				</DialogTitle>
			</DialogBox>
			<SBox loading={documents.isLoading}>
				<DialogBox className="s-dialog-box inner w-grid-2">
					<div
						dangerouslySetInnerHTML={{
							__html: t('trends.similarTo', { article: article.title }),
						}}
						style={{ color: 'rgba(0, 0, 0, 0.55)' }}
					/>
					<FiltersNavBar
						id="tree-map-documents-filters"
						params={{ filter: '' }}
						// onUpdate={onFilterHandler}
						sorts={[]}
						filters={[
							{
								value: 'article',
								label: (
									<SCheckbox
										id="article-filter"
										value="article"
										name="article"
										className="article"
										checked={contentType.article === true}
										label={`${t('trends.contentType.article')}s`}
										onChange={(e) => onFilterHandler({ filter: e.target.name })}
									/>
								),
							},
							{
								value: 'patent',
								label: (
									<SCheckbox
										id="patent-filter"
										value="patent"
										className="patent"
										name="patent"
										label={`${t('trends.contentType.patent')}s`}
										checked={contentType.patent === true}
										onChange={(e) => onFilterHandler({ filter: e.target.name })}
									/>
								),
							},
						]}
						label={getFilterLabel()}
					/>
					<DialogBox className="s-dialog-content">
						<ul className="list">
							{documents?.docs.map((doc) => (
								<li
									className="list-item"
								>
									<IconButton href={doc.href} target="_blank" rel="noreferrer" sx={{ textDecoration: 'none' }}>
										<DynamicCircle
											icon="fa fa-file-text"
											size="x-large"
											className="trends__category article fill"
											action={t('global.view')}
										/>
									</IconButton>
									<div
										aria-hidden
										className="container-content"
									>
										{/* @ts-ignore */}
										<div className="mb-2 document__item">
											<span className="trends__category">{t('trends.contentType.article')}</span>
											<p className="document__title">
												{doc.title}
												<a href={doc.href} target="_blank" rel="noreferrer" className="ml-1">
													<Icon icon="link" />
												</a>
											</p>
											<span className="infos">{article.date.year}</span>
										</div>
										{/* @ts-ignore */}
										<RedactorText html={doc.abstract} hasToggle className="content-text" />
									</div>
								</li>
							))}

						</ul>
					</DialogBox>
				</DialogBox>
			</SBox>
		</DialogContent>
	);
};

const Translation = withTranslation('common')(MModalDocuments);

const ModalDocuments = (props: IModal) => (
	<Suspense fallback={<div />}>
		<Translation {...props} />
	</Suspense>
);

export default ModalDocuments;
