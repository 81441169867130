/**
 * Disrupção:
	 - Definição do Problema = (<valor do slider> * 1,50)
	 - Dominio do Conhecimento = (<valor do slider> * 1,50)
 * Dificuldade = (<valor do slider> * 3,4) - 17
 * Impacto = (<valor do slider> * 3,2) - 17

 * Disrupção
		Incremental     [0.0 , 02.5]
		Breakthrough    [2.5 , 05.0]
		Radical         [5.0 , 07.5]
		Pesquisa Básica [7.5 , 10.0]
*/

const disruptionCalc = (dominioConhecimento: number, definicaoProblema: number) => {
	const dc = dominioConhecimento * 1.5;
	const dp = definicaoProblema * 1.5;

	return {
		dominioConhecimento: dc,
		definicaoProblema: dp,
	};
};

const disruptionValue = (dC: number, dP: number) => {
	if (dC >= 0 && dC <= 7.5) {
		if (dP >= 0 && dP <= 7.5) {
			return 'PESQUISA';
		} if (dP >= 7.5 && dP <= 15) {
			return 'BREAKTHROUGH';
		}
	} else if (dC >= 7.5 && dC <= 15) {
		if (dP >= 0 && dP <= 7.5) {
			return 'RADICAL';
		} if (dP >= 7.5 && dP <= 15) {
			return 'INCREMENTAL';
		}
	}

	return 'Pendente';
};

const difficultyCalc = (difficulty: number) => (difficulty * 3.2) - 17;

const difficultyValue = (difficulty: number) => {
	if (!difficulty && difficulty !== 0) {
		return 'Pendente';
	}

	if (difficulty >= -17 && difficulty < -6) {
		return 'BAIXA';
	} if (difficulty >= -6 && difficulty < 7) {
		return 'MÉDIA';
	} if (difficulty >= -7 && difficulty <= 17) {
		return 'ALTA';
	}

	return 'Pendente';
};

const impactCalc = (impact: number) => (impact * 3.4) - 17;

const impactValue = (impact: number) => {
	if (!impact && impact !== 0) {
		return 'Pendente';
	}

	if (impact >= -15 && impact < -5) {
		return 'BAIXO';
	}
	if (impact >= -5 && impact < 6) {
		return 'MÉDIO';
	}
	if (impact >= 5 && impact <= 17) {
		return 'ALTO';
	}

	return 'Pendente';
};

const returnAndTimeValues = (value: number) => {
	if (!value && value !== 0) {
		return 'Pendente';
	}

	if (value >= 0 && value <= 3) {
		return 'BAIXO';
	}
	if (value > 3 && value <= 7) {
		return 'MÉDIO';
	}
	if (value > 7 && value <= 10) {
		return 'ALTO';
	}

	return 'Pendente';
};

const DIValue = (d: number, i: number) => {
	if (!d && !i) {
		return 'Pendente';
	}
	if (!i) {
		return 'Pendente Impacto';
	}
	if (!d) {
		return 'Pendente Dificuldade';
	}
	if (d >= -17 && d <= 0) {
		if (i >= -14 && i < 0) {
			return 'Fill Ins';
		} if (i === 0) {
			return 'Fill Ins/Quick Wins';
		}
		return 'Quick Win';
	}
	if (i >= -14 && i <= 0) {
		return 'Hard Slogs';
	} if (i === 0) {
		return 'Hards Slogs/Major Projects';
	}
	return 'Major Projects';
};

export {
	DIValue,
	impactCalc,
	impactValue,
	disruptionCalc,
	difficultyCalc,
	disruptionValue,
	difficultyValue,
	returnAndTimeValues,
};
