import React from 'react';
import { minThreeDigits } from '@utils/ReactUtils';
import { Box, BoxProps, Tooltip } from '@mui/material';
import Icon from './Icon';

interface ActivityChipProps extends BoxProps {
	count?: number
	target?: string
	icon: React.ReactNode | string
	tooltip: string
}

const ActivityChip = ({
	count = 0, target, icon, tooltip, className, onClick, sx, ...rest
}: ActivityChipProps) => {
	const [open, setOpen] = React.useState(false);
	const handleClose = () => {
		setOpen(false);
	};

	const handleOpen = () => {
		if (tooltip) { setOpen(true); }
	};

	return (
		<Tooltip title={tooltip} placement="top" arrow open={open} onClose={handleClose} onOpen={handleOpen}>
			<Box
				id={target}
				className={`activity-count ${className || ''}`}
				onClick={(e) => (typeof onClick === 'function' ? onClick(e) : e.stopPropagation())}
				sx={{
					color: '#fff',
					alignItems: 'center',
					justifyContent: 'center',
					display: 'flex',
					transition: 'all 0.277s 0s ease-in-out',
					position: 'relative',
					padding: '0.188rem 0.375rem',
					letterSpacing: '0.01em',
					lineHeight: '1rem',
					gap: 1,
					cursor: typeof onClick === 'function' ? 'pointer' : 'auto',
					'&.dark': {
						color: '#000',
						borderColor: '#000',
					},
					...sx,
				}}
				{...rest}
			>
				{typeof icon === 'string' ? <Icon icon={icon} /> : icon}
				<span>
					{minThreeDigits(count)}
				</span>
			</Box>
		</Tooltip>
	);
};

export { ActivityChip };
