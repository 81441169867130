// @ts-nocheck
import { Form, SFormGroup, SInputLabel } from '@components/DynamicForm/ComponentsCore';
import { SButton } from '@components/Buttons';
import { STextField } from '@forms/Components';
import * as Yup from 'yup';
import ImageInput from '@components/Inputs/ImageInput';
import SpinnerLDSRipple from '@components/Shared/SpinnerLDSRipple';
import { APIOrganizations } from '@services/apis';
import React, { MouseEvent, Suspense, useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Creators } from '@actions';
import { getValidationErrors } from '@utils/yup';
import { handleAxiosErrors } from '@services/auth';
import { IRootState } from 'src/store';
import { isEmpty } from '@utils/ReactUtils';
import { TObject } from 'src/@types/IShared';
import { maxLength } from '@constants/GLOBAL';
import { SSelect } from '@components/DynamicForm/Components/SSelect';
import { useNavigate } from 'react-router-dom';
import { Box, Grid, Typography } from '@mui/material';

interface IOrganizationState {
	membersQtd?: { value: number, label: string }
	nome: string
	image?: string
	invites?: Array<{ value: string, label: string }>
}

interface IOwnProps {
	show: boolean
	path?: string
}

declare type TProps = IOwnProps & WithTranslation

const _OrganizationSetup = (props: TProps) => {
	const { t, show, path } = props;
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [inProgress, setInProgress] = useState<string | undefined>(undefined);
	const [errors, setErrors] = useState<TObject>({});
	const { userOrganizationProfile } = useSelector((state: IRootState) => state);
	const [organization, setOrganization] = useState<IOrganizationState>({
		nome: userOrganizationProfile?.organization?.nome,
		image: userOrganizationProfile?.organization?.image,
		invites: [],
		membersQtd: userOrganizationProfile?.organization?.membersQtd || 5,
	});

	const isAdministrator = userOrganizationProfile?.user?.isAdministrator;

	const generatorArray = (li: number, ls: number, label: string) => {
		const array = [];
		for (li; li <= ls; li++) {
			array.push({
				value: li,
				label: `${li} ${label}`,
			});
		}
		return array;
	};

	const options = generatorArray(5, 30, t('global.members'));

	const onErrorHandler = (field: string) => {
		if (!isEmpty(errors)) delete errors[field];
	};

	const onOrganizationHandler = (org: IOrganizationState) => {
		Object.keys(org).forEach((key) => onErrorHandler(key));
		setOrganization({
			...organization,
			...org,
		});
	};

	const onSubmitOrg = async (event: MouseEvent<HTMLButtonElement>) => {
		event?.preventDefault();

		const { membersQtd, nome } = organization;

		const data = { membersQtd, nome };

		try {
			setInProgress('submit');
			const schema = Yup.object().shape({
				nome: Yup.string().required(t('messages.requiredField')),
				membersQtd: Yup.string().required(t('messages.requiredField')),
			});

			await schema.validate(data, {
				abortEarly: false,
			});

			await APIOrganizations.patchOrganization(userOrganizationProfile.currentTenant, data);

			navigate(`${path}?step=invites`);
		} catch (err: any) {
			if (err instanceof Yup.ValidationError) {
				setErrors(getValidationErrors(err));
			} else handleAxiosErrors(err);
		} finally {
			setInProgress(undefined);
		}
	};

	const onOrganizationImageHandler = ({ formData }) => {
		setInProgress('image-organization-account-setup');
		dispatch(Creators.USER_ORGANIZATION.handleOrganizationImage(
			{
				formData,
				onSuccess: (image: string) => setOrganization((prevState) => ({ ...prevState, image })),
				callback: () => setInProgress(undefined),
			},
		));
	};

	if (!show) {
		return null;
	}

	return (
		<Form className="s-form">
			<Grid container textAlign="center" spacing={2}>
				<Grid item xs={12}>
					<Typography variant="h1">
						{t('register.setUpOrganization')}
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<Typography variant="body1">
						{t('register.fillSomeDetails')}
					</Typography>
				</Grid>
			</Grid>
			<Box marginY={3}>
				<ImageInput
					id="image-organization-account-setup"
					image={organization?.image}
					initial={organization.nome}
					onClick={onOrganizationImageHandler}
					disabled={!isAdministrator}
					className="justify-content-center"
					isLoading={inProgress === 'image-organization-account-setup'}
				/>
			</Box>

			<SFormGroup>
				<SInputLabel htmlFor="organization-setup-name" error={typeof errors.nome === 'string'}>
					{t('organization.name')}
				</SInputLabel>
				<STextField
					id="organization-setup-name"
					value={organization.nome}
					placeholder={t('form.placeholder.organization.name')}
					autoFocus
					name="nome"
					maxLength={maxLength.orgName}
					pattern="^[a-zA-ZÀ-ÖØ-öø-ÿ0-9 ]+$"
					autoComplete="off"
					onChange={(e) => onOrganizationHandler({ nome: e.target.value })}
					error={typeof errors.nome === 'string'}
					helperText={errors.nome}
				/>
			</SFormGroup>
			<SFormGroup>
				<SInputLabel htmlFor="quantity-members-select">
					{t('settings.numberOfMembers')}
				</SInputLabel>
				<SSelect
					id="quantity-members-select"
					fullWidth
					value={organization.membersQtd}
					options={options}
					onChange={(e) => onOrganizationHandler({ membersQtd: e.target.value })}
				/>
			</SFormGroup>
			<SButton
				type="button"
				variant="outlined"
				color="primary"
				onClick={onSubmitOrg}
				isLoading={inProgress === 'submit'}
				error={errors.nome}
				disabled={organization.nome?.length < 1 || errors.nome}
			>
				{t('global.continue')}
			</SButton>
		</Form>
	);
};
const Translation = withTranslation('common')(_OrganizationSetup);
const OrganizationSetup = (props: IOwnProps) => (
	<Suspense fallback={<SpinnerLDSRipple />}>
		<Translation {...props} />
	</Suspense>
);

export { OrganizationSetup };
