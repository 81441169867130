// @ts-nocheck
import React from 'react';
import { withTranslation } from 'react-i18next';
import JoditEditor, { JoditProps } from 'jodit-react';
import i18next from 'i18next';
import { Box, FormHelperText } from '@mui/material';
import { SInputStatus } from './DynamicForm/Components/SInputStatus';

export const DLanguage = {
	'pt-BR': 'pt_br',
	'en-US': 'en',
};

interface IRichTextEditorProps extends JoditProps {
	name?: string
	disabled?: boolean
	isLoading?: boolean
	isAsync?: boolean
	minHeight?: string
	placeholder?: string
	helperText?: string
	error?: boolean
}

class JoditRichTextEditor extends React.Component<IRichTextEditorProps> {
	constructor(props) {
		super(props);
		const {
			i18n, t,
			onPaste,
			toolbarAdaptive,
			showCharsCounter,
			showWordsCounter,
			showXPathInStatusbar,
			disabled,
			toolbar = true,
			onBlur,
			onFocus,
			onKeyDown,
			placeholder,
		} = props;

		// this.value = value;
		this.lang = i18next.language === 'pt-BR' ? 'pt_br' : 'en';

		this.config = {
			// enter: 'BR',
			showCharsCounter: typeof showCharsCounter !== 'undefined' ? showCharsCounter : true,
			showWordsCounter: typeof showWordsCounter !== 'undefined' ? showWordsCounter : true,
			showXPathInStatusbar: typeof showXPathInStatusbar !== 'undefined' ? showXPathInStatusbar : true,
			readonly: disabled,
			disabled,
			// i18n,
			language: this.lang,
			toolbar,
			cleanHTML: {
				timeout: 300,
				removeEmptyElements: true,
				fillEmptyParagraph: true,
				replaceNBSP: true,
				replaceOldTags: {
					i: 'em',
					b: 'strong',
				},
				allowTags: false,
				denyTags: false,
				removeOnError: true,
				safeJavaScriptLink: true,
			},
			placeholder: placeholder || t('richTextEditor.placeholder.default'),
			fullsize: false,

			afterPaste: (event) => (typeof onPaste === 'function' ? onPaste(event) : null),
			buttons: [
				'bold', 'strikethrough', 'underline', 'eraser', 'italic', 'ul', 'ol', 'outdent', 'indent',
				'fontsize', 'brush', 'paragraph', 'image', 'video', 'table', 'align',
			],
			toolbarAdaptive,
			toolbarSticky: false,
			toolbarStickyOffset: 0,
			// minWidth: props.minWidth || "400px",
			width: '100%',
			// @ts-ignore
			minHeight: props.minHeight,
			// defaultActionOnPaste: "insert_only_text",
			defaultActionOnPaste: 'insert_clear_html',
			askBeforePasteHTML: false,
			askBeforePasteFromWord: false,
			// extraButtons: [extraButtonTranslate],
			events: {
				blur: () => (typeof onBlur === 'function' ? onBlur() : null),
				focus: () => (typeof onFocus === 'function' ? onFocus() : null),
				keydown: (event) => (typeof onKeyDown === 'function' ? onKeyDown(event) : null),
				// paste: (event) => (typeof onPaste === 'function'
				//   ? setTimeout(() => {
				//     onPaste(event);
				//   }, 500) : null),
			},
		};
	}

	shouldComponentUpdate(nextProps, nextState) {
		if (
			typeof nextProps.forceUpdate === 'boolean'
			&& nextProps.forceUpdate === true) {
			this.config.disabled = nextProps.disabled;
			this.config.readonly = nextProps.disabled;
			this.config.toolbar = nextProps.toolbar;
			return false;
		}
		return true;
	}

	render() {
		const {
			onChange, value, disabled, forwardedRef,
		} = this.props;

		// all options from https://xdsoft.net/jodit/doc/

		return (
			<JoditEditor
				ref={forwardedRef}
				value={value}
				config={this.config}
				onChange={(e) => {
					if (typeof onChange === 'function' && !disabled) {
						onChange(e);
					}
				}}
				tabIndex="-1"
			/>
		);
	}
}

const withAdvanced = (Component) => {
	const forwardRef = function (props, ref) {
		return (
			<Component
				forwardedRef={ref}
				{...props}
			/>
		);
	};

	return React.forwardRef(forwardRef);
};

const RRichTextEditor = (props: IRichTextEditorProps) => {
	const {
		isLoading, isAsync, error, helperText, id,
	} = props;

	return (
		<Box className={`s-jodit ${error ? 'Mui-error' : ''}`}>
			<JoditRichTextEditor {...props} />
			<SInputStatus isLoading={isLoading} isAsync={isAsync} />
			<Box sx={{ display: 'flex' }}>
				{helperText && (
					<FormHelperText id={`helper-text-${id}`} error={error}>
						{helperText}
					</FormHelperText>
				)}
			</Box>
		</Box>
	);
};

const Editor = withAdvanced(RRichTextEditor);

const RichTextEditor = withTranslation('common')(Editor);

export default RichTextEditor;
