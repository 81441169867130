// @ts-nocheck
import {
	call,
	takeLatest,
	delay,
} from 'redux-saga/effects';
import { APIGlobal, APIOrganizations } from '@services/apis';
import { handleAxiosErrors } from '@services/auth';
import { AxiosResponse } from 'axios';
import { SToast } from '@modules/SToast';
import { Types } from '../actionsTypes';

function* getUsers(action) {
	const { queryParams, onSuccess } = action.params;
	try {
		yield delay(1000);
		const response: AxiosResponse = yield call(APIOrganizations.getUsers, queryParams);

		if (typeof onSuccess === 'function') {
			yield call(onSuccess, response.data);
		}
	} catch (err) {
		yield call(SToast.error, err);
	}
}

function* getParticipants(action) {
	const { onSuccess, queryParams } = action.params;
	try {
		yield delay(1000);
		const response: AxiosResponse = yield call(APIOrganizations.getParticipants, queryParams);

		if (typeof onSuccess === 'function') {
			yield call(onSuccess, { docs: response.data });
		}
	} catch (err) {
		yield call(handleAxiosErrors, err);
	}
}

function* getTags(action) {
	const { onSuccess } = action.params;
	try {
		const response: AxiosResponse = yield call(APIGlobal.getTags);

		if (typeof onSuccess === 'function') {
			yield call(onSuccess, { docs: response.data });
		}
	} catch (err: any) {
		handleAxiosErrors(err);
	}
}

export default function* () {
	yield takeLatest(Types.SHARED.GET_USERS, getUsers);
	yield takeLatest(Types.SHARED.GET_PARTICIPANTS, getParticipants);
	yield takeLatest(Types.SHARED.GET_TAGS, getTags);
}
