import { IPaginatedRequest } from 'src/@types/IShared';
import { IPlatform } from 'src/@types/IPlatform';
import { axiosInstance } from '@services/AxiosInstance';
import { getQueryParams } from './utils';

export declare type TPlatformRequest = 'challenge' | 'brainstorm';

const APIPlatforms = {
	getTemplates(params: IPaginatedRequest & {ids?: Array<string>}) {
		const queryParams = getQueryParams(params || {});
		return axiosInstance.get(`/templates/platform${queryParams}`);
	},
	getList(params: IPaginatedRequest, type?: TPlatformRequest) {
		let queryParams = getQueryParams(params);
		if (type) {
			queryParams += `&type=${type}`;
		}
		return axiosInstance.get(`/platforms${queryParams}`);
	},
	getFavorites(params: IPaginatedRequest, type: TPlatformRequest) {
		let queryParams = getQueryParams(params);
		if (type) {
			queryParams += `&type=${type}`;
		}
		return axiosInstance.get(`/favorites/platforms${queryParams}`);
	},
	getDrafts(params: IPaginatedRequest, type: TPlatformRequest) {
		let queryParams = getQueryParams(params);
		if (type) {
			queryParams += `&type=${type}`;
		}
		return axiosInstance.get(`/drafts/platform${queryParams}`);
	},
	// @ts-ignore
	postCreatePlatform(platform?: {nome: string, type: string, templateId?: string}) {
		return axiosInstance.post('/platforms', platform);
	},
	getIdeas(platformId: string, params: IPaginatedRequest) {
		const queryParams = getQueryParams(params);
		return axiosInstance.get(`/platforms/${platformId}/ideas${queryParams}`);
	},
	getCoauthors(platformId: string) {
		return axiosInstance.get(`/platforms/${platformId}/coauthors`);
	},
	// @ts-ignore
	postCreateTemplate(newTemplate) {
		return axiosInstance.post('/templates/platform', newTemplate);
	},
	putPublish(platformId: string, platform: IPlatform) {
		return axiosInstance.put(`/platforms/${platformId}/publish`, platform);
	},
	patchPlatform(platformId: string, platform: IPlatform) {
		return axiosInstance.patch(`/platforms/${platformId}`, platform);
	},
	postDuplicate(platformId: string) {
		return axiosInstance.post(`/platforms/${platformId}/duplicate`, {});
	},
	getBadges(platformId: string, ideaId?: string) {
		const queryParams = typeof ideaId !== 'undefined' && ideaId.length > 0 ? `?ideaId=${ideaId}` : '';
		return axiosInstance.get(`/platforms/${platformId}/badges${queryParams}`);
	},
	getPlatformsTypes() {
		return axiosInstance.get('/platforms/types');
	},
	get(platformId: string) {
		return axiosInstance.get(`/platforms/${platformId}`);
	},
	postAttachments(platformId: string, formData: any) {
		return axiosInstance.post(`/platforms/${platformId}/upload`, formData);
	},
	postTrendsPlatform(platformId: string) {
		return axiosInstance.post(`/platforms/${platformId}/trends`, {
			test: true,
		});
	},
	postCover(platformId: string, formData: any) {
		return axiosInstance.post(`/platforms/${platformId}/upload_cover`, formData);
	},
	deletePlatform(platformId: string) {
		return axiosInstance.delete(`/platforms/${platformId}`);
	},

	postJoinPlatform(platformId: string, password?: string) {
		return axiosInstance.post(`/platforms/${platformId}/join`, { senha: password });
	},
	postAcceptInvite(platformId: string, password?: string) {
		return axiosInstance.post(`/platforms/${platformId}/accept_invite`, { senha: password });
	},
	getParticipants(platformId: string, params: IPaginatedRequest) {
		const queryParams = getQueryParams(params);
		return axiosInstance.get(`/platforms/${platformId}/participants${queryParams}`);
	},
	getPlatformsJoined(ideaId: string, params: IPaginatedRequest & { type: TPlatformRequest }) {
		let queryParams = getQueryParams(params);
		if (params.type) {
			queryParams += `&type=${params.type}`;
		}
		return axiosInstance.get(`/platforms/joined${queryParams}&ideaId=${ideaId}`);
	},
	postLeavePlatform(platformId: string) {
		return axiosInstance.post(`/platforms/${platformId}/leave`, {});
	},
	postSecretPassword(platformId: string, password: string) {
		return axiosInstance.post(`/platforms/${platformId}/confirm_password`, { senha: password });
	},
	postArchivePlatform(platformId: string) {
		return axiosInstance.post(`/platforms/${platformId}/archive`, {});
	},
	postUnarchivePlatform(platformId: string) {
		return axiosInstance.post(`/platforms/${platformId}/unarchive`, {});
	},
	// @ts-ignore
	getPlatformTrendsList(platformId: string, limit: number, sort?: string, contentType?: string) {
		let filter = '';
		if (contentType && contentType?.length > 0 && contentType !== 'all') filter += `&contentType=${contentType}`;
		if (sort && sort?.length > 0) filter += `&sort=${sort}`;
		return axiosInstance.get(`/platforms/${platformId}/trends?limit=${limit}${filter}`);
	},
	getPlatformTrendsNLPs(platformId: string) {
		return axiosInstance.get(`/platforms/${platformId}/trends/nlps`);
	},
	putTemplatePlatform(platformId: string, templateId: string) {
		return axiosInstance.put(`/platforms/${platformId}/change_template`, { template: templateId });
	},
	getDashboard(platformId: string) {
		return axiosInstance.get(`/platforms/${platformId}/dashboard`);
	},
	getEvaluatedIdeas(platformId: string, matrix: string, params: IPaginatedRequest) {
		let queryParams = `?filter=${params?.filter}`; // required
		if (typeof params?.sort === 'string' && params?.sort.length > 0) queryParams += `&sort=${params?.sort}`;
		if (typeof params?.query === 'string' && params?.query.length > 0) queryParams += `&query=${encodeURIComponent(params?.query.trim())}`;

		return axiosInstance.get(`/platforms/${platformId}/dashboard/${matrix}${queryParams}`);
	},
};

export default APIPlatforms;
