// @ts-nocheck
import React, { Suspense, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import ModalHeader from '@modules/Modal/ModalHeader';
import {
	Form, SInputLabel, SFormGroup,
} from '@components/DynamicForm/ComponentsCore';
import { HelpButton, SButton } from '@components/Buttons';
import { STextField } from '@forms/Components';
import { isEmpty } from '@utils/ReactUtils';
import { getValidationErrors } from '@utils/yup';
import { FLOWS, maxLength } from '@constants/GLOBAL';
import type { IModal } from '@modules/Modal/types';
import { IRootState } from 'src/store';
import { TemplateDetails } from '@components/Background/TemplateDetails';
import { IBadge } from 'src/@types/IBadge';
import { handleAxiosErrors } from '@services/auth';
import APIBadges from '@services/apis/badges';
import { DialogBox } from '@modules/Modal/Components';
import {
	Box, DialogActions, DialogContent, DialogTitle, InputLabel,
} from '@mui/material';
import { SSelect } from '@components/DynamicForm/Components/SSelect';
import ModalFeedback, { IFeedbackProps } from '@components/Modals/ModalFeedback';
import { useTour } from '@reactour/tour';
import { SToast } from '@modules/SToast';
import { W_GRID } from '@components/Grids/GenericGrid';
import { ColorPalette } from '@components/Shared/ColorPalette';
import { SBox } from '@components/Background/SBox';
import { STour } from '@components/STour';
import { Creators } from '../../../store/ducks/actionsTypes';

declare type TProps = IModal & WithTranslation;

const MModalBadge = (props: TProps) => {
	const { t } = props;
	const dispatch = useDispatch();
	const tour = useTour();
	const {
		platforms, badges, modal, shared,
	} = useSelector((state: IRootState) => state);
	const [errors, setErrors] = useState({});
	const [activeField, setActiveField] = useState(undefined);
	const { currentModel, pagingPaths, action } = useSelector(
		(state) => state.modal?.ModalProps,
	);

	const flow = platforms?.type?.toLowerCase() || modal.flow;
	const [feedbackProps, setFeedbackProps] = useState<IFeedbackProps>({ open: false });

	let badge = badges;

	// PAGINATION

	if (currentModel) {
		badge = currentModel;
	}

	const TOUR_STEPS = [
		'template.create', 'template.update'
	].map((key) => ({
		id: key.replaceAll('.', '-'),
		title: t(`tour.${key}.title`),
		action: t(`tour.${key}.action`),
		children: t(`tour.${key}.description`),
		imagePath: undefined,
	}));

	const disabled = ['insert', 'view'].includes(action)
		|| ['delete', 'insert', 'reset'].includes(activeField) || !badge;

	const onTourHandler = () => {
		if (shared.tour === FLOWS.CHALLENGE) {
			tour.setCurrentStep(10);
		}
		tour.setIsOpen(false);
	};

	const onCreateHandler = async () => {
		try {
			if (!isEmpty(errors)) return;
			setActiveField('create');

			const newBadge = {
				nome: badges?.nome,
				descricao: badges?.descricao,
				dificuldade: badges?.dificuldade,
				cor: badges?.cor,
			};
			const schema = Yup.object().shape({
				nome: Yup.string().required(t('messages.requiredField')),
				descricao: Yup.string().required(t('messages.requiredField')),
				dificuldade: Yup.string().required(t('messages.requiredField')),
			});

			await schema.validate(newBadge, {
				abortEarly: false,
			});

			await APIBadges.postCreateBadge({ platformId: platforms._id, ...newBadge });
			dispatch(Creators.MODAL.backModal());
			SToast.success(t('messages.success.badge.created'));
		} catch (err) {
			if (err instanceof Yup.ValidationError) {
				setErrors(getValidationErrors(err));
			} else handleAxiosErrors(err);
		} finally {
			setActiveField(undefined);
		}
	};

	const onErrorHandler = (field) => {
		if (!isEmpty(errors)) delete errors[field];
	};

	const onBadgeHandler = (key, value) => {
		setActiveField(key);
		onErrorHandler(key);
		dispatch(
			Creators.BADGES.handleBadge({
				badgeId: badge._id,
				key,
				value,
				callback: () => setActiveField(undefined),
				onSuccess: () => {
					const index = platforms?.medalhas.findIndex((item) => item._id === badge._id);
					if (index > -1) {
						platforms?.medalhas.splice(index, 1, { ...badges, [key]: value });
						dispatch(Creators.PLATFORMS.updatePlatform({ medalhas: platforms?.medalhas }));
					}
				},
				onError: (messages) => setErrors({ ...errors, ...messages }),
			}),
		);
	};

	const onDeleteHandler = () => {
		setFeedbackProps({
			open: true,
			title: t('feedback.remove.title-a', { name: `${t('global.badge').toLowerCase()} ${badge?.nome}` }),
			rightButtonText: t('global.remove'),
			color: 'error',
			onRightButton: () => {
				setFeedbackProps((prevState) => ({ ...prevState, isLoading: true }));
				dispatch(
					Creators.BADGES.deleteBadge({
						badgeId: badge._id,
						platformId: platforms._id,
						templateId: badge.template._id,
						onSuccess: () => {
							onTourHandler();
							dispatch(Creators.BADGES.getBadgesByPlatform({
								platformId: platforms._id,
								onSuccess: (response) => dispatch(Creators.PLATFORMS.updatePlatform({ medalhas: response.docs })),
							}));
						},
						callback: () => setFeedbackProps((prevState) => ({ ...prevState, isLoading: false })),
					}),
				);
			},
		});
	};

	const difficultyOptions = {
		dificil: { value: 'dificil', label: t('form.badges.level.dificil') },
		medio: { value: 'medio', label: t('form.badges.level.medio') },
		facil: { value: 'facil', label: t('form.badges.level.facil') },
	};

	const onResetHandler = () => {
		setFeedbackProps({
			...feedbackProps,
			title: t('feedback.reset.title'),
			text: t('feedback.reset.content'),
			rightButtonText: t('global.confirm'),
			open: true,
			color: 'success',
			onRightButton: () => {
				setFeedbackProps((prevState) => ({ ...prevState, isLoading: true }));
				dispatch(Creators.BADGES.restoreBadge({
					badgeId: badge._id,
					platformId: platforms.id,
					onSuccess: () => setFeedbackProps({ open: false }),
					callback: () => setFeedbackProps((prevState) => ({ ...prevState, isLoading: false })),
				}));
			},
		});
	};

	const onInsertHandler = () => {
		setActiveField('insert');
		dispatch(Creators.BADGES.insertBadge({
			platformId: platforms._id,
			templateId: badge._id,
			onSuccess: (response: IBadge) => {
				dispatch(Creators.BADGES.setBadge(response));
				dispatch(Creators.MODAL.handleParams('action', 'edit'));
			},
			callback: () => setActiveField(undefined),
		}));
	};

	const getLoading = (key) => activeField === key && action !== 'create';

	const onBackHandler = (params) => {
		onTourHandler();
		dispatch(Creators.MODAL.backModal(params));
	};

	return (
		<DialogContent id="BadgeDialog">
			<ModalHeader
				hideClose
				fullScreen
				icon={flow}
				BreadcrumbProps={{ className: W_GRID.W_GRID_2 }}
				hideBack={pagingPaths}
				onBack={onBackHandler}
				pathParams={{
					rootPath: platforms?.nome, // isolated path
					platform: platforms?.nome,
					type: platforms?.type,
					badge: badge?.nome || '...',
				}}
				BackModalProps={{ anchor: '#badges-modal-create' }}
			/>
			<DialogBox className="s-dialog-title inner w-grid-2">
				<DialogTitle variant="h1">{t('form.badge.details')}</DialogTitle>
			</DialogBox>

			<DialogBox className="s-dialog-box inner w-grid-2">
				<SBox loading={typeof badge === 'undefined' || !badge || isEmpty(badge)} className="s-form">
					<Form>
						<SFormGroup>
							<Box className="s-label-group s-label">
								<InputLabel>
									{t('global.template')}
								</InputLabel>
								<HelpButton color="secondary" className="tour" placement="right-start">
									<STour {...TOUR_STEPS?.[badge.template ? 1 : 0]} hideActions />
								</HelpButton>
							</Box>

							<TemplateDetails
								onReset={action === 'edit' && onResetHandler}
								name={badge.template?.nome || badge.nome || '...'}
								color={badge.template?.cor || badge.cor}
								icon="badge"
							/>
						</SFormGroup>
						<SFormGroup>
							<SInputLabel htmlFor="badge-name" error={typeof errors?.nome === 'string'}>
								{t('form.badge.name')}
							</SInputLabel>
							<STextField
								id="badge-name"
								className="mr-3"
								name="nome"
								variant="standard"
								innerClass="border-0"
								maxLength={maxLength.default}
								autoFocus
								isAsync={action !== 'create'}
								isLoading={getLoading('nome')}
								value={badge.nome}
								error={typeof errors?.nome === 'string'}
								helperText={errors?.nome}
								disabled={disabled}
								onChange={(e) => onBadgeHandler('nome', e.target.value)}
								placeholder={t('form.badge.placeholder.name')}
							/>
						</SFormGroup>
						<SFormGroup className="w-25">
							<SInputLabel htmlFor="badge-difficulty">
								{t('form.difficulty')}
							</SInputLabel>
							<SSelect
								id="badge-difficulty"
								fullWidth
								isLoading={getLoading('dificuldade')}
								isAsync={action !== 'create'}
								value={difficultyOptions[badge?.dificuldade].value}
								name="dificuldade"
								disabled={disabled}
								options={Object.values(difficultyOptions)}
								onChange={(e) => onBadgeHandler('dificuldade', e.target.value)}
							/>

						</SFormGroup>

						<SFormGroup>
							<SInputLabel htmlFor="badge-description" error={typeof errors?.descricao === 'string'}>
								{t('form.summary')}
							</SInputLabel>
							<STextField
								id="badge-description"
								multiline
								isAsync={action !== 'create'}
								name="descricao"
								isLoading={getLoading('descricao')}
								value={badge.descricao}
								disabled={disabled}
								placeholder={t('form.placeholder.description')}
								rows={8}
								maxLength={maxLength.default500}
								error={typeof errors?.descricao === 'string'}
								helperText={errors?.descricao}
								onChange={(e) => onBadgeHandler('descricao', e.target.value)}
							/>
						</SFormGroup>
						<SFormGroup>
							<SInputLabel htmlFor="badge-color">{t('global.color')}</SInputLabel>
							<ColorPalette
								id="badge-color"
								value={badge.cor}
								onChange={(e) => onBadgeHandler('cor', e.target.value)}
								disabled={disabled}
							/>
						</SFormGroup>
						{action && (
							<DialogActions className="s-dialog-actions">
								{action === 'create' && (
									<SButton
										type="button"
										onClick={onCreateHandler}
										color="primary"
										variant="outlined"
										fullWidth
										size="large"
										isLoading={activeField === 'create'}
										error={!isEmpty(errors)}
									>
										{t('form.badges.create')}
									</SButton>
								)}
								{action === 'insert' && (
									<SButton
										type="button"
										onClick={onInsertHandler}
										variant="outlined"
										color="primary"
										fullWidth
										size="large"
										isLoading={activeField === 'insert'}
									>
										{t('form.badges.insert')}
									</SButton>
								)}
								{action === 'edit' && (
									<SButton
										type="button"
										size="large"
										onClick={onDeleteHandler}
										color="error"
										fullWidth
										variant="outlined"
									>
										{t('form.badges.remove')}
									</SButton>
								)}
							</DialogActions>
						)}
					</Form>
				</SBox>
			</DialogBox>
			{feedbackProps.open && (
				<ModalFeedback
					id="badge"
					onRequestClose={() => setFeedbackProps({ open: false })}
					{...feedbackProps}
				/>
			)}
		</DialogContent>
	);
};

const Translation = withTranslation('common')(MModalBadge);
const ModalBadge = (props: IModal) => (
	<Suspense fallback={<div />}>
		<Translation {...props} />
	</Suspense>
);
export default ModalBadge;
