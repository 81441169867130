// @ts-nocheck
import React, { Suspense, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';
import ModalHeader from '@modules/Modal/ModalHeader';
import { UserAvatar } from '@components/Avatar/UserAvatar';
import { DialogBox } from '@modules/Modal/Components';
import { IRootState } from 'src/store';
import { FLOWS } from '@constants/GLOBAL';
import { EmptyState } from '@components/Shared/EmptyState';
import SpinnerLDSRipple from '@components/Shared/SpinnerLDSRipple';
import { Creators } from '@actions';
import { DialogContent, DialogTitle } from '@mui/material';

const MModalSponsorsForFeature = (props) => {
	const { t } = props;
	const dispatch = useDispatch();
	const ModalProps = useSelector((state: IRootState) => state.modal?.ModalProps);
	const [sponsorships, setSponsorships] = useState({ name: '', supporters: [], isLoading: true });

	const loadSupporters = () => {
		setSponsorships({ ...sponsorships, isLoading: true });
		dispatch(Creators.IDEAS.getSupporters({
			featureId: ModalProps.featureId,
			onSuccess: (supporters) => setSponsorships({
				...supporters,
				isLoading: false,
			}),
		}));
	};

	useEffect(() => {
		loadSupporters();
	}, []);

	return (
		<DialogContent
			id="SponsorsDialog"
		>
			<ModalHeader
				icon={FLOWS.IDEA}
				hasButtonBack={false}
			/>
			<DialogBox className="s-dialog-box w-100">
				<DialogTitle variant="h6">
					{`${t('global.sponsorships')} ${t('global.of')}`}
					<strong className="" style={{ paddingLeft: '.5rem' }}>
						{sponsorships?.name || '...'}
					</strong>
				</DialogTitle>
				<div className="modal__content">
					{!sponsorships || sponsorships.isLoading || !sponsorships?.supporters
						? <SpinnerLDSRipple />
						: (
							<ul className="list mt-20px vh-50 mb-20px">
								{sponsorships?.supporters?.length === 0 ? <EmptyState type="teams" />
									: sponsorships?.supporters?.map((item) => (
										<li className="pb-2 pt-2" key={item._id}>
											<div className="align-items-center p-0 d-flex">
												<UserAvatar profile={item.userOrganizationProfile} />
												<span className="font-light ml-2 word-ellipsis">
													{item.userOrganizationProfile.user._nome || item.userOrganizationProfile.user.nome}
												</span>
											</div>
											<div className="align-items-end font-light d-flex ml-auto mr-1">
												<span className="mr-2">{t('global.in', { date: new Date(item.createdAt) })}</span>
											</div>
										</li>
									))}
							</ul>
						)}
				</div>
			</DialogBox>
		</DialogContent>
	);
};

const Translation = withTranslation('common')(MModalSponsorsForFeature);
const ModalSponsorsForFeature = (props) => (
	<Suspense fallback={<div />}>
		<Translation {...props} />
	</Suspense>
);
export default ModalSponsorsForFeature;
