// @ts-nocheck
import React, { Suspense, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { WithTranslation, withTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { isEmpty } from '@utils/ReactUtils';
import { FLOWS, maxLength } from '@constants/GLOBAL';
import { APIIdeas, APIPlatforms } from '@services/apis';
import type { IModal } from '@modules/Modal/types';
import { getValidationErrors } from '@utils/yup';
import { IRootState } from 'src/store';
import { TObject } from 'src/@types/IShared';
import { Creators } from '@actions';
import {
	Form, SFormGroup, SInputLabel,
} from '@components/DynamicForm/ComponentsCore';
import { SButton } from '@components/Buttons';
import { STextField } from '@forms/Components';
import {
	DialogBox,
} from '@modules/Modal/Components';
import { handleAxiosErrors } from '@services/auth';
import {
	Button, DialogActions, DialogContent, DialogTitle,
} from '@mui/material';
import { SToast } from '@modules/SToast';

declare type TProps = IModal & WithTranslation;

const MModalTemplateCreate = (props: TProps) => {
	const { t } = props;
	const container = useSelector((state: IRootState) => state.modal?.ModalProps);
	const dispatch = useDispatch();
	const { _id } = container;
	const [inProgress, setInProgress] = useState(false);
	const [errors, setErrors] = useState<TObject>({});

	const [template, setTemplate] = useState({ nome: '', descricao: '', color: '' });

	const onErrorHandler = (key: string) => {
		if (!isEmpty(errors)) delete errors[key];
	};

	const onChangeHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
		onErrorHandler(e.target.name);
		setTemplate({
			...template,
			[e.target.name]: e.target.value,
		});
	};

	const handleSubmit = async (event) => {
		try {
			event?.preventDefault();
			if (!isEmpty(errors)) return;
			setInProgress(true);
			const { nome, descricao } = template;
			const fields = { nome, descricao };
			switch (container.flow) {
				case FLOWS.IDEA:
					fields.ideiaId = _id;
					break;
				case FLOWS.CHALLENGE:
				case FLOWS.BRAINSTORM:
					fields.plataformaId = _id;
					break;
				default:
					break;
			}

			const schema = Yup.object().shape({
				nome: Yup.string().required(t('messages.requiredField')),
				descricao: Yup.string().required(t('messages.requiredField')),
			});

			await schema.validate(fields, {
				abortEarly: false,
			});

			switch (container.flow) {
				case FLOWS.IDEA:
					await APIIdeas.postCreateTemplate(fields);
					break;
				case FLOWS.CHALLENGE:
				case FLOWS.BRAINSTORM:
					await APIPlatforms.postCreateTemplate(fields);
					break;
				default:
					break;
			}

			SToast.success(t('messages.success.template.created'));

			dispatch(Creators.MODAL.hideModal());
		} catch (err) {
			if (err instanceof Yup.ValidationError) {
				setErrors(getValidationErrors(err));
			} else handleAxiosErrors(err);
		} finally {
			setInProgress(false);
		}
	};

	return (
		<DialogContent id="TemplateCreateDialog">
			<DialogBox className="s-dialog-title">
				<DialogTitle variant="h6">
					{t('form.flow.template.new', { flow: t(`global.${container.flow}`) })}
				</DialogTitle>
			</DialogBox>

			<Form className="s-form" onSubmit={handleSubmit}>
				<SFormGroup>
					<SInputLabel htmlFor="template-name" error={typeof errors?.nome === 'string'}>
						{t('form.templateName')}
					</SInputLabel>
					<STextField
						type="text"
						autoFocus
						fullWidth
						name="nome"
						maxLength={maxLength.flowName}
						className="form"
						id="template-name"
						autoComplete="off"
						value={template.nome}
						placeholder={t('form.placeholder.flow.template.name')}
						error={typeof errors?.nome === 'string'}
						helperText={errors?.nome}
						onChange={onChangeHandler}
					/>
				</SFormGroup>
				<SFormGroup>
					<SInputLabel htmlFor="template-description" error={typeof errors?.descricao === 'string'}>
						{t('form.description')}
					</SInputLabel>
					<STextField
						multiline
						name="descricao"
						id="template-description"
						rows="6"
						placeholder={t('form.placeholder.description')}
						maxLength={maxLength.default500}
						error={typeof errors?.descricao === 'string'}
						helperText={errors?.descricao}
						onChange={onChangeHandler}
						value={template.descricao}
					/>
				</SFormGroup>
				{/* <SFormGroup>
						<SInputLabel htmlFor="template-color">
							{t('global.color')}
						</SInputLabel>
						<ColorPalette
							id="template-color"
							value={template.color}
							onChange={onChangeHandler}
							name="color"
						/>
					</SFormGroup> */}
				<DialogBox>
					<DialogActions className="s-dialog-actions">
						<Button type="button" color="secondary" onClick={() => dispatch(Creators.MODAL.hideModal())}>
							{t('global.cancel')}
						</Button>
						<SButton
							type="submit"
							color="success"
							onClick={handleSubmit}
							isLoading={inProgress}
							error={!isEmpty(errors)}
						>
							{t('global.create')}
						</SButton>
					</DialogActions>
				</DialogBox>

			</Form>
		</DialogContent>
	);
};

const Translation = withTranslation('common')(MModalTemplateCreate);

const ModalTemplateCreate = (props: IModal) => (
	<Suspense fallback={<DialogContent />}>
		<Translation {...props} />
	</Suspense>
);
export default ModalTemplateCreate;
