import { Box } from '@mui/material';
// eslint-disable-next-line import/no-extraneous-dependencies
import { styled as MuiStyled } from '@mui/system';

export const SurveyBar = MuiStyled(Box)(({ theme }) => ({
	width: '100%',
	backgroundColor: 'var(--primary)',
	height: '56px',
	bottom: '0',
	position: 'absolute',
	color: '#fff',
	display: 'flex',
	alignItems: 'center',
	padding: '8px 120px',
	fontSize: '16px',
	lineHeight: '20px',
	fontWeight: '400',
	transition: '5s',
	'.survey__question': {
		margin: '0 10px',
	},
	'.MuiButton-outlinedInherit': {
		color: '#fff',
		minWidth: 'auto',
		margin: '0 8px',
	},
}));
