import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export declare type TSimilarity = 'low' | 'medium' | 'high' | undefined;
interface ISimilarityProps {
	id: string
	similarity: number
}
const Similarity = ({ id, similarity }: ISimilarityProps) => {
	const { t } = useTranslation();
	const similarityLevel = (value: number): TSimilarity => {
		const s = value * 100;
		const low = 100 / 3;
		const high = (100 / 3) * 2;

		if (s > low && s < high) {
			return 'medium';
		}

		if (s >= high) {
			return 'high';
		}
		if (s <= low) {
			return 'low';
		}

		return undefined;
	};

	useEffect(() => {
		const similarityInfo = document.getElementById(id);

		const base = (similarity || 0) * 100;
		let dotsWidths = [0, 0, 0, 0, 0];
		let i = base / 20;
		dotsWidths = dotsWidths.map((el) => {
			let response;
			if (i > 1) {
				response = 1;
				i -= 1;
			} else {
				response = i;
				i -= i;
			}
			return response;
		});
		const dotsDiv = similarityInfo?.querySelectorAll('.similarity__details--value .dot');

		if (!dotsDiv) return;

		dotsWidths?.forEach((dotWidth, index) => {
			if (!dotsDiv[index]) return;
			// @ts-ignore
			dotsDiv[index].style.cssText = `--width:${dotWidth * 100}%;`;
		});
	}, []);

	return (
		<div className="similarity">
			<div id={id} className="similarity__info">
				<div className="similarity__details">
					<div className="similarity__details--value">
						<div className="bar">
							<div className="dot" />
							<div className="dot" />
							<div className="dot" />
							<div className="dot" />
							<div className="dot" />
							<span />
						</div>
					</div>
				</div>
			</div>
			<div className="similarity__level">
				{t(`trends.similarity.${similarityLevel(similarity)}`)}
			</div>
		</div>
	);
};

export { Similarity };
