// @ts-nocheck
import { TObject } from 'src/@types/IShared';

const loadImage = (url: string) => new Promise((resolve, reject) => {
	const image = new Image();
	image.crossOrigin = '*'; // <-- set here
	image.onload = function () {
		resolve(image);
	};
	image.onerror = function () {
		reject(new Error(`Could not load image at ${url}`));
	};
	image.src = url;
});

export const loadImageDashboard = async (imagePath: string) => {
	if (!imagePath || imagePath === '') {
		return undefined;
	}

	const imgSize = 50;
	const canvas = document.createElement('canvas');

	canvas.height = imgSize * 4;
	canvas.width = imgSize * 4;
	const context = canvas.getContext('2d');

	const image = await loadImage(imagePath);

	context?.save();
	context?.beginPath();
	context?.arc(2 * imgSize, 2 * imgSize, 2 * imgSize, 0, Math.PI * 2, true);
	context?.closePath();
	context?.clip();

	context?.drawImage(image, 0, 0, 4 * imgSize + 2, 4 * imgSize + 2);
	context?.restore();

	return canvas.toDataURL();
};

const maxX = 20;
const maxY = 20;
const minX = -1 * maxX;
const minY = -1 * maxY;

const maxXMatrizDI = 16;
const maxYMatrizDI = 16;

export const HMatrices = {
	D: {
		title: 'dashboards.d.title',
		quadrants: {
			topLeft: 'dashboards.d.breakthrough',
			topRight: 'dashboards.d.incremental',
			bottomLeft: 'dashboards.d.searchBasic',
			bottomRight: 'dashboards.d.radical',
		},
		option: {
			title: {
				text: '',
			},
			grid: {
				top: 30,
				bottom: 30,
				left: 30,
				right: 30,
			},
			xAxis: {
				min: 0,
				max: 16,
			},
			yAxis: {
				min: 0,
				max: 17,
			},
			series: [
				{
					data: [
						[maxYMatrizDI / 2, maxYMatrizDI],
						[maxYMatrizDI, maxYMatrizDI],
					],
					name: 'Incremental',
					type: 'line',
					color: 'rgba(0,0,0,0)',
					areaStyle: {
						opacity: 1,
						color: 'rgba(0,0,0,0)',
					},
				},
				{
					data: [
						[0, maxYMatrizDI],
						[maxXMatrizDI / 2 + 0.5, maxYMatrizDI],
					],
					name: 'Breakthrough',
					type: 'line',
					color: 'rgba(0,0,0,0)',
					areaStyle: {
						opacity: 1,
						color: 'rgba(0,0,0,0)',
					},
				},
				{
					data: [
						[maxXMatrizDI / 2 + 0.5, maxYMatrizDI / 2],
						[maxYMatrizDI, maxYMatrizDI / 2],
					],
					name: 'Radical',
					type: 'line',
					color: 'rgba(0,0,0,0)',
					areaStyle: {
						opacity: 1,
						color: 'rgba(0,0,0,0)',
					},
				},
				{
					data: [
						[0, maxYMatrizDI / 2],
						[maxXMatrizDI / 2 + 0.5, maxYMatrizDI / 2],
					],
					name: 'Search Basic',
					type: 'line',
					color: 'rgba(0,0,0,0)',
					areaStyle: {
						opacity: 1,
						color: 'rgba(0,0,0,0)',
					},
				},
			],
		},
		buildSeries(data: TObject) {
			const series = [];
			if (data) {
				let serie = [];
				data?.forEach((item) => {
					const dataChart = [];
					dataChart.push([
						item.dominioConhecimento,
						item.definicaoProblema,
						item.ideia_nome,
					]);

					serie = {
						name: item.ideia_nome.split(' ', '-'),
						data: dataChart,
						type: 'scatter',
						symbol: item.ideia_image ? `image://${item.ideia_image}` : 'circle',
					};
					series.push(serie);
				});
			}
			return series;
		},
	},
	DI: {
		title: 'dashboards.di.title',
		quadrants: {
			topLeft: 'dashboards.di.quickWin',
			topRight: 'dashboards.di.majorProject',
			bottomLeft: 'dashboards.di.fillIns',
			bottomRight: 'dashboards.di.hardSlogs',
		},
		option: {
			title: {
				text: '',
			},
			grid: {
				top: 30,
				bottom: 30,
				left: -55,
				right: -55,
			},
			xAxis: {
				// min: -20,
				// max: 20,
			},
			yAxis: {
				// min: -20,
				// max: 20,
			},
			series: [
				{
					data: [[0, maxY], [maxX, maxY]],
					name: 'Major Projects',
					type: 'line',
					color: 'rgba(0,0,0,0)',
					areaStyle: {
						opacity: 1,
						color: 'rgba(0,0,0,0)',
					},
				},
				{
					data: [[0, maxY], [minX, maxY]],
					name: 'Quick Wins',
					type: 'line',
					color: 'rgba(0,0,0,0)',
					areaStyle: {
						opacity: 1,
						color: 'rgba(0,0,0,0)',
					},
				},
				{
					data: [[0, minY], [minX, minY]],
					name: 'Fill Ins',
					type: 'line',
					color: 'rgba(0,0,0,0)',
					areaStyle: {
						opacity: 1,
						color: 'rgba(0,0,0,0)',
					},
				},
				{
					data: [[0, minY], [maxX, minY]],
					name: 'Hard Slogs',
					type: 'line',
					color: 'rgba(0,0,0,0)',
					areaStyle: {
						opacity: 1,
						color: 'rgba(0,0,0,0)',
					},
				},
			],
		},
		buildSeries(data: TObject) {
			const series = [];
			if (data) {
				let serie = [];
				data?.forEach((item) => {
					const dataChart = [];

					dataChart.push([
						item.dificuldade,
						item.impacto,
						item.ideia_nome,
					]);

					serie = {
						name: item.ideia_nome,
						data: dataChart,
						type: 'scatter',
						symbol: item.ideia_image ? `image://${item.ideia_image}` : 'circle',
					};
					series.push(serie);
				});
			}

			return series;
		},
	},
	TR: {
		title: 'dashboards.tr.title',
		quadrants: {
			topLeft: 'dashboards.tr.return', bottomRight: 'dashboards.tr.time',
		},
		option: {
			title: {
				text: '',
			},
			grid: {
				top: 30,
				bottom: 30,
				left: 30,
				right: 30,
			},
			xAxis: {
				min: 0,
				max: 10.5,
			},
			yAxis: {
				min: 0,
				max: 11,
			},
			series: [
				{
					data: [
						[0, 11],
						[11, 0],
					],
					name: 'Tempo',
					type: 'line',
					color: 'rgba(0,0,0,0)',
					areaStyle: {
						opacity: 1,
						color: 'rgba(0,0,0,0)',
					},
				},
				{
					data: [
						[0, 11],
						[11, 0],
					],
					name: 'Retorno',
					type: 'line',
					color: 'rgba(0,0,0,0)',
					areaStyle: {
						opacity: 1,
						color: 'rgba(0,0,0,0)',
					},
				},
			],
		},
		buildSeries(data: TObject) {
			const series = [];
			if (data) {
				let serie = [];
				data?.forEach((item) => {
					const dataChart = [];
					dataChart.push([
						item.tempo,
						item.retorno,
						item.ideia_nome,
					]);

					serie = {
						name: item.ideia_nome,
						data: dataChart,
						type: 'scatter',
						symbol: item.ideia_image ? `image://${item.ideia_image}` : 'circle',
					};
					series.push(serie);
				});
			}

			return series;
		},
	},
};
