// @ts-nocheck
import React, { Suspense } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import SpinnerLDSRipple from '@components/Shared/SpinnerLDSRipple';
import { SButton } from '@components/Buttons';
import { SBox } from '@components/Background/SBox';

type TProps = WithTranslation;

const _Enterprise = ({ t }: TProps) => {
	const features = [
		{
			title: t('enterpriseFeatures.singleSignOn.title'),
			text: t('enterpriseFeatures.singleSignOn.text'),
			availableOn: t('enterpriseFeatures.availableOnEnterpriseAndStandard'),
		},
		{
			title: t('enterpriseFeatures.sessionTimeout.title'),
			text: t('enterpriseFeatures.sessionTimeout.text'),
			availableOn: t('enterpriseFeatures.availableOnEnterprise'),
		},
		{
			title: t('enterpriseFeatures.sharingPolicy.title'),
			text: t('enterpriseFeatures.sharingPolicy.text'),
			availableOn: t('enterpriseFeatures.availableOnEnterprise'),
		},
		{
			title: t('enterpriseFeatures.auditLogs.title'),
			text: t('enterpriseFeatures.auditLogs.text'),
			availableOn: t('enterpriseFeatures.availableOnEnterprise'),
		},
	];

	return (
		<SBox id="accountEnterprise">
			<ul className="enterprise__features">
				{features.map((item, index) => (
					<li key={index} className="d-flex flex-column">
						<h5>{item.title}</h5>
						<p>
							{item.text}
							<a href="# ">{t('global.learnMore')}</a>
						</p>
					</li>
				))}
			</ul>
			<div className="footer">
				<SButton
					href={t('urls.contact')}
					target="_blank"
					color="secondary"
					variant="outlined"
					rel="noreferrer"
				>
					{t('global.contactUs')}
				</SButton>
			</div>
		</SBox>
	);
};

const Translation = withTranslation('common')(_Enterprise);

const Enterprise = () => (
	<Suspense fallback={<SpinnerLDSRipple />}>
		<Translation />
	</Suspense>
);

export default Enterprise;
