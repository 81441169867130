const NOTIFICATION = 'notifications';

const IDEA = `${NOTIFICATION}.idea`;
const TEAM = `${NOTIFICATION}.team`;
const INVITE = `${NOTIFICATION}.invite`;
const CHALLENGE = `${NOTIFICATION}.challenge`;
const BRAINSTORM = `${NOTIFICATION}.brainstorm`;

export const NOTIFICATIONS_ACTIONS = {
	challenge: {
		LIKE: `${CHALLENGE}.like`,
		UPDATE: `${CHALLENGE}.update`,
		TEMPLATE: `${CHALLENGE}.template`,
		COMMENT_LIKE: `${CHALLENGE}.comment.like`,
		NEW_COMMENT: `${CHALLENGE}.comment.default`,
		NEW_COMMENT_ANSWER: `${CHALLENGE}.comment.answer`,
		INVITED_AS_AGENT: `${CHALLENGE}.invite.agent`,
		INVITED_AS_SPONSOR: `${CHALLENGE}.invite.sponsor`,
		INVITED_AS_MODERATOR: `${CHALLENGE}.invite.moderator`,
		PARTICIPATION_AS_AGENT: `${CHALLENGE}.participation.agent`,
		PARTICIPATION_AS_SPONSOR: `${CHALLENGE}.participation.sponsor`,
		PARTICIPATION_AS_MODERATOR: `${CHALLENGE}.participation.moderator`,
	},
	brainstorm: {
		LIKE: `${BRAINSTORM}.like`,
		UPDATE: `${BRAINSTORM}.update`,
		TEMPLATE: `${BRAINSTORM}.template`,
		INVITE: `${BRAINSTORM}.invite.default`,
		PARTICIPATION: `${BRAINSTORM}.participation`,
		COMMENT_LIKE: `${BRAINSTORM}.comment.like`,
		NEW_COMMENT: `${BRAINSTORM}.comment.default`,
		NEW_COMMENT_ANSWER: `${BRAINSTORM}.comment.answer`,
	},
	idea: {
		LIKE: `${IDEA}.like`,
		UPDATE: `${IDEA}.update`,
		AWARDED: `${IDEA}.awarded`,
		APPROVED: `${IDEA}.approved`,
		REPROVED: `${IDEA}.reproved`,
		TEMPLATE: `${IDEA}.template`,
		SUPPORTED: `${IDEA}.supported`,
		ADD_CHALLENGE: `${IDEA}.add.challenge`,
		ADD_BRAINSTORM: `${IDEA}.add.brainstorm`,
		COMMENT_LIKE: `${IDEA}.comment.like`,
		EVALUATED: `${IDEA}.evaluated.default`,
		COMMENT: `${IDEA}.comment.default`,
		COMMENT_ANSWER: `${IDEA}.comment.answer`,

		REMOVE_PLATFORM: `${IDEA}.remove.platform`,

		INVITE_TO_CO_AUTHOR: `${IDEA}.coauthor.invite`,
		NEW: `${IDEA}.new.default`,
		NEW_WITHOUT_PLATFORM: `${IDEA}.new.without.platform`,
		UPDATE_WITHOUT_PLATFORM: `${IDEA}.updated.without.platform`,
		EVALUATED_WITHOUT_PLATFORM: `${IDEA}.evaluated.without.platform`,
	},
	team: {
		NEW: `${TEAM}.create`,
		UPDATE: `${TEAM}.update`,
		INVITE: `${TEAM}.invite`,
		REMOVE: `${TEAM}.remove`,
	},
	report: {
		IDEA: `${NOTIFICATION}.report.idea`,
		CHALLENGE: `${NOTIFICATION}.report.challenge`,
		BRAINSTORM: `${NOTIFICATION}.report.brainstorm`,
		COMMENT_IDEA: `${NOTIFICATION}.report.comment.idea`,
		COMMENT_CHALLENGE: `${NOTIFICATION}.report.comment.challenge`,
		COMMENT_BRAINSTORM: `${NOTIFICATION}.report.comment.brainstorm`,
	},
	invite: {
		SINGUP: `${INVITE}.singup`,
	},
};

export const NOTIFICATIONS_ICONS = {
	challenge: {
		NEW: `${CHALLENGE}.new`,
		LIKE: `${CHALLENGE}.like`,
		UPDATE: `${CHALLENGE}.update`,
		PUBLISH: `${CHALLENGE}.publish`,
		TEMPLATE: `${CHALLENGE}.template`,
		INVITED: `${CHALLENGE}.invite.default`,
		COMMENT_LIKE: `${CHALLENGE}.comment.like`,
		NEW_COMMENT: `${CHALLENGE}.comment.default`,
		INVITED_AS_AGENT: `${CHALLENGE}.invite.agent`,
		NEW_COMMENT_ANSWER: `${CHALLENGE}.comment.answer`,
		INVITED_AS_SPONSOR: `${CHALLENGE}.invite.sponsor`,
		INVITED_AS_MODERATOR: `${CHALLENGE}.invite.moderator`,
	},
	brainstorm: {
		NEW: `${BRAINSTORM}.new`,
		LIKE: `${BRAINSTORM}.like`,
		UPDATE: `${BRAINSTORM}.update`,
		PUBLISH: `${BRAINSTORM}.publish`,
		TEMPLATE: `${BRAINSTORM}.template`,
		INVITED: `${BRAINSTORM}.invite.default`,
		COMMENT_LIKE: `${BRAINSTORM}.comment.like`,
		NEW_COMMENT: `${BRAINSTORM}.comment.default`,
		NEW_COMMENT_ANSWER: `${BRAINSTORM}.comment.answer`,
	},
	idea: {
		LIKE: `${IDEA}.like`,
		UPDATE: `${IDEA}.update`,
		NEW: `${IDEA}.new.default`,
		AWARDED: `${IDEA}.awarded`,
		APPROVED: `${IDEA}.approved`,
		REPROVED: `${IDEA}.reproved`,
		TEMPLATE: `${IDEA}.template`,
		SUPPORTED: `${IDEA}.supported`,
		ADD_PLATFORM: `${IDEA}.add.platform`,
		COMMENT_LIKE: `${IDEA}.comment.like`,
		EVALUATED: `${IDEA}.evaluated.default`,
		NEW_COMMENT: `${IDEA}.comment.default`,
		REMOVE_PLATFORM: `${IDEA}.remove.platform`,
		NEW_COMMENT_ANSWER: `${IDEA}.comment.answer`,
		INVITE_TO_CO_AUTHOR: `${IDEA}.coauthor.invite`,
		NEW_WITHOUT_PLATFORM: `${IDEA}.new.without.platform`,
		UPDATE_WITHOUT_PLATFORM: `${IDEA}.updated.without.platform`,
		EVALUATED_WITHOUT_PLATFORM: `${IDEA}.evaluated.without.platform`,
	},
	team: {
		NEW: `${TEAM}.create`,
		UPDATE: `${TEAM}.update`,
		INVITE: `${TEAM}.invite`,
		REMOVE: `${TEAM}.remove`,
	},
	report: {
		IDEA: `${NOTIFICATION}.report.idea`,
		PLATFORM: `${NOTIFICATION}.report.platform`,
		COMMENT_IDEA: `${NOTIFICATION}.report.comment.idea`,
		COMMENT_PLATFORM: `${NOTIFICATION}.report.comment.platform`,
	},
	invite: {
		SINGUP: `${INVITE}.singup`,
	},
};
