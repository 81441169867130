// @ts-nocheck
import { createActions, createReducer } from 'reduxsauce';
import { IFeaturesActions, IFeaturesState } from './types';

export const featuresActions: IFeaturesActions = createActions({
	handleFeature: ['params'],
	insertFeature: ['params'],
	successFeatures: ['key', 'value'],
	deleteFeature: ['params'],
	setFeature: ['feature'],
	getFeatures: ['params'],
	clearFeatures: [''],
	restoreFeature: ['params'],
	getSupporters: ['params'],
});

const { Types } = featuresActions;

export const STATE_FEATURES: IFeaturesState = {

};

const successFeatures = (state = STATE_FEATURES, action) => ({
	...state,
	[action.key]: action.value,
});

const handleFeature = (state = STATE_FEATURES, action) => {
	const { key, value } = action.params;
	return {
		...state,
		[key]: value,
	};
};

const setFeature = (state = STATE_FEATURES, action) => ({
	...action.feature,
});

/** Default functions */

const clearFeatures = () => ({});
const defaultFeatures = (state = STATE_FEATURES) => ({ ...state });

/** Creating our own reducer */

export default createReducer(STATE_FEATURES, {
	[Types.HANDLE_FEATURE]: handleFeature,
	[Types.INSERT_FEATURE]: defaultFeatures,
	[Types.DELETE_FEATURE]: defaultFeatures,
	[Types.GET_FEATURES]: defaultFeatures,
	[Types.SUCCESS_FEATURES]: successFeatures,
	[Types.SET_FEATURE]: setFeature,
	[Types.CLEAR_FEATURES]: clearFeatures,
	[Types.RESTORE_FEATURE]: defaultFeatures,
	[Types.GET_SUPPORTERS]: defaultFeatures,
});
