// @ts-nocheck
import React, { useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import IdeaCover from '@components/Covers/IdeaCover';
import PageLoader from '@components/Loaders/PageLoader';
import AttachmentsList from '@components/Lists/AttachmentsList';
import { Creators } from '@actions';
import routes from '@routes/modal';
import { SponsorsList } from '@components/Lists/SponsorsList';
import { EvaluatorsList } from '@components/Lists/EvaluatorsList';
import { APIIdeas } from '@services/apis';
import { MembersList } from '@components/Lists/MembersList';
import FiltersNavBar from '@components/NavBar/FiltersNavBar';
import { Sticker } from '@components/Stickers/Sticker';
import { Signals } from '@components/Signals';
import { FLOWS } from '@constants/GLOBAL';
import { IRootState } from 'src/store';
import { IdeaCard } from '@components/Cards/IdeaCard';
import { IIdea } from 'src/@types/IIdea';
import { IComment, IPaginatedRequest, IPagination } from 'src/@types/IShared';
import URLS from '@constants/URLS';
import { Comments } from '@components/Comments';
import { SocketIO, SOCKET_EVENTS } from '@services/socket';
import { handleAxiosErrors } from '@services/auth';
import { HTMLDescription } from '@components/Background/HTMLDescription';
import { BCanvas } from '@components/Background/BCanvas';
import { BBadges } from '@components/Background/BBadges';
import { BForms } from '@components/Background/BForms';
import { BFeatures } from '@components/Background/BFeatures';
import ReceiveButton from '@components/Buttons/ReceiveButton';
import { HMatrices, loadImageDashboard } from '@components/Matrices/helpers';
import { MatrixBase } from '@components/Matrices/MatrixBase';
import { IListRequest } from '@components/Lists/types';
import CardGrid from '@components/Grids/CardGrid';
import { HelpButton, SButton } from '@components/Buttons';
import QA from '@components/QA';
import { useTour, withTour } from '@reactour/tour';
import { BuildTour } from '@components/STour';
import { STabs } from '@components/STabs';
import { STabPanel } from '@components/STabs/STabPanel';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { IForm } from 'src/@types/IForm';
import { SBox } from '@components/Background/SBox';
import { GenericGrid, W_GRID } from '@components/Grids/GenericGrid';
import { Typography } from '@mui/material';

type TProps = WithTranslation;

const _Idea = ({ t }: TProps) => {
	const dispatch = useDispatch();
	const match = useParams<{id: string}>();
	const tour = useTour();

	const { modal, shared } = useSelector((state: IRootState) => state);

	const [idea, setIdea] = useState<IIdea & { isLoading: boolean}>({ comentarios: [], isLoading: true });
	const [forms, setForms] = useState<IPagination<IForm>>({ docs: [], isLoading: true });
	const [canvas, setCanvas] = useState<IPagination<IForm>>({ docs: [], isLoading: true });
	const [coauthors, setCoauthors] = useState({ docs: [], isLoading: true, totalDocs: 0 });
	const [evaluators, setEvaluators] = useState({ docs: [], isLoading: true, totalDocs: 0 });
	const [connections, setConnections] = useState<IPagination<IIdea>>({ docs: [], isLoading: true, totalDocs: 0 });
	const [dashboards, setDashboards] = useState({ docs: [], isLoading: true });

	const loadCoauthors = async (params: IPaginatedRequest) => {
		try {
			setCoauthors((prevState) => ({ ...prevState, isLoading: true }));
			const response = await APIIdeas.getCoauthors(match.id, params);
			setCoauthors((prevState) => ({
				...response.data,
				docs: response.data.hasPrevPage
					? prevState.docs.concat(response.data.docs)
					: response.data.docs,
				isLoading: false,
			}));
		} catch (err) {
			handleAxiosErrors(err);
		} finally { // loadMore
			if (typeof params.callback === 'function') {
				params.callback();
			}
		}
	};

	const updateIdea = (_idea: IIdea) => {
		if (_idea?.coautores?.length > coauthors.totalDocs) {
			loadCoauthors({ sort: 'a-z', page: 1, limit: 20 });
		}

		if (_idea?.forms) {
			setForms((prevState) => ({ ...prevState, docs: _idea.forms }));
		}

		if (_idea?.canvas) {
			setCanvas((prevState) => ({ ...prevState, docs: _idea.canvas }));
		}

		setIdea((prevState) => ({ ...prevState, ..._idea }));
	};

	const onDisconnectHandler = (ideaId: string, mainIdea: IIdea) => {
		const index = connections.docs.findIndex((item) => item._id === ideaId);
		if (index > -1) {
			connections.docs.splice(index, 1);
			setConnections((prevState) => ({ ...prevState, connections }));
		}

		if (mainIdea) {
			updateIdea(mainIdea);
		}
	};

	const loadDashboards = async () => {
		try {
			setDashboards((prevState) => ({ ...prevState, isLoading: true }));
			const response = await APIIdeas.getDashboard(match.id);

			await Promise.all(Object.keys(response.data).map(async (key) => {
				await Promise.all(
					response.data[key].map(async (item) => {
						item.ideia_image = await loadImageDashboard(item.ideia_image);
					}),
				);
			}));

			setDashboards({ ...response.data, isLoading: false });
		} catch (err) {
			handleAxiosErrors(err);
		}
	};

	const loadEvaluators = (queryParams: IListRequest) => {
		setEvaluators((prevState) => ({ ...prevState, isLoading: true }));
		dispatch(Creators.IDEAS.getEvaluatorsByIdea({
			ideaId: match.id,
			onSuccess: (response) => setEvaluators({ ...response, isLoading: false }),
			queryParams: { ...queryParams, limit: 3 },
		}));
	};

	const setTour = () => {
		const TOUR_STEPS = BuildTour.steps(['idea.status', 'idea.evaluations', 'idea.tabs.trends',
			'idea.tabs.qa', 'idea.tabs.dashboards', 'idea.tabs.forms', 'idea.tabs.canvas',
			'idea.tabs.attachments'], { noAction: true });

		BuildTour.setTour(tour, TOUR_STEPS, { currentStep: 0 });
	};

	const mainButton = () => {
		const flow = idea?.plataforma?.type?.toLowerCase();

		const defaultProps = {
			type: 'button',
			color: 'primary',
			variant: 'outlined',
			size: 'large',
		};

		if (typeof idea !== 'object') return null;

		if (idea.status === 'rascunho') {
			return (
				<SButton
					onClick={(e) => {
						dispatch(Creators.IDEAS.updateIdeas({ workflow: 'UPDATE_IDEA', syncIdea: updateIdea, ...idea }));
						dispatch(Creators.MODAL.showModal(routes.MODAL_IDEA.path));
						e.stopPropagation();
					}}
					{...defaultProps}
				>
					{t('global.edit')}
				</SButton>
			);
		}

		if (idea.isArchived) {
			return <Sticker label={t('idea.archived')} className="dark lg" />;
		}

		if (idea.plataforma?.possuiPedidoParticipacao) {
			return (
				<ReceiveButton
					color="primary"
					mode="invite"
					feedbackOptions={{
						match: {
							ideaId: match.id,
							platformId: idea.plataforma?._id,
							flow,
							hasInvite: idea.plataforma?.possuiPedidoParticipacao,
							permission: idea.plataforma?.permissao,
							updateIdea,
						},
					}}
				/>
			);
		}

		if ((idea.plataforma?.type === 'Brainstorm' && idea.plataforma?.usuarioParticipa)
			|| idea.plataforma?.usuarioModera || !idea.plataforma && (idea.usuarioCoautora || idea.usuarioIsAutor)) {
			return (
				<SButton
					onClick={onEvaluationHandler}
					{...defaultProps}
				>
					{idea?.status === 'pendente' ? t('global.evaluate') : t('idea.viewEvaluation')}
				</SButton>
			);
		}

		if (idea.plataforma && !idea.plataforma.usuarioParticipa) {
			return (
				<ReceiveButton
					mode="join"
					feedbackOptions={{
						match: {
							ideaId: match.id,
							platformId: idea.plataforma?._id,
							flow,
							updateIdea,
						},
					}}
				/>
			);
		}

		return null;
	};

	const loadIdea = async (startTour?: boolean) => {
		try {
			setIdea((prevState) => ({ ...prevState, isLoading: true }));
			const response = await APIIdeas.getIdea(match.id);

			setCanvas((prevState) => ({ ...prevState, docs: [...response.data.canvas, ...response.data.miroBoards], isLoading: false }));
			setForms((prevState) => ({ ...prevState, docs: response.data.forms, isLoading: false }));

			delete response.data.premios;
			delete response.data.miroBoards;
			delete response.data.forms;

			setIdea((prevState) => ({
				...prevState,
				...response.data,
				hasConnectAction: response?.data?.status !== 'rascunho'
						&& ((response?.data?.plataforma?.type === 'Challenge' && response?.data?.plataforma?.usuarioAgencia)
							|| (response?.data?.plataforma?.type === 'Brainstorm' && response?.data?.plataforma?.usuarioParticipa)
							|| response?.data?.usuarioCoautora || response?.data?.usuarioIsAutor),
				mode: response.data?.isArchived
						|| ['aprovada', 'reprovada'].includes(response.data.status) ? 'view' : 'edit',
				isLoading: false,
			}));

			if (startTour) {
				setTour();
			}
		} catch (err) {
			handleAxiosErrors(err);
		}
	};

	const onEvaluationHandler = () => {
		dispatch(Creators.IDEAS.updateIdeas({
			...idea,
			workflow: 'EVALUATE_IDEA',
			syncIdea: updateIdea,
			loadDashboards,
			loadEvaluators,
		})); // updateIdea function Store

		dispatch(Creators.MODAL.showModal(routes.MODAL_APPROVE.path));
	};

	const loadBadges = () => {
		if (!idea?.plataforma?._id) return;
		dispatch(Creators.BADGES.getBadgesByPlatform({
			platformId: idea?.plataforma._id,
			ideaId: match.id,
			onSuccess: (response) => setIdea((prevState) => ({ ...prevState, premios: response.docs })),
		}));
	};

	const loadCanvas = () => {
		dispatch(Creators.CANVAS.getCanvas({
			flow: FLOWS.IDEA,
			ideaId: match.id,
			onSuccess: (response) => setIdea((prevState) => ({ ...prevState, canvas: response.docs })),
		}));
	};

	const loadConnections = async (params: IListRequest) => {
		try {
			setConnections((prevState) => ({ ...prevState, isLoading: true }));
			const response = await APIIdeas.getConnections(match.id, params);
			setConnections((prevState) => ({
				...prevState,
				...response.data,
				docs: response.data.hasPrevPage
					? prevState.docs.concat(response.data.docs)
					: response.data.docs,
				isLoading: false,
			}));
		} catch (err) {
			handleAxiosErrors(err);
		} finally { // loadMore
			if (typeof params.callback === 'function') {
				params.callback();
			}
		}
	};

	const loadForms = () => {
		setForms((prevState) => ({ ...prevState, isLoading: true }));
		dispatch(Creators.FORMS.getForms({
			ideaId: match.id,
			flow: FLOWS.IDEA,
			onSuccess: (response: IPagination<IForm>) => setForms({ ...response, isLoading: false }),
		}));
	};

	React.useEffect(() => {
		SocketIO.emit(SOCKET_EVENTS.JOIN_IDEA, { room: match.id });
		SocketIO.emit(SOCKET_EVENTS.JOIN_COMMENT, { room: match.id });
		dispatch(Creators.IDEAS.updateIdeas({ syncIdea: updateIdea, loadDashboards, loadEvaluators })); // updateIdea function Store
		loadIdea(shared.tour === FLOWS.IDEA && !modal.flow);
	}, []);

	if (!idea || idea?.isLoading) {
		return <PageLoader />;
	}

	const isChallenge = idea?.plataforma?.type === 'Challenge';
	const noAccess = !(idea.plataforma?.usuarioParticipa
			|| idea.usuarioCoautora || idea.usuarioIsAutor);

	const TOUR_DASHBOARD = BuildTour.steps(['dashboard.d', 'dashboard.di', 'dashboard.tr'], { noAction: true });

	const TABS = {
		trends: { value: 'trends', label: 'Signals' },
		qa: { value: 'qa', label: 'Q&A' },
		evaluations: {
			value: 'evaluations',
			label: t('global.evaluations'),
			icon: !noAccess && <HelpButton onClick={() => BuildTour.setTour(tour, TOUR_DASHBOARD, { currentStep: 0 })} />,
			iconPosition: 'end',
		},
		forms: { value: 'forms', label: `${t('global.form')}s` },
		canvas: { value: 'canvas', label: 'Canvas' },
		badges: { value: 'badges', label: t('global.badges'), hide: !isChallenge },
		supports: { value: 'supports', label: t('global.supports'), hide: !isChallenge },
		attachments: { value: 'attachments', label: t('global.attachments') },
	};

	const RELATED_TABS = {
		coauthors: {
			value: 'coauthors',
			label:
					`${t('global.co-authors')
					} (${idea.coautores?.length || 0
					})`,
		},
		evaluations: {
			value: 'evaluations',
			label:
					`${t('global.evaluators')
					} (${evaluators.totalDocs || idea?.avaliadores || 0
					})`,
		},
		sponsors: {
			value: 'sponsors',
			label:
					`${t('global.sponsors')
					} (${idea?.supporters?.length
					})`,
			hide: !isChallenge,
		},
		connections: {
			value: 'connections',
			label:
					`${t('global.connections')
					} (${idea?.conexoes?.length
					})`,
		},
		comments: {
			value: 'comments',
			label:
					`${t('global.comments')
					} (${idea.comentarios.reduce((acc, c) => {
						// Adiciona o numero de respostas
						acc += c.respostas ? c.respostas.length : 0;
						// Adiciona 1 pelo comentario atual;
						acc++;
						// Retorna acc;
						return acc;
					}, 0)
					})`,
		},
	};

	const classes = {
		innerWidth: W_GRID.W_GRID_2,
	};

	return (
		<GenericGrid id="idea-page" className="idea__page s-grid-page">
			<IdeaCover
				idea={idea}
				updateIdea={updateIdea}
			/>
			<div className="block has-tablet">
				<div className="inner w-grid-2 first-section">
					<span className="summary">
						{t('global.summary')}
					</span>
					<HTMLDescription className="box__page--info" type="summary" html={idea.resumo} />
					{idea.tags?.length > 0 && (
						<div className="tags__group">
							{idea.tags.map((tag) => (
								<a key={tag.value} href={`${URLS.SEARCH}?query=${tag.value}`} className="tag">
									{tag.value}
								</a>
							))}
						</div>
					)}
					<div className="main-action">
						{mainButton()}
					</div>
				</div>
				<STabs
					id="idea-tabs"
					tabs={Object.values(TABS)}
					className="main-tabs"
					classes={{ appBar: 'inner w-grid-2' }}
					active={TABS.trends.value}
					TabIndicatorProps={{ sx: { display: 'none' } }}
					noAccess={noAccess}
					textColor="secondary"
					historyMode="primary"
					sx={{ sTabs: { marginY: 6 } }}
					TabPanelProps={{
						sx: {
							marginY: 3,
						},
					}}
				>
					<STabPanel value={TABS.trends.value}>
						<Signals ideaId={idea._id} classes={{ zoomIn: 'inner w-grid-1', zoomOut: classes.innerWidth }} />
					</STabPanel>
					<STabPanel value={TABS.qa.value} className={classes.innerWidth}>
						<QA ideaId={idea._id} />
					</STabPanel>
					<STabPanel value={TABS.evaluations.value} onShow={loadDashboards}>
						<MatrixBase
							id="d"
							className="matrix__d g-2x2"
							title={t(HMatrices.D.title)}
							quadrants={HMatrices.D.quadrants}
							optionBase={HMatrices.D.option}
							classes={{
								zoomIn: W_GRID.W_GRID_1,
								zoomOut: classes.innerWidth,
							}}
							isLoading={dashboards.isLoading}
							series={HMatrices.D.buildSeries(dashboards.disruption)}
							// hasOptions={!_senp}
						/>
						<MatrixBase
							id="di"
							className="matrix__di g-2x2"
							title={t(HMatrices.DI.title)}
							quadrants={HMatrices.DI.quadrants}
							optionBase={HMatrices.DI.option}
							classes={{
								zoomIn: W_GRID.W_GRID_1,
								zoomOut: classes.innerWidth,
							}}
							isLoading={dashboards.isLoading}
							series={HMatrices.DI.buildSeries(dashboards.difficultyImpact)}
							// hasOptions={!_senp}
						/>
						<MatrixBase
							id="tr"
							className="matrix__tr g-3x3"
							title={t(HMatrices.TR.title)}
							quadrants={HMatrices.TR.quadrants}
							optionBase={HMatrices.TR.option}
							isLoading={dashboards.isLoading}
							classes={{
								zoomIn: W_GRID.W_GRID_1,
								zoomOut: classes.innerWidth,
							}}
							series={HMatrices.TR.buildSeries(dashboards.timeReturn)}
							// hasOptions={!_senp}
						/>
					</STabPanel>
					<STabPanel value={TABS.forms.value} onShow={loadForms} className={classes.innerWidth}>
						<BForms
							id="idea-forms"
							mode="view"
							flow={FLOWS.IDEA}
							components={{
								title: (
									<Typography variant="h5" className="title-list">{t('form.idea.forms')}</Typography>
								),
							}}
							forms={forms}
							routes={{ nav: routes.MODAL_FORMS.path }}
							rootPath={idea.nome || '...'}
							hasEmptyState
							size="x-large"
							orientation="vertical"
						/>
					</STabPanel>
					<STabPanel value={TABS.canvas.value} onShow={loadCanvas} className={classes.innerWidth}>
						<BCanvas
							id="platform-canvas"
							mode="view"
							orientation="vertical"
							components={{
								title: (
									<Typography variant="h5" className="title-list">{t('form.idea.canvas', { flow: t('global.idea') })}</Typography>
								),
							}}
							size="x-large"
							canvas={canvas}
							flow={FLOWS.IDEA}
							routes={{ nav: routes.MODAL_CANVAS.path }}
							ideaId={idea._id}
							rootPath={idea.nome || '...'}
							hasEmptyState
						/>
					</STabPanel>
					<STabPanel value={TABS.badges.value} onShow={loadBadges} className={classes.innerWidth}>
						<BBadges
							id="idea-rewards"
							badges={{ docs: idea.premios, isLoading: !idea.premios }}
							mode={idea.plataforma?.usuarioModera
									&& !['aprovada', 'reprovada'].includes(idea.status) ? 'reward' : undefined}
							ideaId={idea._id}
							hasEmptyState
							updateBadges={loadBadges}
							orientation="vertical"
							size="x-large"
							components={{
								title: (
									<Typography variant="h5" className="title-list">{t('form.idea.badges')}</Typography>
								),
							}}
						/>
					</STabPanel>
					<STabPanel value={TABS.supports.value} className={classes.innerWidth}>
						<BFeatures
							id="features-list"
							routes={{ sponsors: routes.MODAL_SPONSORS_FOR_FEATURE.path }}
							features={{ docs: idea.funding }}
							mode={idea.plataforma?.usuarioPatrocina
										&& !['reprovada'].includes(idea.status)
								? 'support' : undefined}
							orientation="vertical"
							hasEmptyState
							size="x-large"
							idea={idea}
							updateIdea={updateIdea}
							components={{
								title: (
									<Typography variant="h5" className="title-list">{t('form.idea.features')}</Typography>
								),
							}}
						/>
					</STabPanel>
					<STabPanel value={TABS.attachments.value} className={classes.innerWidth}>
						<AttachmentsList
							attachments={{ docs: idea.anexos }}
							components={{ title: <Typography variant="h5" className="title-list">{t('global.attachments')}</Typography> }}
						/>
					</STabPanel>
				</STabs>
				<STabs
					id="idea-related-tabs"
					tabs={Object.values(RELATED_TABS)}
					className="inner w-grid-2 related-tabs"
					active={RELATED_TABS.coauthors.value}
					textColor="secondary"
					TabIndicatorProps={{ sx: { display: 'none' } }}
					sx={{ sTabs: { marginY: 6 } }}
					noAccess={noAccess}
					TabPanelProps={{
						sx: {
							marginY: 3,
						},
					}}
				>
					<STabPanel
						value={RELATED_TABS.coauthors.value}
						onShow={() => loadCoauthors({ sort: 'a-z', page: 1, limit: 20 })}
						// onMount={() => (idea ? loadCoauthors({ sort: 'a-z', page: 1, limit: 20 }) : null)}
					>
						<FiltersNavBar
							id="idea-coauthors-nav"
							params={{ sort: 'a-z', page: 1, limit: 20 }}
							onSearch={loadCoauthors} // Debounced
							onUpdate={loadCoauthors}
						>
							<MembersList
								id="coauthors-list"
								areCoauthors
								isBrainstorm
								members={coauthors}
								paddingY={3}
								loadMore={loadCoauthors} // queryParams props
							/>
						</FiltersNavBar>
					</STabPanel>
					<STabPanel
						value={RELATED_TABS.evaluations.value}
						onShow={() => loadEvaluators({ sort: 'last-first', page: 1, limit: 2 })}
					>
						<FiltersNavBar
							id="idea-evaluators-nav"
							onSearch={loadEvaluators} // Debounced
							onUpdate={loadEvaluators}
							params={{ sort: 'last-first', page: 1, limit: 2 }}
						>
							<EvaluatorsList
								total={evaluators?.totalDocs || 0}
								ideaId={match.id}
								paddingY={3}
								evaluators={evaluators}
								loadMore={loadEvaluators} // queryParams props
							/>
						</FiltersNavBar>
					</STabPanel>
					<STabPanel
						value={RELATED_TABS.sponsors.value}
						hide={!isChallenge}
					>
						<SponsorsList sponsors={{ docs: idea.supporters }} paddingY={3} />
					</STabPanel>
					<STabPanel value={RELATED_TABS.comments.value}>
						<Comments
							comments={idea.comentarios}
							objectId={match.id}
							updateComments={(comentarios: IComment) => updateIdea({ comentarios })}
						/>
					</STabPanel>
					<STabPanel
						value={RELATED_TABS.connections.value}
						onShow={() => loadConnections({ page: 1, limit: 2 })}
					>
						<CardGrid
							id="idea-connections"
							section={connections}
							loadMore={loadConnections}
							type="connections"
							GridItemProps={{
								xs: 12, sm: 6, md: 6, lg: 6, xl: 6,
							}}
							queryParams={{ limit: 2 }}
							component={(connected: IIdea) => (
								<IdeaCard
									idea={connected}
									routes={routes}
									sectionLocator="connections"
									match={idea}
									onDisconnect={onDisconnectHandler}
									// To Like
									updateIdea={(_idea) => {
										const index = connections.docs.findIndex((item) => item._id === _idea._id);
										if (index > -1) {
											connections.docs.splice(index, 1, { ...connections?.docs[index], ..._idea });
											setConnections((prevState) => ({ ...prevState, connections }));
										}
									}}
								/>
							)}
						/>
					</STabPanel>
				</STabs>
			</div>
		</GenericGrid>
	);
};

const Translation = withTranslation('common')(_Idea);

const Idea = () => (
	<React.Suspense fallback={<PageLoader />}>
		<Translation />
	</React.Suspense>
);

export default withTour(Idea);
