import React from 'react';
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
	typography: {
		fontFamily: 'var(--font-family)',
	},
	breakpoints: {
		values: {
			xs: 0,
			sm: 600,
			md: 1200,
			lg: 1536,
			xl: 1900,
		},
	},
	palette: {
		primary: {
			light: '#4C7A90',
			main: '#04486E',
			dark: '#000000',
			contrastText: '#fff',
		},
		secondary: {
			light: '#ff7961',
			main: '#000',
			dark: '#000',
			contrastText: '#fff',
		},
		error: {
			light: '#ff7961',
			main: '#E94F3F',
			dark: '#ba000d',
			contrastText: '#fff',
		},
		success: {
			main: '#3B9776',
		},
	},
	components: {
		MuiButton: {
			styleOverrides: {
				root: {
					fontSize: '1rem',
					borderRadius: '4px',
				},
			},
			defaultProps: {},
		},
	},
});

theme.typography.caption = {
	fontSize: '14px',
	lineHeight: '16px',
	fontWeight: '400',
};

theme.typography.body1 = {
	fontSize: '1rem',
	lineHeight: '1.25rem',
	fontWeight: '400',
};

theme.typography.h1 = {
	fontSize: '2.25rem',
	lineHeight: '2.25rem',
	fontWeight: '400',
};
theme.typography.h2 = {
	fontSize: '2.2rem',
	fontWeight: '400',
};
theme.typography.h3 = {
	fontSize: '1.75rem',
	fontWeight: 'inherit',
};
theme.typography.h4 = {
	fontSize: '1.5rem',
	fontWeight: 'inherit',
};
theme.typography.h5 = {
	fontSize: '1.25rem',
	fontWeight: 'inherit',
};
theme.typography.h6 = {
	fontSize: '1rem',
	fontWeight: 'inherit',
};

export { theme };
