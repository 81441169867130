// @ts-nocheck
import React, { Suspense, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import PageLoader from '@components/Loaders/PageLoader';
import FiltersNavBar from '@components/NavBar/FiltersNavBar';
import { UserAvatar } from '@components/Avatar/UserAvatar';
import { IRootState } from 'src/store';
import { IPaginatedRequest, IPagination } from 'src/@types/IShared';
import { IIdea } from 'src/@types/IIdea';
import { IPlatform } from 'src/@types/IPlatform';
import { ITeam } from 'src/@types/ITeam';
import URLS from '@constants/URLS';
import { PlatformCard } from '@components/Cards/PlatformCard';
import { IdeaCard } from '@components/Cards/IdeaCard';
import { TeamCard } from '@components/Cards/TeamCard';
import { APIUser } from '@services/apis';
import { IUserOrganization } from 'src/@types/IUser';
import { handleAxiosErrors } from '@services/auth';
import SpinnerLDSRipple from '@components/Shared/SpinnerLDSRipple';
import { Creators } from '@actions';
import CardGrid, { TCardsElements } from '@components/Grids/CardGrid';
import { IListRequest } from '@components/Lists/types';
import routes from '@routes/modal';
import { SButton } from '@components/Buttons';
import { ISTab, STabs } from '@components/STabs';
import { STabPanel } from '@components/STabs/STabPanel';
import Icon from '@components/Shared/Icon';
import { Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { SBox } from '@components/Background/SBox';
import { GenericGrid } from '@components/Grids/GenericGrid';

interface IProfileContainerProps {
	profile: IUserOrganization
	isAdministrator: boolean
}

const _ProfileContainer = (props: IProfileContainerProps & WithTranslation) => {
	const {
		profile, isAdministrator, t,
	} = props;
	const navigate = useNavigate();
	return (
		<Grid container item className="profile-infos" spacing={2} marginY={3} display="flex" flexDirection="column">
			<Grid item>
				<UserAvatar
					profile={profile}
					size="xxx-large"
				/>
			</Grid>
			<Grid item>
				<span className="name">{profile.user._nome || profile.user.nome}</span>
			</Grid>
			{profile.user?.createdAt && (
				<Grid item>
					{t('dates.fullDate', { date: new Date(profile.user?.createdAt) })}
				</Grid>
			)}
			{isAdministrator && (
				<Grid item>
					<SButton color="secondary" variant="outlined" size="large" onClick={() => navigate(URLS.SETTINGS)}>
						{t('global.edit')}
					</SButton>
				</Grid>
			)}
		</Grid>
	);
};

const TTranslation = withTranslation('common')(_ProfileContainer);

const ProfileContainer = (props: IProfileContainerProps) => (
	<Suspense fallback={<SpinnerLDSRipple />}>
		<TTranslation {...props} />
	</Suspense>
);

interface IProfile extends IUserOrganization {
	isLoading?: boolean
}

interface IProfileTabs {
	BRAINSTORMS: ISTab,
	CHALLENGES: ISTab
	IDEAS: ISTab
	TEAMS: ISTab
}

declare type TElements = 'challenges' | 'brainstorms' | 'teams' | 'ideas'

interface IState {
	challenges: IPagination<IPlatform>
	brainstorms: IPagination<IPlatform>
	ideas: IPagination<IIdea>
	teams: IPagination<ITeam>
}

declare type TProps = WithTranslation

const PProfile = ({ t }: TProps) => {
	const match = useParams<{id: string}>();
	const dispatch = useDispatch();

	const { userOrganizationProfile } = useSelector((state: IRootState) => state);

	const [queryParams, setQueryParams] = useState<IPaginatedRequest>({ limit: screen.width <= 1536 ? 2 : 3, sort: 'last-first', page: 1 });

	const [state, setState] = useState<IState>({
		ideas: { docs: [], isLoading: true },
		brainstorms: { docs: [], isLoading: true },
		challenges: { docs: [], isLoading: true },
		teams: { docs: [], isLoading: true },
	});

	const [profile, setProfile] = useState<IProfile>();

	const TABS: IProfileTabs = {
		BRAINSTORMS: { value: 'brainstorms' },
		CHALLENGES: { value: 'challenges' },
		IDEAS: { value: 'ideas' },
		TEAMS: { value: 'teams' },
	};

	const onDeleteHandler = (elementId: string) => {
		Object.keys(state).forEach((k) => {
			const key = k as TElements;
			const index = state[key]?.docs?.findIndex((item: any) => item._id === elementId);
			if (!index || index > -1) {
				state[key].docs?.splice(index, 1);
				setState((prevState) => ({
					...prevState,
					[key]: {
						...prevState[key],
					},
				}));
			}
		});
	};

	const updateHandler = (element: TCardsElements) => {
		Object.keys(state).forEach((k) => {
			const key = k as TElements;
			const index = state[key]?.docs?.findIndex((item) => item._id === element._id);
			if (!index || index > -1) {
				// @ts-ignore
				state[key]?.docs?.splice(index, 1, { ...state[key]?.docs[index], ...element });
				setState((prevState) => ({
					...prevState,
					[key]: {
						...prevState[key],
					},
				}));
			}
		});
	};

	const loadPlatforms = async (params: IListRequest) => {
		try {
			const key = `${params.filter}s` as 'brainstorms' | 'challenges';
			const response = await APIUser.getPlatforms(match.id, params);
			setState((prevState) => ({
				...prevState,
				[key]: {
					...prevState[key],
					...response.data,
					docs: response.data.hasPrevPage
						? prevState[key].docs.concat(response.data.docs)
						: response.data.docs,
					isLoading: false,
				},
			}));
		} catch (err) {
			handleAxiosErrors(err);
		} finally { // loadMore
			if (typeof params.callback === 'function') {
				params.callback();
			}
		}
	};

	const loadTeams = async (params: IListRequest) => {
		try {
			const response = await APIUser.getTeams(match.id, params);
			setState((prevState) => ({
				...prevState,
				teams: {
					...prevState.teams,
					...response.data,
					docs: response.data.hasPrevPage
						? prevState.teams?.docs.concat(response.data.docs)
						: response.data.docs,
					isLoading: false,
				},
			}));
		} catch (err) {
			handleAxiosErrors(err);
		} finally { // loadMore
			if (typeof params.callback === 'function') {
				params.callback();
			}
		}
	};

	const loadIdeas = async (params: IListRequest) => {
		try {
			const response = await APIUser.getIdeas(match.id, params);
			setState((prevState) => ({
				...prevState,
				ideas: {
					...prevState.ideas,
					...response.data,
					docs: response.data.hasPrevPage
						? prevState.ideas?.docs.concat(response.data.docs)
						: response.data.docs,
					isLoading: false,
				},
			}));
		} catch (err) {
			handleAxiosErrors(err);
		} finally { // loadMore
			if (typeof params.callback === 'function') {
				params.callback();
			}
		}
	};

	const loadProfile = () => {
		dispatch(Creators.USER_ORGANIZATION.getProfile({
			profileId: match.id,
			onSuccess: (response: IProfile) => setProfile((prevState) => ({ ...prevState, ...response })),
		}));
	};

	const updateList = (filter: TElements, params: IPaginatedRequest) => {
		setQueryParams(params);
		switch (filter) {
			case 'brainstorms':
				loadPlatforms({ ...params, page: 1, filter: 'brainstorm' });
				break;
			case 'challenges':
				loadPlatforms({ ...params, page: 1, filter: 'challenge' });
				break;
			case 'ideas':
				loadIdeas({ ...params, page: 1 });
				break;
			case 'teams':
				loadTeams({ ...params, page: 1 });
				break;
			default:
				break;
		}
	};

	React.useEffect(() => {
		if (match.id) {
			loadProfile();
			loadPlatforms({ ...queryParams, filter: 'brainstorm' });
			loadPlatforms({ ...queryParams, filter: 'challenge' });
			loadIdeas(queryParams);
			loadTeams(queryParams);

			dispatch(Creators.IDEAS.updateIdeas({ syncIdea: updateHandler }));
			dispatch(Creators.PLATFORMS.updatePlatform({ syncPlatform: updateHandler }));
		}
	}, [match.id]);

	if (!profile || !profile.user || profile.isLoading) {
		return <PageLoader />;
	}

	// Translate tabs
	Object.keys(TABS).forEach((k) => {
		const key = k as keyof IProfileTabs;
		// @ts-ignore
		TABS[key].label = `${t(`global.${TABS[key].value}`)} (${state[TABS[key].value].totalDocs || 0})`;
		TABS[key].icon = <Icon icon={TABS[key].value} />;
		TABS[key].iconPosition = 'start';
	});

	const isAdministrator = userOrganizationProfile._id === match.id;

	const GridItemProps = { xs: 12, sm: 6, md: 6, lg: 6, xl: 4 }

	return (
		<GenericGrid id="profilePage" className="s-grid-page" paddingTop={6}>
			<SBox id="profilePage" loading={!userOrganizationProfile} className="w-grid-1 inner" modus="page">
				<STabs
					tabs={Object.values(TABS)}
					components={
						{ BeforeTabs: <ProfileContainer profile={profile} isAdministrator={isAdministrator} /> }
					}
					active={TABS.BRAINSTORMS.value}
					id="profile-tabs"
					orientation="vertical"
					textColor="secondary"
					TabIndicatorProps={{ sx: { display: 'none' } }}
					sx={{
						sTabs: {
							display: 'flex',
							'.MuiTab-root': { justifyContent: 'flex-start' },
						},
					}}
					TabsProps={{
						xs: 12,
						sm: 4,
						md: 3,
					}}
					TabPanelProps={{
						xs: 12,
						sm: 8,
						md: 9,
						sx: {
							height: 'auto',
							borderLeft: 1,
							borderColor: 'divider',
							paddingX: { xs: 0, sm: 3, md: 12 },
						},
					}}
				>
					<STabPanel value={TABS.BRAINSTORMS.value}>
						<FiltersNavBar
							id="profile-brainstorms-nav"
							params={{ ...queryParams, filter: 'brainstorm' }}
							onUpdate={(params) => updateList('brainstorms', params)}
						>
							<CardGrid
								section={state.brainstorms}
								loadMore={loadPlatforms}
								type="brainstorms"
								GridItemProps={GridItemProps}
								component={(platform: IPlatform) => (
									<PlatformCard
										platform={platform}
										sectionLocator="brainstorms"
										routes={routes}
										onDelete={onDeleteHandler}
										updatePlatform={updateHandler}
									/>
								)}
							/>
						</FiltersNavBar>

					</STabPanel>
					<STabPanel value={TABS.CHALLENGES.value}>
						<FiltersNavBar
							id="profile-challenges-nav"
							params={{ ...queryParams, filter: 'challenge' }}
							onUpdate={(params) => updateList('challenges', params)}
						>
							<CardGrid
								section={state.challenges}
								loadMore={loadPlatforms}
								type="challenges"
								GridItemProps={GridItemProps}
								component={(platform: IPlatform) => (
									<PlatformCard
										platform={platform}
										sectionLocator="challenges"
										routes={routes}
										onDelete={onDeleteHandler}
										updatePlatform={updateHandler}
									/>
								)}
							/>
						</FiltersNavBar>
					</STabPanel>
					<STabPanel value={TABS.IDEAS.value}>
						<FiltersNavBar
							id="profile-ideas-nav"
							params={queryParams}
							onUpdate={(params) => updateList('ideas', params)}
						>
							<CardGrid
								section={state.ideas}
								loadMore={loadIdeas}
								type="ideas"
								GridItemProps={GridItemProps}
								component={(idea: IIdea) => (
									<IdeaCard
										idea={idea}
										sectionLocator="ideas"
										routes={routes}
										onDelete={onDeleteHandler}
										updateIdea={updateHandler}
									/>
								)}
							/>
						</FiltersNavBar>
					</STabPanel>
					<STabPanel value={TABS.TEAMS.value}>
						<FiltersNavBar
							id="profile-teams-nav"
							params={queryParams}
							onUpdate={(params) => updateList('teams', params)}
						>
							<CardGrid
								section={state.teams}
								loadMore={loadTeams}
								type="teams"
								GridItemProps={GridItemProps}
								component={(team: ITeam) => (
									<TeamCard team={team} />
								)}
							/>
						</FiltersNavBar>
					</STabPanel>
				</STabs>
			</SBox>
		</GenericGrid>
	);
};

const Translation = withTranslation('common')(PProfile);

const Profile = () => (
	<React.Suspense fallback={<PageLoader />}>
		<Translation />
	</React.Suspense>
);

export default Profile;
