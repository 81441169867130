// @ts-nocheck
import React, { useState, ReactNode } from 'react';
import { goToLink } from '@utils/ReactUtils';
import Icon from '@components/Shared/Icon';
import { Box, BoxProps } from '@mui/material';

export declare type TSizeCircle = 'auto' | 'x-small' | 'small' | 'middle' | 'large' | 'x-large' | 'xx-large' | 'xxx-large'

export interface DynamicCircleProps extends BoxProps {
	icon?: string | ReactNode
	iconClass?: string
	clickable?: boolean
	initial?: string
	image?: string
	background?: string
	color?: string
	href?: string
	size?: TSizeCircle
	onClick?: (event: React.MouseEvent<HTMLDivElement>) => void
	disabled?: boolean
	action?: string
}

const DynamicCircle = (props: DynamicCircleProps) => {
	const {
		icon,
		href,
		image,
		color,
		onClick,
		id = '',
		initial,
		children,
		disabled,
		clickable,
		className,
		background,
		iconClass = '',
		size = 'small',
		action,
		...rest
	} = props;

	const [loaded, setLoaded] = useState(true);
	const [hover, setHover] = useState(false);

	const handleClick = () => {
		if (disabled) return;
		if (href) {
			goToLink(href);
			return;
		}
		if (typeof onClick === 'function') {
			onClick();
		}
	};

	const renderChildren = () => {
		if (children) return children;

		if (typeof icon === 'string' && !initial) {
			if (action && hover) {
				return undefined;
			}
			return <Icon icon={icon} className={iconClass || ''} />;
		}
		if (typeof icon === 'object') return icon;
		if ((!image && !initial && !icon && !children) || !loaded) {
			return <img className="avatar" loading="lazy" src="/images/default.jpg" alt="" />;
		}
		if (loaded && image) {
			return <img className="avatar" loading="lazy" alt="" src={image} onError={() => setLoaded(false)} />;
		}
		if (initial) {
			return initial?.getInitials();
		}

		return null;
	};

	return (
		<Box
			id={id}
			className={`s-circle dynamic-circle ${size} ${className || ''} ${action && hover ? 'has-action' : ''}`}
			onClick={handleClick}
			aria-hidden
			onMouseLeave={!disabled ? () => setHover(false) : null}
			onMouseMove={!disabled ? () => setHover(true) : null}
			style={{
				backgroundColor: (!image && !initial && !icon && !children) || image ? 'transparent' : background || '',
				color,
				cursor: (action && hover) || (clickable && !disabled) ? 'pointer' : 'auto',
			}}
			{...rest}
		>
			{action && hover ? <span className="">{action}</span> : renderChildren()}
		</Box>
	);
};

export { DynamicCircle };
