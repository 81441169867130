// @ts-nocheck
import React from 'react';
import { DynamicCircle, DynamicCircleProps } from '@components/DynamicCircle';
import { IconButton, Tooltip } from '@mui/material';

export interface ISeeMoreCircleProps extends DynamicCircleProps {
	isCollapsed?: boolean
	id: string
	numbersToShow?: number
	titleTooltip: string
	more: []
}

const SeeMoreCircle = ({
	onClick, more, isCollapsed,
	numbersToShow, titleTooltip, size, ...rest
}: ISeeMoreCircleProps) => {
	let _length = more?.length - numbersToShow;

	if (isCollapsed) {
		if (more?.length < 2) return null;
		_length = more.length;
	} else if (more?.length < numbersToShow) {
		return null;
	}

	if (_length < 1) return null;

	return (
		<Tooltip classes={{ popper: 'secondary' }} arrow title={titleTooltip} placement="right">
			<IconButton
				sx={{ padding: 0, cursor: 'pointer' }}
				className="circle"
				color="inherit"
				onClick={onClick}
			>
				<DynamicCircle
					background="transparent"
					size={size}
					{...rest}
				>
					{_length < 100 ? _length : '+99'}
				</DynamicCircle>
			</IconButton>
		</Tooltip>
	);
};

SeeMoreCircle.defaultProps = {
	isCollapsed: false,
	numbersToShow: 3,
};

export { SeeMoreCircle };
