import React from 'react';
import {
	AutocompleteChangeReason,
	AutocompleteChangeDetails,
	createFilterOptions,
} from '@mui/base';
import Autocomplete, { AutocompleteRenderGetTagProps } from '@mui/material/Autocomplete';
import _ from 'lodash';
import { STextField } from '@forms/Components';
import { orderOptions } from './utils';
import { useTranslation } from 'react-i18next';

export interface IAutocompleteOption {
	value: string
  label: string
  isFixed?: boolean
	_id?: string
	id?: string
}

export interface SAutocompleteProps<T> {
	id: string
	loading?: boolean
	isAsync?: boolean
	loadOptions?: boolean
	disabled?: boolean
	value: T[]
	unique?: string
	options: readonly T[]
	freeSolo?: boolean
	limitTags?: number
	error?: boolean
	regex?: RegExp
	helperText?: string
	multiple?: boolean
	single?: boolean
	renderTags?: (
    value: T[],
    getTagProps: AutocompleteRenderGetTagProps
  ) => React.ReactNode;
	onReorder?: (value: readonly T[]) => void
	placeholder?: string
	onChange?: (
    event: React.SyntheticEvent,
    value: readonly T[] | T,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<T>,
  ) => void
}

const filter = createFilterOptions<IAutocompleteOption>();

export const SAutocomplete = (props: SAutocompleteProps<IAutocompleteOption>) => {
	const {
		id, value, onChange, single, loading, regex = /^[a-zA-ZÀ-ÖØ-öø-ÿ ]+$/, isAsync, placeholder, loadOptions, unique = 'value',
		freeSolo, error, helperText, renderTags, disabled, ...rest
	} = props;
	const [open, setOpen] = React.useState(false);
	const [innerError, setInnerError] = React.useState<string | undefined >();

	const { t } = useTranslation()

	// Use in case of split and onPaste
	const normalizeTags = (tag: string):
	IAutocompleteOption[] => {
		let text = tag;

		const validated: IAutocompleteOption[] = [];

		if (regex) {
			_.uniq(text.match(regex) || [])
				.forEach((option: string) => {
					text = text?.replace(
						new RegExp(option, 'g'),
						'',
					);
					validated.push({ value: option, label: option });
				});
		} else {
			validated.push({ value: tag, label: tag });
		}

		const newTags: IAutocompleteOption[] = _.uniqBy(
			value.concat(validated), // concatenates with existing value
			(item) => item.value.toLowerCase(),
		);

		return newTags;
	};

	const handleChange = (
		event: React.SyntheticEvent,
		values: readonly IAutocompleteOption[],
		reason: AutocompleteChangeReason,
		details?: AutocompleteChangeDetails<IAutocompleteOption>,
	) => {
		let fixed = [];

		if (typeof onChange === 'function') {
			switch (reason) {
				case 'createOption':
					if (!freeSolo || single) return;

					if (typeof details?.option === 'string') {
						// @ts-ignore
						if (regex && details?.option?.trim()?.match(regex) && details.option.length > 1) {
							const normalizedTags = normalizeTags(details?.option); // + value and unique

							// @ts-ignore
							onChange(event, orderOptions(normalizedTags), reason, details);
						} else {
							setInnerError(t('messages.invalid.entry'));
						}
					}
					break;
				case 'removeOption':
					if (typeof details === 'object' && details.option.isFixed) {
						return;
					}
					// @ts-ignore
					onChange(event, values, reason, details);
					break;

				case 'clear':
					fixed = value.filter((v) => v.isFixed);
					// @ts-ignore
					onChange(event, fixed, reason, details);
					break;
				default:
					if (single && details?.option) { // single props
						// @ts-ignore
						onChange(event, orderOptions([details.option]), reason, details);
						return;
					}

					// @ts-ignore
					onChange(
						event,
						// @ts-ignore
						orderOptions(values.map((item) => ({ ...item, label: item.value }))),
						reason,
						details,
					);
					break;
			}
		}
	};

	return (
		<Autocomplete
			disabled={disabled}
			freeSolo={freeSolo}
			open={open}
			id={id}
			multiple
			onOpen={() => {
				setOpen(true);
			}}
			onClose={() => {
				setOpen(false);
			}}
			loading={loadOptions}
			className="s-autocomplete"
			value={value}
			// @ts-ignore
			onChange={handleChange}
			renderTags={renderTags}
			renderInput={(params) => (
				<STextField
					{...params}
					variant="outlined"
					placeholder={placeholder}
					error={typeof innerError === 'string' || error}
					helperText={innerError || helperText}
					isLoading={loading}
					noMatch
					onChange={() => {
						setInnerError(undefined);
					}}
					isAsync={isAsync}
					InputProps={{
						...params.InputProps,
						inputProps: {
							...params.inputProps,
						},
					}}
				/>
			)}
			filterOptions={(opts, state) => {
				const filtered = filter(opts, state);
				if (freeSolo) {
					const { inputValue } = state;
					// Suggest the creation of a new value
					const isExisting = opts.some((option) => inputValue === option.value);
					if (typeof inputValue === 'string' && inputValue !== '' && !isExisting
					&& inputValue.length > 1) {
						filtered.push({
							value: inputValue,
							label: `Add "${inputValue}"`,
						});
					}
				}
				// @ts-ignore
				return _.differenceBy(filtered, value, unique);
			}}
			{...rest}
		/>
	);
};
