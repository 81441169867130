import ModalPlatformsToAdd from '@components/Modals/Ideas/ModalPlatformsToAdd';
import ModalFeatures from '@components/Modals/Features/ModalFeatures';
import ModalFeature from '@components/Modals/Features/ModalFeature';
import ModalIdea from '@components/Modals/Ideas/ModalIdea';
import ModalTemplates from '@components/Modals/Templates/ModalTemplates';
import ModalCanvas from '@components/Modals/Canvas/ModalCanvas';
import ModalCanvasEdit from '@components/Modals/Canvas/ModalCanvasEdit';
import ModalForms from '@components/Modals/Forms/ModalForms';
import ModalForm from '@components/Modals/Forms/ModalForm';
import ModalTemplate from '@components/Modals/Templates/ModalTemplate';

const rootPath = '/:idea';

const ModalIdeasRouter = {
	MODAL_IDEA: {
		path: rootPath,
		component: ModalIdea,
		fullScreen: true,
		maxWidth: 'xl',
	},
	MODAL_IDEA_TEMPLATES: {
		path: `${rootPath}/<global.template>s`,
		component: ModalTemplates,
		fullScreen: true,
		maxWidth: 'xl',
	},
	MODAL_IDEA_TEMPLATE: {
		path: `${rootPath}/<global.template>s/:template`,
		component: ModalTemplate,
		fullScreen: true,
		maxWidth: 'xl',
	},
	MODAL_IDEA_CANVAS: {
		path: `${rootPath}/canvas`,
		component: ModalCanvas,
		fullScreen: true,
		maxWidth: 'xl',
	},
	MODAL_IDEA_CANVAS_MODEL: {
		path: `${rootPath}/canvas/:model`,
		component: ModalCanvasEdit,
		fullScreen: true,
		maxWidth: 'xl',
	},
	MODAL_IDEA_CANVAS_DIRECT: {
		path: `${rootPath}/:model`,
		component: ModalCanvasEdit,
		fullScreen: true,
		maxWidth: 'xl',
	},
	MODAL_IDEA_FEATURES: {
		path: `${rootPath}/<global.features>`,
		component: ModalFeatures,
		fullScreen: true,
		maxWidth: 'xl',
	},
	MODAL_IDEA_FEATURE: {
		path: `${rootPath}/<global.features>/:feature`,
		component: ModalFeature,
		fullScreen: true,
		maxWidth: 'xl',
	},
	MODAL_IDEA_FORMS: {
		path: `${rootPath}/<global.form>s`,
		component: ModalForms,
		fullScreen: true,
		maxWidth: 'xl',
	},
	MODAL_IDEA_FORM: {
		path: `${rootPath}/<global.form>s/:form`,
		component: ModalForm,
		fullScreen: true,
		maxWidth: 'xl',
	},
	MODAL_IDEA_FORM_DIRECT: {
		path: `${rootPath}/:form`,
		component: ModalForm,
		fullScreen: true,
		maxWidth: 'xl',
	},
	MODAL_IDEA_FEATURE_DIRECT: {
		path: `${rootPath}/:feature`,
		component: ModalFeature,
		fullScreen: true,
		maxWidth: 'xl',
	},
	MODAL_IDEA_PLATFORMS: {
		path: `${rootPath}/:platformType`,
		component: ModalPlatformsToAdd,
		fullScreen: true,
		hideClose: true,
		maxWidth: 'xl',
	},
};

export { ModalIdeasRouter };
