// @ts-nocheck
import React, { useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { SFormGroup, SInputLabel } from '@components/DynamicForm/ComponentsCore';
import { HelpButton, SButton, GoogleUploadButton } from '@components/Buttons';
import Icon from '@components/Shared/Icon';
import { ComputerUploadButton } from '@components/Buttons/ComputerUploadButton';
import { Box } from '@mui/material';

interface IOwnProps {
	id: string
	className?: string
	isLoading?: boolean
	isDisabled?: boolean
	onRemove: (id: string, callback: () => void) => void
	onClick: (file: any) => void
}

declare type TProps = IOwnProps & WithTranslation
declare type TUploadType = 'computer-upload' | 'google-driver' | undefined;

const _UploadFiles = (props: TProps) => {
	const {
		values = [], t, className, isLoading, isDisabled, onRemove, onClick, id,
	} = props;

	const [removeId, setRemoveId] = useState<string | undefined>(undefined);
	const [uploadType, setUploadType] = useState<TUploadType>(undefined);

	const onRemoveHandler = (id) => {
		if (isDisabled) return;
		setRemoveId(id);
		onRemove(id, () => setRemoveId(undefined));
	};

	const onClickHandler = (file: any, type: TUploadType) => {
		if (typeof onClick === 'function') {
			setUploadType(type);
			onClick(file);
		}
	};

	return (
		<Box>
			{
				values?.map((item) => (
					<div
						key={item._id}
						className="file-content"
					>
						<Box className="content-left" sx={{ gap: 1 }}>
							{
								item.attType === 'google-drive' ? (
									<Icon icon="google-driver" />
								) : (
									<Icon icon="pc" />
								)
							}
							<a
								href={item.metadata.url}
								target="_blank"
								rel="noreferrer"
								className="word-ellipsis"
							>
								{item.metadata.name}
							</a>
						</Box>
						<SButton
							type="button"
							color="primary"
							variant="text"
							size="small"
							disabled={isDisabled}
							isLoading={isLoading && removeId === item._id}
							onClick={() => onRemoveHandler(item._id)}
							startIcon={<Icon icon="remove" />}
						>
							{t('global.delete')}
						</SButton>
					</div>
				))
			}
			<Box className="upload-buttons" sx={{ display: 'flex', gap: 1 }}>
				<GoogleUploadButton
					id={`google-upload-${id}`}
					onClick={(file) => onClickHandler(file, 'google-driver')}
					disabled={isDisabled}
					isLoading={isLoading && !removeId && uploadType === 'google-driver'}
				/>
				<ComputerUploadButton
					id={`computer-upload-${id}`}
					onClick={(file) => onClickHandler(file, 'computer-upload')}
					isLoading={isLoading && !removeId && uploadType === 'computer-upload'}
					disabled={isDisabled}
				/>
			</Box>
		</Box>
	);
};

const Translation = withTranslation('common')(_UploadFiles);

const UploadFiles = (props: IOwnProps) => (
	<Translation {...props} />
);

UploadFiles.defaultProps = {
	isDisabled: false,
};

export { UploadFiles };
