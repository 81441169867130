import React, { Suspense } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import UnauthenticatedHeader from '@components/Header/UnauthenticatedHeader';
import { LogInForm } from '@components/Forms/LogInForm';
import PageLoader from '@components/Loaders/PageLoader';
import { useLocation, useParams } from 'react-router-dom';
import URLS from '@constants/URLS';
import { Box, Grid, Typography } from '@mui/material';
import { GenericGrid } from '@components/Grids/GenericGrid';

type TProps = WithTranslation

const UCheckAccount = ({ t }: TProps) => {
	const location = useLocation();
	const { orgId } = useParams();

	return (
		<GenericGrid id="check-account-page" className="check__account--page s-grid-page">
			<UnauthenticatedHeader modus={location.pathname === URLS.LOGIN ? 'signUp' : 'logIn'} />
			<Box
				className="w-grid-3 inner unauthenticated"
				sx={{
					display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%',
				}}
			>
				<Grid container id="checkoutAccount" spacing={3}>
					<Grid item container textAlign="center" spacing={2}>
						<Grid item xs={12}>
							<Typography variant="h1">
								{location.pathname === URLS.LOGIN ? t('form.login.title') : t('register.getStarted')}
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<Typography variant="body1">
								{location.pathname === URLS.LOGIN ? t('form.login.subTitle') : t('register.noCreditCard')}
							</Typography>
						</Grid>
					</Grid>
					<Grid item xs={12}>
						<LogInForm
							path={location.pathname}
							hasSAMLSSO={false}
							orgId={orgId}
						/>
					</Grid>
				</Grid>
				<Box className="footer__info">
					<p dangerouslySetInnerHTML={{ __html: t('register.acknowledgement') }} />
				</Box>
			</Box>
		</GenericGrid>
	);
};

const Translation = withTranslation('common')(UCheckAccount);

const CheckAccount = () => (
	<Suspense fallback={<PageLoader />}>
		<Translation />
	</Suspense>
);

export default CheckAccount;
