import React, { Suspense } from 'react';

interface IOwnProps {
	className?: string
	label: string
}

const SSticker = (props: IOwnProps) => (
	<div className={`sticker ${props.className || ''}`}>
		{props.label}
	</div>
);

const Sticker = (props: IOwnProps) => (
	<Suspense fallback={<div />}>
		{typeof props.label === 'undefined' ? <div /> : <SSticker {...props} />}
	</Suspense>
);

export { Sticker };
