// @ts-nocheck
import _ from 'lodash';
import { useTour } from '@reactour/tour';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { useSelector, useDispatch } from 'react-redux';
import { WithTranslation, withTranslation } from 'react-i18next';
import {
	Suspense,
	useState,
	useEffect,
	useCallback,
} from 'react';
import {
	Box,
	Grid,
	DialogTitle,
	DialogContent,
	AutocompleteChangeReason,
	AutocompleteChangeDetails,
	DialogActions,
	InputLabel,
} from '@mui/material';
import { Creators } from '@actions';
import { IRootState } from 'src/store';
import { SMenu } from '@components/SMenu';
import Icon from '@components/Shared/Icon';
import { APIGlobal } from '@services/apis';
import { goToLink } from '@utils/ReactUtils';
import { IPlatform } from 'src/@types/IPlatform';
import type { IModal } from '@modules/Modal/types';
import { handleAxiosErrors } from '@services/auth';
import { TFlow, TObject } from 'src/@types/IShared';
import ModalHeader from '@modules/Modal/ModalHeader';
import { FLOWS, maxLength } from '@constants/GLOBAL';
import { IUserOrganization } from 'src/@types/IUser';
import { BuildTour, STour } from '@components/STour';
import { BCover } from '@components/Background/BCover';
import { BForms } from '@components/Background/BForms';
import { BCanvas } from '@components/Background/BCanvas';
import { BBadges } from '@components/Background/BBadges';
import RichTextEditor from '@components/RichTextEditor';
import { HelpButton, SButton } from '@components/Buttons';
import { debounce, getTextFromHTML } from '@utils/helpers';
import { STextField, SPasswordField } from '@forms/Components';
import { UploadFiles } from '@components/Background/UploadFiles';
import { passwordValidation } from '@forms/Components/SPasswordField';
import { DialogBox } from '@modules/Modal/Components';
import { TemplateDetails } from '@components/Background/TemplateDetails';
import { SAutocomplete } from '@components/DynamicForm/Components/SSelect/SAutocomplete';
import ModalFeedback, { IFeedbackProps } from '@components/Modals/ModalFeedback';
import {
	Form,
	SRadio,
	SFormGroup,
	SInputLabel,
} from '@components/DynamicForm/ComponentsCore';
import {
	Tag,
	Tags,
	UserTag,
	getUserOptions,
} from '@components/DynamicForm/Components/SSelect/Components';

import { SToast } from '@modules/SToast';
import { W_GRID } from '@components/Grids/GenericGrid';
import { SBox } from '@components/Background/SBox';
import ModalCover from './Shared/ModalCover';

declare type TProps = IModal & WithTranslation;

const MModalPlatform = (props: TProps) => {
	const { t, routes } = props;

	const dispatch = useDispatch();
	const tour = useTour();
	const { platforms, userOrganizationProfile, shared } = useSelector((state: IRootState) => state);
	const [inProgress, setInProgress] = useState<keyof IPlatform | string | 'PUBLISH' | 'GET_PLATFORM' | undefined>();
	const [tagOptions, setTagOptions] = useState({ docs: [], isLoading: false });
	const [open, setOpen] = useState<'cover' | undefined>(undefined);
	const [errors, setErrors] = useState<TObject>({});
	const [password, setPassword] = useState({ value: '', change: false });
	const [participantsOptions, setParticipantsOptions] = useState([]);
	const flow = platforms?.type?.toLowerCase();
	const platformPage = document.getElementById('platform-page');
	const disabled = platforms?.mode === 'view' || !platforms.createdAt;
	const [feedbackProps, setFeedbackProps] = useState<IFeedbackProps>({ open: false });
	const flowT = t(`global.${flow}`);
	const isEmptyTags = getTextFromHTML(platforms?.resumo || '').length < 8;
	const [anchorTimesEl, setAnchorTimesEl] = useState<null | HTMLElement>(null);
	const [anchorQuantitiesEl, setAnchorQuantitiesEl] = useState<null | HTMLElement>(null);

	const timeOptions = [
		{ value: '10 horas', label: `10 ${t('global.hour')}s` },
		{ value: '12 horas', label: `12 ${t('global.hour')}s` },
		{ value: '24 horas', label: `24 ${t('global.hour')}s` },
		{ value: '24 horas', label: `48 ${t('global.hour')}s` },
		{ value: '72 horas', label: `72 ${t('global.hour')}s` },
		{ value: '7 dias', label: `7 ${t('global.day')}s` },
		{ value: '30 dias', label: `30 ${t('global.day')}s` },
		{ value: '60 dias', label: `60 ${t('global.day')}s` },
		{ value: '90 dias', label: `90 ${t('global.day')}s` },
		{ value: '180 dias', label: `180 ${t('global.day')}s` },
	];

	const permissions = [
		{
			value: 'publica',
			icon: 'public',
			label2: t('permission.publicAll'),
		},
		{
			value: 'privada',
			icon: 'private',
			label2: t('permission.privateMore'),
		},
		{
			value: 'secreta',
			icon: 'secret',
			label2: t('permission.secretMore'),
		},
	];

	const loadTagOptions = () => {
		setTagOptions({ ...tagOptions, isLoading: true });
		dispatch(Creators.SHARED.getTags({
			onSuccess: (tags) => {
				setTagOptions({
					...tags,
					isLoading: false,
				});
			},
		}));
	};

	const onPublishHandler = (event) => {
		event.preventDefault();
		event.stopPropagation();
		tour.setIsOpen(false);
		if (!_.isEmpty(errors)) return;
		setInProgress('PUBLISH');
		dispatch(Creators.PLATFORMS.publishPlatform({
			password: password.value,
			callback: () => setInProgress(),
			onError: (messages) => {
				setErrors({ ...errors, ...messages });
			},
		}));
	};

	const onUsersHandler = (
		key: 'string',
		value: readonly IUserOrganization[],
		reason: AutocompleteChangeReason,
		details?: AutocompleteChangeDetails<IUserOrganization>,
	) => {
		setInProgress(key);
		if (details?.option?.isFixed && reason === 'removeOption') return;
		dispatch(Creators.PLATFORMS.handlePlatform({
			key,
			value: Array.isArray(value) ? value.map((item) => item._id) : [],
			noDelay: true,
			hasWait: true,
			onSuccess: (response) => {
				dispatch(Creators.PLATFORMS.updatePlatform(response));
			},
			callback: setInProgress,
		}));
	};

	const registerTags = useCallback(debounce(async (html: string) => {
		try {
			const response = await APIGlobal.postRegisterTags(html);

			if (!response) return;

			setInProgress('tags');
			dispatch(
				Creators.PLATFORMS.handlePlatform({
					key: 'tags',
					noDelay: true,
					value: response.data.keywords.map((k) => ({ nome: k[0], value: k[0], label: k[0] })),
					onSuccess: () => SToast.success(t('messages.success.tags.added')),
					callback: () => setInProgress(undefined),
				}),
			);

			SToast.success(t('messages.success.tags.generated'));
		} catch (err) {
			handleAxiosErrors(err);
		}
	}, 1000), []);

	const onTagsHandler = (html: string) => {
		if (isEmptyTags) return;
		setInProgress('generate-tags');
		registerTags(html);
	};

	const onErrorHandler = (field) => delete errors[field];

	const onPlatformHandler = (key: keyof IPlatform | string, value: any) => {
		setInProgress(key);
		onErrorHandler(key);

		if (key === 'challengeFormat') {
			setInProgress(value.type);
		}

		switch (key) {
			case 'tags':
				dispatch(Creators.PLATFORMS.handlePlatform({
					key,
					value: value.map((item) => ({ value: item.value, label: item.value })),
					callback: setInProgress,
					onError: (messages) => setErrors({ ...errors, ...messages }),
				}));
				break;
			case 'resumo':
				dispatch(Creators.PLATFORMS.handlePlatform({
					key,
					value,
					callback: setInProgress,
					// onSuccess: ({ resumo }) => {
					//   if (typeof resumo === 'undefined'
					//     || (typeof resumo === 'string' && window.$(resumo).text().length < 1))
					//     {
					//     setActiveField('tags');
					//     dispatch(Creators.PLATFORMS.handlePlatform({
					//       platformId: platforms._id,
					//       key: 'tags',
					//       value: [],
					//       callback: () => setActiveField(undefined),
					//       // onError: (messages) => setErrors({ ...errors, ...messages }),
					//     }))
					//   }
					// },
					onError: (messages) => setErrors({ ...errors, ...messages }),
				}));

				break;
			case 'senha':
			case 'passwordRecurrency':
			case 'permissao':
				if (['secreta', 'publica', 'privada'].includes(value)) {
					setInProgress(value);
				}
				if (value === 'secreta') {
					setPassword({ action: true, password: '' });
				}
				dispatch(
					Creators.PLATFORMS.handlePlatform({
						noDelay: true,
						key,
						value,
						callback: setInProgress,
						onSuccess: (response) => {
							if (['senha'].includes(key)) {
								SToast.success(t('messages.success.platform.senha'));
							}

							if (['permissao'].includes(key)) {
								SToast.success(t(
									'messages.success.platform.permission',
									{ permission: t(`platform.permission.${value}`) },
								));
								dispatch(Creators.PLATFORMS.updatePlatform(response));
							}
						},
						onError: (messages) => setErrors({ ...errors, ...messages }),
					}),
				);
				break;
			case 'anexos':
				dispatch(Creators.PLATFORMS.selectAttachments(value, () => setInProgress()));
				break;
			default:
				dispatch(Creators.PLATFORMS.handlePlatform({
					key,
					value,
					callback: () => setInProgress(),
					onError: (messages) => setErrors({ ...errors, ...messages }),
				}));
				break;
		}
	};

	const onCloseHandler = (action?: 'view') => {
		BuildTour.close(tour);
		dispatch(Creators.SHARED.updateShared({ tour: undefined }));
		dispatch(Creators.MODAL.hideModal());

		if (!platforms) return;

		if (action === 'view' && !platformPage) {
			setTimeout(() => {
				goToLink(`${flow}s/${platforms._id}`);
			}, 300);
		} else if (typeof platforms.syncPlatform === 'function' && ['UPDATE_PLATFORM'].includes(platforms.workflow)) {
			platforms.syncPlatform(platforms); // store to page
		}

		dispatch(Creators.PLATFORMS.clearPlatforms());
	};

	const focus = (selector) => {
		const mainInput = document.querySelector(selector);
		if (mainInput) mainInput.focus();
	};

	useEffect(() => {
		loadTagOptions();
		if (errors?.nome) {
			focus('#platform-name');
		}
	}, [errors]);

	useEffect(() => {
		if (password.action) {
			document.getElementById('platform-secret-password')?.focus();
		}
	}, [password.action]);

	const participants = platforms ? [
		{
			id: 'agents',
			name: 'agentes',
			value: platforms.membros?.agentes,
			visible: platforms.type === 'Challenge',
		},
		{
			id: 'moderators',
			name: 'moderadores',
			value: platforms.membros?.moderadores,
			visible: platforms.type === 'Challenge',
		},
		{
			id: 'sponsors',
			name: 'sponsors',
			value: platforms.membros?.sponsors,
			visible: platforms.type === 'Challenge',
		},
		{
			id: 'participants',
			name: 'participantes',
			value: platforms.membros?.participantes,
			visible: platforms.type === 'Brainstorm',
		},
	] : [];

	const loadParticipants = (inputValue, callback) => {
		if (disabled) return;
		dispatch(Creators.SHARED.getUsers({
			queryParams: { query: inputValue || '' },
			onSuccess: (response) => {
				if (typeof callback === 'function') {
					callback(response);
				}
				if (response?.length !== 0) {
					setParticipantsOptions(response);
				}
			},
		}));
	};

	const onTemplateHandler = (mode: 'reset' | 'update') => {
		tour.setIsOpen(false);

		setFeedbackProps({
			...feedbackProps,
			title: t(`feedback.${mode}.title`),
			text: t(`feedback.${mode}.content2`),
			rightButtonText: t('global.confirm'),
			open: true,
			color: 'success',
		});

		switch (mode) {
			case 'update':
				if (!platforms.template) {
					setFeedbackProps((prevProps) => ({
						...prevProps,
						onRightButton: () => {
							setFeedbackProps((prevState) => ({ ...prevState, isLoading: true }));
							dispatch(Creators.MODAL.showModal(routes?.MODAL_IDEA_TEMPLATES.path));
						},
						onLeftButton: () => {
							if (shared?.tour) {
								tour.setIsOpen(true);
							}
							setFeedbackProps((prevState) => ({ ...prevState, open: false }));
						},
					}));
					return;
				}

				dispatch(Creators.MODAL.showModal(routes?.MODAL_PLATFORM_TEMPLATES.path));
				break;
			case 'reset':
				setFeedbackProps((prevProps) => ({
					...prevProps,
					onRightButton: () => {
						setFeedbackProps((prevState) => ({ ...prevState, isLoading: true }));
						dispatch(Creators.PLATFORMS.handlePlatformTemplate({
							templateId: platforms.template._id,
							platformId: platforms._id,
							onSuccess: () => setFeedbackProps({ open: false }),
							callback: () => setFeedbackProps((prevState) => ({ ...prevState, isLoading: false })),
						}));
					},
					onLeftButton: () => {
						if (shared?.tour) {
							tour.setIsOpen(true);
						}
						setFeedbackProps({ ...prevProps, open: false });
					},
				}));
				break;
			default:
				break;
		}
	};

	const onCoverHandler = (action: 'edit' | 'delete') => {
		if (disabled) return;
		setInProgress('cover');

		if (action === 'delete') {
			dispatch(Creators.PLATFORMS.handleCoverPlatform({
				cover: '',
				coverThumb: '',
				coverAuthor: undefined,
				callback: setInProgress,
			}));
		}

		if (action === 'edit') {
			BuildTour.close(tour);
			setOpen('cover');
		}
	};

	const TOUR_IMAGES = ['forms.new', 'platform.agents', 'platform.sponsors', 'platform.moderators', 'platform.participants'];

	const TOUR_STEPS = {
		brainstorm: [
			'template.update', 'platform.type', 'platform.summary', 'tags',
			'platform.cover', 'platform.participants', 'forms.new', 'canvas.new', 'attachments.add', 'platform.permission', 'platform.publish',
		].map((key) => ({
			id: key.replaceAll('.', '-'),
			title: t(`tour.${key}.title`, { type: 'Brainstorm' }),
			action: t(`tour.${key}.action`, { type: 'Brainstorm' }),
			children: t(`tour.${key}.description`, { type: 'Brainstorm' }),
			imagePath: TOUR_IMAGES?.includes(key) ? `/images/tour/${key.replaceAll('.', '-')}.gif` : undefined,
		})),
		challenge: [
			'template.update', 'platform.type', 'platform.summary', 'tags',
			'platform.cover', 'platform.agents', 'platform.moderators', 'platform.sponsors', 'forms.new',
			'canvas.new', 'badges.new', 'attachments.add', 'platform.format', 'platform.permission', 'platform.publish',
		].map((key) => ({
			id: key.replaceAll('.', '-'),
			title: t(`tour.${key}.title`, { type: 'Challenge' }),
			action: t(`tour.${key}.action`, { type: 'Challenge' }),
			children: t(`tour.${key}.description`, { type: 'Challenge' }),
			imagePath: TOUR_IMAGES?.includes(key) ? `/images/tour/${key.replaceAll('.', '-')}.gif` : undefined,
		})),
	};

	const onTypeHandler = (type: TFlow) => {
		setInProgress(type);
		dispatch(
			Creators.PLATFORMS.handlePlatform({
				key: 'type',
				noDelay: true,
				value: type,
				onSuccess: (response) => {
					dispatch(Creators.PLATFORMS.updatePlatform(response));
					dispatch(Creators.MODAL.handleModal({ flow: response.type?.toLowerCase() }));
					SToast.success(t('messages.success.platform.changedType', { type: t(`global.${response.type?.toLowerCase()}`) }));
					if ([FLOWS.BRAINSTORM, FLOWS.CHALLENGE].includes(shared.tour) || tour.isOpen) {
						BuildTour.setTour(tour, TOUR_STEPS[response.type?.toLowerCase()]);
					}
				},
				callback: () => setInProgress(),
			}),
		);
	};

	const generatorArray = () => {
		const array = [];
		let amount = 10;
		for (let li = 10; li <= 500; li += amount) {
			if (li === 100) {
				amount = 100;
			}
			array.push({
				value: `${li} ideias`,
				label: `${li} ${t('global.ideas')}`,
			});
		}
		return array;
	};

	useEffect(() => {
		loadParticipants('');

		if ([FLOWS.BRAINSTORM, FLOWS.CHALLENGE].includes(shared.tour)) {
			BuildTour.setTour(tour, TOUR_STEPS[flow]);
		}
		dispatch(Creators.IDEAS.clearIdeas());
	}, []);

	const openTimesMenu = platforms.status !== 'rascunho' ? false : Boolean(anchorTimesEl);
	const openQuantitiesMenu = platforms.status !== 'rascunho' ? false : Boolean(anchorQuantitiesEl);
	return (
		<DialogContent id="PlatformDialog">
			<ModalHeader
				fullScreen
				icon={flow}
				BreadcrumbProps={{ className: W_GRID.W_GRID_2 }}
				pathParams={{
					platform: platforms?.nome || '...',
					type: platforms?.type || '...',
				}}
				onClose={onCloseHandler}
			/>
			<SBox loading={!platforms || _.isEmpty(platforms) || !flow || !platforms.createdAt} className="inner w-grid-2">
				<DialogBox className="s-dialog-title">
					<DialogTitle variant="h1">
						{t('form.platform.addDetails', { flow: flowT?.toLowerCase() })}
					</DialogTitle>
					<HelpButton
						size="large"
						className="ml-1"
						onClick={() => BuildTour.setTour(tour, TOUR_STEPS?.[flow], { currentStep: 0 })}
					>
						Tour
					</HelpButton>
				</DialogBox>
				<DialogBox className="s-dialog-box">
					<Form className="s-form modal-edit" autoComplete="off">
						<SFormGroup>
							<SInputLabel htmlFor="platform-name" error={typeof errors?.nome === 'string'}>
								{t('form.platform.name', { flow: flowT })}
							</SInputLabel>
							<STextField
								name="nome"
								fullWidth
								id="platform-name"
								variant="standard"
								maxLength={maxLength.flowName}
								autoFocus
								placeholder={t(`form.placeholder.flow.${flow}`)}
								isAsync
								isLoading={inProgress === 'nome'}
								value={platforms.nome}
								disabled={disabled}
								error={typeof errors?.nome === 'string'}
								helperText={errors?.nome}
								onChange={(event) => onPlatformHandler('nome', event.target.value)}
							/>
						</SFormGroup>
						{(platforms.status === 'rascunho' || platforms.template) && (
							<SFormGroup data-tut="template-update">
								<Box className="s-label-group s-label">
									<InputLabel>
										{t('global.template')}
									</InputLabel>
									<HelpButton color="secondary" className="tour">
										<STour {...TOUR_STEPS?.[flow]?.[0]} hideActions helperURL={t('urls.platformTemplates')} />
									</HelpButton>
								</Box>
								<TemplateDetails
									icon={platforms?.template?.slug || 'template'}
									color={platforms?.template?.color || undefined}
									isBlank={!platforms.template?.templateNome}
									name={platforms.template?.templateNome}
									onReset={platforms.mode === 'edit' && platforms.status === 'rascunho'
										? () => onTemplateHandler('reset') : undefined}
									onChange={platforms.mode === 'edit' && platforms.status === 'rascunho'
										? () => onTemplateHandler('update') : undefined}
								/>
							</SFormGroup>
						)}

						<SFormGroup data-tut="platform-type">
							<Box className="s-label-group s-label">
								<InputLabel>
									{t('form.format')}
								</InputLabel>
								<HelpButton color="secondary" className="tour">
									<STour {...TOUR_STEPS[flow]?.[1]} hideActions helperURL={t('urls.brainstorm')} />
								</HelpButton>
							</Box>
							<Grid
								container
								className="d-flex platform-type"
								spacing={2}
								sx={{
									display: 'flex',
									flexDirection: { xs: 'column', sm: 'row' },
									'.s-check': { display: 'flex' },
								}}
							>
								{[{ type: FLOWS.CHALLENGE }, { type: FLOWS.BRAINSTORM }]
									.map((item) => (
										<Grid item key={item.type} xs={12} sm={6}>
											<SRadio
												id={`platform-${item.type}`}
												value={item.type.capitalizeFirstLetter()}
												checked={platforms.type === item.type.capitalizeFirstLetter()}
												disabled={disabled || platforms.status !== 'rascunho'}
												onChange={(event) => onTypeHandler(event.target.value)}
												isAsync
												isLoading={inProgress === item.type.capitalizeFirstLetter()}
												label={(
													<Box className="px-1">
														<Box className="align-items-center d-flex mb-2">
															<Icon icon={item.type} />
															<span>{t(`global.${item.type}`)}</span>
														</Box>
														<p className="lh-base">{t(`platform.${item.type}`)}</p>
													</Box>
												)}
											/>
										</Grid>
									))}
							</Grid>
						</SFormGroup>
						<SFormGroup data-tut="platform-summary">
							<Box className="s-label-group s-label">
								<InputLabel htmlFor="platform-summary" error={typeof errors?.resumo === 'string'}>
									{t('form.summary')}
								</InputLabel>
								<HelpButton color="secondary" className="tour">
									<STour {...TOUR_STEPS[flow]?.[2]} hideActions helperURL={t('urls.summary')} />
								</HelpButton>
							</Box>
							<RichTextEditor
								id="platform-summary"
								name="resumo"
								minHeight="160px"
								disabled={disabled}
								// onPaste={onTagsHandler}
								isLoading={inProgress === 'resumo'}
								isAsync
								error={typeof errors?.resumo === 'string'}
								helperText={errors?.resumo}
								placeholder={t('form.placeholder.summary')}
								value={platforms.resumo}
								onChange={(value: string) => onPlatformHandler('resumo', value)}
							/>
						</SFormGroup>
						<SFormGroup data-tut="tags">
							<Box className="d-flex align-items-center justify-content-between s-label">
								<Box className="s-label-group">
									<InputLabel htmlFor="platform-generate-tags">
										Tags
									</InputLabel>
									<HelpButton color="secondary" className="tour">
										<STour {...TOUR_STEPS[flow]?.[3]} hideActions helperURL={t('urls.tags')} />
									</HelpButton>
								</Box>
								<SButton
									type="button"
									color="primary"
									size="small"
									onClick={() => onTagsHandler(platforms.resumo)}
									id="platform-generate-tags"
									isLoading={inProgress === 'generate-tags'}
									disabled={disabled || isEmptyTags}
									startIcon={<Icon icon="magic" />}
								>
									{t('global.generate')}
								</SButton>
							</Box>
							<SAutocomplete
								id="tags-platform-autocomplete"
								value={platforms.tags || []}
								placeholder={t('form.tagsSelectOrCreate')}
								multiple
								freeSolo
								renderTags={(_value, getTagProps) => (
									<Tags
										value={_value}
										onReorder={!disabled ? (value) => onPlatformHandler('tags', value) : undefined}
										component={(option, index) => (
											<Tag renderTags option={option} {...getTagProps({ index })} />
										)}
									/>
								)}
								options={tagOptions.docs}
								onChange={(event, value) => onPlatformHandler('tags', value)}
								loading={inProgress === 'tags'}
								isAsync
								disabled={disabled}
							/>
						</SFormGroup>
						<SFormGroup className="cover__group" id="cover" data-tut="platform-cover">
							<Box className="s-label" sx={{ display: 'flex', justifyContent: 'space-between' }}>
								<Box className="s-label-group">
									<InputLabel>
										Cover
									</InputLabel>
									<HelpButton className="tour" color="secondary">
										<STour {...TOUR_STEPS[flow]?.[4]} hideActions helperURL={t('urls.cover')} />
									</HelpButton>
								</Box>
								<SButton
									type="button"
									color="primary"
									size="small"
									disabled={disabled}
									onClick={() => onCoverHandler(platforms.cover !== '' && !disabled ? 'delete' : 'edit')}
									isLoading={inProgress === 'cover'}
									startIcon={platforms.cover !== '' && !disabled ? <Icon icon="remove" /> : <Icon icon="sync" />}
								>
									{platforms.cover !== '' && !disabled ? t('global.remove') : t('global.change')}
								</SButton>
							</Box>
							<BCover
								area={flow}
								isDisabled={disabled}
								cover={platforms.coverThumb || platforms.cover}
								isLoading={inProgress === 'cover'}
								onClick={() => onCoverHandler('edit')}
							/>
						</SFormGroup>
						{participants.map((item) => {
							if (!item.visible) return null;
							return (
								<SFormGroup key={item._id} data-tut={`platform-${item.id}`}>
									<Box className="s-label-group s-label">
										<InputLabel htmlFor={`${item.id}-autocomplete`}>
											{t(`global.${item.id}`)}
										</InputLabel>
										<HelpButton className="tour" color="secondary" placement="top">
											{(flow === FLOWS.BRAINSTORM || (flow === FLOWS.CHALLENGE && item.id === 'agents')) && (
												<STour {...TOUR_STEPS[flow]?.[5]} hideActions helperURL={t(`urls.${item.id}`)} />
											)}
											{flow === FLOWS.CHALLENGE && item.id === 'moderators' && (
												<STour {...TOUR_STEPS[flow]?.[6]} hideActions helperURL={t(`urls.${item.id}`)} />
											)}
											{flow === FLOWS.CHALLENGE && item.id === 'sponsors' && (
												<STour {...TOUR_STEPS[flow]?.[7]} hideActions helperURL={t(`urls.${item.id}`)} />
											)}
										</HelpButton>
									</Box>
									<SAutocomplete
										id={`${item.id}-autocomplete`}
										disabled={disabled}
										multiple
										unique="_id"
										placeholder={t('form.select')}
										value={getUserOptions(item.value) || []}
										loading={inProgress === item.name}
										isAsync
										options={getUserOptions(participantsOptions)}
										onChange={(event, value, reason, details) => onUsersHandler(item.name, value, reason, details)}
										renderTags={(opts, getTagProps) => (
											<Tags
												value={opts}
												component={(option, index) => (
													<UserTag
														renderTags
														option={option}
														draggable
														{...getTagProps({ index })}
													/>
												)}
											/>
										)}
										renderOption={(_props, option) => (
											<li {..._props}>
												<UserTag disabled={disabled} option={option} />
											</li>
										)}
									/>
								</SFormGroup>
							);
						})}
						<SFormGroup id="forms-modal-create" data-tut="forms-new">
							<Box className="s-label-group s-label">
								<InputLabel>
									{`${t('global.form')}s`}
								</InputLabel>
								<HelpButton color="secondary" className="tour">
									<STour {...TOUR_STEPS[flow]?.[flow === FLOWS.BRAINSTORM ? 6 : 8]} hideActions helperURL={t('urls.forms')} />
								</HelpButton>
							</Box>
							<BForms
								routes={{
									form: routes.MODAL_PLATFORM_FORM_DIRECT.path,
									forms: routes.MODAL_PLATFORM_FORMS.path,
								}}
								CreateProps={{
									title: t('global.new'),
								}}
								forms={{ docs: platforms?.forms }}
								isDisabled={disabled}
								flow={flow}
								mode={platforms.mode} // view | edit
							/>
						</SFormGroup>
						<SFormGroup id="canvas-modal-create" data-tut="canvas-new">
							<Box className="s-label-group s-label">
								<InputLabel>
									Canvas
								</InputLabel>
								<HelpButton color="secondary" className="tour">
									<STour {...TOUR_STEPS[flow]?.[flow === FLOWS.BRAINSTORM ? 7 : 9]} hideActions helperURL={t('urls.canvas')} />
								</HelpButton>
							</Box>
							<BCanvas
								CreateProps={{
									title: t('global.new'),
								}}
								mode={platforms.mode}
								isDisabled={disabled}
								flow={platforms?.type?.toLowerCase()}
								canvas={{ docs: platforms.canvas }}
								template={platforms.templateNome || platforms.template?.nome || '...'}
								routes={{
									model: routes.MODAL_PLATFORM_CANVAS_DIRECT.path,
									models: routes.MODAL_PLATFORM_CANVAS.path,
								}}
							/>
						</SFormGroup>
						{platforms.type === 'Challenge' && (
							<SFormGroup id="badges-modal-create" data-tut="badges-new">
								<Box className="s-label-group s-label">
									<InputLabel>
										{t('global.badges')}
									</InputLabel>
									<HelpButton color="secondary" className="tour">
										<STour {...TOUR_STEPS[flow]?.[10]} hideActions helperURL={t('urls.badges')} />
									</HelpButton>
								</Box>
								<BBadges
									CreateProps={{
										title: t('global.new'),
									}}
									flow={flow}
									mode={platforms.mode}
									isDisabled={disabled}
									badges={{ docs: platforms.medalhas }}
									routes={{
										badges: routes.MODAL_PLATFORM_BADGES.path,
										badge: routes.MODAL_PLATFORM_BADGE_DIRECT.path,
									}}
								/>
							</SFormGroup>
						)}
						<SFormGroup className="upload-files" data-tut="attachments-add">
							<Box className="s-label-group s-label">
								<InputLabel htmlFor="attachments-add">
									{t('global.attachments')}
								</InputLabel>
								<HelpButton color="secondary" className="tour">
									<STour
										{...TOUR_STEPS[flow]?.[flow === FLOWS.BRAINSTORM ? 8 : 11]}
										hideActions
										helperURL={t('urls.attachments')}
									/>
								</HelpButton>
							</Box>
							<UploadFiles
								id="attachments-add"
								values={platforms.anexos}
								isDisabled={disabled}
								onRemove={(id, callback) => {
									setInProgress('anexos');
									dispatch(
										Creators.PLATFORMS.removeAttachment(
											id,
											() => {
												callback();
												setInProgress();
											},
										),
									);
								}}
								onClick={(file) => onPlatformHandler('anexos', file)}
								isLoading={inProgress === 'anexos'}
							/>
						</SFormGroup>
						{platforms.type === 'Challenge' && (
							<SFormGroup className="challenge__types" data-tut="platform-format">
								<Box className="s-label-group s-label">
									<InputLabel>
										{t('form.platform.challengeFormat')}
									</InputLabel>
									<HelpButton color="secondary" className="tour">
										<STour {...TOUR_STEPS[flow]?.[12]} hideActions helperURL={t('urls.challengeFormat')} />
									</HelpButton>
								</Box>
								<div className="d-flex align-items-center">
									<SRadio
										id="platform-tempo"
										className="d-flex align-items-center"
										value="tempo"
										checked={platforms.challengeFormat?.type === 'tempo'}
										disabled={platforms.status !== 'rascunho'}
										isAsync={platforms.challengeFormat?.type === 'tempo'}
										onChange={(event) => onPlatformHandler(
											'challengeFormat',
											{ type: event.target.value, value: '12 horas' },
										)}
										isLoading={inProgress === 'tempo'}
										label={(
											<div className="challenge__format" id="challenge-tempo">
												<div className="type">
													<Icon icon="clock" />
													<span>
														{t('global.time')}
														{' '}
													</span>
												</div>
												<span className="mr-3">{t('form.platform.timeMore')}</span>
												<SMenu
													id="challenge-tempo-menu"
													open={openTimesMenu}
													anchorEl={anchorTimesEl}
													setAnchorEl={setAnchorTimesEl}
													options={timeOptions}
													onChange={(option) => onPlatformHandler('challengeFormat', {
														type: 'tempo',
														value: option.value,
													})}
												>
													<SButton
														className={`arrow ${openTimesMenu ? 'down' : 'up'}`}
														endIcon={<MdKeyboardArrowDown />}
														disabled={platforms.challengeFormat?.type !== 'tempo'
																|| platforms.status !== 'rascunho' || inProgress === 'tempo'}
													>
														{timeOptions.filter(
															(o) => o.value === platforms.challengeFormat?.value,
														)[0]?.label || '-'}
													</SButton>
												</SMenu>
											</div>
										)}
									/>
								</div>
								<div className="d-flex align-items-center">
									<SRadio
										id="platform-quantidade"
										className="d-flex align-items-center"
										value="quantidade"
										checked={platforms.challengeFormat?.type === 'quantidade'}
										disabled={platforms.status !== 'rascunho'}
										isAsync={platforms.challengeFormat?.type === 'quantidade'}
										onChange={(event) => onPlatformHandler(
											'challengeFormat',
											{ type: event.target.value, value: '10 ideias' },
										)}
										isLoading={inProgress === 'quantidade'}
										label={(
											<div className="challenge__format" id="challenge-quantidade">
												<div className="type">
													<Icon icon="sort" />
													<span>
														{t('global.amount')}
														{' '}
													</span>
												</div>
												<span className="mr-3">{t('form.platform.amountMore')}</span>
												<SMenu
													id="challenge-quantidade-menu"
													open={openQuantitiesMenu}
													anchorEl={anchorQuantitiesEl}
													setAnchorEl={setAnchorQuantitiesEl}
													options={generatorArray()}
													onChange={(option) => onPlatformHandler('challengeFormat', {
														type: 'quantidade',
														value: option.value,
													})}
												>
													<SButton
														className={`arrow ${openQuantitiesMenu ? 'down' : 'up'}`}
														endIcon={<MdKeyboardArrowDown />}
														disabled={platforms.challengeFormat?.type !== 'quantidade'
																|| platforms.status !== 'rascunho' || inProgress === 'quantidade'}
													>
														{generatorArray().filter(
															(o) => o.value === platforms.challengeFormat?.value,
														)[0]?.label || '-'}
													</SButton>
												</SMenu>
											</div>
										)}
									/>
								</div>
							</SFormGroup>
						)}
						<SFormGroup data-tut="platform-permission">
							<Box className="s-label group s-label">
								<InputLabel>
									{t('global.permission')}
								</InputLabel>
								<HelpButton color="secondary" className="tour">
									<STour {...TOUR_STEPS[flow]?.[flow === FLOWS.BRAINSTORM ? 9 : 13]} hideActions helperURL={t('urls.permission')} />
								</HelpButton>
							</Box>
							{permissions.map((permission) => (
								<div className="permission__item" key={permission.icon}>
									<SRadio
										id={permission.icon}
										className="d-flex align-items-center"
										isAsync={platforms.permissao === permission.value}
										value={permission.value}
										checked={platforms.permissao === permission.value}
										disabled={platforms.status !== 'rascunho'}
										onChange={(event) => onPlatformHandler('permissao', event.target.value)}
										isLoading={inProgress === permission.value}
										label={(
											<div className="permission__radio">
												<Icon icon={permission.icon} className="permission__icon" />
												<span className="bold">{t(`permission.${permission.icon}`)}</span>
												<span>{permission.label2}</span>
												{permission.value === 'publica' && (
													<>
														<span className="bold px-1">{userOrganizationProfile.organization.nome}</span>
														<span>{t('permission.publicMore')}</span>
													</>
												)}
											</div>
										)}
									/>
									{(permission.value === 'secreta' && platforms.permissao === 'secreta' && platforms.status !== 'rascunho') && (
										<SButton
											type="button"
											color="primary"
											size="small"
											startIcon={<Icon icon="sync" />}
											onClick={() => setPassword((params) => ({ action: !params.action, password: '' }))}
										>
											{t(`global.${password.action ? 'hide' : 'change'}`)}
										</SButton>
									)}
								</div>
							))}
						</SFormGroup>
					</Form>
					{((platforms.permissao === 'secreta' && platforms.status === 'rascunho') || password.action)
						&& (
							<Form className="s-form">
								<SFormGroup className="ml-30px mt-0 w-40">
									<SInputLabel htmlFor="platform-secret-password" error={typeof errors?.senha === 'string'}>
										{t('form.password')}
									</SInputLabel>
									<SPasswordField
										id="platform-secret-password"
										name="password"
										value={password.value}
										// disabled={disabled}
										helperText={errors?.senha || passwordValidation(password.value)?.message}
										error={typeof errors?.senha === 'string'}
										placeholder={t('form.placeholder.newPassword')}
										onChange={(e) => {
											onErrorHandler('senha');
											setPassword((params) => ({ ...params, value: e.target.value }));
										}}
										hasValidation
									/>
								</SFormGroup>
								{/* <SFormGroup className="ml-30px">
									<SRadio
										id="secret-participation"
										value="participation"
										className="w-100"
										checked={platforms.passwordRecurrency === 'participation'}
										// disabled={platforms.status !== 'rascunho'}
										onChange={(event) => onPlatformHandler('passwordRecurrency', event.target.value)}
									>
										{t('platform.passwordRecurrency.participation')}
									</SRadio>
									<SRadio
										id="secret-access"
										value="access"
										className="w-100"
										checked={platforms.passwordRecurrency === 'access'}
										// disabled={platforms.status !== 'rascunho'}
										onChange={(event) => onPlatformHandler('passwordRecurrency', event.target.value)}
									>
										{t('platform.passwordRecurrency.access')}
									</SRadio>
								</SFormGroup> */}
								<SButton
									type="button"
									variant="outlined"
									className="mt-20px ml-30px"
									// disabled={password.value === '' || !password.value || !platforms.passwordRecurrency}
									// error={password.value === '' || !password.value || !platforms.passwordRecurrency}
									disabled={password.value === '' || !password.value}
									// error={password.value === '' || !password.value}
									onClick={() => onPlatformHandler('senha', password.value)}
									isLoading={inProgress === 'senha'}
								>
									{t('global.save')}
								</SButton>
							</Form>
						)}
				</DialogBox>
				<DialogBox className="s-dialog-box">
					<DialogActions className="s-dialog-actions" data-tut="platform-publish">
						<SButton
							type="button"
							error={!_.isEmpty(errors)}
							variant="outlined"
							color="primary"
							fullWidth
							size="large"
							isLoading={inProgress === 'PUBLISH'}
							onClick={platforms.status === 'rascunho' ? onPublishHandler : () => onCloseHandler('view')}
						>
							<span className="platform_publish">
								{platforms.status === 'rascunho'
									? t('form.flow.publish', { flow: flowT })
									: t('platform.view', { type: flowT })}
							</span>
						</SButton>
					</DialogActions>
				</DialogBox>

			</SBox>

			{feedbackProps.open && (
				<ModalFeedback
					id="platform-edit"
					onRequestClose={() => setFeedbackProps({ open: false })}
					{...feedbackProps}
				/>
			)}
			{open === 'cover' && (
				<ModalCover
					flow={flow}
					id={platforms._id}
					open={open === 'cover'}
					onClose={() => {
						if (shared?.tour) {
							BuildTour.setTour(tour, TOUR_STEPS, { currentStep: 4 });
						}
						setOpen(undefined);
						setInProgress(undefined);
					}}
					query={
						platforms.tags && platforms.tags.length > 0
							? platforms.tags[0].value
							: ''
					}
				/>
			)}
		</DialogContent>
	);
};

const Translation = withTranslation('common')(MModalPlatform);

const ModalPlatform = (props: IModal) => (
	<Suspense fallback={<DialogContent />}>
		<Translation {...props} />
	</Suspense>
);

export default ModalPlatform;
