import React, { Suspense, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SpinnerLDSRipple from '@components/Shared/SpinnerLDSRipple';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from 'src/store';
import { SButton } from '@components/Buttons';
import StripeForm from '@components/Forms/StripeForm';
import { SBox } from '@components/Background/SBox';

const BPaymentMethod = () => {
	const { t } = useTranslation();
	const defaultPaymentMethod = useSelector((state: IRootState) => state.billing?.defaultPaymentMethod);
	const subscription = useSelector((state: IRootState) => state.billing?.subscription);

	const dispatch = useDispatch();
	const [newCard, setNewCard] = useState<boolean>();

	return (
		<SBox className="billing-payment-method">
			{defaultPaymentMethod
				? (
					<>
						<div className="info">
							<p className="">
								{t(
									'settings.nextPaymentThisCard',
									{
										cardInfos: `${defaultPaymentMethod.card.brand.toUpperCase()} 
									****${defaultPaymentMethod.card.last4} 
									${defaultPaymentMethod.card.exp_month}
									/${defaultPaymentMethod.card.exp_year}`,
									},
								)}
								<br />
								{subscription && t('settings.nextBillingDue', {
									due: t('dates.fullDate', { date: new Date(subscription.current_period_end * 1000) }),
									licenses: subscription.plan.interval === 'year'
										? Intl.NumberFormat('pt-BR', { style: 'currency', currency: subscription.plan.currency })
											.format(((subscription.plan.amount || 0) / 100) * subscription.quantity * 12)
										: Intl.NumberFormat('pt-BR', { style: 'currency', currency: subscription.plan.currency })
											.format(((subscription.plan.amount || 0) / 100) * subscription.quantity),
								})}
							</p>
						</div>

						<SButton
							color="secondary"
							variant="outlined"
							size="large"
							type="button"
							onClick={() => setNewCard(true)}
						>
							{t('creditCard.change')}
						</SButton>
						{newCard
							&& (
								<StripeForm
									marginY={3}
									ConfirmButtonProps={{ children: t('global.save'), size: 'large' }}
									variant="outlined"
									mode="card"
								/>
							)}
					</>
				)
				: (
					<StripeForm ConfirmButtonProps={{ children: t('global.save'), size: 'large' }} variant="outlined" mode="card" />
				)}
		</SBox>
	);
};


const PaymentMethod = () => (
	<Suspense fallback={<SpinnerLDSRipple />}>
		<BPaymentMethod />
	</Suspense>
);

export default PaymentMethod;
