// @ts-nocheck
import { axiosInstance, BASE_URL } from '@services/AxiosInstance';
import { IPaginatedRequest } from 'src/@types/IShared';
import { getQueryParams } from './utils';

const APIGlobal = {
	getTags(params: IPaginatedRequest) {
		const queryParams = getQueryParams(params);
		return axiosInstance.get(`/tags${queryParams}`);
	},
	postRegisterTags(text: string) {
		return axiosInstance.post(`${BASE_URL}/ai/nlp/keywords/extract`, { text });
	},
	deleteAttachment(attachmentId: string) {
		return axiosInstance.delete(`/attachments/${attachmentId}`);
	},
	postReport(objectReported) {
		return axiosInstance.post('/reports', objectReported);
	},
	getSearch(params: IPaginatedRequest) {
		const queryParams = getQueryParams(params);
		return axiosInstance.get(`/search${queryParams}`);
	},
	getAddress(cep: string) {
		return axiosInstance.get(`https://viacep.com.br/ws/${cep}/json`);
	},

	getPreviewUrl(url: string) {
		return axiosInstance.get(`/preview_url/?url=${encodeURIComponent(url)}`);
	},
};

export default APIGlobal;
