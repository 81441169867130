// @ts-nocheck
import React from 'react';
import {
	Route, Routes, useNavigate,
} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import routes, { TRoutes } from '@routes/modal';
import { IRootState } from 'src/store';
import { Creators } from '@actions';
import { Box, Dialog, DialogProps } from '@mui/material';
import { GenericGrid } from '@components/Grids/GenericGrid';
import type { IModal } from './types';

interface SDialogProps extends DialogProps {

}

const ModalModule = () => {
	const navigate = useNavigate();
	const isOpen = useSelector((state: IRootState) => state.modal?.isOpen) || false;
	const currentPath = useSelector((state: IRootState) => state.modal?.path);
	const secondPath = useSelector((state: IRootState) => state.modal?.secondPath);
	const ModalProps = useSelector((state: IRootState) => state.modal?.ModalProps);
	const dispatch = useDispatch();

	const secondModal = routes[
		Object.keys(routes).find((key) => routes[key].path === secondPath)
	];
	const mainProps = routes[
		Object.keys(routes).find((key) => routes[key].path === currentPath)
	];
	const secondProps = routes[
		Object.keys(routes).find((key) => routes[key].path === secondPath)
	];

	React.useEffect(() => {
		if (mainProps?.path === currentPath) {
			navigate(currentPath);
		}
	}, [currentPath]);

	if (!mainProps || (mainProps.path !== currentPath)) {
		return null;
	}

	const ComponentType = mainProps.component;

	// console.log('MAIN PROPS', mainProps)

	return (
		<Box>
			<Dialog
				open={isOpen}
				className={`${`s-modal-${mainProps?.maxWidth}` || ''}`}
				fullScreen={mainProps?.fullScreen}
				maxWidth={mainProps?.maxWidth}
				fullWidth={mainProps?.fullWidth}
				scroll="body"
				onClose={() => dispatch(Creators.MODAL.hideModal())}
				PaperProps={{
					sx: {
						'&.MuiDialog-paperFullScreen .MuiDialogContent-root': {
							paddingBottom: '1rem',
						},
					},
				}}
				style={{
					overlay: {
						zIndex: 200,
						backgroundColor: 'rgba(0,0,0,0.75)',
					},
				}}
			>
				<GenericGrid className={`s-grid-modal ${mainProps?.maxWidth}`}>
					<Routes>
						<Route
							path={mainProps.path}
							element={(
								<ComponentType
									fullScreen={mainProps?.fullScreen}
									maxWidth={mainProps?.maxWidth}
									fullWidth={mainProps?.fullWidth}
									routes={routes as TRoutes}
									{...mainProps}
									{...ModalProps}
								/>
							)}
						/>
					</Routes>
				</GenericGrid>
			</Dialog>
			{secondModal && (
				<Dialog
					open={typeof secondPath === 'string'}
					scroll="body"
					className={`${`s-modal-${secondProps?.maxWidth}` || ''}`}
					fullScreen={secondProps?.fullScreen}
					maxWidth={secondProps?.maxWidth}
					fullWidth={secondProps?.fullWidth}
					onClose={() => dispatch(Creators.MODAL.hideSecondModal())}
				>
					<GenericGrid className={`s-grid-modal ${secondProps?.maxWidth}`}>
						<secondModal.component
							fullScreen={secondProps?.fullScreen}
							maxWidth={secondProps?.maxWidth}
							fullWidth={secondProps?.fullWidth}
							routes={routes}
							{...secondProps}
						/>
					</GenericGrid>
				</Dialog>
			)}
		</Box>
	);
};

export default ModalModule;
