// @ts-nocheck
import React, { useState, Suspense, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { SButton } from '@components/Buttons';
import TableSearch from '@components/DataTable/TableSearch';
import {
	DialogBox,
} from '@modules/Modal/Components';
import { IPagination } from 'src/@types/IShared';
import { IUserOrganization } from 'src/@types/IUser';
import { UserCell } from '@components/DataTable/Cells';
import { IRootState } from 'src/store';
import { TableColumn } from 'react-data-table-component';
import { handleAxiosErrors } from '@services/auth';
import { DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { Creators } from '@actions';
import ModalFeedback, { IFeedbackProps } from '@components/Modals/ModalFeedback';
import { SToast } from '@modules/SToast';
import { IconsMap } from '@utils/IconsMap';
import { APIBilling } from '../../../services/apis';

const MModalDowngrade = (props) => {
	const { t } = props;
	const dispatch = useDispatch();
	const [users, setUsers] = useState({ docs: [], isLoading: true });
	const [selected, setSelected] = useState([]);
	const updatePlan = useSelector((state: IRootState) => state.billing?.updatePlan);
	const subscription = useSelector((state: IRootState) => state.billing?.subscription);
	const licenses = useSelector((state: IRootState) => state.billing?.licenses);
	const [feedbackProps, setFeedbackProps] = useState<IFeedbackProps>({ open: false });

	if (!subscription) return;

	const remove = (subscription?.quantity - licenses) <= updatePlan?.quantity
		? 0 : subscription?.quantity - parseInt(updatePlan.quantity, 10);

	const CellLastLogin = ({ row }) => (
		<div>
			<div style={{ display: 'flex' }}>
				{row.lastLoginAt
					? new Date(row.lastLoginAt).toLocaleString()
					: 'Nunca'}
			</div>
		</div>
	);

	const loadParticipants = () => {
		setUsers({ ...users, isLoading: true });
		dispatch(Creators.SHARED.getParticipants({
			queryParams: { excludeCurrentUser: false },
			onSuccess: (response: IPagination<IUserOrganization>) => {
				setUsers({ ...response, isLoading: false });
			},
		}));
	};

	const onDowngradeHandler = async ({ callback }) => {
		try {
			setFeedbackProps((prevState) => ({ ...prevState, isLoading: true }));
			await APIBilling.postDowngrade({
				planId: updatePlan.id,
				quantity: updatePlan.quantity,
				interval: updatePlan.interval,
				userIds: selected,
			});
			SToast.success(t('settings.planChanged'), t('global.success'));
			dispatch(Creators.BILLING.getOverviewPlan());
			dispatch(Creators.MODAL.hideModal());
		} catch (err) {
			handleAxiosErrors(err);
		} finally {
			setFeedbackProps((prevState) => ({ ...prevState, isLoading: false }));
		}
	};

	const columns: TableColumn<IUserOrganization>[] = [
		{
			name: t('global.name'),
			sortable: true,
			wrap: true,
			maxWidth: '600px', // when using custom you should use width or maxWidth, otherwise, the table will default to flex grow behavior
			minWidth: '210px',
			selector: (row) => row.user?.nome || '',
			cell: (row) => (
				<UserCell
					user={row.user}
				/>
			),
		},
		{
			name: t('global.brainstorms'),
			sortable: true,
			center: true,
			maxWidth: '50px',
			selector: (row) => row.brainstormsCount || 0,
		},
		{
			name: t('global.challenges'),
			sortable: true,
			center: true,
			maxWidth: '50px',
			selector: (row) => row.challengesCount || 0,
		},
		{
			name: t('global.ideas'),
			sortable: true,
			maxWidth: '50px',
			center: true,
			selector: (row) => row.ideias_count || 0,
		},
		{
			name: t('global.teams'),
			sortable: true,
			center: true,
			maxWidth: '50px',
			selector: (row) => row.teamsCount || 0,
		},
		{
			name: t('global.lastLoginAt'),
			sortable: true,
			center: true,
			maxWidth: '60px',
			selector: (row) => row.user?.lastLoginAt || '',
			cell: (row) => <CellLastLogin row={row} />,
		},
	];

	useEffect(() => {
		loadParticipants();
	}, []);

	return (
		<DialogContent className="modal__downgrade" id="DowngradeDialog">
			<DialogBox className="s-dialog-title">
				<DialogTitle variant="h6">
					{t('settings.plan')}
				</DialogTitle>
			</DialogBox>
			<DialogBox className="s-dialog-box">
				<p
					className="info__remain"
					dangerouslySetInnerHTML={{
						__html: t('plans.infoRemain', {
							plan: subscription.plan.name,
							newPlan: updatePlan.name,
							licenses: updatePlan.quantity,
							numbersUsers: remove,
						}),
					}}
				/>
				<strong>{`${t('global.select')} ${remove} ${t('plans.usersToRemain')}`}</strong>

				<TableSearch
					hasSearch
					paginationPerPage={4}
					selectableRows
					progressPending={users.isLoading}
					columns={columns}
					selectableRowsNoSelectAll
					disabled={!remove}
					selectableRowSelected={(row) => row.isFixed || selected.includes(row._id)}
					onSelectedRowsChange={({ selectedRows }) => setSelected(selectedRows.map((item) => item._id))}
					data={users.docs}
				/>
				<div className="contactus">
					<Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
						<Icon icon="warning" />
						{t('plans.keepInnnovating')}
					</Box>
					<p>{t('plans.keepContactUs')}</p>
					<SButton href={t('urls.contact')} target="_blank" color="secondary" variant="contained">
						{t('global.contactUs')}
					</SButton>
				</div>
			</DialogBox>
			<DialogActions className="s-dialog-actions">
				<SButton
					type="button"
					color="secondary"
					onClick={() => dispatch(Creators.MODAL.hideModal())}
				>
					{t('global.cancel')}
				</SButton>
				<SButton
					type="button"
					color="success"
					disabled={selected.length < 1 || selected.length > parseInt(updatePlan.quantity, 10)}
					onClick={() => setFeedbackProps({
						open: true,
						rightButtonText: t('global.continue'),
						title: t('plans.youAreReducing'),
						text: t('plans.thisActionWillRemoveMembers'),
						// handleButtonRight: onDowngradeHandler,
					})}
				>
					{t('global.approve')}
				</SButton>
			</DialogActions>
			{feedbackProps.open && (
				<ModalFeedback
					{...feedbackProps}
					id="downgrade"
					onRequestClose={() => setFeedbackProps({ open: false })}
					onRightButton={onDowngradeHandler}
				/>
			)}
		</DialogContent>
	);
};

const Translation = withTranslation('common')(MModalDowngrade);

const ModalDowngrade = (props) => (
	<Suspense fallback={<DialogContent />}>
		<Translation {...props} />
	</Suspense>
);
export default ModalDowngrade;
