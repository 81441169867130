// @ts-nocheck
import React, { useState, Suspense } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import { formatDate } from '@utils/ReactUtils';
import {
	SFormGroup, SInputLabel, SRadio,
} from '@components/DynamicForm/ComponentsCore';
import { DialogBox } from '@modules/Modal/Components';
import {
	Box, DialogContent, DialogTitle,
} from '@mui/material';
import { SSelect } from '@components/DynamicForm/Components/SSelect';
import StripeForm from '@components/Forms/StripeForm';
import { SButton } from '@components/Buttons';
import { SBox } from '@components/Background/SBox';
import { Creators } from '../../../store/ducks/actionsTypes';

const MModalPlan = (props) => {
	const { t } = props;
	const dispatch = useDispatch();
	const { updatePlan } = useSelector((state) => state.billing);
	const { defaultPaymentMethod, subscription } = useSelector((state) => state.billing);
	const [card, setCard] = useState(defaultPaymentMethod?.card?.last4 || 'other');
	const [inProgress, setInProgress] = useState('');

	moment.locale();

	const generatorArray = (li, ls, label) => {
		const array = [];
		for (li; li <= ls; li++) {
			array.push({
				value: li,
				label: `${li} ${label}`,
			});
		}
		return array;
	};

	const options = generatorArray(5, 30, t('global.members'));

	const onCloseHandler = () => {
		dispatch(Creators.MODAL.hideModal());
	};

	return (
		<DialogContent className="modal__plan" id="PlanDialog">
			<DialogBox className="s-dialog-title">
				<DialogTitle variant="h6">
					{t('settings.plan')}
				</DialogTitle>
			</DialogBox>

			<SBox loading={!subscription}>
				<DialogBox className="s-dialog-box" style={{ maxHeight: 'initial' }}>
					<SSelect
						className="s-form"
						id="licenses-options"
						label={t('settings.chooseTeamSize')}
						// className="licenses-options"
						value={updatePlan.quantity}
						onChange={(e) => dispatch(Creators.BILLING.handlePlan({ quantity: parseInt(e.target.value, 10) }))}
						options={options}
						disabled={updatePlan.name === 'Free'}
					/>

					{subscription && updatePlan
							&& (
								<div className="info">
									<div className="info__current">
										<strong>{t('global.current')}</strong>
										<span>{`${subscription.quantity} ${t('global.members')}`}</span>
										<span>{t(`settings.${subscription.plan.interval}lyPayments`)}</span>
										<span>
											{subscription.plan.interval === 'year'
												? `${Intl.NumberFormat('pt-BR', { style: 'currency', currency: subscription.plan.currency }).format(((subscription.plan.amount || 0) / 100) * parseInt(subscription.quantity) * 12)} ${t('global.per')} ${t('settings.year')}`
												: `${Intl.NumberFormat('pt-BR', { style: 'currency', currency: subscription.plan.currency }).format(((subscription.plan.amount || 0) / 100) * parseInt(subscription.quantity))} ${t('global.per')} ${t('settings.month')}`}
										</span>
									</div>
									<div className="angle">
										<svg xmlns="http://www.w3.org/2000/svg" width="11.855" height="23.004">
											<g opacity=".5"><path className="angle-left" d="M11.502 11.502L.354 22.65M11.502 11.502L.354.354" /></g>
										</svg>
									</div>
									{updatePlan
										&& (
											<div className="info__updated">
												<strong>{t('global.updated')}</strong>
												<span>{`${updatePlan.quantity} ${t('global.members')}`}</span>
												<span>{t(`settings.${updatePlan.interval}lyPayments`)}</span>
												<span>
													{updatePlan.interval === 'year'
														? `${Intl.NumberFormat('pt-BR', { style: 'currency', currency: updatePlan.currency }).format((updatePlan.amount / 100) * parseInt(updatePlan.quantity) * 12)} ${t('global.per')} ${t('settings.year')}`
														: `${Intl.NumberFormat('pt-BR', { style: 'currency', currency: updatePlan.currency }).format((updatePlan.amount / 100) * parseInt(updatePlan.quantity))} ${t('global.per')} ${t('settings.month')}`}
												</span>
											</div>
										)}
								</div>
							)}

					{subscription
							&& (
								<p className="s-label">
									{`${t('settings.calculatedPriceRules')} ${formatDate(subscription.current_period_end * 1000)} ${t('global.learnMoreSee')} `}
									<a href="# ">FAQ</a>
									{' '}
									{t('global.or')}
									{' '}
									<a href="# " onClick={(e) => e.preventDefault()}>{t('global.submitAQuestion')}</a>
								</p>
							)}
					<Box className="mb-0 radio-group">
						{defaultPaymentMethod && updatePlan?.name !== 'Free' && (
							<SFormGroup className="mb-0">
								<SInputLabel>{t('settings.choosePaymentMethod')}</SInputLabel>
								<SRadio
									id="current-card-payment-method"
									className="mt-10px"
									value={defaultPaymentMethod?.card?.last4}
									onChange={(event) => setCard(event.target.value)}
									checked={card === defaultPaymentMethod?.card?.last4}
									label={`${t('global.card')} **** ${defaultPaymentMethod?.card?.last4}`}
								/>
								<SRadio
									id="other-card-payment-method"
									value="other"
									onChange={(event) => setCard(event.target.value)}
									checked={card === 'other'}
									disabled={updatePlan?.name === 'Free'}
									label={t('creditCard.other')}
								/>
							</SFormGroup>
						)}

						<StripeForm
							mode={card === 'other' || (!defaultPaymentMethod && updatePlan?.name !== 'Free') ? 'update' : 'subscrible'}
							components={{
								closeButton: (
									<SButton
										color="secondary"
										onClick={onCloseHandler}
									>
										{t('global.close')}
									</SButton>
								),
							}}
							ConfirmButtonProps={{ children: t('billing.plan.update') }}
						/>

					</Box>
				</DialogBox>
			</SBox>
		</DialogContent>
	);
};

const Translation = withTranslation('common')(MModalPlan);
const ModalPlan = (props) => (
	<Suspense fallback={<DialogContent />}>
		<Translation {...props} />
	</Suspense>
);

export default ModalPlan;
