import React from 'react';
import { Box, CircularProgress } from '@mui/material';
import { MdCheckCircle } from 'react-icons/md';

interface SInputStatusProps {
	isLoading?: boolean
	isAsync?: boolean
}

export const SInputStatus = ({ isLoading, isAsync }: SInputStatusProps) => (
	<Box className="s-status">
		{isLoading && isAsync && <CircularProgress color="inherit" size={10} />}
		{!isLoading && isAsync && <MdCheckCircle color="success" fontSize="small" />}
	</Box>
);
