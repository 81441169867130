import { CircularProgress, Link, LinkProps } from '@mui/material';
import React from 'react';

interface ISLink {
	isLoading?: boolean
	error?: boolean
	startIcon?: React.ReactNode
}

export const SLink = <C extends React.ElementType>(
	props: LinkProps<C, { component?: C }> & ISLink,
) => {
	const {
		children, className = '', isLoading, disabled, error, color = 'primary', startIcon, endIcon, ...rest
	} = props;
	return (
		<Link
			className={`s-link ${className} ${color}`}
			color={error ? 'error' : color}
			disabled={isLoading || disabled}
			{...rest}
		>
			{isLoading ? <CircularProgress color="inherit" size={10} sx={{ marginRight: '0.25rem' }} /> : startIcon}
			{children}
			{endIcon}
		</Link>
	);
};
