import React, { Suspense } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { IComment } from 'src/@types/IShared';
import { SocketIO, SOCKET_EVENTS } from '@services/socket';
import { Box } from '@mui/material';
import { CommentArea } from './CommentArea';
import { Comment } from './Comment';

interface ICommentsProps {
	className?: string
	objectId: string
	comments: IComment[]
	updateComments: (comments: IComment[]) => void
}

declare type TCommentsProps = ICommentsProps & WithTranslation

const CComments = (props: TCommentsProps) => {
	const {
		objectId, className = '', comments, t, updateComments,
	} = props;

	React.useEffect(() => {
		[SOCKET_EVENTS.COMMENT, SOCKET_EVENTS.COMMENT_LIKE,
			SOCKET_EVENTS.COMMENT_UPDATE, SOCKET_EVENTS.COMMENT_DELETE]
			.forEach((event) => {
				SocketIO.on(event, ({ comment }: any) => {
					const index = comments?.findIndex((_comment) => (_comment._id === comment._id));

					if (index < 0) { // new comments
						comments.push(comment);
					} else {
						if (event === 'comment_delete') {
							if (comment.conteudo) {
								comments.splice(index, 1, comment);
							} else {
								comments.splice(index, 1);
							}
						} else {
							comments.splice(index, 1, comment);
						} // update
					}

					updateComments(comments);
				});
			});
		SocketIO.on(SOCKET_EVENTS.ERROR, ({ err }: { err: string }) => {
			console.log('SOCKET ERROR', err);
		});
	}, []);

	return (
		<Box className={`comments__list ${className || ''}`}>
			{comments.length < 1 ? <article className="empty__comment">{t('emptyStates.comments')}</article>
				: (
					<Box sx={{ maxHeight: {xs: '500px', xl: '700px'}, overflowY: 'auto' }}>
						{ comments.map((comment) => {
							const _id = `${comment._id}`;
							const target = {
								reply: `reply-to-${comment._id}`,
								like: `c-like-${comment.autor?._id}-${comment._id}`,
								flag: `c-flag-${comment.autor?._id}-${comment._id}`,
							};
							return (
								<div key={comment._id}>
									<article>
										<Comment
											objectId={objectId}
											comment={comment}
											isReply={false}
											target={target}
										/>

									</article>
									{/* Render the response */}
									{comment?.respostas?.map((reply, k) => {
										const rTarget = {
											reply: `reply-to-${reply._id}`,
											like: `r-like-${k}-${reply._id}`,
											flag: `r-flag-${k}-${reply._id}`,
										};

										return (
											<article key={reply._id} className="reply">
												<Comment
													objectId={objectId}
													// @ts-ignore
													comment={reply} // response
													isReply
													target={rTarget}
												/>
											</article>
										);
									})}
									{/* Here write the answer  */}
									<article className="reply-box d-none">
										<CommentArea
											objectId={objectId}
											isReply
											selectorPreview={`previewComponentReply-${_id}`}
											comment={comment}
										/>
									</article>
								</div>
							);
						})}

					</Box>
				)}
			<article className="main__comment">
				<CommentArea
					objectId={objectId}
					selectorPreview="previewComponent"
				/>
			</article>
		</Box>
	);
};

const Translation = withTranslation('common')(CComments);

const Comments = (props: ICommentsProps) => (
	<Suspense fallback={<div />}>
		<Translation {...props} />
	</Suspense>
);

export { Comments };
