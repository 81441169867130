// @ts-nocheck
import React, {
	useState, Fragment, Suspense, useEffect,
} from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { APIIdeas, APIPlatforms } from '@services/apis';
import {
	INLPs, IPaginatedRequest, ITrends, ITrendsList, TContentType,
} from 'src/@types/IShared';
import { handleAxiosErrors } from '@services/auth';
import FiltersNavBar from '@components/NavBar/FiltersNavBar';
import { SSelect } from '@components/DynamicForm/Components/SSelect';
import { SBox } from '@components/Background/SBox';
import { SButton } from '@components/Buttons';
import {
	Box, BoxProps, Divider, Grid, IconButton,
} from '@mui/material';
import { SMenu } from '@components/SMenu';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { FaAlignJustify, FaMap } from 'react-icons/fa';
import { nlpCategories } from './Helpers';
import TreeMap from './TreeMap';
import { AccordionArticle } from './AccordionArticle';

declare type TMode = 'list' | 'map';

interface ITreeMapGroup {
	id: string
	label: string
	weight: number
	groups: {
		abstract: string
		authors: {
			name: string
			href: string
		}[]
		date: {}
		href: string
		id: string
		label: string
		name: string
		similarity: number
		source: string
		title: string
		weight: number
		_value: string
	}[]
}

interface TTrendsList extends ITrendsList {
	isLoading: boolean
	timeoutId?: string | null
	trends: ITrends[]
}

interface ITrendsRequest extends IPaginatedRequest {
	contentType?: TContentType
	count?: number
}

interface TTrendsMap extends INLPs {
	isLoading: boolean
	timeoutId?: string
}

interface SignalsProps extends BoxProps {
	ideaId?: string
	platformId?: string
	classes?: { zoomIn?: string, zoomOut?: string }
}

interface IMutation {
	mode: TMode
	contentType: TContentType
}

declare type TProps = SignalsProps & WithTranslation

const _Signals = ({
	t, ideaId, platformId, classes, sx,
}: TProps) => {
	const [list, setList] = useState<TTrendsList>({ trends: [], isLoading: true });
	const [NLPs, setNLPs] = useState<TTrendsMap>({ groups: [], isLoading: true });
	const [anchorFilters, setAnchorFilters] = useState<null | HTMLElement>(null);

	const sorts = [
		{ value: 'first-last', label: t('sort.moreSimilar') },
		{ value: 'last-first', label: t('sort.lessSimilar') },
		{ value: 'z-a', label: t('sort.z-a') },
		{ value: 'a-z', label: t('sort.a-z') },
	];

	const limits = [
		{ value: 10, label: `10 ${t('global.items')}` },
		{ value: 20, label: `20 ${t('global.items')}` },
		{ value: 30, label: `30 ${t('global.items')}` },
		{ value: 40, label: `40 ${t('global.items')}` },
		{ value: 50, label: `50 ${t('global.items')}` },
	];

	const [mutation, setMutation] = useState<IMutation>({
		mode: 'list',
		contentType: nlpCategories()[0].value,
	});

	const [queryParams, setQueryParams] = useState<ITrendsRequest>({
		limit: 10, sort: 'first-last',
	});

	// filter === limit

	const loadTrends = async (params: ITrendsRequest, _mutation: IMutation) => {
		setQueryParams({ ...queryParams, ...params });

		const { limit, sort } = params;

		const updatedParams = { ...mutation, ..._mutation };

		setMutation(updatedParams);

		switch (updatedParams.mode) {
			case 'list':
				try {
					setList({ ...list, trends: [], isLoading: true });
					let response: ITrendsList;

					if (ideaId) {
						response = await APIIdeas.getIdeaTrendsList(
							ideaId,
							limit,
							sort,
							updatedParams.contentType,
						);
					}
					if (platformId) {
						response = await APIPlatforms.getPlatformTrendsList(
							platformId,
							limit,
							sort,
							updatedParams.contentType,
						);
					}
					setList({ ...response.data, isLoading: false });
				} catch (err) {
					handleAxiosErrors(err);
				}
				break;
			case 'map':
				try {
					setNLPs({ ...NLPs, isLoading: true });
					let response;

					if (ideaId) { response = await APIIdeas.getIdeaTrendsNLPs(ideaId); }
					if (platformId) { response = await APIPlatforms.getPlatformTrendsNLPs(platformId); }
					setNLPs({ ...response.data, isLoading: false });
				} catch (err: any) {
					handleAxiosErrors(err);
				}
				break;
			default:
				break;
		}
	};

	const updateTrends = async (params: ITrendsRequest, _mutation: IMutation) => {
		try {
			if (ideaId) { await APIIdeas.postTrendsIdea(ideaId); }

			if (platformId) { await APIPlatforms.postTrendsPlatform(platformId); }

			loadTrends(params, _mutation);
		} catch (err) {
			handleAxiosErrors(err);
		}
	};

	useEffect(() => {
		loadTrends(queryParams, mutation);
	}, []);

	const openFilters = Boolean(anchorFilters);
	const disabled = mutation.mode === 'list' ? list.isLoading : NLPs.isLoading;

	return (
		<Box id="trendsView" className="trends__list" sx={sx}>
			<FiltersNavBar
				id="signals-nav"
				disabled={disabled}
				onUpdate={(params) => loadTrends(params, mutation)}
				limits={mutation.mode === 'list' ? limits : undefined}
				className={classes?.zoomOut}
				sorts={mutation.mode === 'list' ? sorts : undefined}
				params={queryParams}
				sx={{
					'.s-menu-sort': {
						display: mutation.mode === 'list' ? 'flex' : 'none',
					},
				}}
				components={{
					LeftButton: (
						<Box sx={{
							display: 'flex',
							alignItems: 'center',
						}}
						>
							<IconButton
								type="button"
								size="small"
								color="default"
								sx={{ borderRadius: 0, color: mutation.mode === 'list' ? 'var(--charcoal-color)' : 'var(--disabled-color)' }}
								disabled={disabled}
								onClick={() => loadTrends(queryParams, { mode: 'list' })}
							>
								<FaAlignJustify />
							</IconButton>
							<Divider orientation="vertical" sx={{ height: '30px !important', marginX: '8px' }} />
							<IconButton
								type="button"
								size="small"
								color="default"
								sx={{ borderRadius: 0, color: mutation.mode === 'map' ? 'var(--charcoal-color)' : 'var(--disabled-color)' }}
								disabled={disabled}
								onClick={() => loadTrends(queryParams, { mode: 'map' })}
							>
								<FaMap />
							</IconButton>
							{mutation.mode === 'list' && (
								<SMenu
									id="signals-filters-menu"
									open={openFilters}
									anchorEl={anchorFilters}
									setAnchorEl={setAnchorFilters}
									options={nlpCategories()}
									disabled={disabled}
									onChange={(option) => loadTrends(queryParams, { contentType: option.value })}
									sx={{
										display: 'flex',
										justifyContent: 'space-between',
										'.MuiButton-endIcon': {
											transform: 'scale(1)',
											svg: {
												transform: openFilters ? 'rotate(180deg)' : 'rotate(0deg);',
											},
										},
									}}
								>
									<SButton
										color="secondary"
										endIcon={<MdKeyboardArrowDown />}
										disabled={disabled}
										sx={{ marginX: '8px' }}
										className={`arrow ${openFilters ? 'down' : 'up'}`}
									>
										{nlpCategories()?.find((item) => item.value === mutation.contentType)?.label}
									</SButton>
								</SMenu>
							)}
						</Box>
					),
					RightButton: (
						<SButton
							type="button"
							color="inherit"
							variant="text"
							sx={{ borderRadius: 0 }}
							disabled={disabled}
							isLoading={mutation.mode === 'list' ? list.isLoading : NLPs.isLoading}
							onClick={() => updateTrends(queryParams, mutation)}
							startIcon={<i className="fa fa-arrow-rotate-right" />}
						>
							{t('global.reload')}
						</SButton>
					),
				}}
			/>
			<Box marginY={3}>
				{mutation.mode === 'list' && (
					<SBox className={classes?.zoomOut} loading={list.isLoading} empty={list.trends?.length < 1 ? 'trends' : undefined}>
						<Grid
							container
							sx={{
								flexDirection: 'row',
								flexWrap: 'nowrap',
							}}
						>
							<Grid item xs={6}>{t('global.documents')}</Grid>
							<Grid item xs={3} marginLeft={2}>{t('global.year')}</Grid>
							<Grid item xs={3}>{t('global.similarity')}</Grid>
							<Grid item sx={{ width: '30px' }} />
						</Grid>
						{list?.trends?.map((element, index) => (
							<Fragment key={index}>
								<AccordionArticle
									article={element.artigo}
									nlp={element.nlp}
									index={index}
									similarity={element.similarity}
								/>
							</Fragment>
						))}
					</SBox>
				)}

				{mutation.mode === 'map' && (
					<TreeMap
						groups={NLPs.groups}
						isLoading={NLPs.isLoading}
						classes={classes}
					/>
				)}
			</Box>

		</Box>
	);
};

const Translation = withTranslation('common')(_Signals);

const Signals = (props: SignalsProps) => (
	<Suspense fallback={<div />}>
		<Translation {...props} />
	</Suspense>
);

export { Signals };
