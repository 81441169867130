// @ts-nocheck
import React from 'react';
import { DynamicCircle } from '@components/DynamicCircle';
import { SDraggable } from '@components/Shared/SDraggable';
import {
	AutocompleteGetTagProps, AutocompleteRenderGetTagProps, Box,
	Chip, FormHelperText, IconButton, Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import Icon from '@components/Shared/Icon';
import { AiOutlineClose } from 'react-icons/ai';

interface IAutocompleteOption {
  label: string
  value: string
	isFixed?: boolean
}

interface TagProps<T=IAutocompleteOption> extends ReturnType<AutocompleteGetTagProps> {
  option: T;
	draggable?: boolean
	single?: boolean
  renderTags?: boolean
	disabled?: boolean
}

interface TagsProps<T=IAutocompleteOption> extends ReturnType<AutocompleteRenderGetTagProps> {
	value: T[]
	onReorder?: (value: T[]) => T[]
	component: (option: T, index: number) => React.ReactNode
}

export const Tag = ({
	option, draggable, renderTags, ...rest
}: TagProps) => (
	<Chip
		variant="outlined"
		label={typeof option === 'object' ? option.label : option}
		disabled={typeof option === 'object' && option.isFixed}
		sx={{
			'&.MuiButtonBase-root.MuiChip-root': {
				cursor: draggable ? 'grab' : 'auto',
				gap: 1,
			},
		}}
		deleteIcon={(
			<IconButton size="small">
				<AiOutlineClose />
			</IconButton>
		)}
		{...rest}
	/>
);

export const Tags = (props: TagsProps) => {
	const {
		value, onReorder, component,
	} = props;

	if (!value) return null;

	return (
		<SDraggable
			value={value}
			onReorder={onReorder}
			component={(option: IAutocompleteOption | string, index: number) => (
				<div className={`d-flex ${typeof option === 'object' && option.isFixed ? 'is-fixed' : ''}`}>
					{component(option, index)}
				</div>
			)}
		/>
	);
};

export interface IUserOption {
  value: string
  label: string
  isFixed?: boolean
  image?: string
  color: string
  isAuthor?: boolean
  status?: string
  _id?: string
}

export const getUserOptions = (data: []) : IUserOption[] => {
	if (!data) return [];
	const options = data.map((item) => {
		const image = item.user?.image || item.image;
		const name = item.user?._nome || item.user?.nome || item.nome || item.name;
		const color = item.user?.cor || item.cor || item.color || '#04486E';
		return ({
			...item,
			value: name,
			name,
			label: name,
			image,
			color,
		});
	});

	return options;
};

export const UserTag = (props: TagProps<IUserOption>) => {
	const { t } = useTranslation();
	const {
		option, onDelete, renderTags, single, disabled, ...rest
	} = props;

	if (!option) return null;

	return (
		<Box className="autocomplete-item" {...rest}>
			<Box
				className="autocomplete-label-1"
				sx={{
					display: 'flex',
					justifyContent: 'center',
					gap: 1,
					alignItems: 'center',
					'.s-circle': {
						color: option?.status === 'pendente' ? 'transparent' : '#fff',
					},
				}}
			>
				{option?.status === 'pendente' && (
					<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 12 12" fill="none" style={{ position: 'absolute', zIndex: 1 }}>
						<path d="M6 0C2.68548 0 0 2.68548 0 6C0 9.31452 2.68548 12 6 12C9.31452 12 12 9.31452 12 6C12 2.68548 9.31452 0 6 0ZM6 10.8387C3.32661 10.8387 1.16129 8.67339 1.16129 6C1.16129 3.32661 3.32661 1.16129 6 1.16129C8.67339 1.16129 10.8387 3.32661 10.8387 6C10.8387 8.67339 8.67339 10.8387 6 10.8387ZM7.49516 8.3129L5.44113 6.82016C5.36613 6.76452 5.32258 6.67742 5.32258 6.58548V2.6129C5.32258 2.45323 5.45323 2.32258 5.6129 2.32258H6.3871C6.54677 2.32258 6.67742 2.45323 6.67742 2.6129V6.04113L8.29355 7.21694C8.42419 7.31129 8.45081 7.49274 8.35645 7.62339L7.90161 8.25C7.80726 8.37823 7.62581 8.40726 7.49516 8.3129Z" fill="white" />
					</svg>
				)}
				<DynamicCircle
					image={option.image}
					background={option.color}
					initial={option.label}
					size="small"
				/>
			</Box>
			<Typography className="autocomplete-label-2" sx={{ whiteSpace: 'nowrap' }}>
				{option.label}
			</Typography>
			<span className="autocomplete-label-3">
				{option.isAuthor && `(${t('global.author')})`}
			</span>
			{(renderTags && !option.isFixed && !single && !disabled) && (
				<IconButton onClick={onDelete} className="autocomplete-label-3" size="small">
					<AiOutlineClose />
				</IconButton>
			)}
		</Box>
	);
};

interface ISelectOptionLabelProps {
	icon?: string | React.ReactNode
	label: string | React.ReactNode
	details?: string | React.ReactNode
}

export const SSelectOptionLabel = ({ icon, label, details }: ISelectOptionLabelProps) => (
	<Box className="d-flex flex-column">
		{typeof icon === 'object' && icon}
		{typeof icon === 'string' && <Icon icon={icon} />}
		{typeof label === 'object'
			? label
			: (
				<span className={`item-description ${icon ? 'col-9 pr-0' : 'col-12'}`}>
					{label}
				</span>
			)}
		{details && (
			<FormHelperText className={`item-details ${icon ? 'col-9 pr-0' : 'col-12'}`}>
				{details}
			</FormHelperText>
		)}
	</Box>
);
