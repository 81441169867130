// @ts-nocheck
import React, { Suspense, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { WithTranslation, withTranslation } from 'react-i18next';
import { APIBilling, APIGlobal } from '@services/apis';
import { isEmpty } from '@utils/ReactUtils';
import { SButton } from '@components/Buttons';
import SpinnerLDSRipple from '@components/Shared/SpinnerLDSRipple';
import { TObject } from 'src/@types/IShared';
import { getValidationErrors } from '@utils/yup';
import { useSelector } from 'react-redux';
import { IRootState } from 'src/store';
import { IBillingCustomer } from 'src/@types/IBilling';
import { DynamicForm } from '@components/DynamicForm';
import { inputTypes } from '@components/DynamicForm/FieldsTypes';
import { handleAxiosErrors } from '@services/auth';
import countries from '@utils/CountriesList';
import { SBox } from '@components/Background/SBox';
import { Grid } from '@mui/material';
import { SToast } from '@modules/SToast';

type TProps = WithTranslation;

const BCustomer = ({ t }: TProps) => {
	const billing = useSelector((state: IRootState) => state.billing?.subscription?.billingCustomer?.metadata);
	const [billingCustomer, setBillingCustomer] = useState<IBillingCustomer>(billing);
	const [country, setCountry] = useState((billingCustomer?.address
		&& billingCustomer?.address.country)
		? billingCustomer?.address.country
		: 'BR');

	const [errors, setErrors] = useState<TObject>({});
	const [inProgress, setInProgress] = useState(false);

	const setFieldError = (key: string, error: string) => {
		setErrors({ ...errors, [key]: error });
	};

	const onAddressHandler = (addr) => {
		if (addr.country) {
			setCountry(addr.country);

			setBillingCustomer({
				...billingCustomer,
				address: {
					...billingCustomer?.address,
					country: addr.country,
				},
			});
			return;
		}

		setBillingCustomer({
			...billingCustomer,
			address: {
				...billingCustomer?.address,
				...addr,
			},
		});
	};

	const onCustomerHandler = (key: string, value: string) => {
		delete errors?.[key];

		setBillingCustomer({
			...billingCustomer,
			[key]: value,
		});
	};

	// @ts-ignore
	const loadAddressByZipCode = async ({ target }) => {
		const cep = target.value;

		if (cep.length > 0) {
			try {
				const response = await APIGlobal.getAddress(cep);

				onAddressHandler({
					line1: response.data.logradouro,
					district: response.data.bairro,
					city: response.data.localidade,
					state: response.data.uf,
				});

				delete errors.postal_code;
				setFieldError(errors);

				(document.getElementById('customer_number') as HTMLInputElement).focus();
			} catch (err) {
				setFieldError('postal_code', t('messages.zipCodeNotFound'));
				console.log('err method loadAddressByZipCode', err);
			}
		} else {
			delete errors.postal_code;

			setFieldError(errors);
		}
	};

	const onSubmit = async () => {
		try {
			setInProgress(true);
			const schema = Yup.object().shape({
				name: Yup.string().required(t('messages.requiredField')).nullable(),
				email: Yup.string().email(t('messages.email.isInvalid')).required(t('messages.requiredField')),
			});

			await schema.validate(billingCustomer, {
				abortEarly: false,
			});

			await APIBilling.putCustomer(billingCustomer);

			SToast.success(t('messages.success.saved'));
		} catch (err) {
			if (err instanceof Yup.ValidationError) {
				setErrors(getValidationErrors(err));
			} else handleAxiosErrors(err);
		} finally {
			setInProgress(false);
		}
	};

	useEffect(() => {
		if (country.value === 'BR') (document.getElementById('customer_postal_code') as HTMLInputElement)?.focus();
		else (document.getElementById('customer_city') as HTMLElement)?.focus();
	}, [country]);

	return (
		<SBox id="billingCustomer" sx={{ width: { xs: '100%', sm: '50%' } }} loading={!billingCustomer}>
			<Grid container sx={{ display: 'flex', flexDirection: 'column' }} spacing={3}>
				<Grid item>
					<DynamicForm
						id="billing-customer-form"
						className="s-form"
						errors={errors}
						form={{
							fields: [
								{
									id: 'name',
									name: 'name',
									placeholder: t('form.placeholder.customer.name'),
									label: t('global.name'),
									type: 'text',
									inputype: inputTypes.TEXT_INPUT,
									value: billingCustomer?.name,
									handleField: (e: Event) => onCustomerHandler('name', e.target.value),
								},
								{
									id: 'email',
									name: 'email',
									label: t('form.email'),
									placeholder: t('form.placeholder.customer.email'),
									type: 'email',
									inputype: inputTypes.TEXT_INPUT,
									value: billingCustomer?.email,
									handleField: (e: Event) => onCustomerHandler('email', e.target.value),
								},

								{
									name: 'country',
									label: t('form.country'),
									placeholder: t('form.placeholder.country'),
									inputype: inputTypes.TEXT_PICKER,
									options: countries,
									handleField: (e: any) => onAddressHandler({ country: e.target?.value }),
									value: country,
								},
								{
									id: 'customer_postal_code',
									name: 'postal_code',
									label: t('form.zipCode'),
									placeholder: t('form.placeholder.zipCode'),
									mask: '00000-000',
									inputype: inputTypes.MASKER_INPUT,
									error: typeof errors?.postal_code === 'string',
									value: billingCustomer?.address?.postal_code || '',
									onBlur: loadAddressByZipCode,
									handleField: (e) => onAddressHandler({ postal_code: e.target.value }),
								},
								{
									name: 'state',
									placeholder: t('form.placeholder.state'),
									label: t('form.state'),
									disabled: country.value === 'BR',
									inputype: inputTypes.TEXT_INPUT,
									className: country.value === 'BR' ? 'disabled_field' : '',
									value: billingCustomer?.address?.state || '',
									handleField: (e) => onAddressHandler({ state: e.target.value }),
								},
								{
									id: 'customer_city',
									name: 'city',
									placeholder: t('form.placeholder.city'),
									inputype: inputTypes.TEXT_INPUT,
									label: t('form.city'),
									disabled: country.value === 'BR',
									className: country.value === 'BR' ? 'disabled_field' : '',
									value: billingCustomer?.address?.city || '',
									handleField: (e) => onAddressHandler({ city: e.target.value }),
								},

								{
									name: 'district',
									placeholder: t('form.placeholder.district'),
									inputype: inputTypes.TEXT_INPUT,
									label: t('form.district'),
									disabled: country.value === 'BR',
									className: country.value === 'BR' ? 'disabled_field' : '',
									value: billingCustomer?.address?.district || '',
									handleField: (e) => onAddressHandler({ district: e.target.value }),
								},
								{
									name: 'line1', // street
									placeholder: t('form.placeholder.street'),
									label: t('form.street'),
									inputype: inputTypes.TEXT_INPUT,
									disabled: country.value === 'BR',
									value: billingCustomer?.address?.line1 || '',
									className: country.value === 'BR' ? 'disabled_field' : '',
									handleField: (e) => onAddressHandler({ line1: e.target.value }),
								},
								{
									id: 'customer_number',
									name: 'line2',
									placeholder: t('form.placeholder.number'),
									label: t('form.number'),
									inputype: inputTypes.TEXT_INPUT,
									type: 'text',
									value: billingCustomer?.address?.line2 || '',
									handleField: (e) => {
										// to not add an non numeric number
										const value = e.target.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');
										onAddressHandler({ line2: value });
									},
								},
								{
									label: t('form.phone'),
									placeholder: t('form.placeholder.phone'),
									inputype: inputTypes.MASKER_INPUT,
									value: billingCustomer?.phone,
									error: errors?.phone,
									mask: '(00) 0 0000-0000',
									handleField: (e) => onCustomerHandler('phone', e.target.value),
								},
							],
						}}
					>
						<Grid item container className="footer" marginTop={3}>
							<Grid item>
								<SButton
									type="button"
									color="secondary"
									variant="outlined"
									size="large"
									onClick={onSubmit}
									error={!isEmpty(errors)}
									isLoading={inProgress}
								>
									{t('global.save')}
								</SButton>
							</Grid>
						</Grid>
					</DynamicForm>
				</Grid>
			</Grid>
		</SBox>
	);
};

const Translation = withTranslation('common')(BCustomer);

const Customer = () => (
	<Suspense fallback={<SpinnerLDSRipple />}>
		<Translation />
	</Suspense>
);

export default Customer;
