import React, { Suspense, useEffect, useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import SpinnerLDSRipple from '@components/Shared/SpinnerLDSRipple';
import { formatDate } from '@utils/ReactUtils';
import { APIBilling } from '@services/apis';
import { TableColumn } from 'react-data-table-component';
import { IInvoice } from 'src/@types/IBilling';
import TableSearch from '@components/DataTable/TableSearch';
import { handleAxiosErrors } from '@services/auth';
import { SBox } from '@components/Background/SBox';

type TProps = WithTranslation;

const BInvoices = (props: TProps) => {
	const { t } = props;
	const [loaded, setLoaded] = useState(false);
	const [invoices, setInvoices] = useState<IInvoice[]>([]);

	const columns: TableColumn<IInvoice>[] = [
		{
			name: t('global.date'),
			sortable: true,
			maxWidth: '600px',
			selector: (row) => formatDate(row.status_transitions.paid_at * 1000),
			cell: (row) => <span className="text-center">{formatDate(row.status_transitions.paid_at * 1000)}</span>,
		},
		{
			name: t('global.payment'),
			sortable: true,
			center: true,
			selector: (row) => row.metadata.paymentMethod?.card.last4 || false,
			cell: (row) => (
				<span className="text-center">
					****
					{row.metadata.paymentMethod?.card.last4}
				</span>
			),
		},
		{
			name: t('form.description'),
			sortable: true,
			selector: (row) => row.lines.data[0].description,
			cell: (row) => <span className="">{row.lines.data[0].description}</span>,
		},
		{
			name: t('global.price'),
			sortable: true,
			center: true,
			selector: (row) => Intl.NumberFormat('pt-BR', { style: 'currency', currency: row.currency }).format(row.amount_paid / 100),
			cell: (row) => (
				<span className="text-center">
					{Intl.NumberFormat('pt-BR', { style: 'currency', currency: row.currency })
						.format(row.amount_paid / 100)}
				</span>
			),
		},
		{
			name: 'Status',
			selector: (row) => row.status,
			sortable: true,
		},
		{
			name: t('global.actions'),
			grow: 0,
			center: true,
			minWidth: '200px',
			cell: (row) => (
				<a
					href={row.hosted_invoice_url}
					className="text-link underline text-14px"
					rel="noopener noreferrer"
					target="_blank"
				>
					{t('global.view')}
				</a>
			),

		},
	];

	const loadInvoices = async () => {
		try {
			setLoaded(false);
			const response = await APIBilling.getInvoices();
			setInvoices(response.data.data);
			setLoaded(true);
		} catch (err) {
			handleAxiosErrors(err);
		}
	};

	useEffect(() => {
		loadInvoices();
	}, []);

	return (
		<SBox id="billingInvoices" >
			<TableSearch
				columns={columns}
				data={invoices}
				progressPending={!loaded}
			/>
		</SBox>
	);
};

const Translation = withTranslation('common')(BInvoices);

const Invoices = () => (
	<Suspense fallback={<SpinnerLDSRipple />}>
		<Translation />
	</Suspense>
);

export default Invoices;
