// @ts-nocheck
import React, { useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Creators } from 'src/store/ducks/actionsTypes';
import PlatformCover from '@components/Covers/PlatformCover';
import IdeaCover from '@components/Covers/IdeaCover';
import { IPagination } from 'src/@types/IShared';
import GLOBAL from '@constants/GLOBAL';
import { IIdea } from 'src/@types/IIdea';
import { IPlatform } from 'src/@types/IPlatform';
import URLS from '@constants/URLS';
import { APIIdeas, APIPlatforms } from '@services/apis';
import { IdeaCard } from '@components/Cards/IdeaCard';
import { PlatformCard } from '@components/Cards/PlatformCard';
import { handleAxiosErrors } from '@services/auth';
import CardGrid, { TCards } from '@components/Grids/CardGrid';
import routes from '@routes/modal';
import { useDispatch } from 'react-redux';
import { Box } from '@mui/material';
import { GenericGrid, W_GRID } from '@components/Grids/GenericGrid';
import PageLoader from '@components/Loaders/PageLoader';
import { SBox } from '@components/Background/SBox';

interface IState {
	challenges: IPagination<IPlatform>
	brainstorms: IPagination<IPlatform>
	ideas: IPagination<IIdea>
}

type TProps = WithTranslation

const HHome = ({ t }: TProps) => {
	const dispatch = useDispatch();
	const [state, setState] = useState<IState>({
		ideas: { docs: [], isLoading: true },
		challenges: { docs: [], isLoading: true },
		brainstorms: { docs: [], isLoading: true },
	});

	const loadPlatforms = async (type: 'brainstorm' | 'challenge') => {
		try {
			const response = await APIPlatforms.getList({
				page: 1,
				filter: 'recommendations',
				limit: GLOBAL.DOCS_PER_PAGE,
			}, type);

			const key = `${type}s` as keyof IState;

			setState((prevState) => ({
				...prevState,
				[key]: {
					...prevState[key],
					...response.data,
					isLoading: false,
				},
			}));
		} catch (err: any) {
			handleAxiosErrors(err);
		}
	};

	const updateHandler = (element: IIdea | IPlatform) => {
		Object.keys(state).forEach((k) => {
			const key = k as keyof IState;
			const index = state[key].docs?.findIndex((item) => item._id === element._id);
			if (index > -1) {
				// @ts-ignore
				state[key]?.docs.splice(index, 1, { ...state[key]?.docs[index], ...element });
				setState((prevState) => ({
					...prevState,
					[key]: { ...prevState[key] },
				}));
			}
		});
	};

	const loadIdeas = async () => {
		try {
			const response = await APIIdeas.getList({
				page: 1,
				filter: 'recomendadas',
				limit: GLOBAL.DOCS_PER_PAGE,
			});

			setState((prevState) => ({
				...prevState,
				ideas: {
					...prevState.ideas,
					...response.data,
					isLoading: false,
				},
			}));
		} catch (err: any) {
			handleAxiosErrors(err);
		}
	};

	React.useEffect(() => {
		loadIdeas();
		loadPlatforms('challenge');
		loadPlatforms('brainstorm');

		dispatch(Creators.IDEAS.updateIdeas({ syncIdea: updateHandler }));
		dispatch(Creators.PLATFORMS.updatePlatform({ syncPlatform: updateHandler }));
	}, []);

	const carrouselData = [...state.challenges.docs, ...state.brainstorms.docs, ...state.ideas.docs];
	const carrouselInner = carrouselData.length > 0 && carrouselData.map((item, index) => (
		<div
			key={index}
			className={`carousel-item ${index === 0 ? 'active' : ''}`}
		>
			{['Brainstorm', 'Challenge'].includes((item as IPlatform).type) ? (
				<PlatformCover
					platform={item as IPlatform}
					updatePlatform={updateHandler}
					isHome
				/>
			) : (
				<IdeaCover
					idea={item as IIdea}
					updateIdea={updateHandler}
					isHome
				/>
			)}
		</div>
	));

	return (
		<GenericGrid id="home-page" className="home__page s-grid-page">
			{carrouselData.length < 1 ? null : (
				<div
					id="carouselHomeControls"
					className="carousel slide"
					data-bs-ride="carousel"
				>
					<div className="carousel-inner">{carrouselInner}</div>
					<div className="carousel-controls">
						<a
							className="carousel-control-prev"
							href="#carouselHomeControls"
							role="button"
							data-bs-slide="prev"
						>
							<span
								className="carousel-control-prev-icon"
								aria-hidden="true"
							/>
							<span className="sr-only">Previous</span>
						</a>
						<a
							className="carousel-control-next"
							href="#carouselHomeControls"
							role="button"
							data-bs-slide="next"
						>
							<span
								className="carousel-control-next-icon"
								aria-hidden="true"
							/>
							<span className="sr-only">Next</span>
						</a>
					</div>
				</div>
			)}

			{
				[
					{
						type: 'brainstorms',
						section: state.brainstorms,
						href: `${URLS.BRAINSTORMS}?filter=recommendations`,
						component: (platform: IPlatform) => (
							<PlatformCard
								platform={platform}
								routes={routes}
								sectionLocator="brainstorms" // section locator parameter to update
								updatePlatform={updateHandler}
							/>
						),
					},
					{
						type: 'challenges',
						section: state.challenges,
						href: `${URLS.CHALLENGES}?filter=recommendations`,
						component: (platform: IPlatform) => (
							<PlatformCard
								platform={platform}
								routes={routes}
								sectionLocator="challenges" // section locator parameter to update
								updatePlatform={updateHandler}
							/>
						),
					},
					{
						type: 'ideas',
						section: state.ideas,
						href: `${URLS.IDEAS}?filter=recomendadas`,
						component: (idea: IIdea) => (
							<IdeaCard
								idea={idea}
								routes={routes}
								sectionLocator="ideas"
								updateIdea={updateHandler}
							/>
						),
					},
				].map((item) => (
					<Box className={W_GRID.W_GRID_1} key={item.type}>
						<CardGrid
							id={`${item.type}-home`}
							headerLabel={t(`page.${item.type}.recommended`)}
							headerSubLabel={t('page.selection')}
							href={item.href}
							GridItemProps={{ xs: 12, sm: 6, md: 4, xl: 3}}
							queryParams={{ limit: GLOBAL.DOCS_PER_PAGE }}
							section={item.section}
							type={item.type as TCards}
							component={item.component}
						/>
					</Box>
				))
			}
		</GenericGrid>
	);
};

const Translation = withTranslation('common')(HHome);

const Home = () => (
	<React.Suspense fallback={<PageLoader />}>
		<Translation />
	</React.Suspense>
);

export default Home;
