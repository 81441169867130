// @ts-nocheck
import React, { useState } from 'react';
import { debounce } from '@utils/helpers';
import { isEmpty } from '@utils/ReactUtils';
import { useSelector } from 'react-redux';
import { APIGlobal } from '@services/apis';
import { IRootState } from 'src/store';
import { handleAxiosErrors } from '@services/auth';
import URLS from '@constants/URLS';
import Icon from '@components/Shared/Icon';
import {
	Box, BoxProps, ClickAwayListener, InputAdornment, ListItemIcon, ListItemText, MenuItem, MenuList, Paper, Popper, TextField, Typography,
} from '@mui/material';
import { SBox } from '@components/Background/SBox';
import { UserAvatar } from '@components/Avatar/UserAvatar';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export declare type TSearch = 'platform' | 'idea' | 'connect' | 'innovation' | 'board' | 'teams' | 'user';

const HHeaderSearch = (props: BoxProps) => {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const navigate = useNavigate();
	const { t } = useTranslation();
	const location = useLocation();
	const [query, setQuery] = useState('');
	const [search, setSearch] = useState({});
	const [loaded, setLoaded] = useState(false);

	const fetch = React.useCallback(debounce(async (value: string) => {
		try {
			setLoaded(false);
			const response = await APIGlobal.getSearch({ query: value, limit: 3 });

			setSearch(response.data);

			setLoaded(true);
		} catch (err) {
			handleAxiosErrors(err);
		}
	}, 100), []);

	const onChangeInput = (event: React.KeyboardEvent<HTMLInputElement>) => {
		setQuery(event.target.value);
		fetch(event.target.value);
		setAnchorEl(event.target);
	};

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
		fetch(event.target.value);
	};

	React.useEffect(() => {
		const queryParam = new URLSearchParams(location.search)?.get('query') || '';
		setQuery(queryParam);
	}, []);

	const open = Boolean(anchorEl);
	const id = open ? 'header-search' : undefined;


	return (
		<ClickAwayListener onClickAway={() => setAnchorEl(null)}>
			<Box {...props}>
				<Box
					className="form-search"
					noValidate
					// component="form"
					autoComplete="off"
				>
					<TextField
						type="search"
						placeholder={t('global.search')}
						aria-label="Search"
						size="small"
						fullWidth
						value={query}
						variant="standard"
						name="query"
						onChange={onChangeInput}
						aria-describedby={id}
						onKeyDown={(e: React.KeyboardEvent) => {
							if (e.key === 'Enter') {
								navigate(`${URLS.SEARCH}?query=${query}`);
							}
						}}
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<Icon icon="search" />
								</InputAdornment>
							),
						}}
					/>
				</Box>

				<Popper
					id={id}
					open={open}
					anchorEl={anchorEl}
					disablePortal
					className="search-popper"
					// onClose={() => setAnchorEl(null)}
					onClick={() => setAnchorEl(null)}
					placement="bottom"
					modifiers={[
						{
							name: 'flip',
							enabled: true,
							options: {
								altBoundary: true,
								rootBoundary: 'document',
								padding: 8,
							},
						},
						{
							name: 'preventOverflow',
							enabled: true,
							options: {
								altAxis: true,
								altBoundary: true,
								tether: true,
								rootBoundary: 'document',
								padding: 8,
							},
						},
					]}
				>
					<Paper>
						<Box sx={{
							display: 'flex', alignItems: 'center', paddingY: '6px', paddingX: '16px',
						}}
						>
							<Typography>
								{ typeof query === 'undefined' || query === ''
									? t('menu_header_busca.search')
									: (
										<>
											<Icon icon="search" className="mr-2" />
											{t('menu_header_busca.itensWith')}
											:
											{` ${query}`}
										</>
									)}
							</Typography>
						</Box>
						<SBox
							className="p-0"
							scrollable-md="true"
							loading={!loaded}
							xs={{ width: '100%' }}
						>

							{typeof query === 'undefined' || query === '' ? (
								<div className="mt-3 d-flex align-items-center justify-content-center">
									<span>{t('menu_header_busca.searchBy')}</span>
								</div>
							) : [
								{ section: search.brainstorms, type: 'brainstorm', path: URLS.BRAINSTORMS }, // type == filter: ;
								{ section: search.challenges, type: 'challenge', path: URLS.BRAINSTORMS }, // type == filter: ;
								{ section: search.ideas, type: 'idea', path: URLS.IDEAS },
								{ section: search.teams, type: 'team', path: URLS.TEAMS },
								{ section: search.users, type: 'user', path: URLS.PROFILE },
							].map((item) => (
								<Box key={item.type}>
									<Box sx={{
										display: item?.section?.docs?.length > 0 ? 'flex' : 'none',
										alignItems: 'center',
										paddingY: '6px',
										paddingX: '16px',
									}}
									>
										<ListItemIcon sx={{
											minWidth: '25px',
											color: 'var(--charcoal-color)',
										}}
										>
											<Icon icon={item.type} />
										</ListItemIcon>
										<ListItemText primary={`${t(`global.${item.type}`)} (${item?.section?.docs?.length || '0'})`} />
									</Box>

									<MenuList
										sx={{
											display: item?.section?.docs?.length > 0 ? 'flex' : 'none',
										}}
									>
										{item?.section?.docs.map((doc) => {
											const name = doc.user?._nome || doc.user?.nome || doc.nome;
											const color = doc.user?.cor || doc.cor || '#04486e';
											return (
												<MenuItem key={doc._id} onClick={() => navigate(`${item.path}/${doc._id}`)} sx={{ width: '100%' }}>
													<UserAvatar
														profile={doc.user || {
															image: doc.coverThumb || doc.cover,
															name: doc.nome,
															color,
														}}
														size="small"
														initial={name}
													/>
													<Typography sx={{ whiteSpace: 'break-spaces' }}>{name}</Typography>
												</MenuItem>
											);
										})}

										{item.section?.docs?.length > 3 && (
											<MenuItem onClick={() => navigate(`${URLS.SEARCH}?query=${query}&filter=${item.type}`)}>
												{t('menu_header_busca.seeAll')}
											</MenuItem>
										)}
									</MenuList>
								</Box>
							))}
						</SBox>
					</Paper>
				</Popper>
			</Box>
		</ClickAwayListener>
	);
};

const HeaderSearch = (props: BoxProps) => {
	const { userOrganizationProfile } = useSelector((state: IRootState) => state);

	const _fallback = (
		<form
			className="form-search loading"
			autoComplete="off"
			style={{ overflow: 'hidden' }}
		>
			<input className="form-control p-2" disabled />
		</form>
	);

	return (
		<React.Suspense fallback={_fallback}>
			{isEmpty(userOrganizationProfile) ? _fallback : <HHeaderSearch {...props} />}
		</React.Suspense>
	);
};
export default HeaderSearch;
