import {
	Radio, Box, Typography, BoxProps,
} from '@mui/material';
import React, { ChangeEvent, Suspense } from 'react';

interface TrailCardProps extends BoxProps {
	id: string
	title: string
	children: React.ReactNode
	src: string
	className?: string
	checked?: boolean
	onChange?: (event: ChangeEvent<HTMLInputElement>) => void
	LottieBoxProps?: BoxProps
}

const TTrailCard = (props: TrailCardProps) => {
	const {
		title, children, src, className, id, checked, onChange, LottieBoxProps, ...rest
	} = props;

	return (
		<Box
			{...rest}
			className={`trail-card ${className || ''} ${checked ? 'checked' : ''}`}
			sx={{
				border: '2px solid var(--charcoal-color)',
				position: 'relative',
				zIndex: '0',
				borderRadius: '4px',
				width: '100%',
				cursor: 'pointer',
				padding: '12px 20px 16px',
				display: 'flex',
				justifyContent: 'center',
				flexDirection: 'column',
				alignItems: 'center',
				...rest.sx,
			}}
		>
			<Radio
				id={id}
				color="primary"
				checked={checked}
				onChange={typeof onChange === 'function' ? onChange : undefined}
				sx={{
					marginLeft: 'auto',
					position: 'absolute',
					top: '5px',
					right: '5px',
				}}
			/>
			<Box className="lottie__player" sx={{ maxHeight: '80px', maxWidth: '80px', marginY: 2 }} {...LottieBoxProps}>
				{/* @ts-ignore */}
				<lottie-player
					src={src}
					background="transparent"
					speed="1"
					loop=""
					autoplay=""
					style={{ height: 'auto' }}
				/>
			</Box>
			<Typography className="trail-title" textAlign="center" marginY={1}>{title}</Typography>
			{children}
		</Box>
	);
};

const TrailCard = (props: TrailCardProps) => (
	<Suspense fallback={<div />}>
		<TTrailCard {...props} />
	</Suspense>
);

export { TrailCard };
