import { axiosInstance } from '@services/AxiosInstance';
import { IPaginatedRequest } from 'src/@types/IShared';
import { ITeam } from 'src/@types/ITeam';
import { getQueryParams } from './utils';

const APITeams = {
	getTeams(params: IPaginatedRequest) {
		const queryParams = getQueryParams(params);
		return axiosInstance.get(`/teams${queryParams}`);
	},
	getMembers(teamId: string, params: IPaginatedRequest) {
		const queryParams = getQueryParams(params);
		return axiosInstance.get(`/teams/${teamId}/members${queryParams}`);
	},
	getTeam(teamId: string) {
		return axiosInstance.get(`/teams/${teamId}`);
	},
	deleteTeam(teamId: string, name: string) {
		return axiosInstance.delete(`/teams/${teamId}?nome=${name}`);
	},
	patchTeam(teamId: string, team: ITeam) {
		return axiosInstance.patch(`/teams/${teamId}`, team);
	},
	postLeaveTeam(teamId: string) {
		return axiosInstance.post(`/teams/${teamId}/leave`, {});
	},
	postCreateTeam(team: ITeam) {
		return axiosInstance.post('/teams', team);
	},
};

export default APITeams;
