// @ts-nocheck
import React, {
	Suspense, ChangeEvent, useState,
} from 'react';
import { Form, SCheckbox } from '@components/DynamicForm/ComponentsCore';
import { SButton } from '@components/Buttons';
import SpinnerLDSRipple from '@components/Shared/SpinnerLDSRipple';
import { useTranslation, withTranslation, WithTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from 'src/store';
import { Creators } from '@actions';
import { APIUser } from '@services/apis';
import { handleAxiosErrors } from '@services/auth';
import { Grid } from '@mui/material';
import { SBox } from '@components/Background/SBox';

interface INotificationProps {
	onChange?: (notification) => void;
	notification: {}
	name: 'brainstorm' | 'challenge' | 'invite' | 'team'
}

const Notification = (props: INotificationProps) => {
	const { t } = useTranslation();
	const { notification, onChange, name } = props;

	const onNotificationHandler = (e: ChangeEvent<HTMLInputElement>) => {
		if (typeof onChange === 'function') {
			onChange({
				[name]: {
					...notification,
					[e.target.name]: !/true/i.test(e.target.value),
				},
			});
		}
	};

	return (
		<Grid container item flexDirection="column">
			<Grid item>
				<h5>{t(`global.${name}s`)}</h5>
			</Grid>
			<Grid item>
				{Object.keys(notification).map((key) => (
					<div className="form-group" key={`${name}-${key}`}>
						<SCheckbox
							id={`${name}-${key}`}
							name={key}
							value={notification[key]}
							checked={notification[key] === true}
							onChange={onNotificationHandler}
							label={t(`settings.notifications.${name}.${key}`)}
						/>
					</div>
				))}
			</Grid>
		</Grid>
	);
};

type TProps = WithTranslation;

const _Notifications = ({ t }: TProps) => {
	const dispatch = useDispatch();

	const { userOrganizationProfile } = useSelector((state: IRootState) => state);
	const [notifications, setNotifications] = useState(userOrganizationProfile.notifications);
	const [inProgress, setInProgress] = useState<'submit' | 'subscribe' | undefined>();

	const isAllChecked = Object.keys(notifications)
		.every((key) => Object.values(notifications[key]).every((item) => item === true));

	const onCheckAll = () => {
		const n = { ...notifications };

		Object.keys(n).forEach(
			(key) => Object.keys(n[key])
				.forEach((k) => {
					n[key][k] = !isAllChecked;
				}),
		);

		setNotifications(n);
	};

	const onSubmitHandler = async () => {
		try {
			setInProgress('submit');
			const response = await APIUser.pathNotificationsProfile(notifications);
			dispatch(Creators.USER_ORGANIZATION.successUser({
				notifications: response.data.notifications,
			}));
		} catch (err) {
			handleAxiosErrors(err);
		} finally {
			setInProgress();
		}
	};

	return (
		<SBox
			className="user-notifications"
			loading={!userOrganizationProfile || !userOrganizationProfile.notifications || !notifications}
		>
			<Grid container spacing={3} display="flex" flexDirection="column">
				<Grid item>
					<Form className="s-form">
						<SCheckbox
							id="flexSwitchCheckDefault"
							checked={isAllChecked}
							onChange={onCheckAll}
							isLoading={inProgress === 'subscribe'}
							label={isAllChecked ? t('settings.unsubscribeAll') : t('settings.subscribeAll')}
						/>
						{Object.keys(notifications).map((key) => (
							<div key={key}>
								<Notification
									name={key}
									notification={notifications[key]}
									onChange={(n) => setNotifications((prevState) => ({ ...prevState, ...n }))}
								/>
							</div>
						))}
					</Form>
				</Grid>
				<Grid item className="footer">
					<SButton
						type="button"
						variant="outlined"
						size="large"
						onClick={onSubmitHandler}
						isLoading={inProgress === 'submit'}
						// error={errors?.name}
					>
						{t('global.save')}
					</SButton>
				</Grid>
			</Grid>
		</SBox>
	);
};

const Translation = withTranslation('common')(_Notifications);

const Notifications = () => (
	<Suspense fallback={<SpinnerLDSRipple />}>
		<Translation />
	</Suspense>
);
export default Notifications;
