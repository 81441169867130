import { Box } from '@mui/material';
// eslint-disable-next-line import/no-extraneous-dependencies
import { styled as MuiStyled } from '@mui/system';

export const StickerIdeaBox = MuiStyled(Box)(({ theme }) => ({
	textTransform: 'capitalize',
	willChange: 'box-shadow, transform',
	whiteSpace: 'nowrap',
	borderRadius: '2px',
	height: '25px',
	fontWeight: '400',
	gap: '8px',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	overflowX: 'hidden',
	fontSize: '14px',
	backgroundColor: 'var(--fog-gray-color)',
	color: 'var(--iron-gray-color)',
	cursor: 'auto !important',

	'&.badges': {
		backgroundColor: 'var(--sky-blue-color)',
		color: '#fff',
		cursor: 'pointer',
	},
	'&.sponsorships': {
		backgroundColor: 'var(--flamingo-color)',
		color: '#fff',
		cursor: 'pointer',
	},

	'&.status': {
		'&.aprovada': {
			backgroundColor: 'var(--success-color)',
			color: '#fff',
		},
		'&.reprovada': {
			backgroundColor: 'var(--error-color)',
			color: '#fff',
		},
	},

}));
