// @ts-nocheck
import React, { Suspense, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { FLOWS, maxLength } from '@constants/GLOBAL';
import { IRootState, persistor } from 'src/store';
import type { IModal } from '@modules/Modal/types';
import {
	DialogBox,
} from '@modules/Modal/Components';
import {
	Form, SFormGroup, SInputLabel,
} from '@components/DynamicForm/ComponentsCore';
import { SButton } from '@components/Buttons';
import { STextField } from '@forms/Components';
import { handleAxiosErrors } from '@services/auth';
import { Creators } from '@actions';
import { APIIdeas, APIPlatforms } from '@services/apis';
import { isEmpty } from '@utils/ReactUtils';
import { DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { SToast } from '@modules/SToast';
import { SBox } from '@components/Background/SBox';

declare type TProps = IModal & WithTranslation;

const MModalCreate = (props: TProps) => {
	const { t, routes } = props;

	const dispatch = useDispatch();
	const { flow, ModalProps } = useSelector((state: IRootState) => state.modal);
	const [name, setName] = useState('');
	const [inProgress, setInProgress] = useState<boolean>(false);
	const [errors, setErrors] = useState<string>({});

	const onErrorHandler = (key: string, value?: string) => {
		if (typeof value === 'string') {
			setErrors((prevState) => ({ ...prevState, [key]: value }));
			return;
		}
		if (!isEmpty(errors)) delete errors[key];
	};

	const onCreateHandler = async (event) => {
		event?.preventDefault();
		setInProgress(true);

		if (name === '') {
			onErrorHandler('name', t('messages.requiredField'));
			setInProgress(false);
			document.getElementById('flow-name')?.focus();
			return;
		}

		try {
			let response = null;

			switch (flow) {
				case FLOWS.IDEA:

					response = await APIIdeas.postCreateIdea({ nome: name, platformId: ModalProps?.platformId });

					dispatch(Creators.IDEAS.updateIdeas({ workflow: 'CREATE_IDEA', flow, ...response.data }));
					dispatch(Creators.MODAL.showModal(routes?.MODAL_IDEA_TEMPLATES.path));
					SToast.success(t('messages.success.idea.created'));
					break;
				case FLOWS.BRAINSTORM:
				case FLOWS.CHALLENGE:

					response = await APIPlatforms.postCreatePlatform({
						nome: name,
						type: flow.capitalizeFirstLetter(),
					});

					dispatch(Creators.PLATFORMS.updatePlatform({ workflow: 'CREATE_PLATFORM', ...response.data }));
					dispatch(Creators.MODAL.showModal(routes?.MODAL_PLATFORM_TEMPLATES.path));
					SToast.success(t('messages.success.platform.created', { type: t(`global.${flow}`) }));
					break;
				default:
					break;
			}

			persistor.persist();
		} catch (err) {
			handleAxiosErrors(err);
			SToast.error(t('messages.error.platform.create', { type: t(`global.${flow}`) }));
		} finally {
			setInProgress(false);
		}
	};

	const onCancel = () => {
		dispatch(Creators.MODAL.hideModal());
	};

	const handleValue = (e) => {
		setName(e.target.value);
	};

	return (
		<DialogContent id="CreateDialog">
			<SBox loading={!flow}>
				<DialogBox className="s-dialog-title">
					<DialogTitle variant="h6">
						{t(`form.flow.whatIsTheName.${flow}`)}
					</DialogTitle>
				</DialogBox>

				<Form className="s-form" onSubmit={onCreateHandler}>
					<DialogBox className="s-dialog-content">
						<SFormGroup>
							<SInputLabel htmlFor="flow-name" error={typeof errors?.name === 'string'}>
								{t('form.enterATitletoGetStarted')}
							</SInputLabel>
							<STextField
								// onKeyUp={(e) => (e.keyCode === 13 ? onCreateHandler(e) : null)}
								type="text"
								required
								fullWidth
								maxLength={maxLength.flowName}
								autoFocus
								name="name"
								id="flow-name"
								placeholder={t(`form.placeholder.flow.${flow}`)}
								value={name}
								onError={onErrorHandler}
								onChange={handleValue}
								error={typeof errors?.name === 'string'}
								helperText={errors?.name}
							/>
						</SFormGroup>
					</DialogBox>
					<DialogBox className="s-dialog-box">
						<DialogActions className="s-dialog-actions">
							<SButton type="button" onClick={onCancel} color="secondary">
								{t('global.cancel')}
							</SButton>
							<SButton
								type="submit"
								color="success"
								isLoading={inProgress}
								error={!isEmpty(errors)}
								// onClick={onCreateHandler}
								disabled={name.length < 1}
							>
								{t('global.create')}
							</SButton>
						</DialogActions>
					</DialogBox>

				</Form>

			</SBox>
		</DialogContent>
	);
};

const Translation = withTranslation('common')(MModalCreate);

const ModalCreate = (props: IModal) => (
	<Suspense fallback={<div />}>
		<Translation {...props} />
	</Suspense>
);
export default ModalCreate;
