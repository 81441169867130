import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, BoxProps, Grid, Typography } from '@mui/material';
import Icon from './Icon';
import { SLink } from '@components/Buttons/SLink';

const _public_images = '/images/empty';

export const EMPTY_STATES = {
	'': '',
	IDEAS: `${_public_images}/ideas.svg`,
	AGENTE: `${_public_images}/`,
	AGENTEMINICARD: `${_public_images}/empty-agentes-minicard.svg`,
	ATTACHMENTS: `${_public_images}/attachments.svg`,
	ANEXOMINICARD: `${_public_images}/`,
	AVALIACAO: `${_public_images}/empty-avaliacoes-minicard.svg`,
	AVALIACAOMINICARD: `${_public_images}/empty-avaliacoes-minicard.svg`,
	CONNECTIONS: `${_public_images}/connections.svg`,
	CONEXAOMINICARD: `${_public_images}/empty-conexoes-minicard.svg`,
	TEAMS: `${_public_images}/teams.svg`,
	GRUPOMINICARD: `${_public_images}/empty-grupos-minicard.svg`,
	IDEIAMINICARD: `${_public_images}/empty-ideias-minicard.svg`,
	FEATURES: `${_public_images}/features.svg`,
	INVESTIMENTOMINICARD: `${_public_images}/`,
	BADGES: `${_public_images}/badges.svg`,
	FORMS: `${_public_images}/forms.svg`,
	MODERADOR: `${_public_images}/`,
	MODERADORMINICARD: `${_public_images}/empty-moderadores-minicard.svg`,
	NOTIFICATIONS: `${_public_images}/notifications.svg`,
	NOTIFICACAOMINICARD: `${_public_images}/`,
	PATROCINIO: `${_public_images}/`,
	PATROCINIOMINICARD: `${_public_images}/`,
	PLATFORMS: `${_public_images}/platforms.svg`,
	BRAINSTORMS: `${_public_images}/brainstorms.svg`,
	CHALLENGES: `${_public_images}/challenges.svg`,
	PLATAFORMAMINICARD: `${_public_images}/empty-plataformas-minicard.svg`,
	IMAGE: `${_public_images}/image.svg`,
	CANVAS: `${_public_images}/canvas.svg`,
	IMAGE_DISABLED: `${_public_images}/empty-image-disabled.svg`,
	TRENDSMINICARD: `${_public_images}/empty-trends-minicard.svg`,
};

export declare type TEmptyState = 'platforms' | 'ideas' | 'connections' | 'teams' | 'members'
	| 'attachments' | 'users' | 'brainstorms' | 'challenges' | 'canvas' | 'badges' | 'forms' | 'image'
	| 'notifications' | 'trends' | 'evaluations' | 'features' | 'description' | 'summary' | 'answers' | 'questions'

interface EmptyStateProps extends BoxProps {
	type?: TEmptyState
	disabled?: boolean
	className?: string
}

const EmptyState = (props: EmptyStateProps) => {
	const { t } = useTranslation();
	const {
		type, className, disabled, sx, ...rest
	} = props;

	return (
		<Box
			className={`s-empty-state ${className || ''}`}
			sx={{
				backgroundColor: 'var(--empty-state-color)',
				padding: 3,
				img: {
					width: { xs: '126.7px' },
					height: { xs: '60px' },
				},
				...sx,
			}}
			{...rest}
		>
			<Grid
				container
				spacing={2}
				sx={{
					display: 'flex',
					justifyContent: 'center',
					flexDirection: 'column',
					alignItems: 'center',
				}}
			>
				<Grid item>
					<img src={type ? `${_public_images}/${type}.svg` : `${_public_images}/forms.svg`} alt="Empty State" />
				</Grid>
				<Grid item>
					{!disabled
						&& (
							<Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
								<Typography fontWeight={700} color="rgba(0, 0, 0, 0.75)">{t(`emptyStates.${type}.title`)}</Typography>
								<Typography color="rgba(0, 0, 0, 0.4)">
									{t(`emptyStates.${type}.text`)}
								</Typography>
								<SLink
									href={t(`emptyStates.${type}.help`)}
									target="_blank"
									underline="always"
									color="primary"
									onClick={(e) => e.stopPropagation()}
									rel="noopener noreferrer"
									endIcon={<Icon icon="link" className="ml-2" />}
								>
									{t(`emptyStates.${type}.how`)}
								</SLink>
							</Box>
						)}
				</Grid>
			</Grid>
		</Box>
	);
};

export { EmptyState };
