/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-restricted-globals */
// @ts-nocheck
import React, { Component } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import FiltersNavBar from '@components/NavBar/FiltersNavBar';
import { IPagination } from 'src/@types/IShared';
import { IPlatform } from 'src/@types/IPlatform';
import { IIdea } from 'src/@types/IIdea';
import { ITeam } from 'src/@types/ITeam';
import { PlatformCard } from '@components/Cards/PlatformCard';
import { IdeaCard } from '@components/Cards/IdeaCard';
import { TeamCard } from '@components/Cards/TeamCard';
import { UserCard } from '@components/Cards/UserCard';
import { APIGlobal } from '@services/apis';
import { IUserOrganization } from 'src/@types/IUser';
import { handleAxiosErrors } from '@services/auth';
import CardGrid, { TCards } from '@components/Grids/CardGrid';
import routes from '@routes/modal';
import PageLoader from '@components/Loaders/PageLoader';
import Icon from '@components/Shared/Icon';
import { Box } from '@mui/material';
import { GenericGrid } from '@components/Grids/GenericGrid';

// type GConstructor<T = {}> = new (...args: any[]) => T;

interface IState {
	isLoading: boolean
	query: string
	filter: string
	users: IPagination<IUserOrganization>
	teams: IPagination<ITeam>
	platforms: IPagination<IPlatform>
	ideas: IPagination<IIdea>
}

type TProps = WithTranslation

class _Search extends Component<TProps, IState> {
	// private limit = GLOBAL.DOCS_PER_PAGE;

	constructor(props: TProps) {
		super(props);
		this.state = {
			sort: null,
			teams: { docs: [], isLoading: true },
			ideas: { docs: [], isLoading: true },
			users: { docs: [], isLoading: true },
			challenges: { docs: [], isLoading: true },
			brainstorms: { docs: [], isLoading: true },
			searched: {
				ideas: 1,
				teams: 1,
				users: 1,
				challenges: 1,
				brainstorms: 1,
			},
			loaded: false,
			query: new URLSearchParams(location.search)?.get('query') || '',
			filter: new URLSearchParams(location.search)?.get('filter') || '',
		};

		this.updateListFilter = this.updateListFilter.bind(this);
		this.updateListOrder = this.updateListOrder.bind(this);
		this.updateFilter = this.updateFilter.bind(this);
		this.loadSearch = this.loadSearch.bind(this);
	}

	componentDidMount() {
		this.loadSearch();
	}

	componentDidUpdate() { }

	loadSearch() {
		const that = this;
		this.setState((prevState) => ({
			...prevState,
			loaded: false,
		}), async () => {
			try {
				const response = await APIGlobal.getSearch({ query: this.state.query, limit: that.limit });
				Object.keys(response.data).forEach((key) => {
					that.setState((prevState) => ({
						...prevState,
						[key]: {
							...this.state[key],
							...response.data[key],
							isLoading: false,
						},
						searched: {
							...this.state.searched,
							[key]: response.data[key].totalDocs > 0 ? 1 : 0,
						},
						loaded: true,
					}));
				});
			} catch (err) {
				handleAxiosErrors(err);
			}
		});
	}

	updateListFilter({ filter }) {
		if (filter === '') {
			this.enableAllFilters();
		} else {
			const filtro = this.state.searched;

			filtro[filter] = 1 - (filtro[filter] | 0);
			this.setState({
				searched: filtro,
			});
		}
	}

	updateFilter(filter) {
		if (filter === '') {
			this.enableAllFilters();
		} else {
			const filtro = this.state.searched;

			Object.keys(filtro).forEach((key) => filtro[key] = filter !== key ? 0 : 1);

			this.setState({
				searched: filtro,
			});
		}
	}

	updateListOrder(sort) {
		if (sort) { }
	}

	enableAllFilters() {
		this.setState({
			searched: {
				ideas: 1,
				teams: 1,
				users: 1,
				challenges: 1,
				brainstorms: 1,
			},
		});
	}

	disableAllFilters() {
		this.setState({
			searched: {
				brainstorms: 0,
				challenges: 0,
				ideas: 0,
				teams: 0,
				users: 0,
			},
		});
	}

	render() {
		const { t } = this.props;

		const {
			teams, challenges, brainstorms, ideas, users, searched, query, loaded,
		} = this.state;

		if (!loaded) {
			return <PageLoader />;
		}

		const options = [
			{
				value: 'brainstorms',
				label: t('global.brainstorms'),
				className: searched.brainstorms === 0 ? 'inactive' : '',
				icon: <Icon icon="brainstorm" />,
				close: false,
			},
			{
				value: 'challenges',
				label: t('global.challenges'),
				className: searched.challenges === 0 ? 'inactive' : '',
				icon: <Icon icon="challenge" />,
				close: false,
			},
			{
				value: 'ideas',
				label: t('global.ideas'),
				className: searched.ideas === 0 ? 'inactive' : '',
				icon: <Icon icon="idea" />,
				close: false,
			},
			{
				value: 'teams',
				label: t('global.teams'),
				className: searched.teams === 0 ? 'inactive' : '',
				icon: <Icon icon="team" />,
				close: false,
			},
			{
				value: 'users',
				label: `${t('global.user')}s`,
				className: searched.users === 0 ? 'inactive' : '',
				icon: <Icon icon="user" />,
				close: false,
			},
			{
				value: '',
				label: t('global.all'),
				close: false,
			},
		];

		return (
			<GenericGrid id="search-page" className="search__page s-grid-page">
				<div className="cover">
					<div className="results">
						{(teams.docs.length === 0
						&& users.docs.length === 0
						&& ideas.docs.length === 0
						&& challenges.docs.length === 0
						&& brainstorms.docs.length === 0)
							? (
								<span className="pr-0">
									{t('global.weDidNotAnyReference')}
								</span>
							)
							: (
								<>
									<span className="pr-0">{`${t('global.weFound')}`}</span>
									{teams.docs.length !== 0 && (
										<>
											<span>{` ${teams.totalDocs}`}</span>
											<a href="# " onClick={(e) => { this.updateFilter('teams'), e.preventDefault(); }}>
												{` ${t('global.team')}${teams.totalDocs === 1 ? ',' : 's,'}`}
											</a>
										</>
									)}
									{ideas.docs?.length !== 0 && (
										<>
											<span>{` ${ideas.totalDocs}`}</span>
											<a href="# " onClick={(e) => { this.updateFilter('ideas'), e.preventDefault(); }}>
												{` ${t('global.idea')}${ideas.totalDocs === 1 ? ',' : 's,'}`}
											</a>
										</>
									)}
									{brainstorms.docs?.length !== 0 && (
										<>
											<span>{` ${brainstorms.totalDocs}`}</span>
											<a href="# " onClick={(e) => { this.updateFilter('brainstomrs'), e.preventDefault(); }}>
												{`${t('global.brainstorm')}${brainstorms.totalDocs === 1 ? ',' : 's,'}`}
											</a>
										</>
									)}
									{challenges.docs?.length !== 0 && (
										<>
											<span>{` ${challenges.totalDocs}`}</span>
											<a href="# " onClick={(e) => { this.updateFilter('challenges'), e.preventDefault(); }}>
												{`${t('global.challenge')}${challenges.totalDocs === 1 ? ',' : 's,'}`}
											</a>
										</>
									)}
									{users.docs?.length !== 0 && (
										<>
											<span>{` ${users.totalDocs}`}</span>
											<a href="# " onClick={(e) => { this.updateFilter('users'), e.preventDefault(); }}>
												{` ${t('global.user')}${users.totalDocs === 1 ? '' : 's'}`}
											</a>
										</>
									)}
								</>
							)}
						<span>{t('global.with')}</span>
						<strong>{` ${query}`}</strong>
					</div>
				</div>
				<FiltersNavBar
					id="search-nav"
					className="inner"
					params={{ sort: 'a-z' }}
					onUpdate={this.updateListFilter}
					filters={options}
					sx={{
						'.s-navbar': {
							display: 'flex',
							justifyContent: 'flex-end',
						},
					}}
					label={t('page_search.refineSearch')}
				/>
				{
					[
						{
							section: brainstorms,
							type: 'brainstorms',
							component: (item) => (
								<PlatformCard
									platform={item}
									sectionLocator="platforms"
									routes={routes}
								/>
							),
						},
						{
							section: challenges,
							type: 'challenges',
							component: (item) => (
								<PlatformCard
									platform={item}
									sectionLocator="platforms"
									routes={routes}
								/>
							),
						},
						{
							section: ideas,
							type: 'ideas',
							component: (item) => (
								<IdeaCard
									idea={item}
									sectionLocator="ideas"
									routes={routes}
								/>
							),
						},
						{
							section: teams,
							type: 'teams',
							component: (item) => (
								<TeamCard
									team={item}
								/>
							),
						},
						{
							section: users,
							type: 'users',
							component: (item) => (
								<UserCard
									profile={item}
								/>
							),
						},
					].map((item) => (
						<Box className="w-grid-1 inner">
							{item.section.docs.length > 0
								&& (
									<div key={item.type}>
										<CardGrid
											headerLabel={`${item.section.totalDocs || 0} ${t(`global.${item.type}`)}`}
											section={item.section}
											type={item.type as TCards}
											GridItemProps={{ xs: 12, sm: 6, md: 4, xl: 3}}
											hide={searched[item.type] === 0}
											component={item.component}
											itemsList={false}
										/>
									</div>
								)}
						</Box>
					))
				}
			</GenericGrid>
		);
	}
}

const Translation = withTranslation('common')(_Search);

const Search = (props: TProps) => (
	<React.Suspense fallback={<PageLoader />}>
		<Translation {...props} />
	</React.Suspense>
);

export default Search;
