// @ts-nocheck
import React, { useEffect, Suspense, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { WithTranslation, withTranslation } from 'react-i18next';
import ModalHeader from '@modules/Modal/ModalHeader';
import type { IModal } from '@modules/Modal/types';
import { IRootState } from 'src/store';
import { APIFeatures } from '@services/apis';
import { DialogBox } from '@modules/Modal/Components';
import { handleAxiosErrors } from '@services/auth';
import { Creators } from '@actions';
import { IFeature } from 'src/@types/IIdea';
import { BFeatures, TFeatureActions } from '@components/Background/BFeatures';
import { DynamicCircle } from '@components/DynamicCircle';
import { DialogContent, DialogTitle, Typography } from '@mui/material';
import { useTour } from '@reactour/tour';
import { FLOWS } from '@constants/GLOBAL';
import { BuildTour } from '@components/STour';
import { W_GRID } from '@components/Grids/GenericGrid';
import { HelpButton } from '../../Buttons/HelpButton';

declare type TProps = IModal & WithTranslation

const MModalFeatures = (props: TProps) => {
	const { t, routes } = props;
	const dispatch = useDispatch();
	const tour = useTour();
	const { ideas, shared } = useSelector((state: IRootState) => state) || {};
	const [features, setFeatures] = useState({ docs: [], isLoading: true });
	const [templates, setTemplates] = useState({ docs: [], isLoading: true });

	const newFeature: IFeature = {
		nome: '',
		prioridade: 'media',
		budget: 0,
		resumo: '',
		cor: 'rgb(76, 122, 144)',
	};

	const TOUR_STEPS = [
		{
			id: templates.docs.length > 1 ? 'template-0-vertical' : 'feature-create',
			selector: templates.docs.length > 1 ? '[data-tut="template-0-vertical"]' : '[data-tut="feature-create"]',
			title: t(`tour.forms.${templates.docs.length > 1 ? 'add' : 'create'}.title`),
			description: t(`tour.forms.${templates.docs.length > 1 ? 'add' : 'create'}.description`),
			action: t(`tour.forms.${templates.docs.length > 1 ? 'add' : 'create'}.action`),
		},
		{
			id: 'modal-back',
			selector: '[data-tut="modal-back"]',
			title: t('tour.modal.back.title'),
			description: t('tour.modal.back.description'),
			action: t('tour.modal.back.action'),
		},
	];

	const loadTemplates = async (ids: string[], startTour?: boolean) => {
		try {
			setTemplates({ ...templates, isLoading: true });
			const response = await APIFeatures.getTemplates(ids);
			setTemplates({ docs: response.data, isLoading: false });
			if (startTour) {
				BuildTour.setTour(tour, TOUR_STEPS, { currentStep: 0 });
			}
		} catch (err) {
			handleAxiosErrors(err);
		}
	};

	const loadFeatures = (startTour?: boolean) => {
		setFeatures({ ...features, isLoading: true });
		dispatch(Creators.FEATURES.getFeatures({
			ideaId: ideas._id,
			onSuccess: (response) => {
				setFeatures({ ...response, isLoading: false });
				loadTemplates(response.docs.map((item) => item.template._id, startTour));
			},
		}));
	};

	const paramsPaths = {
		feature: routes.MODAL_IDEA_FEATURE.path,
	};

	interface IViewHandler { feature: IFeature, action: TFeatureActions }

	const onTourHandler = () => {
		if (shared.tour === FLOWS.IDEA) {
			tour.setCurrentStep(10);
		}
		tour.setIsOpen(false);
	};

	const onViewHandler = ({ feature, action }: IViewHandler) => {
		dispatch(Creators.FEATURES.setFeature(feature));
		dispatch(
			Creators.MODAL.showModal(
				paramsPaths.feature,
				{ action },
			),
		);
	};

	const onInsertHandler = (featureId: string, callback) => {
		if (ideas?.mode === 'edit') {
			dispatch(Creators.FEATURES.insertFeature({
				ideaId: ideas?._id,
				templateId: featureId,
				onSuccess: () => loadFeatures(),
				callback,
			}));
		}
	};

	const onBackHandler = (params) => {
		onTourHandler();
		dispatch(Creators.MODAL.backModal(params));
	};

	useEffect(() => {
		loadFeatures(shared.tour === FLOWS.IDEA);
	}, []);

	return (
		<DialogContent id="modal-features">
			<ModalHeader
				fullScreen
				icon="idea"
				hideClose
				BreadcrumbProps={{ className: W_GRID.W_GRID_2 }}
				pathParams={{
					idea: ideas.nome || '...',
					template: ideas.templateNome || '...',
				}}
				onBack={onBackHandler}
				BackModalProps={{ anchor: '#supports-idea-create' }}
			/>
			<DialogBox className="s-dialog-title inner w-grid-2">
				<DialogTitle variant="h1">
					{t('form.idea.features')}
				</DialogTitle>
				<HelpButton
					size="small"
					href={t('urls.features')}
					onClick={() => BuildTour.setTour(tour, TOUR_STEPS, { currentStep: 0 })}
				/>
			</DialogBox>
			<DialogBox className="s-dialog-box inner w-grid-2">
				<BFeatures
					features={features}
					mode={ideas.mode}
					routes={paramsPaths}
					size="x-large"
					hasEmptyState
				/>

				<DialogBox className="s-dialog-content">
					<Typography variant="h6" fontWeight={700}>{t('form.features.add')}</Typography>
				</DialogBox>

				{ideas?.mode === 'edit' && (
					<div className="list">
						<div
							data-tut="feature-create"
							className="list-item"
						>
							<DynamicCircle
								icon="feature"
								size="x-large"
								className="default-colors"
								action={ideas?.mode === 'edit' ? t('global.create') : undefined}
								onClick={() => onViewHandler({ feature: newFeature, action: 'create' })}
								clickable={ideas?.mode === 'edit'}
							/>
							<div
								className="container-content"
							>
								<div className="title">
									{t('form.features.blank.new')}
								</div>
								<div className="content-text">
									{t('form.features.blank.introduction')}
								</div>
							</div>
						</div>
					</div>
				)}

				<BFeatures
					id="features-list"
					features={templates}
					routes={paramsPaths}
					mode={ideas.mode === 'view' ? 'view' : 'add'}
					orientation="vertical"
					size="x-large"
					onInsert={onInsertHandler}
				/>
			</DialogBox>
		</DialogContent>
	);
};

const Translation = withTranslation('common')(MModalFeatures);

const ModalFeatures = (props: IModal) => (
	<Suspense fallback={<div />}>
		<Translation {...props} />
	</Suspense>
);

export default ModalFeatures;
