import React from 'react';
import { AiOutlinePlus } from 'react-icons/ai';
import { FaArrowLeft, FaEdit, FaEye, FaMapSigns, FaTrash } from 'react-icons/fa';

export const IconsMap = {
	eye: <FaEye />,
	pen: 'fas fa-pen',
	home: 'fa fa-home',
	sad: 'fa icon-sad',
	like: 'fa fa-heart',
	edit: <FaEdit />,
	warning: 'fa-solid fa-circle-exclamation',
	form: 'fa fa-tasks',
	pc: 'fa fa-desktop',
	cube: 'fas fa-cube',
	copy: 'fas fa-copy',
	team: 'fa fa-users',
	trash: 'fa fa-trash',
	sync: 'fa fa-rotate',
	tasks: 'fa fa-tasks',
	secret: 'fa fa-lock',
	time: 'fa icon-flag',
	report: 'fa fa-flag',
	undo: 'fa icon-undo',
	lock: 'fa icon-lock',
	reply: 'fa fa-reply',
	clock: 'fa fa-clock',
	badge: 'fa fa-medal',
	teams: 'fa fa-users',
	grupo: 'fa fa-users',
	drafts: 'fas fa-edit',
	check: 'fas fa-check',
	tools: 'fas fa-tools',
	print: 'fas fa-print',
	rocket: 'fa fa-rocket',
	medalha: 'fa fa-medal',
	remove: <FaTrash />,
	image: 'fa icon-image',
	chart: 'fa icon-chart',
	stats: 'fa icon-stats',
	smile: 'fa icon-smile',
	share: 'fa icon-share',
	editar: 'fa icon-edit',
	idea: 'fa fa-lightbulb',
	billing: 'fa fa-wallet',
	fire: 'fa-fire-alt fas',
	fechar: 'fa icon-cross',
	funcao: 'fa fa-sitemap',
	pesquisa: 'fa icon-lab',
	plus: <AiOutlinePlus />,
	filter: 'fa-filter fas',
	service: 'fas fa-tools',
	signs: <FaMapSigns />,
	ideia: 'fa fa-lightbulb',
	ideas: 'fa fa-lightbulb',
	template: 'fa fa-shapes',
	approved: 'fas fa-check',
	reproved: 'fas fa-times',
	favorites: 'fa fa-heart',
	history: 'fa fa-history',
	canvas: 'fas fa-columns',
	notice: 'fa icon-notice',
	stats2: 'fa icon-stats2',
	cancel: 'fa icon-cancel',
	notificacao: 'fa fa-bell',
	atualizar: 'fa icon-redo',
	member: 'fas fa-user-plus',
	challenge: 'fas fa-trophy',
	admin: 'fa fa-user-shield',
	pushpin: 'fa icon-pushpin',
	neutral: 'fa icon-neutral',
	conexoes: 'fa icon-shrink',
	timer: 'fa icon-stopwatch',
	moderador: 'fa icon-gavel',
	link: 'fa fa-external-link',
	leave: 'fa fa-sign-out-alt',
	favorite: 'fa fa-thumbtack',
	challenges: 'fas fa-trophy',
	flag: 'fa fa-solid fa-flag',
	user: 'fa fa-solid fa-user',
	sponsor: 'fa icon-suitcase',
	portfolio: 'fa icon-target',
	bullhorn: 'fas fa-bullhorn',
	'user-settings': 'fa fa-cog',
	logout: 'fa fa-sign-out-alt',
	list: 'fa fa-clipboard-list',
	private: 'fa fa-user-shield',
	infinite: 'fa icon-infinite',
	suitcase: 'fa icon-suitcase',
	question: 'fa icon-question',
	ellipsis: 'fa fa-ellipsis-h',
	organizations: 'fas fa-city',
	localizacao: 'fa icon-earth',
	sysop: 'fa icon-streetlight',
	marketing: 'fas fa-bullhorn',
	security: 'fas fa-shield-alt',
	help: 'fa fa-question-circle',
	'landing-page': 'fas fa-file',
	'go-back': <FaArrowLeft />,
	feature: 'fa fa-solid fa-gem',
	google: 'fa-brands fa-google',
	platform: 'fa fa-layer-group',
	puzzle: 'fas fa-puzzle-piece',
	process: 'fas fa-puzzle-piece',
	checkmark: 'fa icon-checkmark',
	upload: 'fa fa-cloud-arrow-up',
	organization: 'fa fa-building',
	platforms: 'fa fa-layer-group',
	brainstorm: 'fa-cloud-bolt fas',
	public: 'fas fa-globe-americas',
	avaliacoes: 'fa icon-clipboard',
	plataforma: 'fa fa-layer-group',
	info: 'fa fa-circle-exclamation',
	search: 'fa fa-magnifying-glass',
	'open-innovation': 'fas fa-cube',
	redo: 'fa fa-arrow-rotate-right',
	brainstorms: 'fa-cloud-bolt fas',
	questionSignal: 'fa fa-question',
	'business-model': 'fa fa-rocket',
	'ideia-aprovada': 'fa icon-fire',
	invitations: 'fas fa-paper-plane',
	product: 'fas fa-shopping-basket',
	magic: 'fa fa-magic-wand-sparkles',
	'active-users': 'fas fa-user-check',
	sort: 'fa fa-arrow-down-short-wide',
	backup: 'fa-cloud-download-alt fas',
	linkedin: 'fa-brands fa-linkedin-in',
	'apoio-financeiro': 'fa icon-diamond',
	download: 'fa-cloud-download-alt fas',
	'google-drive': 'fa icon-google-drive',
	'aprovar-agente': 'fa icon-user-check',
	twitter: 'fa-brands fa-twitter-square',
	'upload-computer': 'fas fa-file-upload',
	'organization-profile': 'fa fa-building',
	facebook: 'fa-brands fa-facebook-square',
	shoppingbasket: 'fas fa-shopping-basket',
	'apoio-institucional': 'fa fa-feather-alt',
	'avaliacao-impacto': 'fa fa-solid fa-bomb',
	instagram: 'fa-brands fa-instagram-square',
	'price-equal': 'fa icon-arrow-circle-right',
	'google-driver': 'fa-brands fa-google-drive',
	'avaliacao-disrupcao': 'fa fa-solid fa-atom',
	'avaliacao-retorno': 'fa fa-solid fa-money-bill',
	'avaliacao-tempo': 'fa fa-solid fa-hourglass-half',
	'avaliacao-dificuldade': 'fa fa-solid fa-mountain',
};

export declare type TIcons = typeof IconsMap
