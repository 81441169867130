// @ts-nocheck
import React, { useEffect, Suspense, MouseEvent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { goToLink } from '@utils/ReactUtils';
import { Creators } from '@actions';
import Icon from '@components/Shared/Icon';
import { TFn, TObject } from 'src/@types/IShared';
import {
	Box, BoxProps, Breadcrumbs, Fab, IconButton, Link,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { MdKeyboardArrowUp } from 'react-icons/md';
import { DialogBox } from './Components';

export interface ModalHeaderProps {
	hideAnchored?: string
	icon?: string
	onClose?: (event: MouseEvent<HTMLButtonElement>, callback: TFn) => void
	onBack?: (BackModalProps: TObject) => void
	hidePath?: boolean
	hideNav?: boolean
	fullScreen?: boolean
	BackModalProps?: TObject
	pathParams?: TObject
	BreadcrumbProps?: BoxProps
}

declare type TProps = ModalHeaderProps & WithTranslation

const MModalHeader = (props: TProps) => {
	const dispatch = useDispatch();
	const currentModel = useSelector((state) => state.modal?.ModalProps?.currentModel);
	const pagingPaths = useSelector((state) => state.modal?.ModalProps?.pagingPaths);
	const anchor = useSelector((state) => state.modal?.ModalProps?.anchor);
	const ModalProps = useSelector((state) => state.modal?.ModalProps);
	const { path, secondPath } = useSelector((state) => state.modal);

	const {
		t,
		fullScreen,
		BackModalProps,
		hideBack,
		onBack,
		onClose,
		hideAnchored,
		hideClose,
		hidePath,
		pathParams = {},
		hideNav, icon,
		BreadcrumbProps,
	} = props;

	const navigate = useNavigate();
	const location = useLocation();

	// Replace path params
	let breadcrumbPath = Object.keys(pathParams).reduce((pathnameReplaced, param) => pathnameReplaced.replace(
		new RegExp(`:${param}`, 'g'),
		pathParams[param],
	), location.pathname);

	// Translate path translations
	breadcrumbPath = (location.pathname.match(/(?<=\<).+?(?=\>)/g) || []).reduce(
		(pathnameTranslated, translationString) => pathnameTranslated.replace(
			new RegExp(`<${translationString}>`, 'g'),
			t(translationString.trim()),
		),
		breadcrumbPath,
	);

	const breadcrumb = breadcrumbPath.split('/');

	const currentPage = (pagingPaths && currentModel)
		? pagingPaths.findIndex((pagingPath) => (pagingPath.pageModel._id || pagingPath.pageModel.id) === (currentModel?._id || currentModel?.id)) : 0;

	const nextPage = () => {
		const page = currentPage + 1 <= pagingPaths?.length - 1 ? currentPage + 1 : currentPage;
		return page;
	};

	const prevPage = () => {
		const page = currentPage - 1 >= 0 ? currentPage - 1 : currentPage;
		return page;
	};

	useEffect(() => {
		if (location.state) {
			const state = { ...location.state };
			delete state.transaction;
			navigate({ ...location, state }, { replace: true });
		}
	}, []);

	function topFunction() {
		const scrollSection = document.querySelector('.MuiDialogContent-root');
		if (scrollSection) {
			scrollSection.scrollTop = 0;
		}
	}

	const onBackHandler = () => {
		if (typeof onBack === 'function') {
			onBack(BackModalProps);
			return;
		}
		dispatch(Creators.MODAL.backModal(BackModalProps));
	};

	useEffect(() => {
		const btnScroll = document.getElementById('btn-scroll');
		const scrollSection = document.querySelector('.MuiDialogContent-root');

		if (scrollSection && btnScroll) {
			scrollSection.addEventListener('scroll', () => {
				if (scrollSection.scrollTop > 20) {
					btnScroll.classList.add('transition');
					btnScroll.classList.remove('hidden');
				} else {
					btnScroll.classList.remove('transition');
					btnScroll.classList.add('hidden');
				}
			});
		}
	});

	const onNextHandler = () => {
		if (nextPage() === currentPage) return;
		dispatch(
			Creators.MODAL.showModal(
				pagingPaths[nextPage()].pagePath,
				{
					...ModalProps,
					currentModel: pagingPaths[nextPage()].pageModel,
					pagingPaths,
				},
			),
		);
	};

	const onPrevHandler = () => {
		if (prevPage() === currentPage) return;
		dispatch(
			Creators.MODAL.showModal(
				pagingPaths[prevPage()].pagePath,
				{
					...ModalProps,
					currentModel: pagingPaths[prevPage()].pageModel,
					pagingPaths,
				},
			),
		);
	};

	const onCloseHandler = (event: MouseEvent<HTMLButtonElement>) => {
		if (typeof onClose === 'function') {
			onClose(
				event,
				() => {
					dispatch(Creators.MODAL.handleModal({ flow: undefined }));
					dispatch(Creators.MODAL.hideModal());
				},
			);
		}

		if (secondPath) {
			dispatch(Creators.MODAL.hideSecondModal(hideAnchored)); // close submodal anchored
		} else {
			dispatch(Creators.MODAL.handleModal({ flow: undefined }));
			dispatch(Creators.MODAL.hideModal());
		}
	};

	useEffect(() => {
		if (anchor) {
			setTimeout(() => {
				goToLink(anchor);
			}, 100);

			dispatch(Creators.MODAL.handleParams('anchor', undefined));
		}
	}, []);

	return (
		<>
			<Box className="modal__header">
				{
					/* BT BACK */
					fullScreen
					&& !hideBack
					&& location.pathname.replace(/^\//g, '').split('/').length > 1 && (
						<IconButton
							data-tut="modal-back"
							aria-label="back"
							size="medium"
							onClick={onBackHandler}
						>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="61.354"
								height="40.707"
							>
								<g stroke="#000">
									<path d="M1.354 20.354h60M.354 20.354l20-20M.354 20.354l20 20" />
								</g>
							</svg>
						</IconButton>
					)
				}

				{
					/* BT CLOSE */
					!hideBack && Array.isArray(pagingPaths) && !hideNav ? (
						''
					) : (!hideClose || Array.isArray(pagingPaths)) && (
						<IconButton
							aria-label="close"
							className={` ${!(Array.isArray(pagingPaths) && pagingPaths.length && !hideNav) && 'ml-auto'}`}
							size="medium"
							onClick={onCloseHandler}
						>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width={!fullScreen ? '18.707' : '30.707'}
								height={!fullScreen ? '18.707' : '30.707'}
								preserveAspectRatio="none"
								viewBox="0 0 30 30"
							>
								<g stroke="#000">
									<path d="M.354.354l30 30M.354 30.354l30-30" />
								</g>
							</svg>
						</IconButton>
					)
				}
				{

					/* BTS PAGINATION */
					Array.isArray(pagingPaths) && pagingPaths.length > 0 && !hideNav && (
						<div className="modal__header_nav ml-auto">
							<IconButton
								className={`mr-5 ${prevPage() === currentPage && 'disabled'}`}
								size="medium"
								disabled={prevPage() === currentPage}
								onClick={onPrevHandler}
							>
								<svg xmlns="http://www.w3.org/2000/svg" width="20.707" height="40.707">
									<path d="M.354 20.354l20-20M.354 20.354l20 20" stroke="#000" />
								</svg>
							</IconButton>
							<IconButton
								className={`ml- ${nextPage() === currentPage && 'disabled'}`}
								onClick={onNextHandler}
								disabled={nextPage() === currentPage}
								size="medium"
								sx={{ transform: 'scaleX(-1)' }}
							>
								<svg xmlns="http://www.w3.org/2000/svg" width="20.707" height="40.707">
									<path d="M.354 20.354l20-20M.354 20.354l20 20" stroke="#000" />
								</svg>
							</IconButton>
						</div>
					)
				}
			</Box>

			{(fullScreen && !hidePath) && (
				<DialogBox
					{...BreadcrumbProps}
					className={`s-dialog-breadcrumbs ${BreadcrumbProps?.className}`}
					sx={{ display: 'flex', ...BreadcrumbProps.sx }}
				>
					{icon && <Box sx={{ marginRight: '8px', color: 'var(--charcoal-color)', fontSize: '14px' }}><Icon icon={icon} /></Box>}
					<Breadcrumbs
						sx={{
							justifyContent: 'flex-start',
							display: 'flex',
							alignItems: 'center',
							color: 'var(--charcoal-color)',
							'a:hover': {
								color: 'var(--charcoal-color)',
							},
							...BreadcrumbProps?.sx,
						}}
					>

						{breadcrumb.map((item, index) => {
							const lastPath = breadcrumb.length - 1 === index && breadcrumb.length > 2;
							const key = `path-${index}`;
							if (item !== '') {
								return (
									<Link
										key={key}
										href="# "
										onClick={(e) => e.preventDefault()}
										style={{
											fontWeight: lastPath ? '600' : '500',
											cursor: 'auto',
										}}
									>
										{item}
									</Link>
								);
							}

							return '';
						})}
					</Breadcrumbs>
				</DialogBox>
			)}
			{ fullScreen && (
				<Fab
					type="button"
					onClick={topFunction}
					id="btn-scroll"
					title={t('global.goToTop')}
				>
					<MdKeyboardArrowUp />
				</Fab>
			)}
		</>
	);
};

const Translation = withTranslation('common')(MModalHeader);

const ModalHeader = (props: ModalHeaderProps) => (
	<Suspense fallback={<div />}>
		<Translation {...props} />
	</Suspense>
);

export default ModalHeader;
