// @ts-nocheck
import { Creators } from '@actions';
import { IRootState } from 'src/store';
import { APITeams } from '@services/apis';
import { isEmpty } from '@utils/ReactUtils';
import { SButton } from '@components/Buttons';
import type { IModal } from '@modules/Modal/types';
import { handleAxiosErrors } from '@services/auth';
import { useState, useEffect, Suspense } from 'react';
import InviteForm from '@components/Forms/InviteForm';
import { useDispatch, useSelector } from 'react-redux';
import {
	Box, DialogActions, DialogContent, DialogTitle,
} from '@mui/material';
import { WithTranslation, withTranslation } from 'react-i18next';
import { DialogBox } from '@modules/Modal/Components';
import {
	Form, SFormGroup, SInputLabel,
} from '@components/DynamicForm/ComponentsCore';
import { getUserOptions, Tags, UserTag } from '@components/DynamicForm/Components/SSelect/Components';
import { IAutocompleteOption, SAutocomplete } from '@components/DynamicForm/Components/SSelect/SAutocomplete';
import Icon from '@components/Shared/Icon';

declare type TProps = IModal & WithTranslation

const MModalInviteMember = (props: TProps) => {
	const { t } = props;
	const dispatch = useDispatch();
	const [teams, setTeams] = useState([]);
	const [emails, setEmails] = useState<IAutocompleteOption[]>([]);
	const [inProgress, setInProgress] = useState(false);
	const [teamsData, setTeamsData] = useState([]);
	const subscription = useSelector((state: IRootState) => state.billing?.subscription);
	const licenses = useSelector((state: IRootState) => state.billing?.licenses);

	const [errors, setErrors] = useState({});

	const params = useSelector((state: IRootState) => state.modal?.ModalProps);

	const disabled = teams.length > licenses || licenses === 0;

	const onInviteSubmit = (event) => {
		event?.preventDefault();

		const newMembers = {
			invites: emails.map((item) => item.value),
			times: teams.map((item) => item._id),
		};

		if (disabled) {
			return;
		}

		if (newMembers.invites.length < 1) {
			setErrors({ invites: t('messages.requiredField') });
			return;
		}

		setInProgress(true);
		dispatch(Creators.USER_ORGANIZATION.inviteOrganization({
			invitations: newMembers,
			onSuccess: () => {
				if (typeof params?.callback === 'function') {
					params.callback(); // loadInvites on page
				}
				dispatch(Creators.MODAL.hideModal());
			},
			// onErrors: () => setErrors({ invites: 'Convites não enviados' }),
			callback: () => setInProgress(false),
		}));
	};

	const getTeams = async () => {
		try {
			const response = await APITeams.getTeams();
			setTeamsData(response.data.docs);
		} catch (err) {
			handleAxiosErrors(err);
		}
	};

	const onErrorHandler = (field) => {
		if (!isEmpty(errors)) delete errors[field];
	};

	const onInviteHandler = (arr) => {
		console.log('🚀 ~ file: ModalInviteMember.tsx:84 ~ onInviteHandler ~ arr:', arr);
		onErrorHandler('invites');
		if (arr.length > licenses) {
			setEmails(arr.slice(0, licenses));
			setErrors({ licenses: t('member.maxLicenses') });
			return;
		}

		setEmails(arr);
	};

	useEffect(() => {
		setTeams([]);
		setEmails([]);
		getTeams();

		dispatch(Creators.BILLING.getOverviewPlan());
	}, []);

	return (
		<DialogContent className="modal__invite" id="InviteDialog">
			<DialogBox className="s-dialog-title">
				<DialogTitle variant="h6">
					{t('global.new_member')}
				</DialogTitle>
			</DialogBox>

			<DialogBox className="s-dialog-content">
				<Form
					id="invite-form"
					className="s-form"
				>
					<InviteForm
						invites={emails}
						handleInvites={onInviteHandler}
						name="invites"
						error={typeof errors?.invites === 'string'}
						helperText={errors?.invites || errors?.licenses} // licenses warning
						inBatch
					/>
					<SFormGroup>
						<SInputLabel htmlFor="teams-autocomplete">
							{t('member.add_to_teams')}
						</SInputLabel>
						<SAutocomplete
							id="teams-autocomplete"
							disabled={disabled}
							placeholder={t('form.select')}
							multiple
							// isClearable={ideas.coautores?.some((v) => !v.isFixed)}
							value={teams || []}
							isLoading={inProgress === 'coautores'}
							options={getUserOptions(teamsData)}
							onChange={(event, value) => setTeams(value)}
							disableCloseOnSelect
							renderTags={(opts, getTagProps) => (
								<Tags
									value={opts}
									component={(option, index) => (
										<UserTag renderTags option={option} {...getTagProps({ index })} />
									)}
								/>
							)}
							renderOption={(_props, option) => <li {..._props}><UserTag option={option} /></li>}
						/>
					</SFormGroup>
					<Box className="box__info--small">
						<Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
							<Icon icon="warning" />
							{t('member.using_licenses')}
						</Box>
						{subscription ? (
							<p
								// eslint-disable-next-line react/no-danger
								dangerouslySetInnerHTML={{
									__html: t('member.inviteInfo', {
										licenses,
										plan: subscription.plan?.name,
									}),
								}}
							/>
						) : (
							<p>
								{t('member.noSubscription')}
							</p>
						)}
					</Box>
				</Form>
			</DialogBox>

			<DialogBox className="s-dialog-content">
				<DialogActions>
					<SButton type="button" color="secondary" onClick={() => dispatch(Creators.MODAL.hideModal())}>
						{t('global.cancel')}
					</SButton>
					<SButton
						type="button"
						color="success"
						disabled={disabled}
						onClick={onInviteSubmit}
						isLoading={inProgress}
					>
						{t('global.invite')}
					</SButton>
				</DialogActions>
			</DialogBox>

		</DialogContent>
	);
};

const Translation = withTranslation('common')(MModalInviteMember);

const ModalInviteMember = (props: IModal) => (
	<Suspense fallback={<div />}>
		<Translation {...props} />
	</Suspense>
);

export default ModalInviteMember;
