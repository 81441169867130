// @ts-nocheck

import { axiosInstance } from '@services/AxiosInstance';
import { IBadge } from 'src/@types/IBadge';

const APIBadges = {
	getTemplates(ids: Array<string>) {
		const queryParams = typeof ids !== 'undefined' && ids.length > 0 ? `?ids=${ids}` : '';
		return axiosInstance.get(`/templates/badge${queryParams}`);
	},
	postCreateBadge(badge: IBadge) {
		return axiosInstance.post('/badges', badge);
	},
	postBadgePlatform(platformId: string, templateId: string) {
		return axiosInstance.post('/badges/associate', { platformId, templateId });
	},
	getBadges() {
		return axiosInstance.get('/badges');
	},
	putRestoreBadge(badgeId: string) {
		return axiosInstance.put(`/badges/${badgeId}/restore_template`, {});
	},
	putBadge(badgeId: string, field) {
		return axiosInstance.put(`/badges/${badgeId}`, field);
	},
	deleteBadge(badgeId: string) {
		return axiosInstance.delete(`/badges/${badgeId}`);
	},
	getBadgesByIdea(ideaId: string) {
		return axiosInstance.get(`/badges/${ideaId}/idea`);
	},
};

export default APIBadges;
