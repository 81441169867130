// @ts-nocheck
import React, {
	useState, Suspense, useEffect,
} from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import SpinnerLDSRipple from '@components/Shared/SpinnerLDSRipple';
import { UserCell } from '@components/DataTable/Cells';
import TableSearch from '@components/DataTable/TableSearch';
import { IUser } from 'src/@types/IUser';
import { TableColumn } from 'react-data-table-component';
import { APIOrganizations } from '@services/apis';
import { handleAxiosErrors } from '@services/auth';
import { SBox } from '@components/Background/SBox';
import { Grid } from '@mui/material';

interface IReported {
	usuario: { user: IUser }
	ipAddress: string
	href: string
	createdAt: string
	objeto_id: string
	objeto: string
	motivo: string
	outro_motivo: string
}

interface IReportedState {
	docs: IReported[]
	isLoading: boolean
}

type TProps = WithTranslation
const _ReportedContent = (props: TProps) => {
	const { t } = props;

	const [reported, setReported] = useState<IReportedState>({ docs: [], isLoading: true });
	const [flagged, setFragged] = useState<string>('');

	const loadReported = async () => {
		try {
			const response = await APIOrganizations.getReported({ page: 1 });
			setReported({
				...response.data, isLoading: false,
			});
		} catch (err) {
			handleAxiosErrors(err);
		}
	};

	useEffect(() => {
		loadReported();
	}, []);

	const flaggedContentList = reported.docs.filter(
		(reported) => (reported.usuario
			&& reported.usuario.user
			&& reported.usuario.user._nome?.toLowerCase().includes(flagged.toLowerCase()))
			|| (reported.objeto
				&& reported.objeto
					.toLowerCase()
					.includes(flagged.toLowerCase()))
			|| (reported.motivo
				&& reported.motivo
					.toLowerCase()
					.includes(flagged.toLowerCase())),
	);

	const CellLink = ({ row }) => (
		<div>
			<a href={row} target="_blank" rel="noopener noreferrer">
				{row}
			</a>
		</div>
	);

	const CellDate = ({ row }) => (
		<div>
			<div style={{ display: 'flex' }}>
				{row ? new Date(row).toLocaleString() : 'Nunca'}
			</div>
		</div>
	);

	const columns: TableColumn<IReported>[] = [
		{
			name: t('fields.denuncia.usuario'),
			selector: (row) => row.usuario.user.nome,
			sortable: true,
			center: false,
			maxWidth: '200px',
			minWidth: '200px',
			cell: (row) => <UserCell user={row.usuario.user} />,
		},
		{
			name: t('fields.denuncia.reportedAt'),
			selector: (row) => row.createdAt,
			sortable: true,
			center: true,
			minWidth: '150px',
			cell: (row) => <CellDate row={row.createdAt} />,
		},
		{
			name: t('fields.denuncia.link'),
			selector: (row) => `${row.objeto}/${row.objeto_id}`,
			sortable: true,
			center: true,
			cell: (row) => (
				<CellLink row={`${/^comen/gmi.test(row.objeto)
					? `${row.href}/#${row.objeto_id}`
					: `./${row.objeto}s/${row.objeto_id}`}`}
				/>
			),
		},
		{
			name: t('fields.denuncia.objeto'),
			selector: (row) => row.objeto,
			sortable: true,
			center: true,
			cell: (row) => row.objeto.charAt(0).toUpperCase() + row.objeto.slice(1),
		},
		{
			name: t('fields.denuncia.motivo'),
			selector: (row) => row.motivo,
			sortable: true,
			center: true,
			wrap: true,
			cell: (row) => t(`report.${row.motivo}`),
		},
		{
			name: t('fields.denuncia.outro_motivo'),
			selector: (row) => row.outro_motivo,
			sortable: true,
			center: true,
			wrap: true,
			cell: (row) => row.outro_motivo,
		},
	];

	return (
		<SBox className="settings__report">
			<Grid container item xs={12} className="section__header" spacing={3} flexDirection="column">
				<TableSearch
					data={reported.docs}
					columns={columns}
					progressPending={reported.isLoading}
				/>
			</Grid>
		</SBox>
	);
};

const Translation = withTranslation('common')(_ReportedContent);

const ReportedContent = () => (
	<Suspense fallback={<SpinnerLDSRipple />}>
		<Translation />
	</Suspense>
);

export default ReportedContent;
