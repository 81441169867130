export default {
	IDEAS: '/ideas',
	TEAMS: '/teams',
	DRAFTS: '/drafts',
	SEARCH: '/search',
	PROFILE: '/profile',
	SETTINGS: '/settings',
	FAVORITES: '/favorites',
	CHALLENGES: '/challenges',
	BRAINSTORMS: '/brainstorms',

	HOME: '/',
	RESET: '/reset',
	LOGIN: '/logIn',
	SIGN_UP: '/signUp',
	RECOVER: '/recover',
	TOKEN: '/signUp/token',
	ACCOUNT_SETUP: '/accountSetup',
	ORGANIZATION_SETUP: '/organizationSetup',

	INVITE: '/invite',
	SLACK_FEEDBACK: '/slack',
	MIRO_FEEDBACK: '/miro',
	LINKEDIN_FEEDBACK: '/linkedIn',
	DELETE_ACCOUNT: '/deleteAccount',
	CREATE_ORGANIZATION: '/createOrganization',
	DELETE_ORGANIZATION: '/deleteOrganization',
};
