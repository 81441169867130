import React, { Suspense, useState, MouseEvent } from 'react';
import $ from 'jquery';
import { Creators } from '@actions';
import { UserAvatar } from '@components/Avatar/UserAvatar';
import Icon from '@components/Shared/Icon';
import Preview from '@components/Shared/Preview';
import routes from '@routes/modal';
import { withTranslation, WithTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { IComment } from 'src/@types/IShared';
import { IRootState, persistor } from 'src/store';
import { SocketIO } from '@services/socket';
import { AiFillHeart, AiOutlineHeart } from 'react-icons/ai';
import { SButton } from '@components/Buttons';
import { SMenu } from '@components/SMenu';
import { IconButton } from '@mui/material';
import { CommentArea } from './CommentArea';

interface ICommentProps {
	objectId: string
	target: { flag: string, reply: string, like: string }
	isReply?: boolean
	comment: IComment
}

declare type TProps = ICommentProps & WithTranslation

const CComment = ({
	comment, isReply, target, t, objectId,
}: TProps) => {
	const dispatch = useDispatch();
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const { userOrganizationProfile } = useSelector((state: IRootState) => state);
	const [edit, setEdit] = useState(false);

	const handleDelete = (id: string) => {
		SocketIO.emit('comment_delete', {
			_id: id,
		});
	};

	const handleEdit = () => {
		setEdit(true);
		const replyBox = document.querySelectorAll('.reply-box');
		if (replyBox) {
			replyBox.forEach((item) => item.classList.add('d-none'));
		}
	};

	const handleLike = (e: MouseEvent<HTMLButtonElement>) => {
		e.preventDefault();
		const _id = $(e.currentTarget).data('comment-id');

		SocketIO.emit('comment_like', { _id });
	};

	const focusReply = (e: React.MouseEvent) => {
		const id = $(e.currentTarget).data('reply-to');
		const $reply = $(`#reply-to-${id}`);

		$reply.closest('article').closest('div').find('.reply-box').removeClass('d-none');
		$reply.focus();
	};

	return edit
		? (
			<CommentArea
				comment={comment}
				objectId={objectId}
				isReply={isReply}
				selectorPreview={`previewComponentEdit-${comment._id}`}
				edit={edit}
				onSuccess={() => setEdit(false)}
			/>
		)
		: (
			<>
				<div className="comment__author">
					<UserAvatar
						profile={comment.autor}
						size={`${isReply ? 'middle' : 'large'}`}
						hasPopover={!comment.isAuthor}
						target={`comment_${comment.autor?._id}_${comment._id}`}
					/>
				</div>
				<div className={`${isReply ? 'comment__sm' : 'comment__lg'}`}>
					<div className="panel panel__default">
						<div className="panel__body">
							<header className="">
								<div className="">
									<div className="comment__user d-inline mr-3">
										{comment.autor?.user?._nome || comment.autor?.user?.nome}
									</div>
									<time className="comment__edited" dateTime={comment.createdAt}>
										{t('dates.fullDate', { date: new Date(comment.createdAt) })}
									</time>
								</div>
								{comment.isAuthor
									&& (
										<SMenu
											id={`${comment._id}-menu`}
											open={Boolean(anchorEl)}
											anchorEl={anchorEl}
											setAnchorEl={setAnchorEl}
											options={[
												{
													icon: <Icon icon="edit" />,
													label: t('global.edit'),
													// className: 'comment-edit',
													onClick: () => handleEdit(),
												},
												{
													icon: <Icon icon="remove" />,
													label: t('global.delete'),
													onClick: () => handleDelete(comment._id),
												},
											]}
										>
											<IconButton color="primary" size="small">
												<Icon icon="ellipsis" />
											</IconButton>
										</SMenu>
									)}
							</header>
							<div className="comment__post">
								{comment.preview
									&& (
										<Preview
											title={comment.preview.title}
											description={comment.preview.description}
											image={comment.preview.image}
											link={comment.preview.link}
											className="transition border-0 mb-0"
										/>
									)}
								<div dangerouslySetInnerHTML={{ __html: comment.conteudo }} />
							</div>
						</div>
						<div className="d-flex justify-content-between mb-2 mt-2">
							{(comment.edited && comment.updatedAt)
								? (
									<time className="comment__edited" dateTime={comment.updatedAt}>
										{t('global.editedIn', { date: new Date(comment.updatedAt) })}
									</time>
								)
								: <div />}
							<div className="content-right">
								<SButton
									type="button"
									color="primary"
									id={target.reply}
									data-reply-to={comment._id}
									onClick={focusReply}
									className="comment__action"
									startIcon={<Icon icon="reply" />}
								>
									<span className="">{t('global.reply')}</span>
								</SButton>
								<SButton
									color="primary"
									id={target.like}
									className="ml-2 comment__action"
									data-comment-id={comment._id}
									onClick={handleLike}
									startIcon={comment.likes?.findIndex((_like) => _like.autor?.toString() === userOrganizationProfile?._id?.toString()) > -1
										? <AiFillHeart className="heart-red" /> : <AiOutlineHeart />}
								>

									{` ${comment.likes?.length || 0} `}
								</SButton>
								{comment.autor?._id !== userOrganizationProfile?._id && (
									<div
										aria-hidden
										id={target.flag}
										className="ml-2 comment__action"
										onClick={(e) => {
											persistor.pause();
											dispatch(
												Creators.MODAL.showModal(
													routes.MODAL_REPORT.path,
													{ _id: comment._id, objeto: 'comentario' },
												),
											);
											e.preventDefault();
										}}
									>
										<Icon icon="flag" className="mr-0" />
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			</>
		);
};

const Translation = withTranslation('common')(CComment);

const Comment = (props: ICommentProps) => (
	<Suspense fallback={<div />}>
		<Translation {...props} />
	</Suspense>
);

export { Comment };
