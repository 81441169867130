import i18next from 'i18next';
import * as Yup from 'yup';
import { goToLink } from '@utils/ReactUtils';
import { SToast } from '@modules/SToast';

export const TOKEN_KEY: string = 'SENNO_DELTA_TOKEN';
export const isAuthenticated = (): boolean => localStorage.getItem(TOKEN_KEY) !== null;
export const getToken = () => localStorage.getItem(TOKEN_KEY);

export const auth = {
	setToken(token: string) {
		localStorage.setItem(TOKEN_KEY, token);
	},
};

export const onLogout = () => {
	localStorage.removeItem(TOKEN_KEY);
	localStorage.removeItem('persist:SENNO_DELTA');
	sessionStorage.clear();
	localStorage.clear();
	goToLink('/logIn');
};

export const handleAxiosErrors = (err: any, key?: 'token') => {
	console.log('AXIOS ERRORS', err, err.response);

	if (err instanceof Yup.ValidationError) {
		return;
	}

	if (err.response?.data?.err) {
		if (['messages.user.notFound', 'messages.token.expired', 'messages.user.profileNotFound'].includes(err.response?.data?.err)) {
			SToast.error(i18next.t(err.response?.data?.err));
			onLogout();
			return;
		}
		if (key !== 'token') {
			SToast.error(i18next.t(err.response?.data?.err));
		}

		return;
	}

	SToast.error(err);
};
