import React from 'react';
import { IPreview } from 'src/@types/IShared';

export interface IPreviewProps extends IPreview {
	className?: string
}

const Preview = (props: IPreviewProps) => {
	const {
		image, title, description, link, id = 'preview-link', className,
	} = props;

	return (
		<a id={id} href={link} target="_blank" className={`preview-box ${className || ''}`} rel="noreferrer">
			<div className="preview-image">
				<label
					style={
						{
							backgroundSize: 'cover',
							backgroundPosition: 'center center',
							backgroundImage: `url(${image})`,
						}
					}
				/>
			</div>
			<div className="premain__content">
				<p className="preview-title">
					{' '}
					{title}
					{' '}
				</p>
				<p className="preview-description">
					{' '}
					{description}
				</p>
			</div>
		</a>
	);
};

export default Preview;
