declare interface String {
	getInitials(): string
	normalizeString(): string
	capitalizeFirstLetter(): string
}

String.prototype.normalizeString = function () {
	return this.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
};

String.prototype.capitalizeFirstLetter = function () {
	return this.charAt(0).toUpperCase() + this.slice(1);
};

String.prototype.capitalize = function () {
	const words = this.split(' ');
	let capitalized = '';

	words.forEach((word, index) => {
		if (word.length > 3 || index === 0 || words[index - 1].length <= 3) {
			capitalized += word[0]?.toUpperCase() + word.substring(1)?.toLowerCase();
		} else {
			capitalized += word.toLowerCase();
		}
		if (index < words.length) {
			capitalized += ' ';
		}
	});
	return capitalized;
};

String.prototype.getInitials = function () {
	const word = this;
	let result = '';
	if (word) {
		const words = word.split(' ');
		let firstWord = '';
		let lastWord = '';
		firstWord = words[0];
		if (words.length > 1) {
			lastWord = words[words.length - 1];
		}
		firstWord = firstWord.charAt(0).toUpperCase();
		lastWord = lastWord.charAt(0).toUpperCase();
		result = `${firstWord}${lastWord}`;
	} else {
		result = '';
	}
	return result;
};

String.prototype.repeatStringNumTimes = function (times) {
	let repeatedString = '';
	while (times > 0) {
		repeatedString += this;
		times--;
	}
	return repeatedString;
};
String.prototype.padCustom = function (length) {
	const pad = '0'.repeatStringNumTimes(length);
	return (pad + this).slice(-pad.length);
};

Array.prototype.flatten = function () {
	return this.reduce((el, al) => [].concat.apply(al || [], el || []), []);
};

declare module 'gsap/all';
