import {
	Box, BoxProps, Typography,
} from '@mui/material';
import React from 'react';

interface STabPanelProps extends BoxProps {
  children?: React.ReactNode
  dir?: string
  active?: string
  value: string
	hide?: boolean
	onShow?: () => void
	className?: string
	noAccess?: boolean
}

const STabPanel = (props: STabPanelProps) => {
	const {
		children, value, active, hide, onShow, noAccess, className = '', ...rest
	} = props;

	React.useEffect(() => {
		if (value === active && !hide && !noAccess) {
			if (typeof onShow === 'function') {
				onShow();
			}
		}
	}, [active]);

	if (hide) {
		return null;
	}

	if (noAccess && value === active) {
		return (
			<Box
				sx={{
					width: '100%', height: '45vh', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column',
				}}
				className="blur"
			>
				<Typography>
					No access, no access, no access, no access, no access, no access, no access,
				</Typography>
				<Typography>
					No access, no access, no access, no access, no access, no access, no access,
				</Typography>
				<Typography>
					No access, no access, no access, no access, no access, no access, no access,
				</Typography>
			</Box>
		);
	}

	return (
		<Box
			role="tabpanel"
			hidden={value !== active}
			id={`tabpanel-${value}`}
			aria-labelledby={`tab-${value}`}
			className={className || ''}
			{...rest}
		>
			{value === active && (
				<Box>
					{children}
				</Box>
			)}
		</Box>
	);
};

export { STabPanel };
