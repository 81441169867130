// @ts-nocheck
import SpinnerLDSRipple from '@components/Shared/SpinnerLDSRipple';
import { Creators } from '@actions';
import { showTab } from '@utils/helpers';
import React, { useEffect } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from 'src/store';
import routes from '@routes/modal';
import { Box } from '@mui/material';
import { SBox } from '@components/Background/SBox';
import { useNavigate } from 'react-router-dom';

export interface IOwnProps {

}

type TProps = IOwnProps & WithTranslation

const BOverview = ({ t }: TProps) => {
	const defaultPaymentMethod = useSelector((state: IRootState) => state.billing?.defaultPaymentMethod);
	const subscription = useSelector((state: IRootState) => state.billing?.subscription);
	const dispatch = useDispatch();

	const onTeamSizeHandler = () => {
		if (subscription?.plan.name === 'Free') {
			showTab({ navId: '#billing-tabs', tabId: 'billing-plans-tab' });
		} else {
			dispatch(Creators.MODAL.showModal(routes.MODAL_PLAN.path));
		}
	};

	useEffect(() => {
		dispatch(Creators.BILLING.getOverviewPlan());
	}, []);

	return (
		<SBox id="billingOverview" className="billing__overview">
			<h5>{t('settings.overview.singleSignOn.title')}</h5>
			<div className="">
				{!subscription ? <div className="loading" />
					: (
						<div className="">
							<p>
								{t('settings.overview.singleSignOn.text1', {
									plan: subscription?.plan?.name,
									interval: subscription?.plan?.interval,
								})}
								<a href="# " onClick={() => showTab({ navId: 'billing-tabs', tabId: 'billing-plans-tab' })}>{t('settings.changePlan')}</a>
								<br />
								{t('settings.overview.singleSignOn.text2', {
									licenses: subscription?.quantity || 0,
								})}
								<a
									href="# "
									onClick={onTeamSizeHandler}
								>
									{t('settings.changeTeamSize')}
								</a>
								<br />
								{t('settings.overview.singleSignOn.text3', {
									renew: t('dates.fullDate', { date: new Date(subscription?.current_period_end * 1000) }),
								})}
								{subscription?.plan.interval !== 'year'
									&& (
										<a
											href="# "
											onClick={() => showTab({ navId: 'billing-tabs', tabId: 'billing-plans-tab', focusId: 'switch-left' })}
										>
											{t('settings.switchToYearly')}
										</a>
									)}
							</p>
						</div>
					)}

				<h5>{t('settings.overview.billingActions.title')}</h5>
				<a
					href="# "
					onClick={() => showTab({ tabId: 'billing-payment-method-tab' })}
				>
					{t('messages.stripe.paymentMethod.register')}
				</a>

				{!subscription ? <div className="loading" />
					: (
						<div className="">
							{defaultPaymentMethod
								&& (
									<p>
										{t('settings.overview.billingActions.futureCharges', {
											brand: defaultPaymentMethod.card.brand.toUpperCase(),
											last4: defaultPaymentMethod.card.last4,
										})}
										<a
											href="# "
											onClick={() => showTab({ navId: '#billing-tabs', tabId: 'billing-payment-method-tab' })}
										>
											{t('settings.changePaymentMethod')}
										</a>
										<br />
										{t('settings.overview.billingActions.billingEmails', {
											email: subscription?.billingCustomer.metadata.email,
										})}
										<a
											href="# "
											onClick={() => showTab({ navId: '#billing-tabs', tabId: 'billing-settings-tab' })}
										>
											{t('settings.changeEmail')}
										</a>
									</p>
								)}
						</div>
					)}
				<div className={`footer ${!subscription ? 'loading' : ''}`}>
					<a href="# " onClick={() => showTab({ navId: '#billing-tabs', tabId: 'billing-plans-tab' })}>
						{t('settings.cancelSubscription')}
					</a>
					<div className="infos">
						{t('settings.overview.note')}
						<a
							href="# "
							onClick={onTeamSizeHandler}
						>
							{` ${t('settings.changeTeamSize')}`}

						</a>
						{` ${t('global.or')} `}
						<a
							href="# "
							onClick={() => showTab({ navId: '#billing-tabs', tabId: 'billing-payment-method-tab' })}
						>
							{` ${t('settings.changePaymentMethod')}`}

						</a>
					</div>
				</div>
			</div>
		</SBox>
	);
};

const Translation = withTranslation('common')(BOverview);

const Overview = (props: IOwnProps) => (
	<React.Suspense fallback={<SpinnerLDSRipple />}>
		<Translation {...props} />
	</React.Suspense>
);

export default Overview;
