import { axiosInstance } from '@services/AxiosInstance';
import { TIntegrations } from 'src/@types/IUser';

const APIIntegrations = {
	deleteIntegration(app: TIntegrations) {
		return axiosInstance.delete(`/integrations/${app}`);
	},

	postMiro(code: string) {
		return axiosInstance.post('/integrations/miro', { code });
	},
};

export default APIIntegrations;
