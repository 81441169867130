/* eslint-disable no-param-reassign */
// @ts-nocheck
import { useDispatch, useSelector } from 'react-redux';
import { useTour, withTour } from '@reactour/tour';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { withTranslation, WithTranslation } from 'react-i18next';
import URLS from '@constants/URLS';
import routes from '@routes/modal';
import { Creators } from '@actions';
import { IForm } from 'src/@types/IForm';
import { IIdea } from 'src/@types/IIdea';
import { FLOWS } from '@constants/GLOBAL';
import { STabs } from '@components/STabs';
import { APIPlatforms } from '@services/apis';
import { BuildTour } from '@components/STour';
import { Comments } from '@components/Comments';
import { IPlatform } from 'src/@types/IPlatform';
import { IRootState, persistor } from 'src/store';
import { handleAxiosErrors } from '@services/auth';
import CardGrid from '@components/Grids/CardGrid';
import { IdeaCard } from '@components/Cards/IdeaCard';
import { IListRequest } from '@components/Lists/types';
import { BForms } from '@components/Background/BForms';
import { STabPanel } from '@components/STabs/STabPanel';
import PageLoader from '@components/Loaders/PageLoader';
import { BBadges } from '@components/Background/BBadges';
import { BCanvas } from '@components/Background/BCanvas';
import { HelpButton, SButton } from '@components/Buttons';
import { SocketIO, SOCKET_EVENTS } from '@services/socket';
import { IComment, IPagination } from 'src/@types/IShared';
import { MembersList } from '@components/Lists/MembersList';
import { MatrixBase } from '@components/Matrices/MatrixBase';
import FiltersNavBar from '@components/NavBar/FiltersNavBar';
import PlatformCover from '@components/Covers/PlatformCover';
import ReceiveButton from '@components/Buttons/ReceiveButton';
import AttachmentsList from '@components/Lists/AttachmentsList';
import { HTMLDescription } from '@components/Background/HTMLDescription';
import { HMatrices, loadImageDashboard } from '@components/Matrices/helpers';
import { GenericGrid, W_GRID } from '@components/Grids/GenericGrid';
import { Signals } from '@components/Signals';
import { Typography } from '@mui/material';

type TProps = WithTranslation
const PPlatform = ({ t }: TProps) => {
	const tour = useTour();
	const dispatch = useDispatch();
	const match = useParams<{ id: string }>();
	const { modal, shared } = useSelector((state: IRootState) => state);

	const [platform, setPlatform] = useState<IPlatform & {isLoading: boolean }>({ comentarios: [], isLoading: true });
	const [ideas, setIdeas] = useState({ docs: [], isLoading: false, params: { sort: 'last-first', page: 1, limit: 3 } });
	const [participants, setParticipants] = useState({ docs: [], isLoading: true, totalDocs: 0 });
	const [forms, setForms] = useState<IPagination<IForm>>({ docs: [], isLoading: true });
	const [canvas, setCanvas] = useState<IPagination<IForm>>({ docs: [], isLoading: true });
	const [dashboards, setDashboards] = useState({ isLoading: true });

	const onOpenDashboard = (params) => {
		dispatch(Creators.MODAL.handleModal({ flow: params.flow }));
		dispatch(Creators.MODAL.showModal(routes.MODAL_DASHBOARD.path, {
			...params,
			platformId: match.id,
			rootPath: platform.nome,
		}));
	};

	const setTour = () => {
		const type = platform?.type.toLowerCase();
		const TOUR_STEPS = BuildTour.steps(type === FLOWS.BRAINSTORM
			? ['platform.tabs.trends', 'platform.tabs.dashboards',
				'platform.tabs.forms', 'platform.tabs.canvas', 'platform.tabs.attachments']
			: ['platform.tabs.trends', 'platform.tabs.dashboards', 'platform.tabs.forms',
				'platform.tabs.canvas', 'platform.tabs.badges', 'platform.tabs.attachments'], { noAction: true });

		BuildTour.setTour(tour, TOUR_STEPS, { currentStep: 0 });
	};

	const loadDashboards = async () => {
		try {
			const response = await APIPlatforms.getDashboard(match.id);

			await Promise.all(Object.keys(response.data).map(async (key) => {
				await Promise.all(
					response.data[key].map(async (item) => {
						item.ideia_image = await loadImageDashboard(item.ideia_image);
					}),
				);
			}));

			setDashboards({ ...response.data, isLoading: false });
		} catch (err) {
			handleAxiosErrors(err);
		}
	};

	const updateIdea = (idea: IIdea) => {
		const index = ideas.docs.findIndex((item) => item._id === idea._id);
		if (index > -1) {
			ideas?.docs.splice(index, 1, { ...ideas?.docs[index], ...idea });
			setIdeas((prevState) => ({ ...prevState, ...ideas }));
		}
	};

	const loadPlatform = async (startTour?: boolean): void => {
		try {
			const response = await APIPlatforms.get(match.id);

			setCanvas((prevState) => ({ ...prevState, docs: [...response.data.canvas, ...response.data.miroBoards], isLoading: false }));
			setForms((prevState) => ({ ...prevState, docs: response.data.forms, isLoading: false }));

			delete response.data.canvas;
			delete response.data.miroBoards;
			delete response.data.forms;

			setPlatform({
				...response.data,
				mode: response.data.isArchived
					|| (Object.hasOwnProperty.call(response.data, 'isFinished')
						&& response.data.isFinished) ? 'view' : 'edit',
				isLoading: false,
			});

			if (startTour) {
				setTour();
			}
		} catch (err) {
			handleAxiosErrors(err);
		}
	};

	const loadParticipants = ({ page, callback, ...rest }: IListRequest) => {
		dispatch(Creators.PLATFORMS.getPlatformParticipants({
			platformId: match.id,
			page,
			limit: 20,
			onSuccess: (response) => {
				setParticipants((prevState) => ({
					...prevState,
					...response,
					docs: response.hasPrevPage
						? prevState.docs.concat(response.docs)
						: response.docs,
					isLoading: false,
				}));
			},
			callback,
			...rest,
		}));
	};

	const loadCanvas = () => {
		dispatch(Creators.CANVAS.getCanvas({
			flow: platform?.type.toLowerCase(),
			platformId: match.id,
			onSuccess: (response) => setPlatform((prevState) => ({ ...prevState, canvas: response.docs })),
		}));
	};

	// state.forms, platform.canvas, platform.medalhas -> badges
	const updatePlatform = (_platform: IPlatform) => {
		if (_platform?.forms) {
			setForms((prevState) => ({ ...prevState, docs: _platform.forms }));
		}

		if (_platform?.canvas) {
			setCanvas((prevState) => ({ ...prevState, docs: _platform.canvas }));
		}

		const members = [
			...(_platform.membros?.agentes || []),
			...(_platform.membros?.moderadores || []),
			...(_platform.membros?.participantes || []),
			...(_platform.membros?.sponsors || []),
		];

		if (members.length > participants.totalDocs) {
			loadParticipants({
				sort: 'a-z', page: 1, limit: 20, filter: '',
			});
		}

		setPlatform((prevState) => ({ ...prevState, ..._platform }));
	};

	const loadIdeas = async (params: IListRequest) => {
		try {
			setIdeas((prevState) => ({
				...prevState,
				isLoading: true,
			}));

			const response = await APIPlatforms.getIdeas(match.id, {
				...ideas.params, ...params,
			});

			setIdeas((prevState) => ({
				...prevState,
				...response.data,
				docs: response.data.hasPrevPage
					? prevState.docs.concat(response.data.docs)
					: response.data.docs,
				isLoading: false,
				params,
			}));
		} catch (err) {
			handleAxiosErrors(err);
		} finally { // loadMore
			if (typeof params.callback === 'function') {
				params.callback();
			}
		}
	};

	const loadForms = () => {
		dispatch(Creators.FORMS.getForms({
			platformId: platform._id,
			flow: platform?.type?.toLowerCase(),
			onSuccess: (response) => setForms({ ...response, isLoading: false }),
		}));
	};

	const platformButton = (type: string) => {
		const flow = platform?.type.toLowerCase();

		const defaultProps = {
			type: 'button',
			color: 'primary',
			variant: 'outlined',
			size: 'large',
		};

		switch (type) {
			case 'newIdea':
				return (
					<SButton
						onClick={(e) => {
							persistor.pause();
							dispatch(Creators.MODAL.handleModal({ flow: FLOWS.IDEA }));
							dispatch(Creators.MODAL.showModal(
								routes.MODAL_CREATE.path,
								{ platformId: platform._id, isFullScreen: false },
							));
							e.stopPropagation();
						}}
						{...defaultProps}
					>
						{t('idea.new')}
					</SButton>
				);
			case 'edit':
				return (
					<SButton
						onClick={() => {
							dispatch(Creators.PLATFORMS.updatePlatform({ workflow: 'UPDATE_PLATFORM' }));
							dispatch(Creators.PLATFORMS.getPlatform({
								redirectPath: routes.MODAL_PLATFORM.path,
								platformId: match.id,
							}));
						}}
						{...defaultProps}
					>
						{t('global.edit')}
					</SButton>
				);
			case 'authenticate':
			case 'join':
			case 'invite':
				return (
					<ReceiveButton
						mode={type}
						color="primary"
						feedbackOptions={{
							match: {
								platformId: platform?._id,
								flow,
								hasInvite: platform.possuiPedidoParticipacao,
								updatePlatform,
								permission: platform.permissao,
								passwordRecurrency: platform.passwordRecurrency,
							},
						}}
					/>
				);
			default:
				return null;
		}
	};

	const loadBadges = () => {
		dispatch(Creators.BADGES.getBadgesByPlatform({
			platformId: match.id,
			onSuccess: (response) => setPlatform((prevState) => ({ ...prevState, medalhas: response.docs })),
		}));
	};

	const mainButton = () => {
		if (platform.status === 'rascunho') return platformButton('edit');
		if (platform.isArchived) return null;
		if (platform.usuarioParticipa) {
			switch (platform.type) {
				case 'Challenge':
					if (platform.usuarioAgencia && !platform.isFinished) return platformButton('newIdea');
					if (platform.usuarioModera) return platformButton('edit');
					if (platform.usuarioPatrocina) return null;
					break;
				case 'Brainstorm':
					if (platform?.usuarioParticipa) {
						return platformButton('newIdea');
					}
					break;
				default:
					break;
			}
		}

		if (platform.possuiPedidoParticipacao) {
			return platformButton('invite');
		}

		if (platform.permissao === 'privada') return null;

		return platformButton('join');
	};

	useEffect(() => {
		SocketIO.emit(SOCKET_EVENTS.JOIN_PLATFORM, { room: match.id });
		SocketIO.emit(SOCKET_EVENTS.JOIN_COMMENT, { room: match.id });
		dispatch(Creators.PLATFORMS.updatePlatform({ syncPlatform: updatePlatform })); // updateIdea function Store
		dispatch(Creators.IDEAS.updateIdeas({ syncIdea: updateIdea }));
		loadPlatform([FLOWS.BRAINSTORM, FLOWS.CHALLENGE].includes(shared.tour) && !modal.flow);
	}, []);

	const filtersChallenge = [
		{ value: '', label: t('global.all'), active: true },
		{ value: 'agentes', label: t('global.agents') },
		{ value: 'moderadores', label: t('global.moderators') },
		{ value: 'sponsors', label: t('global.sponsors') },
	];

	const members = [
		...(platform.membros?.agentes || []),
		...(platform.membros?.moderadores || []),
		...(platform.membros?.participantes || []),
		...(platform.membros?.sponsors || []),
	];

	if (platform.isLoading || !platform) {
		return <PageLoader />;
	}

	const flow = platform?.type?.toLowerCase();
	const noAccess = !platform?.usuarioParticipa;
	const isChallenge = platform?.type === 'Challenge';

	const TOUR_DASHBOARD = BuildTour.steps(['dashboard.d', 'dashboard.di', 'dashboard.tr'], { noAction: true });

	const TABS = {
		trends: { value: 'trends', label: 'Signals'},
		evaluations: {
			value: 'evaluations',
			label: t('global.evaluations'),
			icon: !noAccess && <HelpButton onClick={() => BuildTour.setTour(tour, TOUR_DASHBOARD, { currentStep: 0 })} />,
			iconPosition: 'end',
		},
		forms: { value: 'forms', label: `${t('global.form')}s` },
		canvas: { value: 'canvas', label: 'Canvas' },
		badges: { value: 'badges', label: t('global.badges'), hide: !isChallenge },
		attachments: { value: 'attachments', label: t('global.attachments') },
	};

	const RELATED_TABS = {
		participants: {
			value: 'participants',
			label: `${t('global.participants')} (${members.length || 0})`,
		},
		ideas: {
			value: 'ideas',
			label:
					`${t('global.ideas')} (${ideas.totalDocs || platform.ideias?.length || 0})`,
		},
		comments: {
			value: 'comments',
			label:
					`${t('global.comments')
					} (${platform.comentarios.reduce((acc, c) => {
						// Adiciona o numero de respostas
						acc += c.respostas ? c.respostas.length : 0;
						// Adiciona 1 pelo comentario atual;
						acc += 1;
						// Retorna acc;
						return acc;
					}, 0)
					})`,
		},
	};

	return (
		<GenericGrid id="platform-page" className="platform__page s-grid-page">
			<PlatformCover
				platform={platform}
				updatePlatform={updatePlatform}
			/>
			<div className="block has-tablet">
				<div className="inner w-grid-2 first-section">
					<span className="summary">
						{t('global.summary')}
					</span>
					<HTMLDescription className="box__page--info" type="summary" html={platform.resumo} />
					{platform.tags?.length > 0 && (
						<div className="tags__group">
							{platform.tags.map((tag) => (
								<a key={tag.value} href={`${URLS.SEARCH}?query=${tag.value}`} className="tag">
									{tag.value}
								</a>
							))}
						</div>
					)}
					<div className="main-action">
						{mainButton()}
					</div>
				</div>
				<STabs
					id="platform-tabs"
					tabs={Object.values(TABS)}
					className="main-tabs"
					historyMode="primary"
					classes={{ appBar: W_GRID.W_GRID_2, tabs: W_GRID.W_GRIsD_2 }}
					active={TABS.trends.value}
					TabIndicatorProps={{ sx: { display: 'none' } }}
					noAccess={noAccess}
					textColor="secondary"
					sx={{ sTabs: { marginY: 6 } }}
					TabPanelProps={{
						sx: {
							marginY: 3,
						},

					}}
				>
					<STabPanel value={TABS.trends.value}>
						<Signals platformId={platform._id} classes={{ zoomIn: 'inner w-grid-1', zoomOut: W_GRID.W_GRID_2 }} />
					</STabPanel>
					<STabPanel value={TABS.evaluations.value} onShow={loadDashboards}>
						<MatrixBase
							id="d"
							className="matrix__d g-2x2"
							title={t(HMatrices.D.title)}
							optionBase={HMatrices.D.option}
							quadrants={HMatrices.D.quadrants}
							classes={{
								zoomIn: W_GRID.W_GRID_1,
								zoomOut: W_GRID.W_GRID_2,
							}}
							isLoading={dashboards.isLoading}
							series={HMatrices.D.buildSeries(dashboards.disruption)}
							onClickMore={(params) => onOpenDashboard({
								...params,
								matrix: 'disruption',
								flow,
								title: t(HMatrices.D.title),
							})}
						/>
						<MatrixBase
							id="di"
							className="matrix__di g-2x2"
							title={t(HMatrices.DI.title)}
							optionBase={HMatrices.DI.option}
							quadrants={HMatrices.DI.quadrants}
							classes={{
								zoomIn: W_GRID.W_GRID_1,
								zoomOut: W_GRID.W_GRID_2,
							}}
							isLoading={dashboards.isLoading}
							series={HMatrices.DI.buildSeries(dashboards.difficultyImpact)}
							onClickMore={(params) => onOpenDashboard({
								...params,
								matrix: 'difficulty-impact',
								flow,
								title: t(HMatrices.DI.title),
							})}
						/>
						<MatrixBase
							id="tr"
							className="matrix__tr g-3x3"
							title={t(HMatrices.TR.title)}
							optionBase={HMatrices.TR.option}
							quadrants={HMatrices.TR.quadrants}
							classes={{
								zoomIn: W_GRID.W_GRID_1,
								zoomOut: W_GRID.W_GRID_2,
							}}
							isLoading={dashboards.isLoading}
							series={HMatrices.TR.buildSeries(dashboards.timeReturn)}
							onClickMore={(params) => onOpenDashboard({
								...params,
								matrix: 'time-return',
								flow,
								title: t(HMatrices.TR.title),
							})}
						/>
					</STabPanel>
					<STabPanel value={TABS.attachments.value} className={W_GRID.W_GRID_2}>
						<AttachmentsList
							title={<Typography variant="h5" className="title-list">{t('global.attachments')}</Typography>}
							attachments={{ docs: platform.anexos }}
						/>
					</STabPanel>
					<STabPanel
						value={TABS.canvas.value}
						onShow={loadCanvas}
						className={W_GRID.W_GRID_2}
					>
						<BCanvas
							id="platform-canvas"
							mode="view"
							orientation="vertical"
							components={{
								title: (
									<Typography variant="h5" className="title-list">
										{t('form.platform.canvas', { flow: t(`global.${flow}`) })}
									</Typography>
								),
							}}
							size="x-large"
							canvas={canvas}
							flow={flow}
							routes={{ nav: routes.MODAL_CANVAS.path }}
							platformId={platform._id}
							rootPath={platform.nome || '...'}
							hasEmptyState
						/>
					</STabPanel>
					<STabPanel
						value={TABS.forms.value}
						onShow={loadForms}
						className={W_GRID.W_GRID_2}
					>
						<BForms
							id="platforms-forms"
							mode="view"
							forms={forms}
							flow={flow}
							components={{
								title: (
									<Typography variant="h5" className="title-list">
										{t('form.platform.forms', { flow: t(`global.${flow}`) })}
									</Typography>
								),
							}}
							routes={{ nav: routes.MODAL_FORMS.path }}
							rootPath={platform.nome || '...'}
							hasEmptyState
							size="x-large"
							orientation="vertical"
						/>
					</STabPanel>
					<STabPanel
						value={TABS.badges.value}
						hide={!isChallenge}
						onShow={loadBadges}
						className={W_GRID.W_GRID_2}
					>
						<BBadges
							id="platform-badges"
							badges={{ docs: platform.medalhas, isLoading: !platform.medalhas }}
							hasEmptyState
							orientation="vertical"
							size="x-large"
							components={{
								title: (
									<Typography variant="h5" className="title-list">
										{t('form.platform.badges', { flow: t(`global.${flow}`) })}
									</Typography>
								),
							}}
						/>
					</STabPanel>
				</STabs>
				<STabs
					id="platform-related-tabs"
					tabs={Object.values(RELATED_TABS)}
					className="inner w-grid-2 related-tabs"
					active={RELATED_TABS.participants.value}
					TabIndicatorProps={{ sx: { display: 'none' } }}
					textColor="secondary"
					noAccess={noAccess}
					sx={{ sTabs: { marginY: 6 } }}
					TabPanelProps={{
						sx: {
							marginY: 3,
						},

					}}
				>
					<STabPanel
						value={RELATED_TABS.ideas.value}
						onShow={() => loadIdeas({ ...ideas.params, page: 1 })}
					>
						<FiltersNavBar
							id="ideas-platform-nav"
							params={ideas.params}
							onSearch={loadIdeas}
							onUpdate={loadIdeas}
						>
							<CardGrid
								sx={{ marginY: 3 }}
								id="platform-ideas-cards"
								section={ideas}
								loadMore={loadIdeas}
								queryParams={ideas.params}
								type="ideas"
								GridItemProps={{
									xs: 12, sm: 6, md: 6, lg: 6, xl: 6,
								}}
								component={(idea: IIdea) => (
									<IdeaCard
										idea={idea}
										routes={routes}
										sectionLocator="ideas"
										updateIdea={updateIdea}
									/>
								)}
							/>
						</FiltersNavBar>
					</STabPanel>
					<STabPanel
						value={RELATED_TABS.participants.value}
						onShow={() => loadParticipants({
							sort: 'a-z', page: 1, limit: 20, filter: '',
						})}
					>
						<FiltersNavBar
							id="platform-participants-nav"
							params={{
								sort: 'a-z', page: 1, limit: 20, filter: '',
							}}
							onSearch={loadParticipants}
							onUpdate={loadParticipants}
							filters={isChallenge ? filtersChallenge : undefined}
						>
							<MembersList
								id="participants-list"
								isBrainstorm={!isChallenge}
								isChallenge={isChallenge}
								members={participants || []}
								loadMore={loadParticipants}
							/>
						</FiltersNavBar>
					</STabPanel>
					<STabPanel value="comments">
						<Comments
							comments={platform.comentarios}
							objectId={match.id}
							updateComments={(comentarios: IComment) => updatePlatform({ comentarios })}
						/>
					</STabPanel>
				</STabs>
			</div>
		</GenericGrid>
	);
};

const Translation = withTranslation('common')(PPlatform);

const Platform = (props: TProps) => (
	<React.Suspense fallback={<PageLoader />}>
		<Translation {...props} />
	</React.Suspense>

);

export default withTour(Platform);
