// @ts-nocheck
import React, { Component } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { IRootState } from 'src/store';
import { IPagination, IPaginatedRequest } from 'src/@types/IShared';
import { ITeam } from 'src/@types/ITeam';
import APITeams from '@services/apis/teams';
import { TeamCard } from '@components/Cards/TeamCard';
import { handleAxiosErrors } from '@services/auth';
import GLOBAL from '@constants/GLOBAL';
import { IListRequest } from '@components/Lists/types';
import CardGrid from '@components/Grids/CardGrid';
import { SButton } from '@components/Buttons';
import { Box } from '@mui/material';
import Icon from '@components/Shared/Icon';
import FiltersNavBar from '@components/NavBar/FiltersNavBar';
import { GenericGrid } from '@components/Grids/GenericGrid';
import PageLoader from '@components/Loaders/PageLoader';

interface IState extends IPagination<ITeam> {
	queryParams: IPaginatedRequest
}

type TProps = WithTranslation & IRootState

class _Teams extends Component<TProps, IState> {
	constructor(props: TProps) {
		super(props);
		this.state = {
			queryParams: {
				page: 1,
				limit: GLOBAL.DOCS_PER_PAGE,
				sort: 'last-first',
				filter: 'highlights',
			},
			teams: { docs: [], isLoading: true },
		};

		this.loadTeams = this.loadTeams.bind(this);
		this.loadMore = this.loadMore.bind(this);
		this.updateList = this.updateList.bind(this);
	}

	componentDidMount() {
		this.loadTeams(this.state.queryParams);
	}

	componentDidUpdate() {

	}

	async loadTeams(params: IListRequest) {
		try {
			const response = await APITeams.getTeams(params);

			this.setState((prevState) => ({
				...prevState,
				teams: {
					...response.data,
					docs: response.data.hasPrevPage
						? prevState.teams.docs.concat(response.data.docs)
						: response.data.docs,
					isLoading: false,
				},
			}));
		} catch (err: any) {
			handleAxiosErrors(err);
		} finally { // loadMore
			if (typeof params.callback === 'function') {
				params.callback();
			}
		}
	}

	updateList(params: IPaginatedRequest) {
		this.setState((prevState) => ({
			...prevState,
			...params,
		}));

		this.loadTeams(params);
	}

	loadMore(params: IListRequest) {
		this.setState((prevState) => ({
			...prevState,
			queryParams: params,
		}));
		this.loadTeams(params);
	}

	render() {
		const { t } = this.props;
		const { teams, queryParams } = this.state;

		const filters = [
			{
				value: 'meus',
				label: t('page.teams.my'),
				hide: true,
				icon: 'teams',
			},
			{
				value: 'highlights',
				label: t('global.all'),
			},
			{
				value: 'grupos_participo',
				label: t('global.participations'),
			},
		];

		return (
			<GenericGrid id="teams-page" className="s-grid-page" paddingTop={6}>
				<FiltersNavBar
					id="teams-nav"
					className="w-grid-1 inner"
					onUpdate={this.updateList}
					filters={filters}
					params={queryParams}
					components={{
						LeftButton: (
							<SButton
								type="button"
								color="inherit"
								variant="text"
								sx={{ borderRadius: 0 }}
								startIcon={filters[0].icon && <Icon icon={filters[0].icon} />}
								onClick={() => this.updateList({ ...queryParams, filter: filters[0].value })}
							>
								{filters[0]?.label}
							</SButton>
						),
					}}
				/>
				<Box className="w-grid-1 inner">
					<CardGrid
						section={teams}
						loadMore={this.loadMore}
						type="teams"
						GridItemProps={{ xs: 12, sm: 6, md: 4, xl: 3}}
						component={(item) => (
							<TeamCard team={item} />
						)}
					/>
				</Box>
			</GenericGrid>
		);
	}
}

const Translation = withTranslation('common')(_Teams);

const Teams = (props: TProps) => (
	<React.Suspense fallback={<PageLoader />}>
		<Translation {...props} />
	</React.Suspense>
	
);

export default Teams;
