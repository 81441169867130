/* eslint-disable react/no-danger */
import React, { useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { SButton } from '@components/Buttons';
import UnauthenticatedHeader from '@components/Header/UnauthenticatedHeader';
import { goToLink } from '@utils/ReactUtils';
import APIUnregistered from '@services/apis/unregistered';
import PageLoader from '@components/Loaders/PageLoader';
import {
	Link, useLocation, useNavigate, useParams,
} from 'react-router-dom';
import URLS from '@constants/URLS';
import { APIIntegrations, APIOrganizations, APIUser } from '@services/apis';
import { IRootState } from 'src/store';
import { Creators } from '@actions';
import { useDispatch, useSelector } from 'react-redux';
import { auth } from '@services/auth';
import { GenericGrid } from '@components/Grids/GenericGrid';
import { SBox } from '@components/Background/SBox';
import { MessageBox } from '@components/Shared/MessageBox';

interface MessageProps {
	title: string
	content: string
	buttonLabel?: string
	handleClick?: () => void
}

type TProps = WithTranslation

const _Feedback = (props: TProps) => {
	const { t } = props;
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	const match = useParams<{ id: string }>();
	const [message, setMessage] = useState<MessageProps>();
	const [hideSubmit, setHideSubmit] = useState<boolean>();
	const [inProgress, setInProgress] = useState<boolean>();

	const { userOrganizationProfile, modal } = useSelector((state: IRootState) => state);

	const onDeleteOrganization = async () => {
		try {
			// @ts-ignore
			await APIUnregistered.deleteOrganization(match.id);
		} catch (err: any) {
			if (err?.response?.status === 404) {
				goToLink(URLS.HOME);
				return;
			}

			setMessage({
				title: t('global.error'),
				content: t('messages.delete.organization.error'),
			});
		} finally {
			setInProgress(false);
		}
	};

	const onDeleteAccount = async () => {
		try {
			setInProgress(true);
			// @ts-ignore
			await APIUser.deleteAccount(match.id);
		} catch (err: any) {
			if (err?.response?.status === 404) {
				goToLink(URLS.HOME);
				return;
			}

			setMessage({
				title: t('global.error'),
				content: t('messages.delete.account.error'),
			});
		} finally {
			setInProgress(false);
		}
	};

	const onCreateOrganizationRequest = () => {
		setInProgress(true);
		dispatch(Creators.USER_ORGANIZATION.requestOrganizationCreation({
			email: userOrganizationProfile?.user?.email,
			onSuccess: () => navigate(`${URLS.TOKEN}?step=createOrganization&email=${userOrganizationProfile?.user?.email}`),
			callback: () => setInProgress(false),
		}));
	};

	const onCheckoutLinkedIn = async () => {
		try {
			const code = new URLSearchParams(location.search)?.get('code');
			if (!code) return;
			const response = await APIUnregistered.postLinkedInCheckout(code);

			auth.setToken(response.data?.token);

			if (response?.status === 200) { // created
				goToLink(URLS.HOME);
				return;
			}
			if (response?.status === 201) { // register
				goToLink(URLS.ACCOUNT_SETUP);
			}
		} catch (err: any) {
			setMessage({
				title: t('global.error'),
				content: t('feedback.integration.error'),
			});
			setHideSubmit(false);
		} finally {
			setInProgress(false);
		}
	};

	const onCheckoutSlack = async () => {
		try {
			setInProgress(true);
			const code = new URLSearchParams(location.search)?.get('code');
			if (!code) return;

			await APIOrganizations.postSlack(code);

			goToLink(modal?.pagePath);
		} catch (err: any) {
			setMessage({
				title: t('global.error'),
				content: t('feedback.integration.error'),
				buttonLabel: `${t('global.goTo')} SENNO`,
				handleClick: () => goToLink(modal?.pagePath),
			});
		} finally {
			setInProgress(false);
		}
	};

	const onCheckoutMiro = async () => {
		try {
			const code = new URLSearchParams(location.search)?.get('code');
			if (!code) return;
			const response = await APIIntegrations.postMiro(code);
			console.log('miró', response);
			// goToLink(modal?.pagePath);

			setTimeout(() => {
				window.close();
			}, 5000);
		} catch (err: any) {
			console.log('err Slack', err, err?.response);
			setMessage({
				title: t('global.error'),
				content: t('feedback.integration.error'),
				buttonLabel: `${t('global.goTo')} SENNO`,
				handleClick: () => goToLink(modal?.pagePath),
			});
		} finally {
			setInProgress(false);
		}
	};

	React.useEffect(() => {
		if (location.pathname.includes('deleteOrganization')) {
			onDeleteOrganization();
			setMessage({
				title: t('messages.delete.organization.title'),
				content: t('messages.delete.organization.text'),
			});
		}

		if (location.pathname.includes('deleteAccount')) {
			onDeleteAccount();
			setMessage({
				title: t('messages.delete.account.title'),
				content: t('messages.delete.account.text'),
			});
		}

		if (location.pathname.includes('linkedin')) {
			onCheckoutLinkedIn();
			setMessage({
				title: t('feedback.integration.title', { social: 'LinkedIn' }),
				content: t('feedback.integration.text'),
			});
			setHideSubmit(true);
		}

		if (location.pathname.includes('slack')) {
			onCheckoutSlack();
			setMessage({
				title: t('feedback.integration.title', { social: 'Slack' }),
				content: t('feedback.integration.text'),
			});
			setHideSubmit(true);
		}

		if (location.pathname.includes('miro')) {
			onCheckoutMiro();

			setMessage({
				title: t('feedback.integration.title', { social: 'Miró' }),
				content: t('feedback.integration.text-close'),
			});
			setHideSubmit(true);
		}

		if (location.pathname.includes('createOrganization')) {
			setMessage({
				title: t('feedback.noOrg.title'),
				content: t('feedback.noOrg.text'),
				handleClick: onCreateOrganizationRequest,
				buttonLabel: t('global.continue'),
			});
		}
	}, []);

	const createOrganization = location.pathname.includes('createOrganization');

	// http://localhost:8080/signUp/token?step=createOrganization&email=mayssalaiane@gmail.com

	return (
		<GenericGrid id="feedback-page" className="s-grid-page">
			<UnauthenticatedHeader modus={createOrganization ? 'logIn' : undefined} />
			<SBox className="w-grid-3 inner unauthenticated" loading={!message}>
				<MessageBox
					title={message?.title || ''}
					content={message?.content}
					button={
						typeof message?.handleClick === 'function' ? (
							<SButton
								type="button"
								variant="outlined"
								sx={{ width: { xs: '100%' } }}
								color="primary"
								isLoading={inProgress}
								onClick={message?.handleClick}
							>
								{message?.buttonLabel}
							</SButton>
						) : !hideSubmit && (
							<SButton
								type="button"
								variant="outlined"
								sx={{
									padding: 0,
									width: { xs: '100%' },
									a: {
										display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', width: '100%',
									},
								}}
								color="primary"
							>
								<Link to={URLS.LOGIN}>
									{t('register.backToLogin')}
								</Link>
							</SButton>
						)
					}
				/>
			</SBox>
		</GenericGrid>
	);
};

const Translation = withTranslation('common')(_Feedback);
const Feedback = () => (
	<React.Suspense fallback={<PageLoader />}>
		<Translation />
	</React.Suspense>
);

export default Feedback;
