/* eslint-disable import/no-extraneous-dependencies */
// @ts-nocheck
import Papa from 'papaparse';
import { Creators } from '@actions';
import { IRootState } from 'src/store';
import { SButton } from '@components/Buttons';
import { useNavigate } from 'react-router-dom';
import type { IModal } from '@modules/Modal/types';
import { useState, useEffect, Suspense } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	Box, DialogActions, DialogContent, DialogTitle, Typography,
} from '@mui/material';
import { Trans, WithTranslation, withTranslation } from 'react-i18next';
import { DialogBox } from '@modules/Modal/Components';
import { ComputerUploadButton } from '@components/Buttons/ComputerUploadButton';
import { IAutocompleteOption } from '@components/DynamicForm/Components/SSelect/SAutocomplete';
import Icon from '@components/Shared/Icon';
import { SToast } from '@modules/SToast';
import ModalFeedback, { IFeedbackProps } from '../ModalFeedback';

declare type TProps = IModal & WithTranslation

const _ModalBatchInvite = (props: TProps) => {
	const { t } = props;
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [emails, setEmails] = useState<IAutocompleteOption[]>([]);
	const [inProgress, setInProgress] = useState(false);
	const params = useSelector((state: IRootState) => state.modal?.ModalProps);
	const subscription = useSelector((state: IRootState) => state.billing?.subscription);
	const licenses = useSelector((state: IRootState) => state.billing?.licenses);
	const [feedbackProps, setFeedbackProps] = useState<IFeedbackProps>({ open: false });

	const onInviteSubmit = (event) => {
		event?.preventDefault();

		const newMembers = {
			invites: emails,
		};

		setInProgress(true);
		dispatch(Creators.USER_ORGANIZATION.inviteOrganization({
			invitations: newMembers,
			onSuccess: () => {
				if (typeof params?.callback === 'function') {
					params.callback(); // loadInvites on page
				}
				dispatch(Creators.MODAL.hideModal());
			},
			// onErrors: () => setErrors({ invites: 'Convites não enviados' }),
			callback: () => setInProgress(false),
		}));
	};

	const handleEmails = (data) => {
		Papa.parse(data, {
			header: true,
			skipEmptyLines: true,
			complete: (result) => {
				console.log('-----', result);
				const csvEmails = result.data.map((csv) => csv['E-mail'] || csv.email || csv['e-mail']);

				if (result?.data?.length <= licenses) {
					setEmails(csvEmails);
					SToast.success(t('member.imported', { number: emails.length }));
				} else {
					setFeedbackProps((prevProps) => ({
						...prevProps,
						open: true,
						text: t('member.limit', { number: licenses }),
						title: t('member.bulkInvite'),
					}));
				}
			},
		});
	};

	useEffect(() => {
		dispatch(Creators.BILLING.getOverviewPlan({ navigate }));
	}, []);

	return (
		<DialogContent className="modal__invite" id="BatchInviteDialog">
			<DialogBox className="s-dialog-title">
				<DialogTitle variant="h6">
					{t('member.bulkInvite')}
				</DialogTitle>
			</DialogBox>
			<DialogBox className="s-dialog-content">
				<ComputerUploadButton
					label="Choose a csv file"
					accept="text/csv"
					onClick={handleEmails}
					isLoading={inProgress === 'upload'}
					mode="File"
				/>
			</DialogBox>

			{emails.length > 0 && (
				<DialogBox className="s-dialog-content">
					<Typography>
						<Trans
							i18nKey="member.imported"
							values={{ number: emails.length }}
						/>
					</Typography>
				</DialogBox>
			)}

			<DialogBox className="s-dialog-content">
				<Box className="box__info--small">
					<Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
						<Icon icon="warning" />
						{t('member.using_licenses')}
					</Box>
					{subscription ? (
						<p
							// eslint-disable-next-line react/no-danger
							dangerouslySetInnerHTML={{
								__html: t('member.inviteInfo', {
									licenses,
									plan: subscription.plan?.name,
								}),
							}}
						/>
					) : (
						<p>
							{t('member.noSubscription')}
						</p>
					)}
				</Box>
			</DialogBox>
			<DialogBox className="s-dialog-box">
				<DialogActions className="s-dialog-actions">
					<SButton type="button" color="secondary" onClick={() => dispatch(Creators.MODAL.hideModal())}>
						{t('global.cancel')}
					</SButton>
					<SButton
						type="button"
						color="success"
						disabled={emails.length <= 0}
						onClick={onInviteSubmit}
						isLoading={inProgress}
					>
						{t('global.invite')}
					</SButton>
				</DialogActions>
			</DialogBox>

			{feedbackProps.open && (
				<ModalFeedback
					id="bulk-invite"
					onRequestClose={() => setFeedbackProps({ open: false })}
					{...feedbackProps}
				/>
			)}
		</DialogContent>
	);
};

const Translation = withTranslation('common')(_ModalBatchInvite);

const ModalBatchInvite = (props: IModal) => (
	<Suspense fallback={<div />}>
		<Translation {...props} />
	</Suspense>
);

export default ModalBatchInvite;
