// @ts-nocheck
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withTranslation, WithTranslation } from 'react-i18next';
import routes from '@routes/modal';
import { Creators } from '@actions';
import { onLogout } from '@services/auth';
import { IRootState, persistor } from 'src/store';
import PageLoader from '@components/Loaders/PageLoader';
import { ISTab, STabs } from '@components/STabs';
import { STabPanel } from '@components/STabs/STabPanel';
import Icon from '@components/Shared/Icon';
import { SBox } from '@components/Background/SBox';
import _ from 'lodash';
import { GenericGrid } from '@components/Grids/GenericGrid';
import Invites from './Invites';
import ActiveUsers from './ActiveUsers';
import Organizations from './Organizations';
import ReportedContent from './ReportedContent';
import Account from './Account';
import Billing from './Billing';
import User from './User';

export const TABS_CLASSNAMES = '';

interface ISettingsTabs {
	USER: ISTab
	REPORT: ISTab
	BACKUP: ISTab
	LOGOUT: ISTab
	BILLING: ISTab
	SECURITY: ISTab
	INVITATIONS: ISTab
	LANDING_PAGE: ISTab
	ACTIVE_USERS: ISTab
	ORGANIZATIONS: ISTab
	ORGANIZATION_PROFILE: ISTab
}

type TProps = WithTranslation

const _Settings = ({ t }: TProps) => {
	const dispatch = useDispatch();
	const { userOrganizationProfile } = useSelector((state: IRootState) => state);

	if (!userOrganizationProfile || !userOrganizationProfile?.user) {
		return <PageLoader />;
	}

	const isAdministrator = userOrganizationProfile?.user?.isAdministrator;

	const TABS: ISettingsTabs = {
		USER: { value: 'user' },
		SECTION_ACCOUNT: { value: 'section-account', disabled: true },
		ORGANIZATIONS: { value: 'organizations' },
		ORGANIZATION_PROFILE: { value: 'organization-profile' },
		BILLING: { value: 'billing', hide: !isAdministrator },
		SECURITY: { value: 'security', hide: !isAdministrator },
		// LANDING_PAGE: { value: 'landing-page', hide: !isAdministrator },
		SECTION_USERS_CONTENT: {
			value: 'section-user-content', hide: !isAdministrator, disabled: true,
		},
		ACTIVE_USERS: { value: 'active-users', hide: !isAdministrator },
		INVITATIONS: { value: 'invitations', hide: !isAdministrator },
		REPORT: { value: 'report', hide: !isAdministrator },
		BACKUP: {
			value: 'backup',
			help: t('urls.zendesk'),
			onClick: () => {
				persistor.pause();
				dispatch(Creators.MODAL.showModal(routes.MODAL_BACKUP.path));
			},
		},
		LOGOUT: { value: 'logout', onClick: onLogout },
	};

	Object.keys(TABS).forEach((key) => {
		TABS[key].label = t(`settings.tabs.${TABS[key].value}`);
		TABS[key].icon = <Icon icon={TABS[key].value} />;
		TABS[key].iconPosition = 'start';
	});

	return (
		<GenericGrid className="s-grid-page" paddingTop={6}>
			<SBox
				id="settings-page"
				className="settings__page inner w-grid-1"
				loading={!userOrganizationProfile || !userOrganizationProfile?.user}
				modus="page"
			>
				<STabs
					tabs={Object.values(TABS)}
					className="settings__tabs"
					active={TABS.USER.value}
					id="settings-tabs"
					historyMode="primary"
					orientation="vertical"
					textColor="secondary"
					TabIndicatorProps={{ sx: { display: 'none' } }}
					sx={{
						sTabs: {
							display: 'flex',
							'.MuiTab-root': { justifyContent: 'flex-start' },
						},
					}}
					TabsProps={{
						xs: 12,
						sm: 4,
						md: 3,
					}}
					TabPanelProps={{
						xs: 12,
						sm: 8,
						md: 9,
						sx: {
							height: 'auto',
							borderLeft: 1,
							borderColor: 'divider',
							paddingX: { xs: 0, sm: 3, md: 12 },
						},
					}}
				>
					<STabPanel value={TABS.USER.value}>
						<User />
					</STabPanel>
					<STabPanel value={TABS.ORGANIZATIONS.value}>
						<Organizations.List />
					</STabPanel>
					<STabPanel value={TABS.ORGANIZATION_PROFILE.value}>
						<Organizations.Profile />
					</STabPanel>
					<STabPanel value={TABS.BILLING.value} hide={!isAdministrator}>
						<Billing />
					</STabPanel>
					<STabPanel value={TABS.SECURITY.value} hide={!isAdministrator}>
						<Account />
					</STabPanel>
					{/* <STabPanel value={TABS.LANDING_PAGE.value} hide={!isAdministrator}>
							<Organizations.LandingPage />
						</STabPanel> */}
					<STabPanel value={TABS.ACTIVE_USERS.value} hide={!isAdministrator}>
						<ActiveUsers />
					</STabPanel>
					<STabPanel value={TABS.INVITATIONS.value} hide={!isAdministrator}>
						<Invites />
					</STabPanel>
					<STabPanel value={TABS.REPORT.value} hide={!isAdministrator}>
						<ReportedContent />
					</STabPanel>
				</STabs>
			</SBox>
		</GenericGrid>

	);
};

const Translation = withTranslation('common')(_Settings);

const Settings = () => (
	<React.Suspense fallback={<PageLoader />}>
		<Translation />
	</React.Suspense>
);

export default Settings;
