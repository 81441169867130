import React, { Suspense, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TableColumn } from 'react-data-table-component';
import { WithTranslation, withTranslation } from 'react-i18next';
import SpinnerLDSRipple from '@components/Shared/SpinnerLDSRipple';

import { IRootState } from 'src/store';
import { SButton } from '@components/Buttons';
import { APIIntegrations } from '@services/apis';
import { IUserIntegration } from 'src/@types/IUser';
import TableSearch from '@components/DataTable/TableSearch';
import { Creators } from '@actions';
import { SBox } from '@components/Background/SBox';
import { handleAxiosErrors } from '@services/auth';
import { goToLink } from '@utils/ReactUtils';

type TProps = WithTranslation
const _Integrations = (props: TProps) => {
	const { t } = props;
	const { userOrganizationProfile } = useSelector((state: IRootState) => state);
	const integrations = userOrganizationProfile?.integrations || [];

	const dispatch = useDispatch();

	const [inProgress, setInProgress] = useState('');

	const removeIntegration = async (integration: IUserIntegration) => {
		try {
			const { app } = integration;
			setInProgress(app);

			await APIIntegrations.deleteIntegration(app);

			const index = integrations.findIndex((item) => item.app === app);

			if (index > -1) {
				integrations[index].isLoggedIn = false;
			}

			dispatch(Creators.USER_ORGANIZATION.successUser({ integrations }));
		} catch (err) {
			handleAxiosErrors(err);
		} finally {
			setInProgress('');
		}
	};

	const columns: TableColumn<IUserIntegration>[] = [
		{
			name: '',
			sortable: false,
			maxWidth: '80px',
			cell: (row) => (
				<div>
					<img
						alt=""
						width="60"
						height="80"
						src={row.image}
					/>
				</div>
			),
		},
		{
			name: t('global.app'),
			sortable: true,
			maxWidth: '600px',
			cell: (row) => (
				<div>
					{t(`apps.${row.app}.name`)}
				</div>
			),
		},
		{
			name: t('global.actions'),
			grow: 0,
			minWidth: '200px',
			center: true,
			cell: (row) => (
				<SButton
					type="button"
					variant="outlined"
					color="primary"
					isLoading={inProgress === row.app}
					onClick={() => {
						if (row.isLoggedIn) {
							removeIntegration(row);
						} else {
							dispatch(Creators.MODAL.handleModal({
								pagePath: window.location.href,
							}));
							goToLink(row.loginLink);
						}
					}}
				>
					{row.isLoggedIn ? t('global.logout') : t('global.connect')}
				</SButton>
			),
		},
	];

	return (
		<SBox className="user-integrations">
			<TableSearch
				data={integrations}
				columns={columns}
				progressPending={!columns}
			/>
		</SBox>

	);
};

const Translation = (withTranslation('common')(_Integrations));

const Integrations = () => (
	<Suspense fallback={<SpinnerLDSRipple />}>
		<Translation />
	</Suspense>
);

export default Integrations;
