// @ts-nocheck
import React from 'react';
import lodash from 'lodash';
import { WithTranslation, withTranslation } from 'react-i18next';
import { showTab } from '@utils/helpers';
import URLS from '@constants/URLS';
import { TIdeaStatus } from 'src/@types/IIdea';
import { TPlatform } from 'src/@types/IPlatform';
import { Box, Tooltip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { StickerIdeaBox } from './styles';

interface IOwnProps {
	readonly isCover?: boolean
	readonly hasBadges?: boolean
	readonly hasSponsorships?: boolean
	isFinished: boolean
	isArchived: boolean
	readonly status: TIdeaStatus
	readonly type: TPlatform
	readonly id: string
}

declare type TProps = IOwnProps & WithTranslation

const SStickerIdea = (props: TProps) => {
	const {
		t,
		id = '',
		hasBadges,
		hasSponsorships,
		status = 'pendente',
		type = '',
		isCover,
		isFinished,
		isArchived,
	} = props;

	const navigate = useNavigate();

	const handleClick = (e, type) => {
		const ideaPage = document.querySelector('#idea-page');
		e.stopPropagation();

		const href = `${URLS.IDEAS}/${id}`;

		switch (type) {
			case 's':
				if (hasSponsorships && ideaPage) { showTab({ tabId: 'supports-tab' }); }
				if (hasSponsorships && !ideaPage) {
					navigate(`${href}?tab=supports-tab`);
				}
				break;
			case 'b':
				if (ideaPage && hasBadges) { showTab({ tabId: 'badges-tab' }); }
				if (!ideaPage && hasBadges) {
					navigate(`${href}?tab=badges-tab`);
				}
				break;
			default:
				break;
		}
	};

	return (type === 'Challenge' && !isArchived) ? (
		<Box id="idea-status" display="flex" data-tut="idea-status">
			<Tooltip
				placement="top"
				open={!isCover && undefined}
				classes={{ popper: status, tooltip: 'sticker' }}
				arrow
				title={t(`idea.status.${status}`)}
			>
				<StickerIdeaBox
					className={`sticker-idea status ${isCover ? 'cover' : ''} ${status}`}
				>
					{isCover ? t(`idea.status.${status}`) : t(`idea.status.${status}`).getInitials()}
				</StickerIdeaBox>
			</Tooltip>
			<Tooltip
				placement="top"
				arrow
				open={!isCover && undefined}
				classes={{ popper: hasSponsorships ? 'sponsorship' : 'pendente', tooltip: 'sticker' }}
				title={t('global.sponsorship')}
			>
				<StickerIdeaBox
					className={`sticker-idea  ml-2 mr-2 ${hasSponsorships ? 'sponsorships' : ''} ${isCover ? 'cover' : ''}`}
					onClick={(e) => handleClick(e, 's')}
				>
					{isCover
						? t('global.sponsorships')
						: t('global.sponsorships').getInitials()}
				</StickerIdeaBox>
			</Tooltip>
			<Tooltip
				placement="top"
				arrow
				open={!isCover && undefined}
				classes={{ popper: hasBadges ? 'badges' : 'pendente', tooltip: 'sticker' }}
				title={t('global.badges')}
			>
				<StickerIdeaBox
					className={`sticker-idea ${hasBadges ? 'badges' : ''} ${isCover ? ' cover' : ''}`}
					onClick={(e) => handleClick(e, 'b')}
				>
					{isCover ? t('global.badges') : t('global.badges').getInitials()}
				</StickerIdeaBox>
			</Tooltip>
		</Box>
	) : (
		<StickerIdeaBox
			data-tut="idea-status"
			id={`sticker-status_${lodash.uniqueId(id)}`}
			className={`sticker-idea status just ${status}`}
		>
			{isArchived ? t('idea.status.arquivada') : t(`idea.status.${status}`)}
		</StickerIdeaBox>
	);
};
const Translation = withTranslation('common')(SStickerIdea);

const StickerIdea = (props: IOwnProps) => (
	<React.Suspense fallback={<div />}>
		{typeof props.status === 'undefined' ? <div /> : <Translation {...props} />}
	</React.Suspense>
);

export { StickerIdea };
