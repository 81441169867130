/* eslint-disable no-underscore-dangle */
// @ts-nocheck
import React, {
	Suspense, useEffect, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { WithTranslation, withTranslation } from 'react-i18next';
import ModalHeader from '@modules/Modal/ModalHeader';
import { FLOWS } from '@constants/GLOBAL';
import { IRootState } from 'src/store';
import { DialogBox } from '@modules/Modal/Components';
import { IPaginatedRequest, IPagination, ITemplate } from 'src/@types/IShared';
import { handleAxiosErrors } from '@services/auth';
import { Creators } from '@actions';
import { DynamicCircle } from '@components/DynamicCircle';
import ModalFeedback, { IFeedbackProps } from '@components/Modals/ModalFeedback';
import type { IModal } from '@modules/Modal/types';
import { createBackground } from '@utils/helpers';
import { DialogContent, DialogTitle, List, ListItem } from '@mui/material';
import { BuildTour } from '@components/STour';
import { useTour } from '@reactour/tour';
import { W_GRID } from '@components/Grids/GenericGrid';
import RedactorText from '../../Shared/RedactorText';
import { UserUpdated } from '../../Shared/UserUpdated';
import SpinnerLDSRipple from '../../Shared/SpinnerLDSRipple';
import { HelpButton } from '../../Buttons/HelpButton';
import FiltersNavBar from '../../NavBar/FiltersNavBar';
import { APIIdeas, APIPlatforms } from '../../../services/apis';

declare type TProps = IModal & WithTranslation;

const MModalTemplates = (props: TProps) => {
	const { t, routes } = props;
	const tour = useTour();
	const dispatch = useDispatch();
	const { ideas, platforms, shared } = useSelector((state: IRootState) => state);
	const { flow } = useSelector((state: IRootState) => state.modal);
	const [templates, setTemplates] = useState <IPagination<ITemplate>>({ docs: [], isLoading: true });
	const [feedbackProps, setFeedbackProps] = useState<IFeedbackProps>({ open: false });
	const [inProgress, setInProgress] = useState<string | undefined>();

	const disabled = {
		idea: ideas?.mode === 'view',
		brainstorm: platforms.mode === 'view',
		challenge: platforms.mode === 'view',
	};
	const cursor = disabled[flow] || inProgress === 'CHANGE' ? 'auto' : 'pointer';
	const rootPath = {
		idea: ideas.nome,
		brainstorm: platforms.nome,
		challenge: platforms.nome,
	};

	const TOUR_STEPS = BuildTour.steps(['template.add']);

	const loadTemplates = async (params: IPaginatedRequest, startTour?: boolean) => {
		setTemplates({ ...templates, isLoading: true });
		try {
			if (flow !== FLOWS.IDEA) {
				dispatch(Creators.MODAL.handleModal({ flow: params.filter?.toLowerCase() }));
			}

			let response;
			switch (flow) {
				case FLOWS.IDEA:
					response = await APIIdeas.getTemplates(params);
					break;
				case FLOWS.CHALLENGE:
				case FLOWS.BRAINSTORM:
					response = await APIPlatforms.getTemplates(params);
					break;
				default:
					break;
			}
			setTemplates({ ...response.data, isLoading: false });

			if (startTour) {
				BuildTour.setTour(tour, TOUR_STEPS, { currentStep: 0 });
			}
		} catch (err) {
			handleAxiosErrors(err);
		}
	};

	const onChangeTemplate = (template) => {
		tour.setIsOpen(false);
		switch (flow) {
			case FLOWS.IDEA:
				if (template?.isDefault) {
					dispatch(Creators.MODAL.showModal(routes?.MODAL_IDEA_TEMPLATE.path, { template }));
				} else {
					setInProgress(template?._id || 'undefined');
					dispatch(Creators.IDEAS.handleIdeaTemplate({
						templateId: template?._id,
						ideaId: ideas._id,
						platformId: ideas.plataforma?._id || undefined,
						redirectPath: routes.MODAL_IDEA.path,
						callback: () => setInProgress(undefined),
					}));
				}
				break;
			case FLOWS.BRAINSTORM:
			case FLOWS.CHALLENGE:
				if (template?.isDefault) {
					dispatch(Creators.MODAL.showModal(routes?.MODAL_PLATFORM_TEMPLATE.path, { template }));
				} else {
					setInProgress(template?._id || 'undefined');
					dispatch(Creators.PLATFORMS.handlePlatformTemplate({
						templateId: template?._id,
						platformId: platforms._id,
						redirectPath: routes.MODAL_PLATFORM.path,
						callback: () => setInProgress(undefined),
					}));
				}
				break;
			default:
				break;
		}
	};

	const filters = [
		{ value: 'Brainstorm', label: t('global.brainstorms') },
		{ value: 'Challenge', label: t('global.challenges') },
	];

	useEffect(() => {
		if (typeof flow === 'string') {
			loadTemplates({
				page: 1,
				sort: 'last-first',
				limit: 8,
				filter: flow !== FLOWS.IDEA ? flow.capitalizeFirstLetter() : undefined,
			}, shared?.tour === flow);
		}
	}, []);

	return (
		<DialogContent id="TemplatesDialog">
			<ModalHeader
				fullScreen
				hideClose
				BreadcrumbProps={{ className: W_GRID.W_GRID_2 }}
				icon={flow}
				pathParams={{
					idea: rootPath[flow] || '...',
					platform: rootPath[flow] || '...',
				}}
			/>
			{typeof flow === 'string'
				&& (
					<DialogBox className="s-dialog-box w-grid-2 inner">
						<DialogBox className="s-dialog-title">
							<DialogTitle variant="h1">
								{t('templates.title', { flow })}
							</DialogTitle>
							<HelpButton
								size="medium"
								href={t(`urls.${flow}`)}
								onClick={() => BuildTour.setTour(tour, TOUR_STEPS, { currentStep: 0 })}
							/>
						</DialogBox>
						<DialogBox className="s-dialog-content">
							<FiltersNavBar
								id="templates-nav"
								onSearch={loadTemplates}
								onUpdate={loadTemplates}
								filters={flow !== FLOWS.IDEA ? filters : undefined}
								params={{
									page: 1,
									sort: 'last-first',
									limit: 8,
									filter: flow !== FLOWS.IDEA ? flow.capitalizeFirstLetter() : undefined,
								}}
							/>
						</DialogBox>
						<DialogBox className="s-dialog-content">
							<List className="list templates_blank">
								<ListItem
									data-position="right"
									aria-hidden
									className={`list-item ${inProgress === 'undefined' ? 'loading' : ''}`}
								>
									<DynamicCircle
										icon="plus"
										size="x-large"
										className="default-colors"
										clickable={!inProgress}
										onClick={() => onChangeTemplate(undefined)}
									/>
									<div className="container-content">
										<div className="title">
											{t('templates.blank.title', { flow: flow.capitalizeFirstLetter() })}
										</div>
										<div className="content-text">
											{t('templates.blank.summary')}
										</div>
									</div>
								</ListItem>
								{templates?.isLoading || !templates || !Array.isArray(templates.docs) ? (
									<SpinnerLDSRipple style={{ minHeight: '50vh' }} />
								)
									: templates?.docs?.map((template) => (
										<ListItem
											className={`list-item ${inProgress === template._id ? 'loading' : ''}`}
											aria-hidden
											key={template.slug}
											data-tut="template-add"
											onClick={() => onChangeTemplate(template)}
											style={{ cursor }}
										>
											<DynamicCircle
												icon={template.slug || 'template'}
												className="default-colors"
												action={disabled[flow] ? undefined : t('global.toSelect')}
												size="x-large"
												color={template.isDefault ? template.color : undefined}
												background={template.isDefault ? createBackground(template.color) : undefined}
											/>
											<div className="container-content">
												<div className="d-flex">
													<div className="title">
														{template?.templateNome || '...'}
													</div>
												</div>
												<RedactorText
													html={template.templateDescricao || template.descricaoTemplate}
													hasToggle
													className="content-text"
												/>
												{(template.autor && template.updatedAt) && (
													<UserUpdated
														className="mt-20px"
														profile={template.autor}
														updatedAt={template.updatedAt}
													/>
												)}
											</div>
										</ListItem>
									))}
							</List>
						</DialogBox>
					</DialogBox>
				)}
			{feedbackProps.open && (
				<ModalFeedback
					id="templates"
					onRequestClose={() => setFeedbackProps({ open: false })}
					{...feedbackProps}
				/>
			)}
		</DialogContent>
	);
};

const Translation = withTranslation('common')(MModalTemplates);

const ModalTemplates = (props: IModal) => (
	<Suspense fallback={<DialogContent />}>
		<Translation {...props} />
	</Suspense>
);
export default ModalTemplates;
