/* eslint-disable max-len */
/* eslint-disable react/no-danger */
import React, { useState } from 'react';
import {
	Box, Grid, Typography,
} from '@mui/material';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useTranslation, withTranslation, WithTranslation } from 'react-i18next';
import { HelpButton, SButton } from '@components/Buttons';
import { IAnswer, ISavedQuestion } from 'src/@types/IIdea';
import { IRootState } from 'src/store';
import { APITrends } from '@services/apis';
import { STabs } from '@components/STabs';
import { STabPanel } from '@components/STabs/STabPanel';
import Icon from '@components/Shared/Icon';
import { handleAxiosErrors } from '@services/auth';
import { Creators } from '@actions';
import SeeResults from './SeeResults';
import SavedQuestionList from './SavedQuestionsList';
import QuestionAnswerList from './QuestionAnswerList';

interface QAProps {
	ideaId: string;
}

export interface GenerateAnswersParams {
	text: string
	callback?: () => void
	onSuccess?: () => void
}

declare type TProps = QAProps & WithTranslation

const _QA = (props: TProps) => {
	const { ideaId } = props;
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { userOrganizationProfile } = useSelector((state: IRootState) => state);
	const question = useSelector((state: IRootState) => state?.ideas?.question) || '';

	const [answers, setAnswers] = useState<IAnswer[]>([]);
	const [textName, setTextName] = useState<string>('');
	const [generatedQuestions, setGeneratedQuestions] = useState<object[]>([]);

	const [isLoading, setLoading] = useState(false);
	const [seeResults, setSeeResults] = useState<boolean>(false);
	const [inProgress, setInProgress] = useState<string | undefined>();
	const [modus, setModus] = useState<'answers' | undefined>();

	const [savedQuestionResponses, setSavedQuestionResponses] = useState([]);

	const generateAnswers = async ({ text, callback, onSuccess }: GenerateAnswersParams) => {
		try {
			setLoading(true);

			setAnswers([]);
			setGeneratedQuestions([]);

			const response = await APITrends.getQAresponse({ text, id: ideaId });

			setAnswers(response.data.answers);

			const underFiftyScore = response.data.answers.find((item: any) => parseInt((item.score * 100).toFixed(0), 10) < 50);
			if (underFiftyScore) {
				setTextName(underFiftyScore.answer);
			}

			setSavedQuestionResponses(response.data.answers);

			setLoading(false);
			onSuccess?.();
		} catch (err) {
			handleAxiosErrors(err);
		} finally {
			if (typeof callback === 'function') {
				callback();
			}
		}
	};

	const getGeneratedQuestions = async () => {
		setLoading(true);

		setAnswers([]);
		setGeneratedQuestions([]);
		const result = await APITrends.getGeneratedQuestions({ id: ideaId });

		setGeneratedQuestions(result.data);
		setLoading(false);
	};

	const TABS = {
		question: {
			value: 'question',
			label: t('questions.question'),
			icon: <Icon icon="questionSignal" />,
			iconPosition: 'start',
		},
		savedQuestions: {
			value: 'saved_questions',
			label: t('questions.savedQuestions'),
			icon: <Icon icon="favorite" />,
			iconPosition: 'start',
		},
	};

	return (
		<STabs
			id="qa-tabs"
			// @ts-ignore
			tabs={Object.values(TABS)}
			active={TABS.question.value}
			variant="standard"
			textColor="secondary"
			TabIndicatorProps={{ sx: { display: 'none' } }}
			TabPanelProps={{ sx: { marginY: 3 } }}
		>
			<STabPanel value={TABS.question.value} sx={{ width: '100%' }} onShow={() => setSeeResults(false)}>
				<Grid container style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
					{!seeResults ? (
						<>
							<Grid item sx={{ display: 'flex' }} paddingBottom={3}>
								<Typography variant="h5" className="QA-title">
									{t('questions.makeAQuestion')}
								</Typography>
								<HelpButton href="# " size="small" />
							</Grid>
							<Grid item>
								<input
									id="qa-question"
									value={question}
									placeholder="Search"
									onChange={(e: any) => dispatch(Creators.IDEAS.updateIdeas({ question: e.target.value }))}
									className="QA-question"
									autoComplete="off"
								/>
							</Grid>

							<div className="QA-note">
								{t('questions.noKeywords')}
							</div>
							<div className="QA-buttons-group">
								{/* <Button
									variant="outlined"
									onClick={getGeneratedQuestions}
									className="QA-button"
								>
									{t('questions.generateQuestions')}
								</Button>
								<div className="QA-buttons-space" /> */}
								<SButton
									className="QA-button"
									variant="outlined"
									isLoading={inProgress === 'generate-answer'}
									disabled={question?.length < 6}
									onClick={() => {
										setInProgress('generate-answer');
										generateAnswers({
											text: question,
											callback: () => setInProgress(undefined),
											onSuccess: () => setModus('answers'),
										});
									}}
								>
									{t('questions.generateAnswers')}
								</SButton>
							</div>
							{ modus === 'answers' && (
								<>
									<hr style={{ color: '#797878', marginBottom: '35px' }} />
									<QuestionAnswerList
										ideaId={ideaId}
										textName={textName}
										isLoading={isLoading}
										question={question}
										answerList={answers}
										questionsList={generatedQuestions}
										generateAnswers={generateAnswers}
									/>
								</>
							)}
						</>
					) : (
						<SeeResults
							isLoading={isLoading}
							textName={textName}
							list={savedQuestionResponses}
							setSeeResults={setSeeResults}
						/>
					)}
				</Grid>
			</STabPanel>

			<STabPanel value={TABS.savedQuestions.value} sx={{ width: '100%' }}>
				<Box sx={{ display: 'flex', justifyContent: 'flex-start', width: '100%' }}>
					{seeResults
						? (
							<SeeResults
								isLoading={isLoading}
								textName={textName}
								list={savedQuestionResponses}
								setSeeResults={setSeeResults}
							/>
						) : (
							<SavedQuestionList
								userId={userOrganizationProfile?._id || ''}
								ideaId={ideaId}
								setSeeResults={setSeeResults}
								// @ts-ignore
								generateAnswers={generateAnswers}
							/>
						)}
				</Box>
			</STabPanel>
		</STabs>
	);
};

function mapStateToProps(state: IRootState) {
	return state;
}

const Translation = connect(
	mapStateToProps,
)(withTranslation('common')(_QA));

const QA = (props: QAProps) => (
	<Translation {...props} />
);

export default QA;
