// Adapted from https://echarts.apache.org/examples/en/editor.html?c=bar-simple
// https://echarts.apache.org/examples/en/editor.html?c=bar-data-color
// https://echarts.apache.org/examples/en/editor.html?c=bar1
import React, { Suspense } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import SpinnerLDSRipple from '@components/Shared/SpinnerLDSRipple';
import ReactEcharts from 'echarts-for-react';

interface IOwnProps {
}

declare type TProps = IOwnProps & WithTranslation;

// [
// 	1992: {
// 		patent: 34,
// 		article: 35,
// 	},
// 	1193: {
// 		patent: 34,
// 		article: 35,
// 	}
// ]

const _GroupGraph = (props: TProps) => {
	const option = {
		xAxis: {
			type: 'category',
			show: false,
			// years
			data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
		},
		yAxis: {
			type: 'value',
			show: false,
		},
		// tooltip: {
		// 	trigger: 'axis',
		// },
		// toolbox: {
		// 	show: true,
		// 	feature: {
		// 		dataView: { show: true, readOnly: false },
		// 	},
		// },
		series: [
			{
				name: 'article',
				// quantity
				data: [120, 200, 150, 80, 70, 110, 2],
				type: 'bar',
				color: 'rgb(76, 122, 144)',
			},
			{
				name: 'patent',
				// quantity
				data: [20, 100, 150, 40, 50, 10, 8],
				type: 'bar',
				color: 'rgb(253, 114, 89)',
			},
		],
	};

	return (
		<ReactEcharts
			// ref={(e) => setRef(e)}
			option={option}
			// notMerge
			// echarts={echarts}
			// showLoading={isLoading}
			lazyUpdate
			// opts={{ height: 60, width: 200 }}
			style={{ height: 60, width: 200 }}
			className=""
		/>
	);
};

const Translation = withTranslation('common')(_GroupGraph);
const GroupGraph = (props: IOwnProps) => (
	<Suspense fallback={<SpinnerLDSRipple />}>
		<Translation {...props} />
	</Suspense>
);

export { GroupGraph };
