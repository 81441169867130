// @ts-nocheck
import React, { Suspense, useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import URLS from '@constants/URLS';
import { IPaginatedRequest, IPagination } from 'src/@types/IShared';
import { UserAvatar } from '@components/Avatar/UserAvatar';
import { IUser } from 'src/@types/IUser';
import { SButton } from '@components/Buttons';
import { BoxProps, Grid } from '@mui/material';
import { SBox } from '@components/Background/SBox';
import { EvaluateIcons } from '../Background/EvaluateIcons';
import { IListRequest } from './types';

interface EvaluatorsListProps extends BoxProps {
	loadMore?: (params: IListRequest) => void
	evaluators: IPagination<IUser>
	ideaId: string
	queryParams?: IPaginatedRequest
}

declare type TProps = EvaluatorsListProps & WithTranslation

const EEvaluatorsList = (props: TProps) => {
	const {
		t, evaluators, loadMore, ideaId, queryParams, ...rest
	} = props;

	const [isLoadingMore, setIsLoadingMore] = useState(false);

	const loadMoreHandler = () => {
		setIsLoadingMore(true);
		loadMore({
			...queryParams,
			page: evaluators.nextPage,
			callback: () => setIsLoadingMore(false),
		});
	};

	return (
		<SBox
			id="evaluatorsList"
			loading={!evaluators || (evaluators?.isLoading && !isLoadingMore) || !evaluators.docs}
			empty={evaluators.docs.length === 0 ? 'evaluations' : undefined}
			{...rest}
		>
			<ul className="members__list">
				{evaluators.docs.map((member, index) => (
					// eslint-disable-next-line react/no-array-index-key
					<li key={`key-${index}-${member._id}`}>
						<div className="d-flex">
							<UserAvatar
								profile={member.moderador}
								size="middle"
								hasPopover
								initial={member.moderador.user._nome || member.moderador.user.nome}
								target={`popover_evaluator_${member.moderador.user._id}`}
								hasHref
							/>
							<div className="member__info">
								<Link to={`${URLS.PROFILE}/${member.moderador._id}`} className="name">
									{member.moderador.user._nome || member.moderador.user.nome}
								</Link>
								{member.lastEvaluation && (
									<div className="mr-1 font-light">
										<span className="mr-1">{t('idea.evaluatedIn', { date: new Date(member.lastEvaluation) })}</span>
									</div>
								)}
							</div>
						</div>

						{/* <StickerIdea status={evaluators.status} /> */}
						<EvaluateIcons
							averageEvaluations={member.avaliacoes}
							ideaId={ideaId}
							hasPopover
							prefixTarget={`${member.moderador._id}`}
						/>
					</li>
				))}
			</ul>
			<Grid container className="footer__list" marginY={6}>
				<Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
					{evaluators.hasNextPage && typeof loadMore === 'function'
						? (
							<SButton
								type="button"
								color="secondary"
								variant="outlined"
								isLoading={isLoadingMore}
								onClick={loadMoreHandler}
							>
								{t('global.loadMore')}
							</SButton>
						)
						: <span>{t('messages.loadedAll.evaluations')}</span>}
				</Grid>
			</Grid>
		</SBox>
	);
};

const Translation = withTranslation('common')(EEvaluatorsList);

const EvaluatorsList = (props: EvaluatorsListProps) => (
	<Suspense fallback={<div />}>
		<Translation {...props} />
	</Suspense>
);

export { EvaluatorsList };
