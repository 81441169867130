/* eslint-disable dot-notation */
// @ts-nocheck   DynamicForm
import React, { Suspense, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { Trans, withTranslation, WithTranslation } from 'react-i18next';
import { Creators } from '@actions';
import { isEmpty } from '@utils/ReactUtils';
import {
	Form, SFormGroup, SInputLabel } from '@components/DynamicForm/ComponentsCore';
import { SButton } from '@components/Buttons';
import { SPasswordField, STextField } from '@forms/Components';
import { ColorPalette } from '@components/Shared/ColorPalette';
import { APIUser } from '@services/apis';
import { DynamicForm } from '@components/DynamicForm';
import { inputTypes } from '@components/DynamicForm/FieldsTypes';
import SpinnerLDSRipple from '@components/Shared/SpinnerLDSRipple';
import { TObject, TLanguages, IPagination } from 'src/@types/IShared';
import { getValidationErrors } from '@utils/yup';
import { IRootState } from 'src/store';
import ImageInput from '@components/Inputs/ImageInput';
import URLS from '@constants/URLS';
import { auth, handleAxiosErrors, onLogout } from '@services/auth';
import i18next from 'i18next';
import { passwordValidation } from '@forms/Components/SPasswordField';
import { maxLength } from '@constants/GLOBAL';
import ModalFeedback, { IFeedbackProps } from '@components/Modals/ModalFeedback';
import routes from '@routes/modal';
import { IUserOrganization } from 'src/@types/IUser';
import { Box, Divider, Grid } from '@mui/material';
import { SSelect } from '@components/DynamicForm/Components/SSelect';
import { SSelectOptionLabel } from '@components/DynamicForm/Components/SSelect/Components';
import { SLink } from '@components/Buttons/SLink';
import { SBox } from '@components/Background/SBox';
import { SToast } from '@modules/SToast';
import Icon from '@components/Shared/Icon';

const languagesOptions = {
	'pt-BR': { value: 'pt-BR', label: <SSelectOptionLabel label="Português (Brasil)" details="Portuguese (Brazil)" /> },
	'en-US': { value: 'en-US', label: <SSelectOptionLabel label="English (United States)" details="English (United States)" /> },
};

interface IFeedbackMatch {
	value: string
}

declare type TProcess = 'pwd' | 'submit' | 'email' | 'image' | undefined | TLanguages

declare type TProps = WithTranslation;

const UProfile = (props: TProps) => {
	const { t } = props;
	const dispatch = useDispatch();

	const { organization, organizations, user } = useSelector(
		(state: IRootState) => state.userOrganizationProfile,
	) || {};

	const { userOrganizationProfile } = useSelector(
		(state: IRootState) => state,
	);

	const [isHiddenEmailPwd, setIsHiddenEmailPwd] = useState(true);
	const [errors, setErrors] = useState<TObject>({});
	const [password, setPassword] = useState({ new: '', current: '' });
	const [email, setEmail] = useState({ password: '' });
	const [feedbackProps, setFeedbackProps] = useState<IFeedbackProps<IFeedbackMatch>>({ open: false });

	const [inProgress, setInProgress] = useState<TProcess>();
	const [users, setUsers] = useState<IPagination<IUserOrganization>>({ docs: [], isLoading: true });

	const onErrorHandler = (field: string) => {
		if (!isEmpty(errors)) delete errors[field];
	};

	const setFieldError = (key: string, error: string) => {
		setErrors({ ...errors, [key]: error });
	};

	// Does not make requests
	const onUserHandler = (key: string, value: string) => {
		onErrorHandler(key);
		switch (key) {
			case 'current':
			case 'new':
				setPassword({ ...password, [key]: value });
				break;
			case 'password':
				setEmail({ ...email, [key]: value });
				break;
			case 'email':
			default:
				dispatch(Creators.USER_ORGANIZATION.handleUser({ [key]: value }));
				break;
		}
	};

	const onLanguageHandler = ({ target }) => {
		localStorage.setItem('i18nextLng', target.value);
		i18next.changeLanguage(target.value);
		dispatch(Creators.USER_ORGANIZATION.handleUser({ idioma: target.value }));
	};

	const onUserImageHandler = ({ formData }) => {
		setInProgress('image-user-profile');
		dispatch(Creators.USER_ORGANIZATION.handleUserImage({
			formData,
			callback: () => setInProgress(undefined),
		}));
	};

	const onAvailableOrganization = () => {
		const orgs = organizations?.map((item) => item._id);
		const target = orgs?.filter((item) => item !== organization?._id)[0];

		if (target) {
			dispatch(Creators.USER_ORGANIZATION.changeOrganization(target));
			return;
		}
		onLogout();
	};

	const onDeleteAccountHandler = async (match?: IFeedbackMatch) => {
		try {
			setFeedbackProps((prevState) => ({ ...prevState, isLoading: true }));
			if (!userOrganizationProfile?.user?.OAuth?.active && (!match?.value || match?.value === '')) {
				setErrors({ match: t('messages.requiredField') });
				return;
			}

			await APIUser.deleteAccount(user?._id, match?.value);

			onAvailableOrganization();

			// setFeedbackProps((prevState) => ({
			// 	...prevState,
			// 	title: t('feedback.userAccount.delete.message.title'),
			// 	text: t(
			// 		'feedback.userAccount.delete.message.text',
			// 		{ email: user?.email || '' },
			// 	),
			// 	hideLeftButton: true,
			// 	container: undefined,
			// 	rightButtonText: t('global.close'),
			// 	onRightButton: onAvailableOrganization,
			// 	shouldCloseOnEsc: false,
			// }));
		} catch (err) {
			handleAxiosErrors(err);
		} finally {
			setFeedbackProps((prevState) => ({ ...prevState, isLoading: false }));
		}
	};

	const onSubmit = async () => {
		try {
			const profile = {
				name: user.nome,
				apelido: user._nome,
				cor: user.cor,
				idioma: user.idioma,
				sobre: user.sobre || '',
			};

			if (errors?.nome) return;
			setInProgress('submit');
			const schema = Yup.object().shape({
				nome: Yup.string().required(t('messages.requiredField')),
			});

			await schema.validate(user, {
				abortEarly: false,
			});

			await APIUser.putProfile(profile);
			SToast.success(t('messages.success.profileUpdated'));
		} catch (err) {
			if (err instanceof Yup.ValidationError) {
				setErrors(getValidationErrors(err));
			} else handleAxiosErrors(err);
		} finally {
			setInProgress(undefined);
		}
	};

	const onSubmitPassword = async (e) => {
		try {
			e.preventDefault();
			setInProgress('pwd');
			const schema = Yup.object().shape({
				current: Yup.string().required(t('messages.requiredField')),
				new: Yup.string().min(6).required(t('messages.requiredField')),
			});

			if (passwordValidation(password.new)?.level !== 'strong') {
				setErrors((prevState) => ({
					...prevState,
					new: t('messages.password.level.error'),
				}));
				return;
			}

			await schema.validate(password, {
				abortEarly: false,
			});

			await APIUser.patchPasswordProfile(password.new, password.current);

			SToast.success(t('messages.password.changed'));
		} catch (err) {
			if (err instanceof Yup.ValidationError) {
				setErrors(getValidationErrors(err));
				return;
			}
			if (err?.response?.data?.err) {
				if (err?.response?.data?.err.includes('messages.password.noMatch')) {
					setFieldError('current', t(err?.response?.data?.err));
					return;
				}
				setFieldError('new', t(err?.response?.data?.err));
				return;
			}
			handleAxiosErrors(err);
		} finally {
			setInProgress(undefined);
		}
	};

	const onSubmitEmail = async (e) => {
		try {
			e.preventDefault();
			if (errors?.password || errors?.email) return;
			setInProgress('email');
			const data = { email: user?.email, password: email.password };
			const schema = Yup.object().shape({
				password: Yup.string().required(t('messages.requiredField')),
				email: Yup.string().email(t('messages.email.isInvalid')).required(t('messages.requiredField')),
			});

			await schema.validate(data, {
				abortEarly: false,
			});
			// Token JWT to new email
			const response = await APIUser.patchEmailProfile(data.email, data.password);
			auth.setToken(response.data.token);
			window.location.reload();

			SToast.success(t('messages.email.changed'));
		} catch (err: any) {
			if (err instanceof Yup.ValidationError) {
				setErrors(getValidationErrors(err));
				return;
			}
			if (err?.response?.data?.err) {
				if (err?.response?.data?.err.includes('password')) {
					setFieldError('password', t(err?.response?.data?.err));
				}
				if (err?.response?.data?.err.includes('email')) {
					setFieldError('email', t(err?.response?.data?.err));
				}
				return;
			}
			handleAxiosErrors(err);
		} finally {
			setInProgress(undefined);
		}
	};

	const loadParticipants = () => {
		setUsers({ ...users, isLoading: true });
		dispatch(Creators.SHARED.getParticipants({
			onSuccess: (response: IPagination<IUserOrganization>) => {
				setUsers({ ...response, isLoading: false });
			},
		}));
	};

	useEffect(() => {
		if (feedbackProps.open) {
			loadParticipants();
		}
	}, [feedbackProps.open]);

	return (
		<SBox className="user__profile" sx={{ width: { xs: '100%', sm: '50%' } }} loading={!user}>
			<Grid container spacing={3} display="flex" flexDirection="column">
				<Grid item>
					<h5>{t('global.about')}</h5>
				</Grid>
				<Grid item>
					<ImageInput
						id="image-user-profile"
						image={user.image}
						initial={user._nome || user.nome}
						color={user.cor}
						isLoading={inProgress === 'image-user-profile'}
						onClick={onUserImageHandler}
					/>
				</Grid>
				<Grid item>
					<DynamicForm
						className="s-form"
						id="form_setting_profile"
						errors={errors}
						form={
							{
								fields: [
									{
										name: 'nome',
										maxLength: maxLength.userName,
										placeholder: t('form.placeholder.user.name'),
										id: 'user-name',
										label: t('global.name'),
										pattern: '^[a-zA-ZÀ-ÖØ-öø-ÿ ]+$',
										value: user.nome,
										inputype: inputTypes.TEXT_INPUT,
										type: 'text',
										helperText: errors?.nome,
										error: typeof errors?.nome === 'string',
										handleField: (e) => onUserHandler('nome', e.target.value),
									},
									{
										name: '_nome',
										id: 'user-nickname',
										placeholder: t('form.placeholder.user.nickname'),
										maxLength: maxLength.userName,
										pattern: '^[a-zA-ZÀ-ÖØ-öø-ÿ ]+$',
										inputype: inputTypes.TEXT_INPUT,
										label: t('global.nickname'),
										value: user._nome,
										type: 'text',
										handleField: (e) => onUserHandler('_nome', e.target.value),
									},
									{
										label: t('global.color'),
										component: (
											<ColorPalette
												id="user-color"
												value={user.cor}
												onChange={(e) => onUserHandler('cor', e.target.value)}
												className="color-row"
											/>),

									},
									{
										id: 'user-quote',
										label: t('global.observation'),
										placeholder: t('form.placeholder.user.aboutYou'),
										maxLength: maxLength.default250,
										inputype: inputTypes.TEXT_AREA,
										rows: 4,
										value: user.sobre,
										handleField: (e) => onUserHandler('sobre', e.target.value),
									},
								],
							}
						}
					/>
				</Grid>
				<Grid item>
					<h5>{t('global.system')}</h5>
				</Grid>

				<Grid item>

					<Form className="s-form">
						<SFormGroup>
							<SInputLabel htmlFor="profile-language">
								{t('global.language')}
							</SInputLabel>
							<SSelect
								id="profile-language"
								className="language__options"
								selectable
								value={user.idioma || i18next.language}
								options={Object.values(languagesOptions)}
								onChange={onLanguageHandler}
							/>
						</SFormGroup>
					</Form>
				</Grid>
				<Grid item className="footer">
					<SButton
						type="button"
						variant="outlined"
						color="secondary"
						size="large"
						onClick={onSubmit}
						isLoading={inProgress === 'submit'}
						error={typeof errors?.nome === 'string'}
					>
						{t('global.save')}
					</SButton>
				</Grid>
				<Grid item><Divider /></Grid>
				<Grid item className="align-items-center d-flex justify-content-between">
					<h5 className="mt-0">
						{t('form.password')}
						{' & '}
						{t('form.email')}
					</h5>
					{!user?.OAuth?.active && (
						<SLink
							component="button"
							className="mt-0"
							onClick={() => setIsHiddenEmailPwd(!isHiddenEmailPwd)}
						>
							{isHiddenEmailPwd ? t('global.change') : t('global.hide')}
						</SLink>
					)}
				</Grid>
				<Grid item>
					<Form className="s-form" onSubmit={onSubmitEmail}>
						<SFormGroup>
							<SInputLabel htmlFor="user-email" error={typeof errors?.email === 'string'}>
								{t('form.email')}
							</SInputLabel>
							<STextField
								type="email"
								id="user-email"
								value={user.email}
								disabled={isHiddenEmailPwd}
								className={isHiddenEmailPwd ? 'disabled_field' : ''}
								onChange={(e) => onUserHandler('email', e.target.value)}
								error={typeof errors?.email === 'string'}
								helperText={errors?.email}
							/>
						</SFormGroup>
						{!isHiddenEmailPwd
								&& (
									<>
										<SFormGroup>
											<SInputLabel htmlFor="user-password" error={typeof errors?.password === 'string'}>
												{t('form.currentPassword')}
											</SInputLabel>
											<SPasswordField
												id="user-password"
												value={email.password}
												onChange={(e) => onUserHandler('password', e.target.value)}
												error={typeof errors?.password === 'string'}
												helperText={errors?.password}
												placeholder={t('form.placeholder.yourPassword')}
											/>
											<small>
												<span className="">{t('messages.password.cantRemember')}</span>
												<SLink
													href={URLS.RECOVER}
													className="ml-1"
													target="_blank"
													underline="always"
												>
													{t('messages.password.reset')}
												</SLink>
											</small>
										</SFormGroup>
										<SButton
											type="submit"
											variant="outlined"
											color="secondary"
											className="mt-4"
											isLoading={inProgress === 'email'}
											error={errors?.password || errors?.email}
											disabled={email.password?.length < 1 || user.email?.length < 1}
										>
											{t('global.change')}
										</SButton>
									</>
								)}
					</Form>
				</Grid>

				{!isHiddenEmailPwd && (
					<Grid item>
						<Form className="s-form" onSubmit={onSubmitPassword}>
							<Divider />
							<SFormGroup>
								<SInputLabel htmlFor="newPassword" error={errors?.new}>
									{t('form.newPassword')}
								</SInputLabel>
								<SPasswordField
									id="newPassword"
									value={password.new}
									onChange={(e) => onUserHandler('new', e.target.value)}
									error={errors?.new}
									placeholder={t('form.placeholder.newPassword')}
									helperText={errors?.new || passwordValidation(password.new)?.message}
								/>
							</SFormGroup>
							<SFormGroup>
								<SInputLabel htmlFor="current" error={errors?.current}>
									{t('form.currentPassword')}
								</SInputLabel>
								<SPasswordField
									id="current"
									value={password.current}
									onChange={(e) => onUserHandler('current', e.target.value)}
									error={errors?.current}
									placeholder={t('form.placeholder.yourPassword')}
									helperText={errors?.current}
								/>
								<small>
									<span className="">{t('messages.password.cantRemember')}</span>
									<SLink
										href={URLS.RECOVER}
										className="ml-1"
										target="_blank"
										underline="always"
									>
										{t('messages.password.reset')}
									</SLink>
								</small>
							</SFormGroup>
							<SButton
								type="submit"
								variant="outlined"
								color="secondary"
								className="mt-4"
								isLoading={inProgress === 'pwd'}
								error={errors?.current || errors?.new}
								disabled={password.current?.length < 1 || password.new?.length < 1}
							>
								{t('global.change')}
							</SButton>
						</Form>
					</Grid>
				)}
				<Grid item><Divider /></Grid>
				<Grid item className="delete__account">
					<SLink
						component="button"
						href="# "
						underline="always"
						className="delete__account--title mb-3"
						onClick={() => setFeedbackProps({
							match: { value: '' },
							open: true,
							title: t('feedback.userAccount.delete.title'),
							rightButtonText: t('global.delete'),
							onRightButton: ({ match }) => onDeleteAccountHandler(match),
						})}
					>
						{t('settings.profile.deleteAccount')}
					</SLink>
					<p>{t('settings.deleteAccount.confirmationEmail')}</p>
					<p>{t('settings.deleteAccount.permanentExclusion')}</p>
					<p>
						<Trans
							i18nKey="settings.deleteAccount.save"
						>
							?
							<SLink
								href="# "
								component="button"
								sx={{ verticalAlign: 'baseline' }}
								onClick={() => dispatch(Creators.MODAL.showModal(routes.MODAL_BACKUP.path))}
							>
								{t('settings.profile.saveBackup')}
							</SLink>
						</Trans>

					</p>
				</Grid>
			</Grid>

			{feedbackProps.open && (
				<ModalFeedback
					{...feedbackProps}
					id="user-profile"
					onRequestClose={() => setFeedbackProps({ open: false })}
					layer="layer-1"
					color="error"
					className="s-form"
					rightButtonDisabled={!userOrganizationProfile?.user?.OAuth?.active
							&& feedbackProps.match?.value?.length < 1}
				>
					{!feedbackProps.children && (
						<>
							<p
								id="delete-account-infos"
							>
								<span
									dangerouslySetInnerHTML={{
										__html: t('feedback.organization.delete.text', {
											platforms: userOrganizationProfile?.plataformas_count,
											ideas: userOrganizationProfile?.ideasCount,
										}),
									}}
								/>
								<br />
								<span className="mr-1">{t('feedback.organization.delete.text3')}</span>
								<SLink
									href="# "
									component="button"
									underline="always"
									className="bold"
									onClick={() => dispatch(Creators.MODAL.showModal(routes.MODAL_BACKUP.path))}
								>
									{t('global.saveBackups')}
								</SLink>
								<span className="ml-1">{t('global.beforeDeleting')}</span>
							</p>
							{!userOrganizationProfile?.user?.OAuth?.active && (
								<SFormGroup>
									<SInputLabel htmlFor="user-password" error={typeof errors.match === 'string'}>
										{t('feedback.userAccount.password')}
									</SInputLabel>
									<SPasswordField
										id="user-password"
										value={feedbackProps.match?.value}
										error={typeof errors.match === 'string'}
										helperText={errors.match}
										// onKeyDown={(e) => e.key === 'Enter' && onLeaveHandler(feedbackProps.match)}
										autoFocus
										placeholder={t('form.placeholder.yourPassword')}
										autoComplete="off"
										onChange={(e) => {
											onErrorHandler('match');
											setFeedbackProps((prevState) => ({
												...prevState,
												match: { ...prevState.match, value: e.target.value },
											}));
										}}
									/>
								</SFormGroup>
							)}
							<Box className="box__info--small">
								<Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
									<Icon icon="warning" />
									{t('global.youllBeSignedOut')}
								</Box>
								<p dangerouslySetInnerHTML={{ __html: t('feedback.organization.delete.text2') }} />
							</Box>
						</>
					)}
				</ModalFeedback>
			)}
		</SBox>
	);
};

const Translation = withTranslation('common')(UProfile);

const Profile = (props) => (
	<Suspense fallback={<SpinnerLDSRipple />}>
		<Translation />
	</Suspense>
);
export default Profile;
