import { combineReducers } from 'redux';
import modal from './modal';
import platforms from './platforms';
import badges from './badges';
import canvas from './canvas';
import ideas from './ideas';
import features from './features';
import userOrganizationProfile from './userOrganizationProfile';
import billing from './billing';
import shared from './shared';
import forms from './forms';

const rootReducer = combineReducers({
	modal,
	platforms,
	shared,
	badges,
	canvas,
	ideas,
	features,
	userOrganizationProfile,
	billing,
	forms,
});

export default rootReducer;
