// @ts-nocheck
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import UnauthenticatedHeader from '@components/Header/UnauthenticatedHeader';
import { Creators } from '@actions';
import { isEmpty } from '@utils/ReactUtils';
import PageLoader from '@components/Loaders/PageLoader';
import { IRootState } from 'src/store';
import {
	Link, useLocation, useNavigate, useParams,
} from 'react-router-dom';
import URLS from '@constants/URLS';
import { StepsGenerator } from '@components/Shared/StepsGenerator';
import { useDispatch, useSelector } from 'react-redux';
import { GenericGrid } from '@components/Grids/GenericGrid';
import { Box } from '@mui/material';
import { InvitesSetup } from './InvitesSetup';
import { UserSetup } from './UserSetup';
import { OrganizationSetup } from './OrganizationSetup';

type TProps = WithTranslation

const SAccountSetup = ({ t }: TProps) => {
	const location = useLocation();
	const navigate = useNavigate();
	const match = useParams<{token?: string }>();
	const dispatch = useDispatch();

	const { userOrganizationProfile } = useSelector((state: IRootState) => state);

	// async handleOnlyDomainUsers(event) {
	//   const value = /true/i.test(event.target.value);
	//   try {
	//     await API.patchOrganization({ onlyDomainUsers: !value });
	//     this.setState({ org: { ...this.state.org, onlyDomainUsers: !value } });
	//   } catch (err) {
	//     console.log('error method pathOrganization', err);
	//   }
	// }

	const isGuest = typeof match.token !== 'undefined';
	const step = new URLSearchParams(location.search)?.get('step') || 'user';
	const email = new URLSearchParams(location.search)?.get('email')
			|| userOrganizationProfile?.user?.email;
	const isOrganizationCreate = location.pathname === URLS.ORGANIZATION_SETUP;
	const steps = {
		user: 1,
		organization: isOrganizationCreate ? 1 : 2,
		invites: isOrganizationCreate ? 2 : 3,
	};

	React.useEffect(() => {
		if (typeof match.token === 'undefined') {
			dispatch(Creators.USER_ORGANIZATION.getUserOrganization());
		}
	}, []);

	if (!isGuest && (isEmpty(userOrganizationProfile?.user) || userOrganizationProfile?.isLoading)) {
		return <PageLoader />;
	}

	return (
		<GenericGrid id="account-setup-page" className="account__setup--page s-grid-page">
			<UnauthenticatedHeader />
			<Box
				className="w-grid-3 inner unauthenticated"
				sx={{
					display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%',
				}}
			>
				<Box>
					<UserSetup
						token={match.token}
						email={email}
						show={step === 'user' || isGuest}
					/>
					<OrganizationSetup
						show={step === 'organization'}
						path={location.pathname}
					/>
					<InvitesSetup
						show={step === 'invites'}
						path={location.pathname}
					/>
					<StepsGenerator
						hide={isGuest}
						step={steps[step]}
						options={isOrganizationCreate ? [
							{ step: 1, onClick: () => navigate(`${URLS.ACCOUNT_SETUP}?step=organization`) },
							{ step: 2, onClick: () => navigate(`${URLS.ACCOUNT_SETUP}?step=invites`) },
						] : [
							{ step: 1, onClick: () => navigate(`${URLS.ACCOUNT_SETUP}?step=user`) },
							{ step: 2, onClick: () => navigate(`${URLS.ACCOUNT_SETUP}?step=organization`) },
							{ step: 3, onClick: () => navigate(`${URLS.ACCOUNT_SETUP}?step=invites`) },
						]}
					/>
				</Box>
				<Box className="footer__info" show={step === 'user' || isGuest}>
					<p className="">
						<span dangerouslySetInnerHTML={{ __html: t(isGuest ? 'register.registering' : 'register.currentlyLoggedInAs', { email }) }} />
						{` ${t('register.ifYouDontIntendToSetUp')} `}
						<Link to={URLS.LOGIN} className="link">
							<strong>{t('register.loginAnotherEmail')}</strong>
						</Link>
					</p>
				</Box>

			</Box>

		</GenericGrid>
	);
};

const Translation = withTranslation('common')(SAccountSetup);

const AccountSetup = () => (
	<React.Suspense fallback={<PageLoader />}>
		<Translation />
	</React.Suspense>
);

export default AccountSetup;
