// @ts-nocheck
import React, { Suspense, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { WithTranslation, withTranslation } from 'react-i18next';
import * as Yup from 'yup';
import ModalHeader from '@modules/Modal/ModalHeader';
import { getValidationErrors } from '@utils/yup';
import {
	Form, SInputLabel, SFormGroup,
} from '@components/DynamicForm/ComponentsCore';
import { HelpButton, SButton } from '@components/Buttons';
import { STextField } from '@forms/Components';
import { FLOWS, maxLength } from '@constants/GLOBAL';
import { DynamicForm } from '@components/DynamicForm';
import { APIForms } from '@services/apis';
import type { IModal } from '@modules/Modal/types';
import { IRootState } from 'src/store';
import { handleAxiosErrors } from '@services/auth';
import { TemplateDetails } from '@components/Background/TemplateDetails';
import {
	DialogBox,
} from '@modules/Modal/Components';
import FormBuilder from '@components/DynamicForm/FormBuilder';
import { inputTypes } from '@components/DynamicForm/FieldsTypes';
import { Creators } from '@actions';
import { BFormLine } from '@components/Background/BFormLine';
import { IForm } from 'src/@types/IForm';
import ModalFeedback, { IFeedbackProps } from '@components/Modals/ModalFeedback';
import {
	Box,
	DialogActions, DialogContent, DialogTitle, InputLabel, List, ListItem,
} from '@mui/material';
import { SSelect } from '@components/DynamicForm/Components/SSelect';
import { useTour } from '@reactour/tour';
import { useLocation } from 'react-router-dom';
import { SToast } from '@modules/SToast';
import { W_GRID } from '@components/Grids/GenericGrid';
import { ColorPalette } from '@components/Shared/ColorPalette';
import { SBox } from '@components/Background/SBox';
import _ from 'lodash';
import { STour } from '@components/STour';

declare type TProps = IModal & WithTranslation;

const MModalForm = (props: TProps) => {
	const { t } = props;
	const tour = useTour();
	const {
		forms, platforms, ideas, modal, shared,
	} = useSelector((state: IRootState) => state);

	const [activeField, setActiveField] = useState(undefined);
	const [errors, setErrors] = useState({});

	const { flow } = useSelector((state: IRootState) => state.modal);
	const {
		currentModel, pagingPaths, rootPath, action,
	} = useSelector(
		(state) => state.modal?.ModalProps,
	);

	const { pathname } = useLocation();
	const dispatch = useDispatch();
	const [feedbackProps, setFeedbackProps] = useState<IFeedbackProps>({ open: false });

	/** pagingPaths required for navigate */

	let form = forms;

	// PAGINATION

	if (currentModel) {
		form = currentModel;
	}

	Yup.addMethod(Yup.array, 'unique', function (field, message) {
		return this.test('unique', message, function (array) {
			if (!array) return true;
			const uniqueData = Array.from(
				new Set(array.map((row) => row[field]?.toLowerCase())),
			);
			const isUnique = array.length === uniqueData.length;
			if (isUnique) {
				return true;
			}
			const index = array.findIndex(
				(row, i) => row[field]?.toLowerCase() !== uniqueData[i],
			);
			if (array[index][field] === '') {
				return true;
			}
			return this.createError({
				path: `${this.path}[${index}].${field}`,
				message,
			});
		});
	});

	const fieldsSchema = Yup.array()
		.of(
			Yup.object().shape({
				label: Yup.string().required(t('messages.requiredField')),
				options: Yup
					.array()
					.of(
						Yup.object().shape({
							label: Yup.string().required(t('messages.requiredField')),
						}),
					)
					.optional()
					.unique('label', t('messages.field.mustBeUnique')),
			}),
		);

	const disable = ['insert', 'view'];

	const disabled = {
		idea: ideas?.mode === 'view',
		brainstorm: platforms?.mode === 'view',
		challenge: platforms?.mode === 'view',
	};

	const isDisabled = disabled[flow] || disable.includes(action)
		|| (action !== 'answer' && form?.isHeritage);

	const onFormHandler = (key, value) => {
		if (isDisabled) return;
		delete errors?.[key];

		dispatch(Creators.FORMS.updateForm({
			formId: form._id,
			key,
			value,
			onSuccess: () => {
				if ([FLOWS.IDEA].includes(flow)) {
					const index = ideas.forms.findIndex((item) => item._id === form._id);
					if (index > -1) {
						ideas.forms.splice(index, 1, { ...forms, [key]: value });
						dispatch(Creators.IDEAS.updateIdeas({ forms: ideas.forms }));
					}
				}
				if ([FLOWS.CHALLENGE, FLOWS.BRAINSTORM].includes(flow)) {
					const index = platforms.forms?.findIndex((item) => item._id === form._id);
					if (index > -1) {
						platforms.forms.splice(index, 1, { ...forms, [key]: value });
						dispatch(Creators.PLATFORMS.updatePlatform({ forms: platforms.forms }));
					}
				}
			},
			onErrors: (messages) => setErrors({ ...errors, ...messages }),
		}));
	};

	const onFieldsHandler = async (fields, errorsFields) => {
		if (typeof errorsFields !== 'undefined') {
			setErrors(errorsFields);
		}

		if (Array.isArray(fields)) {
			dispatch(Creators.FORMS.updateForm({
				formId: form._id,
				key: 'fields',
				fieldsSchema,
				value: fields,
				onErrors: (messages) => setErrors({ ...errors, ...messages }),
			}));
		}
	};

	const onCreateHandler = async () => {
		try {
			if (isDisabled) return;
			// if (!isEmpty(errors)) return;
			setActiveField('create');

			const newForm = {
				name: form?.name,
				difficulty: form?.difficulty,
				color: form?.color,
				useCases: form?.useCases,
				fields: form?.fields,
				description: form?.description,
			};

			const schema = Yup.object().shape({
				name: Yup.string().required(t('messages.requiredField')),
				description: Yup.string().required(t('messages.requiredField')),
				useCases: Yup.string().required(t('messages.requiredField')),
				fields: fieldsSchema,
			});

			if ([FLOWS.IDEA].includes(flow)) {
				newForm.ideaId = ideas._id;
			}
			if ([FLOWS.BRAINSTORM, FLOWS.CHALLENGE].includes(flow)) {
				newForm.platformId = platforms._id;
			}

			await schema.validate(newForm, {
				abortEarly: false,
			});

			await APIForms.postCreate(newForm);
			SToast.success(t('messages.success.form.created'));
			dispatch(Creators.MODAL.backModal());
		} catch (err) {
			if (err instanceof Yup.ValidationError) {
				setErrors(getValidationErrors(err));
			} else handleAxiosErrors(err);
		} finally {
			setActiveField(undefined);
		}
	};

	const onTourHandler = () => {
		if (shared?.tour === FLOWS.IDEA) {
			tour.setCurrentStep(6);
		}
		if (shared.tour === FLOWS.CHALLENGE) {
			tour.setCurrentStep(8);
		}
		if (shared.tour === FLOWS.BRAINSTORM) {
			tour.setCurrentStep(6);
		}
		tour.setIsOpen(false);
	};

	const onDeleteHandler = () => {
		setFeedbackProps({
			open: true,
			rightButtonText: t('global.remove'),
			title: t('feedback.remove.title-o', { name: `${t('global.form').toLowerCase()} ${form?.name}` }),
			color: 'error',
			onRightButton: () => {
				setFeedbackProps((prevState) => ({ ...prevState, isLoading: true }));
				dispatch(Creators.FORMS.deleteForm({
					formId: form._id,
					path: pathname,
					ideaId: ideas?._id,
					platformId: platforms?._id,
					flow: modal.flow,
					onSuccess: () => {
						onTourHandler();
						dispatch(Creators.FORMS.getForms({
							platformId: platforms?._id,
							ideaId: ideas?._id,
							flow: modal.flow,
							onSuccess: (response) => {
								dispatch(Creators.MODAL.backModal());
								if ([FLOWS.IDEA].includes(modal.flow)) {
									dispatch(Creators.IDEAS.updateIdeas({ forms: ideas.forms }));
								}
								if ([FLOWS.BRAINSTORM, FLOWS.CHALLENGE].includes(modal.flow)) {
									dispatch(Creators.PLATFORMS.updatePlatform({ forms: response.docs }));
								}
							},
							onErrors: (messages) => setErrors({ ...errors, ...messages }),
						}));
					},
					callback: () => setFeedbackProps((prevState) => ({ ...prevState, isLoading: false })),
				}));
			},
		});
	};

	const onInsertHandler = () => {
		setActiveField('insert');

		dispatch(Creators.FORMS.insertForm({
			templateId: form._id,
			platformId: platforms?._id,
			ideaId: ideas?._id,
			flow,
			callback: () => setActiveField(undefined),
			onSuccess: (_form: IForm) => {
				dispatch(Creators.MODAL.handleParams('action', form.isDefault ? 'view' : 'edit'));
				dispatch(Creators.FORMS.setForm(_form));
			},
		}));
	};

	const ArrayValidation = [];

	const onAnswerSubmit = async () => {
		try {
			if (isDisabled) return;

			setActiveField('answer');

			form.fields.forEach((field) => {
				if (field.required && ((field.value.length === 0))) {
					setErrors((prevState) => ({ ...prevState, [field.id]: t('messages.requiredField') }));
					ArrayValidation.push(field);
				}
			});

			if (!_.isEmpty(ArrayValidation)) {
				setActiveField(undefined);
				return;
			}

			await APIForms.patchForm(form._id, { fields: form.fields });

			if ([FLOWS.IDEA].includes(flow)) {
				const index = ideas.forms.findIndex((item) => item._id === form._id);
				if (index > -1) {
					ideas.forms.splice(index, 1, form);
					dispatch(Creators.IDEAS.updateIdeas({ forms: ideas.forms }));
				}
			}
			if ([FLOWS.CHALLENGE, FLOWS.BRAINSTORM].includes(flow)) {
				const index = platforms.forms?.findIndex((item) => item._id === form._id);
				if (index > -1) {
					platforms.forms.splice(index, 1, form);
					dispatch(Creators.PLATFORMS.updatePlatform({ forms: platforms.forms }));
				}
			}

			SToast.success(t('messages.success.form.submited'));
			dispatch(Creators.MODAL.backModal({ anchor: '#formBlank' }));
		} catch (err) {
			handleAxiosErrors(err);
		} finally {
			setActiveField(undefined);
		}
	};

	const onResetHandler = () => {
		if (isDisabled) return;
		setFeedbackProps({
			...feedbackProps,
			title: t('feedback.reset.title'),
			text: t('feedback.reset.content'),
			rightButtonText: t('global.confirm'),
			open: true,
			color: 'success',
			onRightButton: () => {
				setFeedbackProps((prevState) => ({ ...prevState, isLoading: true }));
				dispatch(Creators.FORMS.restoreForm({
					formId: form._id,
					onSuccess: () => setFeedbackProps({ open: false }),
					callback: () => setFeedbackProps((prevState) => ({ ...prevState, isLoading: false })),
				}));
			},
		});
	};

	const difficultyOptions = {
		alta: { value: 'alta', label: t('evaluation.values.alta') },
		média: { value: 'média', label: t('evaluation.values.media') },
		baixa: { value: 'baixa', label: t('evaluation.values.baixa') },
	};

	/** When Answer */

	const handleField = (e, index, inputype) => {
		let value;

		switch (inputype) {
			case inputTypes.TEXT_INPUT:
			case inputTypes.NUMBER_INPUT:
			case inputTypes.MASKER_INPUT:
			case inputTypes.TEXT_AREA:
			case inputTypes.RADIO:
			case inputTypes.TEXT_PICKER:
				value = e.target.value;
				break;
			case inputTypes.CHECKBOX:
			case inputTypes.SELECT_OPTION:
			case inputTypes.LINEAR_SCALE:
			case inputTypes.MULTIPLE_CHOICE:
				value = e;
				break;
			default:
				break;
		}

		dispatch(Creators.FORMS.handleField({
			formId: form._id,
			key: index,
			value,
		}));

		delete errors?.[`field-${index}`];
	};

	form.fields?.forEach((field, index) => {
		field.handleField = (e) => handleField(e, index, field.inputype);
		if (field.inputype === inputTypes.MULTIPLE_CHOICE) {
			if (!field.value) field.value = [];
		}
		if (!field.name) field.name = `field-${index}`;
		if (!field.id) field.id = `field-${index}`;
	});

	const onBackHandler = (params) => {
		onTourHandler();
		dispatch(Creators.MODAL.backModal(params));
	};

	const TOUR_STEPS = [
		'template.create', 'template.update',
	].map((key) => ({
		id: key.replaceAll('.', '-'),
		title: t(`tour.${key}.title`),
		action: t(`tour.${key}.action`),
		children: t(`tour.${key}.description`),
		imagePath: undefined,
	}));

	return (
		<DialogContent id="FormDialog">
			<ModalHeader
				fullScreen
				hideBack={pagingPaths}
				onBack={onBackHandler}
				BreadcrumbProps={{ className: W_GRID.W_GRID_2 }}
				BackModalProps={{ anchor: '#forms-modal-create' }}
				icon={modal.flow}
				hideClose
				pathParams={{
					rootPath,
					platform: platforms?.nome || '...',
					idea: ideas?.nome || '...',
					form: form?.name || '...',
					type: platforms?.type || '...',
					template: ideas.templateNome || '...',
				}}
			/>

			<SBox loading={typeof form === 'undefined' || !form || _.isEmpty(form)} className="inner w-grid-2">
				<DialogBox className="s-dialog-title">
					<DialogTitle variant="h1">
						{action === 'answer' ? t('form.submitAnswer') : t('form.details')}
					</DialogTitle>
				</DialogBox>
				<DialogBox className="s-form s-dialog-box">
					{(action === 'answer' || action === 'view' || pagingPaths) ? (
						<>
							<List className="list">
								<ListItem className="list-item">
									<BFormLine form={form} />
								</ListItem>
							</List>
							<DynamicForm
								id="form-answer"
								errors={errors}
								form={form}
								disabled={action !== 'answer'}
							/>
						</>
					) : (
						<>
							{action !== 'answer' && (
								<DialogBox className="s-dialog-content">
									<Box className="s-label-group s-label">
										<InputLabel>
											{t('global.template')}
										</InputLabel>
										<HelpButton color="secondary" className="tour" placement="right-start">
											<STour {...TOUR_STEPS?.[form?.template ? 1 : 0]} hideActions />
										</HelpButton>
									</Box>

									<TemplateDetails
										onReset={action === 'edit' && onResetHandler}
										name={form.template?.name || form.name || '...'}
										color={form.template?.color || form.color}
										icon="form"
									/>
								</DialogBox>
							)}
							<Form className="s-form">
								<SFormGroup>
									<SInputLabel htmlFor="form-name" error={typeof errors?.name === 'string'}>
										{t('form.name')}
									</SInputLabel>
									<STextField
										id="form-name"
										tabIndex={0}
										name="name"
										maxLength={maxLength.default}
										autoFocus
										placeholder={t('form.placeholder.name')}
										value={form?.name}
										disabled={isDisabled}
										autoComplete="off"
										helperText={errors?.name}
										error={typeof errors?.name === 'string'}
										onChange={(e) => onFormHandler('name', e.target.value)}
									/>
								</SFormGroup>
								<SFormGroup>
									<SInputLabel htmlFor="template-color">{t('global.color')}</SInputLabel>
									<ColorPalette
										id="template-color"
										value={form?.color}
										disabled={isDisabled}
										onChange={(e) => onFormHandler('color', e.target.value)}
									/>
								</SFormGroup>
								<div className="d-flex">
									<SFormGroup className="w-75">
										<SInputLabel htmlFor="use-cases" error={typeof errors?.useCases === 'string'}>
											{t('form.useCases')}
										</SInputLabel>
										<STextField
											tabIndex="0"
											id="use-cases"
											name="useCases"
											placeholder={t('form.placeholder.useCases')}
											maxLength={60}
											value={form?.useCases}
											disabled={isDisabled}
											helperText={errors?.useCases}
											error={typeof errors?.useCases === 'string'}
											onChange={(e) => onFormHandler('useCases', e.target.value)}
										/>
									</SFormGroup>
									<SFormGroup className="w-25 ml-30px">
										<SInputLabel htmlFor="form-difficulty">
											{t('form.difficulty')}
										</SInputLabel>
										<SSelect
											id="form-difficulty"
											fullWidth
											value={difficultyOptions[form.difficulty].value}
											name="difficulty"
											disabled={isDisabled}
											options={Object.values(difficultyOptions)}
											onChange={(event) => onFormHandler('difficulty', event.target.value)}
										/>
									</SFormGroup>
								</div>
								<SFormGroup>
									<SInputLabel htmlFor="form-description" error={typeof errors?.description === 'string'}>
										{t('form.description')}
									</SInputLabel>
									<STextField
										multiline
										rows={4}
										name="description"
										id="form-description"
										value={form?.description}
										helperText={errors?.description}
										error={typeof errors?.description === 'string'}
										placeholder={t('form.placeholder.description')}
										disabled={isDisabled}
										maxLength={maxLength.default500}
										onChange={(e) => onFormHandler('description', e.target.value)}
									/>
								</SFormGroup>
								<FormBuilder
									fields={form?.fields}
									handleFields={onFieldsHandler}
									disabled={isDisabled}
									errors={errors}
								/>
							</Form>

						</>
					)}
				</DialogBox>
				{action && !pagingPaths && (
					<DialogBox className="s-dialog-box">
						<DialogActions className="s-dialog-actions">
							{action === 'create' && (
								<SButton
									type="button"
									variant="outlined"
									color="primary"
									fullWidth
									size="large"
									isLoading={activeField === 'create'}
									onClick={onCreateHandler}
									error={!_.isEmpty(errors)}
								>
									{t('form.create')}
								</SButton>
							)}
							{action === 'insert' && (
								<SButton
									type="button"
									variant="outlined"
									color="primary"
									size="large"
									fullWidth
									isLoading={activeField === 'insert'}
									onClick={onInsertHandler}
								>
									{t('form.insert')}
								</SButton>
							)}

							{action === 'answer' && !disabled[flow] && (
								<SButton
									type="button"
									variant="outlined"
									color="primary"
									size="large"
									fullWidth
									isLoading={activeField === 'answer'}
									onClick={onAnswerSubmit}
									error={ArrayValidation.some((item) => item.error)}
									disabled={action !== 'answer'}
								>
									{t('form.submit')}
								</SButton>
							)}

							{(!form.isHeritage && ['edit', 'view'].includes(action) && !disabled[flow]) && (
								<SButton
									type="button"
									variant="outlined"
									size="large"
									fullWidth
									color="error"
									isLoading={activeField === 'delete'}
									onClick={onDeleteHandler}
								>
									{t('form.remove')}
								</SButton>
							)}
						</DialogActions>
					</DialogBox>
				)}
			</SBox>
			{feedbackProps.open && (
				<ModalFeedback
					id="form"
					onRequestClose={() => setFeedbackProps({ open: false })}
					{...feedbackProps}
				/>
			)}
		</DialogContent>
	);
};

const Translation = withTranslation('common')(MModalForm);

const ModalForm = (props: IModal) => (
	<Suspense fallback={<DialogContent />}>
		<Translation {...props} />
	</Suspense>
);

export default ModalForm;
