// @ts-nocheck
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { generatorArrayNumbers } from '../FormUtils';
import { SSelect } from '../Components/SSelect';

interface ILinearScaleBuilderProps {
	onChange: (key: string, value: string) => void
	field: {
		label: string
		markerMax: string
		markerMin: string
		max: number
		min: number
	}
}

declare type TLinearScaleBuilderProps = ILinearScaleBuilderProps & WithTranslation;

const _LinearScaleBuilder = (props: TLinearScaleBuilderProps) => {
	const { t, onChange, field } = props;

	const [scale, setScale] = React.useState({
		min: field.min || '0',
		max: field.max || '10',
		markerMin: field.markerMin || '',
		markerMax: field.markerMax || '',
	});

	const onScaleHandler = (key: string, value: string) => {
		setScale({ ...scale, [key]: value });

		if (typeof onChange === 'function') {
			onChange(key, value);
		}
	};

	return (
		<Box className="linear-scale-builder">
			<div className="delimiters">
				<SSelect
					value={scale.min}
					variant="standard"
					onChange={(e) => onScaleHandler('min', e.target.value)}
					options={generatorArrayNumbers(0, 1)}
				/>
				<span>{t('global.to')}</span>
				<SSelect
					value={scale.max}
					variant="standard"
					onChange={(e) => onScaleHandler('max', e.target.value)}
					options={generatorArrayNumbers(0, 10)}
				/>
			</div>
			{
				[{
					label: scale.min,
					value: scale.markerMin,
					name: 'markerMin',
				},
				{
					label: scale.max,
					value: scale.markerMax,
					name: 'markerMax',
				}].map((item, index) => (
					<div className="marker" key={index}>
						<div className="prefix">{item.label}</div>
						<input
							name={item.name}
							type="text"
							maxLength={30}
							className="form-control"
							placeholder={`${t('form.marker')} (${t('form.optional')})`}
							value={item.value}
							onChange={(e) => onScaleHandler(item.name, e.target.value)}
						/>
						<span className="max-char">{t('form.max30Char')}</span>
					</div>
				))
			}
		</Box>
	);
};

const LinearScaleBuilder = withTranslation('common')(_LinearScaleBuilder);

export { LinearScaleBuilder };
