// @ts-nocheck
import React, { Suspense, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Creators } from '@actions';
import type { IModal } from '@modules/Modal/types';
import { IRootState } from 'src/store';
import ModalHeader from '@modules/Modal/ModalHeader';
import { SButton } from '@components/Buttons';
import { DialogBox } from '@modules/Modal/Components';
import { APIIdeas } from '@services/apis';
import { BEvaluationRow } from '@components/Background/BEvaluationRow';
import { ICriteria, IIdea } from 'src/@types/IIdea';
import {
	DialogContent, DialogTitle, Grid,
} from '@mui/material';
import ModalFeedback, { IFeedbackProps } from '@components/Modals/ModalFeedback';
import { SToast } from '@modules/SToast';
import { W_GRID } from '@components/Grids/GenericGrid';
import { SBox } from '@components/Background/SBox';
import Icon from '../../Shared/Icon';
import { HelpButton } from '../../Buttons/HelpButton';

declare type TProps = IModal & WithTranslation

const MModalIdeaApprove = (props: TProps) => {
	const { t, routes } = props;
	const { ideas, userOrganizationProfile } = useSelector((state: IRootState) => state);

	const [inProgress, setInProgress] = useState<'GET_IDEA' | undefined>();
	const [feedbackProps, setFeedbackProps] = useState<IFeedbackProps>({ open: false });
	const dispatch = useDispatch();

	const somePending = ideas?.usuarioAvaliou;

	const isApproved = ideas?.status === 'aprovada';
	const isReproved = ideas?.status === 'reprovada';

	const onCloseHandler = () => {
		const ideaPage = document.querySelector('#idea-page');

		if (typeof ideas.syncIdea === 'function' && ['EVALUATE_IDEA'].includes(ideas.workflow)) {
			ideas.syncIdea({
				_id: ideas._id,
				media_avaliacoes: ideas?.media_avaliacoes,
				status: ideas.status,
				usuarioAvaliou: ideas.usuarioAvaliou,
			}); // storage to page state
		}

		if (typeof ideas.loadDashboards === 'function' && ideaPage) {
			ideas.loadDashboards();
		}

		if (typeof ideas.loadEvaluators === 'function' && ideaPage) {
			ideas.loadEvaluators();
		}

		dispatch(Creators.IDEAS.clearIdeas());
	};

	const approveStatus = {
		aprovada: 'approved',
		reprovada: 'reproved',
	};

	const onApproveHandler = async (status: 'aprovada' | 'reprovada') => {
		try {
			setFeedbackProps((params) => ({ ...params, isLoading: true }));
			const response = await APIIdeas.postApproveEvaluation(ideas._id, {
				status,
				justificativa: 'Teste de aprovacao',
			});

			const updated = { status: response.data.status, _id: response.data._id };

			if (typeof ideas.syncIdea === 'function' && ['EVALUATE_IDEA'].includes(ideas.workflow)) {
				ideas.syncIdea(updated); // storage to page state
				dispatch(Creators.IDEAS.updateIdeas(updated));
			}

			SToast.success(t(`messages.success.idea.${approveStatus[status]}`));
			setFeedbackProps({ open: false });
		} catch (err) {
			SToast.error(t('messages.error.idea.evaluate'));
		} finally {
			setFeedbackProps((params) => ({ ...params, isLoading: false }));
		}
	};

	const onEvaluateHandler = (criteria) => {
		dispatch(
			Creators.MODAL.showModal(
				routes.MODAL_EVALUATION.path,
				{
					currentModel: criteria,
					pagingPaths: ideas.media_avaliacoes.map(
						(item) => {
							item._id = item.type;
							item.isLoading = true;
							return {
								pagePath: routes.MODAL_EVALUATION.path,
								pageModel: item,
							};
						},
					),
				},
			),
		);
	};

	const getAction = (criteria: ICriteria) => {
		const evaluated = criteria.evaluators?.find((evaluator) => evaluator._id === userOrganizationProfile?._id);
		if (ideas.status === 'pendente' && !evaluated) { return 'evaluate'; }
		if (ideas.status === 'pendente' && evaluated) { return 'edit'; }

		return 'view';
	};

	useEffect(() => {
		dispatch(Creators.IDEAS.getIdea({
			ideaId: ideas._id,
		}));
		dispatch(Creators.IDEAS.updateIdeas({ evaluate: false }));
	}, []);

	return (
		<DialogContent className="modal__approve" id="ApproveDialog">
			<ModalHeader
				fullScreen
				icon="idea"
				hideBack
				onClose={onCloseHandler}
				BreadcrumbProps={{ className: W_GRID.W_GRID_2 }}
				pathParams={{
					idea: ideas?.nome || '...',
				}}
			/>
			{!somePending && (
				<DialogBox className="s-dialog-title inner w-grid-2">
					<DialogTitle variant="h1">
						{t('evaluation.chooseCriteriaToAvaluate')}
					</DialogTitle>
					<HelpButton size="middle" href={t('urls.evaluation')} />
				</DialogBox>
			)}
			<SBox loading={!ideas || inProgress === 'GET_IDEA'}>
				<DialogBox id="modalFeatureToBeSponsored" className="s-dialog-box inner w-grid-2">
					<ul className="list">
						{ideas?.media_avaliacoes?.map((criteria) => (
							<li
								aria-hidden
								key={`${criteria.icon}-criteria`}
								className="align-items-sm-start"
							>
								<BEvaluationRow
									evaluation={criteria}
									routes={routes}
									ideaId={ideas._id}
									onClick={() => onEvaluateHandler(criteria)}
									myEvaluationValue={ideas?.minhas_avaliacoes?.find((item) => item.tipo === criteria.icon)?.valor}
									action={getAction(criteria) ? t(`global.${getAction(criteria)}`) : undefined}
								/>
							</li>
						))}
					</ul>
					{ideas.plataforma?.type && (
						<Grid
							container
							className={`approve__actions ${ideas.status}`}
							id="approve-actions"
							spacing={2}
							sx={{
								display: 'flex',
								flexDirection: 'row',
								'.s-button': {
									width: '100%',
								},
							}}
						>
							{(isApproved || ideas.status === 'pendente') && (
								<Grid item xs={6}>
									<SButton
										type="button"
										color="success"
										variant="outlined"
										className="mr-15px"
										size="large"
										disabled={(isApproved || ideas.plataforma?.type === 'Challenge') && !ideas.plataforma.usuarioModera ? false : !ideas.usuarioAvaliou || ideas.status !== 'pendente'}
										onClick={isApproved ? undefined : () => setFeedbackProps({
											title: t('idea.approve'),
											rightButtonText: t('global.approve'),
											text: t('idea.warning.approve'),
											open: true,
											color: 'success',
											onRightButton: () => onApproveHandler('aprovada'),
										})}
										startIcon={<Icon icon="approved" />}
									>
										{isApproved ? t('global.approved') : t('global.approve')}
									</SButton>
								</Grid>
							)}
							{(isReproved || ideas.status === 'pendente') && (
								<Grid item xs={6}>
									<SButton
										type="button"
										color="error"
										variant="outlined"
										size="large"
										disabled={isReproved || (ideas.plataforma?.type === 'Challenge' && !ideas.plataforma.usuarioModera) ? false : !ideas.usuarioAvaliou || ideas.status !== 'pendente'}
										onClick={isReproved ? undefined : () => setFeedbackProps({
											title: t('idea.decline'),
											rightButtonText: t('global.decline'),
											text: t('idea.warning.reprove'),
											open: true,
											color: 'error',
											onRightButton: () => onApproveHandler('reprovada'),
										})}
										startIcon={<Icon icon="reproved" />}
									>
										{isReproved ? t('global.reproved') : t('global.decline')}
									</SButton>
								</Grid>
							)}
						</Grid>
					)}
				</DialogBox>

			</SBox>

			{feedbackProps.open && (
				<ModalFeedback
					id="idea-approve"
					onRequestClose={() => setFeedbackProps({ open: false })}
					{...feedbackProps}

				/>
			)}
		</DialogContent>
	);
};

const Translation = withTranslation('common')(MModalIdeaApprove);

const ModalIdeaApprove = (props: IModal) => (
	<Suspense fallback={<div />}>
		<Translation {...props} />
	</Suspense>
);

export default ModalIdeaApprove;
