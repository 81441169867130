import React, { useState } from 'react';
import { SButton } from '@components/Buttons';
import { Box, Grid, IconButton, Link } from '@mui/material';
import { PopoverContentProps, TourProps, useTour } from '@reactour/tour';
import { useTranslation } from 'react-i18next';
import { Creators } from '@actions';
import { useDispatch } from 'react-redux';
import i18n from '@services/i18n';
import { TFlow } from 'src/@types/IShared';

export declare type TTourMeta = 'store'

export interface ISTourOptions {
	noAction?: boolean
	bridge?: TFlow
	currentStep?: number
	images?: string[]
}

export interface ISTourProps {
	id: string
	title: string
	action?: string
	hideActions?: boolean
	children: React.ReactNode
	options?: ISTourOptions
	helperURL?: string
	imagePath?: string
}

const STour = (props: ISTourProps & PopoverContentProps) => {
	const {
		title, children, action, hideActions, id, options, helperURL, imagePath,
	} = props;

	const { t } = useTranslation();
	const dispatch = useDispatch();
	const {
		setCurrentStep, setIsOpen, currentStep, steps, setSteps,
	} = useTour();

	const onCloseHandler = () => {
		dispatch(Creators.SHARED.updateShared({ tour: options?.bridge }));
		setSteps?.([]);
		setIsOpen(false);
		setCurrentStep(0);
	};

	const onPrevHandler = () => {
		if (currentStep === 0) {
			onCloseHandler();
		} else {
			setCurrentStep((prevState) => prevState - 1);
		}
	};

	const onNextHandler = () => {
		if (steps.length - 1 === currentStep) {
			onCloseHandler();
		} else {
			setCurrentStep((prevState) => prevState + 1);
		}
	};

	return (
		<Grid id={`tour-${id}`} container className="" flexDirection="column" spacing="12px" sx={{ padding: hideActions ? '1rem' : 0 }}>
			{!hideActions && (
				<Grid item container justifyContent="flex-end">
					<Grid item>
						<IconButton onClick={onCloseHandler}>
							<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path
									d="M5.73113 6.99807L0.75 11.9746L2.01111 13.2345L6.99225 8.25801L11.9889 13.25L13.25 11.9901L8.25336 6.99807L13.2461 2.00994L11.985 0.75L6.99225 5.73813L2.01348 0.764001L0.752369 2.02394L5.73113 6.99807Z"
									fill="var(--charcoal-color)"
								/>
							</svg>
						</IconButton>
					</Grid>
				</Grid>
			)}
			<Grid
				item
				marginTop="-20px"
				sx={{
					fontWeight: 700,
					lineHeight: '20px',
				}}
			>
				{title}
			</Grid>
			<Grid item>{children}</Grid>
			{imagePath && (
				<Grid item sx={{ width: 'auto', img: { width: '100%', height: '100%' } }}>
					<Box
						sx={{
							height: '225px',
							backgroundImage: `url(${imagePath})`,
							backgroundSize: 'contain',
							backgroundPosition: 'center',
							position: 'relative',
						}}
					/>
				</Grid>
			)}

			{action && (
				<Grid item>
					<strong>{`${t('global.action')}: `}</strong>
					{action}
				</Grid>
			)}
			{helperURL && (
				<Grid item>
					<Link href={helperURL} target="_blank" rel="noopener">{t('global.toNowMore')}</Link>
				</Grid>
			)}
			{!hideActions && (
				<Grid item container justifyContent="flex-end" className="footer">
					{steps.length !== 1 && (
						<Grid item>
							<SButton type="button" onClick={onPrevHandler}>
								{currentStep === 0 ? t('global.close') : t('global.back')}
							</SButton>
						</Grid>
					) }
					<Grid item>
						<SButton type="button" onClick={onNextHandler}>
							{steps.length - 1 === currentStep ? t('global.close') : t('global.next') }
						</SButton>
					</Grid>
				</Grid>
			)}
		</Grid>
	);
};

const BuildTour = {
	steps: (steps: string[], options?: ISTourOptions): ISTourProps[] => {
		const { noAction, bridge, images } = options || {};
		const s = steps.map((key) => (
			{
				id: key.replaceAll('.', '-'),
				title: i18n.t(`tour.${key}.title`),
				action: !noAction ? i18n.t(`tour.${key}.action`) : undefined,
				children: i18n.t(`tour.${key}.description`),
				bridge,
				imagePath: images?.includes(key) ? `/images/tour/${key.replaceAll('.', '-')}.gif` : undefined,
			}
		));

		return s;
	},
	setTour: (tour: TourProps, steps: ISTourProps[], options?: ISTourOptions) => {
		tour?.setSteps?.(steps.map((step) => ({
			selector: `[data-tut="${step.id}"]`,
			content: (prevProps) => (
				<STour
					{...step}
					{...prevProps}
					options={options}
					imagePath={step.imagePath}
				/>
			),
		})));

		if (options?.currentStep) {
			tour?.setCurrentStep(options.currentStep);
		}

		tour?.setIsOpen(true);
	},
	close: (tour: TourProps) => {
		tour?.setSteps?.([]);
		tour?.setIsOpen(false);
		tour?.setCurrentStep(0);
	},
};

export { STour, BuildTour };
