// @ts-nocheck
import {
	put,
	call,
	takeLatest,
	select,
	delay,
} from 'redux-saga/effects';
import i18next from 'i18next';
import * as Yup from 'yup';
import { Creators, Types } from '@actions';
import APIBadges from '@services/apis/badges';
import { getValidationErrors } from '@utils/yup';
import { handleAxiosErrors } from '@services/auth';
import { APIPlatforms } from '@services/apis';
import { IRootState } from 'src/store';
import { AxiosResponse } from 'axios';
import { SToast } from '@modules/SToast';

function* insertBadge(action) {
	const {
		templateId, platformId, onSuccess, callback,
	} = action.params;
	try {
		const response: AxiosResponse = yield call(APIBadges.postBadgePlatform, platformId, templateId);
		if (typeof onSuccess === 'function') {
			yield call(onSuccess, response.data);
		}
		yield call(SToast.success, i18next.t('messages.success.badge.added'));
	} catch (err) {
		yield call(handleAxiosErrors, err);
	} finally {
		if (typeof callback === 'function') {
			yield call(callback);
		}
	}
}

function* deleteBadge(action) {
	const { badgeId, callback, onSuccess } = action.params;
	try {
		yield call(APIBadges.deleteBadge, badgeId);
		if (typeof onSuccess === 'function') {
			yield call(onSuccess);
		}
		yield put(Creators.MODAL.backModal());
		yield call(SToast.success, i18next.t('messages.success.badge.removed'));
	} catch (err) {
		yield call(handleAxiosErrors, err);
	} finally {
		if (typeof callback === 'function') {
			yield call(callback);
		}
	}
}

function* restoreBadge(action) {
	try {
		const response: AxiosResponse = yield call(APIBadges.putRestoreBadge, action.p.badgeId);

		yield put(Creators.BADGES.setBadge(response.data));

		if (typeof action.p.onSuccess === 'function') {
			yield call(action.p.onSuccess, response.data);
		}

		yield call(SToast.success, i18next.t('messages.success.template.reseted'));

		if (typeof action.p.onSuccess === 'function') {
			yield call(action.p.onSuccess);
		}
	} catch (err) {
		yield call(handleAxiosErrors, err);
	} finally {
		if (typeof action.p.callback === 'function') {
			yield call(action.p.callback);
		}
	}
}

export function* getBadgesByPlatform(action) {
	const {
		platformId, ideaId, onSuccess, callback,
	} = action.params;
	try {
		const response: AxiosResponse = yield call(APIPlatforms.getBadges, platformId, ideaId);

		if (typeof onSuccess === 'function') {
			yield call(onSuccess, { docs: response.data });
		}
	} catch (err) {
		yield call(handleAxiosErrors, err);
	} finally {
		if (typeof callback === 'function') {
			yield call(callback);
		}
	}
}

export function* getBadgesByIdea(action) {
	try {
		const response: AxiosResponse = yield call(APIBadges.getBadgesByIdea, action.params.ideaId);
	} catch (err) {
		yield call(handleAxiosErrors, err);
	}
}

function* handleBadge(action) {
	const {
		badgeId, key, value, onError, callback, onSuccess,
	} = action.params;
	try {
		const { modal } = yield select((state: IRootState) => state);

		if (modal.ModalProps.action !== 'edit' || !badgeId) return;
		const field = { [key]: value };
		let schema = null;

		switch (key) {
			case 'nome':
			case 'descricao':
				schema = Yup.object().shape({
					[key]: Yup.string().required(i18next.t('messages.requiredField')),
				});

				yield schema.validate(field, {
					abortEarly: false,
				});
				break;

			default:
				break;
		}

		// Debounced!
		yield delay(1000);
		yield call(APIBadges.putBadge, badgeId, field);

		if (typeof onSuccess === 'function') {
			yield call(onSuccess);
		}
	} catch (err) {
		if (err instanceof Yup.ValidationError) {
			const errorMessages = yield call(getValidationErrors, err);
			if (typeof onError === 'function') {
				yield call(onError, errorMessages);
			}
		} else yield call(handleAxiosErrors, err);
	} finally {
		if (typeof callback === 'function') {
			yield call(callback);
		}
	}
}

export default function* () {
	yield takeLatest(Types.BADGES.RESTORE_BADGE, restoreBadge);
	yield takeLatest(Types.BADGES.INSERT_BADGE, insertBadge);
	yield takeLatest(Types.BADGES.DELETE_BADGE, deleteBadge);
	yield takeLatest(Types.BADGES.HANDLE_BADGE, handleBadge);
	yield takeLatest(Types.BADGES.GET_BADGES_BY_PLATFORM, getBadgesByPlatform);
	yield takeLatest(Types.BADGES.GET_BADGES_BY_IDEA, getBadgesByIdea);
}
