import React, { Suspense, useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import ModalHeader from '@modules/Modal/ModalHeader';
import type { IModal } from '@modules/Modal/types';
import { IRootState } from 'src/store';
import { DialogBox } from '@modules/Modal/Components';
import { handleAxiosErrors } from '@services/auth';
import { SCheckbox } from '@components/DynamicForm/ComponentsCore';
import { SButton } from '@components/Buttons';
import { APIUser } from '@services/apis';
import Icon from '@components/Shared/Icon';
import { IBackupOptions } from 'src/@types/IShared';
import {
	Box, DialogContent, DialogTitle, Grid,
} from '@mui/material';
import { Creators } from '@actions';
import { ISMenuOption, SMenu } from '@components/SMenu';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { SToast } from '@modules/SToast';
import {  W_GRID } from '@components/Grids/GenericGrid';

interface IOption {
	name?: string
	label?: string
	value?: any
}

interface IContent {
	brainstorms: IOption
	challenges: IOption
	ideas: IOption
	teams: IOption
	drafts: IOption
	archived: IOption
	all: IOption
}

declare type TProps = IModal & WithTranslation

const MModalBackup = (props: TProps) => {
	const { t } = props;
	const [backup, setBackup] = useState<IBackupOptions>({
		brainstorms: false,
		challenges: false,
		ideas: false,
		teams: false,
		drafts: false,
		archived: false,
		all: false,
	});
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const { userOrganizationProfile } = useSelector((state: IRootState) => state);
	const [inProgress, setInProgress] = useState<'export' | 'download' | undefined>();
	const [step, setStep] = useState(1);
	const [link, setLink] = useState();

	const dispatch = useDispatch();

	const options = [
		{
			value: 30,
			label: `${t('global.last')} 30 ${t('global.day')}s`,
			details: `${t('settings.backup.exportLast')} 30 ${t('global.day')}s`,
		},
		{
			value: 60,
			label: `${t('global.last')} 60 ${t('global.day')}s`,
			details: `${t('settings.backup.exportLast')} 60 ${t('global.day')}s`,
		},
		{
			value: 90,
			label: `${t('global.last')} 90 ${t('global.day')}s`,
			details: `${t('settings.backup.exportLast')} 90 ${t('global.day')}s`,
		},
		{ value: Number.MAX_SAFE_INTEGER, label: t('settings.backup.allData') },
	];

	const [days, setDays] = useState<ISMenuOption>(options[0]);

	const CONTENT: IContent = {
		brainstorms: {},
		challenges: {},
		ideas: {},
		teams: {},
		drafts: {},
		archived: {},
		all: {},
	};

	Object.keys(CONTENT).forEach((k) => {
		const key = k as keyof IContent;
		CONTENT[key].name = key;
		CONTENT[key].label = t(`global.${key}`);
		CONTENT[key].value = backup[key];
	});

	const onExportHandler = async () => {
		try {
			setStep(2);
			if (!userOrganizationProfile) return;

			if (Object.keys(backup).every((key) => backup[key as keyof IContent] === false)) {
				return;
			}

			setInProgress('export');

			setTimeout(() => {
				// @ts-ignore
				document.querySelector('.backup-steps')?.childNodes[0].classList.remove('opacity-05');
			}, 100);

			setTimeout(() => {
				// @ts-ignore
				document.querySelector('.backup-steps')?.childNodes[1].classList.remove('opacity-05');
			}, 200);

			const backupOptions = { ...backup, days: days.value };
			delete backupOptions.all;

			// @ts-ignore
			const response = await APIUser.postBackup(backupOptions);

			setLink(response.data.link);

			setTimeout(() => {
				// @ts-ignore
				document.querySelector('.backup-steps')?.childNodes[2].classList.remove('opacity-05');
			}, 1000);

			SToast.success(t('messages.success.backup'));
		} catch (err) {
			handleAxiosErrors(err);
		} finally {
			setInProgress(undefined);
		}
	};

	const onDownloadHandler = () => {
		window.location.href = link || '';
	};

	const openMenu = Boolean(anchorEl);

	return (
		<DialogContent id="BackupDialog">
			<ModalHeader
				fullScreen
				BreadcrumbProps={{ className: W_GRID.W_GRID_2 }}
				hidePath
				onClose={() => dispatch(Creators.MODAL.hideSecondModal())}
			/>

			<DialogBox className="s-dialog-title inner w-grid-2">
				<DialogTitle variant="h1">
					{t('settings.backup.manage')}
				</DialogTitle>
			</DialogBox>
			<DialogBox className="s-dialog-box inner w-grid-2">
				<Box className="settings-backup s-dialog-content">
					<h3 className="">{t('settings.backup.export')}</h3>
					<p className="" />
					{step === 1 && (
						<div className="">
							<span className="mb-2">{t('settings.backup.choose')}</span>

							{Object.values(CONTENT).map((item) => (
								<div className="form-group" key={item.name}>
									<SCheckbox
										id={item.name}
										name={item.name}
										value={item.value}
										checked={item.value === true}
										label={item.label}
										onChange={(event) => {
											if (item.name === 'all') {
												Object.keys(backup).forEach((k) => {
													const key = k as keyof IContent;
													backup[key] = !/true/i.test(event.currentTarget.value);
												});
												setBackup({ ...backup });
												return;
											}
											setBackup({
												...backup,
												[item.name]: !/true/i.test(event.currentTarget.value),
												all: backup.all && !/true/i.test(event.currentTarget.value),
											});
										}}
									/>
								</div>
							))}
							<Grid container marginTop={3}>
								<Grid item>
									<SMenu
										id="backup-menu"
										open={openMenu}
										anchorEl={anchorEl}
										setAnchorEl={setAnchorEl}
										options={options}
										// @ts-ignore
										onChange={(option: ISMenuOption) => setDays(option)}
									>
										<SButton
											className={`arrow ${openMenu ? 'down' : 'up'}`}
											size="large"
											endIcon={<MdKeyboardArrowDown />}
										>
											{days.label}
										</SButton>
									</SMenu>
								</Grid>
							</Grid>
							<Grid item marginTop={3}>
								<SButton
									type="button"
									isLoading={inProgress === 'export'}
									variant="outlined"
									color="primary"
									onClick={onExportHandler}
									disabled={Object.keys(backup).every(
										(key) => backup[key as keyof IContent] === false,
									)}
								>
									{t('settings.backup.export')}
								</SButton>
							</Grid>

						</div>
					)}
					{step === 2 && (
						<div className="backup-steps">
							<span className="step opacity-05">
								<Icon icon="fa fa-circle-check" className="check-backup" />
								{t('settings.backup.startedAt', { date: new Date() })}
							</span>
							<span className="step opacity-05">
								<Icon icon="fa fa-circle-check" className="check-backup" />
								{t('settings.backup.waiting')}
							</span>
							<span className="step opacity-05">
								<Icon icon="fa fa-circle-check" className="check-backup" />
								{t('settings.backup.completed')}
							</span>
							<Grid container marginTop={3}>
								{link && (
									<Grid item>
										<SButton
											type="button"
											variant="outlined"
											color="primary"
											isLoading={inProgress === 'download'}
											onClick={onDownloadHandler}
										>
											Download
										</SButton>
									</Grid>
								)}
							</Grid>

						</div>
					)}
				</Box>
			</DialogBox>
		</DialogContent>
	);
};

const Translation = withTranslation('common')(MModalBackup);

const ModalBackup = (props: IModal) => (
	<Suspense fallback={<DialogContent />}>
		<Translation {...props} />
	</Suspense>
);

export default ModalBackup;
