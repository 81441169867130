import { IPaginatedRequest } from 'src/@types/IShared';

export const getQueryParams = (params: IPaginatedRequest & { ids?: string[]}) => {
	let queryParams = `?page=${typeof params?.page === 'undefined' ? 1 : params?.page}`;
	if (params?.ids && Array.isArray(params?.ids)) queryParams += `&ids=${params?.ids}`;
	if (typeof params?.filter === 'string' && params?.filter.length > 0) queryParams += `&filter=${params?.filter}`;
	if (typeof params?.query === 'string' && params?.query.length > 0) queryParams += `&query=${encodeURIComponent(params?.query.trim())}`;
	if (typeof params?.sort === 'string' && params?.sort.length > 0) queryParams += `&sort=${params?.sort}`;
	if (params?.limit) queryParams += `&limit=${params?.limit}`;

	return queryParams;
};
