// @ts-nocheck
import React, { Suspense, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { WithTranslation, withTranslation } from 'react-i18next';
import ModalHeader from '@modules/Modal/ModalHeader';
import { isEmpty } from '@utils/ReactUtils';
import type { IModal } from '@modules/Modal/types';
import { IRootState } from 'src/store';
import { TemplateDetails } from '@components/Background/TemplateDetails';
import { getValidationErrors } from '@utils/yup';
import { APIFeatures } from '@services/apis';
import {
	Form, SInputLabel, SFormGroup,
} from '@components/DynamicForm/ComponentsCore';
import { HelpButton, SButton } from '@components/Buttons';
import { STextField } from '@forms/Components';
import {
	DialogBox,
} from '@modules/Modal/Components';
import { handleAxiosErrors } from '@services/auth';
import { FLOWS, maxLength } from '@constants/GLOBAL';
import { Creators } from '@actions';
import { Box, DialogActions, DialogContent, DialogTitle, InputLabel } from '@mui/material';
import { SSelect } from '@components/DynamicForm/Components/SSelect';
import ModalFeedback, { IFeedbackProps } from '@components/Modals/ModalFeedback';
import { SMaskField } from '@components/DynamicForm/Components/SMaskField';
import { useTour } from '@reactour/tour';
import { W_GRID } from '@components/Grids/GenericGrid';
import { ColorPalette } from '@components/Shared/ColorPalette';
import { SBox } from '@components/Background/SBox';
import SpinnerLDSRipple from '../../Shared/SpinnerLDSRipple';
import { STour } from '@components/STour';

declare type TProps = IModal & WithTranslation;

const MModalFeature = (props: TProps) => {
	const { t } = props;
	const tour = useTour();
	const { features, ideas, shared } = useSelector((state: IRootState) => state);

	const dispatch = useDispatch();

	const [activeField, setActiveField] = useState(undefined);
	const [errors, setErrors] = useState({});

	const { currentModel, pagingPaths, action } = useSelector(
		(state: IRootState) => state.modal?.ModalProps,
	);
	const [feedbackProps, setFeedbackProps] = useState<IFeedbackProps>({ open: false });

	let feature = features;

	// PAGINATION

	if (currentModel) {
		feature = currentModel;
	}

	const disabled = ['insert', 'view'].includes(action)
		|| ['delete', 'insert', 'reset'].includes(activeField) || !feature;

	const onErrorHandler = (field) => {
		if (!isEmpty(errors)) delete errors[field];
	};

	const onCreateHandler = async () => {
		try {
			if (!isEmpty(errors)) return;
			setActiveField('create');

			const schema = Yup.object().shape({
				nome: Yup.string().required(t('messages.requiredField')),
				resumo: Yup.string().required(t('messages.requiredField')),
				budget: Yup.string().matches(/[0-9]+/gi, 'Enter number only').required(t('messages.requiredField')),
			});

			const newFeature = {
				nome: features?.nome,
				prioridade: features?.prioridade,
				budget: features?.budget,
				resumo: features?.resumo,
				cor: features?.cor,
				ideaId: ideas._id,
			};

			await schema.validate(newFeature, {
				abortEarly: false,
			});

			await APIFeatures.postCreateFeature(newFeature);
			dispatch(Creators.MODAL.backModal());
		} catch (err) {
			if (err instanceof Yup.ValidationError) {
				setErrors(getValidationErrors(err));
			} else handleAxiosErrors(err);
		} finally {
			setActiveField(undefined);
		}
	};

	const onTourHandler = () => {
		if (shared.tour === FLOWS.IDEA) {
			tour.setCurrentStep(10);
		}
		tour.setIsOpen(false);
	};

	const onFeature = (key, value) => {
		setActiveField(key);
		dispatch(
			Creators.FEATURES.handleFeature({
				featureId: feature._id,
				key,
				value,
				callback: () => setActiveField(undefined),
				onError: (messages) => setErrors({ ...errors, ...messages }),
			}),
		);
		onErrorHandler(key);
	};

	const onDeleteHandler = () => {
		setFeedbackProps({
			open: true,
			title: t('feedback.remove.title-o', { name: `${t('global.feature').toLowerCase()} ${feature?.nome}` }),
			rightButtonText: t('global.remove'),
			color: 'error',
			onRightButton: () => {
				setFeedbackProps((prevState) => ({ ...prevState, isLoading: true }));
				dispatch(
					Creators.FEATURES.deleteFeature({
						featureId: feature._id,
						ideaId: ideas._id,
						templateId: feature.template._id,
						onSuccess: () => {
							onTourHandler();
							dispatch(Creators.FEATURES.getFeatures({
								ideaId: ideas._id,
								onSuccess: (response) => dispatch(Creators.IDEAS.updateIdeas({ funding: response.docs })),
							}));
						},
						callback: () => setFeedbackProps((prevState) => ({ ...prevState, isLoading: false })),
					}),
				);
			},
		});
	};

	const onInsertHandler = () => {
		setActiveField('insert');
		dispatch(
			Creators.FEATURES.insertFeature({
				ideaId: ideas._id,
				templateId: feature._id,
				callback: () => setActiveField(undefined),
				onSuccess: (feature) => {
					dispatch(Creators.MODAL.handleParams('action', 'edit'));
					dispatch(Creators.FEATURES.setFeature(feature));
				},
			}),
		);
	};

	const onResetHandler = () => {
		setFeedbackProps({
			...feedbackProps,
			title: t('feedback.reset.title'),
			text: t('feedback.reset.content'),
			rightButtonText: t('global.confirm'),
			open: true,
			color: 'success',
			onRightButton: () => {
				setFeedbackProps((prevState) => ({ ...prevState, isLoading: true }));
				dispatch(Creators.FEATURES.restoreFeature({
					featureId: feature._id,
					onSuccess: () => setFeedbackProps({ open: false }),
					callback: () => setFeedbackProps((prevState) => ({ ...prevState, isLoading: false })),
				}));
			},
		});
	};

	const onBackHandler = (params) => {
		if (!disabled) {
			dispatch(Creators.FEATURES.getFeatures({
				ideaId: ideas._id,
				onSuccess: (response) => dispatch(Creators.IDEAS.updateIdeas({ funding: response.docs })),
			}));
		}

		onTourHandler();
		dispatch(Creators.MODAL.backModal(params));
	};

	const TOUR_STEPS = [
		'template.create', 'template.update'
	].map((key) => ({
		id: key.replaceAll('.', '-'),
		title: t(`tour.${key}.title`),
		action: t(`tour.${key}.action`),
		children: t(`tour.${key}.description`),
		imagePath: undefined,
	}));

	const priorityOptions = {
		alta: { value: 'alta', label: t('evaluation.values.alta') },
		media: { value: 'media', label: t('evaluation.values.media') },
		baixa: { value: 'baixa', label: t('evaluation.values.baixa') },
	};

	return (
		<DialogContent id="FeaturesDialog">
			<ModalHeader
				fullScreen
				hideBack={pagingPaths}
				icon="idea"
				BreadcrumbProps={{ className: W_GRID.W_GRID_2 }}
				hideClose
				onBack={onBackHandler}
				pathParams={{
					idea: ideas?.nome || '...',
					feature: feature?.nome || '...',
					template: ideas?.templateNome || '...',
				}}
				BackModalProps={{ anchor: '#supports-idea-create' }}
			/>
			<DialogBox className="s-dialog-title inner w-grid-2">
				<DialogTitle variant="h1">
					{t('form.features.details')}
				</DialogTitle>
			</DialogBox>
			<SBox className="inner w-grid-2" loading={(typeof feature === 'undefined' || !feature || isEmpty(feature))}>
				<DialogBox className="s-dialog-content">
					<Box className="s-label-group s-label">
						<InputLabel>
							{t('global.template')}
						</InputLabel>
						<HelpButton color="secondary" className="tour" placement="right-start">
							<STour {...TOUR_STEPS?.[feature?.template ? 1 : 0]} hideActions />
						</HelpButton>
					</Box>

					<TemplateDetails
						onReset={action === 'edit' && onResetHandler}
						name={feature.template?.nome || feature.nome || '...'}
						color={feature.template?.cor || feature.cor}
						icon="feature"
					/>
				</DialogBox>

				<DialogBox className="s-dialog-box s-form">
					<Form>
						<SFormGroup>
							<SInputLabel htmlFor="feature-name" error={typeof errors?.nome === 'string'}>
								{t('form.features.name')}
							</SInputLabel>
							<STextField
								id="feature-name"
								name="nome"
								variant="standard"
								maxLength={maxLength.default}
								autoFocus
								placeholder={t('form.features.placeholder.name')}
								value={feature.nome}
								isLoading={activeField === 'nome'}
								isAsync={action !== 'create'}
								disabled={disabled}
								error={typeof errors?.nome === 'string'}
								helperText={errors?.nome}
								onChange={(e) => onFeature('nome', e.target.value)}
							/>
						</SFormGroup>

						<div className="d-flex">
							<SSelect
								id="form-priority"
								label={t('global.priority')}
								value={priorityOptions[feature?.prioridade].value}
								name="prioridade"
								fullWidth
								className="w-25"
								isAsync={action !== 'create'}
								isLoading={activeField === 'prioridade'}
								disabled={disabled}
								options={Object.values(priorityOptions)}
								onChange={(event) => onFeature('prioridade', event.target.value)}
							/>
							<SMaskField
								id="budget"
								disabled={disabled}
								label={t('global.budget')}
								isAsync={action !== 'create'}
								className="ml-30px"
								isLoading={activeField === 'budget'}
								value={`${feature.budget}`}
								error={typeof errors?.budget === 'string'}
								helperText={errors?.budget}
								inputProps={{
									radix: ',', // fractional delimiter
									scale: 2, // digits after point, 0 for integers
									signed: true, // disallow negative
									unmask: false,
									thousandsSeparator: '.',
									maxLength: 8,
									mask: Number,
									normalizeZeros: true, // only integer
									// if true, then pads zeros at end to the length of scale
									padFractionalZeros: false,
									onAccept: (value) => {
										onFeature('budget', value.replace(/[^\d,]+/g, ''));
									},
								}}
							/>
						</div>
						<SFormGroup>
							<SInputLabel htmlFor="feature-description" error={typeof errors?.resumo === 'string'}>
								{t('form.summary')}
							</SInputLabel>
							<STextField
								multiline
								id="feature-description"
								isLoading={activeField === 'resumo'}
								name="resumo"
								error={typeof errors?.resumo === 'string'}
								helperText={errors?.resumo}
								value={feature.resumo}
								placeholder={t('form.placeholder.description')}
								disabled={disabled}
								isAsync={action !== 'create'}
								rows={8}
								maxLength={maxLength.default500}
								onChange={(e) => onFeature('resumo', e.target.value)}
							/>
						</SFormGroup>
						<SFormGroup>
							<SInputLabel htmlFor="feature-color">{t('global.color')}</SInputLabel>
							<ColorPalette
								id="feature-color"
								value={feature.cor}
								onChange={(e) => onFeature('cor', e.target.value)}
								disabled={disabled}
							/>
						</SFormGroup>
						{action && (
							<DialogActions className="s-dialog-actions">
								{action === 'create' && (
									<SButton
										type="button"
										variant="outlined"
										color="primary"
										fullWidth
										onClick={onCreateHandler}
										isLoading={activeField === 'create'}
										error={!isEmpty(errors)}
									>
										{t('form.features.create')}
									</SButton>
								)}
								{action === 'insert' && (
									<SButton
										type="button"
										variant="outlined"
										color="primary"
										isLoading={activeField === 'insert'}
										onClick={onInsertHandler}
									>
										{t('form.features.insert')}
									</SButton>
								)}
								{action === 'edit' && (
									<SButton
										type="button"
										color="error"
										variant="outlined"
										onClick={onDeleteHandler}
									>
										{t('form.features.remove')}
									</SButton>
								)}
							</DialogActions>
						)}
					</Form>
				</DialogBox>
			</SBox>
			{feedbackProps.open && (
				<ModalFeedback
					id="feature"
					onRequestClose={() => setFeedbackProps({ open: false })}
					{...feedbackProps}
				/>
			)}
		</DialogContent>
	);
};

const Translation = withTranslation('common')(MModalFeature);

const ModalFeature = (props: IModal) => (
	<Suspense fallback={<DialogContent id="FeaturesDialog" />}>
		<Translation {...props} />
	</Suspense>
);

export default ModalFeature;
