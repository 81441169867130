// @ts-nocheck
import React, {
	Suspense, useEffect, useState,
} from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import ModalHeader from '@modules/Modal/ModalHeader';
import { IdeaCard } from '@components/Cards/IdeaCard';
import { IRootState } from 'src/store';
import type { IModal } from '@modules/Modal/types';
import { IPaginatedRequest, IPagination } from 'src/@types/IShared';
import { IIdea } from 'src/@types/IIdea';
import { APIIdeas } from '@services/apis';
import { handleAxiosErrors } from '@services/auth';
import { DialogBox } from '@modules/Modal/Components';
import { Creators } from '@actions';
import CardGrid from '@components/Grids/CardGrid';
import { IListRequest } from '@components/Lists/types';
import { DialogContent, DialogTitle } from '@mui/material';
import { GenericGrid, W_GRID } from '@components/Grids/GenericGrid';
import FiltersNavBar from '../../NavBar/FiltersNavBar';
import { HelpButton } from '../../Buttons/HelpButton';

declare type TProps = IModal & WithTranslation

const MModalConnect = (props: TProps) => {
	const { t, routes } = props;
	const { ideas } = useSelector((state: IRootState) => state);
	const [_ideas, setIdeas] = useState<IPagination<IIdea>>({ docs: [], isLoading: true });
	const [queryParams, setQueryParams] = useState<IPaginatedRequest>({
		page: 1,
		limit: screen.width <= 1536 ? 2 : 3,
		sort: 'last-first',
	}); // loadMore

	const dispatch = useDispatch();

	const loadIdeas = async ({ callback, ...rest }: IListRequest) => {
		try {
			setIdeas((prevState) => ({ ...prevState, isLoading: true }));
			setQueryParams((prevState) => ({ ...prevState, ...rest }));
			const response = await APIIdeas.getIdeasToConnect(ideas._id, rest);

			setIdeas({
				...response.data,
				docs: response.data.hasPrevPage
					? _ideas.docs.concat(response.data.docs)
					: response.data.docs,
				isLoading: false,
			});
		} catch (err) {
			handleAxiosErrors(err);
		} finally { // loadMore
			if (typeof callback === 'function') {
				callback();
			}
		}
	};

	const onConnectHandler = (ideaId: string, mainIdea: IIdea) => {
		const index = _ideas.docs.findIndex((item) => item._id === ideaId);
		if (index > -1) {
			_ideas.docs.splice(index, 1);
			setIdeas({ ..._ideas });
		}

		if (mainIdea && typeof ideas?.syncIdea === 'function') {
			ideas?.syncIdea(mainIdea);
		}
	};

	useEffect(() => {
		loadIdeas(queryParams);
	}, []);

	return (
		<DialogContent id="ConnectDialog">
			<ModalHeader
				icon="idea"
				BreadcrumbProps={{ className: W_GRID.W_GRID_1 }}
				fullScreen
				onClose={(event, hideModal) => {
					dispatch(Creators.IDEAS.clearIdeaEdit());
					hideModal();
				}}
				hideBack
				pathParams={{
					idea: ideas.nome || '...',
				}}
			/>
			<DialogBox className="s-dialog-title inner w-grid-1">
				<DialogTitle variant="h1">
					{t('idea.chooseToConnect')}
				</DialogTitle>
				<HelpButton size="medium" helperURL={t('urls.howToConnection')} />
			</DialogBox>
			<DialogBox className="s-dialog-content inner w-grid-1">
				<FiltersNavBar
					id="connection-nav"
					onSearch={loadIdeas} // Debounced
					onUpdate={loadIdeas}
					type="ideas"
					params={queryParams}
				>
					<CardGrid
						id="ideas-connect"
						section={_ideas}
						type="ideas"
						GridItemProps={{
							xs: 12, sm: 6, md: 4, xl: 3,
						}}
						loadMore={loadIdeas}
						component={(idea) => (
							<IdeaCard
								clickable={false}
								idea={idea}
								routes={routes}
								sectionLocator="connect"
								match={ideas}
								onConnect={onConnectHandler}
								// To Like
								updateIdea={(_idea) => {
									const index = _ideas.docs.findIndex((item) => item._id === _idea._id);
									if (index > -1) {
										_ideas.docs.splice(index, 1, { ..._ideas?.docs[index], ..._idea });
										setIdeas({ ..._ideas });
									}
								}}
							/>
						)}
					/>
				</FiltersNavBar>
			</DialogBox>
		</DialogContent>
	);
};

const Translation = withTranslation('common')(MModalConnect);

const ModalConnect = (props: IModal) => (
	<Suspense fallback={<div />}>
		<Translation {...props} />
	</Suspense>
);

export default ModalConnect;
