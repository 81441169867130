import Icon from '@components/Shared/Icon';
import React from 'react';
import { Box } from '@mui/material';

interface IOwnProps {
	className?: string
	name?: string
	placeholder?: string
}

const InputSearch = ({
	placeholder = 'Search', className = 'w-25', name = 'search', ...rest
}: IOwnProps) => (
	<Box
		className={`input-group ${className}`}
		sx={{
			display: 'flex',
			alignItems: 'center',
			overflow: 'hidden',

			'.fa-magnifying-glass': {
				position: 'absolute',
				padding: '5px',
			},

			input: {
				padding: '0.5rem 0.5rem 0.5rem 2rem',
				backgroundColor: 'transparent',
			},
			'input:focus': {
				backgroundColor: 'transparent',
			},
		}}

	>
		<Icon icon="search" />
		<input
			className="form-control"
			type="search"
			name={name}
			placeholder={placeholder}
			aria-label="Search"
			autoComplete="Off"
			{...rest}
		/>
		<div className="input-line-ripple" />
	</Box>
);

export default InputSearch;
