/* eslint-disable react/no-danger */
// @ts-nocheck
import React, {
	Suspense, useCallback, useEffect, useState,
} from 'react';
import { useTranslation} from 'react-i18next';
import { IPaginatedRequest } from 'src/@types/IShared';
import { debounce } from '@utils/helpers';
import { SButton } from '@components/Buttons';
import { ISMenuOption, SMenu } from '@components/SMenu';
import { Box, BoxProps } from '@mui/material';
import Icon from '../Shared/Icon';
import InputSearch from '../Inputs/InputSearch';

interface FiltersNavBarProps extends BoxProps {
	id: string
	disabled?: boolean
	children?: React.ReactChildren // 1 child
	filters?: ISMenuOption[]
	limits?: ISMenuOption[]
	sorts?: ISMenuOption[]
	onUpdate?: (params: IPaginatedRequest) => void
	onSearch?: (params: IPaginatedRequest) => void
	label?: string
	params?: IPaginatedRequest
	controlled?: boolean
	className?: string
	components?: { LeftButton?: React.ReactNode, RightButton?: React.ReactNode }
}

const FFiltersNavBar = (props: FiltersNavBarProps) => {
	const {
		filters, sorts, limits, onUpdate, onSearch, sx, components, disabled,
		label, params, id, controlled, children, className, ...rest
	} = props;

	const { t } = useTranslation();

	const [anchorLimit, setAnchorLimit] = useState<null | HTMLElement>(null);
	const [anchorSort, setAnchorSort] = useState<null | HTMLElement>(null);
	const [anchorFilter, setAnchorFilter] = useState<null | HTMLElement>(null);

	const open = {
		sort: Boolean(anchorSort),
		filter: Boolean(anchorFilter),
		limit: Boolean(anchorLimit),
	};

	const defaultSort = [
		{ value: 'first-last', label: t('sort.first-last') },
		{ value: 'last-first', label: t('sort.last-first') },
		{ value: 'z-a', label: t('sort.z-a') },
		{ value: 'a-z', label: t('sort.a-z') },
	];
	const [queryParams, setQueryParams] = useState<IPaginatedRequest>({
		query: '',
	});

	const [sortOptions, setSortOptions] = useState([]);

	const [filter, setFilter] = useState(undefined);
	const [sort, setSort] = useState(undefined);
	const [limit, setLimit] = useState(undefined);

	const onChangeHandler = (option: ISMenuOption, mode: 'filter' | 'sort' | 'limit') => {
		let updatedParams = {
			...queryParams,
			page: 1,
		};

		if (mode === 'sort') {
			setSort(option);
			updatedParams = { ...updatedParams, sort: option.value };
		}

		if (typeof option.value === 'number') {
			setLimit(option);
			updatedParams = { ...updatedParams, limit: option.value };
		}

		if (mode === 'filter') {
			setFilter(option);
			updatedParams = { ...updatedParams, filter: option.value };
		}

		setQueryParams(updatedParams);

		if (typeof onUpdate === 'function') {
			onUpdate(updatedParams);
		}
	};

	const onSearchedDebounced = useCallback(debounce(onSearch, 1000), []);

	const onSearchHandler = (event) => {
		const updatedParams = { ...queryParams, query: event.target.value };

		setQueryParams(updatedParams);
		onSearchedDebounced(updatedParams);
	};

	const renderChild = () => {
		// if (!React.isValidElement(children)) return null;
		const _children = Array.isArray(children) ? children : React.Children.toArray(children);
		return _children.map((child) => React.cloneElement(child, {
			queryParams,
		}));
	};

	useEffect(() => {
		setQueryParams({ ...queryParams, ...params });
		setSortOptions(sorts || defaultSort);
		setSort(() => (sorts || defaultSort)?.find((item) => item.value === params?.sort));
		setFilter(() => filters?.find((item) => item.value === params?.filter));
		setLimit(() => limits?.find((item) => item.value === params?.limit));

		// console.log('MOUNT FILTERS', sorts);
	}, []);

	useEffect(() => {
		if (controlled) {
			setQueryParams({ ...queryParams, ...params });
			setSort(() => (sorts || defaultSort)?.find((item) => item.value === params?.sort));
			setFilter(() => filters?.find((item) => item.value === params?.filter));
			setLimit(() => limits?.find((item) => item.value === params?.limit));
		}
	}, [controlled, params]);

	return (
		<Box className={`s-navbar ${className || ''}`} sx={{ display: 'flex', flexDirection: 'column', ...sx }} {...rest}>
			<Box
				id={id}
				className="s-navbar-filters"
				sx={{ width: '100%', flexDirection: 'row', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
			>
				<Box sx={{ display: 'flex' }}>
					{components?.LeftButton}
					{typeof onSearch === 'function' && (
						<InputSearch
							className="input-search-nav"
							onChange={onSearchHandler}
							disabled={disabled}
							placeholder={t('global.searchByName')}
							value={queryParams.query}
						/>
					)}
				</Box>

				<Box sx={{ display: 'flex', gap: 1 }}>
					{filters && (
						<SMenu
							id={`${id}-filters-menu`}
							open={open.filter}
							anchorEl={anchorFilter}
							aria-disabled={disabled}
							setAnchorEl={setAnchorFilter}
							options={filters}
							onChange={typeof onUpdate === 'function' ? (option) => onChangeHandler(option, 'filter') : undefined}
						>
							<SButton
								type="button"
								color="inherit"
								variant="text"
								disabled={disabled}
								sx={{ borderRadius: 0 }}
								startIcon={<Icon icon="filter" />}
							>
								{label || filter?.label}
							</SButton>
						</SMenu>
					)}
					{limits && (
						<SMenu
							id={`${id}-limits-menu`}
							open={open.limit}
							anchorEl={anchorLimit}
							setAnchorEl={setAnchorLimit}
							options={limits}
							disabled={disabled}
							aria-disabled={disabled}
							onChange={typeof onUpdate === 'function' ? (option) => onChangeHandler(option, 'limit') : undefined}
						>
							<SButton
								type="button"
								color="inherit"
								variant="text"
								disabled={disabled}
								sx={{ borderRadius: 0 }}
								startIcon={<Icon icon="filter" />}
							>
								{limit?.label}
							</SButton>
						</SMenu>
					)}

					{sortOptions.length > 0 && (
						<SMenu
							id={`${id}-sorts-menu`}
							open={open.sort}
							anchorEl={anchorSort}
							setAnchorEl={setAnchorSort}
							disabled={disabled}
							options={sortOptions}
							onChange={typeof onUpdate === 'function' ? (option) => onChangeHandler(option, 'sort') : undefined}
						>
							<SButton
								type="button"
								color="inherit"
								className="s-menu-sort"
								variant="text"
								sx={{ borderRadius: 0 }}
								disabled={disabled}
								startIcon={<Icon icon="sort" />}
							>
								{sort?.label}
							</SButton>
						</SMenu>
					)}
					{components?.RightButton}
				</Box>
			</Box>
			{renderChild()}
		</Box>
	);
};

const FiltersNavBar = (props: FiltersNavBarProps) => (
	<Suspense fallback={<div />}>
		<FFiltersNavBar {...props} />
	</Suspense>
);

export default FiltersNavBar;
