// @ts-nocheck
import { SButton } from '@components/Buttons';
import Icon from '@components/Shared/Icon';
import React, { Suspense, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { SurveyBar } from './styles';

const _RelevanceSurveyBar = (props) => {
	const { t } = props;
	const [answered, setAnswered] = useState(false);

	const onAnswerHandler = () => {
		setAnswered(true);
	};

	return (
		<SurveyBar
			className="survey__bar"
			sx={{
				opacity: answered ? 0 : 1,
			}}
		>
			<Icon icon="far fa-comment-alt" />
			<span className="survey__question">{t('trends.relevanceSurvey')}</span>
			<SButton type="button" color="inherit" variant="outlined" onClick={onAnswerHandler} disabled={answered}>
				{t('global.yes')}
			</SButton>
			<SButton type="button" color="inherit" variant="outlined" onClick={onAnswerHandler} disabled={answered}>
				{t('global.not')}
			</SButton>
		</SurveyBar>
	);
};

const Translation = withTranslation('common')(_RelevanceSurveyBar);

const RelevanceSurveyBar = (props) => (
	<Suspense fallback={<div />}>
		<Translation {...props} />
	</Suspense>
);

export { RelevanceSurveyBar };
